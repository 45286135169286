import React from 'react'
import "./select.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ToastAlert from "../ToastAlert/ToastAlert";
import Header from '../Header/Header';
import { GetCookie } from '../Cookie/Session';
const ToastRef = React.createRef();


const AddProfile = () => {
    const [multipleProfile, setMultipleProfile] = useState([]);
    const navigate = useNavigate();

    const allProfiles = [
        { name: 'Landlord', action: () => navigate('/addproperty'), },
        
        { name: 'Tenant', action: () =>  ToastRef.current.notify(["success", 'Tenant']) },
        { name: 'Trade', action: () =>  ToastRef.current.notify(["success", 'Trade']) },
        { 
            name: 'Agent/Agency', 
            action: () => {
                if (multipleProfile.includes('Agency')) {
                    navigate("/agency/dashboard");
                    console.log('Hello Agency')
                } else if (multipleProfile.includes('Agent')) {
                    navigate("/agent/dashboard");
                    ToastRef.current.notify(["success", 'Agent']);
                    console.log('Hello agent')
                }
            }, 
        },
    ];
    // const remainingProfiles = allProfiles.filter(profile => multipleProfile.includes(profile.name));
    const remainingProfiles = allProfiles.filter(profile => {
        if (profile.name === 'Agent/Agency') {
            return multipleProfile.includes('Agent') || multipleProfile.includes('Agency');
        } else {
            return multipleProfile.includes(profile.name);
        }
    });
    

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    const userRoles = parsedData.userProfiles.map((profile) => profile.userRoleLabel);

                  
                    console.log(multipleProfile)
                    setMultipleProfile(parsedData.userProfiles.map((profile) => profile.userRoleLabel));

                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };

        retrieveData();
    }, []);
    return (
        <div className="selectionuser-choose-profile">
            <ToastAlert ref={ToastRef} />
            <Header />
            <div className="containerx">
                <div className="selectionadd-profile1">Add Profile</div>
                <div className="selectionframe-parent">
                    {
                        remainingProfiles.map((label, index) => (
                            <div key={index} >
                                <div
                                    className="selectionframe-container"
                                    id={label.name}
                                    onClick={label.action}
                                >
                                    {
                                        label.name == 'Landlord' ? (
                                            <div className="selectionrectangle-wrapper">
                                                <img
                                                    className="selectionframe-child"
                                                    alt=""
                                                    src=".\assets\images\landlord.png"
                                                />
                                            </div>
                                        ) : label.name == 'Tenant' ? (
                                            <div className="selectionrectangle-wrapper">
                                                <img
                                                    className="selectionframe-child"
                                                    alt=""
                                                    src=".\assets\images\tenent.png"
                                                />
                                            </div>
                                        ) : label.name == 'Trade' ? (
                                            <div className="selectionrectangle-wrapper">
                                                <img
                                                    className="selectionframe-child"
                                                    alt=""
                                                    src=".\assets\images\trade.png"
                                                />
                                            </div>
                                        ) : label.name == 'Agent/Agency' ? (
                                            <div className="selectionrectangle-wrapper">
                                                <img
                                                    className="selectionframe-child"
                                                    alt=""
                                                    src=".\assets\images\agent.png"
                                                />
                                            </div>
                                        ) : null

                                    }
                                    <div className="selectionlandlord">{label.name}</div>
                                </div>
                            </div>

                        ))
                    }

                    <div
                        className="selectionframe-container"
                        id="agentagency"
                        onClick={() => navigate("/SelectAddProfile")}
                    >
                        <div className="selectionrectangle-wrapper">
                            <img
                                className="selectionframe-child"
                                alt=""
                                src=".\assets\images\Add.png"
                            />
                        </div>
                        <div className="selectionlandlord">Add Profile</div>
                    </div>
                </div>






            </div>
        </div>
    )
}

export default AddProfile

