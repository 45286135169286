import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

class BasicDetails extends React.Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    noOfColumnInRow: PropTypes.number,
    modifiedDataStatus: PropTypes.bool,
    under: PropTypes.string,
  };

  static defaultProps = {
    noOfColumnInRow: 2,
  };

  renderDetailValue(value) {
    if (typeof value === 'string' && value.length > 30) {
      return (
        <Tooltip title={value}>
          <span>{value.slice(0, 30)}...</span>
        </Tooltip>
      );
    }
    return value;
  }

  render() {
    const { details, noOfColumnInRow, modifiedDataStatus } = this.props;
    const basicDetailsKeys = Object.keys(details);

    return (
      <div className="ml-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="row mx-0">
          {basicDetailsKeys.map((key, index) => (
            <div
              key={`basicDetails_${index}`}
              className={`col-lg-${noOfColumnInRow} col-sm-5 mb-3 col-md-${noOfColumnInRow}`}
              style={{ wordBreak: 'break-word' }}
            >
              <label className="labelbasicfont" style={{ fontSize: '14px', fontWeight: '600' }}>
                {this.props.under
                  ? key.replace(/_/g, ' ').trim()
                  : key.replace(/([A-Z])/g, ' $1').trim()}
              </label>
              <br />
              <label className="detailsfont" style={{ fontSize: '14px', fontWeight: '600' }}>
                {this.renderDetailValue(details[key])}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default BasicDetails;
