import React, { useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ContactActivitiesList from "./listActivities";
import PropertyActivitiesList from "./listPropertyActivities"
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { useNavigate } from "react-router-dom";

const MainActivities = () => {
    const ToastRef = useRef();
    const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("contactActivities");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListActivities");
  const [topicValue, setTopicValue] = useState("Profile");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  return (

    <div className="dashboard-agency">
    <Header />
    <div
      className="blank_space"
      style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
    ></div>
    <div className="continer-dbag">
      <ToastAlert ref={ToastRef} />
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
    
    <div className="p-2 mt-3" style={{width:'100%'}}>
      <Tabs activeKey={activeTab} onSelect={handleTabChange} className="mb-3 properties-nav-bar">
        <Tab eventKey="contactActivities" title="Contact Activities">
          <div style={{width:'100%'}}>
          <ContactActivitiesList handleparentsuccess={handleToastMessage}/>
          </div>
        </Tab>
        {/* Add more tabs as needed */}
        <Tab eventKey="otherTab" title="Property Activities">
          {/* Other component */}
          <div style={{width:'100%'}}>
          <PropertyActivitiesList handleparentsuccess={handleToastMessage}/>
          </div>
        </Tab>
      </Tabs>
    </div>
      </div>
    </div>
  </div>
  );
};

export default MainActivities;
