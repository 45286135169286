import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import {
  FilePath,
  PropertySetPrimaryUrl,
  defaultProfilePic,
  SearchContacts,
  ContactDetailsApi,
  addupdateTenant,
  addtenantfromcontact,
  updatetenantfoliodetails
} from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { BiEdit, BiMailSend, BiEnvelope, BiPhone ,BiDollar } from "react-icons/bi";
import Collapse from "../../../../src/input-components/collapse";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RatingStar from "../../../../src/input-components/ratingStar";
import { rentDetails,TenantContactSchema } from "../../../CommonSection/Schema/schema";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import {addParametersForProfiles,dynamicUrl} from "../../../../Utilities/profileManagement";
import { IoPersonAddOutline } from "react-icons/io5";
import { Tabs,Card  } from "antd";
import { BiUserPlus } from "react-icons/bi";


const { TabPane } = Tabs;

const initialDetailsValues  = {
  fname1: "", 
  lname1: "", 
  email: "", 
  mobileNumber: "", 
  tenantId: ""
}

const initialRentValues = {
  Rent: "",
  Period: "",
  RentIncludeTax: false,
  BondAmount: "",
  BondInTrust: "",
  AgreementStart: "",
  AgreementEnd: "",
  Deposited: "",
  Balance: "",
  RentReviewFrequency: "",
  IsCommercial: "",
  ABN: "",
};

const TenantPropertyAgency = ({onDataRecive,handleparentsuccess, getDetails,handleCurrentPage}) => {
  const [userId, setUserId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [ownerShipLists, setOwnerShipLists] = useState([]);
  const [displayAddOwnerShip, setDisplayAddOwnerShip] = useState(false);
  const [renderFields] = useState(true);
  const [typeData, setTypeData] = useState([
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ]);
  const [updateTable, setUpdateTable] = useState(true);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");
  const [agencyId,setAgencyId] = useState("");
  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [displayAddContacts, setDisplayAddContacts] = useState(false);
  const [submitExistigContact, setSubitExisitingContact] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [activeKey, setActiveKey] = useState("1");
  const [isTabDisabled, setIsTabDisabled] = useState(true);
  const [isCommercialData, setIsCommercialData] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);
  const [periodData, setPeriodData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value:  2 },
  ]);

  const [rentIncludeTaxData, setRentIncludeTaxData] = useState([
    { label: "Yes", value: true },
    { label: "No", value:  false },
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId);
          setAgencyId(parsedData.agencyID);
          PostApiWithSpinner(parsedData.agentId,parsedData.agencyUniqueID)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  useEffect(() => {
    if(getDetails){
      let ownerListData = getDetails.tenantDetails ? getDetails.tenantDetails : [];
      let ownerList = ownerListData.map((data) => ({
        Name: data.getFullName,
        Email: data.tenantEmail,
        Phone: data.tenantMobile,
        isPrimary: data.primaryTenant,
        ownerId: data.tenantId,
      }));
      setOwnerShipLists(ownerList);
      rentSetFieldValue("Rent", ownerListData[0]?.tenantFolioDetails.rent);
      rentSetFieldValue("Period", ownerListData[0]?.tenantFolioDetails.period);
      rentSetFieldValue("IsCommercial", ownerListData[0]?.tenantFolioDetails.isCommercial);
      rentSetFieldValue("RentIncludeTax", ownerListData[0]?.tenantFolioDetails.rentIncludeTax);
      rentSetFieldValue("Deposited",ownerListData[0]?.tenantFolioDetails.deposited);
      rentSetFieldValue("Balance", ownerListData[0]?.tenantFolioDetails.balance);
      rentSetFieldValue("RentReviewFrequency", ownerListData[0]?.tenantFolioDetails.rentReviewFrequency);
      rentSetFieldValue("ABN", ownerListData[0]?.tenantFolioDetails.abn);
      rentSetFieldValue("AgreementStart", formatDate(ownerListData[0]?.tenantFolioDetails.agreementStart));
      rentSetFieldValue("AgreementEnd", formatDate(ownerListData[0]?.tenantFolioDetails.agreementEnd));
      rentSetFieldValue("BondAmount", ownerListData[0]?.tenantFolioDetails.bondAmount);
      rentSetFieldValue("BondInTrust", ownerListData[0]?.tenantFolioDetails.bondInTrust);
      setIsTabDisabled(ownerListData.length  != 0 ? false : true)
    }
  },[getDetails])

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  const {
    errors: detailsErrors,
    values: detailsValues,
    touched: detailsTouched,
    handleBlur: detailsHandleBlur,
    handleChange: detailsHandleChange,
    handleSubmit: detailsHandleSubmit,
    setFieldValue: detailsSetFieldValue,
  } = useFormik({
    initialValues: initialDetailsValues,
    validationSchema:TenantContactSchema,
    onSubmit: (values) => {
      submitExistigContact ? handleExisitingContact() : handleFinalSubmit();
    }
  });

  const {
    errors: rentErrors,
    values: rentValues,
    touched: rentTouched,
    handleBlur: rentHandleBlur,
    handleChange: rentHandleChange,
    handleSubmit: rentHandleSubmit,
    setFieldValue: rentSetFieldValue,
  } = useFormik({
    initialValues: initialRentValues,
    validationSchema:rentDetails,
    onSubmit: (values) => {
      handleRentSubmit();
    }
  });

  const handleFinalSubmit = () => {
    const formData = {
      TenantId: detailsValues.tenantId ? detailsValues.tenantId : 0,
      PropertyId: getDetails.propertyId,
      TenantFname: detailsValues.fname1,
      TenantLname: detailsValues.lname1,
      TenantMobile: detailsValues.mobileNumber,
      TenantEmail: detailsValues.email,
      UserId: userId,
      AgencyId : agencyId
    };
    const url = addupdateTenant;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleparentsuccess(["success", returnData.message]);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          onDataRecive(propertyUId)
          setDisplayAddContacts(false);
          setDisplayAddOwnerShip(false);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleExisitingContact = () => {
    debugger;
    const formData = {
      PropertyId: getDetails.propertyId ? getDetails.propertyId : 0,
      ContactId: contactId,
      UserId: userId,
      AgencyId :agencyId
    };
    const url = addtenantfromcontact;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          debugger;
          setDisplayAddOwnerShip(false);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          onDataRecive(propertyUId)
          handleparentsuccess(["success", returnData.message]);
          //GetApi(propertyUId);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleRentSubmit = () => {
    const formData = {
      Id: getDetails?.tenantDetails[0]?.tenantFolioDetails.id,
      Rent: parseFloat(rentValues.Rent),
      Period: rentValues.Period ? parseInt(rentValues.Period) : null,
      RentIncludeTax: rentValues.RentIncludeTax,
      BondAmount: parseFloat(rentValues.BondAmount),
      BondInTrust: parseFloat(rentValues.BondInTrust),
      AgreementStart: rentValues.AgreementStart ,
      AgreementEnd: rentValues.AgreementEnd ,
      Deposited: parseFloat(rentValues.Deposited),
      Balance: parseFloat(rentValues.Balance),
      RentReviewFrequency: rentValues.RentReviewFrequency,
      IsCommercial: rentValues.IsCommercial,
      ABN: rentValues.ABN,
      UserId: userId,
    };
    const url = updatetenantfoliodetails;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          handleparentsuccess(["success", returnData.message]);
          onDataRecive(propertyUId);
          handleCurrentPage("Overview")
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleAddOwner = () => {
    setSubitExisitingContact(false)
    setDisplayAddContacts(false);
    detailsSetFieldValue("fname1", "");
    detailsSetFieldValue("lname1", "");
    detailsSetFieldValue("email", "");
    detailsSetFieldValue("mobileNumber", "");
    setDisplayAddOwnerShip(true);
  };

  const getTypeData = (selectedValue) => {
    return typeData.find((item) => item.value === selectedValue);
  };

  const handleUpdateOwnerShip = (ownerId) => {
    false;
    const matchedOwner = getDetails.tenantDetails.find(
      (owner) => owner.tenantId === ownerId
    );
    if (matchedOwner) {
      setDisplayAddContacts(true);
      setDisplayAddOwnerShip(true);
      setSubitExisitingContact(false)
      detailsSetFieldValue("fname1", matchedOwner.tenantFname);
      detailsSetFieldValue("lname1", matchedOwner.tenantLname);
      detailsSetFieldValue("email", matchedOwner.tenantEmail);
      detailsSetFieldValue("mobileNumber", matchedOwner.tenantMobile);
      detailsSetFieldValue(matchedOwner.contactUId);
      detailsSetFieldValue("tenantId" , matchedOwner.tenantId)
    }
  };

  const handleRatingChange = (newRating, ownerId) => {
    getAgencyCompanyDetails(ownerId);
    setUpdateTable(false);
  };

  const getAgencyCompanyDetails = (ownerId) => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${PropertySetPrimaryUrl}?OwnerId=${ownerId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          setUpdateTable(true);
          setDisplayAddOwnerShip(false);
          handleparentsuccess(["success", returnData.message]);
        } else {
          handleparentsuccess(["error", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", response.data.message]);
      });
  };

  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const agentId = agentUIDRef.current;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOption([]);
      setFetching(true);
      const baseUrl = SearchContacts;
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgentID: agentId,
      };
      data = addParametersForProfiles(data);
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
              uid: item.uid
            }));
            if (fetchId === fetchRef.current) {
              setOption(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    const label = value.map((item) => item.label).join(",");
    const values = value.map((item) => item.value).join(",");
    const uid = value.map((item) => item.uid).join(",");
    GetContactDetails(uid)
    detailsSetFieldValue("contactId", values);
    setFetching("propertyName", label);
    setContactId(values);
    debounceFetcher(value);
    setSubitExisitingContact(value.length != 0 ? true : false);
  };

  const GetContactDetails = (value) => {
    var url = new URL(window.location.href);
    var contactUniqueId = value ;
    const baseUrl = ContactDetailsApi;
    const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let data = response.object
          detailsSetFieldValue("fname1", data.firstName)
          detailsSetFieldValue("lname1", data.lastName)
          detailsSetFieldValue("mobileNumber", data.mobileNumber)
          detailsSetFieldValue("email", data.email)
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleAddContacts = () => {
    setSubitExisitingContact(false);
    setDisplayAddContacts(!displayAddContacts);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleAgreementNav = () => {
    setActiveKey('2')
   window.scrollTo(0, 0);
  }

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  return (
    <div>
      <Tabs style={{ padding: '0px 25px 0px 25px' }} activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab="Details" key="1">

          {!displayAddOwnerShip ? (
            <div style={{ paddingTop: "10px" }}>
              {
                ownerShipLists.length > 0 ? (
                  <div className="property-details-content df-all">
                    <div
                      className="pb-0"
                      style={{ display: "flex", justifyContent: "end", width: "100%" }}
                    >
                      <button className="pdb-save-changes mr-1" onClick={handleAddOwner}>
                        <BiUserPlus style={{ fontSize: '20px' }} />
                      </button>
                    </div>
                    {ownerShipLists.map((owner) => (
                      <Card>
                        <div>
                          <div
                            className="main-label-no"
                            style={{ width: "100%", fontWeight: "500", cursor: "pointer", display: 'flex', justifyContent: 'end' }}
                            onClick={(e) => handleUpdateOwnerShip(owner.ownerId)}
                          >
                            <BiEdit
                              style={{ fontSize: "18px", marginRight: "5px" }}
                            />
                          </div>
                          <div
                            key={owner.ownerId}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                              }}
                              src={FilePath + defaultProfilePic}
                              alt=""
                            />
                            <div style={{ width: "100%" }}>
                              <div className="mail-label-name">
                                {owner.Name}{" "}
                                <span
                                  style={{ marginLeft: "10px", marginTop: "-10px" }}
                                >
                                  {" "}
                                  {owner.isPrimary ? (
                                    <RatingStar
                                      totalStars={1}
                                      initialRating={owner.isPrimary ? 1 : 0}
                                    />
                                  ) : null}
                                </span>
                              </div>
                              <div className="main-label-no">
                                <BiPhone
                                  style={{ fontSize: "16px", marginRight: "5px" }}
                                />
                                {owner.Phone}
                              </div>
                              <div className="main-label-no">
                                <BiMailSend
                                  style={{ fontSize: "16px", marginRight: "5px" }}
                                />
                                {owner.Email}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "0px 20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >

                          </div>
                        </div>
                      </Card>
                      

                    ))}
                    <div
                      className="pb-0"
                      style={{ display: "flex", justifyContent: "end", width: "100%" }}
                    >
                      <button className="pdb-save-changes" onClick={handleAgreementNav}>
                        Save & Next
                      </button>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={detailsHandleSubmit}>
                    <div className="property-details-content df-all all-input">
                      <div className="pddpn-group pd-address">
                        <Collapse
                          id={"Personal-Details"}
                          title={"Personal-Details"}
                          data={
                            <div>

                              <div>

                                <div className="contacts-group">
                                  <div className={`lda col-lg-6 col-sm-12`}>
                                    <label className="s1">Contacts </label>
                                    <div style={{ display: "flex" }}>
                                      <Select
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                        }}
                                        mode="multiple"
                                        placeholder="Search for contacts"
                                        labelInValue
                                        filterOption={false}
                                        onSearch={debounceFetcher}
                                        notFoundContent={
                                          fetching ? (
                                            <Spin size="small" />
                                          ) : null
                                        }
                                        options={option}
                                        onChange={(label, value, uid) =>
                                          handleSearchChange(label, value,uid)
                                        }
                                        defaultValue={selectedValues}
                                      />
                                      <IoPersonAddOutline
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "35px",
                                          marginRight: "5px",
                                        }}
                                        onClick={handleAddContacts}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div className="contacts-group">
                                                <TextBox
                                                label="First Name"
                                                isMandatory
                                    placeholder="First Name"
                                    id="fname1"
                                    name="fname1"
                                    value={detailsValues.fname1}
                                    onChange={detailsHandleChange}
                                    onBlur={detailsHandleBlur}
                                    error={detailsErrors.fname1}
                                    touched={detailsTouched.fname1}
                                  />
                                  <TextBox
                                  label="Last Name"
                                    placeholder="Last Name"
                                    id="lname1"
                                    name="lname1"
                                    value={detailsValues.lname1}
                                    onChange={detailsHandleChange}
                                    onBlur={detailsHandleBlur}
                                    error={detailsErrors.lname1}
                                    touched={detailsTouched.lname1}
                                  />
                                  <TextBox
                                  label="Mobile"
                                    placeholder="Mobile"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    value={detailsValues.mobileNumber}
                                    onChange={detailsHandleChange}
                                    onBlur={detailsHandleBlur}
                                    error={detailsErrors.mobileNumber}
                                    touched={detailsTouched.mobileNumber}
                                    icon={<BiPhone height="100%" width="100%" />}
                                  />
                                  <TextBox
                                  label="Email"
                                    placeholder="Email"
                                    id="email"
                                    name="email"
                                    value={detailsValues.email}
                                    onChange={detailsHandleChange}
                                    onBlur={detailsHandleBlur}
                                    error={detailsErrors.email}
                                    touched={detailsTouched.email}
                                    icon={<BiEnvelope height="100%" width="100%" />}
                                  />

                                </div>

                              </div>

                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="property-details-buttons">
                      <button
                        className="pdb-cancel"
                        type="reset"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button className="pdb-save-changes" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                )
              }
            </div>
          ) : null}

          {displayAddOwnerShip ? (
            <form onSubmit={detailsHandleSubmit} className="details-form">
              <div className="property-details-content df-all all-input">
                <div className="pddpn-group pd-address">
                  
                  <Collapse
                    id={"Personal-Details"}
                    title={"Personal-Details"}
                    data={
                      <div>
                        {renderFields ? (
                          <div>
                            <div className="contacts-group">
                              <div className={`lda col-lg-6 col-sm-12`}>
                              <label className="s1">Contacts </label>
                                <div style={{ display: "flex" }}>
                                  <Select
                                    style={{
                                      width: "100%",
                                      height: "40px",
                                    }}
                                    mode="multiple"
                                    placeholder="Search for contacts"
                                    labelInValue
                                    filterOption={false}
                                    onSearch={debounceFetcher}
                                    notFoundContent={
                                      fetching ? <Spin size="small" /> : null
                                    }
                                    options={option}
                                    onChange={(label, value) =>
                                      handleSearchChange(label, value)
                                    }
                                    defaultValue={selectedValues}
                                  />
                                  <IoPersonAddOutline
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "35px",
                                      marginRight: "5px",
                                    }}
                                    onClick={handleAddContacts}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="contacts-group">
                                          <TextBox
                                          label={"First Name"}
                                          isMandatory
                                placeholder="First Name"
                                id="fname1"
                                name="fname1"
                                value={detailsValues.fname1}
                                onChange={detailsHandleChange}
                                onBlur={detailsHandleBlur}
                                error={detailsErrors.fname1}
                                touched={detailsTouched.fname1}
                              />
                              <TextBox
                              label="Last Name"
                                placeholder="Last Name"
                                id="lname1"
                                name="lname1"
                                value={detailsValues.lname1}
                                onChange={detailsHandleChange}
                                onBlur={detailsHandleBlur}
                                error={detailsErrors.lname1}
                                touched={detailsTouched.lname1}
                              />
                              <TextBox
                              label="Mobile"
                                placeholder="Mobile"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={detailsValues.mobileNumber}
                                onChange={detailsHandleChange}
                                onBlur={detailsHandleBlur}
                                error={detailsErrors.mobileNumber}
                                touched={detailsTouched.mobileNumber}
                                icon={<BiPhone height="100%" width="100%" />}
                              />
                              <TextBox
                              label="Email"
                                placeholder="Email"
                                id="email"
                                name="email"
                                value={detailsValues.email}
                                onChange={detailsHandleChange}
                                onBlur={detailsHandleBlur}
                                error={detailsErrors.email}
                                touched={detailsTouched.email}
                                icon={<BiEnvelope height="100%" width="100%" />}
                              />

                            </div>
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                  <div style={{ width: "100%" }}>
                    {updateTable && ownerShipLists.length != 0 ? (
                      <DataTable
                        value={ownerShipLists}
                        size={"normal"}
                        tableStyle={{ minWidth: "20rem" }}
                        dataKey=""
                        rowClassName="custom-row"
                      >
                        <Column
                          field="Name"
                          header="Name"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="Email"
                          header="Email"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="Phone"
                          header="Phone"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="isPrimary"
                          header="Primary/Secondary"
                          className="common-table-style"
                          headerClassName="centered-header"
                          body={(rowData) => (
                            <RatingStar
                              totalStars={1}
                              initialRating={rowData.isPrimary ? 1 : 0}
                              onRatingChange={(newRating) =>
                                handleRatingChange(newRating, rowData.ownerId)
                              }
                            />
                          )}
                        ></Column>
                      </DataTable>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="property-details-buttons">
                <button className="pdb-cancel" type="reset" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="pdb-save-changes" type="submit">
                  Save
                </button>
              </div>
            </form>
          ) : null}
        </TabPane>
        <TabPane tab="Rent Details" key="2" disabled={isTabDisabled}>
        <form onSubmit={rentHandleSubmit}>
          <div className="property-details-content df-all all-input">
            <div className="pddpn-group pd-address">
              <div className="contacts-group">
                  <TextBox
                    label={"Rent"}
                    placeholder={"Rent"}
                    name="Rent"
                    value={rentValues.Rent}
                    onChange={rentHandleChange}
                    onBlur={rentHandleBlur}
                    error={rentErrors.Rent}
                    touched={rentTouched.Rent}
                    icon={
                      <BiDollar height="100%" width="100%" />
                    }
                  />
                <SelectBox
                  label={"Period"}
                  placeholder="Period"
                  name="Period"
                  options={periodData}
                  value={rentValues.Period }
                  onChange={(val) =>
                    rentSetFieldValue("Period", val.value)
                  }
                  onBlur={rentHandleBlur}
                  error={rentErrors.Period }
                  touched={rentTouched.Period }
                />
                <SelectBox
                label={"Rent Include Tax"}
                  placeholder="Rent Include Tax"
                  name="RentIncludeTax"
                  options={rentIncludeTaxData}
                  value={rentValues.RentIncludeTax}
                  onChange={(val) =>
                    rentSetFieldValue("RentIncludeTax", val.value)
                  }
                  error={rentErrors.RentIncludeTax}
                  touched={rentTouched.RentIncludeTax}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                <TextBox
                label={"Bond Amount"}
                  placeholder="Bond Amount"
                  name="BondAmount"
                  value={rentValues.BondAmount}
                  onChange={rentHandleChange}
                    onBlur={rentHandleBlur}
                  error={rentErrors.BondAmount}
                  touched={rentTouched.BondAmount}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Bond In Trust"}
                  placeholder="Bond In Trust"
                  name="BondInTrust"
                  value={rentValues.BondInTrust}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.BondInTrust}
                  touched={rentTouched.BondInTrust}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                  label="Agreement Start"
                  name="AgreementStart"
                  type="date"
                  value={rentValues.AgreementStart}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.AgreementStart}
                  touched={rentTouched.AgreementStart}
                />
                 <TextBox
                  label="Agreement End"
                  name="AgreementEnd"
                  type="date"
                  value={rentValues.AgreementEnd}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.AgreementEnd}
                  touched={rentTouched.AgreementEnd}
                />
                 <TextBox
                 label={"Deposited"}
                  placeholder="Deposited"
                  name="Deposited"
                  value={rentValues.Deposited}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.Deposited}
                  touched={rentTouched.Deposited}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Balance"}
                  placeholder="Balance"
                  name="Balance"
                  value={rentValues.Balance}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.Balance}
                  touched={rentTouched.Balance}
                  icon={
                    <BiDollar height="100%" width="100%" />
                  }
                />
                 <TextBox
                 label={"Rent Review Frequency"}
                  placeholder="Rent Review Frequency"
                  name="RentReviewFrequency"
                  value={rentValues.RentReviewFrequency}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.RentReviewFrequency}
                  touched={rentTouched.RentReviewFrequency}
                />
                <SelectBox
                label={"Is Commercial"}
                  placeholder="Is Commercial "
                  name="IsCommercial "
                  options={isCommercialData}
                  value={rentValues.IsCommercial }
                  onChange={(val) =>
                    rentSetFieldValue("IsCommercial", val.value)
                  }
                  error={rentErrors.IsCommercial }
                  touched={rentTouched.IsCommercial }
                />
                 <TextBox
                 label={"ABN"}
                  placeholder="ABN"
                  name="ABN"
                  value={rentValues.ABN}
                  onChange={rentHandleChange}
                  onBlur={rentHandleBlur}
                  error={rentErrors.ABN}
                  touched={rentTouched.ABN}
                />
              </div>
            </div>
          </div>
          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
          </form>

        </TabPane>
      </Tabs>

    </div>
  );
};

export default TenantPropertyAgency;
