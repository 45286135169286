import React from "react";
import Select from "react-select";
import { useField, useFormikContext, useFormik } from "formik";

const CustomSelect = ({ label, options, handleChange, ...props }) => {

  const handleselectionChange = (selectedOption) => {
    handleChange(selectedOption.value, props.name);
  };

  return (
    <div>
      <Select
        id={props.name}
        {...props}
        options={options}
        onChange={handleselectionChange}
        value={options.find((option) => option.value === props.value)}
        name={props.name}
      />
    </div>
  );
};

export default CustomSelect;
