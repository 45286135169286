


import React, {useRef } from "react";
import './ForgotPasswordClass.css';
import { useFormik } from 'formik';
import axios from 'axios';
import { forgotSchema } from '../Schema/schema';
import { userForgotPassword } from '../apiresources';
import { useNavigate } from 'react-router-dom';
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";

const initialValues ={
    email:"", 
  };
const ForgotPassword = () => {
  const navigate=useNavigate();
  const ToastRef = useRef();

  const {errors, values, touched,   handleBlur, handleChange, handleSubmit}  = useFormik({
    initialValues: initialValues, 
    validationSchema: forgotSchema,
    onSubmit: (values, action)=>{
      console.log(values);
      handleForgetSubmit();
      // action.resetForm();
    }
})

const handleForgetSubmit=()=>{
  const apiUrl = userForgotPassword;

 

  // Request body with login information

  const loginData = {

    Email: values.email

  }

  axios.post(apiUrl, loginData)

    .then((response) => {
      const data  = email;

      if (response.data.status == 1) {
        ToastRef.current.notify(["success", response.data.message]);
        setTimeout(() => {
          navigate('/check?email=' + values.email);
        }, 2000); // Adjust the delay time as needed (2000ms = 2 seconds)
      }else {
        ToastRef.current.notify(["error",response.data.message]);

        //alert(`Message: ${response.data.message}`);

      }

    })

    .catch((error) => {

      console.error('Error:', error);

    });
}


return (
<div className="col-12 section">
<div className='col-xs-12 col-sm-12 col-md-10 col-lg-3 container'>
    <div className="header">
    <ToastAlert ref={ToastRef} />

    <img
      className="logo-forealproperty-one-color2"
      alt=""
      src=".\assets\images\logo.png"
    />
    </div>
    <div className="content-fp">

        <div className="content2">
            <div className="text-and-supporting-text">
                <div className="icon"><img src='.\assets\images\key.png' className='icon_img'/></div>
                <div className="text">Forgot Password</div>
            </div>
        </div>
        <div className="content1">
            <form className='form' onSubmit={handleSubmit}>
                <div className="input-field">
                    <label htmlFor="email">Email*</label>
                    <input  type="email" 
                            className='email form-control' 
                            placeholder='Enter your email' 
                            id='email'
                            name='email' 
                            value={values.email} 
                            onChange={handleChange} 
                            onBlur={handleBlur} 
                    />
                    {errors.email && touched.email ?<p className="form-error">{errors.email}</p>: null}
                </div>
                
                <div className="submit">
                    <button type='submit'className="button1" >Reset Password</button>
                </div>
                <div className='signup'>
                    <a href="/signin" className='a'><img src='.\assets\images\left_arrow.png'/>   Back to Log in</a>
                </div>
            </form>
        </div>
        <div className="footerspace"><p></p></div>
    </div>
    <div className="footer">
      <div className="text12">© FOREAL PROPERTY 2077</div>
    </div>
    </div>
    </div>
);
}
    
export default ForgotPassword 