import React, { useEffect, useState } from 'react';
import './collapse.css'; // Import the CSS file for styling

const CustomCheckbox = ({ labelName, onChange,defaultChecked,error ,touched , name}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

useEffect(() => {
    setIsChecked(defaultChecked)
},[defaultChecked])

  const toggleCheckbox = () => {
    const newValue = isChecked === null ? true : isChecked === true ? false : null;
    setIsChecked(newValue);
    onChange(newValue); // Call the onChange function with the value
  };
  return (
    <div>
    <label name={name} className={`custom-checkbox ${isChecked === true ? 'checked-green' : isChecked === false ? 'checked-red' : ''}`} onClick={toggleCheckbox}>
      <span className="checkmark"><span style={{color:'white'}}>{isChecked === true ? 'Y' : isChecked === false ? 'N' : ''}</span><span style={{marginBottom:'-60px'}}>    {error && touched ? <p className="form-error">{error}</p> : null}
</span></span>
    </label>
    </div>
  );
};

export default CustomCheckbox;
