import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../../components/CommonSection/Header/Header";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import AddOrSelectProperty from "./addNewProperty"
import LandlordDetails from "./landlordDetails";
import RentDetails from "./rent";
import ImpInfo from "./importantInformation";
import Signature from "./signature";
import Summary from "../summary"
import { CreateTenancyAgreement,gettenantagreementforsign } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus, eTenancyAgreementStatus } from "../../../../Utilities/enum";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";

const AgreementTabs = ({ handleparentsuccess }) => {
    const [visiblet, setVisiblet] = useState(true);
    const [topicValue, setTopicValue] = useState("Properties");
    const [activeKey, setActiveKey] = useState("PropertyDetails");
    const [getDetails, setGetDetails] = useState(null);
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [dpdata, setDpdata] = useState([]);
    const [userId, setUserId] = useState("");
    const [activeTopic, setActiveTopic] = useState("ListAgreement");
    const ToastRef = useRef();
    const navigate = useNavigate();
    const [agencyID, setAgencyId] = useState("");
    const [HandleNextPages, setHandleNextPages] = useState('')

    const handleDataChild = (value) => {
        GetApi(value);
    };

    const handleTabChange = (key) => {
        setActiveKey(key);
    };

    useEffect(() => {
        var url = new URL(window.location.href);
        var propertyUId = url.searchParams.get("propertyUID");
        var activeTab = url.searchParams.get("activeTab");
        //GetApi("d759a50547714");
        if (activeTab) {
            setActiveKey(activeTab);
        }
        if (propertyUId) {
            GetApi();
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, [activeKey]);

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");

                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);

                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        var url = new URL(window.location.href);
        var did = url.searchParams.get("did");
        var utype = url.searchParams.get("utype");
        var ref = url.searchParams.get("ref");
        var activeTab = url.searchParams.get("activeTab");
        if (activeTab) {
          setActiveKey(activeTab);
        }
        GetApi(did, utype, ref);
      }, []);


      const GetApi = (did, utype, ref) => {
        let formData = {
          DocumentId: did,
          UserType: utype,
          Reference: ref,
        };
        const url = gettenantagreementforsign;
        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          url,
          "post",
          formData,
          null,
          false
        )
          .then((response) => {
            debugger;
            if (response.object !== null) {
              setGetDetails(response.object);
              const refInt = parseInt(ref);
              const document = response.object.documentReceipients.find(
                (doc) => doc.contactId === refInt
              );
              if (document) {
                if (document.isSigned) {
                  setHandleNextPages("summary");
                }
              }
            }
          })
          .catch((error) => {
            //handleparentsuccess(["error", error]);
          });
      };

    const createManagementAgreement = (data) => {
        let formData = {
            PropertyUId: data,
            AgencyId: agencyID,
        };
        const url = CreateTenancyAgreement;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setGetDetails(returnData.object);

                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleToastMessage = (toastdata) => {
        ToastRef.current.notify(toastdata);
    };

    const handleNextPage = (value) => {
        setHandleNextPages(value)
    }

    return (
        <>
            <ToastAlert ref={ToastRef} />
            <div className="dashboard-agency">
                <Header />
                <div
                    className="blank_space"
                    style={{
                        backgroundColor: "white",
                        height: "80px",
                        padding: "10px",
                    }}
                >
                    <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
                </div>
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div
                            className="pa-content-top-level"
                            onClick={() => setVisiblet(true)}
                        >
                            <div className="text7" style={{ fontSize: "24px", display: 'flex', justifyContent: 'center', textDecoration: 'underline' }}>Residential tenancy agreement</div>
                            {HandleNextPages === '' ? <AddOrSelectProperty
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null}
                            {/* {HandleNextPages === 'LandlordDetails' ? <LandlordDetails
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null} */}
                            {/* {HandleNextPages === 'rentDetails' ? <RentDetails
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null} */}
                            {/* {HandleNextPages === 'impInfo' ? <ImpInfo
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null} */}
                             {HandleNextPages === 'sign' ? <Signature
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null}
                            {HandleNextPages === 'summary' ? <Summary
                                onDataRecive={handleDataChild}
                                handleparentsuccess={handleToastMessage}
                                handleCurrentPage={handleTabChange}
                                getDetails={getDetails}
                                reportTo={dpdata}
                                handleNextPage={handleNextPage}
                            /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgreementTabs;
