import React from "react";
import { Form, Switch as AntSwitch } from "antd";
import "./collapse.css";

const ToggleButtonGroup = ({
  mainLabel,
  switches,
  labels,
  onChange,
  onBlur,
  isMandatory,
  colval,
}) => {
  let colvalue =
    colval === "4"
      ? "col-lg-4 col-xl-4"
      : colval === "8"
      ? "col-lg-8 col-xl-8"
      : colval === "12"
      ? "col-lg-12 col-xl-12"
      : "col-lg-5 col-xl-12";

  return (
    <div className={`lda-${name} ${colvalue}`}>
      <div className="toggle-button-group">
        <Form.Item name="formSwitchGroup">
          <div className="switch-main-label">
            {mainLabel}{" "}
            {isMandatory ? <span style={{ color: "red" }}>*</span> : null}
          </div>
          <div className="toggle-switch-row">
            {switches.map((switchData, index) => (
              <div key={index} className="toggle-switch">
                <AntSwitch
                  id={`switch`}
                  checked={switchData.value}
                  onChange={() => onChange(index)}
                  onBlur={onBlur ? () => onBlur(index) : undefined}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
                <label
                  htmlFor={`switch-${index}`}
                  className="form-check-label ml-3"
                >
                  {labels && labels[index]
                    ? labels[index]
                    : "Label not provided"}
                </label>
              </div>
            ))}
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

export default ToggleButtonGroup;
