import { useCallback, useState, useEffect, useRef } from "react";
import "./verifytenant.css";
// import '../SignIn/temp.css'
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import Select from 'react-select';
import { GetPendingProfiletenant, apiUrlver, baseUrl1 } from "../../CommonSection/apiresources";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";

const Verifytenant = () => {
  const [valueo, setValueo] = useState(null);
  const [valuet, setValuet] = useState(null);
  const navigate = useNavigate();
  const [userId, setUserID] = useState('');
  const [userUId, setUserUId] = useState('');
  const [propAddress, setPropAddress] = useState();
  const [propertyUID, setPropertyUID] = useState();
  const [propId, setPropId] = useState('');
  const [rentAppId, setRentAppId] = useState();
  const [id, setId] = useState('');

  const [fnameo, setfnameo] = useState("");
  const [mnameo, setmnameo] = useState("")
  const [lnameo, setlnameo] = useState("")
  const [numbero, setnumbero] = useState("")
  const [expiryDateo, setExpiryDateo] = useState("")

  const [fnamet, setfanmet] = useState("");
  const [lnamet, setlnamet] = useState("");
  const [numbert, setnumbert] = useState("");
  const [seq, setSeq] = useState("");
  const [expiryDatet, setExpiryDatet] = useState("");


  const options1 = [
    { value: 1, label: 'License (Driving)' },
    { value: 2, label: 'Passport' }
  ]
  const options2 = [
    { value: 1, label: 'Medical (Health)' },
    { value: 2, label: 'BankCard' }
  ]

  const [data, setData] = useState([]);
  let progressData = [
    {
        label: "Property Details",
        url: "/propertydetails",
        isSelected: false,
        isCompleted: false
    },
    {
        label: "Address History",
        url: "/addresshistory",
        isSelected: false,
        isCompleted: false
    },
    {
        label: "Verification",
        url: "/verify",
        isSelected: true,
        isCompleted: false
    },
    {
        label: "Employment",
        url: "/employment",
        isSelected: false,
        isCompleted: false
    },
    {
        label: "Questionnaire",
        url: "/questionnaire",
        isSelected: false,
        isCompleted: false
    },
    {
        label: "Documentation",
        url: "/documentation",
        isSelected: false,
        isCompleted: false
    },
    {
        label: "Consent",
        url: "/consent",
        isSelected: false,
        isCompleted: false
    },]



  // ***********************************************************************************************************
  useEffect(() => {

    const retrieveData = async () => {

      try {

        const userData = GetCookie('@UserData');
        if (userData) {

          const parsedData = JSON.parse(userData)
          setUserUId(parsedData.userUnique_ID);
          setUserID(parsedData.userId);

        }

      } catch (error) {

        console.error('Error retrieving data from AsyncStorage:', error);

      }

    };

    retrieveData();

  }, [userId]);

  useEffect(() => {
    if (propertyUID !=='') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getapi();
      }, 2)
    }

  }, [propertyUID]);



  const getapi =  () => {

    const baseUrl = GetPendingProfiletenant;
    setPropertyUID("b4df6e16b5b74");
    // Request body with login information
    setData(progressData);
    const propertyId = propertyUID;
    const userId = userUId;
    const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
    console.log('API URL:', apiUrl);
    console.log(`PropertyId: ${propertyId}`);

     axios.get(apiUrl)

      .then(response => {

        // Handle the response data
        console.log(response.data);
        if (response.data.status == 1) {
          // console.log('some data');
          // console.log(response.data.object);
          setPropId(response.data.object.propertyId);
          setPropAddress(response.data.object.propertyStreetadress);
          setRentAppId(response.data.object.rentApplicationId);
          console.log(propId)

        } else {

          alert(`Message: ${response.data.message}`);

          console.log(response.data)

        }

      })

      .catch(error => {

        console.error('Error:', error);

      });

  }


  const handlePrimaryDoc = () => {
    const apiUrl = baseUrl1;

    // Request body with login information

    const VerifyData = {
      UserId: userId,
      Id: rentAppId,
      PrimaryDocumentType: valueo.value,
      PrimaryDocFirstName: fnameo,
      PrimaryDocMidName: mnameo,
      PrimaryDocLastName: lnameo,
      PrimaryDocNumber: numbero,
      PrimaryDocExpiry: expiryDateo,
      SecondaryDocumentType: valuet.value,
      SecondaryDocFirstName: fnamet,
      SecondaryDocMidName: lnamet,
      SecondaryDocLastName: numbert,
      SecondaryDocNumber: seq,
      SecondaryDocExpiry: expiryDatet,
    };

    axios.post(apiUrl, VerifyData)

      .then((response) => {

        // Handle the response from the backend

        if (response.data.status == 1) {

          navigate('/addresshistory');
          console.log("Success");
          alert('User added data Successfully');

        } else {

          alert(`Message: ${response.data.message}`);
          console.log(response.data)

        }

      })

      .catch((error) => {

        console.error('Error:', error);

      });



  }
  // ***********************************************************************************************************

  return (
    <div className="add-property" >
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '90px',

        }}>
          <Header />


        </div>
        <div className="progress-ico">
            {data && <ProgressbarLandlord message={data}/>}
           </div>

        <div className="verify-id">{`Verify Id`}</div>
        <form className="verfify-input" >

          <label htmlFor="s1">Choose one option</label>
          <Select options={options1} placeholder="Select any Document" defaultValue={valueo} id="s1" onChange={setValueo} />
          {valueo && <div className="details">
            <input type="text" className="input-v" placeholder="First Name" name='fnameo' id='fnameo' value={fnameo} onChange={(text) => setfnameo(text.target.value)} />
            <input type="text" className="input-v" placeholder="Middle Name" value={mnameo} onChange={(text) => setmnameo(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Last Name" value={lnameo} onChange={(text) => setlnameo(text.target.value)} required />
            <input type="number" className="input-v" placeholder="Number" value={numbero} onChange={(text) => setnumbero(text.target.value)} required />
            <input type="date" className="input-v" placeholder="Expiry" value={expiryDateo} onChange={(text) => setExpiryDateo(text.target.value)} required />
          </div>}
          <label htmlFor="s2">Choose one option</label>
          <Select options={options2} placeholder="Select any Document" defaultValue={valuet} id="s2" onChange={setValuet} />
          {valuet && <div className="details">
            <input type="text" className="input-v" placeholder="First Name" value={fnamet} onChange={(text) => setfanmet(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Last Name" value={lnamet} onChange={(text) => setlnamet(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Number" value={numbert} onChange={(text) => setnumbert(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Seq Number" value={seq} onChange={(text) => setSeq(text.target.value)} required />
            <input type="date" className="input-v" placeholder="Expiry" value={expiryDatet} onChange={(text) => setExpiryDatet(text.target.value)} required />
          </div>}
        </form>
        <button className="button-v" type="submit" onClick={handlePrimaryDoc}>
          <div className="text-parent">
            <div className="text7">Verify</div>
          </div>

        </button>
      </div>
      <Footer />

    </div>
  );
};

export default Verifytenant;
