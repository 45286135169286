import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatesalesagrmntrepairsdetails} from "../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import debounce from "lodash/debounce";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { RepairsAndMaintainanceValidation } from "../../../../components/CommonSection/Schema/schema";
import Collapse from "../../../input-components/collapse";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { BiDollar } from "react-icons/bi";
import CustomCheckbox from "../../../input-components/customCheckbox";
import ShowValue from "../../../input-components/ShowValue";
dayjs.extend(customParseFormat);

const initialValues = {
  TradesmanPayment: "",
  SupplyUrgentRepairToTenant: null,
  LooseFillAsbestosInstallation: null,
  SwimmingPoolRegistered: null,
  SmokeAlarm: null,
  HealthIssues: null,
  Flooding: null,
  Bushfire: null,
  SeriousViolentCrime: null,
  ParkingRestriction: null,
  ShareDriveway: null,
  AffectdFlooding: null,
  AffectdBushfire: null,
  AffectdSeriousViolent: null,
  IsContractForSalePremises: null,
  IsProposelToSell: null,
  HasMortgageeCommenced: null,
  isMortgageeTakgActnPossssion: null,
  WaterEfficiencyByNSW: null,
  PrincipalWarrantsSmokeAlarm: null,
};

const RepairsAndMaintainance = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("TradesmanPayment", getDetails.tradesmanPayment);
      setFieldValue(
        "SupplyUrgentRepairToTenant",
        getDetails.supplyUrgentRepairToTenant
      );
      setFieldValue(
        "LooseFillAsbestosInstallation",
        getDetails.looseFillAsbestosInstallation
      );
      setFieldValue(
        "SwimmingPoolRegistered",
        getDetails.swimmingPoolRegistered
      );
      setFieldValue("SmokeAlarm", getDetails.smokeAlarm);
      setFieldValue("HealthIssues", getDetails.healthIssues);
      setFieldValue("Flooding", getDetails.flooding);
      setFieldValue("Bushfire", getDetails.bushfire);
      setFieldValue("SeriousViolentCrime", getDetails.seriousViolentCrime);
      setFieldValue("ParkingRestriction", getDetails.parkingRestriction);
      setFieldValue("ShareDriveway", getDetails.shareDriveway);
      setFieldValue("AffectdFlooding", getDetails.affectdFlooding);
      setFieldValue("AffectdBushfire", getDetails.affectdBushfire);
      setFieldValue("AffectdSeriousViolent", getDetails.affectdSeriousViolent);
      setFieldValue(
        "IsContractForSalePremises",
        getDetails.isContractForSalePremises
      );
      setFieldValue("IsProposelToSell", getDetails.isProposelToSell);
      setFieldValue("HasMortgageeCommenced", getDetails.hasMortgageeCommenced);
      setFieldValue(
        "isMortgageeTakgActnPossssion",
        getDetails.isMortgageeTakgActnPossssion
      );
      setFieldValue("WaterEfficiencyByNSW", getDetails.waterEfficiencyByNSW);
      setFieldValue(
        "PrincipalWarrantsSmokeAlarm",
        getDetails.principalWarrantsSmokeAlarm
      );
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: RepairsAndMaintainanceValidation,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      TradesmanPayment: values.TradesmanPayment,
      SupplyUrgentRepairToTenant: values.SupplyUrgentRepairToTenant,
      LooseFillAsbestosInstallation: values.LooseFillAsbestosInstallation,
      SwimmingPoolRegistered: values.SwimmingPoolRegistered,
      SmokeAlarm: values.SmokeAlarm,
      HealthIssues: values.HealthIssues,
      Flooding: values.Flooding,
      Bushfire: values.Bushfire,
      SeriousViolentCrime: values.SeriousViolentCrime,
      ParkingRestriction: values.ParkingRestriction,
      ShareDriveway: values.ShareDriveway,
      AffectdFlooding: values.AffectdFlooding,
      AffectdBushfire: values.AffectdBushfire,
      AffectdSeriousViolent: values.AffectdSeriousViolent,
      IsContractForSalePremises: values.IsContractForSalePremises,
      IsProposelToSell: values.IsProposelToSell,
      HasMortgageeCommenced: values.HasMortgageeCommenced,
      isMortgageeTakgActnPossssion: values.isMortgageeTakgActnPossssion,
      WaterEfficiencyByNSW: values.WaterEfficiencyByNSW,
    };
    const url = updatesalesagrmntrepairsdetails;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleNextPage("AgreementSignature");
          ToastRef.current.notify(["success", returnData.message]);
          //navigate(`/agency/ListOpenHome`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
    //handleNextPage("AgreementSignature");
  };

  const handleClear = () => {
    handleNextPage("PromotionalActivities");
  };

  const onChangeSwitch = (e, label) => {
    if (label === "TradesmanPayment") {
      setFieldValue("TradesmanPayment", e);
    } else if (label === "SupplyUrgentRepairToTenant") {
      setFieldValue("SupplyUrgentRepairToTenant", e);
    } else if (label === "LooseFillAsbestosInstallation") {
      setFieldValue("LooseFillAsbestosInstallation", e);
    } else if (label === "SwimmingPoolRegistered") {
      setFieldValue("SwimmingPoolRegistered", e);
    } else if (label === "SmokeAlarm") {
      setFieldValue("SmokeAlarm", e);
    } else if (label === "HealthIssues") {
      setFieldValue("HealthIssues", e);
    } else if (label === "Flooding") {
      setFieldValue("Flooding", e);
    } else if (label === "Bushfire") {
      setFieldValue("Bushfire", e);
    } else if (label === "SeriousViolentCrime") {
      setFieldValue("SeriousViolentCrime", e);
    } else if (label === "ParkingRestriction") {
      setFieldValue("ParkingRestriction", e);
    } else if (label === "ShareDriveway") {
      setFieldValue("ShareDriveway", e);
    } else if (label === "AffectdFlooding") {
      setFieldValue("AffectdFlooding", e);
    } else if (label === "AffectdBushfire") {
      setFieldValue("AffectdBushfire", e);
    } else if (label === "AffectdSeriousViolent") {
      setFieldValue("AffectdSeriousViolent", e);
    } else if (label === "IsContractForSalePremises") {
      setFieldValue("IsContractForSalePremises", e);
    } else if (label === "IsProposelToSell") {
      setFieldValue("IsProposelToSell", e);
    } else if (label === "HasMortgageeCommenced") {
      setFieldValue("HasMortgageeCommenced", e);
    } else if (label === "isMortgageeTakgActnPossssion") {
      setFieldValue("isMortgageeTakgActnPossssion", e);
    } else if (label === "WaterEfficiencyByNSW") {
      setFieldValue("WaterEfficiencyByNSW", e);
    }
    else if (label === "WaterEfficiencyByNSW") {
      setFieldValue("WaterEfficiencyByNSW", e);
    }
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  const handleCheckboxChange = (value, label) => {
    if (label === "TradesmanPayment") {
      setFieldValue("TradesmanPayment", value);
    } else if (label === "SupplyUrgentRepairToTenant") {
      setFieldValue("SupplyUrgentRepairToTenant", value);
    } else if (label === "LooseFillAsbestosInstallation") {
      setFieldValue("LooseFillAsbestosInstallation", value);
    } else if (label === "SwimmingPoolRegistered") {
      setFieldValue("SwimmingPoolRegistered", value);
    } else if (label === "SmokeAlarm") {
      setFieldValue("SmokeAlarm", value);
    } else if (label === "HealthIssues") {
      setFieldValue("HealthIssues", value);
    } else if (label === "Flooding") {
      setFieldValue("Flooding", value);
    } else if (label === "Bushfire") {
      setFieldValue("Bushfire", value);
    } else if (label === "SeriousViolentCrime") {
      setFieldValue("SeriousViolentCrime", value);
    } else if (label === "ParkingRestriction") {
      setFieldValue("ParkingRestriction", value);
    } else if (label === "ShareDriveway") {
      setFieldValue("ShareDriveway", value);
    } else if (label === "AffectdFlooding") {
      setFieldValue("AffectdFlooding", value);
    } else if (label === "AffectdBushfire") {
      setFieldValue("AffectdBushfire", value);
    } else if (label === "AffectdSeriousViolent") {
      setFieldValue("AffectdSeriousViolent", value);
    } else if (label === "IsContractForSalePremises") {
      setFieldValue("IsContractForSalePremises", value);
    } else if (label === "IsProposelToSell") {
      setFieldValue("IsProposelToSell", value);
    } else if (label === "HasMortgageeCommenced") {
      setFieldValue("HasMortgageeCommenced", value);
    } else if (label === "isMortgageeTakgActnPossssion") {
      setFieldValue("isMortgageeTakgActnPossssion", value);
    } else if (label === "WaterEfficiencyByNSW") {
      setFieldValue("WaterEfficiencyByNSW", value);
    }
    else if (label === "PrincipalWarrantsSmokeAlarm") {
      setFieldValue("PrincipalWarrantsSmokeAlarm", value);
    }
  };
  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                    <div>
                      <div
                        className="pddpn-group2 pddpn-group3"
                        
                      >
                        <div
                          className="agreementLabel mb-3"
                          style={{ paddingLeft: "0px" }}
                        >{`Repairs and Maintenance`}</div>
                        <div
                          className={`lda-${name} col-lg-12 col-sm-12`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {/* <ShowValue
                            Label="  The agent is authorised to engage a suitably
                                    qualified tradesperson to undertake repairs up to an
                                    amount of"
                            setData={values.TradesmanPayment}
                            agreement={true}
                            icon={<BiDollar height="100%" width="100%" />}

                          /> */}
                             <div className="address-pair">
                                    <div className="labelssss">The agent is authorised to engage a suitably
                                    qualified tradesperson to undertake repairs up to an
                                    amount of</div>
                                    <div className="valuessss">: {values.TradesmanPayment}</div>
                                  </div>
                        </div>
                      </div>
                    </div>

                    <div className="pddpn-group2 pddpn-group3">
                      <div className="pddpn-group">
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Loose Fill Asbestos Installation
                          </div>
                          <CustomCheckbox
                            labelName="LooseFillAsbestosInstallation"
                            error={errors.LooseFillAsbestosInstallation}
                            touched={touched.LooseFillAsbestosInstallation}
                            defaultChecked={
                              values.LooseFillAsbestosInstallation
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "LooseFillAsbestosInstallation"
                              )
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Smoke alarm
                          </div>
                          <CustomCheckbox
                            error={errors.SmokeAlarm}
                            touched={touched.SmokeAlarm}
                            defaultChecked={values.SmokeAlarm}
                            onChange={(e) =>
                              handleCheckboxChange(e, "SmokeAlarm")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Swimming pool registered
                          </div>
                          <CustomCheckbox
                              error={errors.SwimmingPoolRegistered}
                              touched={touched.SwimmingPoolRegistered}
                            defaultChecked={values.SwimmingPoolRegistered}
                            onChange={(e) =>
                              handleCheckboxChange(e, "SwimmingPoolRegistered")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Health issues
                          </div>
                          <CustomCheckbox
                          error={errors.HealthIssues}
                          touched={touched.HealthIssues}
                            defaultChecked={values.HealthIssues}
                            onChange={(e) =>
                              handleCheckboxChange(e, "HealthIssues")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pddpn-group2 pddpn-group3 mt-3">
                      <div
                        className="agreementLabel mb-3"
                        style={{ paddingLeft: "0px" }}
                      >{`In The Last 5 Years Has The Property Been Subject To`}</div>
                      <div className="pddpn-group">
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Flooding
                          </div>
                          <CustomCheckbox
                            error={errors.Flooding}
                            touched={touched.Flooding}
                            defaultChecked={values.Flooding}
                            onChange={(e) =>
                              handleCheckboxChange(e, "Flooding")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Bushfire
                          </div>
                          <CustomCheckbox
                              error={errors.Bushfire}
                              touched={touched.Bushfire}
                            defaultChecked={values.Bushfire}
                            onChange={(e) =>
                              handleCheckboxChange(e, "Bushfire")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Serious violent crime
                          </div>
                          <CustomCheckbox
                                error={errors.SeriousViolentCrime}
                                touched={touched.SeriousViolentCrime}
                            defaultChecked={values.SeriousViolentCrime}
                            onChange={(e) =>
                              handleCheckboxChange(e, "SeriousViolentCrime")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pddpn-group2 pddpn-group3 mt-3">
                      <div
                        className="agreementLabel mb-3"
                        style={{ paddingLeft: "0px" }}
                      >{`Affected by`}</div>
                      <div className="pddpn-group">
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Parking Restrictions
                          </div>
                          <CustomCheckbox
                               error={errors.ParkingRestriction}
                               touched={touched.ParkingRestriction}
                            defaultChecked={values.ParkingRestriction}
                            onChange={(e) =>
                              handleCheckboxChange(e, "ParkingRestriction")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Share driveways
                          </div>
                          <CustomCheckbox
                               error={errors.ShareDriveway}
                               touched={touched.ShareDriveway}
                            defaultChecked={values.ShareDriveway}
                            onChange={(e) =>
                              handleCheckboxChange(e, "ShareDriveway")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Serious violent crime
                          </div>
                          <CustomCheckbox
                               error={errors.AffectdSeriousViolent}
                               touched={touched.AffectdSeriousViolent}
                            defaultChecked={values.AffectdSeriousViolent}
                            onChange={(e) =>
                              handleCheckboxChange(e, "AffectdSeriousViolent")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Flooding
                          </div>
                          <CustomCheckbox
                               error={errors.AffectdFlooding}
                               touched={touched.AffectdFlooding}
                            defaultChecked={values.AffectdFlooding}
                            onChange={(e) =>
                              handleCheckboxChange(e, "AffectdFlooding")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Bushfire
                          </div>
                          <CustomCheckbox
                               error={errors.AffectdBushfire}
                               touched={touched.AffectdBushfire}
                            defaultChecked={values.AffectdBushfire}
                            onChange={(e) =>
                              handleCheckboxChange(e, "AffectdBushfire")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Is there a contract for the sale of the premises
                          </div>
                          <CustomCheckbox
                               error={errors.IsContractForSalePremises}
                               touched={touched.IsContractForSalePremises}
                            defaultChecked={values.IsContractForSalePremises}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "IsContractForSalePremises"
                              )
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Is there a proposal to sell the premises
                          </div>
                          <CustomCheckbox
                               error={errors.IsProposelToSell}
                               touched={touched.IsProposelToSell}
                            defaultChecked={values.IsProposelToSell}
                            onChange={(e) =>
                              handleCheckboxChange(e, "IsProposelToSell")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Has a mortgagee commenced proceedings in court to
                            enforce a mortgage over the premises
                          </div>
                          <CustomCheckbox
                               error={errors.HasMortgageeCommenced}
                               touched={touched.HasMortgageeCommenced}
                            defaultChecked={values.HasMortgageeCommenced}
                            onChange={(e) =>
                              handleCheckboxChange(e, "HasMortgageeCommenced")
                            }
                          />
                        </div>
                        <div
                          className="pd-eventDate col-lg-5 col-sm-12 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div style={{ width: "100%" }} className="mr-5 s1">
                            Is the mortgagee taking action for possession of the
                            property
                          </div>
                          <CustomCheckbox
                               error={errors.isMortgageeTakgActnPossssion}
                               touched={touched.isMortgageeTakgActnPossssion}
                            defaultChecked={values.isMortgageeTakgActnPossssion}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "isMortgageeTakgActnPossssion"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pddpn-group2 pddpn-group3 mt-3">
                      <div
                        className=" agreementLabel mb-3"
                        style={{ paddingLeft: "0px" }}
                      >{`Water Efficiency Devises`}</div>
                      <div className="s1">The agent is authorized to:</div>
                      <div>
                        <div
                          className=""
                          style={{ display: "flex", width: "100%" }}
                        >
                          <div className="mr-5 s1" style={{ width: "100%" }}>
                            The premises contain the water efficiency measures
                            prescribed by the Residential Tenancies Act 2010
                            (NSW)
                          </div>
                          <CustomCheckbox
                            error={errors.WaterEfficiencyByNSW}
                            touched={touched.WaterEfficiencyByNSW}
                            defaultChecked={values.WaterEfficiencyByNSW}
                            onChange={(e) =>
                              handleCheckboxChange(e, "WaterEfficiencyByNSW")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pddpn-group2 pddpn-group3 mt-3">
                      <div
                        className="agreementLabel mb-3"
                        style={{ paddingLeft: "0px" }}
                      >{`Smoke Alarms`}</div>
                      <div>
                        <div
                          className=""
                          style={{ display: "flex", width: "100%" }}
                        >
                          <div className="mr-5 s1" style={{ width: "100%" }}>
                            The Principal warrants that the Premises comply with
                            the Environmental Planning and Assessment Amendment
                            (Smoke Alarms) Regulation 2006 (NSW)
                          </div>
                          <CustomCheckbox
                            error={errors.PrincipalWarrantsSmokeAlarm}
                            touched={touched.PrincipalWarrantsSmokeAlarm}
                            defaultChecked={values.PrincipalWarrantsSmokeAlarm}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                "PrincipalWarrantsSmokeAlarm"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Save & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairsAndMaintainance;
