import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatesalesagrmntremunerationdetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {dynamicUrl,} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Checkbox } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { salesRemunation } from "../../../components/CommonSection/Schema/schema";
import { MdPercent } from "react-icons/md";
import { Switch } from "antd";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);


const initialValues = {
    SalePriceFlatFee:'',
    LicenceeEstimateSalePrice:'',
    GSTInclusiveRenumeration:'',
    IsEFT:'',
    PAccountName:'',
    PBSB:'',
    PAccountNo:'',
    Gst : false,
};

const Remunation = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();

    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("SalePriceFlatFee", getDetails.salePriceFlatFee)
          setFieldValue("LicenceeEstimateSalePrice",getDetails.licenceeEstimateSalePrice)
          setFieldValue("GSTInclusiveRenumeration",getDetails.gstInclusiveRenumeration)
          setFieldValue("IsEFT",getDetails.isEFT)
          setFieldValue("PAccountName",getDetails.pAccountName)
          setFieldValue("PBSB",getDetails.pbsb)
          setFieldValue("PAccountNo",getDetails.pAccountNo)
          setFieldValue("Gst",getDetails.gst)
        }
      }, [getDetails, reportTo]);

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: salesRemunation,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            SalePriceFlatFee: parseFloat(values.SalePriceFlatFee),
            LicenceeEstimateSalePrice: parseFloat(values.LicenceeEstimateSalePrice),
            GSTInclusiveRenumeration: parseFloat(values.GSTInclusiveRenumeration),
            IsEFT: values.IsEFT ? 1 : 2,
            PAccountName: values.PAccountName,
            PBSB: values.PBSB,
            PAccountNo: values.PAccountNo,
            gst : values.Gst
        };
        const url = updatesalesagrmntremunerationdetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("ExpensesAndCharges")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleClear = () => {
        handleNextPage("AgreementPeriod")
    };

      const handleCancle = () => {
        navigate("/agency/ListAgreement");
      }

      const onChangeSwitch = (e, label) => {
        setFieldValue("IsEFT", e);
    };

    const handleChangeSale = (e) => {
        const value = e.target.value;
        setFieldValue("SalePriceFlatFee", value);
        if(values.Gst){
            let mainValue = getDetails.sellingFixedPrice 
            let estimatePrice = (mainValue * value / 100)
            setFieldValue("LicenceeEstimateSalePrice",estimatePrice.toFixed(2))
            let gstInclusiveRemuneration = calculateGSTInclusive(mainValue, value, 9.1);
            setFieldValue("GSTInclusiveRenumeration", gstInclusiveRemuneration);
        }else{
            let mainValue = getDetails.sellingFixedPrice 
            let estimatePrice = (mainValue * value / 100)
            setFieldValue("LicenceeEstimateSalePrice",estimatePrice.toFixed(2))
            setFieldValue("GSTInclusiveRenumeration", estimatePrice.toFixed(2));
        }

    }

    function calculateGSTInclusive(originalAmount, percentage, gstRate) {
        let initialRemuneration = (originalAmount * percentage) / 100;
        let gstDecimal = gstRate / 100;
        let gstAmount = initialRemuneration * gstDecimal;
        let gstInclusiveAmount = initialRemuneration + gstAmount;
        
        return gstInclusiveAmount.toFixed(2);
    }

    const handleGst = (e) => {
        setFieldValue("Gst",e)
        if(e){
            let mainValue = getDetails.sellingFixedPrice 
            let estimatePrice = (mainValue * values.SalePriceFlatFee / 100)
            setFieldValue("LicenceeEstimateSalePrice",estimatePrice.toFixed(2))
            let gstInclusiveRemuneration = calculateGSTInclusive(mainValue, values.SalePriceFlatFee , 9.1);
            setFieldValue("GSTInclusiveRenumeration", gstInclusiveRemuneration);
        }else if (!e){
            let mainValue = getDetails.sellingFixedPrice 
            let estimatePrice = (mainValue * values.SalePriceFlatFee / 100)
            setFieldValue("LicenceeEstimateSalePrice",estimatePrice.toFixed(2))
            setFieldValue("GSTInclusiveRenumeration", estimatePrice.toFixed(2));
        }
    }   
      useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input" style={{width:'100%'}}>
                            
                                        <div style={{width:'100%'}}>
                                            <Collapse
                                                id={"Fees and Charges"}
                                                title={"REMUNERATION"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                         

                                                        {/* <div className='s1'>The agent shall be entitled to a leasing fee of 1 week. If Principal/client cancels or back-off from market they shell be liable to pay 1 weeks rent</div> */}
                                                        <TextBox
                                                        colVal={6}
                                                        isMandatory
                                                            label="(e.g. % of sale price; flat fee; formula e.g. a combination of % and flat fee)"
                                                            name="SalePriceFlatFee"
                                                            value={values.SalePriceFlatFee}
                                                            onChange={handleChangeSale}
                                                            onBlur={handleBlur}
                                                            error={errors.SalePriceFlatFee}
                                                            touched={touched.SalePriceFlatFee}
                                                        />
                                                        <div className="pd-eventDate col-lg-5 col-sm-12 mt-3 pl-3 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="s1" style={{ marginRight: '20px' }} >Include GST</div>
                                                                <div><Switch checked={values.Gst} onChange={handleGst}/></div>
                                                            </div>
                                                        </div>
                                                        <TextBox
                                                        isMandatory
                                                        colVal={6}

                                                            label="e.g. in relation to the Licensee’s ESTIMATE of the sale price of"
                                                            name="LicenceeEstimateSalePrice"
                                                            value={values.LicenceeEstimateSalePrice}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.LicenceeEstimateSalePrice}
                                                            touched={touched.LicenceeEstimateSalePrice}
                                                        />
                                                        <TextBox
                                                        isMandatory
                                                        colVal={6}

                                                            label="the GST inclusive renumeration would be"
                                                            name="GSTInclusiveRenumeration"
                                                            value={values.GSTInclusiveRenumeration}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.GSTInclusiveRenumeration}
                                                            touched={touched.GSTInclusiveRenumeration}
                                                        />
                                                    </div>
                                                } />
                                                  <Collapse
                                                id={"If the property is leased either:"}
                                                title={"Payment to Principal"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className="mb-3">
                                                            <div className="s1">If money held by the Agent becomes due to the principal,then the Principal directs the Agent to pay that money by</div>
                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div className="s1" style={{ marginRight: '50px' }} >Electronic Funds Transfer (EFT):</div>
                                                                    <div><Switch checked={values.IsEFT} onChange={(e) => onChangeSwitch(e, "PropOccupation")}/></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="s1">if by EFT:</div>
                                                        <TextBox
                                                            label="Account Name"
                                                            name="PAccountName"
                                                            value={values.PAccountName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.PAccountName}
                                                            touched={touched.PAccountName}
                                                        />
                                                        <TextBox
                                                            label="BSB"
                                                            name="PBSB"
                                                            value={values.PBSB}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.PBSB}
                                                            touched={touched.PBSB}
                                                        />
                                                        <TextBox
                                                            label="Account No"
                                                            name="PAccountNo"
                                                            value={values.PAccountNo}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.PAccountNo}
                                                            touched={touched.PAccountNo}
                                                        />
                                                    </div>
                                                } />
                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Remunation;
