import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../../Components/openHome/openHome.css";
import "../../ManagementAgreement/agreement.css";

import { FilePath, tenantagreementsignature, generateTenantAgreementPdfReport } from "../../../../components/CommonSection/apiresources";
import TextBox from "../../../input-components/textbox";
import { makeRequestWithSpinner, uploadToS3WithSpinner, DownloadFiletWithSpinner, uploadSignToS3WithSpinner } from "../../../../Utilities/client";
import { ActionStatus, signatureStatus } from "../../../../Utilities/enum";
import Collapse from "../../../input-components/collapse";
import SignatureCanvas from "react-signature-canvas";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import Verify from "../../../../Utilities/verify"
import TextArea from "../../../input-components/textarea";
import { BiDollar } from "react-icons/bi";
import { useScrollToFirstError } from "../../../../Utilities/ScrollToErrorField";


dayjs.extend(customParseFormat);

const initialValues = {
    primaryfName: '',
    primarylName: '',
    primaryMobileNum: '',
    primaryEmail: '',
    getAgentFullName: '',
    agencyLicenceNumber: '',
    agencyAddress: '',
    mobileNumber: '',
    agentEmail: '',
    address: '',
    fax: '',
    Name: '',
    BusinessName: '',
    LicenseNumber: '',
    Address: '',
    Mobile: '',
    emailAddress: '',
    agentName: '',



    AgreementDate: "",
    LeasePremisesAtMarketRent: "",
    ReferToPrincipalForReLease: "",
    ReviewRentBfrRLease: "",
    PropertyImages: [],
    SignedDate: "",
    AgentSignedDate: "",
};


const PartiesPrinciples = ({ getDetails, handleNextPage1 }) => {
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [propertyimageitems, setPrincipleimageitems] = useState([]);
    const [principleImages, setPrincipleImages] = useState();
    const [secondaryOwner, setSecondaryOwner] = useState([]);
    const inputRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);
    const signatureRef = useRef(null);
    const [sign, setSign] = useState(null);
    const [IsSigned, setIsSigned] = useState(null);
    const [date, setDate] = useState("");
    const [verified, setVerified] = useState(false)
    const [signatureText, setSignatureText] = useState("");
    const { showSpinner, hideSpinner } = useSpinnerContext();

    useEffect(() => {
        console.log(getDetails, 'getDetails')
        if (getDetails) {
            setFieldValue("primaryfName", getDetails.primaryContact.fName);
            setFieldValue("primarylName", getDetails.primaryContact.lName);
            setFieldValue("primaryMobileNum", getDetails.primaryContact.mobileNumber);
            setFieldValue("primaryEmail", getDetails.primaryContact.contactEmail);
            setFieldValue("getAgentFullName", getDetails.getAgentFullName);
            setFieldValue("companyName", getDetails.companyName);
            setFieldValue("agencyLicenceNumber", getDetails.agencyLicenceNumber);
            setFieldValue("agencyAddress", getDetails.agencyAddress);
            setFieldValue("agentEmail", getDetails.agentEmail);
            setFieldValue("fax", getDetails.fax);
            setFieldValue("Name", getDetails.principalName);
            setFieldValue("BusinessName", getDetails.tradingName);
            setFieldValue("LicenseNumber", getDetails.agencyLicenceNumber);
            setFieldValue("Address", getDetails.agencyAddress);
            setFieldValue("Mobile", getDetails.mobile);
            setFieldValue("fax", getDetails.fax);
            setFieldValue("emailAddress", getDetails.agencyEmail);
            setFieldValue("agentName", getDetails.agencyName);
            setSecondaryOwner(getDetails.tenants ? getDetails.tenants : []);
        }
    }, [getDetails]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const clearSignature = () => {
        signatureRef.current.clear();
    };

    // Function to get the signature data as an image URL
    const getSignatureImage = () => {
        if (activeIndex === 1) {
            handleFinalSubmit();
        } else {
            setSign(signatureRef.current.toDataURL());
            handleSignUpload(signatureRef.current.toDataURL());
            return signatureRef.current.toDataURL();
        }
    };

    useEffect(() => {
        // Set default value after component mounts
        const matchedObject = getDetails?.documentReceipients ? getDetails.documentReceipients.filter((doc) => utype === "3" ? doc.agentId : doc.contactId === parseInt(ref)) : null;

        setIsSigned(matchedObject?.length > 0 ? matchedObject[0].isSigned : false);
        if (signatureRef.current && matchedObject[0].signValue) {
            signatureRef.current.clear(); // Clear the canvas
            // Draw the saved signature
            const ctx = signatureRef.current.getCanvas().getContext("2d");
            const image = new Image();
            image.src = matchedObject[0].signValue;
            image.onload = () => {
                ctx.drawImage(image, 0, 0);
            };
        }
    }, [activeIndex]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    useEffect(() => {
        const today = new Date();
        setFieldValue("SignedDate", today);
        setDate(today.toISOString().split("T")[0]); // Set the default value to yesterday's date
    }, []);


    useEffect(() => {
        const url = new URL(window.location.href);
        let ref = url.searchParams.get("ref");
        let utype = url.searchParams.get("utype");
        setVerified(utype === "3" ? true : false);
        if (getDetails) {
            setValues({ ...values, ...getDetails });
            setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
            setFieldValue(
                "ReferToPrincipalForReLease",
                getDetails.referToPrincipalForReLease
            );
            setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
            setFieldValue(
                "LeasePremisesAtMarketRent",
                getDetails.leasePremisesAtMarketRent
            );
            setSecondaryOwner(getDetails.contactlistModel);
            const matchedObject = getDetails.documentReceipients.filter(
                (doc) => doc.contactId === parseInt(ref)
            );
            setFieldValue(
                "SignedDate",
                formatDate(matchedObject.length > 0 ? matchedObject[0].signedDate : "")
            );
            function formatDate(dateString) {
                if (!dateString) {
                    return "";
                }
                const [year, month, day] = dateString.split("T")[0].split("-");
                return `${year}-${month}-${day}`;
            }
            let contacts = getDetails.contactlistModel
                ? getDetails.contactlistModel
                : [];
            const matchedObjects =
                contacts.length === 0
                    ? []
                    : getDetails.contactlistModel.filter(
                        (doc) => doc.contactId === parseInt(ref)
                    );
            if (getDetails.primarycontactModel?.contactId === parseInt(ref)) {
                setVerified(getDetails.primarycontactModel.isUserVerified);
            } else if (contacts.length != 0) {
                if (
                    matchedObjects[0] != null &&
                    matchedObjects[0].contactId === parseInt(ref)
                ) {
                    setVerified(matchedObjects[0].isUserVerified);
                }
            }
        }
    }, [getDetails]);

    const isVerified = () => {
        setVerified(true)
    }

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (principleImages) => {
        const urls = new URL(window.location.href);
        let ref = urls.searchParams.get("ref");
        let signMethods =
            activeIndex + 1 === signatureStatus.Image
                ? 1
                : activeIndex + 1 === signatureStatus.Type
                    ? 2
                    : activeIndex + 1 === signatureStatus.Draw
                        ? 3
                        : "";
        let formData = {
            DocumentId: parseInt(did),
            ContactId: utype === "13" ? ref : null,
            AgentId: utype === "3" ? ref : null,
            SignedDate: moment(),
            SignMethod: signMethods,
            SignPicturePath: principleImages,
            SignValue: activeIndex === 0 ? sign : activeIndex === 1 ? signatureText : null,
        };

        const url = tenantagreementsignature;
        makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            url,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    setIsSigned(true);
                    ToastRef.current.notify(["success", returnData.message]);
                    setIsSigned(true);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleClear = () => {
        handleNextPage("RepairAndMaintainance");
    };

    const textWithBullet = "\u2022 ";

    const select = (event) => {
        inputRef.current.click();
    };
    const handleSignUpload = async (imgByte) => {
        const url = new URL(window.location.href);
        let ref = url.searchParams.get("ref");
        await uploadSignToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "signatures/",
            `contact_${ref}`,
            imgByte
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                };
                setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
                setPrincipleImages(returnData.Key);
                handleFinalSubmit(returnData.Key);
                handleparentsuccess(["success", "Sign uploaded successfully", true]);
            } else {
                handleparentsuccess([
                    "error",
                    "Error uploading file. Please try again.",
                ]);
            }
        });
    };
    const handleUplodeImg = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            selectedFile = e.target.files[i];
            await handleUpload();
        }
    };

    const handleUpload = async () => {
        const url = new URL(window.location.href);
        let ref = url.searchParams.get("ref");
        await uploadToS3WithSpinner(
            showSpinner,
            hideSpinner,
            "signatures/",
            `contact_${ref}`,
            selectedFile
        ).then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
                const transformedItem = {
                    id: Math.floor(Math.random() * 1000000),
                    src: FilePath + returnData.Key,
                    width: 1,
                    height: 1,
                };
                setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
                setPrincipleImages(returnData.Key);
                handleparentsuccess(["success", "File uploaded successfully", true]);
                handleFinalSubmit(returnData.Key);
            } else {
                handleparentsuccess([
                    "error",
                    "Error uploading file. Please try again.",
                ]);
            }
        });
    };

    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    let did = url.searchParams.get("did");
    let utype = url.searchParams.get("utype");

    const isRefMatched = getDetails?.documentReceipients?.some(
        (doc) => doc.contactId === parseInt(ref)
    );


    const handleAgreementDownload = () => {
        const formData = {
            PropertyUId: getDetails.propertyUId,
        };
        DownloadFiletWithSpinner(
            showSpinner,
            hideSpinner,
            generateTenantAgreementPdfReport,
            "post",
            formData,
            null,
            false
        )
            .then((returnData) => {
                if (returnData != null) {
                    window.open(returnData, "_blank");
                } else {
                    ToastRef.current.notify(["error", "Internal Server Error"]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handlePrevious = () => {
        handleNextPage("RepairAndMaintainance");
    };

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${day}-${month}-${year}`;
    }

    const handleSignature = (e) => {
        setSignatureText(e.target.value)
    }

    useScrollToFirstError(isValid, errors, submitCount);
    return (
        <>
            <div className="dashboard-agency" style={{ width: "100%" }}>
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content" style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="">
                                {getDetails ?
                                    <div className="property-details-content">
                                        <div style={{ width: '100%' }}>
                                            {!IsSigned ?
                                                <div>
                                                    <div className="pddpn-group2 pddpn-group3">

                                                        <div className=" agreementLabel mt-4">{`Primary Property Owner(s)`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">First Name</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.primaryContact.fName}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Last Name</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.primaryContact.lName}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Mobile Number</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.primaryContact.mobileNumber}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Email Address</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.primaryContact.contactEmail}</div>
                                                            </div>
                                                        </div>
                                                        {getDetails
                                                            ? getDetails.contactlistModel.map((contact, index) => (
                                                                <div style={{ width: '100%' }} >
                                                                    <div className="agreementLabel">{`Secondary Owner (${index + 1})`}</div>
                                                                    <div className={`lda-${name} `} >
                                                                        <div className="address-pair">
                                                                            <div className="labelssss">Name</div>
                                                                            <div style={{ marginTop: '3px' }}>:</div>
                                                                            <div className="valuessss"> {contact.fName + " " + contact.lName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`lda-${name} `} >
                                                                        <div className="address-pair">
                                                                            <div className="labelssss">Email</div>
                                                                            <div style={{ marginTop: '3px' }}>:</div>
                                                                            <div className="valuessss"> {contact.contactEmail}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`lda-${name} `} >
                                                                        <div className="address-pair">
                                                                            <div className="labelssss">Mobile Number</div>
                                                                            <div style={{ marginTop: '3px' }}>:</div>
                                                                            <div className="valuessss"> {contact.mobileNumber}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`lda-${name} `} >
                                                                        <div className="address-pair">
                                                                            <div className="labelssss">Address</div>
                                                                            <div style={{ marginTop: '3px' }}>:</div>
                                                                            <div className="valuessss"> {contact.getFullAddress}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </div>

                                                    {getDetails.tenants
                                                        ? getDetails.tenants.map((contact, index) => (
                                                            <div className="pddpn-group2 pddpn-group3">
                                                                <div className=" agreementLabel mt-4">{`Tenant (${index + 1})`}</div>

                                                                <div className={`lda-${name} `} >
                                                                    <div className="address-pair">
                                                                        <div className="labelssss">Name</div>
                                                                        <div style={{ marginTop: '3px' }}>:</div>
                                                                        <div className="valuessss"> {contact.getFullName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className={`lda-${name} `} >
                                                                    <div className="address-pair">
                                                                        <div className="labelssss">Email</div>
                                                                        <div style={{ marginTop: '3px' }}>:</div>
                                                                        <div className="valuessss"> {contact.tenantEmail}</div>
                                                                    </div>
                                                                </div>
                                                                <div className={`lda-${name} `} >
                                                                    <div className="address-pair">
                                                                        <div className="labelssss">Mobile Number</div>
                                                                        <div style={{ marginTop: '3px' }}>:</div>
                                                                        <div className="valuessss"> {contact.tenantMobile}</div>
                                                                    </div>
                                                                </div>
                                                                <div className={`lda-${name} `} >
                                                                    <div className="address-pair">
                                                                        <div className="labelssss">Address</div>
                                                                        <div style={{ marginTop: '3px' }}>:</div>
                                                                        <div className="valuessss"> {contact.tenantAddress}</div>
                                                                    </div>
                                                                </div>
                                                            </div>))
                                                        : null}
                                                </div> : null}
                                            <div className="pddpn-group2 pddpn-group3">
                                                {!IsSigned ?
                                                    <div>
                                                        <div className=" agreementLabel mt-4">{`Agent`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Name</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentName}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Business Name</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordBusinessAddress}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">License Number</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.agencyLicenceNumber}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Address </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.agencyAddress}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Mobile  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.mobile}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">fax  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.fax}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Email address </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.agentEmail}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name}`} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Agent Name </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.agentFirstName + ' ' + getDetails.agentLastName}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Landlord's agent details [if applicable]`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Agent Name</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentName}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Business Address</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordBusinessAddress}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Subrub</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentSuburb}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">State </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentState}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `}>
                                                            <div className="address-pair">
                                                                <div className="labelssss">PostCode </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentPostcode}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Contact Details</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordAgentContact}</div>
                                                            </div>
                                                        </div>

                                                        <div className="agreementLabel mb-3 mt-4">{`Property (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified.`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Address </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.propertyAddress}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Subrub </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.propertySuburb}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">State </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.propertyState}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">PostCode  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.propertyPostCode}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `}>
                                                            <div className="address-pair">
                                                                <div className="labelssss">The residential premises include </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.otherSpace}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Term of agreement`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Starting On </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {formatDate(getDetails.agreementStart)}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Ending On </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {formatDate(getDetails.agreementEnd)}</div>
                                                            </div>
                                                        </div>

                                                        <div className="agreementLabel mb-3 mt-4">{`Rent`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">The rent is</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"><BiDollar /> {getDetails.rentAmount}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Period</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.period === 1 ? "Weekly" : "Monthly"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Payable in advance starting on</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {formatDate(getDetails.leaseCommencement)}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">A/C Name  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.accountName}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `}>
                                                            <div className="address-pair">
                                                                <div className="labelssss">BSB  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.bsb}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">A/C No </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.accountNo}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Payment reference </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.paymentReference}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">to </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.paymentTo}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">at </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.paymentAt}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">as follows </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.paymentFollows}</div>
                                                            </div>
                                                        </div>

                                                        <div className="agreementLabel mb-3 mt-4">{`Rental Bondrental Bond`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">A rental bond of </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"><BiDollar/> {getDetails.bondAmount}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">The tenant provided the rental bond amount to</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.bondAmountProvidedTo}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Maximum number of occupants`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">No more than </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.maximumTenant}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Urgent repairs`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Electrical Repair</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.electricalRepair}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Telephone </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.electricalRepairContact}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Plumbing repairs </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.plumbingRepair}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Telephone </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.plumbingRepairContact}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `}>
                                                            <div className="address-pair">
                                                                <div className="labelssss">Other repairs </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.otherRepair}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Telephone</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.otherRepairContact}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Water usage`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Will the tenant be required to pay separately for water usage?</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.payWaterBillSeperately ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Utilities`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Is electricity supplied to the premises from an embedded network</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.isElectricitySupplied ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Is gas supplied to the premises from an embedded network?</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.isGasSupplied ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Rental Bondrental Bond`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">premises are hardwired or battery operated  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.smokeAlarmType ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">If the smoke alarms are battery operated, are the batteries in the smoke alarms of a kind the tenant can replace? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.smokeAlarmBatteryReplacable ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">If yes, specify the type of battery that needs to be used if the battery in the smoke alarm needs to be replaced </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.batteryType}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">If the smoke alarms are hardwired, are the back-up batteries in the smoke alarms of a kind the tenant can replace?  </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.backupBatteryReplacable ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `}>
                                                            <div className="address-pair">
                                                                <div className="labelssss">If yes, specify the type of back-up battery that needs to be used if the back-up battery in the smoke alarm needs to be replaced</div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.typeOfBackupBattery}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">If the Strata Schemes Management Act 2015 applies to the residential premises, is the owners corporation of the strata scheme responsible for the repair and replacement of smoke alarms in the residential premises? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.strataRsponsibleForReplacement ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Are there any strata or community scheme by-laws applicable to the residential premises? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.strataByLawsApplicable ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Landlord`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Does the landlord give express consent to the electronic service of notices and documents? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.doesLandlordConsentElectronic ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Are there any strata or community scheme by-laws applicable to the residential premises? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.landlordElctrcConsentEmail}</div>
                                                            </div>
                                                        </div>
                                                        <div className="agreementLabel mb-3 mt-4">{`Tenant`}</div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Does the tenant give express consent to the electronic service of notices and documents? </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.doesTenantConsentElectronic ? "Yes" : "No"}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Specify email address to be used for the purpose of serving notices and documents. </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.tenantElctrcConsentEmail}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Pet Details </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.petDetails}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} `} >
                                                            <div className="address-pair">
                                                                <div className="labelssss">Additional Terms </div>
                                                                <div style={{ marginTop: '3px' }}>:</div>
                                                                <div className="valuessss"> {getDetails.additionalComments}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div >
                                                    <div style={{ width: "100%" }}>
                                                        {/* {!verified ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>     <Verify getDetails={getDetails} isVerified={isVerified} verificationType={1} /></div> : null} */}
                                                        {!IsSigned ? (
                                                            <div >
                                                                <div>
                                                                <div className="agreementLabel mb-3 mt-4">{`Repairs and Maintenance`}</div>
                                                                            <div >
                                                                                <div className="s1">
                                                                                    {textWithBullet}The Agent will be indemnified from
                                                                                    any claims or actions, suits, legal costs or
                                                                                    demands taken against the Principal(s) named in
                                                                                    this agreement and the Agent will not be liable
                                                                                    for any claims made against the Principal(s) due
                                                                                    to any actions, omissions or statements made or
                                                                                    not made by the Principal(s).
                                                                                </div>
                                                                                <div className="s1 mt-3">
                                                                                    {textWithBullet}The Principal acknowledges that
                                                                                    the Agent is not a Financial and Investment
                                                                                    Advisor and any financial advice would be general
                                                                                    advice and the Principal should seek their own
                                                                                    financial advice.
                                                                                </div>
                                                                                <div className="s1 mt-3">
                                                                                    {textWithBullet}The Principal acknowledges that
                                                                                    the Property Stock and Business Agents Act
                                                                                    requires the Agent to disclose all Material Facts
                                                                                    relating to the property throughout the
                                                                                    transaction and warrants that all materials facts
                                                                                    have been disclosed to the best of their
                                                                                    knowledge.
                                                                                </div>
                                                                                <div className="s1 mt-3">
                                                                                    {textWithBullet}The Principal acknowledges that at
                                                                                    all times the Principal has sole control of the
                                                                                    property and that the Agent acts under the
                                                                                    instructions of the Principal during the sale of
                                                                                    the property. The Principal is responsible for the
                                                                                    Work Health and Safety requirements of the
                                                                                    property.
                                                                                </div>
                                                                                <div className="s1 mt-3">
                                                                                    {textWithBullet}The Principal acknowledges that by
                                                                                    signing this agreement the Principal is denied the
                                                                                    right to lease the Property without paying a fee
                                                                                </div>
                                                                            </div>

                                                                    {isRefMatched || utype === "3" ? (
                                                                        <div>
                                                                            <div className="agreementLabel mb-3 mt-4">{`Privacy Statement`}</div>

                                                                            <div>
                                                                                <div >
                                                                                    <div
                                                                                        className="s1"
                                                                                        style={{ fontWeight: "600" }}
                                                                                    >
                                                                                        All information collected by the Agent is done
                                                                                        so with respect of the Privacy Act 1998 and
                                                                                        the Privacy Principals.
                                                                                    </div>
                                                                                    <div className="s1 mt-3">
                                                                                        {textWithBullet}Information collected is
                                                                                        necessary for one or more of the activities
                                                                                        associated with this agreement.
                                                                                    </div>
                                                                                    <div className="s1 mt-3">
                                                                                        {textWithBullet}The Agent will not use or
                                                                                        disclose your personal information for another
                                                                                        purpose, unless it would be reasonable to
                                                                                        expect such disclosure is necessary for the
                                                                                        completion of activities associated with this
                                                                                        agreement.
                                                                                    </div>
                                                                                    <div className="s1 mt-3">
                                                                                        {textWithBullet}The Agent will take all
                                                                                        reasonable steps to ensure that the personal
                                                                                        information collected, used or disclosed is
                                                                                        accurate, complete and up-to-date.
                                                                                    </div>
                                                                                    <div className="s1 mt-3">
                                                                                        {textWithBullet}The Agent will take reasonable
                                                                                        steps to protect the personal information it
                                                                                        holds from misuse and loss and from
                                                                                        unauthorised access, modification or
                                                                                        disclosure and also take reasonable steps to
                                                                                        destroy or permanently de-identify personal
                                                                                        information it no longer needs.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                           
                                                                    ) : null}
                                                                    {isRefMatched || utype === "3" ? (

                                                                                <div>
                                                                                     <div className="agreementLabel mb-3 mt-4">{`Signature of Tenant(s)`}</div>

                                                                                    <div
                                                                                        className="mt-2"
                                                                                        style={{ padding: "0px 30px", display: "flex" }}
                                                                                    >
                                                                                        <div
                                                                                            className="card"
                                                                                            style={{ display: "flex" }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{ padding: "10px 10px 0px 20px" }}
                                                                                                    className="s1"
                                                                                                    htmlFor="name"
                                                                                                >
                                                                                                    Signature{" "}
                                                                                                </div>
                                                                                                <TextBox
                                                                                                    colVal="sm-6"
                                                                                                    type="date"
                                                                                                    name="SignedDate"
                                                                                                    placeholder="dd/mm/yyyy"
                                                                                                    value={date}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    error={errors.SignedDate}
                                                                                                    touched={touched.SignedDate}
                                                                                                />
                                                                                            </div>

                                                                                            <div>
                                                                                                <TabView
                                                                                                    activeIndex={activeIndex}
                                                                                                    onTabChange={(e) =>
                                                                                                        setActiveIndex(e.index)
                                                                                                    }
                                                                                                >

                                                                                                    <TabPanel header="Draw">
                                                                                                        <div className="tc-sign ">
                                                                                                            <div className="sign-tc">
                                                                                                                <SignatureCanvas
                                                                                                                    penColor="black"
                                                                                                                    ref={signatureRef}
                                                                                                                    canvasProps={{
                                                                                                                        width: "300",
                                                                                                                        height: 75,
                                                                                                                        className: "signature-canvas",
                                                                                                                    }}
                                                                                                                />
                                                                                                               
                                                                                                            </div>
                                                                                                            <div
                                                                                                                onClick={clearSignature}
                                                                                                                className="tc-b3"
                                                                                                            >
                                                                                                                Clear
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </TabPanel>
                                                                                                    <TabPanel header="Text">
                                                                                                        <div className="tc-sign ">
                                                                                                            <TextArea colVal={12} label={"Signature"} value={signatureText} onChange={handleSignature} />
                                                                                                            <div
                                                                                                                onClick={clearSignature}
                                                                                                                className="tc-b3 ml-3"
                                                                                                            >
                                                                                                                Clear
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </TabPanel>
                                                                                                </TabView>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                          
                                                                    ) : null}


                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        {IsSigned ? (
                                                            <div className="signTxt">
                                                                <div>Thank you</div>
                                                                <div>your document has been signed</div>
                                                            </div>
                                                        ) : null}
                                                        <div className="property-details-buttons">
                                                            {!IsSigned ? (
                                                                <>
                                                                    <button
                                                                        className="pdb-save-changes"
                                                                        type="button"
                                                                        onClick={getSignatureImage}
                                                                    >
                                                                        {"Submit"}
                                                                    </button>
                                                                </>
                                                            ) : null}

                                                            {IsSigned ? <button
                                                                className="pdb-cancel"
                                                                type="button"
                                                                onClick={handleAgreementDownload}
                                                            >
                                                                {"Download Agreement"}
                                                            </button> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> : null}
                                {/* <div className="property-details-buttons">
                                    <button
                                        className="pdb-save-changes"
                                        type="button"
                                        onClick={handleFinalSubmit}
                                    >
                                        Next
                                    </button>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartiesPrinciples;
