import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { assignedTo,SearchProperty,AddTask} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { Button } from "primereact/button";
import SelectBox from "../../../src/input-components/selectBoxNew";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { Select, Spin , Divider} from "antd";
import debounce from "lodash/debounce";
import { addNewTask } from "../../../components/CommonSection/Schema/schema";
import Collapse from "../../../src/input-components/collapse";

const initialValues = {
    assignedTo:"",
    taskName: "",
    relatedToProperty:false,
    isRecuring:false,
    taskPriorityIndex:"",
    taskDesc:"",
    recurringType:"",
    DataTime:""
};

const AddNewTask = ({getDetails,}) => {
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [agencyUID, setAgencyUID] = useState("");
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("ListTask");
    const [topicValue, setTopicValue] = useState("Profile");
    const [selectedCity, setSelectedCity] = useState(null);
    const [dpdata, setDpdata] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValues] = useState("");
    const [agentId,setAgentId]= useState("");
    const fetchRef = useRef(0);
    const property_For = [
        { value: 1, label: "High" },
        { value: 2, label: "Medium" },
        { value: 3, label: "Low" }
      ];

      const recurringType = [
        { value: 1, label: "Weekly" },
        { value: 2, label: "Monthly" },
        { value: 3, label: "Yearly" }
      ];
    const ToastRef = useRef();


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID);
                    setAgentId(parsedData.agentId);
                    reportData(parsedData.agencyUniqueID);
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (getDetails) {
            setValues({ ...values, ...getDetails });
        }
    }, [getDetails]);

    const reportData = (agencyUID) => {
        const apiUrl = assignedTo;
        const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
        axios.get(baseUrl).then((response) => {
          if (response.data.object) setDpdata(response.data.object);
        });
      };

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: initialValues,
        validationSchema: addNewTask,
        onSubmit: (values, action) => {
            AddTasks();
        },
    });


    const AddTasks = () => {
        let formData = {
            AsigneeId: parseInt(selectedCity, 10),
            AuthorId : userId,
            Description : values.taskDesc,
            DueDate : values.DataTime,
            IsRecuring : values.isRecuring,
            IsRelatedToProperty : values.relatedToProperty,
            Name : values.taskName,
            PriorityLevel : values.taskPriorityIndex,
            PropertyId : searchValue,
            RecuringType : values.recurringType
        };
        const url = AddTask;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    dynamicUrl(navigate, "ListTask");
                } else {
                    ToastRef.current.notify(["warn", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["warn", returnData.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const handleClear = () => {
        var urls = new URL(window.location.href);
        var OpenHomeUniqueId = urls.searchParams.get("OpenHomeUniqueId");
        resetForm();
        dynamicUrl(navigate, "OpenHomeDetails" + "?OpenHomeUniqueId="+OpenHomeUniqueId);
        ToastRef.current.notify(["warn",  "Changes are cancelled"]);
    }

    const handleAssignedTo = (e) => {
        setSelectedCity(e.value);
    
        setFieldValue("assignedTo", e.value);
      };

      const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
      };

      const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
          fetchRef.current += 1;
          const fetchId = fetchRef.current;
          setOptions([]);
          setFetching(true);
          const baseUrl = SearchProperty;
          const data = {
            Search: value,
            RecordsPerPage: 10,
            PageNo: 1,
          };
    
          makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            baseUrl,
            "post",
            data,
            "",
            false
          )
            .then((response) => {
              if (response.status === 1) {
                let landingPageListData = response.list;
    
                // Transform data to match Select options
                const transformedData = landingPageListData.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));
    
                // Update options and fetching state
                if (fetchId === fetchRef.current) {
                  setOptions(transformedData);
                  setFetching(false);
                }
              } else {
                setFetching(false);
              }
            })
            .catch((error) => {
              setFetching(false);
            });
        };
        return debounce(loadOptions, 800);
      }, []);
    
      const handleSearchProperty = (value) => {
        debounceFetcher(value);
        setSearchValues(value.value);
      };

      const handleClearProperty = () => {
        setSearchValues("");
      };
    return (
        <div className="dashboard-agency">
            <Header />
            <ToastAlert ref={ToastRef} />
            <div
                className="blank_space"
                style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
            ></div>
            <div className="dashboardagency-content">
                <Sidebar
                    visible={sidebarVisible}
                    handleTopicValue={handleTopicValue}
                    topicValue={activeTopic}
                />
                <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>


                    <div className="property-details-content df-all all-input">
                        <div className="contacts-group pd-address">
                            <div className="text-parent" style={{ display: 'block' }}>
                                <div className="text7" style={{ fontSize: "24px" }}>
                                    Add Task
                                </div>
                            </div>
                            <Divider />
                            <Collapse
                      id={"Task-Details"}
                      title={"Task-Details"}
                      data={
                            <div>
                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                    <div className="cda-reportTo col-lg-6 col-sm-12">
                                        <label htmlFor="trading state" className="s1 w-100">
                                            Assignee<span style={{ color: "red" }}>*</span>
                                        </label>

                                        <Dropdown
                                            value={selectedCity}
                                            onChange={handleAssignedTo}
                                            options={dpdata}
                                            optionLabel="text"
                                            className="w-100 md:w-14rem"
                                            placeholder="Select Assigned to"
                                        />

                                        {errors.assignedTo && touched.assignedTo ? (
                                            <p className="form-error">{errors.assignedTo}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="contacts-group" style={{ padding: "0px 30px" }}>
                                    <TextBox
                                        isMandatory
                                        label="Task date/time"
                                        type="date"
                                        placeholder=""
                                        id="Unit"
                                        name="DataTime"
                                        value={values.DataTime}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.DataTime}
                                        touched={touched.DataTime}
                                    />
                                    <div className="cda-AgentAddress col-lg-5 col-sm-12">
                                        <label htmlFor="AgentAddress" className="s1">
                                            Related to property
                                        </label>
                                        <div className="input-group mb-3">
                                            <Button
                                                type="button"
                                                label="Yes"
                                                className={
                                                    values.relatedToProperty === true
                                                        ? "custom-button-success"
                                                        : "p-button-secondary"
                                                }
                                                onClick={() => setFieldValue("relatedToProperty", true)}
                                                text
                                                raised
                                                style={{ borderRadius: "5px" }}
                                            />
                                            <Button
                                                type="button"
                                                label="No"
                                                className={
                                                    values.relatedToProperty === false
                                                        ? "custom-button-success"
                                                        : "p-button-secondary"
                                                }
                                                onClick={() => setFieldValue("relatedToProperty", false)}
                                                text
                                                raised
                                                style={{ borderRadius: "5px" }}
                                            />
                                        </div>
                                    </div>
                                    {values.relatedToProperty ?
                                        <div className=" col-12 col-lg-5 col-md-4 col-sm-4" >
                                            <label htmlFor="AgentAddress" className="s1">
                                                Property <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Select
                                                style={{ width: '100%' }}
                                                className="landingPageSearchs"
                                                mode="single"
                                                showSearch
                                                placeholder="Search for Property"
                                                labelInValue
                                                filterOption={false}
                                                onSearch={debounceFetcher}
                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                options={options}
                                                onChange={handleSearchProperty}
                                                allowClear
                                                onClear={handleClearProperty}
                                            />
                                        </div> : null}
                                    <div className="cda-AgentAddress col-lg-5 col-sm-12">
                                        <label htmlFor="AgentAddress" className="s1">
                                            Is Recuring
                                        </label>
                                        <div className="input-group mb-3">
                                            <Button
                                                type="button"
                                                label="Yes"
                                                className={
                                                    values.isRecuring === true
                                                        ? "custom-button-success"
                                                        : "p-button-secondary"
                                                }
                                                onClick={() => setFieldValue("isRecuring", true)}
                                                text
                                                raised
                                                style={{ borderRadius: "5px" }}
                                            />
                                            <Button
                                                type="button"
                                                label="No"
                                                className={
                                                    values.isRecuring === false
                                                        ? "custom-button-success"
                                                        : "p-button-secondary"
                                                }
                                                onClick={() => setFieldValue("isRecuring", false)}
                                                text
                                                raised
                                                style={{ borderRadius: "5px" }}
                                            />
                                        </div>
                                    </div>
                                    {values.isRecuring ?
                                        <SelectBox
                                            isMandatory
                                            label="Recuring Type"
                                            name="recuringType"
                                            options={recurringType}
                                            placeholder={""}
                                            value={values.recurringType}
                                            onChange={(val) => setFieldValue("recurringType", val.value)}
                                            error={errors.recurringType}
                                            touched={touched.recurringType}
                                        /> : null}
                                    <SelectBox
                                        label="Task Priority Index"
                                        name="propertyFor"
                                        options={property_For}
                                        placeholder={"Select Priority Level"}
                                        value={values.taskPriorityIndex}
                                        onChange={(val) => setFieldValue("taskPriorityIndex", val.value)}
                                        error={errors.taskPriorityIndex}
                                        touched={touched.taskPriorityIndex}
                                    />
                                    <TextBox
                                        isMandatory
                                        label="Task Name"
                                        placeholder="Task name"
                                        id="Unit"
                                        name="taskName"
                                        value={values.taskName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.taskName}
                                        touched={touched.taskName}
                                    />
                                    <div className="ea-profileDesc col-lg-8 col-sm-12">
                                        <label htmlFor="lnumber" className="s1">
                                            Task Description
                                        </label>
                                        <div className="input-group mb-3">
                                            <ReactQuill
                                                className="w-75 d-flex flex-column"
                                                theme="snow"
                                                value={sanitizeHTML(values.taskDesc)}
                                                onChange={(val) => setFieldValue("taskDesc", val)}
                                            />
                                        </div>
                                        {errors.profileDesc && touched.profileDesc ? (
                                            <p className="form-error">{errors.taskDesc}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>}
                            />
                        </div>

                    </div>
                      {/* }
                      /> */}
                    <div className="property-details-buttons">
                        <button
                            className="pdb-cancel"
                            type="reset"
                            onClick={handleClear}
                        >
                            Cancel
                        </button>
                        <button className="pdb-save-changes" type="submit">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddNewTask;
