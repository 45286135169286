import React, { useState, useRef, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { useNavigate } from "react-router-dom";
import ListAgreementType from "./ListAgreementType";
import ListAgreement from "./ListAgreement"

const MainActivities = () => {
    const ToastRef = useRef();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('contactActivities');
    const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const [topicValue, setTopicValue] = useState("Profile");

  useEffect(() => {
    const url = new URL(window.location.href);
    const page = url.searchParams.get('Page');
    if (page) {
      setActiveTab(page);
    }
  }, []);

  const handleTabChange = (tab) => {
    if (ToastRef.current) {
      ToastRef.current.clear();
    }
    setActiveTab(tab);
   
  };
  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  return (

    <div className="dashboard-agency">
    <Header />
    <div
      className="blank_space"
      style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
    ></div>
    <div className="continer-dbag">
      <ToastAlert ref={ToastRef} />
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
    
    <div className="p-2 mt-3" style={{width:'100%'}}>
    <Tabs activeKey={activeTab} onSelect={handleTabChange} className="mb-3 properties-nav-bar">
      <Tab eventKey="contactActivities" title="Create Agreement" key="1">
        <div style={{ width: '100%' }}>
          <ListAgreementType handleparentsuccess={handleToastMessage} />
        </div>
      </Tab>
      <Tab eventKey="AgreementList" title="Agreement List" key="2">
        <div style={{ width: '100%' }}>
          <ListAgreement handleparentsuccess={handleToastMessage} />
        </div>
      </Tab>
      {/* Add more tabs as needed */}
    </Tabs>
    </div>
      </div>
    </div>
  </div>
  );
};

export default MainActivities;
