import React from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import './collapse.css'; // Import your CSS file for styling

const Steps = ({ items }) => {
    return (
        <div className="steps-container">
            {items.map((item, index) => (
                <div className="step-item" key={index}>
                    <div className="step-icon">
                    <img src={item.img} alt="lp1" height='50px' width='50px'/>
                        </div>
                    <div className="step-icon">
                        
                    {index !== items.length - 1 && <div className="vertical-line"></div>}
                        <BiCheckCircle style={{fontSize:'24px'}} />
                    </div>
                    <div className="step-content">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Steps;
