import React, { useState, useEffect } from "react";
import "./HeaderLogo.css";
import { RemoveCookie, GetCookie, currentProfile } from "../Cookie/Session";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { FilePath, defaultProfilePic } from "../apiresources";

const Header = () => {
  const navigate = useNavigate();
  const [Toggle, settoggle] = useState(false);
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [profile, setProfile] = useState("");
  const [multipleProfile, setMultipleProfile] = useState([]);
  const [agentId, setAgentId] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setName(parsedData.firstName);
          setLName(parsedData.lastName);
          setProfile(parsedData.profilePicture);
          setAgentId(parsedData.agentId);
          setMultipleProfile(
            parsedData.userProfiles.map((profile) => profile.userRoleLabel)
          );
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [name]);

  const handleMenu = () => {
    setMenu(!menu);
  };
  const handleLogout = () => {
    RemoveCookie("@UserData");
  };

  const currentSideBar = currentProfile.get();

  const menuItems = [
    {
      name: currentSideBar === 2 ? "Agency Details" : "Agent Details",
      icon: "../../../assets/ProfileIcon/Profile.png",
      action: () => {
        if (multipleProfile.includes("Agency")) {
          navigate("/agency/profile");
          // console.log('Hello Agency')
        } else if (multipleProfile.includes("Agent")) {
          // console.log('Hello agent Profile')
          navigate("/agent/Profile" + "?value=" + agentId);
        }
      },
    },
    {
      name: currentSideBar === 2 ? "Agency" : "Agent",
      icon: "../../../assets/ProfileIcon/dashboard.png",
      icon1: "../../../assets/ProfileIcon/SwitchProfile.png",
      action: () => navigate("/agency/Dashboard"),
      action1: () => navigate("/SelectAddProfile"),
    },
    {
      name: "Change Password",
      icon: "../../../assets/ProfileIcon/Password.png",
      action: () => navigate("/forgot_password"),
    },
    // {
    //   name: "Setting",
    //   icon: "../../../assets/ProfileIcon/Setting.png",
    //   action: () => navigate(""),
    // },
    {
      name: "Logout",
      icon: "../../../assets/ProfileIcon/Logout.png",
      action: () => handleLogout(), // Replace with your logout logic
    },
  ];

  var auth = GetCookie("@UserData");
  // console.log(auth)

  const handleFindPropertySale = () => {
    navigate("/listLandingPage" + "?catId=1");
    window.location.reload();
  };

  const handleFindPropertyRent = () => {
    navigate("/listLandingPage" + "?catId=2");
    window.location.reload();
  };

  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div className="selectionlogo-forealproperty-one-color2-parent ">
        <img
          className="selectionlogo-forealproperty-one-color21"
          alt=""
          src="..\..\assets\images\logo.png"
        />
        <nav className="selectionframe-parent2">
          <div className="selectiontext-parent1">
            <div className="selectionadd-profile" onClick={handleHome}>
              Home
            </div>
            {/* <div className="selectionadd-profile" onClick={handleFindProperty}>Find Property</div> */}
            <div
              className="selectionadd-profile"
              onClick={handleFindPropertySale}
            >
              Find Sale
            </div>
            <div
              className="selectionadd-profile"
              onClick={handleFindPropertyRent}
            >
              Find Rental
            </div>
            {/* <div className='selectionadd-profile' onClick={handleprofile}>Profile</div> */}
            {/* <div className="selectionadd-profile">Listing</div>
            <div className="selectionadd-profile">Pricing</div>
            <div className="selectionadd-profile">Setting</div> */}
          </div>

          {auth ? (
            <div className="profileMenu">
              <div className="menu-detail">
                <div className="pdd-pic-view">
                  <img
                    src={
                      FilePath +
                      (profile != null && profile !== ""
                        ? profile
                        : defaultProfilePic)
                    }
                    alt="Profile Pic"
                    width={45}
                    height={45}
                    className="ProfilePic"
                  />
                </div>

                <div className="">
                  {name} {lName}
                </div>
                <div onClick={handleMenu}>
                  {menu ? (
                    <img
                      src="../../../assets/ProfileIcon/UpIcon.png"
                      alt=""
                      width={25}
                      height={25}
                      className="material-icons"
                    />
                  ) : (
                    <img
                      src="../../../assets/ProfileIcon/DownIcon.png"
                      alt=""
                      width={25}
                      height={25}
                      className="material-icons"
                    />
                  )}
                </div>
              </div>
              {menu && (
                <div className="CardMenu">
                  {menuItems.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="ItemMenu" onClick={item.action}>
                          <img
                            src={item.icon}
                            alt={item.name}
                            width={22}
                            height={22}
                            className="MenuIcon"
                          />
                          <div className="MenuName">{item.name}</div>
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={item.action1}
                        >
                          {item.icon1 && (
                            <img
                              src={item.icon1}
                              alt={item.name + "1"} // Adjusted alt attribute
                              width={22}
                              height={22}
                              className="MenuIcon"
                            />
                          )}
                        </span>
                      </div>
                      <div className="SingleLine"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="selectionnavigation-actions">
              <div className="selectionbutton1">
                <div
                  className="selectiontext5"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </div>
              </div>
              <div className="selectionbutton1">
                <div
                  className="selectiontext5"
                  onClick={() => RemoveCookie("@UserData")}
                >
                  Log In
                </div>
              </div>
            </div>
          )}

          {/* <div className="selectionnavigation-actions"> */}

          {/* {auth && <div className="selectionbutton1">
              <div className="selectiontext5" onClick={() => RemoveCookie("@UserData")}>Log Out</div>
            </div>}
            {!auth && <div className="selectionbutton1">
              <div className="selectiontext5" onClick={() => RemoveCookie("@UserData")}>Log In</div>
            </div>} */}
          {/* </div> */}
        </nav>
        <div className="hamburger">
          <a href="#" onClick={() => settoggle(!Toggle)}>
            <GiHamburgerMenu color="#1d2939" />
          </a>
        </div>
      </div>
      {Toggle && (
        <nav className="navbar1">
          <div className="navbartext">Home</div>
          <div className="navbartext">Find Property</div>
          {/* <div className='navbartext' onClick={handleprofile}>Profile</div> */}
          <div className="navbartext">Listing</div>
          <div className="navbartext">Pricing</div>
          <div className="navbartext">Setting</div>

          {auth && (
            <div
              className="navbartext"
              onClick={() => RemoveCookie("@UserData")}
            >
              Log Out
            </div>
          )}
          {!auth && (
            <div
              className="navbartext"
              onClick={() => RemoveCookie("@UserData")}
            >
              Log in
            </div>
          )}
        </nav>
      )}
    </>
  );
};

export default Header;
