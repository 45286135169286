import React from "react";
import { useState, useRef, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  BucketName,
  Region,
  AccessKey,
  Keyid,
  getProfileDetails,
  addPersonalDetailsProfile,
  FilePath,
  addBrandingProfileDetails,
  defaultProfilePic,
} from "../../CommonSection/apiresources";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import { dateChnager } from "../../CommonSection/Utility/Utility";
import {
  makeRequestWithSpinner,
  uploadToS3,
  uploadToS3WithSpinner,
} from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import ProfileImage from "../../CommonSection/ProfileImage/ProfileImage";
import TextBox from "../../../src/input-components/textbox";

const BradingDetailsProfile = ({ handleToastMessage, handleCurrentPage }) => {
  const initialValues = {
    name: "",
    logo_Path: "",
  };

  const { showSpinner, hideSpinner } = useSpinnerContext();

  const inputRef = useRef();
  const navigate = useNavigate();

  let selectedFile;
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [tempValue, setTempValue] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetAgencyDetails();
      }, 2);
    }
  }, [userUId]);

  const GetAgencyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getProfileDetails}?agencyUID=${agencyUniqueID}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let agencyDetails = returnData.object;
          setValues({ ...values, ...agencyDetails });
        } else {
          handleToastMessage(["warn", response.data.message]);
          console.log(response.data);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", error]);
        console.error("Error:", error);
      });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    action,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: addresshistorySchema,
    onSubmit: (values, action) => {
      submitBrandingDetails();
    },
  });

  const select = (event) => {
    inputRef.current.click();
  };

  const handleFileChange = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      // console.log(e.target.files[i], i);
      selectedFile = e.target.files[i];

      // setSelectedFile(e.target.files[i]);
      await handleUpload();
    }
  };

  const handleUpload = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "agency/",
      "logopic",
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        setFieldValue("logo_Path", returnData.Key);
        handleToastMessage(["success", "File uploaded successfully"]);
      } else {
        handleToastMessage([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const submitBrandingDetails = () => {
    // Request body with login information
    const formData = {
      AgencyUID: agencyUniqueID,
      Name: values.name,
      Logo_Path: values.logo_Path,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addBrandingProfileDetails,
      "post",
      formData
    )
      .then((returnData) => {
        // Handle the response from the backend
        if (returnData.status == ActionStatus.Successfull) {
          handleToastMessage(["success", returnData.message]);
          handleCurrentPage("TrustAccountDetails");
        } else {
          // alert(`Message: ${response.data.message}`);
          handleToastMessage(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", returnData.message]);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">


        <div className="df-all-agent">
          <div className="agent-detial-dp">
            <ProfileImage handleFileChange={handleFileChange} inputRef={inputRef} imageSrc={values.logo_Path} onClickHandler={select} isCircle={false} isPersonalImage={true} />
          </div>
          <div className="all-input">

            <TextBox label="Agency Name" placeholder="Agency name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} error={errors.name} touched={touched.name} />
          </div>


        


          {/* <div className="all-stuff">
            <div className="pdd-upload-section col-lg-6 col-sm-12">
              <div className="photo col-lg-6 col-sm-12">
                <ProfileImage
                  imageSrc={values.logo_Path}
                  handleFileChange={handleFileChange}
                  inputRef={inputRef}
                  onClickHandler={select}
                  isCircle={true}
                />
              </div>
            </div>
          </div> */}


        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() => handleparent(["warn", "changes are canceled"])}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default BradingDetailsProfile;
