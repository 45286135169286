import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import debounce from "lodash/debounce";
import {
  EnquiryListSearch,
  ListPortalEnquiry,
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import { BiSearch, BiPhone, BiLogoGmail, BiUser } from "react-icons/bi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { Paginator } from "primereact/paginator";
import { Select, Spin } from "antd";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { ActionStatus } from "../../../Utilities/enum";
import { format } from "date-fns";

const PortalEnquiryList = () => {
  const [listPortalEnquiry, setListPortalEnquiry] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agentUniqueID, setAgentUniqueId] = useState("");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListPortalEnquiry");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const [protalVal, setProtalVal] = useState("");
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [date, setDate] = useState("");
  const [portalArr, setPortalArr] = useState([
    { label: "All", value: "All" },
    { label: "REA", value: "REA" },
    { label: "DOMAIN", value: "DOMAIN" },
  ]);
  const ToastRef = useRef();
  const [propertyTypeArr, setPropertyTypeArr] = useState([
    { label: "All", value: null },
    { label: "Sale", value: 1 },
    { label: "Rental", value: 2 },
  ]);
  const [currentPages, setCurrentPage] = useState(1);
  const [totalRow, setTotalRow] = useState(5);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUniqueId(parsedData.agencyUniqueID);
          setAgentId(parsedData.agentId);
          setAgentUniqueId(parsedData.agentUniqueId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) SearchEnquiryList(1,10);
  }, [userId]);

  useEffect(() => {
    const yesterday = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString();
    setDate(yesterday);
  }, []);

  const SearchEnquiryList = () => {
    const baseUrl = ListPortalEnquiry;
    let data = {
      RecordsPerPage: totalRow,
      AgencyId: agencyID,
      SortBy: "receivedAt",
      SortOrder: "Desc",
      Search: searchText,
      Portals: protalVal,
      PropertyId: searchValue,
      PageNo: currentPages,
      PropertyType: propertyTypeVal,
      Sincedate: date,
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length === 0) {
            ToastRef.current.notify(["error", "Search Records not found!"]);
          } else {
            let portalEnquiresData = response.list;
            let enquiryList = portalEnquiresData
              .filter((enquiry) => enquiry.listing !== null)
              .map((enquiry) => ({
                Portal: enquiry.portal,
                Property: enquiry.listing.address,
                receivedAt: enquiry.receivedAt,
                Comments: enquiry.comments,
                ContactDetails: enquiry.contactDetails,
                crmuId: enquiry.crmuId,
              }));
            debugger;
            setListPortalEnquiry(enquiryList);
          }
        } else {
          //ToastRef.current.notify(["error", "error"]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      // Call your API with the search value
      const baseUrl = EnquiryListSearch;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (value) => {
    setSearchValues(value.value);
    setSearchText(value.label);
    debounceFetcher(value);
  };

  const handleClear1 = () => {
    setSearchValues("");
    setSearchText("");
  };

  const handleProtalEnquiryList = () => {
    SearchEnquiryList();
  };

  const handleInput = (e, label) => {
    if (label === "Portal") {
      setProtalVal(e.value);
    } else if (label === "propertyType") {
      setPropertyTypeVal(e.value);
    }
  };

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    setDate(yesterday.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);

  const handleInputDate = (e, label) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      Portal Enquiries
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  {/* <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add
                      </div>
                    </div>
                  </button> */}
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="overview-card-container-row1 ">
                  <SelectBox
                    colVal={2}
                    name="propertyFor"
                    options={portalArr}
                    value={protalVal}
                    placeholder={"Portals"}
                    onChange={(e) => handleInput(e, "Portal")}
                  />
                  <SelectBox
                    colVal={2}
                    name="propertyType"
                    options={propertyTypeArr}
                    value={propertyTypeVal}
                    placeholder={"Property Type"}
                    onChange={(e) => handleInput(e, "propertyType")}
                  />
                  <TextBox
                    label=""
                    type={"date"}
                    colVal={2}
                    name="date"
                    value={date} // Bind the date state here
                    onChange={handleInputDate} // Handle changes to the selected date
                  />
                  <div className="mt-4 col-lg-2 col-sm-12">
                    <Select
                      className="landingPageSearchs"
                      mode="single"
                      showSearch
                      placeholder="Search for Property"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      style={{ width: "100%" }}
                      options={options}
                      onChange={handleSearchChange}
                      allowClear
                      onClear={handleClear1}
                    />
                  </div>
                  <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleProtalEnquiryList}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label"></div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                <DataTable
                  ref={dataTableRef}
                  value={listPortalEnquiry}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                >
                  <Column
                    field="Portal"
                    header="Portal"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="ContactDetails"
                    header="Contact Details"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>
                        {rowData.ContactDetails && (
                          <>
                            <div>
                              <BiUser /> {rowData.ContactDetails.fullName}
                            </div>
                            <div>
                              <BiLogoGmail /> {rowData.ContactDetails.email}
                            </div>
                            <div>
                              <BiPhone /> {rowData.ContactDetails.phone}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="Property"
                    header="Property"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div
                        style={{ textDecoration: "underline", color: "blue" }}
                        className="property-link"
                        onClick={() =>
                          dynamicUrl(
                            navigate,
                            `properties?propertyUID=${rowData.crmuId}`
                          )
                        }
                      >
                        {rowData.Property}
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="ReceivedAt"
                    header="Received At"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>
                        {format(new Date(rowData.receivedAt), "dd/MM/yyyy")}
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="Comments"
                    header="Comments"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={totalPages}
                  rowsPerPageOptions={[10, 20, 50]}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalEnquiryList;
