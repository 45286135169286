import React from 'react';
import { useCallback, useState, useEffect, useRef } from "react";
import "./questionnaire.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import {  GetPendingProfiletenant } from "../../CommonSection/apiresources";
import { useFormik } from "formik";
import { questionnaireSchema } from "../../CommonSection/Schema/schema";
import ProgressbarLandlord from '../../CommonSection/ProgressLandlord/ProgressLandlord';
const initialValues = {
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    comment1: "",
    comment2: "",
    comment3: "",
    comment4: "",
}
const Questionnaire = () => {
    const navigate= useNavigate();
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [data, setData] = useState([]);
    const [propAddress, setPropAddress] = useState();
    const [propertyUID, setPropertyUID] = useState();
    const [propId, setPropId] = useState('');
    const [rentAppId, setRentAppId] = useState();
    let progressData = [
        {
            label: "Property Details",
            url: "/propertydetails",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Address History",
            url: "/addresshistory",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Verification",
            url: "/verify",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Employment",
            url: "/employment",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Questionnaire",
            url: "/questionnaire",
            isSelected: true,
            isCompleted: false
        },
        {
            label: "Documentation",
            url: "/documentation",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Consent",
            url: "/consent",
            isSelected: false,
            isCompleted: false
        },]


    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: questionnaireSchema,
        onSubmit: (values, action) => {
            console.log(values);
            console.log(errors);
           // alert("sucess");
            handleQuestionnaire();

        }
    });

    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID)
                    setUserId(parsedData.userId)
                    console.log(userData)
                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);

    useEffect(() => {
        if (propertyUID !== '') {
            // console.log(`UserId number: ${userId}`);
            const timeout = setTimeout(() => {
                getapi();
            }, 2)
        }

    }, [propertyUID]);



    const getapi = () => {

        const baseUrl = GetPendingProfiletenant;
        setPropertyUID("b4df6e16b5b74");
        // Request body with login information
        setData(progressData);
        const propertyId = propertyUID;
        const userId = userUId;
        const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
        console.log('API URL:', apiUrl);
        console.log(`PropertyId: ${propertyId}`);

        axios.get(apiUrl)
            .then(response => {

                // Handle the response data
                console.log(response.data);
                if (response.data.status == 1) {
                    // console.log('some data');
                    // console.log(response.data.object);
                    setPropId(response.data.object.propertyId);
                    setPropAddress(response.data.object.propertyStreetadress);
                    setRentAppId(response.data.object.rentApplicationId);
                    console.log(propId)
                } else {

                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)

                }

            })

            .catch(error => {

                console.error('Error:', error);

            });

    }

    const handleQuestionnaire = () => {

        const apiUrl = 'http://54.79.31.207:8002/api/rent/addrentappquestionnaire';


        // Request body with login information

        const VerifyData = {

            PropertyId: propId,

            PropertyAddress: propAddress,

            PropertyUniqueId: propertyUID,

            RentApplicationId: rentAppId,

            UserUniqueId: userUId,

            hasTenantTerminatedByAgent: values.value1,

            hasTenantTerminatedByAgentNote: values.comment1,

            hasRefusedByAgent: values.value2,

            hasRefusedByAgentNote: values.comment2,

            debtToAgent: values.value3,

            debtToAgentNote: values.comment3,

            anyReasonEffectPayment: values.value4,

            CurrentTenancyLandlordemail: values.comment4,



        };



        axios.post(apiUrl, VerifyData)

            .then((response) => {

                // Handle the response from the backend

                if (response.data.status == 1) {


                    alert(`Message: ${response.data.message}`);

                    navigate('/documentation')

                    console.log(response.data);

                } else {

                    alert(`Message: ${response.data.message}`);

                    console.log(response.data)

                }

            })

            .catch((error) => {

                console.error('Error:', error);

            });







    }

    return (
        <div className="tenant" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>
                <div className="progressbar">{data && <ProgressbarLandlord message={data} />}</div>
                <div className="tenantq">{`Questionnaire`}</div>
                <form className="tenantq-input" onSubmit={handleSubmit} >
                    <div className="tenantq-form">
                        <div className="q-radio1">
                            <label className="ah-radio-text">
                                <div className="radio-text">Question 1* </div>
                                <div className="radio-label">Has your tenancy ever been terminated by a landlord or agent?</div>
                            </label>
                            <div className="radio-radio1">
                                <label htmlFor="radio">
                                    <input
                                        type="radio"
                                        id="radio"
                                        name="value1"
                                        value="1"
                                        checked={values.value1 === "1"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp; &nbsp;1
                                </label>
                                <label htmlFor="radio">
                                    <input
                                        id="radio"
                                        type="radio"
                                        name="value1"
                                        value="2"
                                        checked={values.value1 === "2"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp;&nbsp;No
                                </label>
                            </div>
                            {errors.value1 && touched.value1 ? <p className="form-error">{errors.value1}</p> : null}
                            {values.value1 === '1' && <div className="comment-tq">
                                <div className="radio-text">Comment</div>
                                <input type="text" className="radio-label" placeholder='Comment' name='comment1' value={values.comment1} onChange={handleChange} onBlur={handleBlur} />
                            </div>}
                        </div>
                        <div className="q-radio2">
                            <label className="ah-radio-text">
                                <div className="radio-text">Question 2* </div>
                                <div className="radio-label">
                                    Have you ever been refused a property by any landlord or agent?
                                </div>
                            </label>
                            <div className="radio-radio1">
                                <label htmlFor="radio">
                                    <input
                                        type="radio"
                                        id="radio"
                                        name="value2"
                                        value="1"
                                        checked={values.value2 === "1"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp; &nbsp;1
                                </label>
                                <label htmlFor="radio">
                                    <input
                                        id="radio"
                                        type="radio"
                                        name="value2"
                                        value="2"
                                        checked={values.value2 === "2"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp;&nbsp;No
                                </label>
                            </div>
                            {errors.value2 && touched.value2 ? <p className="form-error">{errors.value2}</p> : null}
                            {values.value2 === '1' && <div className="comment-tq">
                                <div className="radio-text">Comment</div>
                                <input type="text" className="radio-label" placeholder='Comment' name='comment2' value={values.comment2} onChange={handleChange} onBlur={handleBlur} />
                            </div>}
                        </div>
                        <div className="q-radio3">
                            <label className="ah-radio-text">
                                <div className="radio-text">Question 3* </div>
                                <div className="radio-label">Are you in debt to another landlord or agent?</div>
                            </label>
                            <div className="radio-radio1">
                                <label htmlFor="radio">
                                    <input
                                        type="radio"
                                        id="radio"
                                        name="value3"
                                        value="1"
                                        checked={values.value3 === "1"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp; &nbsp;Yes
                                </label>
                                <label htmlFor="radio">
                                    <input
                                        id="radio"
                                        type="radio"
                                        name="value3"
                                        value="2"
                                        checked={values.value3 === "2"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp;&nbsp;No
                                </label>
                            </div>

                            {errors.value3 && touched.value3 ? <p className="form-error">{errors.value3}</p> : null}
                            {values.value3 === '1' && <div className="comment-tq">
                                <div className="radio-text">Comment</div>
                                <input type="text" className="radio-label" placeholder='Comment' name='comment3' value={values.comment3} onChange={handleChange} onBlur={handleBlur} />
                            </div>}
                        </div>
                        <div className="q-radio4">
                            <label className="ah-radio-text">
                                <div className="radio-text">Question 4* </div>
                                <div className="radio-label">Is there any reason known to you that would affect your future rental payments?</div>
                            </label>
                            <div className="radio-radio1">
                                <label htmlFor="radio">
                                    <input
                                        type="radio"
                                        id="radio"
                                        name="value4"
                                        value="1"
                                        checked={values.value4 === "1"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp; &nbsp;Yes
                                </label>
                                <label htmlFor="radio">
                                    <input
                                        id="radio"
                                        type="radio"
                                        name="value4"
                                        value="2"
                                        checked={values.value4 === "2"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    &nbsp;&nbsp;No
                                </label>
                            </div>
                            {errors.value4 && touched.value4 ? <p className="form-error">{errors.value4}</p> : null}
                            {values.value4 === '1' && <div className="comment-tq">
                                <div className="radio-text">Comment</div>
                                <input type="text" className="radio-label" placeholder='Comment' name='comment4' value={values.comment4} onChange={handleChange} onBlur={handleBlur} />
                            </div>}
                        </div>
                    </div>
                    <button className="tenantq-button" type="submit"  >
                        <div className="text-parent">
                            <div className="text7">Continue</div>
                        </div>
                    </button>
                </form>
            </div >
            <Footer />
        </div >
    );
}

export default Questionnaire