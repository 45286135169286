import React from 'react';
import { useCallback, useState, useEffect, useRef } from "react";
import "./employment.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import {  GetPendingProfiletenant, addEmploymentDetails } from "../../CommonSection/apiresources";
import { BiCalendar, BiEnvelope, BiMailSend, BiMap, BiMessage, BiPhone } from 'react-icons/bi';
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik } from "formik";
import { employementSchema } from "../../CommonSection/Schema/schema";
import Select from 'react-select';
import ProgressbarLandlord from '../../CommonSection/ProgressLandlord/ProgressLandlord';
const initialValues = {
    employmentStatus: "",
    employmenttype: "",
    companyname: "",
    abn: "",
    role: "",
    startdate: "",
    income: "",
    incometype: "",
    otherincome: "",
    otherincometype: "",
    refer: "",
    name: "",
    phone: "",
    email: "",
}
const Employment = () => {
     const navigate= useNavigate();

    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [address, setAddress] = useState("");
    const [selectedOption, setSelectedOption] = useState(1);
    const [value1, setValue1] = useState(null);
    const [value2, setValue2] = useState(null);
    const [value3, setValue3] = useState(null);
    const [propAddress, setPropAddress] = useState();
    const [propertyUID, setPropertyUID] = useState();
    const [propId, setPropId] = useState();
    const [rentAppId, setRentAppId] = useState();
    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: employementSchema,
        onSubmit: (values, action) => {
            console.log(values);
            console.log(errors);
            values.employmentStatus = value1.value;
            values.employmenttype = value2.value;
            values.refer = value3.value;
            handleEmployment();

        }
    });
    const employement_type = [
        { value: 'Employed', label: 'Employed' },
        { value: 'Self-employed', label: 'Self-employed' },


    ]
    const employment_status = [
        { value: 'Casual', label: 'Casual' },
        { value: 'Part-Time', label: 'Part-Time' },
        { value: 'Full-time', label: 'Full-time' }

    ]
    const reference = [
        { value: 'Owner', label: 'Owner' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Accountant', label: 'Accountant' },
        { value: 'Other', label: 'Other' },
    ]

    const [data, setData] = useState([]);
    let progressData = [
        {
            label: "Property Details",
            url: "/propertydetails",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Address History",
            url: "/addresshistory",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Verification",
            url: "/verify",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Employment",
            url: "/employment",
            isSelected: true,
            isCompleted: false
        },
        {
            label: "Questionnaire",
            url: "/questionnaire",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Documentation",
            url: "/documentation",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Consent",
            url: "/consent",
            isSelected: false,
            isCompleted: false
        },]


    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID)
                    setUserId(parsedData.userId)
                    console.log(userData)
                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);



    useEffect(() => {
        if (propertyUID !== '') {
            // console.log(`UserId number: ${userId}`);
            const timeout = setTimeout(() => {
                getapi();
            }, 2)
        }

    }, [propertyUID]);



    const getapi = () => {

        const baseUrl = GetPendingProfiletenant;
        setPropertyUID("b4df6e16b5b74");
        // Request body with login information
        setData(progressData);
        const propertyId = propertyUID;
        const userId = userUId;
        const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
        console.log('API URL:', apiUrl);
        console.log(`PropertyId: ${propertyId}`);

        axios.get(apiUrl)
            .then(response => {

                // Handle the response data
                console.log(response.data);
                if (response.data.status == 1) {
                    // console.log('some data');
                    // console.log(response.data.object);
                    setPropId(response.data.object.propertyId);
                    setPropAddress(response.data.object.propertyStreetadress);
                    setRentAppId(response.data.object.rentApplicationId);
                    console.log(propId)
                } else {

                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)

                }

            })

            .catch(error => {

                console.error('Error:', error);

            });

    }


    const handleEmployment = () => {
        const apiUrl = addEmploymentDetails;
        // Request body with login information
        const VerifyData = {
            PropertyId: propId,
            PropertyAddress: propAddress,
            PropertyUniqueId: propertyUID,
            RentApplicationId: rentAppId,
            UserUniqueId: userUId,
            EmployementStatus: values.employmentStatus,
            EmployementType: values.employmenttype,
            CompanyName: values.companyname,
            CompanyABN: values.abn,
            Title: values.role,
            JoinedDate: values.startdate,
            ApplicantIncome: values.income,
            ApplicantIncometenure: values.incometype,
            ApplicantOtherincome: values.otherincome,
            ApplicantOtherincometenure: values.otherincometype,
            EmReferenceType: values.refer,
            EmReferenceName: values.name,
            EmReferenceEmail: values.email,
            EmReferencePhone: values.phone,
        }

        axios.post(apiUrl, VerifyData)
            .then((response) => {
                // Handle the response from the backend
                console.log(VerifyData);
                if (response.data.status == 1) {
                    alert(`Message: ${response.data.message}`);
                    navigate('/questionnaire');
                    console.log(response.data);
                } else {
                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    return (
        <div className="tenant" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>
                <div className="progressbar">{data && <ProgressbarLandlord message={data} />}</div>
                <div className="tenanted">{`Employment `}</div>
                <form className="tenanted-input" onSubmit={handleSubmit} >
                    <div className="ed-form">
                        <div className="ed-agent-input">
                            <div className="ed-employment-status">
                                <div className='s1' htmlFor="name">Employement Status* </div>
                                <div className="input-group mb-3">
                                    <Select
                                        options={employment_status}
                                        className="select"
                                        required
                                        placeholder="Select Status"
                                        name='employmentstatus'
                                        defaultValue={value1}
                                        id="s2"
                                        onChange={setValue1} />
                                </div>
                            </div>
                            <div className="ed-employment-type">
                                <div className='s1' htmlFor="name">Employement Type* </div>
                                <div className="input-group mb-3">
                                    <Select
                                        options={employement_type}
                                        className="select"
                                        required
                                        placeholder="Select Type"
                                        name='employmentstatus'
                                        defaultValue={value2}
                                        id="s2"
                                        onChange={setValue2} />
                                </div>
                            </div>
                            <div className="ed-company-name">
                                <div className='s1' htmlFor="name">Company Name* </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control "
                                        placeholder="Name"
                                        value={values.companyname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="companyname"
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.companyname && touched.companyname ? <p className="form-error">{errors.companyname}</p> : null}
                            </div>
                            <div className="ed-abn">
                                <div className='s1' htmlFor="name">ABN/ACN </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control "
                                        placeholder="Name"
                                        value={values.abn}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="abn"
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            <div className="ed-role">
                                <div className='s1' htmlFor="name">Role or title* </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control "
                                        placeholder="Name"
                                        value={values.role}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="role"
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.role && touched.role ? <p className="form-error">{errors.role}</p> : null}
                            </div>
                            <div className="date">
                                <div className='s1' htmlFor="date">When did you start working for this company?* </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><BiCalendar height="100%" width="100%" /></span>
                                    </div>
                                    <input type="date"
                                        id="tenantpd-date"
                                        className="form-control tenant-date"
                                        value={values.startdate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="startdate"
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.startdate && touched.startdate ? <p className="form-error">{errors.startdate}</p> : null}
                            </div>
                            <div className="income">
                                <div className='s1' htmlFor="weekly-otherincome">
                                    Income*
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input type="text"
                                        id="tenantpd-rent"
                                        className="form-control"
                                        placeholder="400"
                                        name="income"
                                        value={values.income}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.income && touched.income ? <p className="form-error">{errors.income}</p> : null}
                            </div>
                            <div className="ed-radio1">
                                <div className="ah-radio-text s1">
                                    Income Tenure*
                                </div>
                                <div className="radio-radio">
                                    <label htmlFor="radio">
                                        <input
                                            type="radio"
                                            id="radio"
                                            name="incometype"
                                            value="1"
                                            checked={values.incometype === "1"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        &nbsp;Weekly
                                    </label>
                                    <label htmlFor="radio">
                                        <input
                                            id="radio"
                                            type="radio"
                                            name="incometype"
                                            value="2"
                                            checked={values.incometype === "2"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        &nbsp; Monthly
                                    </label>
                                </div>
                                {errors.incometype && touched.incometype ? <p className="form-error">{errors.incometype}</p> : null}
                            </div>
                            <div className="otherincome">
                                <div className='s1' htmlFor="weekly-income">
                                    Other Income*
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input type="text"
                                        id="tenantpd-rent"
                                        className="form-control"
                                        placeholder="400"
                                        name="otherincome"
                                        value={values.otherincome}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.otherincome && touched.otherincome ? <p className="form-error">{errors.otherincome}</p> : null}

                            </div>
                            <div className="ed-radio2">
                                <label className="s1 ah-radio-text">
                                    Other Income Tenure*
                                </label>
                                <div className="radio-radio">
                                    <label htmlFor="radio">
                                        <input
                                            type="radio"
                                            id="radio"
                                            name="otherincometype"
                                            value="1"
                                            checked={values.otherincometype === "1"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        &nbsp;Weekly
                                    </label>
                                    <label htmlFor="radio">
                                        <input
                                            id="radio"
                                            type="radio"
                                            name="otherincometype"
                                            value="2"
                                            checked={values.otherincometype === "2"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        &nbsp;Monthly
                                    </label>
                                </div>
                                {errors.otherincometype && touched.otherincometype ? <p className="form-error">{errors.otherincometype}</p> : null}
                            </div>
                            <div className="ed-reference">
                                <div className='s1' htmlFor="name">Reference* </div>
                                <div className="input-group mb-3">
                                    <Select
                                        options={reference}
                                        className="select"
                                        required
                                        placeholder="Select Refernce"
                                        name='employmentstatus'
                                        defaultValue={value3}
                                        id="s2"
                                        onChange={setValue3} />
                                </div>
                            </div>
                            <div className="ed-name">
                                <div className='s1' htmlFor="name">Name* </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control "
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="name"
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.name && touched.name ? <p className="form-error">{errors.name}</p> : null}
                            </div>
                            <div className="ed-phone">
                                <div className='s1' htmlFor="phone">Phone*</div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><BiPhone height="100%" width="100%" /></span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control tenant-date"
                                        placeholder="Phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="phone"
                                        aria-describedby="basic-addon1" />
                                </div>
                                {errors.phone && touched.phone ? <p className="form-error">{errors.phone}</p> : null}
                            </div>
                            <div className="ed-email">
                                <div className='s1' htmlFor="email">Email* </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><BiEnvelope height="100%" width="100%" /></span>
                                    </div>
                                    <input
                                        type="email"
                                        className="form-control tenant-date"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="email"
                                        aria-describedby="basic-addon1" />

                                </div>
                                {errors.email && touched.email ? <p className="form-error">{errors.email}</p> : null}
                            </div>
                        </div>
                    </div>
                    <button className="tenanted-button" type="submit"  >
                        <div className="text-parent">
                            <div className="text7">Continue</div>
                        </div>
                    </button>

                </form>
            </div >
            <Footer />
        </div >
    );
};

export default Employment