import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { prominantalActivities } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import { BiDollar } from "react-icons/bi";

dayjs.extend(customParseFormat);

const initialValues = {
  isListedOnREA: false,
  isListedOnDomain: false,
  SocialMedia: false,
  Signboard: false,
  ThirdPartyName: "",
  AmountOfRebateDiscount: "",
  ObtainReferenceFromTenant: false,
  EnterIntoSignTenacyAgrmnt: false,
  UndertakeInspection: false,
  EffectRepairMaintainProprty: false,
  PayDisbursemnt: false,
  CollectReceiveRent: false,
  ServeNoticeForBreachTenancy: false,
  UndrtakeStpsToRecvrMony: false,
  RepresentPrincipalCourt: false,
  PayAccountsForAmount: false,
  AdvertisePropertyLetting: false,
  ReviewRentEndTenancy: false,
};

const AgreementPeriod = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("isListedOnREA", getDetails.isListedOnREA);
      setFieldValue("isListedOnDomain", getDetails.isListedOnDomain);
      setFieldValue("SocialMedia", getDetails.redirect);
      setFieldValue("Signboard", getDetails.socialMedia);
      setFieldValue("ThirdPartyName", getDetails.thirdPartyName);
      setFieldValue(
        "AmountOfRebateDiscount",
        getDetails.amountOfRebateDiscount
      );
      setFieldValue(
        "ObtainReferenceFromTenant",
        getDetails.obtainReferenceFromTenant
      );
      setFieldValue(
        "EnterIntoSignTenacyAgrmnt",
        getDetails.enterIntoSignTenacyAgrmnt
      );
      setFieldValue("UndertakeInspection", getDetails.undertakeInspection);
      setFieldValue(
        "EffectRepairMaintainProprty",
        getDetails.effectRepairMaintainProprty
      );
      setFieldValue("PayDisbursemnt", getDetails.payDisbursemnt);
      setFieldValue("CollectReceiveRent", getDetails.collectReceiveRent);
      setFieldValue(
        "ServeNoticeForBreachTenancy",
        getDetails.serveNoticeForBreachTenancy
      );
      setFieldValue(
        "UndrtakeStpsToRecvrMony",
        getDetails.undrtakeStpsToRecvrMony
      );
      setFieldValue(
        "RepresentPrincipalCourt",
        getDetails.representPrincipalCourt
      );
      setFieldValue("PayAccountsForAmount", getDetails.payAccountsForAmount);
      setFieldValue(
        "AdvertisePropertyLetting",
        getDetails.advertisePropertyLetting
      );
      setFieldValue("ReviewRentEndTenancy", getDetails.reviewRentEndTenancy);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: prominantalActivities,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("RepairAndMaintainance");
  };

  const handleClear = () => {
    handleNextPage("TribunalFees");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">

                  <div style={{ width: "100%" }}>

                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`Promotional Activities`}</div>

                      <div className="s1">
                        The Rental Property is to be advertised and/or
                        otherwise promoted as followed
                      </div>
                      <div className="pddpn-group">
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                          <div className="address-pair">
                            <div className="labelssss">Listing on Realestate.com.au</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.isListedOnREA ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Social Media Campaign</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.SocialMedia ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Listing on Domain.com.au</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.isListedOnDomain ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Sign board</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.Signboard ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pddpn-group2 pddpn-group3"

                    >
                      <div className="agreementLabel mb-3 tribulanceBreak" >{`Disclosures of Rebates, Discounts or Commissions in Respect of Expenses`}</div>

                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                        <div className="address-pair">
                          <div className="labelssss">Name of Third party</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.ThirdPartyName}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                        <div className="address-pair">
                          <div className="labelssss">Estimated Amount of Rebate, Discount or Commission</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <BiDollar/>{values.AmountOfRebateDiscount}</div>
                        </div>
                      </div>
                    </div>


                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`Agent’s Authority for Managing the Property`}</div>
                      <div className="s1">The agent is authorized to:</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Obtain references from prospective tenants,
                            arrange inspections of the property by
                            prospective tenants and choose a tenant</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.ObtainReferenceFromTenant ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Enter into and sign a tenancy agreement
                            (specifying the term for which the property may
                            be let)</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.EnterIntoSignTenacyAgrmnt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Undertake inspections of the property</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.UndertakeInspection ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Effect repairs to and maintain the property or
                            engage tradespersons to do so up to an approved
                            amount without obtaining the approval of
                            Principal</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.EffectRepairMaintainProprty ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Pay disbursements and expenses incurred in
                            connection with the agent’s management of the
                            property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.PayDisbursemnt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Pay disbursements and expenses incurred in
                            connection with the agent’s management of the
                            property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.PayDisbursemnt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Pay disbursements and expenses incurred in
                            connection with the agent’s management of the
                            property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.PayDisbursemnt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Pay disbursements and expenses incurred in
                            connection with the agent’s management of the
                            property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.PayDisbursemnt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Represent the Principal in any tribunal or court
                            proceedings in respect of the tenancy of the
                            property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.RepresentPrincipalCourt ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Pay accounts for amounts owing in relation to
                            the property (for example, water rates and
                            charges, council rates, maintenance expenses and
                            owners corporation levies)</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.PayAccountsForAmount ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Advertise the property for letting or
                            re-letting,</div>
                            <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.AdvertisePropertyLetting ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Review the rent at the end of a tenancy</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.ReviewRentEndTenancy ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementPeriod;
