import React from 'react';

// Importing toastify module
import { toast, ToastContainer } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

// toast-configuration method, 
// it is compulsory method.
const duration = 2000;

// This is main function
const Temp = React.forwardRef((props, ref) => {
  // function which is called when 
  // button is clicked
  const notify = () => {
    // Calling toast method by passing string
    if (props.type == 'success')
      toast.success(props.message, {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    else if(props.type=='warn')
     toast.warn(props.message)
    else if (props.type == 'error')
      toast.error(props.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    else
      toast(props.message)

  }
  return (
    <div className="GeeksforGeeks">
      <button onClick={notify} ref={ref} hidden></button>
      <ToastContainer />
    </div>
  );
})


export default Temp;