import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {addPropertyAttributes} from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import Collapse from "../../../../src/input-components/collapse";
import ToggleButtonGroup from "../../../../src/input-components/toggleButton";
import { Button } from 'primereact/button';
import { dynamicUrl} from "../../../../Utilities/profileManagement";

const initialValues = {
  PropertyLandAreaW: null,
  PropertyLandAreaL: '',
  countBedrooms: 0,
  countBathrooms: 0,
  countCarParking: 0,
  countEnsuites: 0,
  countGarageSpace: 0,
  countLivingAreas: 0,
  countToilets: 0,
  coutryard: 0,
  countOutDoorArea: 0,
  balcony: false,
  swimmmingPoolInGround: false,
  deck: false, // Add other features as needed
  fullyFenced: false,
  outdoorEntertainmentArea: false,
  outsideSpa: false,
  remoteGarage: false,
  secureParking: false,
  shed: false,
  swimmmingPoolAboveGround: false,
  tennisCourt: false,
  alarmSystem: false,
  builtInWardrobes: false,
  ductedVacuumSystem: false,
  gym: false,
  intercom: false,
  rumpusRoom: false,
  workshop: false,
  broadbandInternetAvailable: false,
  dishwasher: false,
  floorboards: false,
  insideSpa: false,
  payTVAccess: false,
  study: false,
  airConditioning: false,
  ductedHeating: false,
  gasHeating: false,
  openFireplace: false,
  splitSystemAirConditioning: false,
  ductedCooling: false,
  evaporativeCooling: false,
  hydraulicHeating: false,
  reverseCycleAirConditioning: false,
  splitSystemHeating: false,
  waterTank: false,
  greyWaterSystem: false,
  solarPanels: false,
  solarHotWater: false,
};

const PropertyAttributes = ({ handleparentsuccess,handleCurrentPage1,onDataRecive1,getDetails}) => {
  const [userId, setUserId] = useState("");
  //const [getDetails, setGetDetails] = useState(null);
  const ToastRef = useRef();

  const [land, setLand] = useState([
    { Id: 1, label: "Feet", value: 1 },
    { Id: 2, label: "SQM's", value: 2 },
    { Id: 3, label: "Acres", value: 3 },
    { Id: 4, label: "Hectares", value: 4 },
  ]);

  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });
console.log(getDetails,'getDetails')
  const [outdoorFeatures, setOutdoorFeatures] = useState([
    { label: "Balcony", value: false, name: "balcony" },
    { label: "Swimming Pool - In Ground", value: false, name: "swimmmingPoolInGround" },
    { label: "Deck", value: false, name: "deck" },
    { label: "Fully Fenced", value: false, name: "fullyFenced" },
    {
      label: "Outdoor Entertainment Area",
      value: false,
      name: "outdoorEntertainmentArea",
    },
    { label: "", value: false, name: "outsideSpa" },
    { label: "Remote Garage", value: false, name: "remoteGarage" },
    { label: "Secure Parking", value: false, name: "secureParking" },
    { label: "Shed", value: false, name: "shed" },
    {
      label: "Swimming Pool - Above Ground",
      value: false,
      name: "swimmmingPoolAboveGround",
    },
    { label: "Tennis Court", value: false, name: "tennisCourt" },
    { label: "Coutryard", value: false, name: "coutryard" },
  ]);

  const [indoorFeatures, setIndoorFeatures] = useState([
    { label: "Alarm System", value: false, name: "alarmSystem" },
    { label: "Workshop", value: false, name: "workshop" },
    { label: "Build-in Wardrobes", value: false, name: "builtInWardrobes" },
    {
      label: "Boardband Internet Available",
      value: false,
      name: "broadbandInternetAvailable",
    },
    { label: "Ducted vaccum System", value: false, name: "ductedVacuumSystem" },
    { label: "Dishwasher", value: false, name: "dishwasher" },
    { label: "Gym", value: false, name: "gym" },
    { label: "Floorboards", value: false, name: "floorboards" },
    { label: "Intercom", value: false, name: "intercom" },
    { label: "Inside Spa", value: false, name: "insideSpa" },
    { label: "Rumpus Room", value: false, name: "rumpusRoom" },
    { label: "Pay TV Access", value: false, name: "payTVAccess" },
    { label: "Study", value: false, name: "study" },
  ]);

  const [heatCoolingFeatures, setHeatCoolingFeatures] = useState([
    { label: "Air Conditioning", value: false, name: "airConditioning" },
    { label: "Ducted Cooling", value: false, name: "ductedCooling" },
    { label: "Ducted Heating", value: false, name: "ductedHeating" },
    { label: "Evaporative Cooling", value: false, name: "evaporativeCooling" },
    { label: "Gas Heating", value: false, name: "gasHeating" },
    { label: "Hydraulic Heating", value: false, name: "hydraulicHeating" },
    { label: "Open FirePlace", value: false, name: "openFireplace" },
    {
      label: "Reverse Cycle Air conditioning",
      value: false,
      name: "reverseCycleAirConditioning",
    },
    {
      label: "Split System Air conditioning",
      value: false,
      name: "splitSystemAirConditioning",
    },
    { label: "Split System Heating", value: false, name: "splitSystemHeating" },
  ]);

  const [ecoFriendlyFeatures, setEcoFriendlyFeatures] = useState([
    { label: "Grey Water System", value: false, name: "greyWaterSystem" },
    { label: "Solar Hot Water", value: false, name: "solarHotWater" },
    { label: "Solar Panels", value: false, name: "solarPanels" },
    { label: "Water Tank", value: false, name: "waterTank" },
  ]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      SetInitialValuesToToggle(getDetails);
      const getFilteredData1 = (selectedValue) => {
        return land.find((item) => item.value === selectedValue);
      };
      const selectedValue1 = getDetails.propertyLandAreaW != null
        ? getDetails.propertyLandAreaW
        : '';
      const filteredData1 = getFilteredData1(selectedValue1);
      setFieldValue("PropertyLandAreaL", getDetails.propertyLandAreaL);
      setFieldValue("PropertyLandAreaW", filteredData1 != undefined ? filteredData1.value : "");
    }
  }, [getDetails]);

  const SetInitialValuesToToggle = (attributeDetails) => {
    const updatedOutdoorFeatures = outdoorFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setOutdoorFeatures(updatedOutdoorFeatures);

    const updatedIndoorFeatures = indoorFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setIndoorFeatures(updatedIndoorFeatures);

    const updatedheatCoolingFeatures = heatCoolingFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setHeatCoolingFeatures(updatedheatCoolingFeatures);

    const updatedecoFriendlyFeatures = ecoFriendlyFeatures.map((feature) => ({
      ...feature,
      value: attributeDetails[feature.name],
    }));
    setEcoFriendlyFeatures(updatedecoFriendlyFeatures);
  };
  
  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: getDetails.propertyId,
      UserId: userId,
        PropertyAttibuteDetails: {
        PropertyLandAreaL: values.PropertyLandAreaL,
        PropertyLandAreaW: values.PropertyLandAreaW,
        CountBedrooms: values.countBedrooms,
        CountBathrooms: values.countBathrooms,
        CountCarParking: values.countCarParking,
        CountEnsuites: values.countEnsuites,
        CountGarageSpace: values.countGarageSpace,
        CountLivingAreas: values.countLivingAreas,
        CountToilets: values.countToilets,
        Coutryard: values.coutryard,
      },

      PropertyFeaturesOutdoorDetails: {
        Balcony: values.balcony,
        Deck: values.deck,
        OutdoorEntertainmentArea: values.outdoorEntertainmentArea,
        RemoteGarage: values.remoteGarage,
        Shed: values.shed,
        SwimmmingPoolInGround: values.swimmmingPoolInGround,
        FullyFenced: values.fullyFenced,
        OutsideSpa: values.outsideSpa,
        SecureParking: values.secureParking,
        SwimmmingPoolAboveGround: values.swimmmingPoolAboveGround,
        TennisCourt: values.tennisCourt,
      },

      PropertyFeaturesIndoorDetails: {
        AlarmSystem: values.alarmSystem,
        BuiltInWardrobes: values.builtInWardrobes,
        DuctedVacuumSystem: values.ductedVacuumSystem,
        Gym: values.gym,
        Intercom: values.intercom,
        RumpusRoom: values.rumpusRoom,
        Workshop: values.workshop,
        BroadbandInternetAvailable: values.broadbandInternetAvailable,
        Dishwasher: values.dishwasher,
        Floorboards: values.floorboards,
        InsideSpa: values.insideSpa,
        PayTvaccess: values.payTVAccess,
        Study: values.study,
      },

      PropertyFeaturesHeatCoolDetails: {
        AirConditioning: values.airConditioning,
        DuctedHeating: values.ductedHeating,
        GasHeating: values.gasHeating,
        OpenFireplace: values.openFireplace,
        SplitSystemAirConditioning: values.splitSystemAirConditioning,
        DuctedCooling: values.ductedCooling,
        EvaporativeCooling: values.evaporativeCooling,
        HydraulicHeating: values.hydraulicHeating,
        ReverseCycleAirConditioning: values.reverseCycleAirConditioning,
        SplitSystemHeating: values.splitSystemHeating,
      },

      PropertyEcoFriendlyFeaturesDetails: {
        WaterTank: values.waterTank,
        GreyWaterSystem: values.greyWaterSystem,
        SolarPanels: values.solarPanels,
        SolarHotWater: values.solarHotWater,
      },
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPropertyAttributes,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          onDataRecive1(returnData.sUID);
          handleCurrentPage1("Overview")
        } 
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleOutdoorChange = (index) => {
    const updatedOutdoorValues = [...outdoorFeatures];
    updatedOutdoorValues[index].value = !updatedOutdoorValues[index].value;
    setOutdoorFeatures(updatedOutdoorValues);
    setFieldValue(
      updatedOutdoorValues[index].name,
      updatedOutdoorValues[index].value
    );
  };

  const handleIndoorChange = (index) => {
    const updatedIndoorValues = [...indoorFeatures];
    updatedIndoorValues[index].value = !updatedIndoorValues[index].value;
    setIndoorFeatures(updatedIndoorValues);
    setFieldValue(
      updatedIndoorValues[index].name,
      updatedIndoorValues[index].value
    );
  };

  const handleheatCoolingChange = (index) => {
    const updatedheatCoolingValues = [...heatCoolingFeatures];
    updatedheatCoolingValues[index].value =
      !updatedheatCoolingValues[index].value;
    setHeatCoolingFeatures(updatedheatCoolingValues);
    setFieldValue(
      updatedheatCoolingValues[index].name,
      updatedheatCoolingValues[index].value
    );
  };

  const handleEcoFriendlyChange = (index) => {
    const updatedEcoFrndlyValues = [...ecoFriendlyFeatures];
    updatedEcoFrndlyValues[index].value = !updatedEcoFrndlyValues[index].value;
    setEcoFriendlyFeatures(updatedEcoFrndlyValues);
    setFieldValue(
      updatedEcoFrndlyValues[index].name,
      updatedEcoFrndlyValues[index].value
    );
  };

  const labelsForOutdoorFeatures = [
    'Balcony',
    'Swimming Pool - In Ground',
    'Deck',
    'Fully Fenced',
    'Outdoor Entertainment Area',
    '',
    'Remote Garage',
    'Secure Parking',
    'Shed',
    'Swimming Pool - Above Ground',
    'Tennis Court',
    'Coutryard',
  ];

  const labelsForIndoorFeatures = [
    'Alarm System',
    'Workshop',
    'Build-in Wardrobes',
    'Broadband Internet Available',
    'Ducted Vacuum System',
    'Dishwasher',
    'Gym',
    'Floorboards',
    'Intercom',
    'Inside Spa',
    'Rumpus Room',
    'Pay TV Access',
    'Study',
  ];

  const labelsForHeatCoolingFeatures = [
    'Air Conditioning',
    'Ducted',
    'Ducted Heating',
    'Evaporative Cooling',
    'Gas Heating',
    'Hydraulic Heating',
    'Open FirePlace',
    'Reverse Cycle Air Conditioning',
    'Split System Air Conditioning',
    'Split System Heating',
  ];

  const labelsForEcoFriendlyFeatures = [
    'Grey Water System',
    'Solar Hot Water',
    'Solar Panels',
    'Water Tank',
  ];

  const [edit, setEdit] = useState('')
  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if(propertyUId){
      dynamicUrl(navigate, "ViewPropertyDetails" + "?propertyUID=" + propertyUId);
    }else{
      dynamicUrl(navigate, "propertiesList");
    }
  };

  return (
    <div>
            <ToastAlert ref={ToastRef} />
          <form onSubmit={handleSubmit} className="details-subform">
            <div className="property-details-content df-all all-input">
              <Collapse
                id={"Basic-Details"}
                title={"Basic-Details"}
                data={
                  <div className="pddpn-group" style={{ padding: "0px 30px" }}>

                    <TextBox
                      colVal={3}
                      label="Beds"
                      placeholder="beds"
                      name="countBedrooms"
                      value={values.countBedrooms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countBedrooms}
                      touched={touched.countBedrooms}
                      type={'number'}
                    />
                    <TextBox
                      colVal={3}
                      label="Baths"
                      placeholder="baths"
                      name="countBathrooms"
                      value={values.countBathrooms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countBathrooms}
                      touched={touched.countBathrooms}
                      type={'number'}

                    />
                    <TextBox
                      colVal={3}
                      label="Garage Space"
                      placeholder="garage space"
                      name="countGarageSpace"
                      value={values.countGarageSpace}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countGarageSpace}
                      touched={touched.countGarageSpace}
                      type={'number'}

                    />
                    <TextBox
                      colVal={3}
                      label="Toilets"
                      placeholder="toilets"
                      name="countToilets"
                      value={values.countToilets}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countToilets}
                      touched={touched.countToilets}
                      type={'number'}

                    />

                    <TextBox
                      colVal={3}
                      label="Ensuites"
                      placeholder="ensuites"
                      name="countEnsuites"
                      value={values.countEnsuites}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countEnsuites}
                      touched={touched.countEnsuites}
                      type={'number'}

                    />
                    <TextBox
                      colVal={3}
                      label="Living Areas"
                      placeholder="living areas"
                      name="countLivingAreas"
                      value={values.countLivingAreas}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countLivingAreas}
                      touched={touched.countLivingAreas}
                      type={'number'}

                    />

                    <TextBox
                      colVal={3}
                      label="Outdoor Area"
                      placeholder="outdoor area"
                      name="countOutDoorArea"
                      value={values.countOutDoorArea}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countOutDoorArea}
                      touched={touched.countOutDoorArea}
                      type={'number'}

                    />


                    <TextBox
                      colVal={3}
                      label="Car Ports"
                      placeholder="car ports"
                      name="countCarParking"
                      value={values.countCarParking}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.countCarParking}
                      touched={touched.countCarParking}
                      type={'number'}

                    />


                    <TextBox
                      label="Land Area"
                      placeholder="area"
                      id="Unit"
                      name="PropertyLandAreaL"
                      value={values.PropertyLandAreaL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.PropertyLandAreaL}
                      touched={touched.PropertyLandAreaL}
                    />
                    <div className="cda-AgentAddress col-lg-5 col-sm-12">
                      <label htmlFor="AgentAddress" className='s1'>Land Area Type</label>
                      <div className="input-group mb-3"></div>
                      {land.map((areaType) => (
                        <Button
                          type="button"
                          key={areaType.value}
                          label={areaType.label}
                          className={values.PropertyLandAreaW === areaType.value ? 'custom-button-success' : 'p-button-secondary'}
                          onClick={() => setFieldValue('PropertyLandAreaW', areaType.value)}
                          text raised
                          style={{ borderRadius: '5px' }}
                        />
                      ))}
                    </div>
                  </div>

                }
              />
              <Collapse
                id={"Optional-Features"}
                title={"Optional-Features"}
                data={
                  <div className="" style={{ padding: "0px 30px" }}>
                    <ToggleButtonGroup
                      colval="12"
                      style={{ width: "150px" }}
                      mainLabel="Outdoor Features"
                      switches={outdoorFeatures}
                      labels={labelsForOutdoorFeatures}
                      onChange={(index) => handleOutdoorChange(index)}
                    />
                    <ToggleButtonGroup
                      colval="12"
                      style={{ width: "150px" }}
                      mainLabel="Indoor Features"
                      switches={indoorFeatures}
                      labels={labelsForIndoorFeatures}
                      onChange={handleIndoorChange}
                    />
                    <ToggleButtonGroup
                      colval="12"
                      style={{ width: "150px" }}
                      mainLabel="Heating / Cooling"
                      switches={heatCoolingFeatures}
                      labels={labelsForHeatCoolingFeatures}
                      onChange={handleheatCoolingChange}
                    />
                    <ToggleButtonGroup
                      colval="12"
                      style={{ width: "150px" }}
                      mainLabel="Eco Friendly Features"
                      labels={labelsForEcoFriendlyFeatures}
                      switches={ecoFriendlyFeatures}
                      onChange={handleEcoFriendlyChange}
                    />
                  </div>
                }
              />
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() =>
                  handleCancel()
                }
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
    </div>
  );
};

export default PropertyAttributes;
