import React from "react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { addExperienceAgentDetails } from "../../CommonSection/apiresources";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import SelectBox from "../../../src/input-components/selectBoxNew";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { AgentExperienceSchema } from "../../CommonSection/Schema/schema";
import DOMPurify from "dompurify";

const initialValues = {
  yearOfExperience: null,
  areaOfExpertise: null,
  profileDesc: "",
};

const ExperienceAgent = ({
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
}) => {
  var url = new URL(window.location.href);
  var agentId = url.searchParams.get("value");
  var uniqueId = url.searchParams.get("uniqueId");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      const options = [
        { value: 1, label: "Commercial" },
        { value: 2, label: "Residential" },
        { value: 3, label: "Office" },
      ];
      if (getDetails.areaOfExpertise){
      const areaOfExpertiseValues = getDetails.areaOfExpertise
        .split(",")
        .map((value) => +value);
      const filteredOptions = options.filter((option) =>
        areaOfExpertiseValues.includes(option.value)
      );
      const areaOfExpertiseArray = filteredOptions.map((option) => ({
        value: option.value,
        label: option.label,
      }));
   
      setFieldValue("areaOfExpertise", areaOfExpertiseArray);
    }
    console.log(getDetails.yearsOfExperience)
      setFieldValue("yearOfExperience", {
        value: getDetails.yearsOfExperience,
       
        label: `${
          getDetails.yearsOfExperience == 11 ? 'More than 10' : getDetails.yearsOfExperience  ? getDetails.yearsOfExperience : "Select years"
        }`,
      });

      setFieldValue("profileDesc", getDetails.description);
    }
  }, [getDetails]);

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
  };
  const options = [
    { value: 1, label: "Commercial" },
    { value: 2, label: "Residential" },
    { value: 3, label: "Office" },
  ];
  const options1 = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: "More than 10" },
  ];

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgentExperienceSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formDate = {
      AgencyId: agencyID,
      AgentId: agentId ? agentId : uniqueId,
      YearsOfExperience: values.yearOfExperience
        ? values.yearOfExperience.value
        : "",
      AreaOfExpertise: values.areaOfExpertise
        ? values.areaOfExpertise.map((obj) => obj.value).join(",")
        : "",
      Description: values.profileDesc,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addExperienceAgentDetails,
      "post",
      formDate
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleparentsuccess(["success", returnData.message]);
          handleCurrentPage("ContactDetails");
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleSelectChange = (selectedValues) => {
    setFieldValue("areaOfExpertise", selectedValues);
  };

  const handleSelectChange1 = (selectedValues) => {
    setFieldValue("yearOfExperience", selectedValues);
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">
        <div className="df-all">
          <div className="all-input-ea">
            <SelectBox
              isMandatory
              label="Years of Experience"
              
              name="yearOfExperience"
              options={options1}
              placeholder={
                values.yearOfExperience ? values.yearOfExperience.label : "Select years"
              }
              value={values.yearOfExperience}
              onChange={handleSelectChange1}
              error={errors.yearOfExperience}
              touched={touched.yearOfExperience}
            />
            <SelectBox
              isMandatory
              label="Area of Expertise"
              name="areaOfExpertise"
              options={options}
              placeholder={
                values.areaOfExpertise ? values.areaOfExpertise.label : "Select area"
              }
              value={values.areaOfExpertise}
              onChange={handleSelectChange}
              error={errors.areaOfExpertise}
              touched={touched.areaOfExpertise}
              isMulti={true}
            />
            <div className="ea-profileDesc col-lg-10 col-sm-12">
              <label htmlFor="lnumber" className="s1">
                Profile Description <span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group mb-3">
                <ReactQuill
                  className="w-75 d-flex flex-column"
                  theme="snow"
                  value={sanitizeHTML(values.profileDesc)}
                  onChange={(val) => setFieldValue("profileDesc", val)}
                />
              </div>
              {errors.profileDesc && touched.profileDesc ? (
                <p className="form-error">{errors.profileDesc}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() =>
              handleparentsuccess(["warn", "Changes are cancelled"])
            }
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ExperienceAgent;
