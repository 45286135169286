import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus, BiPencil, BiTrash } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import {OpenListHome , deleteOpenhome,ListTask} from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {GetCookie,currentProfile,} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { SelectButton } from "primereact/selectbutton";
import {ActionStatus} from "../../../Utilities/enum";
import { addParametersForProfiles ,dynamicUrl} from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import "../openHome/openHome.css"
import TextBox from "../../input-components/textbox";
import SelectBox from "../../input-components/selectBoxNew";
import "./task.css"
import { format } from 'date-fns';


const ListTasks = () => {
  const [openHomelist, setOpenHomelist] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agentUniqueID, setAgentUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListTask");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const propertyTypeitems = [
    { name: "All", value: 0 },
    { name: "Completed", value: 1 },
    { name: "Due", value: 2 },
  ];
  const [TypeArr, setTypeArr] = useState([
    { label: "Select", value: null },
    { label: "Added On", value: 1 },
    { label: "Due On", value: 2 },
    { label: "Completed On", value: 3 },
  ]);
  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };
  const [isStaff, setIsStaff] = useState(false);
  const [isBranch, setIsBranch] = useState(false);
  const [addedByAgency , setAddedBYAgency] = useState("")

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgentUniqueId(parsedData.agentId   );
          setAgentUID(parsedData.agentId)
          setIsStaff(parsedData.isStaff)
          setIsBranch(parsedData.isBranch)
          setAddedBYAgency(parsedData.addedByAgencyID)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner(1,10);
  }, [userId, searchText, purpose, propertyType]);

  const PostApiWithSpinner = (currentPage,totalRows) => {
    setDisplaytable(false)
    const baseUrl = ListTask;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage ,
      //AddedByAgency: addedByAgency,
      IsBranch : isStaff,
      IsStaff:isBranch,
      minDate: fromDate,
      maxDate: toDate,
      SearchDateType: propertyTypeVal,
      isCompleted : propertyType === 1 ? true : propertyType === 2 ? false : null
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if(response.list.length > 0){
          let taskData = response.list;
          let taskList = taskData.map((task) => ({
            Name: task.name,
            DueDate: task.dueDate,
            Priority:task.sPriorityLevel,
            Status: task.endTime,
            Property: task.sProperty,
            AssignedTo: task.asignee,
            taskUniqueID : task.taskUniqueID,
            IsCompleted: task.isCompleted,
            IsApproved: task.isApproved
          }));
          setOpenHomelist(taskList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
        }else{
          //ToastRef.current.notify(["warn", "User not added"]);
        }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddTask = () => {
    dynamicUrl(navigate, "AddTask");
  };

  const handleRowClick = (event, rowData) => {
    dynamicUrl(navigate, "TaskDetails" +  "?TaskDetails=" + rowData.taskUniqueID);
  };  
  

  const handlePageChange = (event , pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleInput = (e, label) => {
      setPropertyTypeVal(e.value);
  };

  const handleInputDate = (e, label) => {
    const selectedDate = e.target.value;
    if(label==="from"){
      setFromDate(selectedDate);
    }else{
      setToDate(selectedDate)
    }
  };

  const handleProtalEnquiryList = () => {
    PostApiWithSpinner();
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                     Task List
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <SelectButton
                    className="mr-2"
                    optionLabel="name"
                    options={propertyTypeitems}
                    onChange={(e) => propertyTypeChangeHandler(e.value)}
                    value={propertyType}
                  />
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddTask}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add 
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="overview-card-container-row1 ">
                  <SelectBox
                  label={"Type"}
                    colVal={2}
                    name="Type"
                    options={TypeArr}
                    value={propertyTypeVal}
                    placeholder={"Type"}
                    onChange={(e) => handleInput(e, "propertyType")}
                  />
                  <TextBox
                    label="From"
                    placeholder="From"
                    type={"date"}
                    colVal={2}
                    name="date"
                    value={fromDate} // Bind the date state here
                    onChange={(e) => handleInputDate(e,"from")} // Handle changes to the selected date
                  />
                   <TextBox
                    label="To"
                    type={"date"}
                    colVal={2}
                    name="date"
                    value={toDate} // Bind the date state here
                    onChange={(e) => handleInputDate(e,"to")} // Handle changes to the selected date
                  />
                  <button
                    className="Add-Property-button ml-3"
                    type="button"
                    style={{marginTop:'38px'}}
                    onClick={handleProtalEnquiryList}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
                </div>
                <div className="mb-2" style={{float:'right' , display:'flex' , alignItems:'baseline'}}>
                <div className="customCompleted"></div> 
                    <div className="mr-5">
                      Completed
                    </div>
                    <div className="customInprogress"></div>
                    <div>
                        In-Progress
                    </div>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">Task List</div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
               {displayTable?
                <DataTable
                  ref={dataTableRef}
                  value={openHomelist}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  onRowClick={(e) => handleRowClick(e, e.data)}
                >
                  <Column
                    field="Status"
                    header="Status"
                    className="common-table-style"
                    headerClassName="centered-header"
                    style={{textAlign:'center'}}
                    body={(rowData) => (
                      <>
                        {rowData.IsApproved === true ? (
                          rowData.IsCompleted === true ? (
                            <div className="customCompleted"></div>
                          ) : (
                            <div className="customInprogress"></div>
                          )
                        ) : (
                          <div className="customNotApproved"></div>

                        )}
                      </>
                    )}
                    
                  ></Column>
                    <Column
                        field="AssignedTo"
                        header="Assigned To"
                    ></Column>
                  <Column
                    field="Name"
                    header="Name"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                   <Column
                    field="Property"
                    header="Property"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                   <Column
                    field="Priority"
                    header="Priority"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                   <Column
                    field="DueDate"
                    header="Due Date"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>{format(new Date(rowData.DueDate), 'dd/MM/yyyy')}</div>
                    )}
                  ></Column>
                </DataTable> : null }
                {displayTable.length === 0 ? <div className="emptyList">
                                     No record found
                                </div> : null}
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTasks;
