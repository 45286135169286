import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import {ListPropertyActiviyies,SearchProperty,searchAreaOfOperation} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Select, Spin } from "antd";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { ActionStatus } from "../../../Utilities/enum";
import "./activities.css"
import exportToExcel from "../../input-components/excel";

const PropertyActivitiesList = ({handleparentsuccess}) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const navigate = useNavigate();
  const dataTableRef = useRef(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const fetchRef = useRef(0);
  const fetchRef1 = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const [searchValue1, setSearchValues1] = useState("");
  const [postCode, setPostCode] = useState("Search for PostCode");
  const [selectedRows, setSelectedRows] = useState([]);
  
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) ListPropertyActivities(1,10);
  }, [userId]);

  const ListPropertyActivities = (currentPage, totalRows) => {
    const baseUrl = ListPropertyActiviyies;
    let data = {
        sortby: "CreatedDate",
        SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      PropertyId : searchValue,
      PostCode : searchValue1,
      PageNo: currentPage ,
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
            if(response.list.length != 0){
                setActivitiesList(response.list);
                setTotalPages(response.totalCount);
            }else{
                handleparentsuccess(["error", "No Data Found"]);
            }
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handlePageChange = (event, pageNumber) => {
    ListPropertyActivities(event.page + 1, event.rows);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchProperty;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (value) => {
    debounceFetcher(value);
    setSearchValues(value.value);
  };

  const debounceFetcherPostCode = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef1.current += 1;
      const fetchId = fetchRef1.current;
      setOptions1([]);
      const baseUrl = searchAreaOfOperation;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef1.current) {
              setOptions1(transformedData);
            }
          } 
        })
        .catch((error) => {
          //setFetching1(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handlePostCode = (value) => {
   const numericPart = value.label.split(',').pop().trim();
    setPostCode(numericPart)
    setSearchValues1(value.value);
    debounceFetcherPostCode(value);
  };
  const handleProtalEnquiryList = () => {
    ListPropertyActivities();
  };

  const handleClearProperty = () => {
    setSearchValues("");
  };

  const handleClearPostCode = () => {
    setSearchValues1("");
    setPostCode("Search for Post code")
  };

  const labelMapping1 = {
    "Property Address": "getFormatedAddress",
    "Property Type": "propertySaleRental",
    Owner: "primaryContactName",
    Enquiry: "countofEnquiry",
    Openhome: "countofOpenhome",
    "Sale offers": "countofsaleoffers",
    "Rental Applications": "countofRental",
  };
  

  const handleExportToExcel = () => {
    exportToExcel(selectedRows, "propertyActivities.xlsx", labelMapping1);
  };

  return (
    <div className="dashboard-agency">
      <div className="continer-dbag">
        <div className="dashboardagency-content">
          <div
            className="pa-content-top-level"
            
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      Property Activities
                    </div>
                  </div>
                </div>
              </div>
              <div className="propertyList-main-container" style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{width:'100%'}}>
                <div className="overview-card-container-row1 ">
                  <div className="mt-4 col-12 col-lg-3 col-md-4 col-sm-4 customWidth" >
                    <Select
                    style={{ width: '100%' }}
                      className="landingPageSearchs"
                      mode="single"
                      showSearch
                      placeholder="Search for Property"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options}
                      onChange={handleSearchProperty}
                      allowClear
                      onClear={handleClearProperty}
                    />
                  </div>
                  <div className="mt-4 col-12 col-lg-3 col-md-4 col-sm-4 customWidth">
                    <Select
                    style={{ width: '100%' }}
                      className="landingPageSearchs new-css-class"
                      mode="single"
                      showSearch
                      placeholder="Search for PostCode"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcherPostCode}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options1}
                      onChange={handlePostCode}
                      allowClear
                      onClear={handleClearPostCode} 
                      value={postCode}
                    />
                  </div>
                </div>
              </div>
              <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleProtalEnquiryList}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
                  <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleExportToExcel}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Export
                      </div>
                    </div>
                  </button>
                  </div>
              <div className="propertyList-main-container">

                <DataTable
                  ref={dataTableRef}
                  value={activitiesList}
                  selection={selectedRows}
                  onSelectionChange={(e) => setSelectedRows(e.value)}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[10,20,50,100]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  selectionMode="multiple"
                >
                      <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column
                    header="Property Address"
                    field="getFormatedAddress"
                    className="common-table-style"
                    body={(rowData) => (
                        <div
                          style={{ textDecoration: "underline", color: "blue" }}
                          className="property-link"
                          onClick={() =>
                            dynamicUrl(
                              navigate,
                              `properties?propertyUID=${rowData.propertyUniqueId}`
                            )
                          }
                        >
                          {rowData.getFormatedAddress}
                        </div>
                      )}
                  ></Column>
                  <Column
                    header="Listing Type"
                    field="primaryContactName"
                    className="common-table-style"
                    body={(rowData) => (
                        <div>{rowData.propertySaleRental === 1 ? "Sale" : "Rental"}</div>
                      )}
                  ></Column>
                  <Column
                    header="Owner"
                    field="primaryContactName"
                    className="common-table-style"
                  ></Column>
                  <Column
                    field="countofEnquiry"
                    header="Enquiry"
                    className="common-table-style"
                    
                  ></Column>
                  <Column
                    field="countofOpenhome"
                    header="Openhome"
                    className="common-table-style"
                    
                  ></Column>
                  <Column
                    field="countofsaleoffers"
                    header="Sale offers"
                    className="common-table-style"
                   
                  ></Column>
                  <Column
                    field="countofRental"
                    header="Rental Applications"
                    className="common-table-style"
                  ></Column>
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={totalPages}
                  rowsPerPageOptions={[10, 20, 50,100]}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyActivitiesList;
