import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { prominantalActivities } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import { BiDollar } from "react-icons/bi";

dayjs.extend(customParseFormat);

const initialValues = {
  isListedOnREA: false,
  isListedOnDomain: false,
  SocialMedia: false,
  Signboard: false,
  ThirdPartyName: "",
  AmountOfRebateDiscount: "",
  ObtainReferenceFromTenant: false,
  EnterIntoSignTenacyAgrmnt: false,
  UndertakeInspection: false,
  EffectRepairMaintainProprty: false,
  PayDisbursemnt: false,
  CollectReceiveRent: false,
  ServeNoticeForBreachTenancy: false,
  UndrtakeStpsToRecvrMony: false,
  RepresentPrincipalCourt: false,
  PayAccountsForAmount: false,
  AdvertisePropertyLetting: false,
  ReviewRentEndTenancy: false,
};

const AgreementPeriod = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("isListedOnREA", getDetails.isListedOnREA);
      setFieldValue("isListedOnDomain", getDetails.isListedOnDomain);
      setFieldValue("SocialMedia", getDetails.redirect);
      setFieldValue("Signboard", getDetails.socialMedia);
      setFieldValue("ThirdPartyName", getDetails.thirdPartyName);
      setFieldValue(
        "AmountOfRebateDiscount",
        getDetails.amountOfRebateDiscount
      );
      setFieldValue(
        "ObtainReferenceFromTenant",
        getDetails.obtainReferenceFromTenant
      );
      setFieldValue(
        "EnterIntoSignTenacyAgrmnt",
        getDetails.enterIntoSignTenacyAgrmnt
      );
      setFieldValue("UndertakeInspection", getDetails.undertakeInspection);
      setFieldValue(
        "EffectRepairMaintainProprty",
        getDetails.effectRepairMaintainProprty
      );
      setFieldValue("PayDisbursemnt", getDetails.payDisbursemnt);
      setFieldValue("CollectReceiveRent", getDetails.collectReceiveRent);
      setFieldValue(
        "ServeNoticeForBreachTenancy",
        getDetails.serveNoticeForBreachTenancy
      );
      setFieldValue(
        "UndrtakeStpsToRecvrMony",
        getDetails.undrtakeStpsToRecvrMony
      );
      setFieldValue(
        "RepresentPrincipalCourt",
        getDetails.representPrincipalCourt
      );
      setFieldValue("PayAccountsForAmount", getDetails.payAccountsForAmount);
      setFieldValue(
        "AdvertisePropertyLetting",
        getDetails.advertisePropertyLetting
      );
      setFieldValue("ReviewRentEndTenancy", getDetails.reviewRentEndTenancy);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: prominantalActivities,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("AgreementSignature");
  };

  const handleClear = () => {
    handleNextPage("ExpensesAndCharges");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  function formatDate(date) {
    if (!date) {
        date = new Date();
    } else if (!(date instanceof Date)) {
        date = new Date(date);
        if (isNaN(date.getTime())) {
            date = new Date();
        }
    }
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">

                  <div style={{ width: "100%" }}>

                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`ADVERTISING AND PROMOTION`}</div>

                      <div className="s1">
                      Manner of Advertising and Promotion:
                      </div>
                      <div className="pddpn-group">
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                          <div className="address-pair">
                            <div className="labelssss">Property Photos</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.isListedOnREA ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Social Media Campaign</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.socialMediaCampaign ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Listing on Domain.com.au</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {getDetails.isListedOnDomain ? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Floor Plans</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {values.floorPlans ? 'Yes' : 'No'}</div>
                          </div>
                        </div>


                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Listing on Realestate.com.au</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {getDetails.isListedOnREA? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                          <div className="address-pair">
                            <div className="labelssss">Signboard Outside the property</div>
                            <div style={{marginTop:'3px'}}>:</div>
                            <div className="valuessss"> {getDetails.signboardOutsideProperty? 'Yes' : 'No'}</div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div
                      className="pddpn-group2 pddpn-group3"

                    >
                      <div className="agreementLabel mb-3 tribulanceBreak" >{`INSPECTION OF PROPERTY`}</div>

                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                        <div className="address-pair">
                          <div className="labelssss">Inspection may be conducted in the following circumstances</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.inspection}</div>
                        </div>
                      </div>
                      <div className="s1">The Principal acknowledges, so far as reasonably practicable, that the principal has thoroughly inspected the Property prior to sale and that the Property is</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                        <div className="address-pair">
                          <div className="labelssss">Without risk to health and safety</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.withoutRisk === 2 ? "Yes" : "No"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>

                        <div className="address-pair">
                          <div className="labelssss">Subject to the defects and risks outlined in the contract for sale</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.withDefects === 2 ? "Yes" : "No"}</div>
                        </div>
                      </div>
                    </div>


                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`DISCLOSURE OF REBATES, DISCOUNTS AND COMMISSIONS`}</div>
                      <div className="s1">The agent is authorized to:</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Name of Source of Rebate</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {values.ObtainReferenceFromTenant ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Estimated Amount of Rebates, Discounts or Commissions</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.disclosureEstimateRebate1 ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className="s1">COOLING-OFF PERIOD: You (the vendor) have a cooling-off period for this agreement. If you do not wish to continue with this agreement you can cancel it until 5pm on the next business day or Saturday. How to do this, you should refer to the guide given to you before signing the Agreement.</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">WAIVER OF COOLING OFF PERIOD</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.waiverOfCoolinOffPeriod === 2 ? "Yes" : "No"}</div>
                        </div>
                      </div>
                      <div className="s1">The licensee and the principal/s acknowledge and confirm that before signing this agreement the licensee and The principal/s have read and understood and agree to the terms and conditions in part 3 on the reverse side of this agreement.</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">The approved guide “Agency Agreement for the Sale of Residential Property” was provided to the Principal</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.wasAgreementProvidedPrincipal === 2 ? "Yes" : "No"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Date Provided</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {formatDate(getDetails.dateProvided)}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementPeriod;
