import React, {useRef } from "react";
import { userForgotPassword, userOtpToken } from '../apiresources';
import './checkclass.css';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { useState } from 'react';
const initialValues = {
    checkotp: "",
}
const Check = (props) => {
    const navigate = useNavigate();
    const ToastRef = useRef();

    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        // validationSchema: checkSchema,
        onSubmit: (values, action) => {
            console.log(values);
            handleSubmitOtp();
            // action.resetForm();
        }
    })
    let value;
    let userId;
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('email')) { value = searchParams.get('email'); }
    const handlereset = () => {

        // Your .NET backend API URL

        const apiUrl = userForgotPassword;

        // Request body with login information

        const loginData = {

            Email: value

        }

        axios.post(apiUrl, loginData)

            .then((response) => {

                if (response.data.status == 1) {
                    console.log(response.data.object);
                    userId=response.data.object.userId;
                    ToastRef.current.notify(["error",response.data.message]);

                    alert(userId);

                } else {

                    ToastRef.current.notify(["error",response.data.message]);

                }

            })

            .catch((error) => {

                console.error('Error:', error);

            });

    }

    const handleSubmitOtp = () => {
        const apiUrl = userOtpToken;
        // Request body with login information

        const loginData = {

            token: values.checkotp,
            tokenType: 2

        };
        console.log(loginData)
        axios.post(apiUrl, loginData)

            .then((response) => {
                if (response.data.status == 1) {
                    userId=response.data.object.userId;
                    ToastRef.current.notify(["success",response.data.message]);
                    setTimeout(() => {
                        navigate('/setnewpassword?userId=' + userId);
                    }, 2000);
                    
                } else {

                    ToastRef.current.notify(["error",response.data.message]);
                }

            })

            .catch((error) => {

                console.error('Error:', error);

            });

    }

    return (
        <div className="col-12 section">
            <div className='col-xs-12 col-sm-12 col-md-10 col-lg-3 container'>
                <div className="header">
                <ToastAlert ref={ToastRef} />

                    <img
                        className="logo-forealproperty-one-color2"
                        alt=""
                        src=".\assets\images\logo.png"
                    />
                </div>
                <div className="content">
                    <div className="content2">
                        <div className="text-and-supporting-text">
                            <div className="icon"><img src='.\assets\images\key.png' className='icon_img-chc' /></div>
                            <div className="text">Check your email</div>
                            <div className="supporting-text">We sent a otp to {value}</div>
                        </div>
                    </div>
                    <div className="content1">
                        <form className='form' onSubmit={handleSubmit}>
                            <div className="check-input-otp">
                                <label htmlFor="otp" className='check-input-label'>Enter OTP*</label>

                                <div className="input-group mb-3">
                                    <input
                                        type='text'
                                        className="form-control tenant-date"
                                        placeholder="OTP"
                                        value={values.checkotp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="checkotp"
                                        required
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            <div className="submit1">
                                <button type='submit' className="button12" >Verify</button>
                            </div>
                            <div className='signup'>
                                <div className="promt">Didn’t receive the email? </div>
                                <div className="submitbutton"><div onClick={handlereset} className="a">Click to resend</div></div>
                            </div>

                            <div className='signin'>
                                <a href="/signin" className='a'> <img src='.\assets\images\left_arrow.png' /> Back to Log in</a>
                            </div>
                        </form>
                    </div>

                </div>
                <div className="footer">
                    <div className="text12">© FOREAL PROPERTY 2077</div>
                </div>
            </div>
        </div>
    );
}

export default Check;