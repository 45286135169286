import React, { Component } from 'react';
import './collapse.css';
import { BiChevronDown } from "react-icons/bi";



export default class Collapse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: this.props.initiallyCollapsed ? 'none' : 'block'
		}
	}

	functionName = (e) => {
		let id = e.target.id
		this.setState({
			display: id == this.props.id ? (this.state.display == 'none' ? 'block' : 'none')
				: ''
		})
	}
	render() {



		return (
			<div className="mt-2" style={{width:'100%'}}>
				<div >
					<div className='hover-class' style={{display: 'flex', alignItems: 'center' }} id={this.props.id} onClick={this.functionName}>
						<div className="">
							<div className="bg-white separator weight-500" style={{ color: 'var(--cyan-800, #155B75)', whiteSpace: 'nowrap', fontSize:'18px', fontWeight:'500' }} id={this.props.id}>{this.props.title}</div>
						</div>
						<div style={{ background:'#d6d4d4' , height:'0.5px' , width:'100%' , margin:'0px 10px 0px 10px' }}>
						</div>
						<div className="text-center " onClick={this.functionName}>
							<img onClick={this.functionName} id={this.props.id} className={(this.state.display == 'none') ? 'rotate-down hover-class' : 'rotate hover-class'} src={'../assets/images/icon.png'} width="17" style={{ marginTop: '-3.3px' }} />
                            {/* <BiChevronDown onClick={this.functionName}/> */}
						</div>
						
					</div>
					<br />
				</div>

				<div className="col-12 px-0 animate text-align-center" style={{ display: this.state.display, transition: 'visibility 1s, opacity 0.5s linear'}}>
					{this.props.data}
				</div>
				<div className="">
					<div className="row" >
						<div className="col-md-1">
						</div>
						<div className="col-md-10 text-right" style={{ fontSize:'12px',display: this.state.display }}>
							{this.props.actionData}
                        
						</div>
						<div className="col-md-1">
						</div>
					</div>
				</div>
			</div>
		);
	}
}