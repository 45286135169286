import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatemangmntagrmntpromotiondetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {dynamicUrl,} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { prominantalActivities } from "../../../components/CommonSection/Schema/schema";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);

const initialValues = {
    isListedOnREA: false,
    isListedOnDomain: false,
    SocialMedia: false,
    Signboard: false,
    ThirdPartyName: '',
    AmountOfRebateDiscount: '',
    ObtainReferenceFromTenant: false,
    EnterIntoSignTenacyAgrmnt: false,
    UndertakeInspection: false,
    EffectRepairMaintainProprty: false,
    PayDisbursemnt: false,
    CollectReceiveRent: false,
    ServeNoticeForBreachTenancy: false,
    UndrtakeStpsToRecvrMony: false,
    RepresentPrincipalCourt: false,
    PayAccountsForAmount: false,
    AdvertisePropertyLetting: false,
    ReviewRentEndTenancy: false,
};

const AgreementPeriod = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("isListedOnREA",getDetails.isListedOnREA)
          setFieldValue("isListedOnDomain",getDetails.isListedOnDomain)
          setFieldValue("SocialMedia",getDetails.redirect)
          setFieldValue("Signboard",getDetails.socialMedia)
          setFieldValue("ThirdPartyName",getDetails.thirdPartyName)
          setFieldValue("AmountOfRebateDiscount",getDetails.amountOfRebateDiscount)
          setFieldValue("ObtainReferenceFromTenant",getDetails.obtainReferenceFromTenant)
          setFieldValue("EnterIntoSignTenacyAgrmnt",getDetails.enterIntoSignTenacyAgrmnt)
          setFieldValue("UndertakeInspection",getDetails.undertakeInspection)
          setFieldValue("EffectRepairMaintainProprty",getDetails.effectRepairMaintainProprty)
          setFieldValue("PayDisbursemnt",getDetails.payDisbursemnt)
          setFieldValue("CollectReceiveRent",getDetails.collectReceiveRent)
          setFieldValue("ServeNoticeForBreachTenancy",getDetails.serveNoticeForBreachTenancy)
          setFieldValue("UndrtakeStpsToRecvrMony",getDetails.undrtakeStpsToRecvrMony)
          setFieldValue("RepresentPrincipalCourt",getDetails.representPrincipalCourt)
          setFieldValue("PayAccountsForAmount",getDetails.payAccountsForAmount)
          setFieldValue("AdvertisePropertyLetting",getDetails.advertisePropertyLetting)
          setFieldValue("ReviewRentEndTenancy",getDetails.reviewRentEndTenancy)
        }
      }, [getDetails, reportTo]);

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: prominantalActivities,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            isListedOnREA: values.isListedOnREA,
            isListedOnDomain: values.isListedOnDomain,
            SocialMedia: values.SocialMedia,
            Signboard: values.Signboard,
            ThirdPartyName: values.ThirdPartyName,
            AmountOfRebateDiscount: values.AmountOfRebateDiscount,
            ObtainReferenceFromTenant: values.ObtainReferenceFromTenant,
            EnterIntoSignTenacyAgrmnt: values.EnterIntoSignTenacyAgrmnt,
            UndertakeInspection: values.UndertakeInspection,
            EffectRepairMaintainProprty: values.EffectRepairMaintainProprty,
            PayDisbursemnt: values.PayDisbursemnt,
            CollectReceiveRent: values.CollectReceiveRent,
            ServeNoticeForBreachTenancy: values.ServeNoticeForBreachTenancy,
            UndrtakeStpsToRecvrMony: values.UndrtakeStpsToRecvrMony,
            RepresentPrincipalCourt: values.RepresentPrincipalCourt,
            PayAccountsForAmount: values.PayAccountsForAmount,
            AdvertisePropertyLetting: values.AdvertisePropertyLetting,
            ReviewRentEndTenancy: values.ReviewRentEndTenancy,
        };
        const url = updatemangmntagrmntpromotiondetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("RepairAndMaintainance")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };


    const handleClear = () => {
        handleNextPage("TribunalFees")
    };

    const onChangeSwitch = (e, label) => {
        if (label === "isListedOnREA") {
          setFieldValue("isListedOnREA", e);
        } else if (label === "isListedOnDomain") {
          setFieldValue("isListedOnDomain", e);
        }
        else if (label === "SocialMedia") {
            setFieldValue("SocialMedia", e);
          }
          else if (label === "Signboard") {
            setFieldValue("Signboard", e);
          }
          else if (label === "ObtainReferenceFromTenant") {
            setFieldValue("ObtainReferenceFromTenant", e);
          }
          else if (label === "EnterIntoSignTenacyAgrmnt") {
            setFieldValue("EnterIntoSignTenacyAgrmnt", e);
          }
          else if (label === "UndertakeInspection") {
            setFieldValue("UndertakeInspection", e);
          }
          else if (label === "EffectRepairMaintainProprty") {
            setFieldValue("EffectRepairMaintainProprty", e);
          }
          else if (label === "PayDisbursemnt") {
            setFieldValue("PayDisbursemnt", e);
          }
          else if (label === "CollectReceiveRent") {
            setFieldValue("CollectReceiveRent", e);
          }
          else if (label === "ServeNoticeForBreachTenancy") {
            setFieldValue("ServeNoticeForBreachTenancy", e);
          }
          else if (label === "UndrtakeStpsToRecvrMony") {
            setFieldValue("UndrtakeStpsToRecvrMony", e);
          }
          else if (label === "RepresentPrincipalCourt") {
            setFieldValue("RepresentPrincipalCourt", e);
          }
          else if (label === "PayAccountsForAmount") {
            setFieldValue("PayAccountsForAmount", e);
          }
          else if (label === "AdvertisePropertyLetting") {
            setFieldValue("AdvertisePropertyLetting", e);
          }
          else if (label === "ReviewRentEndTenancy") {
            setFieldValue("ReviewRentEndTenancy", e);
          }
      };

      const handleCancle = () => {
        navigate("/agency/ListAgreement");
      }
      useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                            
                                        <div style={{width:"100%"}}>
                                                  <Collapse
                                                id={"Agreement-Authority"}
                                                title={"Promotional Activities"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className='s1 p-3'>The Rental Property is to be advertised and/or otherwise promoted as followed</div>
                                                        <div className="pddpn-group">
                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Listing on Realestate.com.au</div>
                                                                <div><Switch checked={values.isListedOnREA} onChange={(e) => onChangeSwitch(e, "isListedOnREA")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-3 s1">Social Media Campaign</div>
                                                                <div><Switch checked={values.SocialMedia} onChange={(e) => onChangeSwitch(e, "SocialMedia")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Listing on Domain.com.au</div>
                                                                <div><Switch checked={values.isListedOnDomain} onChange={(e) => onChangeSwitch(e, "isListedOnDomain")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Sign board</div>
                                                                <div><Switch checked={values.Signboard} onChange={(e) => onChangeSwitch(e, "Signboard")} /></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                } />
                                                    <Collapse
                                                id={"The Agent shall be entitled to the following ongoing fees"}
                                                title={"Disclosures of Rebates, Discounts or Commissions in Respect of Expenses"}
                                                data={
                                                    <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            isMandatory
                                                            label="Name of Third party"
                                                            name="ThirdPartyName"
                                                            value={values.ThirdPartyName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ThirdPartyName}
                                                            touched={touched.ThirdPartyName}
                                                        />
                                                         <TextBox
                                                            isMandatory
                                                            label="Estimated Amount of Rebate, Discount or Commission"
                                                            name="AmountOfRebateDiscount"
                                                            value={values.AmountOfRebateDiscount}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.AmountOfRebateDiscount}
                                                            touched={touched.AmountOfRebateDiscount}
                                                            icon={
                                                                <BiDollar height="100%" width="100%" />
                                                            }

                                                        />
                                                    </div>
                                                } />
                                                                                                  <Collapse
                                                id={"Agreement-Authority"}
                                                title={"Agent’s Authority for Managing the Property"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className='s1 pl-3' >The agent is authorized to:</div>
                                                        <div >
                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Obtain references from prospective tenants, arrange inspections of the property by prospective tenants and choose a tenant</div>
                                                                <div><Switch checked={values.ObtainReferenceFromTenant} onChange={(e) => onChangeSwitch(e, "ObtainReferenceFromTenant")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-3 s1">Enter into and sign a tenancy agreement (specifying the term for which the property may be let)</div>
                                                                <div><Switch checked={values.EnterIntoSignTenacyAgrmnt} onChange={(e) => onChangeSwitch(e, "EnterIntoSignTenacyAgrmnt")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Undertake inspections of the property</div>
                                                                <div><Switch checked={values.UndertakeInspection} onChange={(e) => onChangeSwitch(e, "UndertakeInspection")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Effect repairs to and maintain the property or engage tradespersons to do so up to an approved amount without obtaining the approval of Principal</div>
                                                                <div><Switch checked={values.EffectRepairMaintainProprty} onChange={(e) => onChangeSwitch(e, "EffectRepairMaintainProprty")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Pay disbursements and expenses incurred in connection with the agent’s management of the property</div>
                                                                <div><Switch checked={values.PayDisbursemnt} onChange={(e) => onChangeSwitch(e, "PayDisbursemnt")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Collect rent, receive, claim and disburse rental bond money</div>
                                                                <div><Switch checked={values.CollectReceiveRent} onChange={(e) => onChangeSwitch(e, "CollectReceiveRent")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Serve notices for breach of the tenancy agreement or terminate the tenancy agreement</div>
                                                                <div><Switch checked={values.ServeNoticeForBreachTenancy} onChange={(e) => onChangeSwitch(e, "ServeNoticeForBreachTenancy")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Undertake the necessary steps to obtain vacant possession and recover any money owing to the Principal in relation to the tenancy of the property</div>
                                                                <div><Switch checked={values.UndrtakeStpsToRecvrMony} onChange={(e) => onChangeSwitch(e, "UndrtakeStpsToRecvrMony")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Represent the Principal in any tribunal or court proceedings in respect of the tenancy of the property</div>
                                                                <div><Switch checked={values.RepresentPrincipalCourt} onChange={(e) => onChangeSwitch(e, "RepresentPrincipalCourt")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Pay accounts for amounts owing in relation to the property (for example, water rates and charges, council rates, maintenance expenses and owners corporation levies)</div>
                                                                <div><Switch checked={values.PayAccountsForAmount} onChange={(e) => onChangeSwitch(e, "PayAccountsForAmount")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Advertise the property for letting or re-letting,</div>
                                                                <div><Switch checked={values.AdvertisePropertyLetting} onChange={(e) => onChangeSwitch(e, "AdvertisePropertyLetting")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Review the rent at the end of a tenancy</div>
                                                                <div><Switch checked={values.ReviewRentEndTenancy} onChange={(e) => onChangeSwitch(e, "ReviewRentEndTenancy")} /></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                } />
                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className=" pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgreementPeriod;
