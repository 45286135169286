import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updatemangmntagrmnttribunalinsurancedetails } from "../../../../components/CommonSection/apiresources";
import TextBox from "../../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { tribunalFees } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import { BiDollar } from "react-icons/bi";
dayjs.extend(customParseFormat);

const initialValues = {
  ApplicationFeesForUtilites: "",
  OfficeExpenses: "",
  Redirect: false,
  STRATA: false,
  Water: false,
  CouncilRates: false,
  Insurance: false,
  PAccountName: "",
  PBSB: "",
  PAccountNo: "",
};

const TribulantFees = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue(
        "ApplicationFeesForUtilites",
        getDetails.applicationFeesForUtilites
      );
      setFieldValue("OfficeExpenses", getDetails.officeExpenses);
      setFieldValue("Redirect", getDetails.redirect);
      setFieldValue("STRATA", getDetails.strata);
      setFieldValue("Water", getDetails.water);
      setFieldValue("Insurance", getDetails.insurance);
      setFieldValue("PAccountName", getDetails.pAccountName);
      setFieldValue("PBSB", getDetails.pbsb);
      setFieldValue("PAccountNo", getDetails.pAccountNo);
      setFieldValue("CouncilRates", getDetails.councilRates)

    }
  }, [getDetails, reportTo]);


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: tribunalFees,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      OfficeExpenses: values.OfficeExpenses,
      Redirect: values.Redirect,
      STRATA: values.STRATA,
      Water: values.Water,
      CouncilRates: values.CouncilRates,
      Insurance: values.Insurance,
      PAccountName: values.PAccountName,
      PBSB: values.PBSB,
      PAccountNo: values.PAccountNo,
    };
    const url = updatemangmntagrmnttribunalinsurancedetails;

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleNextPage("PromotionalActivities");
          ToastRef.current.notify(["success", returnData.message]);
          //navigate(`/agency/ListOpenHome`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    handleNextPage("FeesAndCharges");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                    <div className="pddpn-group2 pddpn-group3">
                      <div className=" agreementLabel mb-3 tribulanceBreak" style={{ paddingLeft: '0px' }} >{`Tribunal Fees & Insurance Claims (Charges In Relation To The Management Of The Property)`}</div>
                      <div className="s1">
                        Application fees for NCAT, Sherriff or other
                        statutory authorities. Preparation of NCAT case.
                        Organizing repairs, maintenance or refurbishments.
                        Calculation of payments of any outgoings.
                      </div>
                      <div className="s1">
                        Office expenses in respect of each management being
                        postage, phone calls and out of pocket expenses
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">NCAT/Tribunal Application Fee</div>
                          <div className="valuessss">: {"At Cost"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Sherriff Fee</div>
                          <div className="valuessss">: {"At Cost"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Other Statutory  Fee</div>
                          <div className="valuessss">: {"At Cost"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Postage </div>
                          <div className="valuessss">: {"At Cost"}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Amount Per Hour</div>
                          <div className="valuessss">: <BiDollar/>{values.ApplicationFeesForUtilites}</div>
                        </div>
                      </div>
                    </div>
                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`I Authorize Foreal Property To Pay The Following Accounts`}</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="s1">
                          Please provide copy of rates for payment reference
                        </div>
                        <div className="address-pair">
                          <div className="labelssss">Redirect</div>
                          <div className="valuessss">: {values.Redirect ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Strata</div>
                          <div className="valuessss">: {values.STRATA ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Water</div>
                          <div className="valuessss">: {values.Water ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Council Rates</div>
                          <div className="valuessss">: {values.CouncilRates ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Insurance</div>
                          <div className="valuessss">: {values.Insurance ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="pddpn-group2 pddpn-group3">
                      <div className=" agreementLabel mb-3 " >{`Rental Monies to Be Deposited Into the Following Account`}</div>
                      <div
                        className=""
                      >
                        <TextBox
                          isMandatory
                          label="A/C Name"
                          name="PAccountName"
                          value={values.PAccountName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PAccountName}
                          touched={touched.PAccountName}
                        />
                         <TextBox
                          isMandatory
                          label="BSB"
                          name="PBSB"
                          value={values.PBSB}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PBSB}
                          touched={touched.PBSB}
                        />
                        <TextBox
                          isMandatory
                          label="A/C No"
                          name="PAccountNo"
                          value={values.PAccountNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PAccountNo}
                          touched={touched.PAccountNo}
                        />
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TribulantFees;
