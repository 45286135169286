import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { BiDollar } from "react-icons/bi";
import { MdPercent } from "react-icons/md";
import TextBox from "../../../input-components/textbox";
import {updatesalesagrmntremunerationdetails} from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { ActionStatus } from "../../../../Utilities/enum";

dayjs.extend(customParseFormat);

const initialValues = {
  SalePriceFlatFee:'',
  LicenceeEstimateSalePrice:false,
  GSTInclusiveRenumeration:false,
  IsEFT:'',
  PAccountName:'',
  PBSB:'',
  PAccountNo:''
};

const FeesAndCharges = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setValues({ ...values, ...getDetails });
      setFieldValue("SalePriceFlatFee", getDetails.salePriceFlatFee)
      setFieldValue("LicenceeEstimateSalePrice",getDetails.licenceeEstimateSalePrice)
      setFieldValue("GSTInclusiveRenumeration",getDetails.gstInclusiveRenumeration)
      setFieldValue("IsEFT",getDetails.isEFT)
      setFieldValue("PAccountName",getDetails.pAccountName)
      setFieldValue("PBSB",getDetails.pbsb)
      setFieldValue("PAccountNo",getDetails.pAccountNo)
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: feesAndChargesValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    debugger;
    let formData = {
      AgreementId: getDetails.id,
      SalePriceFlatFee: parseInt(values.SalePriceFlatFee),
      LicenceeEstimateSalePrice: parseInt(values.LicenceeEstimateSalePrice),
      GSTInclusiveRenumeration: parseInt(values.GSTInclusiveRenumeration),
      IsEFT: values.IsEFT ? 2 : 1,
      PAccountName: values.PAccountName,
      PBSB: values.PBSB,
      PAccountNo: values.PAccountNo,
    };
    const url = updatesalesagrmntremunerationdetails;

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleNextPage("ExpensesAndCharges");
          ToastRef.current.notify(["success", returnData.message]);
          //navigate(`/agency/ListOpenHome`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };


  const handleClear = () => {
    handleNextPage("ManagementAgreement");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>

                    <div className="pddpn-group2 pddpn-group3">
                      <div className="col-lg-12 col-sm-12 agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`REMUNERATION`}</div>

                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">(e.g. % of sale price; flat fee; formula e.g. a combination of % and flat fee) </div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <MdPercent style={{marginTop:"-5px"}} /> {getDetails.salePriceFlatFee}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">e.g. in relation to the Licensee’s ESTIMATE of the sale price of</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <BiDollar style={{marginTop:"-5px"}} /> {getDetails.licenceeEstimateSalePrice}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">the GST inclusive renumeration would be</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <BiDollar  style={{marginTop:"-5px"}} /> {getDetails.gstInclusiveRenumeration}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="pddpn-group2 pddpn-group3"
                    >
                      <div className="col-lg-12 col-sm-12 agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`Payment to Principal`}</div>
                      <div className="s1 ">If money held by the Agent becomes due to the principal,then the Principal directs the Agent to pay that money by</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Electronic Funds Transfer (EFT)</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss">  {getDetails.isEFT ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                      <TextBox
                          label="A/C Name"
                          name="PAccountName"
                          value={values.PAccountName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PAccountName}
                          touched={touched.PAccountName}
                        />
                         <TextBox
                          label="BSB"
                          name="PBSB"
                          value={values.PBSB}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PBSB}
                          touched={touched.PBSB}
                        />
                        <TextBox
                          label="A/C No"
                          name="PAccountNo"
                          value={values.PAccountNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.PAccountNo}
                          touched={touched.PAccountNo}
                        />
                    </div>

                  </div>
                </div>
             
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesAndCharges;
