import React, { useEffect, useState, useRef } from "react";
import { VerifyDigitalId } from "../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "./client";
import { useSpinnerContext } from "./SpinnerContext";
import { ActionStatus } from "./enum";
import ToastAlert from "../components/CommonSection/ToastAlert/ToastAlert";

const Verify = ({ getDetails, isVerified, verificationType }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  const handleUpdateVerification = (code, contactId) => {
    var formData = {
      Grant_code: code,
      ContactId: contactId[0],
      VerificationType: verificationType,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      VerifyDigitalId,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          //alert("Verified");
          isVerified();
          ToastRef.current.notify(["success", "Verified"]);
        } else {
          //alert("failed");
          ToastRef.current.notify(["error", "failed"]);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    //script.src = "https://digitalid-sandbox.com/sdk/app.js";
    script.src = "https://digitalid.com/sdk/app.js";
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      setScriptLoaded(true);
    };

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    debugger;
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    const isRefMatched = getDetails.documentReceipients.some(
      (doc) => doc.contactId === parseInt(ref)
    );
    const matchedDocumentIds = getDetails.documentReceipients
      .filter((doc) => doc.contactId === parseInt(ref))
      .map((doc) => doc.contactId);
    if (scriptLoaded) {
      debugger;
      /* Verify with Digital iD */
      window.digitalId.init({
         clientId:
          verificationType == 1
            ? "ctid0fBuyYAIZUfdYvheKxfsDS"
            : "ctid5kpdJJqA5QJPkvlTKjGx4T",
            
        // clientId:
        //   verificationType == 1
        //     ? "ctid5HXjw9Ca3xsEvUblvJ6D1Y"
        //     : "ctid0Ka2eEupnp2G9ac0J6EAv9",
        uxMode: "popup",
        onLoadComplete: function () {},
        onComplete: function (msg) {
          console.log(msg);
          handleUpdateVerification(msg.code, matchedDocumentIds);
        },
        onClick: function (opts) {},
        onKeepAlive: function () {},
      });
    }
  }, [scriptLoaded]);

  return (
    <div id="digitalid-verify">
      <ToastAlert ref={ToastRef} />
    </div>
  );
};

export default Verify;
