import "./App.css";
import Signin from "../components/CommonSection/SignIn/Signin";
import Temp from "../components/temp/Temp";
import SignUp from "../components/CommonSection/SignUp/SignUp";
import ForgotPassword from "../components/CommonSection/ForgotPassword/ForgotPassword";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Check from "../components/CommonSection/Check_Email/Check";
import PrivateRoutes from "./PrivateRoutes";
import Selection from "../components/CommonSection/Selection/Selection";
import AddProperty from "../components/Landlord/AddProperty/AddProperty";
import Verify from "../components/Landlord/Verify/Verify";
import Ownership from "../components/Landlord/Ownership/Ownership";
import Addonwer from "../components/Landlord/AddMoreOwners/Addowner";
import Completetion_np from "../components/Landlord/Completion-newproperty/Completion_np";
import Verifytenant from "../components/TenantSection/VerifyTenant/Verifytenant";
import AddressHistory from "../components/TenantSection/AddressHistory/AddressHistory";
import PropertyDetails from "../components/TenantSection/PropertyDetails/PropertyDetails";
import Employment from "../components/TenantSection/Employment/Employment";
import Questionnaire from "../components/TenantSection/Questionnaire/Questionnaire";
import Dashboard from "../components/Dashboard/Agency/Dashboard-agency/DashboardAgency";
import Documentation from "../components/TenantSection/Documentation/Documentation";
import Consent from "../components/TenantSection/Consent/Consent";
import PrincipalDetails from "../components/AgencySection/PrincipalDetails/PrincipalDetails";
import LicenseDetails from "../components/AgencySection/LicenseDetails/LicenseDetails";
import AddCompanyDetails from "../components/AgencySection/AddCompanyDetails/AddCompanyDetails";
import InsuranceDetails from "../components/AgencySection/InsuranceDetails/InsuranceDetails";
import PaymentDetails from "../components/AgencySection/PaymentDetails/PaymentDetails";
import VerifyAgency from "../components/AgencySection/VerifyAgency/VerifyAgency";
import ApproveAgency from "../components/AgencySection/ApproveAgency/ApproveAgency";
import SetNewPassword from "../components/CommonSection/SetNewPassword/SetNewPassword";
import PasswordResetConfirmation from "../components/CommonSection/PasswordResetConfirmation/PasswordResetConfirmation";
import Statuspage from "../components/AgencySection/StatusPage/Statuspage";
import AgencyCompletion from "../components/AgencySection/AgencyCompletion/AgencyCompletion";
import DashboardAgency from "../components/Dashboard/Agency/Dashboard-agency/DashboardAgency";
import LandingPage from "../components/CommonSection/LandingPage/LandingPage";
import PropertiesAgency from "../components/Dashboard/Agency/PropertiesAgecny/PropertiesAgency";
import Profile from "../components/Dashboard/Profile/Profile";
import Example from "../components/temp/Example";
import AddAgent from "../components/Dashboard/Dashboard-agent/AddAgent";
import AgentList from "../components/Dashboard/Dashboard-agent/AgentList";
import EmailVerificationAgent from "../components/CommonSection/EmailVerificationAgent/EmailVerificationAgent";
import PersonalDetailsProfile from "../components/Dashboard/Profile/PersonalDetailsProfile";
import { SpinnerProvider } from "../Utilities/SpinnerContext";
import CustomSpinner from "../Utilities/Spinner/Spinner";

import ListAllProperties from "../components/Dashboard/Agency/PropertiesAgecny/listAllProperties"
import AddNewProperty from "../components/Dashboard/Agency/PropertiesAgecny/addNewProperty";
import AddNewPropertiesListings from "../components/Dashboard/Agency/PropertiesAgecny/addNewPropertyListing";
import PropertiesList from "../components/Dashboard/Agency/PropertiesAgecny/propertyList";
import PropertiesAttributes from "../components/Dashboard/Agency/PropertiesAgecny/propertyAttributes";
import PropertiesLegal from "../components/Dashboard/Agency/PropertiesAgecny/propertyLegal";
import PropertiesListings from "../components/Dashboard/Agency/PropertiesAgecny/propertyListings";
import PropertiesDocuments from "../components/Dashboard/Agency/PropertiesAgecny/propertyDocuments";
import PropertiesOwnerShip from "../components/Dashboard/Agency/PropertiesAgecny/propertyOwnerShip";
import PropertiesPhoto from "../components/Dashboard/Agency/PropertiesAgecny/propertyPhoto";
import AddProfile from "../components/CommonSection/Selection/AddProfile";
import SelectAddProfile from "../components/CommonSection/Selection/SelectAddProfile";

import ListLandingPage from "../components/CommonSection/LandingPage/listDetails";

import ContactList from "../src/Components/Areas/Agency/Contact/contactList";
import Contacts from "../src/Components/Areas/Agency/Contact/contacts";
import ContactDetails from "../src/Components/Areas/Agency/Contact/contacts";
import ContactPrefference from "../src/Components/Areas/Agency/Contact/contactPrefference";
import ContactOthers from "../src/Components/Areas/Agency/Contact/contactOthers";
import LandingPageDetails from "../components/CommonSection/LandingPage/detailsPage";
import Header from "../components/CommonSection/Header/Header";
import AgentDashboard from "./Components/Areas/Agent/AgentDashboard";
import AgentProfile from "./Components/Areas/Agent/AgentProfile";
import ListOpenHome from "./Components/openHome/listOPenHome";
import AddOpenHome from "./Components/openHome/addOpenHome";
import OpenHomeDetails from "./Components/openHome/openHomeDetails";
import AddNewUserOpenHome from "./Components/openHome/addNewUser";

import ListPortalEnquiry from "./Components/PortalEnquiry/listPortalEnquiry";

import ListActivities from "./Components/Activities/MainActivities";
import ListTask from "./Components/Task/listTask";
import AddTask from "./Components/Task/addTask";
import TaskDetails from "./Components/Task/taskDetails";

import ListAgreement from "./Components/ManagementAgreement/MainListingPage";
import ManagementAgreement from "./Components/ManagementAgreement/managemantAgreement";
import ManagementAgreementTabs from "./Components/ManagementAgreement/agreementTabs";
import AddNewPropertyAgreement from "./Components/ManagementAgreement/addNewProperty";
import AgentAgreement from "./Components/ManagementAgreement/agentAgreement";
import AgreementPeriod from "./Components/ManagementAgreement/agreementPeriod";
import FeesAndCharges from "./Components/ManagementAgreement/feesAndCharges";
import TribunalFees from "./Components/ManagementAgreement/TribunalFees";
import PromotionalActivities from "./Components/ManagementAgreement/PromotionalActivities";
import RepairAndMaintainance from "./Components/ManagementAgreement/RepairsAndMaintenance";
import AddNewOwnerShipAgreement from "./Components/ManagementAgreement/AddNewOwnerShip";
import AgreementSignature from "./Components/ManagementAgreement/signature";
import AgreementSummary from "./Components/ManagementAgreement/summary";

import OwnerListAgreement from "./Components/ManagementAgreement/OwnwerAgreement/ListAgreementType";
import OwnerManagementAgreement from "./Components/ManagementAgreement/OwnwerAgreement/managemantAgreement";
import OwnerManagementAgreementTabs from "./Components/ManagementAgreement/OwnwerAgreement/agreementTabs";
import OwnerAddNewPropertyAgreement from "./Components/ManagementAgreement/OwnwerAgreement/AgreementViewPage";
import OwnerAgentAgreement from "./Components/ManagementAgreement/OwnwerAgreement/agentAgreement";
import OwnerAgreementPeriod from "./Components/ManagementAgreement/OwnwerAgreement/agreementPeriod";
import OwnerFeesAndCharges from "./Components/ManagementAgreement/OwnwerAgreement/feesAndCharges";
import OwnerTribunalFees from "./Components/ManagementAgreement/OwnwerAgreement/TribunalFees";
import OwnerPromotionalActivities from "./Components/ManagementAgreement/OwnwerAgreement/PromotionalActivities";
import OwnerRepairAndMaintainance from "./Components/ManagementAgreement/OwnwerAgreement/RepairsAndMaintenance";
import OwnerAddNewOwnerShipAgreement from "./Components/ManagementAgreement/OwnwerAgreement/AddNewOwnerShip";
import OwnerAgreementSignature from "./Components/ManagementAgreement/OwnwerAgreement/signature";
import TenantPropertyDetails from "../components/TenantSection/PropertyDetails/PropertyDetails";



import TenancyAgreementTabs from "./Components/TenantAgreement/tenantAgreementTabs";
import AddNewPropertyTenancy from "./Components/TenantAgreement/addNewProperty";

import AgreementViewAddNewProperty from "./Components/TenantAgreement/TenantLeaseAgreementView/addNewProperty";
import AgreementViewtenantAgreementTabs from "./Components/TenantAgreement/TenantLeaseAgreementView/tenantAgreementTabs";

import ViewPropertyDetails from "../components/Dashboard/Agency/PropertiesAgecny/viewPropertyDetails";
import UploadAgreement from "../components/Dashboard/Agency/PropertiesAgecny/uploadAgreement";
import UploadAgreementManagement from "../components/Dashboard/Agency/PropertiesAgecny/uploadAgreementManagement";
import UploadAgreementTenant from "../components/Dashboard/Agency/PropertiesAgecny/uploadAgreementTenant";

import SaleAgreementTabs from "./Components/SaleAgreement/agreementTabs";
import OwnerSalesAgreementTabs from "./Components/SaleAgreement/OwnwerAgreement/agreementTabs";

import ListTicket from "./Components/Support/ListTickets";
import ListTicketDetails from "./Components/Support/ListTicketDetails"


function App() {
  return (
    <>
      <SpinnerProvider>
        <div>
          <CustomSpinner />
        </div>

        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoutes />}>
              {/* Landloard Screens*/}

              {/* Profile change Route */}
              <Route path="/selection" element={<Selection />} />
              <Route path="/AddProfile" element={<AddProfile />} />
              <Route path="/SelectAddProfile" element={<SelectAddProfile />} />
              {/* End Route */}

              <Route path="/addproperty" element={<AddProperty />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/ownership" element={<Ownership />} />
              <Route path="/addowner" element={<Addonwer />} />
              <Route path="/completion" element={<Completetion_np />} />

              {/* Agency Screens */}

              <Route
                path="/agency/principaldetails"
                element={<PrincipalDetails />}
              />
              <Route
                path="/agency/licensedetails"
                element={<LicenseDetails />}
              />
              <Route
                path="/agency/addcompanydetails"
                element={<AddCompanyDetails />}
              />
              <Route
                path="/agency/insurancedetails"
                element={<InsuranceDetails />}
              />
              <Route
                path="/agency/paymentdetails"
                element={<PaymentDetails />}
              />
              <Route path="/agency/verifyagency" element={<VerifyAgency />} />

              <Route path="/agency/statuspage" element={<Statuspage />} />
              <Route
                path="/agency/agencycompletion"
                element={<AgencyCompletion />}
              />
              {/* Agent Rout */}
              <Route path="/agent/dashboard" element={<AgentDashboard />} />
              <Route path="/agent/Profile" element={<AgentProfile />} />

              {/* //Agency Route */}
              <Route path="/agency/dashboard" element={<DashboardAgency />} />
              <Route path="/agency/properties" element={<PropertiesAgency />} />
              <Route path="/agency/profile" element={<Profile />} />
              <Route
                path="/agency/propertiesList"
                element={<PropertiesList />}
              />
              <Route
                path="/agency/propertiesAttributes"
                element={<PropertiesAttributes />}
              />
              <Route
                path="/agency/propertiesLegal"
                element={<PropertiesLegal />}
              />
              <Route
                path="/agency/propertiesListings"
                element={<PropertiesListings />}
              />
              <Route
                path="/agency/propertiesDocuments"
                element={<PropertiesDocuments />}
              />
              <Route
                path="/agency/propertiesOwnerShip"
                element={<PropertiesOwnerShip />}
              />
              <Route
                path="/agency/propertiesPhoto"
                element={<PropertiesPhoto />}
              />

              <Route
                path="/agent/propertiesList"
                element={<PropertiesList />}
              />
              <Route path="/agent/ContactList" element={<ContactList />} />
              <Route
                path="/agent/ContactDetails"
                element={<ContactDetails />}
              />
              <Route path="/agent/properties" element={<PropertiesAgency />} />

              <Route path="/propertiesList" element={<PropertiesList />} />
            </Route>
            {/* {["/", "/signin"].map((path, index) =>
            <Route path={path} element={<Signin />} key={index} />)} */}

            {/* Login-Forgote-Common */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/listLandingPage" element={<ListLandingPage />} />
            <Route
              path="/landingPageDetails"
              element={<LandingPageDetails />}
            />
            <Route path="/signin" element={<Signin />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/check" element={<Check />} />
            <Route path="/setnewpassword" element={<SetNewPassword />} />
            <Route
              path="/passwordresetconfirmation"
              element={<PasswordResetConfirmation />}
            />
            <Route
              path="/emailverificationagent"
              element={<EmailVerificationAgent />}
            />
            <Route path="/temp" element={<Temp />} />
            <Route path="/example" element={<Example />} />

            <Route path="/agency/approveagency" element={<ApproveAgency />} />

            {/* Tenant Screens */}
            <Route path="/propertydetails" element={<PropertyDetails />} />
            <Route path="/verifytenant" element={<Verifytenant />} />
            <Route path="/addresshistory" element={<AddressHistory />} />
            <Route path="/employment" element={<Employment />} />
            <Route path="questionnaire" element={<Questionnaire />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/consent" element={<Consent />} />
            <Route
              path="/personaldetailsprofile"
              element={<PersonalDetailsProfile />}
            />

            <Route path="/agency/AgentList" element={<AgentList />} />
            <Route path="/agency/agents" element={<AgentList />} />
            <Route path="/agency/AddAgent" element={<AddAgent />} />

            <Route path="/agency/ContactList" element={<ContactList />} />
            <Route path="/agency/Contacts" element={<Contacts />} />
            <Route path="/agency/ContactDetails" element={<ContactDetails />} />
            <Route
              path="/agency/ContactPrefference"
              element={<ContactPrefference />}
            />
            <Route path="/agency/ContactOthers" element={<ContactOthers />} />

            <Route path="/agency/ListOpenHome" element={<ListOpenHome />} />
            <Route path="/agency/AddOpenHome" element={<AddOpenHome />} />
            <Route
              path="/agency/OpenHomeDetails"
              element={<OpenHomeDetails />}
            />
            <Route
              path="/agency/AddNewUserOpenHome"
              element={<AddNewUserOpenHome />}
            />

            <Route path="/agent/ListOpenHome" element={<ListOpenHome />} />
            <Route path="/agent/AddOpenHome" element={<AddOpenHome />} />
            <Route
              path="/agent/OpenHomeDetails"
              element={<OpenHomeDetails />}
            />
            <Route
              path="/agent/AddNewUserOpenHome"
              element={<AddNewUserOpenHome />}
            />

            <Route
              path="/agency/ListPortalEnquiry"
              element={<ListPortalEnquiry />}
            />
            <Route
              path="/agent/ListPortalEnquiry"
              element={<ListPortalEnquiry />}
            />

            <Route path="/agency/ListActivities" element={<ListActivities />} />
            <Route path="/agent/ListActivities" element={<ListActivities />} />

            <Route path="/agency/ListTask" element={<ListTask />} />
            <Route path="/agency/AddTask" element={<AddTask />} />
            <Route path="/agency/TaskDetails" element={<TaskDetails />} />

            <Route path="/agent/ListTask" element={<ListTask />} />
            <Route path="/agent/AddTask" element={<AddTask />} />
            <Route path="/agent/TaskDetails" element={<TaskDetails />} />

            <Route path="/agency/ListAgreement" element={<ListAgreement />} />
            <Route path="/agency/ManagementAgreement" element={<ManagementAgreement />} />
            <Route path="/agency/ManagementAgreementTabs" element={<ManagementAgreementTabs />}/>
            <Route path="/agency/AddNewPropertyAgreement"  element={<AddNewPropertyAgreement />} />
            <Route path="/agency/AgentAgreement" element={<AgentAgreement />} />
            <Route path="/agency/AgreementPeriod" element={<AgreementPeriod />} />
            <Route path="/agency/FeesAndCharges" element={<FeesAndCharges />} />
            <Route path="/agency/TribunalFees" element={<TribunalFees />} />
            <Route path="/agency/PromotionalActivities" element={<PromotionalActivities />} />
            <Route path="/agency/RepairAndMaintainance" element={<RepairAndMaintainance />} />
            <Route path="/agency/AddNewOwnerShipAgreement" element={<AddNewOwnerShipAgreement />} />
            <Route path="/agency/AgreementSummary" element={<AgreementSummary />} />

            <Route path="/managementagreement/ListAgreement" element={<OwnerListAgreement />} />
            <Route path="/managementagreement/ManagementAgreement" element={<OwnerManagementAgreement />} />
            <Route path="/managementagreement/sign" element={<OwnerManagementAgreementTabs />}  />
            <Route path="/managementagreement/AddNewPropertyAgreement" element={<OwnerAddNewPropertyAgreement />} />
            <Route path="/managementagreement/AgentAgreement" element={<OwnerAgentAgreement />} />
            <Route path="/managementagreement/AgreementPeriod" element={<OwnerAgreementPeriod />} />
            <Route path="/managementagreement/FeesAndCharges"  element={<OwnerFeesAndCharges />} />
            <Route path="/managementagreement/TribunalFees" element={<OwnerTribunalFees />} />
            <Route path="/managementagreement/PromotionalActivities" element={<OwnerPromotionalActivities />} />
            <Route path="/managementagreement/RepairAndMaintainance" element={<OwnerRepairAndMaintainance />} />
            <Route path="/managementagreement/AddNewOwnerShipAgreement" element={<OwnerAddNewOwnerShipAgreement />} />
            <Route path="/managementagreement/AgreementSignature" element={<OwnerAgreementSignature />} />
            <Route path="/salesagreement/sign" element={<OwnerSalesAgreementTabs />} />
            <Route  path="/tenantLeaseAgreement/AgreementViewAddNewProperty"  element={<AgreementViewAddNewProperty />}  />
            <Route path="/tenantleaseagreement/sign" element={<AgreementViewtenantAgreementTabs />} />
            <Route path="/agency/ListAllProperties" element={<ListAllProperties />} />
            <Route path="/agent/ListAllProperties" element={<ListAllProperties />} />
            <Route path="/agency/AddNewProperty" element={<AddNewProperty />} />
            <Route path="/agency/AddNewPropertiesListings" element={<AddNewPropertiesListings />} />
            <Route path="/agency/TenantPropertyDetails" element={<TenantPropertyDetails />} />
            <Route path="/agency/ViewPropertyDetails" element={<ViewPropertyDetails />} />
            <Route path="/agency/UploadAgreement" element={<UploadAgreement />}  />
            <Route path="/agency/SaleAgreementTabs" element={<SaleAgreementTabs />}/>
            <Route path="/agency/TenancyAgreementTabs" element={<TenancyAgreementTabs />} />
            <Route path="/agency/UploadAgreementManagement" element={<UploadAgreementManagement />} />
            <Route path="/agency/UploadAgreementTenant" element={<UploadAgreementTenant />} />
            <Route path="/agency/ListTicket" element={<ListTicket />} />
            <Route path="/agency/ListTicketDetails" element={<ListTicketDetails />} />
            <Route path="/agency/TenancyAgreementTabs"   element={<TenancyAgreementTabs />} />
            <Route path="/agency/AddNewPropertyTenancy"   element={<AddNewPropertyTenancy />}  />

            <Route path="/agent/ListAgreement" element={<ListAgreement />} />
            <Route path="/agent/ManagementAgreement" element={<ManagementAgreement />} />
            <Route path="/agent/ManagementAgreementTabs" element={<ManagementAgreementTabs />}/>
            <Route path="/agent/AddNewPropertyAgreement"  element={<AddNewPropertyAgreement />} />
            <Route path="/agent/AgentAgreement" element={<AgentAgreement />} />
            <Route path="/agent/AgreementPeriod" element={<AgreementPeriod />} />
            <Route path="/agent/FeesAndCharges" element={<FeesAndCharges />} />
            <Route path="/agent/TribunalFees" element={<TribunalFees />} />
            <Route path="/agent/PromotionalActivities" element={<PromotionalActivities />} />
            <Route path="/agent/RepairAndMaintainance" element={<RepairAndMaintainance />} />
            <Route path="/agent/AddNewOwnerShipAgreement" element={<AddNewOwnerShipAgreement />} />
            <Route path="/agent/AgreementSummary" element={<AgreementSummary />} />
            
            <Route path="/agent/ListAllProperties" element={<ListAllProperties />} />
            <Route path="/agent/AddNewProperty" element={<AddNewProperty />} />
            <Route path="/agent/AddNewPropertiesListings" element={<AddNewPropertiesListings />} />
            <Route path="/agent/TenantPropertyDetails" element={<TenantPropertyDetails />} />
            <Route path="/agent/ViewPropertyDetails" element={<ViewPropertyDetails />} />
            <Route path="/agent/UploadAgreement" element={<UploadAgreement />}  />
            <Route path="/agent/SaleAgreementTabs" element={<SaleAgreementTabs />}/>
            <Route path="/agent/TenancyAgreementTabs" element={<TenancyAgreementTabs />} />
            <Route path="/agent/UploadAgreementManagement" element={<UploadAgreementManagement />} />
            <Route path="/agent/UploadAgreementTenant" element={<UploadAgreementTenant />} />
            <Route path="/agent/ListTicket" element={<ListTicket />} />
            <Route path="/agent/ListTicketDetails" element={<ListTicketDetails />} />
            <Route path="/agent/TenancyAgreementTabs"   element={<TenancyAgreementTabs />} />
            <Route path="/agent/AddNewPropertyTenancy"   element={<AddNewPropertyTenancy />}  />
          </Routes>
        </BrowserRouter>
      </SpinnerProvider>
    </>
  );
}

export default App;
