import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./principaldetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import {
  getDefaultFromValue,
  getdataForAgencyProgressbar,
  navigateToPageWithDelay,
} from "../../CommonSection/Utility/ComponentFunction";

import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik } from "formik";

import {
  addPrincipalDetails,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, eNewAgencyProfileStatus } from "../../../Utilities/enum";
import { AgencyPrincipalDetails } from "../../CommonSection/Schema/schema";
import CustomSelect from "../../CommonSection/CustomComponents/CustomSelect";
import { Spinner } from "react-bootstrap";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";

const initialValues = {
  jobTitle: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const PrincipalDetails = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyId, setAgencyId] = useState(0);
  const [status, setStatus] = useState(0);
  const [selectedOption, setSelectedOption] = useState(1);

  const ToastRef = useRef();

  const options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
    { value: "Dr", label: "Dr" },
  ];

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyPrincipalDetails,
    onSubmit: (values, action) => {
      values.title = selectedOption.value ? selectedOption.value : values.title;
      handlePrincipleDetails();
    },
  });

  const handleSelectChange = (selectedOption, fieldName) => {
    setFieldValue(fieldName, selectedOption);
    setFieldTouched(fieldName, true);
  };

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          console.log(userData);
        }
      } catch (error) {
        ToastRef.current.notify([
          "error",
          "Error retrieving data from AsyncStorage",
        ]);
      }
    };
    retrieveData();
  }, [userId]); // It's not necessary to have userId as a dependency since it is set inside the useEffect

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        getPrincipalDetails();
      }, 2);
    }
  }, [userUId]);

  const getPrincipalDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (
          (returnData.status =
            ActionStatus.Successfull && returnData.object.email)
        ) {
          const principalDetails = returnData.object;
          setAgencyId(principalDetails.agencyId);
          setStatus(principalDetails.status);
          setValues({ ...values, ...principalDetails });
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handlePrincipleDetails = () => {
    const formData = {
      AgencyId: agencyId,

      UserId: userId,

      UserUniqueId: userUId,

      JobTitle: values.jobTitle,

      FirstName: values.firstName,

      LastName: values.lastName,

      Email: values.email,

      Phone: values.phone,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPrincipalDetails,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/verifyagency", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  return (
    <div className="agency">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        <ProgressbarLandlord
          message={getdataForAgencyProgressbar(
            status,
            eNewAgencyProfileStatus.PrincipalDetailsAdded
          )}
        />
        <div className="agency-page-title">{`Principal Details`}</div>
        <form className="agency-input-form" onSubmit={handleSubmit}>
          <div className="principaldetails-input">
            <div className="pd-title">
              <div className="mb-3">
                <CustomSelect
                  label="Select any "
                  name="jobTitle"
                  options={options}
                  placeholder="Select any "
                  handleChange={handleSelectChange}
                  value={getDefaultFromValue(values.jobTitle)}
                />
              </div>
            </div>
            <div className="pd-fname">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="First Name"
                  value={getDefaultFromValue(values.firstName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                  aria-describedby="basic-addon1"
                  
                />
              </div>
              {errors.firstName && touched.firstName ? (
                <p className="form-error">{errors.firstName}</p>
              ) : null}
            </div>
            <div className="pd-lname">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Last Name"
                  value={getDefaultFromValue(values.lastName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.lastName && touched.lastName ? (
                <p className="form-error">{errors.lastName}</p>
              ) : null}
            </div>
            <div className="pd-phone">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <BiPhone height="100%" width="100%" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Phone"
                  value={getDefaultFromValue(values.phone)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="phone"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.phone && touched.phone ? (
                <p className="form-error">{errors.phone}</p>
              ) : null}
            </div>
            <div className="ah-email">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <BiEnvelope height="100%" width="100%" />
                  </span>
                </div>
                <input
                  type="email"
                  readOnly
                  className="form-control tenant-date"
                  placeholder="Email"
                  value={getDefaultFromValue(values.email)}
                  aria-describedby="basic-addon1"
                  name="email"
                />
              </div>
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}
            </div>
          </div>
          <button className="agency-button" type="submit">
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default PrincipalDetails;
