


import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { propertyPhotos,FilePath,} from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import TextArea from "../../../../src/input-components/textarea";
import {makeRequestWithSpinner,uploadToS3WithSpinner} from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus, ePropertyPhototype } from "../../../../Utilities/enum";
import DocumentUpload from "../../../../src/input-components/documentUpload";
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "react-quill/dist/quill.snow.css";
import { BiX } from "react-icons/bi";
import { Divider, Space } from "antd";
import ShowValue from "../../../../src/input-components/ShowValue";
import { FaEdit } from "react-icons/fa";

const moveArrayItem = (arr, fromIndex, toIndex) => {
  const newArray = [...arr];
  const [movedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedItem);
  return newArray;
};

const initialValues = {
  propertyHeading: "",
  propertyDescription: "",
  propertyBuildingTypeZoning: null,
  PropertyLandAreaL: "",
  PropertyLandAreaW: null,
  PropertyImages: [],
  FloorImages: [],
};

const PropertyPhoto = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage1,
  getDetails,
}) => {
  let selectedFile;
  const [value1, setValue1] = useState(null);
  const [userId, setUserId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyFloorImages, setPropertyFloorImages] = useState([]);
  const [propertyimageitems, setPropertyimageitems] = useState([]);
  const [propertyflooritems, setPropertyflooritems] = useState([]);
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState([
    { Id: 1, label: "A1", value: 1 },
    { Id: 2, label: "A2", value: 2 },
    { Id: 3, label: "A3", value: 3 },
  ]);

  const SortablePhoto = SortableElement(
    ({ index, photo, imageType, onDelete }) => (
      <div
        style={{
          width: "25%", // Set width to 25%
          height: "25%", // Set height to 25%
          overflow: "hidden",
          marginRight: "10px",
        }}
        key={index}
      >
        <button
          type="button"
          style={{
            float: "right",
            fontSize: "24px",
            background: "white",
            border: "none",
          }}
          onClick={() => onDelete(photo.src, imageType)}

        >
          <BiX style={{ float: "right", fontSize: "24px" }} />
        </button>
        <img src={photo.src} alt={photo.title} style={{ width: "100%" }} />
      </div>
    )
  );

  const deleteImage = (idToDelete, imageType) => {
    debugger;
    if (imageType == ePropertyPhototype.Property) {
      const newItems = propertyimageitems.filter(
        (item) => item.src !== idToDelete
      );
      setPropertyimageitems(newItems);
      const fileNames = newItems.map((item) => {
        const pathParts = item.src.split("/");
        return "property/" + pathParts[pathParts.length - 1];
      });
      setPropertyImages(fileNames);
    }
    if (imageType == ePropertyPhototype.Floor) {
      const newItems = propertyflooritems.filter(
        (item) => item.src !== idToDelete
      );
      setPropertyflooritems(newItems);
      const fileNames = newItems.map((item) => {
        const pathParts = item.src.split("/");
        return "property/" + pathParts[pathParts.length - 1];
      });
      setPropertyFloorImages(fileNames);
    }
  };

  const SortableGallery = SortableContainer(
    ({ items, onDelete, imageType }) => (
      <Gallery
        photos={items}
        renderImage={(props, index) => (
          <SortablePhoto
            index={index}
            onDelete={onDelete}
            imageType={imageType}
            {...props}
          />
        )}
      />
    )
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = moveArrayItem(propertyimageitems, oldIndex, newIndex);
    setPropertyimageitems(newItems);
    const fileNames = newItems.map((item) => {
      const pathParts = item.src.split("/");
      return "property/" + pathParts[pathParts.length - 1];
    });
    setPropertyImages(fileNames);
  };

  const onSortEndFloor = ({ oldIndex, newIndex }) => {
    const newItems = moveArrayItem(propertyflooritems, oldIndex, newIndex);
    setPropertyflooritems(newItems);
    const fileNames = newItems.map((item) => {
      const pathParts = item.src.split("/");
      return "property/" + pathParts[pathParts.length - 1];
    });
    setPropertyFloorImages(fileNames);
  };

  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUID = url.searchParams.get("propertyUID");
  const inputRef = useRef();
  const inputRef1 = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      const getFilteredData = (selectedValue) => {
        return data.find((item) => item.value === selectedValue);
      };
      const selectedValue = getDetails.propertyBuildingTypeZoning
        ? +getDetails.propertyBuildingTypeZoning
        : "";
      const filteredData = getFilteredData(selectedValue);
      setFieldValue("propertyBuildingTypeZoning", filteredData);
      setValue1(filteredData ? filteredData.value : "");
      setPropertyId(getDetails.propertyId);
      setFieldValue("propertyHeading", getDetails.propertyHeading);
      setFieldValue("propertyDescription", getDetails.propertyDescription);
      if(getDetails.propertyHeading === null){
        setEdit(true);
      }
    }
    const transformedArray =
      getDetails && getDetails.propertyPics
        ? getDetails.propertyPics.map((item) => ({
          src: FilePath + item,
          width: 1,
          height: 1,
        }))
        : [];
    setPropertyimageitems(transformedArray);
    setPropertyImages(getDetails ? getDetails.propertyPics : []);
    const transformedFloorArray =
      getDetails && getDetails.floorPics
        ? getDetails.floorPics.map((item) => ({
          src: FilePath + item,
          width: 1,
          height: 1,
        }))
        : [];
    setPropertyflooritems(transformedFloorArray);
    setPropertyFloorImages(getDetails ? getDetails.floorPics:[]);
  }, [getDetails]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,
      PropertyHeading: values.propertyHeading,
      PropertyDescription: values.propertyDescription,
      PropertyBuildingTypeZoning: value1 ? value1 : "",
      FloorImages: propertyFloorImages,
      UserId: userId,
      PropertyImages : propertyImages
    };
    const url = propertyPhotos;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleparentsuccess(["success", returnData.message]);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };


  const select = (event) => {
    inputRef.current.click();
  };

  const select1 = (event) => {
    inputRef1.current.click();
  };

  const handleUplodeImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

  const handleUpload = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "property/",
      `property_${getDetails.propertyId}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPropertyimageitems((prevItems) => [...prevItems, transformedItem]);
        setFieldValue("PropertyImages", [
          ...values.PropertyImages,
          returnData.Key,
        ]);
        setPropertyImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        handleparentsuccess(["success", "File uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const handleFloorImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUploadFloorImg();
    }
  };

  const handleUploadFloorImg = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "property/",
      `propertyF_${getDetails.propertyId}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPropertyflooritems((prevItems) => [...prevItems, transformedItem]);
        setFieldValue("FloorImages", [...values.FloorImages, returnData.Key]);
        setPropertyFloorImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        handleparentsuccess(["success", "File uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage1("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  return (
    <div>

      <div
        className="handle-Editpart">
        <a onClick={() => handleEdit()}>
          <Space>
            <FaEdit style={{ fontSize: "24px" }} />
          </Space>
        </a>

      </div>

      {
        edit ? (
          <form onSubmit={handleSubmit} className="details-subform">
            <div className="property-details-content df-all all-input">
              <div className="contacts-group pd-address">
                <TextBox
                  placeholder="Heading"
                  id="Unit"
                  name="propertyHeading"
                  value={values.propertyHeading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.propertyHeading}
                  touched={touched.propertyHeading}
                />
                <TextArea
                  placeholder="Description"
                  id="Unit"
                  name="propertyDescription"
                  value={values.propertyDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.propertyDescription}
                  touched={touched.propertyDescription}
                />
                <DocumentUpload
                  label="Upload Image"
                  inputRef={inputRef}
                  onClickHandler={select}
                  handleFileChange={handleUplodeImg}
                />
                <div className="col-12 mt-2">
                  {propertyimageitems.length > 0 ? (
                    <React.Fragment>
                      <div className="img-label">Property Images</div>
                      <SortableGallery
                        items={propertyimageitems}
                        onSortEnd={onSortEnd}
                        axis={"xy"}
                        imageType={ePropertyPhototype.Property}
                        onDelete={deleteImage}
                        distance={3}
                      />
                    </React.Fragment>
                  ) : null}
                  {propertyimageitems.length > 0 ? <Divider /> : null}
                </div>
                <DocumentUpload
                  label="Upload Floor Plan"
                  inputRef={inputRef1}
                  onClickHandler={select1}
                  handleFileChange={handleFloorImg}
                />

                <div className="col-12 mt-2">
                  {propertyflooritems.length > 0 ? (
                    <React.Fragment>
                      <div className="img-label">Property Floor Images</div>
                      <SortableGallery
                        items={propertyflooritems}
                        onSortEnd={onSortEndFloor}
                        axis={"xy"}
                        imageType={ePropertyPhototype.Floor}
                        distance={2}
                      />
                    </React.Fragment>
                  ) : null}
                  {propertyflooritems.length > 0 ? <Divider /> : null}
                </div>
              </div>
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() =>
                  handleCancel()
                }
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
        ) : (
          <dev className="details-form">
            <div className="contacts-group">
              <ShowValue Label='Heading' setData={values.propertyHeading} />
              <div className="rowphoto">
                <div className="label">
                  <strong>Description :</strong>
                </div>
                <div className="PhotoValue" >
                  {values.propertyDescription}
                </div>
              </div>
              <div className="col-12 mt-2">
                {propertyImages.length > 0 ? (
                  <React.Fragment>
                    <div className="img-label">Property Images</div>
                    <div className="image-Display" style={{ display: "flex", flexWrap: "wrap" , }}>
                    {propertyImages.map((imageUrl, index) => (
                      <div
                        style={{
                          width: "25%", // Set width to 25%
                          height: "25%", // Set height to 25%
                          overflow: "hidden",
                          margin: "8px",
                        }}
                        key={index}
                      >
                        <img key={index} src={FilePath + imageUrl} alt={imageUrl.title} style={{ width: "100%" }} />
                      </div>

                    ))}
                    </div>
                  </React.Fragment>
                ) : null}
                {propertyImages.length > 0 ? <Divider /> : null}
              </div>

              <div className="col-12 mt-2">
                {propertyFloorImages.length > 0 ? (
                  <React.Fragment>
                    <div className="img-label">Floor Images</div>
                    <div className="image-Display" style={{ display: "flex", flexWrap: "wrap" , }}>

                    {propertyFloorImages.map((imageUrl, index) => (
                      <div
                        style={{
                          width: "25%", // Set width to 25%
                          height: "25%", // Set height to 25%
                          overflow: "hidden",
                          margin: "8px",
                         
                        }}
                        key={index}

                      >
                        <img key={index} src={FilePath + imageUrl} alt={imageUrl.title} style={{ width: "100%" }} />
                      </div>

                    ))}
                    </div>
                  </React.Fragment>
                ) : null}
                {propertyFloorImages.length > 0 ? <Divider /> : null}
              </div>
            </div>
          </dev>
        )
      }
    </div>
  );
};

export default PropertyPhoto;
