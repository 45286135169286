import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import {
  FilePath,
  PropertyAddOwnerShipUrl,
  PropertySetPrimaryUrl,
  defaultProfilePic,
  SearchContacts,
  AddExistingContact,
  updatefeefromproperty,
  ContactDetailsApi,
  generatePdfReport, generatetenantcheckreport, generatesalesagreementPdfReport, generateTenantAgreementPdfReport
} from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner, DownloadFiletWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus, isManualFilePath } from "../../../../Utilities/enum";
import AddressInput from "../../../../src/input-components/address";
import { BiEdit, BiMailSend, BiEnvelope, BiPhone, BiUpload, BiDownload } from "react-icons/bi";
import Collapse from "../../../../src/input-components/collapse";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RatingStar from "../../../../src/input-components/ratingStar";
import { OwnershipSchema } from "../../../CommonSection/Schema/schema";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import {addParametersForProfiles,dynamicUrl} from "../../../../Utilities/profileManagement";
import { IoPersonAddOutline } from "react-icons/io5";
import { Tabs, Card } from "antd";
import { BiUserPlus } from "react-icons/bi";
import { format } from 'date-fns';
import CustomModal from "../../../../src/input-components/Modal";
import { MdOutlineNoteAdd } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import ShowValue from "../../../../src/input-components/ShowValue";
import { FormatDateView } from "../../../CommonSection/Utility/Utility"
import { BiDollar } from "react-icons/bi";


const { TabPane } = Tabs;

const initialValues = {
  type: '',
  title: '',
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  ownerShipAddress: "",
  ManagementFee: "",
  AdministrationFee: "",
  MarketingFee: ""
};

const PropertyOwnerShip = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  getDocDetails
}) => {
  const [ownerShipAddress, setOwnerShipAddress] = useState("");
  const [address, setAddress] = useState("");
  const [titleValue, setTitleValue] = useState(null);
  const [typevalue, setTypeValue] = useState(null);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [ownerShipLists, setOwnerShipLists] = useState([]);
  const [displayAddOwnerShip, setDisplayAddOwnerShip] = useState(false);
  const [contactUniqueId, setContactUniqueId] = useState("");
  const [renderFields] = useState(true);
  const [typeData, setTypeData] = useState([
    { value: 1, label: "Individual" },
    { value: 2, label: "Company" },
  ]);
  const [titleData, setTitleData] = useState([
    { value: 1, label: "Mr." },
    { value: 2, label: "Miss." },
    { value: 3, label: "Mrs." },
  ]);
  const [updateTable, setUpdateTable] = useState(true);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  var propertyUId = url.searchParams.get("propertyUID");

  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const ToastRef = useRef();
  const [selectedValues, setSelectedValues] = useState([]);
  const [displayAddContacts, setDisplayAddContacts] = useState(false);
  const [submitExistigContact, setSubitExisitingContact] = useState(false);
  const [contactId, setContactId] = useState(0);
  const dataTableRef = useRef(null);
  const [agreementlist, setAgreementList] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          PostApiWithSpinner(parsedData.agentId, parsedData.agencyUniqueID)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      let ownerListData = getDetails.contactlistModel;
      let ownerList = ownerListData.map((data) => ({
        Name: data.name,
        Email: data.contactEmail,
        Phone: data.phone,
        isPrimary: data.isPrimary,
        ownerId: data.ownerId,
      }));
      setOwnerShipLists(ownerList);
    }
  }, [getDetails]);

  useEffect(() => {
    var subTab = url.searchParams.get("subTab");
    if (subTab) {
      setActiveKey(subTab);
    }
  }, [])

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: OwnershipSchema,
    onSubmit: (values, action) => {
      submitExistigContact ? handleExisitingContact() : handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      AgentId: getDetails ? getDetails.agentId : agentId,
      AgencyId: agencyID,
      Address: ownerShipAddress,
      userRoleID: 10,
      PropertyUniqueId: propertyUId ? propertyUId : 0,
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      MobileNumber: values.phone,
      ContactType: values.type ? values.type : "",
      Title: values.title ? values.title : "",
      ContactUniqueId: contactUniqueId ? contactUniqueId : "",
      UserId: userId,
    };
    const url = PropertyAddOwnerShipUrl;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          setDisplayAddOwnerShip(false);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleExisitingContact = () => {
    const formData = {
      PropertyUniqueId: propertyUId ? propertyUId : 0,
      PropertyContactName: contactId,
      isUpdateOwner: true,
      UserId: userId,
    };
    const url = AddExistingContact;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          handleparentsuccess(["success", returnData.message]);
          setDisplayAddOwnerShip(false);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleAddOwner = () => {
    setSubitExisitingContact(false)
    setDisplayAddContacts(false);
    setFieldValue("type", "");
    setFieldValue("title", "");
    setFieldValue("firstName", "");
    setFieldValue("lastName", "");
    setFieldValue("phone", "");
    setFieldValue("email", "");
    setFieldValue("ownerShipAddress", "");
    setOwnerShipAddress("");
    setContactUniqueId("");
    setDisplayAddOwnerShip(true);
  };

  const handleSellerAddress = (selectedAddress) => {
    setOwnerShipAddress(selectedAddress);
  };

  const getTypeData = (selectedValue) => {
    return typeData.find((item) => item.value === selectedValue);
  };

  const handleUpdateOwnerShip = (ownerId) => {
    false;
    const matchedOwner = getDetails.contactlistModel.find(
      (owner) => owner.ownerId === ownerId
    );
    if (matchedOwner) {
      setDisplayAddContacts(true);
      setDisplayAddOwnerShip(true);
      setSubitExisitingContact(false)
      const selectedValue = matchedOwner.contactType
        ? +matchedOwner.contactType
        : "";
      const typeDatas = getTypeData(selectedValue);
      const getTitleData = (selectedValue) => {
        return titleData.find((item) => item.value === selectedValue);
      };
      const selectedValue1 = matchedOwner.title ? +matchedOwner.title : "";
      const titleDatas = getTitleData(selectedValue1);
      setTypeValue(typeDatas ? typeDatas.value : "");
      setFieldValue("type", matchedOwner.contactType);
      setTitleValue(titleDatas ? titleDatas.value : "");
      setFieldValue("title", matchedOwner.title);
      setFieldValue("firstName", matchedOwner.firstName);
      setFieldValue("lastName", matchedOwner.lastName);
      setFieldValue("phone", matchedOwner.phone);
      setFieldValue("email", matchedOwner.contactEmail);
      setFieldValue("ownerShipAddress", address);
      setOwnerShipAddress(matchedOwner.address);
      setContactUniqueId(matchedOwner.contactUId);
    }
  };

  const handleRatingChange = (newRating, ownerId) => {
    getAgencyCompanyDetails(ownerId);
    setUpdateTable(false);
  };

  const getAgencyCompanyDetails = (ownerId) => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${PropertySetPrimaryUrl}?OwnerId=${ownerId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          setUpdateTable(true);
          setDisplayAddOwnerShip(false);
          handleparentsuccess(["success", returnData.message]);
        } else {
          handleparentsuccess(["error", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", response.data.message]);
      });
  };

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };
  useEffect(() => {
    agentUIDRef.current = agentId;
  }, [agentId]);

  const agentUIDRef = useRef(agentId);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const agentId = agentUIDRef.current;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOption([]);
      setFetching(true);
      const baseUrl = SearchContacts;
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgentID: agentId,
      };
      data = addParametersForProfiles(data);
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
              uid: item.uid
            }));
            if (fetchId === fetchRef.current) {
              setOption(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    const label = value.map((item) => item.label).join(",");
    const values = value.map((item) => item.value).join(",");
    const uid = value.map((item) => item.uid).join(",");
    GetContactDetails(uid)
    setFieldValue("contactId", values);
    setFetching("propertyName", label);
    setContactId(values);
    debounceFetcher(value);
    setSubitExisitingContact(value.length != 0 ? true : false);
  };

  const GetContactDetails = (value) => {
    var url = new URL(window.location.href);
    var contactUniqueId = value;
    const baseUrl = ContactDetailsApi;
    const apiUrl = `${baseUrl}?contactUniqueId=${contactUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let data = response.object
          setFieldValue("firstName", data.firstName)
          setFieldValue("lastName", data.lastName)
          setFieldValue("phone", data.mobileNumber)
          setFieldValue("email", data.email)
        } else {
          handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const handleAddContacts = () => {
    setSubitExisitingContact(false);
    setDisplayAddContacts(!displayAddContacts);
  };

  const PostApiWithSpinner = (agencyID, agencyUniqueID) => {
    let templatesData = [
      {
        "title": "Management Agreement",
        "content": null,
        "templateType": 8,
        "addedOn": "2024-02-26T21:27:07.597",
        "updatedOn": null,
        "status": null,
        "agreementformUniqueId": "aed52bb50a454",
        "addedBy": 1,
        "propertyTypeFilter": 0,
        "agreementFormAuthorityModel": null,
        "ePropertyList": [],
        "isDocusignAuthenticate": false
      },
      {
        "title": "Residential Tenancy Agreement",
        "content": null,
        "templateType": 9,
        "addedOn": "2024-02-26T21:27:07.597",
        "updatedOn": null,
        "status": null,
        "agreementformUniqueId": "945c8949bf0d4",
        "addedBy": 1,
        "propertyTypeFilter": 0,
        "agreementFormAuthorityModel": null,
        "ePropertyList": [],
        "isDocusignAuthenticate": false
      },
      {
        "title": "Exclusive Sales Agency Agreement",
        "content": null,
        "templateType": 1,
        "addedOn": "2024-02-26T21:27:07.593",
        "updatedOn": null,
        "status": null,
        "agreementformUniqueId": "78cbabcababe4",
        "addedBy": 1,
        "propertyTypeFilter": 0,
        "agreementFormAuthorityModel": null,
        "ePropertyList": [],
        "isDocusignAuthenticate": false
      }
    ];
    let templatesList = templatesData.map((task) => ({
      Title: task.title,
      Content: task.content,
      Created_Date: task.addedOn,
      id: task.agreementformUniqueId,
    }));
    setAgreementList(templatesList);
  };

  const handleAgreement = (rowData) => {
    if (rowData.Title === "Management Agreement") {
      dynamicUrl(navigate, "ManagementAgreementTabs" + `?propertyUID=${propertyUId}&&agencyId=${getDetails.agencyId}`);
    }
    if (rowData.Title === "Residential Tenancy Agreement") {
      dynamicUrl(navigate, "TenancyAgreementTabs" + `?propertyUID=${propertyUId}&&agencyId=${getDetails.agencyId}`);
    }
    if (rowData.Title === "Exclusive Sales Agency Agreement") {
      dynamicUrl(navigate, "SaleAgreementTabs" + `?propertyUID=${propertyUId}&&agencyId=${getDetails.agencyId}`);
    }
  }

  const handleUploadAgreement = (rowData) => {
    if (rowData.Title === "Management Agreement") {
      dynamicUrl(navigate, "UploadAgreementManagement" + `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`);
    } else if (rowData.Title === "Residential Tenancy Agreement") {
      dynamicUrl(navigate, "UploadAgreementTenant" + `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`);
    } else {
      dynamicUrl(navigate, "UploadAgreement" + `?propertyUID=${propertyUId}&propertyId=${getDetails.propertyId}`);
    }
  }

  const handleDownload = (record, preview, getDocDetails) => {
    const matchedDocument = getDocDetails.find(doc => doc.sAgreementType === record.Title);
    if (record.Title === "Management Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, '_blank');
      } else {
        handleAgreementDownload(propertyUId, preview);
      }
    } else if (record.Title === "Tenant check") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, '_blank');
      } else {
        handleTenantDownload(propertyUId);
      }
    }
    else if (record.Title === "Residential Tenancy Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, '_blank');
      } else {
        handleSaleAgreementDownload(propertyUId, preview);
      }
    }
    else if (record.Title === "Exclusive Sales Agency Agreement") {
      if (matchedDocument.isManuallyAdded) {
        const fileUrl = `${isManualFilePath}${matchedDocument.agreementFilePath}`;
        window.open(fileUrl, '_blank');
      } else {
        handleTenantAgreementDownload(propertyUId, preview);
      }
    }
  }

  const handleTenantAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generateTenantAgreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleSaleAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatesalesagreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleAgreementDownload = (record, preview) => {
    const formData = {
      PropertyUId: record,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleTenantDownload = (records, preview) => {
    const formData = {
      AgencyId: getDetails.agencyId,
      UserId: records.userId
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatetenantcheckreport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          if (preview) {
            setDocumentUrl(returnData);
            setIsModalVisible(true);
          } else {
            window.open(returnData, "_blank");
          }
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };


  const actionBodyTemplate = (rowData) => {
    const isMatched = getDocDetails?.some(agreement => agreement.sAgreementType === rowData.Title);
    return (
      <div className="action-icons" style={{ display: 'flex' }}>
        {!isMatched && (
          <>
            <MdOutlineNoteAdd title="Add Agreement" style={{ fontSize: '25px', marginRight: '10px', cursor: 'pointer' }} onClick={() => handleAgreement(rowData)} />
            <BiUpload title="Upload Agreement" style={{ fontSize: '25px', marginRight: '10px', cursor: 'pointer' }} onClick={() => handleUploadAgreement(rowData)} />
          </>
        )}
        {isMatched && (
          <>
            <BiDownload title="Download Agreement" style={{ fontSize: '25px', marginRight: '10px', cursor: 'pointer' }} onClick={() => handleDownload(rowData, "", getDocDetails)} />
            <FaRegEye title="Preview" style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => handleDownload(rowData, 'preview', getDocDetails)} />
          </>
        )}
      </div>
    );
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleSubmitFees = () => {
    const formData = {
      AgreementId: getDetails.saleAgreement.id,
      AgreementType: getDetails.saleAgreement.agreementType,
      ManagementFee: values.ManagementFee,
      AdministrationFee: values.AdministrationFee,
      MarketingFee: values.MarketingFee,
      UserId: userId,

    };
    const url = updatefeefromproperty;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUId);
          handleparentsuccess(["success", returnData.message]);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          dynamicUrl(navigate, "ViewPropertyDetails" + "?propertyUID=" + propertyUId);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  }

  const closePreview = () => {
    setIsModalVisible(false)
  }


  return (
    <div>
      <Tabs style={{ padding: '0px 25px 0px 25px' }} activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab="Details" key="1">
          {!displayAddOwnerShip ? (
            <div style={{ paddingTop: "10px" }}>
              {
                ownerShipLists.length > 0 ? (
                  <div className="property-details-content df-all1">
                    <div
                      className="pb-0"
                      style={{ display: "flex", justifyContent: "end", width: "100%" }}
                    >
                      <button className="pdb-save-changes mr-1" onClick={handleAddOwner}>
                        <BiUserPlus style={{ fontSize: '20px' }} />
                      </button>
                    </div>
                    {ownerShipLists.map((owner) => (
                      <Card >
                        <div >
                          <div
                            className="main-label-no"
                            style={{ width: "100%", fontWeight: "500", cursor: "pointer", display: 'flex', justifyContent: 'end' }}
                            onClick={(e) => handleUpdateOwnerShip(owner.ownerId)}
                          >
                            <BiEdit
                              style={{ fontSize: "18px", marginRight: "5px" }}
                            />
                          </div>
                          <div
                            key={owner.ownerId}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                              }}
                              src={FilePath + defaultProfilePic}
                              alt=""
                            />
                            <div style={{ width: "100%" }}>
                              <div className="mail-label-name">
                                {owner.Name}{" "}
                                <span
                                  style={{ marginLeft: "10px", marginTop: "-10px" }}
                                >
                                  {" "}
                                  {owner.isPrimary ? (
                                    <RatingStar
                                      totalStars={1}
                                      initialRating={owner.isPrimary ? 1 : 0}
                                    />
                                  ) : null}
                                </span>
                              </div>
                              <div className="main-label-no">
                                <BiPhone
                                  style={{ fontSize: "16px", marginRight: "5px" }}
                                />
                                {owner.Phone}
                              </div>
                              <div className="main-label-no">
                                <BiMailSend
                                  style={{ fontSize: "16px", marginRight: "5px" }}
                                />
                                {owner.Email}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "0px 20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="property-details-content df-all all-input">
                      <div className="pddpn-group pd-address">
                        <Collapse
                          id={"Personal-Details"}
                          title={"Personal-Details"}
                          data={
                            <div>
                              <div>
                                <div className="contacts-group">
                                  <div className={`lda col-lg-6 col-sm-12`}>
                                    <label className="s1">Contacts </label>
                                    <div style={{ display: "flex" }}>
                                      <Select
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                        }}
                                        mode="multiple"
                                        placeholder="Search for contacts"
                                        labelInValue
                                        filterOption={false}
                                        onSearch={debounceFetcher}
                                        notFoundContent={
                                          fetching ? (
                                            <Spin size="small" />
                                          ) : null
                                        }
                                        options={option}
                                        onChange={(label, value, uid) =>
                                          handleSearchChange(label, value, uid)
                                        }
                                        defaultValue={selectedValues}
                                      />
                                      <IoPersonAddOutline
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "35px",
                                          marginRight: "5px",
                                        }}
                                        onClick={handleAddContacts}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="contacts-group">
                                  <SelectBox
                                    label="Type"
                                    placeholder="Type"
                                    name="type"
                                    options={typeData}
                                    value={values.type}
                                    onChange={(val) => setFieldValue("type", val.value)}
                                    error={errors.type}
                                    touched={touched.type}
                                  />
                                  <SelectBox
                                    label="Title"
                                    placeholder="Title"
                                    name="title"
                                    options={titleData}
                                    value={values.title}
                                    onChange={(val) => setFieldValue("title", val.value)}
                                    error={errors.title}
                                    touched={touched.title}
                                  />
                                  <TextBox
                                    isMandatory
                                    label="First Name"
                                    placeholder="First Name"
                                    id="Unit"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.firstName}
                                    touched={touched.firstName}
                                  />
                                  <TextBox
                                    label="Last Name"
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.lastName}
                                    touched={touched.lastName}

                                  />
                                  <TextBox
                                    label="Phone"
                                    placeholder="Phone"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.phone}
                                    touched={touched.phone}
                                    icon={
                                      <BiPhone height="100%" width="100%" />
                                    }

                                  />
                                  <TextBox
                                    label="Email"
                                    placeholder="Email address"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email}
                                    touched={touched.email}
                                    icon={
                                      <BiEnvelope height="100%" width="100%" />
                                    }

                                  />
                                  <AddressInput
                                    labelName="Address"
                                    address={ownerShipAddress}
                                    setAddress={setOwnerShipAddress}
                                    handlePressSelect={handleSellerAddress}
                                    error={errors.ownerShipAddress}
                                    touched={touched.ownerShipAddress}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="property-details-buttons">
                      <button
                        className="pdb-cancel"
                        type="reset"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button className="pdb-save-changes" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                )
              }
            </div>
          ) : null}

          {displayAddOwnerShip ? (
            <form onSubmit={handleSubmit} className="details-form">
              <div className="property-details-content df-all all-input">
                <div className="pddpn-group pd-address">

                  <Collapse
                    id={"Personal-Details"}
                    title={"Personal-Details"}
                    data={
                      <div>
                        {renderFields ? (
                          <div>
                            <div className="contacts-group">
                              <div className={`lda col-lg-5 col-sm-12`}>
                              <label className="s1">Contacts </label>
                                <div style={{ display: "flex" }}>
                                  <Select
                                    style={{
                                      width: "100%",
                                      height: "40px",
                                    }}
                                    mode="multiple"
                                    placeholder="Search for contacts"
                                    labelInValue
                                    filterOption={false}
                                    onSearch={debounceFetcher}
                                    notFoundContent={
                                      fetching ? <Spin size="small" /> : null
                                    }
                                    options={option}
                                    onChange={(label, value) =>
                                      handleSearchChange(label, value)
                                    }
                                    defaultValue={selectedValues}
                                  />
                                  {/* {errors.property && touched.property ? (<p className="form-error">{errors.property}</p>) : null} */}
                                  <IoPersonAddOutline
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "35px",
                                      marginRight: "5px",
                                    }}
                                    onClick={handleAddContacts}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="contacts-group">
                              <SelectBox
                                label={"Type"}
                                placeholder="Type"
                                name="type"
                                options={typeData}
                                value={values.type}
                                onChange={(val) => setFieldValue("type", val.value)}
                                error={errors.type}
                                touched={touched.type}
                              />
                              <SelectBox
                              label={"Title"}
                                placeholder={"Title"}
                                name="title"
                                options={titleData}
                                value={values.title}
                                onChange={(val) => setFieldValue("title", val.value)}
                                error={errors.title}
                                touched={touched.title}
                              />
                              <TextBox
                              isMandatory
                              label={"First Name"}
                                placeholder="First name"
                                id="Unit"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.firstName}
                                touched={touched.firstName}
                                readOnly={submitExistigContact ? true : false}
                              />
                              <TextBox
                              label={"Last Name"}
                                placeholder="Last name"
                                id="lastName"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.lastName}
                                touched={touched.lastName}
                                readOnly={submitExistigContact ? true : false}

                              />
                              <TextBox
                              label={"Phone"}
                                placeholder="Phone"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.phone}
                                touched={touched.phone}
                                icon={<BiPhone height="100%" width="100%" />}
                                readOnly={submitExistigContact ? true : false}

                              />
                              <TextBox
                              label={"Email"}
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.email}
                                touched={touched.email}
                                icon={<BiEnvelope height="100%" width="100%" />}
                                readOnly={submitExistigContact ? true : false}

                              />
                              <AddressInput
                              labelName={"Address"}
                                address={ownerShipAddress}
                                setAddress={setOwnerShipAddress}
                                handlePressSelect={handleSellerAddress}
                                error={errors.ownerShipAddress}
                                touched={touched.ownerShipAddress}
                                readOnly={submitExistigContact ? true : false}

                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                  <div style={{ width: "100%" }}>
                    {updateTable && ownerShipLists.length != 0 ? (
                      <DataTable
                        value={ownerShipLists}
                        size={"normal"}
                        tableStyle={{ minWidth: "20rem" }}
                        dataKey=""
                        rowClassName="custom-row"
                      >
                        <Column
                          field="Name"
                          header="Name"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="Email"
                          header="Email"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="Phone"
                          header="Phone"
                          className="common-table-style"
                          headerClassName="centered-header"
                        ></Column>
                        <Column
                          field="isPrimary"
                          header="Primary/Secondary"
                          className="common-table-style"
                          headerClassName="centered-header"
                          body={(rowData) => (
                            <RatingStar
                              totalStars={1}
                              initialRating={rowData.isPrimary ? 1 : 0}
                              onRatingChange={(newRating) =>
                                handleRatingChange(newRating, rowData.ownerId)
                              }
                            />
                          )}
                        ></Column>
                      </DataTable>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="property-details-buttons">
                <button className="pdb-cancel" type="reset" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="pdb-save-changes" type="submit">
                  Save
                </button>
              </div>
            </form>
          ) : null}
        </TabPane>
        <TabPane tab="Agreements" key="2">
          <div>
            <DataTable
              ref={dataTableRef}
              value={agreementlist}
              size={"normal"}
              tableStyle={{ minWidth: "20rem" }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              rows={10}
              dataKey=""
              rowClassName="custom-row"
            >
              <Column
                field="Title"
                header="Title"
                className="common-table-style"
                headerClassName="centered-header"

              ></Column>
              <Column
                field="Created_Date"
                header="Created Date"
                className="common-table-style"
                headerClassName="centered-header"
                body={(rowData) => {
                  const isMatched = getDocDetails?.some(agreement => agreement.sAgreementType === rowData.Title);
                  return isMatched ? (
                    <div>{format(new Date(rowData.Created_Date), 'dd/MM/yyyy')}</div>
                  ) : null;
                }}
              ></Column>
              <Column
                header="Actions"
                body={actionBodyTemplate}
                className="action-column right-align"
                headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                style={{ textAlign: 'left' }}
              ></Column>
            </DataTable>
            <CustomModal
              title="Preview"
              width={600}
              closable={closePreview}
              visible={isModalVisible}
              onCancel={closePreview}
              footer={null}
              modalData={
                <div
                  className="row"
                  style={{ marginTop: "30px", marginLeft: "10px" }}
                >
                  {documentUrl && (
                    <iframe
                      src={documentUrl}
                      width="100%"
                      height="600px"
                      title="Document Viewer"
                    />
                  )}
                </div>
              }
            />
          </div>
        </TabPane>
        <TabPane tab="Fees" key="3">
          <div className="property-details-content df-all all-input">
            <div className="details-form" style={{ width: '100%' }}>

              <div className="contacts-group">
                {getDetails.managementAgreement ?
                  <div>
                    <div className="Heading">
                      <strong>Management Agreement</strong>
                    </div>
                    <ShowValue colval={3} Label='Agreement Start Date' setData={FormatDateView(getDetails.managementAgreement?.agreementDate)} />
                    <ShowValue colval={3}  Label='Management Fee' setData={getDetails.managementAgreement?.managementFee} icon={<BiDollar/>}/>
                    <ShowValue colval={3}  Label='Marketing Fee' setData={getDetails.managementAgreement?.marketingFee}  icon={<BiDollar/>}/>
                    <ShowValue colval={3}  Label='Letting Fee' setData={getDetails.managementAgreement?.initialWeekRent}  icon={<BiDollar/>} />
                    <ShowValue colval={3}  Label='NCAT Attendence Fee' setData={getDetails.managementAgreement?.applicationFeesForUtilites}  icon={<BiDollar/>}/>
                    <ShowValue colval={3}  Label='Admin Fee' setData={getDetails.managementAgreement?.administrationFee}  icon={<BiDollar/>}/>
                  </div> : null}

                {getDetails.tenantAgreement ?
                  <div>
                    <div className="Heading">
                      <strong>Tenancy Agreement</strong>
                    </div>
                    <ShowValue colval={3} Label='Lease Start Date' setData={FormatDateView(getDetails.tenantAgreement?.agreementStart)} />
                    <ShowValue colval={3} Label='Lease End Date' setData={FormatDateView(getDetails.tenantAgreement?.agreementEnd)} />
                    <ShowValue colval={3} Label='Rent' setData={getDetails.tenantAgreement?.rentAmount}  icon={<BiDollar/>}/>
                    <ShowValue colval={3} Label='Lease Term' setData={getDetails.tenantAgreement?.termsAgreement === 1 ? 'Weekly' : 'Monthly'} />
                  </div> : null}
                {getDetails.saleAgreement ?
                  <div>
                    <div className="Heading">
                      <strong>Sales Agreement</strong>
                    </div>
                    <ShowValue colval={3} Label='Start Date' setData={FormatDateView(getDetails.saleAgreement?.agreementStartDate)} />
                    <ShowValue colval={3} Label='End Date' setData={FormatDateView(getDetails.saleAgreement?.agreementEndDate)} />
                    <ShowValue colval={3} Label='Marketing Fee' setData={getDetails.saleAgreement?.expenseANDChargesEstimate1}  icon={<BiDollar/>}/>
                    <ShowValue colval={3} Label='Price' setData={getDetails.saleAgreement?.sellingFixedPrice}  icon={<BiDollar/>}/>
                    <ShowValue colval={3} Label='commission %' setData={getDetails.saleAgreement?.salePriceFlatFee}  icon={<BiDollar/>}/>
                    <ShowValue colval={3} Label='Commission amount' setData={getDetails.saleAgreement?.gstInclusiveRenumeration}  icon={<BiDollar/>}/>
                  </div> : null}
              </div>
            </div>
          </div>
          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="pdb-save-changes" type="button" onClick={handleSubmitFees}>
              Save
            </button>
          </div>
        </TabPane>

      </Tabs>

    </div>
  );
};

export default PropertyOwnerShip;
