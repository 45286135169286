import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { feesAndChargesValid } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import { BiDollar } from "react-icons/bi";
import { MdPercent } from "react-icons/md";
dayjs.extend(customParseFormat);

const initialValues = {
  InitialWeekRent: "",
  PrprtyLeasdPrdDuringAgrmnt: false,
  PrprtyLeasdPrdWithinMonth: false,
  ManagementFee: "",
  MarketingFee: "",
  AdministrationFee: "",
};

const FeesAndCharges = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("InitialWeekRent", getDetails.initialWeekRent);
      setFieldValue(
        "PrprtyLeasdPrdDuringAgrmnt",
        getDetails.prprtyLeasdPrdDuringAgrmnt
      );
      setFieldValue(
        "PrprtyLeasdPrdWithinMonth",
        getDetails.prprtyLeasdPrdWithinMonth
      );
      setFieldValue("ManagementFee", getDetails.managementFee);
      setFieldValue("MarketingFee", getDetails.marketingFee);
      setFieldValue("AdministrationFee", getDetails.administrationFee);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: feesAndChargesValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
      handleNextPage("TribunalFees");
  };

  const handleClear = () => {
    handleNextPage("ManagementAgreement");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>

                        <div className="pddpn-group2 pddpn-group3">
                              <div className="col-lg-12 col-sm-12 agreementLabel mb-3" style={{paddingLeft:'0px'}} >{`Fees and Charges`}</div>
                              <div className="s1">
                            The agent shall be entitled to a leasing fee of 1
                            week. If Principal/client cancels or back-off from
                            market they shall be liable to pay 1 weeks rent
                          </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                    <div className="address-pair ">
                                    <div className="labelssss">Letting Fee</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> <BiDollar/> {values.InitialWeekRent}</div>
                                  </div>
                                </div>
                        </div>
                        <div className="pddpn-group2 pddpn-group3">
                        <div className="col-lg-12 col-sm-12 agreementLabel mb-3" style={{paddingLeft:'0px'}} >{`If the property is leased either`}</div>
                           <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                           <div className="s1">
                            The fee to which the Agent is entitled shall be due
                            and payable on the signing of a tenancy agreement or
                            upon demand if the tenancy agreement is not
                            completed owing to a default of the Principal after
                            the parties have entered into a binding agreement or
                            if after making the agreement the Principal and
                            Tenant mutually agree not to proceed with the
                            agreement
                          </div>
                                   <div className="address-pair ">
                                    <div className="labelssss">During the agreement period</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.PrprtyLeasdPrdDuringAgrmnt ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair">
                                    <div className="labelssss">Within 60 days of the termination of the</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.PrprtyLeasdPrdWithinMonth ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                        </div>
                        <div
                          className="pddpn-group2 pddpn-group3"
                        >
                                                  <div className="col-lg-12 col-sm-12 agreementLabel mb-3" style={{paddingLeft:'0px'}} >{`The Agent shall be entitled to the following ongoing fees`}</div>

                           <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair">
                                    <div className="labelssss">Management Fee of </div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> <MdPercent/> {values.ManagementFee +' ' + '+' + 'GST'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair">
                                    <div className="labelssss">Marketing Fee of</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> <BiDollar/> {values.MarketingFee}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair">
                                    <div className="labelssss">An Administration Fee of</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> <BiDollar/> {values.AdministrationFee}</div>
                                  </div>
                                </div>
                        </div>
                     
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesAndCharges;
