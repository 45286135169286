import React from "react";
import Select from "react-select";

const SelectInput = ({
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  error,
  touched,
  isMulti,
  isMandatory,
  defaultValue,
  colVal = '',
}) => {
  let colVals = colVal === 12 ? 'col-lg-12 col-sm-12' :colVal === 3 ? 'col-lg-3 col-sm-12':colVal === 8 ? 'col-lg-8 col-sm-12': 'col-lg-5 col-sm-12';
  return (
    <div className={`ca-${name} ${colVals}`}>
      <label htmlFor={name} className="s1">
        {label}
      </label>
      <Select
        options={options}
        placeholder={placeholder}
        className={`mb-3 ${isMandatory ? 'validate' : ''}`}
        value={value}
        name={name}
        onChange={onChange}
        isMulti={isMulti}
        defaultValue={defaultValue}
        style={{borderRight : isMandatory ? '2px solid red' : '' }}
      />
      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default SelectInput;
