import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiPlus, BiPencil, BiTrash } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { getOpenHomeDetails } from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  GetCookie,
  currentProfile,
} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import { Paginator } from "primereact/paginator";
import { Card } from "primereact/card";
import "./openHome.css";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Button } from "primereact/button";
import { FaLocationDot } from "react-icons/fa6";
import { Timeline } from 'primereact/timeline';

const OpenHomeDetails = () => {
  const [OpenHomeUserlist, setOpenHomeUserlist] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agentUniqueID, setAgentUniqueId] = useState("");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListOpenHome");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [contactdata, setContactData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [userHistoryArr , setUserHistoryArr] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgentUniqueId(parsedData.agentId);
          setAgentUID(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) openHomeDetails();
  }, [userId, searchText, purpose, propertyType]);

  const openHomeDetails = () => {
    var url = new URL(window.location.href);
    var OpenHomeUniqueId = url.searchParams.get("OpenHomeUniqueId");
    const apiUrl = `${getOpenHomeDetails}`;
    let data = {
      openHomeUniqueId: OpenHomeUniqueId,
      RecordsPerPage: 10,
      AgencyId: agencyID,
      SortBy: "CreatedOn",
      SortOrder: "Desc",
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const responseData = response.object;
          setContactData(responseData);
          console.log(responseData,'responseData')
          let propertyList = responseData.addOpenHomeRegistrationModel.map(
            (property) => ({
              email: property.email,
              firstName: property.firstName,
              phone: property.phone,
            })
          );
          setOpenHomeUserlist(propertyList);
          setUserHistoryArr(responseData.updateHistory)
        } else {
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddAgentClick = () => {
    var url = new URL(window.location.href);
    var OpenHomeUniqueId = url.searchParams.get("OpenHomeUniqueId");
    dynamicUrl(
      navigate,
      "AddNewUserOpenHome" + "?OpenHomeUniqueId=" + OpenHomeUniqueId
    );
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return formattedTime;
  }
  const header = <img alt="Card" src="..\..\assets\images\city.jpeg" />;
  const footer = (
    <>
      <Button label="Edit" icon="pi pi-check" />
    </>
  );

  const events = userHistoryArr.map((item) => ({
    date: new Date(item.createdOn).toLocaleDateString(),
    author: item.userName,
    status: `${item.displayName} modified from ${item.beforeValue} to ${item.afterValue}`,
  }));

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      List of Registered Users
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add New User
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="">
                <Splitter style={{}}>
                  <SplitterPanel
                    className="flex align-items-center justify-content-center"
                    size={50}
                    minSize={10}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Card
                      subTitle={contactdata.property}
                      className=" md:w-25rem m-2 customAddress"
                    >
                      <div className="pl-3">
                        <h6>Event Date</h6>
                        <p
                          className="m-0"
                          style={{ color: "black", fontWeight: "600" }}
                        >
                          {formatDate(contactdata.eventDate)}
                        </p>
                        <h6>Start Time - End Time</h6>
                        <p
                          className="m-0"
                          style={{ color: "black", fontWeight: "600" }}
                        >
                          {formatTime(contactdata.startTime)} -{" "}
                          {formatTime(contactdata.endTime)}
                        </p>
                      </div>
                      <div className="timeline-content">
                        <div className="pl-3 mt-3 mb-3" style={{fontWeight:"bold" , color:"#036475"}}>Audit Trait</div>
                        <Timeline
                          value={events}
                          opposite={(item) => (
                            <div>
                              <small className="text-color-secondary">{item.date}</small>
                              <br />
                              <small className="author-text">{item.author}</small>
                            </div>
                          )}
                          content={(item) => (
                            <div>
                              <small>{item.status}</small>
                            </div>
                          )}
                        />
                      </div>
                    </Card>
                  </SplitterPanel>
                  <SplitterPanel
                    className="flex align-items-center justify-content-center"
                    size={65}
                  >
                    {OpenHomeUserlist.length > 0 ? (
                      <div className="mt-3 m-1">
                        <DataTable
                          ref={dataTableRef}
                          value={OpenHomeUserlist}
                          size={"normal"}
                          tableStyle={{ minWidth: "20rem" }}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          rows={10}
                          dataKey=""
                          rowClassName="custom-row"
                        >
                          <Column
                            field="email"
                            header="Email"
                            className="common-table-style"
                            headerClassName="centered-header"
                          ></Column>
                          <Column
                            field="firstName"
                            header="Name"
                            className="common-table-style"
                            headerClassName="centered-header"
                          ></Column>
                          <Column
                            field="phone"
                            header="phone"
                            className="common-table-style"
                            headerClassName="centered-header"
                          ></Column>
                        </DataTable>
                      </div>
                    ) : null}
                    {OpenHomeUserlist.length === 0 ? (
                      <div className="emptyList">No user registered</div>
                    ) : null}
                    {OpenHomeUserlist.length > 0 ? (
                      <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={totalPages}
                        rowsPerPageOptions={[10, 20, 50]}
                        onPageChange={handlePageChange}
                      />
                    ) : null}
                  </SplitterPanel>
                </Splitter>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenHomeDetails;
