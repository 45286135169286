import './Footer.css'
import React from 'react'

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer1">
          <div className="text12">© FOREAL PROPERTY 2077</div>
      </div>
    </div>
  )
}

export default Footer