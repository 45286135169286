import React, { useEffect, useState } from "react";
import "./propertiesagency.css";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import { createPropertyAddress } from "../../../CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { PropertyDetailsSchema } from "../../../CommonSection/Schema/schema";
import AddressInput from "../../../../src/input-components/address";
import { FaEdit } from "react-icons/fa";
import { Tabs, Space } from "antd";
import PropertyAttributes from "./propertyAttributes";
import { dynamicUrl } from "../../../../Utilities/profileManagement";

const { TabPane } = Tabs;

const initialValues = {
  propertyFor: "",
  propertyType: "",
  assignedTo: "",
  address: "",
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  suburb: "",
  postCode: "",
  state: "",
};
const PropertyDetailsAgency = ({ onDataRecive, handleparentsuccess, getDetails, reportTo, handleCurrentPage }) => {
  const [address, setAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [edit, setEdit] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      debugger;
      setEdit(false);
      setValues({ ...values, ...getDetails });
      setFieldValue("propertyType", parseInt(getDetails.propertyType));
      setFieldValue("address", getDetails.getFormatedAddress);
      setLatitude(getDetails.propertyGooglePlacesLocationLat);
      setLongitude(getDetails.propertyGooglePlacesLocationLong);
      setSelectedCity(getDetails.agentId.toString());
      setPropertyId(getDetails.propertyId);
    }
  }, [getDetails, reportTo]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: PropertyDetailsSchema,
    onSubmit: (values) => {
      handleFinalSubmit(values);
    },
  });

  const propertyTypeOptions = [
    { label: "House", value: 7 },
    { label: "Apartment", value: 3 },
    { label: "Flat", value: 6 },
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];
  

  const handleAddressSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const details = results[0];
      const fullFormattedAddress = details.formatted_address;
      const addressComponents = extractAddressComponents(details);
      
      setLatitude(details.geometry.location.lat());
      setLongitude(details.geometry.location.lng());

      setAddress(fullFormattedAddress);
      setValues({ ...values, ...addressComponents, address: fullFormattedAddress });
      setFieldValue("address", fullFormattedAddress);
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  const extractAddressComponents = (details) => {
    const addressComponents = {};

    details.address_components.forEach(({ types, long_name }) => {
      if (types.includes("subpremise")) addressComponents.unitNumber = long_name;
      if (types.includes("street_number")) addressComponents.streetNumber = long_name;
      if (types.includes("route")) addressComponents.streetName = long_name;
      if (types.includes("locality")) addressComponents.suburb = long_name;
      if (types.includes("postal_code")) addressComponents.postCode = long_name;
      if (types.includes("administrative_area_level_1")) addressComponents.state = long_name;
    });

    return addressComponents;
  };

  const handleFinalSubmit = () => {
    if (validateFields()) {
      const formData = {
        PropertyId: propertyId || 0,
        PropertySaleRental: values.propertyFor,
        PropertyType: values.propertyType,
        AgentId: parseInt(selectedCity, 10),
        AgencyId: agencyID,
        PropertyGooglePlacesLocation: values.address,
        State: values.state,
        Suburb: values.suburb,
        PostCode: values.postCode,
        UnitNumber: values.unitNumber,
        StreetNumber: values.streetNumber,
        StreetName: values.streetName,
        UserId: userId,
        PropertyGooglePlacesLocationLong: longitude,
        PropertyGooglePlacesLocationLat: latitude,
      };

      makeRequestWithSpinner(showSpinner, hideSpinner, createPropertyAddress, "post", formData)
        .then((returnData) => {
          handleApiResponse(returnData);
        })
        .catch((error) => {
          handleparentsuccess(["error", error.message]);
        });
    }
  };

  const validateFields = () => {
    const requiredFields = [
      { field: "streetNumber", message: "Street Number is required" },
      { field: "streetName", message: "Street Name is required" },
      { field: "suburb", message: "Suburb Name is required" },
      { field: "state", message: "State Name is required" },
      { field: "postCode", message: "PostCode Name is required" },
    ];

    for (let { field, message } of requiredFields) {
      if (values[field] === "") {
        handleparentsuccess(["error", message]);
        return false;
      }
    }
    return true;
  };

  const handleApiResponse = (returnData) => {
    if (returnData.status === ActionStatus.Successfull) {
      setActiveKey("2");
      onDataRecive(returnData.sUID);
      updateUrl(returnData.sUID);
      handleparentsuccess(["success", returnData.message]);
    } else {
      handleparentsuccess(["error", returnData.message]);
    }
  };

  const updateUrl = (newValue) => {
    const currentURL = window.location.href;
    let updatedURL;

    if (newValue) {
      if (currentURL.includes("?") || currentURL.includes("propertyUID")) {
        updatedURL = currentURL.split("=")[0] + "=" + newValue;
      } else {
        updatedURL = currentURL + "?propertyUID=" + newValue;
      }

      history.pushState(null, null, updatedURL);
    }
  };

  const handleCancel = () => {
    const url = new URL(window.location.href);
    const propertyUId = url.searchParams.get("propertyUID");

    if (propertyUId) {
      handleCurrentPage("Overview");
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  const handleAssignedTo = (e) => {
    setSelectedCity(e.value);
    setFieldValue("assignedTo", e.value);
  };

  return (
    <div>
      {getDetails && (
        <div className="handle-Editpart">
          <Space>
            <FaEdit style={{ fontSize: "24px" }} onClick={() => setEdit(!edit)} />
          </Space>
        </div>
      )}
      <Tabs activeKey={activeKey} onChange={setActiveKey} style={{ padding: "0 25px" }}>
        <TabPane tab="Address" key="1">
          <form onSubmit={handleSubmit} className="details-form">
            <div className="property-details-content df-all all-input">
              <SelectBox
                isMandatory
                label="Property Type"
                name="propertyType"
                options={propertyTypeOptions}
                placeholder="Property Type"
                value={values.propertyType}
                onChange={(val) => setFieldValue("propertyType", val.value)}
                error={errors.propertyType}
                touched={touched.propertyType}
              />
              <div className="contacts-group pd-address">
                <AddressInput
                  labelName="Address"
                  address={values.address}
                  setAddress={(addr) => {
                    setFieldValue("address", addr);
                  }}
                  handlePressSelect={handleAddressSelect}
                  error={errors.address}
                  touched={touched.address}
                  isMandatory
                  handleView={() => setViewAddressDetails(!viewAddressDetails)}
                  viewDetails={true}
                />
                {viewAddressDetails && (
                  <>
                    <TextBox
                      label="Unit Number"
                      addressMargin={true}
                      placeholder="Unit Number"
                      id="unitNumber"
                      name="unitNumber"
                      value={values.unitNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.unitNumber}
                      touched={touched.unitNumber}
                    />
                    <TextBox
                      label="Street Number"
                      isMandatory
                      placeholder="Street Number"
                      id="streetNumber"
                      name="streetNumber"
                      value={values.streetNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.streetNumber}
                      touched={touched.streetNumber}
                    />
                    <TextBox
                      label="Street Name"
                      isMandatory
                      placeholder="Street Name"
                      id="streetName"
                      name="streetName"
                      value={values.streetName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.streetName}
                      touched={touched.streetName}
                    />
                    <TextBox
                      label="Suburb"
                      isMandatory
                      placeholder="Suburb"
                      id="suburb"
                      name="suburb"
                      value={values.suburb}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.suburb}
                      touched={touched.suburb}
                    />
                    <TextBox
                      label="State"
                      isMandatory
                      placeholder="State"
                      id="state"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.state}
                      touched={touched.state}
                    />
                    <TextBox
                      label="Postcode"
                      isMandatory
                      placeholder="Postcode"
                      id="postCode"
                      name="postCode"
                      value={values.postCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.postCode}
                      touched={touched.postCode}
                    />
                  </>
                )}
              </div>
              <div className="contacts-group pd-address">
                <div className="mt-5 cda-reportTo col-lg-6 col-sm-12">
                  <label htmlFor="trading state" className="s1 w-100">
                    Assigned to<span style={{ color: "red" }}>*</span>
                  </label>
                  <Dropdown
                    value={selectedCity}
                    onChange={handleAssignedTo}
                    options={reportTo}
                    optionLabel="text"
                    className="w-100 md:w-14rem"
                    placeholder="Assigned to"
                  />
                  {errors.assignedTo && touched.assignedTo && (
                    <p className="form-error">{errors.assignedTo}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="property-details-buttons">
              <button className="pdb-cancel" type="reset" onClick={handleCancel}>
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
        </TabPane>
        <TabPane tab="Attributes" key="2">
          <PropertyAttributes
            handleCurrentPage1={handleCurrentPage}
            onDataRecive1={onDataRecive}
            getDetails={getDetails}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PropertyDetailsAgency;
