import { useCallback, useState, useEffect, useRef } from "react";
import "./Addresshistory.css";

import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { apiUrlowner, BucketName, Region, AccessKey, Keyid, apiUrlamo, addAddressHistoryDetails, GetPendingProfiletenant } from "../../CommonSection/apiresources";
import { BiCalendar, BiEnvelope, BiMailSend, BiMap, BiMessage, BiPhone } from 'react-icons/bi';
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik } from "formik";
import { addresshistorySchema } from "../../CommonSection/Schema/schema";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
const initialValues = {
    address: "",
    fname1: "",
    lname1: "",
    date1: "",
    phone1: "",
    phone2: "",
    fname2: "",
    lname2: "",
    date2: "",
    reason: "",
    rent: "",
    name: "",
    phone: "",
    email: "",
    type: "",
    ownership: false,
}

const AddressHistory = () => {

    const navigate = useNavigate();
    const [dates, setdates] = useState("");
    const [tenure, setTenure] = useState(0);
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [address, setAddress] = useState("");
    const [selectedOption, setSelectedOption] = useState(1);
    const [response, setResponse] = useState('');
    const [data, setData] = useState([]);
    const [propAddress, setPropAddress] = useState();
    const [propertyUID, setPropertyUID] = useState();
    const [propId, setPropId] = useState();
    const [rentAppId, setRentAppId] = useState();
    let progressData = [
        {
            label: "Property Details",
            url: "/propertydetails",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Address History",
            url: "/addresshistory",
            isSelected: true,
            isCompleted: false
        },
        {
            label: "Verification",
            url: "/verify",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Employment",
            url: "/employment",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Questionnaire",
            url: "/questionnaire",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Documentation",
            url: "/documentation",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Consent",
            url: "/consent",
            isSelected: false,
            isCompleted: false
        },]
    //setData(progressData);
    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addresshistorySchema,
        onSubmit: (values, action) => {
            console.log(values)
            console.log(errors);
            values.address = address,
                values.rent = values.ownership ? "" : values.rent;
            values.reason = values.ownership ? "" : values.reason;
            navigate("/employment");
            handleAddressDetail();
        }
    });
    ///////////////////////////////////////////
    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID)
                    setUserId(parsedData.userId)
                    console.log(userData)
                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);

    useEffect(() => {
        if (propertyUID !=='') {
          // console.log(`UserId number: ${userId}`);
          const timeout = setTimeout(() => {
            getapi();
          }, 2)
        }
    
      }, [propertyUID]);
    
    
    
      const getapi =  () => {
    
        const baseUrl = GetPendingProfiletenant;
        setPropertyUID("b4df6e16b5b74");
        // Request body with login information
        setData(progressData);
        const propertyId = propertyUID;
        const userId = userUId;
        const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
        console.log('API URL:', apiUrl);
        console.log(`PropertyId: ${propertyId}`);
    
         axios.get(apiUrl)
          .then(response => {
    
            // Handle the response data
            console.log(response.data);
            if (response.data.status == 1) {
              // console.log('some data');
              // console.log(response.data.object);
              setPropId(response.data.object.propertyId);
              setPropAddress(response.data.object.propertyStreetadress);
              setRentAppId(response.data.object.rentApplicationId);
              console.log(propId)
            } else {
    
              alert(`Message: ${response.data.message}`);
                  console.log(response.data)
    
            }
    
          })
    
          .catch(error => {
    
            console.error('Error:', error);
    
          });
    
      }
    
    ///////////////////////////////////////////////


    // ***********************************************************************************************************
    const handleAddressDetail = () => {
        const apiUrl = addAddressHistoryDetails;
        // Request body with login information
        const VerifyData = {
            PropertyId: propId,
            PropertyAddress: propAddress,
            PropertyUniqueId: propertyUID,
            RentApplicationId: rentAppId,
            UserUniqueId: userUId,
            CurrentTenancyadress: values.address,
            OwnProperty: values.ownership,
            CurrentTenancyRentpaid: values.rent,
            CurrentTenancyreasonforleaving: values.reason,
            CurrentTenancyReferenceType: values.type,
            CurrentTenancyLandlordname: values.name,
            CurrentTenancyLandlordphone: values.phone,
            CurrentTenancyLandlordemail: values.email,
            Reference1FName: values.fname1,
            Reference1LName: values.lname1,
            Reference1Phone: values.phone1,
            Reference1HowLongKnowYou: values.date1,
            Reference2FName: values.fname2,
            Reference2LName: values.lname2,
            Reference2Phone: values.phone2,
            Reference2HowLongKnowYou: values.date2,
        };
        axios.post(apiUrl, VerifyData)
            .then((response) => {
                console.log(VerifyData)
                // Handle the response from the backend
                if (response.data.status == 1) {
                    alert(`Message: ${response.data.message}`);
                    console.log(response.data);
                } else {
                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    // ***********************************************************************************************************

    return (
        <div className="tenant" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>
                <ProgressbarLandlord message={data} />
                <div className="tenantah">{`Address History`}</div>
                <form className="tenantah-input" onSubmit={handleSubmit} >
                    <div className="ah-form">
                        <div className="ah-details">
                            <div className="ah-text">
                                Address History
                            </div>
                            <div className="current-address">
                                <label htmlFor="current-address" >
                                    Current Address*
                                </label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend address-label">
                                        <span className="input-group-text" id="basic-addon1"><BiMap height="100%" width="100%" /> </span>
                                    </div>
                                    <PlacesAutocomplete
                                        value={address}
                                        onSelect={setAddress}
                                        onChange={setAddress}
                                        searchOptions={{
                                            types: ['geocode'], // Restrict to addresses
                                            componentRestrictions: { country: 'AU' }, // Restrict to Australia
                                        }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="address-input">
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Address',
                                                        className: 'form-control',
                                                        name: 'address',

                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion) => {
                                                        const className = suggestion.active
                                                            ? 'form-control'
                                                            : 'form-control';

                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="checkbox">
                                    Do you own this property?*
                                </label>
                                <input type="checkbox"
                                    className="checkbox-input "
                                    name="ownership" id="checkbox"
                                    value={values.ownership}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                            </div>
                            {!values.ownership &&
                                <>

                                    <div className="weekly-rent">
                                        <label htmlFor="weekly-rent">
                                            Weekly Rent*
                                        </label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                            </div>
                                            <input type="text"
                                                id="tenantpd-rent"
                                                className="form-control"
                                                placeholder="400"
                                                name="rent"
                                                value={values.rent}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                    <div className="Reason">
                                        <label htmlFor="Reason">
                                            Reason for Leaving*
                                        </label>
                                        <div className="input-group mb-3">
                                            <input type="text"
                                                id="tenantpd-rent"
                                                className="form-control"
                                                placeholder="400"
                                                name="reason"
                                                value={values.reason}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                        <div className="ah-agent">
                            <div className="ah-agent-text">
                                Agent/Landlord Details
                            </div>
                            <div className="ah-agent-input">
                                <div className="ah-radio ">
                                    <label className="ah-radio-text">
                                        Agent/Landlord*
                                    </label>
                                    <div className="radio-radio">
                                        <label htmlFor="radio">
                                            <input
                                                type="radio"
                                                id="radio"
                                                name="type"
                                                value="Agent"
                                                checked={values.type === "Agent"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            &nbsp; &nbsp;Agent
                                        </label>
                                        <label htmlFor="radio">
                                            <input
                                                id="radio"
                                                type="radio"
                                                name="type"
                                                value="Landlord"
                                                checked={values.type === "Landlord"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            &nbsp;&nbsp;Landlord
                                        </label>
                                    </div>
                                    {errors.type && touched.type ? <p className="form-error">{errors.type}</p> : null}
                                </div>
                                <div className="ah-name">
                                    <label htmlFor="name">Name of Agent/Landlord* </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="name"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.name && touched.name ? <p className="form-error">{errors.name}</p> : null}
                                </div>
                                <div className="ah-phone">
                                    <label htmlFor="phone">Phone*</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiPhone height="100%" width="100%" /></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="phone"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.phone && touched.phone ? <p className="form-error">{errors.phone}</p> : null}
                                </div>
                                <div className="ah-email">
                                    <label htmlFor="email">Email* </label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiEnvelope height="100%" width="100%" /></span>
                                        </div>
                                        <input
                                            type="email"
                                            className="form-control tenant-date"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="email"
                                            aria-describedby="basic-addon1" />

                                    </div>
                                    {errors.email && touched.email ? <p className="form-error">{errors.email}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="ah-reference">
                            <div className="ah-reference-text">
                                Personal Reference
                            </div>
                            <div className="ref1">
                                <div className="ref1-text">
                                    Reference 1
                                </div>
                                <div className="fname1">
                                    <label htmlFor="fname1">First Name* </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="First Name"
                                            value={values.fname1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="fname1"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.fname1 && touched.fname1 ? <p className="form-error">{errors.fname1}</p> : null}
                                </div>
                                <div className="lname1">
                                    <label htmlFor="lname1">Last Name* </label>
                                    <div className="input-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Last Name"
                                            value={values.lname1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="lname1"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.lname1 && touched.lname1 ? <p className="form-error">{errors.lname1}</p> : null}
                                </div>
                                <div className="phone1">
                                    <div htmlFor="phone1">Phone* </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiPhone height="100%" width="100%" /></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Phone"
                                            value={values.phone1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="phone1"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.phone1 && touched.phone1 ? <p className="form-error">{errors.phone1}</p> : null}
                                </div>
                                <div className="date1">
                                    <label htmlFor="date">How long they know you?* </label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiCalendar height="100%" width="100%" /></span>
                                        </div>
                                        <input type="date"
                                            id="tenantpd-date"
                                            className="form-control tenant-date"
                                            value={values.date1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="date1"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.date1 && touched.date1 ? <p className="form-error">{errors.date1}</p> : null}
                                </div>

                            </div>
                            <div className="ref2">
                                <div className="ref2-text">
                                    Reference 2
                                </div>
                                <div className="fname2">
                                    <label htmlFor="fname2">First Name* </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="First Name"
                                            value={values.fname2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="fname2"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.fname2 && touched.fname2 ? <p className="form-error">{errors.fname2}</p> : null}
                                </div>
                                <div className="lname2">
                                    <label htmlFor="lname2">Last Name* </label>
                                    <div className="input-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Last Name"
                                            value={values.lname2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="lname2"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.lname2 && touched.lname2 ? <p className="form-error">{errors.lname2}</p> : null}
                                </div>
                                <div className="phone2">
                                    <label htmlFor="phone2">Phone* </label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiPhone height="100%" width="100%" /></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control tenant-date"
                                            placeholder="Phone"
                                            value={values.phone2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="phone2"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.phone2 && touched.phone2 ? <p className="form-error">{errors.phone2}</p> : null}
                                </div>
                                <div className="date2">
                                    <label htmlFor="date2">How long they know you?* </label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><BiCalendar height="100%" width="100%" /></span>
                                        </div>
                                        <input type="date"
                                            id="tenantpd-date"
                                            className="form-control tenant-date"
                                            value={values.date2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="date2"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.date2 && touched.date2 ? <p className="form-error">{errors.date2}</p> : null}
                                </div>
                            </div>
                        </div>
                        <button className="tenantah-button" type="submit" >
                            <div className="text-parent">
                                <div className="text7">Continue</div>
                            </div>
                        </button>
                    </div>

                </form>
            </div>
            <Footer />
        </div>
    );
};

export default AddressHistory;
