import React from "react";
import "./SignUpcss.css";
import { useFormik } from "formik";
import { signUpSchema } from "../Schema/schema";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GetCookie, SetCookie } from "../Cookie/Session";
import Footer from "../Footer/Footer";
import { userLogin, userRegistration } from "../apiresources";
import ToastAlert from "../ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import { navigateToPageWithDelay } from "../Utility/ComponentFunction";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
const ToastRef = React.createRef();
const initialValues = {
  name: "",
  email: "",
  password: "",
  cpassword: "",
  rem: false,
};
const SignUp = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        const signupData = {
          FirstName: values.name,
          Email: values.email,
          Password: values.password,
          ConfirmPassword: values.cpassword,
          Terms: values.rem,
          userRoleID: 10,
        };

        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          userRegistration,
          "post",
          signupData
        )
          .then((returnData) => {
            if (returnData.status == ActionStatus.Successfull) {
              const loginData = {
                UserName: values.email,
                Password: values.password,
                //   userRoleID: 10,
              };

              makeRequestWithSpinner(
                showSpinner,
                hideSpinner,
                userLogin,
                "post",
                loginData,
                null,
                false
              )
                .then((returnData) => {
                  if (returnData.status == ActionStatus.Successfull) {
                    // Save user data to AsyncStorage
                    ToastRef.current.notify([
                      "success",
                      "User Created Successfully",
                    ]);
                    const userData = returnData.object;
                    localStorage.setItem("@UserData", JSON.stringify(userData));
                    //localStorage.setItem('sessionTimestamp', Date.now());
                    SetCookie("@UserData", JSON.stringify(userData));
                    navigateToPageWithDelay("/selection", navigate);
                  } else {
                    // alert(`Message: ${response.data.message}`);
                    ToastRef.current.notify([
                      "warning",
                      returnData.message,
                    ]);
                  }
                })

                .catch((error) => {
                  Toast.current.notify(["error", error.message]);
                });
            } else {
              ToastRef.current.notify(["error", returnData.message]);
            }
          })

          .catch((error) => {
            ToastRef.current.notify(["error", error.message]);
          });
        var checkbox = document.getElementById("rem");
        checkbox.checked = false;
      },
    });

  return (
    <div className="col-12 section">
      <div className="col-sm-12 col-md-10 col-lg-3 container">
        <div className="header-su">
          <img
            className="logo-forealproperty-one-color2"
            alt=""
            src=".\assets\images\logo.png"
          />
        </div>
        <ToastAlert ref={ToastRef} />
        <div className="content-su">
          <div className="content2">
            <div className="text-and-supporting-text-su">
              <div className="text">Create an account</div>
            </div>
          </div>
          <div className="content1">
            <form className="form" onSubmit={handleSubmit}>
              <div className="input-field">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="name primary"
                  placeholder="Enter your name"
                  id="name"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name ? (
                  <p className="form-error">{errors.name}</p>
                ) : null}
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="email primary"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="form-error">{errors.email}</p>
                ) : null}
                <label htmlFor="password">Password</label>
                <input
                  className="password primary"
                  type="password"
                  name="password"
                  placeholder="Create a password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password ? (
                  <p className="form-error">{errors.password}</p>
                ) : null}
                <label htmlFor="cpassword">Confirm Password</label>
                <input
                  className="cpassword primary"
                  type="password"
                  name="cpassword"
                  placeholder="Confirm your password"
                  id="cpassword"
                  value={values.cpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.cpassword && touched.cpassword ? (
                  <p className="form-error">{errors.cpassword}</p>
                ) : null}
              </div>
              <div className="input-field2">
                <div className="input2">
                  <input
                    type="checkbox"
                    name="rem"
                    id="rem"
                    className="checkbox"
                    value={values.rem}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="text3">
                    &nbsp;I agree to the terms and conditions{" "}
                  </div>
                  <br />
                </div>
              </div>
              {errors.rem && touched.rem ? (
                <p className="form-error">{errors.rem}</p>
              ) : null}
              <div className="submit">
                <button type="submit" className="button1">
                  Sign Up
                </button>
              </div>
              <div className="signin">
                <div className="promt">Already have an account? </div>
                <a href="/signin" className="asu">
                  {" "}
                  Log in
                </a>
              </div>
            </form>
          </div>
          <div className="footerspace">
            <p></p>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
