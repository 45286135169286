import React from "react";
import { useState, useEffect, useRef } from "react";
import "./paymentdetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik } from "formik";
import Select from "react-select";
import {
  addPaymentDetails,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import { AgencyPaymentDetailsSchema } from "../../CommonSection/Schema/schema";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, eNewAgencyProfileStatus } from "../../../Utilities/enum";
import {
  getdataForAgencyProgressbar,
  navigateToPageWithDelay,
} from "../../CommonSection/Utility/ComponentFunction";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import TextBox from "../../../src/input-components/textbox";

const initialValues = {
  saleAccountName: "",
  saleAccountNumber: "",
  saleBsb: "",
};

const PaymentDetails = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyId, setAgencyId] = useState(0);
  const [status, setStatus] = useState(0);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyPaymentDetailsSchema,
    onSubmit: (values, action) => {
      submitPaymentDetails();
    },
  });

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        getPaymentDetailsApi();
      }, 2);
    }
  }, [userUId]);

  const getPaymentDetailsApi = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          const paymentDetailsdata = returnData.object;
          setAgencyId(paymentDetailsdata.agencyId);
          setValues({ ...values, ...paymentDetailsdata });
          setStatus(paymentDetailsdata.status);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", "Internal Error"]);
      });
  };

  const submitPaymentDetails = () => {
    const formData = {
      AgencyId: agencyId,

      UserId: userId,

      UserUniqueId: userUId,

      AccountName: values.saleAccountName,

      BsbNumber: values.saleBsb,

      AccountNumber: values.saleAccountNumber,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPaymentDetails,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/agencycompletion", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", "Internal Error"]);
      });
  };

  return (
    <div className="agency">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        {
          <ProgressbarLandlord
            message={getdataForAgencyProgressbar(
              status,
              eNewAgencyProfileStatus.PaymentDetails
            )}
          />
        }
        <div className="agency-page-title">{`Payment Details`}</div>
        <form className="agency-input-form" onSubmit={handleSubmit}>
          <div className="payment-input">

            <TextBox isMandatory label="Account Name" colVal="mt-2" placeholder="Account Name" name="saleAccountName" value={values.saleAccountName} onChange={handleChange} onBlur={handleBlur} error={errors.saleAccountName} touched={touched.saleAccountName} />


            <TextBox isMandatory label="BSB Number" colVal="mt-2" placeholder="BSB Number" name="saleBsb" value={values.saleBsb} onChange={handleChange} onBlur={handleBlur} error={errors.saleBsb} touched={touched.saleBsb} />



            <TextBox isMandatory label="Account Number" colVal="mt-2" placeholder="Account Number" name="saleAccountNumber" value={values.saleAccountNumber} onChange={handleChange} onBlur={handleBlur} error={errors.saleAccountNumber} touched={touched.saleAccountNumber} />


          </div>
          <button className="agency-button" type="submit">
            <div className="text-parent">
              <div className="text7">Send Approval</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentDetails;
