import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import SelectBox from "../../input-components/selectBoxNew";
import debounce from "lodash/debounce";
import {getContactActivitiesList,SearchProperty,searchAreaOfOperation} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { BiPhone} from "react-icons/bi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Select, Spin } from "antd";
import { format } from 'date-fns';
import exportToExcel from "../../input-components/excel";
import {getActivitiesDescriptionFromValue,getDescriptionFromValue} from "../../../Utilities/helper";
import { ActionStatus,eContactActivities } from "../../../Utilities/enum";
import "./activities.css"

const ContactActivitiesList = ({handleparentsuccess}) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const dataTableRef = useRef(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const fetchRef = useRef(0);
  const fetchRef1 = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const [searchValue1, setSearchValues1] = useState("");
  const [postCode, setPostCode] = useState("Search for Post code");
  const [propertyActivities, setActivities] = useState("");
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [leadType,setLeadType]= useState("");
  const [date, setDate] = useState("");
  const [leadTypeArr, setLeadTypeArr] = useState([
    { label: "Select", value: null },
    { label: "Hot", value: 5 },
    { label: "Warm", value: 4 },
    { label: "Cold", value: 3 },
  ]);
  const [propertyTypeArr, setPropertyTypeArr] = useState([
    { label: "All", value: null },
    { label: "Sale", value: "1" },
    { label: "Rental", value: "2" },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const[budgetVal,setBudgetVal]= useState('');
  const[contactVal,setContactVal]= useState('');
  
  const contactActivitiesArr = Object.entries(eContactActivities).map(([value, label]) => ({
    value: parseInt(value), // Convert key to integer
    label: label,
  }));
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) ListContactActivities(1,10);
  }, [userId]);

  useEffect(() => {
    const yesterday = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString();
    setDate(yesterday);
  }, []);

  const ListContactActivities = (currentPage, totalRows) => {
    const baseUrl = getContactActivitiesList;
    let data = {
      sortby: "CreatedAt",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      PropertyType : propertyTypeVal,
      PropertyAddress : searchValue,
      PostCode : searchValue1,
      LeadType : leadType,
      Budget : budgetVal,
      ContactName: contactVal,
      Activity: propertyActivities === 0 ? null : propertyActivities,
      PageNo: currentPage ,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if(response.list.length != 0){
            setActivitiesList(response.list);
            setTotalPages(response.totalCount);
          }else if(response.list.length === 0){
            setActivitiesList(response.list);
          }

        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
console.log(activitiesList)

  const handlePageChange = (event, pageNumber) => {
    ListContactActivities(event.page + 1, event.rows);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchProperty;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (value) => {
    debounceFetcher(value);
    setSearchValues(value.value);
  };

  const debounceFetcher1 = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef1.current += 1;
      const fetchId = fetchRef1.current;
      setOptions1([]);
      const baseUrl = searchAreaOfOperation;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef1.current) {
              setOptions1(transformedData);
            }
          }
        })
        .catch((error) => {
          //setFetching1(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handlePostCode = (value) => {
   const numericPart = value.label.split(',').pop().trim();
    setPostCode(numericPart)
    setSearchValues1(value.value);
    debounceFetcher1(value);
  };
  const handleProtalEnquiryList = () => {
    ListContactActivities();
  };

  const handleInput = (e, label) => {
    if (label === "Activities") {
      setActivities(e.value);
    } else if (label === "propertyType") {
      setPropertyTypeVal(e.value);
    }else if(label === "leadType"){
      setLeadType(e.value)
    }
  };

  const handleChangeText = (e,label) =>{
      setBudgetVal(e.target.value)
  }

  const handleChangeText1 = (e,label) =>{
      setContactVal(e.target.value)
  }

  const handleClearProperty = () => {
    setSearchValues("");
  };

  const handleClearPostCode = () => {
    setSearchValues1("");
    setPostCode("Search for Post code")
  };

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    setDate(yesterday.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);


  const labelMapping1 = {
    "First Name": "firstName",
    "Last Name": "lastName",
    Number: "phoneNumber",
    Property: "property",
    Type: "getListingType",
    Budget: "budget",
    "Activity Date": "createdAt",
    Activity: "activity",
    "Post Code": "postcode",
  };

  const handleExportToExcel = () => {
    exportToExcel(selectedRows, "exportata.xlsx", labelMapping1);
  };
  
  const getLeadTypeClassName = (leadType) => {
    switch (leadType) {
      case "Hot":
        return "lead-type-hot";
      case "Warm":
        return "lead-type-warm";
      case "Cold":
        return "lead-type-cold";
      default:
        return "";
    }
  };
  return (
    <div className="dashboard-agency">
      <div className="continer-dbag">
        <div className="dashboardagency-content">
          <div
            className="pa-content-top-level"
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      Contact Activities
                    </div>
                  </div>
                </div>
                <div className=" d-flex">

                </div>
              </div>
              <div className="propertyList-main-container" style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{width:'100%'}}>
                <div className="overview-card-container-row1 ">
                  <TextBox
                    colVal={3}
                    name="Contact"
                    placeholder={"Contact"}
                    onChange={handleChangeText1}
                  />
                  <div className="mt-4 col-12 col-lg-3 col-md-4 col-sm-4 customWidth" >
                    <Select
                    style={{ width: '100%' }}
                      className="landingPageSearchs"
                      mode="single"
                      showSearch
                      placeholder="Search for Property"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options}
                      onChange={handleSearchProperty}
                      allowClear
                      onClear={handleClearProperty}
                    />
                  </div>
                  <div className="mt-4 col-12 col-lg-3 col-md-4 col-sm-4 customWidth">
                    <Select
                    style={{ width: '100%' }}
                      className="landingPageSearchs"
                      mode="single"
                      showSearch
                      placeholder="Search for PostCode"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher1}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      options={options1}
                      onChange={handlePostCode}
                      allowClear
                      onClear={handleClearPostCode} 
                      value={postCode}
                    />
                    
                  </div>
                </div>
                <div className="overview-card-container-row1 ">
                <TextBox
                    colVal={3}
                    name="Budget"
                    placeholder={"Budget"}
                    onChange={handleChangeText}
                  />
                  <SelectBox
                    colVal={3}
                    name="Activities"
                    options={contactActivitiesArr}
                    value={propertyActivities}
                    placeholder={"Activities"}
                    onChange={(e) => handleInput(e, "Activities")}
                  />
                  <SelectBox
                    colVal={3}
                    name="leadType"
                    options={leadTypeArr}
                    value={leadType}
                    placeholder={"Lead Type"}
                    onChange={(e) => handleInput(e, "leadType")}
                  />
                  <SelectBox
                    colVal={3}
                    name="propertyType"
                    options={propertyTypeArr}
                    value={propertyTypeVal}
                    placeholder={"Property Type"}
                    onChange={(e) => handleInput(e, "propertyType")}
                  />

                </div>
              </div>
              <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleProtalEnquiryList}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
                  </div>
              <div className="propertyList-main-container">
                <div
                  className="search-container1 mb-1"
                  style={{ justifyContent: "end", paddingRight: "0%" }}
                >
                  <div className="">
                    <div className="property-label"></div>
                  </div>
                  <button
                    className="Add-Property-button mt-4 ml-3"
                    type="button"
                    onClick={handleExportToExcel}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Export
                      </div>
                    </div>
                  </button>
                </div>
                <DataTable
                  ref={dataTableRef}
                  value={activitiesList}
                  selection={selectedRows}
                  onSelectionChange={(e) => setSelectedRows(e.value)}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50,100]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  selectionMode="multiple"
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column
                    header="Name"
                    body={(rowData) => (
                      <div className="name-column">
                        <div>
                          {rowData.firstName} {rowData.lastName}
                        </div>
                       {rowData.phoneNumber? 
                       <div className="mr-1" style={{display:'flex'}}>
                          <BiPhone /> {rowData.phoneNumber}
                        </div> : null}
                      </div>
                    )}
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    header="Property"
                    body={(rowData) => (
                      <div className="name-column">
                        <div>{rowData.property}</div>
                      <div style={{display:'flex'}}>
                      <div style={{fontWeight:'500'}}>Type: </div>
                        <div> {rowData.getListingType}</div>
                      </div>
                      <div style={{display:'flex'}}>
                      <div style={{fontWeight:'500'}}>Budget: </div>
                        <div>  {rowData.budget}</div>
                      </div>
                      </div>
                    )}
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>

                  <Column
                    field="createdAt"
                    header="Activity Date"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>{format(new Date(rowData.createdAt), 'dd/MM/yyyy')}</div>
                    )}
                  ></Column>
                  <Column
                    field="ReceivedAt"
                    header="Activity"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>
                        {getActivitiesDescriptionFromValue(
                          parseInt(rowData.activity)
                        )}
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="Comments"
                    header="Post Code"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div className="name-column">
                        <div>{rowData.postcode}</div>
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="leadType"
                    header="Lead Type"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div className={`name-column ${getLeadTypeClassName(rowData.leadType)}`}>
                        <div>{rowData.leadType}</div>
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="category"
                    header="Category"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div className="name-column">
                        <div>
                          {getDescriptionFromValue(
                            parseInt(rowData.contactCategory)
                          )}
                        </div>
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="category"
                    header="Comments"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div className="name-column">
                        <div>{rowData.comments}</div>
                      </div>
                    )}
                  ></Column>
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={totalPages}
                  rowsPerPageOptions={[10, 20, 50,100]}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactActivitiesList;
