import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./addcompanydetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import { useFormik } from "formik";
import Select from "react-select";
import {
  ABNLookUpGuid,
  ABNLookUpbaseURL,
  addAddCompany,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { AgencyCompanyDetailsSchema } from "../../CommonSection/Schema/schema";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, eNewAgencyProfileStatus } from "../../../Utilities/enum";
import {
  getdataForAgencyProgressbar,
  isNullOrEmpty,
  navigateToPageWithDelay,
} from "../../CommonSection/Utility/ComponentFunction";
import CustomSelect from "../../CommonSection/CustomComponents/CustomSelect";
import { useCallback } from "react";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import TextBox from "../../../src/input-components/textbox";

const initialValues = {
  compnayName: "",
  tradingName: "",
  abnNum: "",
  acnNumber: "",
  tradingRegion: "",
  isGstRegistered: "false",
  companyRegNumber: "",
  companyRegNumberUnitNo: "",
  companyRegNumberStreetNumber: "",
  companyRegNumberStreetName: "",
  companyRegNumberSuburb: "",
  companyRegNumberPostCode: "",
  companyRegNumberState: "",
};

const AddCompanyDetails = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const ToastRef = useRef();

  const options = [
    { value: 1, label: "New South Wales" },
    { value: 2, label: "Queensland" },
    { value: 3, label: "South Australia" },
    { value: 4, label: "Tasmania" },
    { value: 5, label: "Victoria" },
    { value: 6, label: "Western Australia" },
    { value: 7, label: "Australian Capital Territory" },
    { value: 8, label: "Jervise Bay Territory" },
    { value: 9, label: "Northern Territory" },
  ];

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyCompanyDetailsSchema,
    onSubmit: (values, action) => {
      // navigate("/licensedetails");
      submitCompanyDetails();
    },
  });

  const [agencyId, setAgencyId] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        ToastRef.current.notify([
          "error",
          "Error retrieving data from AsyncStorage",
        ]);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        getAgencyCompanyDetails();
      }, 2);
    }
  }, [userUId]);

  const onFrameContainerClick = useCallback((isVisible) => {
    setIsVisible(isVisible);
  }, []);

  const getAgencyCompanyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let agencyCompanyDetails = returnData.object;
          setStatus(agencyCompanyDetails.status);
          setAgencyId(agencyCompanyDetails.agencyId);
          setValues({ ...values, ...agencyCompanyDetails });
          setAddress(values.companyaddress);

          if (options[gdata.tradingRegion - 1])
            setTradingState(options[gdata.tradingRegion - 1].label);
        } else {
          ToastRef.current.notify(["error", response.data.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFieldValue(fieldName, selectedOption);
    setFieldTouched(fieldName, true);
  };
  const handleABNLoopup = (event) => {
    const abnNumber = event.target.value;

    handleBlur(event);
    if (!isNullOrEmpty(abnNumber)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`,
        "get"
      ).then((returnData) => {
        const jsonString = returnData.match(/\{.*\}/)[0];
        const parsedData = JSON.parse(
          jsonString.slice(jsonString.indexOf("{"))
        );
        if (!isNullOrEmpty(parsedData.Abn)) {
          setFieldError("abnNum", "");
          setFieldValue("compnayName", parsedData.EntityName);
          setFieldValue("tradingName", parsedData.EntityName);
          setFieldValue("acnNumber", parsedData.Acn);
          setFieldValue("tradingRegion", parsedData.AddressState);
          if (parsedData.Gst != null) {
            setFieldValue("isGstRegistered", true);
          }
        } else {
          setFieldError("abnNum", "Invalid ABN number");
          setFieldValue("compnayName", "");
          setFieldValue("tradingName", "");
          setFieldValue("acnNumber", "");
          setFieldValue("tradingRegion", "");
          setFieldValue("isGstRegistered", false);
          ToastRef.current.notify(["error", "Invalid ABN "]);
        }
      });
    }
  };

  const handlepressSelect = async (addrs) => {
    handleChange({
      target: { name: "companyRegNumber", value: addrs },
    });
    try {
      const results = await geocodeByAddress(addrs);
      const details = results[0];
      console.log("details =", details);
      const fullFormattedAddress = details.formatted_address;
      //console.log(fullFormattedAddress);
      setAddress(fullFormattedAddress);
      const { address_components } = details;

      //console.log(details);
      const address = {};
      address_components.forEach((component) => {
        const { types, long_name } = component;
        if (types.includes("subpremise")) {
          address.companyRegNumberUnitNo = long_name;
        }
        if (types.includes("street_number")) {
          address.companyRegNumberStreetNumber = long_name;
        } else if (types.includes("route")) {
          address.companyRegNumberStreetName = long_name;
        } else if (types.includes("locality")) {
          address.companyRegNumberSuburb = long_name;
        } else if (types.includes("postal_code")) {
          address.companyRegNumberPostCode = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          address.companyRegNumberState = long_name;
        }
        setFieldValue("companyRegNumberUnitNo", address.companyRegNumberUnitNo);
        setFieldValue(
          "companyRegNumberStreetNumber",
          address.companyRegNumberStreetNumber
        );
        setFieldValue(
          "companyRegNumberStreetName",
          address.companyRegNumberStreetName
        );
        setFieldValue("companyRegNumberSuburb", address.companyRegNumberSuburb);
        setFieldValue(
          "companyRegNumberPostCode",
          address.companyRegNumberPostCode
        );
        setFieldValue("companyRegNumberState", address.companyRegNumberState);
      });
    } catch (error) {
      console.error("Error", error);
    }

    // Now, you can set the values in your other input fields accordingly
    // For example:
  };

  const submitCompanyDetails = () => {
    console.log('yes')
    const formData = {
      AgencyId: agencyId,

      UserId: userId,

      UserUniqueId: userUId,

      CompanyName: values.compnayName,

      TradingName: values.tradingName,

      CompanyAddress: values.companyRegNumber,

      AcnNumber: values.acnNumber,

      AbnNum: values.abnNum,

      TradingRegion: values.tradingRegion,

      IsGstRegistered: values.isGstRegistered,

      CompanyRegNumberPostCode: values.companyRegNumberPostCode,

      CompanyRegNumberState: values.companyRegNumberState,

      CompanyRegNumberSuburb: values.companyRegNumberSuburb,

      CompanyRegNumberStreetName: values.companyRegNumberStreetName,

      CompanyRegNumberUnitNo: values.companyRegNumberUnitNo,

      CompanyRegNumberStreetNumber: values.companyRegNumberStreetNumber,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addAddCompany,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/insurancedetails", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  return (
    <div className="agency">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        {
          <ProgressbarLandlord
            message={getdataForAgencyProgressbar(
              status,
              eNewAgencyProfileStatus.CompanyDetailsAdded
            )}
          />
        }
        <div className="agency-page-title">{`Add Company Details`}</div>
        <form className="agency-input-form" onSubmit={handleSubmit}>
          <div className="company-input">
            <div className="company-address">
              <label htmlFor="current-address">
                Current Address <span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group mb-3">
                <PlacesAutocomplete
                  value={values.companyRegNumber}
                  onChange={(address) =>
                    handleChange({
                      target: { name: "companyRegNumber", value: address },
                    })
                  }
                  onSelect={handlepressSelect}
                  searchOptions={{
                    types: ["geocode"], // Restrict to addresses
                    componentRestrictions: { country: "AU" }, // Restrict to Australia
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="company-address-input">
                      <input
                        {...getInputProps({
                          placeholder: "Address",
                          className: "input",
                        })}
                      />
                      <div className="dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "input"
                            : "input";

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {errors.companyRegNumber && touched.companyRegNumber ? (
                  <p className="form-error">{errors.companyRegNumber}</p>
                ) : null}
              </div>
            </div>
            <div
              className="label-wrapper"
              onClick={() => onFrameContainerClick(!isVisible)}
            >
              <div className="label1">Details</div>
            </div>

            <div className="subaddress">
              {isVisible && (
                <div className="input-dropdown-parent">
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="Unit Number"
                        id="Unit"
                        name="companyRegNumberUnitNo"
                        value={values.companyRegNumberUnitNo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="hint-text" />
                  </div>
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="Street Number"
                        id="StreetNumber"
                        name="companyRegNumberStreetNumber"
                        value={values.companyRegNumberStreetNumber}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.companyRegNumberStreetNumber &&
                    touched.companyRegNumberStreetNumber ? (
                      <p className="form-error">
                        {errors.companyRegNumberStreetNumber}
                      </p>
                    ) : null}

                    <div className="hint-text" />
                  </div>
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="Street Name"
                        id="StreetName"
                        name="streecompanyRegNumberStreetNameName"
                        value={values.companyRegNumberStreetName}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.companyRegNumberStreetName &&
                    touched.companyRegNumberStreetName ? (
                      <p className="form-error">
                        {errors.companyRegNumberStreetName}
                      </p>
                    ) : null}
                    <div className="hint-text" />
                  </div>
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="Suburb"
                        id="companyRegNumberSuburb"
                        name="companyRegNumberSuburb"
                        value={values.companyRegNumberSuburb}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.companyRegNumberSuburb &&
                    touched.companyRegNumberSuburb ? (
                      <p className="form-error">
                        {errors.companyRegNumberSuburb}
                      </p>
                    ) : null}
                    <div className="hint-text" />
                  </div>
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="Postcode"
                        id="companyRegNumberPostCode"
                        name="companyRegNumberPostCode"
                        value={values.companyRegNumberPostCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.companyRegNumberPostCode &&
                  touched.companyRegNumberPostCode ? (
                    <p className="form-error">
                      {errors.companyRegNumberPostCode}
                    </p>
                  ) : null}
                  <div className="input-dropdown1">
                    <div className="input-with-label">
                      <input
                        type="text"
                        className="input"
                        placeholder="State"
                        id="State"
                        name="companyRegNumberState"
                        value={values.companyRegNumberState}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.companyRegNumberState &&
                  touched.companyRegNumberState ? (
                    <p className="form-error">{errors.companyRegNumberState}</p>
                  ) : null}
                </div>
              )}
            </div>

            <TextBox
              isMandatory
              label="ABN Number"
              colVal="mt-2"
              placeholder="ABN Number"
              name="abnNum"
              value={values.abnNum}
              onChange={handleChange}
              onBlur={handleABNLoopup}
              error={errors.abnNum}
              touched={touched.abnNum}
            />

            <TextBox
              isMandatory
              label="Company Name"
              colVal="mt-2"
              placeholder="Company Name"
              name="compnayName"
              value={values.compnayName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.compnayName}
              touched={touched.compnayName}
              readOnly={true}
            />

            <TextBox
              isMandatory
              label="Trade Name"
              colVal="mt-2"
              placeholder="Trade Name"
              name="tradingName"
              value={values.tradingName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.tradingName}
              touched={touched.tradingName}
              readOnly={true}
            />

            <TextBox
              isMandatory
              label=" ACN Number"
              colVal="mt-2"
              placeholder=" ACN Number"
              name="acnNumber"
              value={values.acnNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.acnNumber}
              touched={touched.acnNumber}
              readOnly={true}
            />

            <div className="company-gst ">
              <label className="t1">
                <input
                  type="checkbox"
                  className="tenantd-input-checkbox"
                  name="isGstRegistered"
                  value={values.isGstRegistered}
                  onChange={handleChange}
                  checked={values.isGstRegistered}
                  readOnly
                />
                <span className="checkmark"> </span>
                <label
                  htmlFor="isGstRegistered"
                  style={{ marginLeft: 10 }}
                  className="input-lable-agency"
                >
                  GST Registered <span style={{ color: "red" }}>*</span>
                </label>
              </label>
            </div>
            {errors.isGstRegistered && touched.isGstRegistered ? (
              <p className="form-error">{errors.isGstRegistered}</p>
            ) : null}

            <TextBox
              readOnly={true}
              isMandatory
              label="Trading State"
              colVal="mt-2"
              placeholder="Trading Region"
              name="tradingRegion"
              value={values.tradingRegion}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.tradingRegion}
              touched={touched.tradingRegion}
            />
          </div>
          <button className="agency-button" type="submit">
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AddCompanyDetails;
