import React, { useState } from 'react';

const RatingStar = ({ totalStars = 5, initialRating = 0, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    if (onRatingChange) {
      onRatingChange(selectedRating);
    }
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={index}
            onClick={() => handleStarClick(starValue)}
            style={{ cursor: 'pointer', color: starValue <= rating ? 'gold' : 'gray', fontSize:'24px' }}
          >
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default RatingStar;
