import React from "react";
import { useToggle, useEffect, useState, useRef } from "react";
import Header from "../../CommonSection/Header/Header";
import "./profile.css";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import axios from "axios";
import { useFormik } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";

import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import PersonalDetails from "./PersonalDetailsProfile";
import ComanyDetailsProfile from "./ComanyDetailsProfile";
import PersonalDetailsProfile from "./PersonalDetailsProfile";
import TrustAccountDetailsProfile from "./TrustAccountDetailsProfile";
import PortalAccountDetailsProfile from "./PortalAccountDetailsProfile";
import PersonalDetailsAgent from "../Dashboard-agent/PersonalDetailsAgent";
import ContactDetailsAgent from "../Dashboard-agent/ContactDetailsAgent";
import Experience from "../Dashboard-agent/ExperienceAgent";
import ExperienceProfile from "../Dashboard-agent/ExperienceAgent";
import RemunerationDetailsAgent from "../Dashboard-agent/RemunerationDetailsAgent";
import LicensingDetailsAgent from "../Dashboard-agent/LicensingDetailsAgent";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import BradingDetailsProfile from "./BradingDetailsProfile";
import Sidebar from "../../CommonSection/SideBar/profileSidebar"
import { dynamicUrl } from "../../../Utilities/profileManagement";

const Profile = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("Profile");
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("PersonalDetails");

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate,text)
    setActiveTopic(text);
  };

  const handle = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };
  const [userId, setUserId] = useState("");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="dashboard-agency">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div
        className="blank_space"
        style={{
          backgroundColor: "white",
          height: "80px",
          padding: "10px",
        }}
      >
        <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
      </div>
      <div className="continer-dbag">
        <div className="dashboardagency-content">
        <Sidebar
        visible={sidebarVisible}
        handleTopicValue={handleTopicValue}
        topicValue={activeTopic}
      />
          {/* <div className="sidebar-toggle-2">
                        {!visiblet && <GiHamburgerMenu onClick={() => setVisiblet(!visiblet)} />}
                    </div> */}
          <div className="pa-content-top-level">
            <Tabs
              defaultActiveKey="PersonalDetails"
              activeKey={activeKey}
              id="fill-tab-example"
              className="mb-3 properties-nav-bar"
              onSelect={handleTabChange}
              fill
            >
              <Tab eventKey="PersonalDetails" title="Personal Details">
                <PersonalDetailsProfile
                  handleToastMessage={handle}
                  handleCurrentPage={handleTabChange}
                />
              </Tab>
              <Tab eventKey="CompanyDetails" title="Company Details">
                <ComanyDetailsProfile
                  handleToastMessage={handle}
                  handleCurrentPage={handleTabChange}
                />
              </Tab>
              <Tab eventKey="Branding" title="Branding">
                <BradingDetailsProfile
                  handleToastMessage={handle}
                  handleCurrentPage={handleTabChange}
                />
              </Tab>
              <Tab eventKey="TrustAccountDetails" title="Trust Account Details">
                <TrustAccountDetailsProfile
                  handleToastMessage={handle}
                  handleCurrentPage={handleTabChange}
                />
              </Tab>
              <Tab
                eventKey="PortalAccountDetails"
                title="Portal Account Details"
              >
                <PortalAccountDetailsProfile
                  handleToastMessage={handle}
                  handleCurrentPage={handleTabChange}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
