import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";


dayjs.extend(customParseFormat);

const initialValues = {
    AgreementId: '',
    RentAmount: '',
    Period: '',
    LeaseCommencement: '',
    BSB: '',
    AccountNo: '',
    AccountName: '',
    PaymentReference: '',
    PaymentTo: '',
    PaymentAt: '',
    PaymentFollows: '',
    BondAmount: '',
    BondAmountProvidedTo: '',
};

const Rent = ({ getDetails, handleNextPage }) => {
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);

    useEffect(() => {
        if (getDetails) {
            setFieldValue("RentAmount", getDetails.rentAmount);
            setFieldValue("Period", getDetails.period);
            setFieldValue("LeaseCommencement", formatDate(getDetails.leaseCommencement));
            setFieldValue("BSB", getDetails.bsb);
            setFieldValue("AccountNo", getDetails.accountNo)
            setFieldValue("AccountName", getDetails.accountName)
            setFieldValue("PaymentReference", getDetails.paymentReference);
            setFieldValue("PaymentTo", getDetails.paymentTo);
            setFieldValue("PaymentAt", getDetails.paymentAt);
            setFieldValue("PaymentFollows", getDetails.paymentFollows)
            setFieldValue("BondAmount", getDetails.bondAmount)
            setFieldValue("BondAmountProvidedTo", getDetails.bondAmountProvidedTo)

        }
    }, [getDetails]);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${day}-${month}-${year}`;
    }


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);


    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: AddOpenHomeSchema,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        handleNextPage("impInfo");
    };

    const handleClear = () => {
        handleNextPage("");
    };

    const handleCancle = () => {
        navigate("/agency/ListAgreement");
    };

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                                    <div style={{ width: '100%' }}>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Rent`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">The rent is</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.RentAmount}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Period</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.Period === 1 ? "Weekly" : "Monthly"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Payable in advance starting on</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LeaseCommencement}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">A/C Name  </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.AccountName}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">BSB  </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.BSB}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">A/C No </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.AccountNo}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Payment reference </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PaymentReference}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">to </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PaymentTo}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">at </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PaymentAt}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">as follows </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PaymentFollows}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Rental Bondrental Bond`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">A rental bond of </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.BondAmount}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">The tenant provided the rental bond amount to</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.BondAmountProvidedTo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rent;
