import React, { useEffect, useState, useRef } from "react";
import "./LandingPage.css";
import Header from "../Header/Header";
import { useFormik } from "formik";
import FooterNew from "../FooterNew/FooterNew";
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { BiBath, BiBed, BiCar, BiEnvelope, BiPhone } from "react-icons/bi";
import BasicDetails from "../../../src/input-components/basicDetails";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import TextBox from "../../../src/input-components/textbox";
import SelectBox from "../../../src/input-components/selectBoxNew";
import Textarea from "../../../src/input-components/textarea";
import { Checkbox } from "antd";
import {
  LandingPageDetails,
  FilePath,
  AddEnquiry,
} from "../../CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, enquiryType } from "../../../Utilities/enum";
import RatingStar from "../../../src/input-components/ratingStar";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { FaLocationDot } from "react-icons/fa6";

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  message: "",
  userType: "",
  schedulingInsp: false,
  priceInfo: false,
  ratesFee: false,
  similarProperties: false,
};

const LandingDetailsPage = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [propertyImg, setPropertyImg] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [basicDetails, setBasicDetails] = useState([]);
  const [displayMarker, setDisplayMarker] = useState(false);
  const [displayImg, setDisplayImg] = useState(false);

  const [center, setCenter] = useState({ lat: 12.971599, lng: 77.594566 });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBlCoMA7GMoWtsv6mmdz2Q2AFopWV5PRZY",
  });
  const [agentPic, setAgentPic] = useState("");
  const [userType, setUserType] = useState([
    { label: "Seller", value: 1 },
    { label: "Buyer", value: 2 },
    { label: "Investor", value: 3 },
    { label: "Tenant", value: 4 },
    { label: "Landlord", value: 5 },
    { label: "Developer", value: 5 },
    { label: "Supplier", value: 5 },
  ]);
  const ToastRef = useRef();

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUniqueId = url.searchParams.get("propertyUniqueId");
    PostApiWithSpinner(propertyUniqueId);
  }, []);

  useEffect(() => {
    const storedDisplayMarker = JSON.parse(
      localStorage.getItem("displayMarker")
    );
    if (storedDisplayMarker !== null) {
      setDisplayMarker(storedDisplayMarker);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("displayMarker", JSON.stringify(displayMarker));
  }, [displayMarker]);

  const PostApiWithSpinner = (propertyUniqueId) => {
    setDisplayMarker(true);
    const baseUrl = LandingPageDetails;
    const data = {
      PropertyUniqueId: propertyUniqueId,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === 1) {
          let detailsData = response.object;
          setPropertyDetails(detailsData);
          const convertedImgData = detailsData.propertyPictures.map((item) => {
            return {
              src: FilePath + item.picPath,
              width: 10, // Set width as needed
              height: 10, // Set height as needed
            };
          });
          setPropertyImg(convertedImgData);
          let checkoutDetails = {
            Carpet_Area: detailsData.propertyLandAreaL,
            Property_Agent: detailsData.agent_Name,
            Project: detailsData.agencyName,
            Status: detailsData.propertyStatus,
          };
          setBasicDetails(checkoutDetails);
          let agentPic = detailsData.agent_Photo_Path
            ? FilePath + detailsData.agent_Photo_Path
            : FilePath + "profile/default.png";
          setAgentPic(agentPic);

          setDisplayImg(true);
          setCenter({
            lat:
              parseFloat(detailsData.propertyGooglePlacesLocationLat) ||
              12.971599,
            lng:
              parseFloat(detailsData.propertyGooglePlacesLocationLong) ||
              77.594566,
          });

          setDisplayMarker(true);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const moveToFirst = (index) => {
    const newItems = [...propertyImg];
    const [movedItem] = newItems.splice(index, 1);
    newItems.unshift(movedItem); // Add the moved item to the beginning
    setPropertyImg(newItems);
  };
  const SortablePhoto = ({ index, photo }) => (
    <div key={index} className="multi-img " onClick={() => moveToFirst(index)}>
      <img
        src={photo.src}
        alt={photo.title}
        height={150}
        style={{ width: "100%", marginBottom: "10px" }}
      />
    </div>
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = [...propertyImg];
    const [movedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, movedItem);
    setPropertyImg(newItems);
  };

  const SortableGallery = SortableContainer(({ items }) => (
    <div className="gallery-container">
      {/* Render the first image separately */}
      {items.length > 0 && (
        <div className="first-image-container">
          <img
            style={{ width: "100%", height: "24%" }}
            src={items[0].src}
            alt={items[0].title}
          />
        </div>
      )}

      {/* Render the rest of the images in a gallery */}
      <div className="gallery" style={{ maxHeight: items.length > 6 ? "400px" : "auto", overflowY: "auto" }}>
        <Gallery
          photos={items}
          renderImage={(props) => <SortablePhoto {...props} />}
        />
      </div>
    </div>
  ));

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (displayMarker) {
        map.setZoom(18);
        bounds.extend(center);
        //map.fitBounds(bounds);
        //map.setCenter(center);
      } else {
        // Center the map and set an appropriate zoom level
        map.setCenter(center);
        map.setZoom(18); // Set an appropriate zoom level here
      }
      setMap(map);
    },
    [center, displayMarker]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    var urls = new URL(window.location.href);
    var propertyId = urls.searchParams.get("propertyId");
    const formData = {
      EnquiryDetails: values.message,
      EnquiryType: enquiryType.property,
      PropertyId: propertyId,
      PriceInformation: values.priceInfo,
      ScheduleAnInspection: values.schedulingInsp,
      RatesFees: values.ratesFee,
      SimilarProperties: values.similarProperties,
      UserName: values.firstName + " " + values.lastName,
      UserEmail: values.email,
      UserPhone: values.phone,
      UserType: values.userType,
    };
    const url = AddEnquiry;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      "",
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", returnData.message]);
      });
  };

  const onChange = (e, label) => {
    if (label === "Scheduling") {
      setFieldValue("schedulingInsp", e.target.checked);
    } else if (label === "Price") {
      setFieldValue("priceInfo", e.target.checked);
    } else if (label === "Rates") {
      setFieldValue("ratesFee", e.target.checked);
    } else if (label === "Properties") {
      setFieldValue("similarProperties", e.target.checked);
    }
  };

  return (
    <div className="landingpage">
      <Header />

      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "100px", padding: "10px" }}
      ></div>
      <div
        className="main-banner"
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "30px",
        }}
      >
        {/* Gallery Component */}
        <div
          className="pl-5 pr-5 pb-5"
          style={{ width: "100%", height: "auto" }}
        >
          <div className="address-label mb-2">
            <FaLocationDot /> {propertyDetails.getFormatedAddress}
          </div>
          {/* Set static width and height */}
          {displayImg ? (
            <div className="detailsImgMain-Container">
              <SortableGallery
                items={propertyImg}
                axis={"xy"}
                onSortEnd={onSortEnd}
              />
            </div>
          ) : null}
          <div className="mt-2 detailsImg-Container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="pr-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BiBed className="mr-2" style={{ fontSize: "20" }} />
                {propertyDetails.countBedrooms
                  ? propertyDetails.countBedrooms
                  : "0"}{" "}
                Beds
              </div>
              <div
                className="pr-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BiBath className="mr-2" style={{ fontSize: "20" }} />
                {propertyDetails.countBathrooms
                  ? propertyDetails.countBathrooms
                  : "0"}{" "}
                Bathroom
              </div>
              <div
                className="pr-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BiCar className="mr-2" style={{ fontSize: "20" }} />
                {propertyDetails.countCarParking ||
                propertyDetails.countGarageSpace
                  ? propertyDetails.countCarParking +
                    propertyDetails.countGarageSpace
                  : "0"}{" "}
                Parking
              </div>
            </div>
          </div>
          <div className="mt-2 detailsImg-Container">
            <BasicDetails details={basicDetails} noOfColumnInRow={4} />
          </div>
          <div style={{ display: "flex" }}>
            <div className="mr-2" style={{ width: "70%" }}>
              <div className="pb-5 mt-2 detailsImg-Container">
                <h4>Why buy in this property ?</h4>
                <ul>
                  <li>
                    <pre>{propertyDetails.propertyDescription} </pre>
                  </li>
                </ul>
              </div>
              <div className="pb-5 mt-2 detailsImg-Container">
                <h4>More Details ?</h4>
                <div>
                  Price :{" "}
                  <span>
                    $
                    {propertyDetails.propertySaleRental == "1" &&
                    propertyDetails.propertyPrice
                      ? propertyDetails.propertyPrice
                      : propertyDetails.propertySaleRental == "2" &&
                        propertyDetails.rentPrice}
                  </span>
                </div>
                <div>
                  Address :{" "}
                  <span>{propertyDetails.getFormatedAddress}</span>
                </div>
                {/* <div>
                  Living area :{" "}
                  <span>
                    {propertyDetails.countLivingAreas
                      ? propertyDetails.countLivingAreas
                      : "N/A"}
                  </span>
                </div> */}
              </div>
            </div>
            <div style={{ width: "30%", height:'100%' }} className="mt-2 details-Container2">
              <div className="details-Container2Title">
                {propertyDetails.agencyName}
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "1.5rem",
                  borderBottom: "1px solid #cecece",
                }}
              >
                <div style={{ borderRadius: "50%" }}>
                  <img
                    src={agentPic}
                    alt="man"
                    width="75%"
                    height="auto"
                    style={{ borderRadius: "50%", maxWidth: "100px" }}
                  />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <div
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      wordBreak: "break-all",
                    }}
                  >
                    {propertyDetails.agent_Name}
                  </div>
                  <div
                    style={{
                      fontSize: "1em",
                      wordBreak: "break-all",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <RatingStar totalStars={1} initialRating={1} />
                    {"5.0 (review 1)"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "1.5rem",
                  borderBottom: "1px solid #cecece",
                }}
              >
                <div style={{ borderRadius: "50%" }}>
                  {propertyDetails.agencyName}
                  <div>{propertyDetails.propertySellerAddress}</div>
                </div>
              </div>
              <div style={{ padding: "1.5rem" }}>
                <div
                  className="pb-2"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button className="pdb-save-changes" type="submit">
                    Get in touch
                  </button>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button className="pdb-cancel" type="submit">
                    Save Property
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 detailsImgMain-Container">
            {/* Maps */}
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={8}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {displayMarker ? <Marker position={center} /> : null}
                {/* Child components, such as markers, info windows, etc. */}
                <></>
              </GoogleMap>
            ) : (
              <></>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }}>
              <div className="mr-2" style={{ width: "70%" }}>
                <form className="details-form">
                  <div className="property-details-content df-all all-input">
                    <div style={{ width: "100%" }}>
                      <h3>Send Your Enquiry</h3>
                    </div>
                    <div>What's your enquiry about ?</div>
                    <div className="all-input">
                      <div className="pd col-lg-5 col-sm-12">
                        <Checkbox
                          checked={values.schedulingInsp}
                          onChange={(e) => onChange(e, "Scheduling")}
                        >
                          Scheduling an inspection
                        </Checkbox>
                      </div>
                      <div className="pd col-lg-5 col-sm-12">
                        <Checkbox
                          checked={values.priceInfo}
                          onChange={(e) => onChange(e, "Price")}
                        >
                          Price information
                        </Checkbox>
                      </div>
                      <div className="pd col-lg-5 col-sm-12">
                        <Checkbox
                          checked={values.ratesFee}
                          onChange={(e) => onChange(e, "Rates")}
                        >
                          Rates & Fees
                        </Checkbox>
                      </div>
                      <div className="pd col-lg-5 col-sm-12">
                        <Checkbox
                          checked={values.similarProperties}
                          nChange={(e) => onChange(e, "Properties")}
                        >
                          Similar Properties
                        </Checkbox>
                      </div>
                    </div>
                    <div className="all-input">
                      <SelectBox
                        label="User Type"
                        name="userType"
                        options={userType}
                        value={values.userType}
                        onChange={(val) => setFieldValue("userType", val.value)}
                        error={errors.userType}
                        touched={touched.userType}
                      />
                    </div>
                    <div className="all-input">
                      <TextBox
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.firstName}
                        touched={touched.firstName}
                      />
                      <TextBox
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.lastName}
                        touched={touched.lastName}
                      />
                      <TextBox
                        label="Phone"
                        placeholder="Phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.phone}
                        touched={touched.phone}
                        icon={<BiPhone height="100%" width="100%" />}
                      />
                      <TextBox
                        label="Email address"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email}
                        touched={touched.email}
                        icon={<BiEnvelope height="100%" width="100%" />}
                      />
                      <Textarea
                        colVal={12}
                        label="Message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.message}
                        touched={touched.message}
                      />
                    </div>
                  </div>
                  <div className="property-details-buttons">
                    <button
                      className="pdb-save-changes1"
                      type="button"
                      onClick={handleFinalSubmit}
                    >
                      Send enquiry
                    </button>
                  </div>
                </form>
              </div>
              <div
                style={{ width: "30%", height: "50%" }}
                className="mt-2 details-Container2"
              >
                <div className="details-Container2Title">
                  {propertyDetails.agencyName}
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "1.5rem",
                    borderBottom: "1px solid #cecece",
                  }}
                >
                  <div style={{ borderRadius: "50%" }}>
                    <img
                      src={agentPic}
                      alt="man"
                      width="75%"
                      height="auto"
                      style={{ borderRadius: "50%", maxWidth: "100px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <div
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        wordBreak: "break-all",
                      }}
                    >
                      {propertyDetails.agent_Name}
                    </div>
                    <div
                      style={{
                        fontSize: "1em",
                        wordBreak: "break-all",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <RatingStar totalStars={1} initialRating={1} />
                      {"5.0 (review 1)"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "1.5rem",
                    borderBottom: "1px solid #cecece",
                  }}
                >
                  <div style={{ borderRadius: "50%" }}>
                    {propertyDetails.agencyName}
                    <div>{propertyDetails.propertySellerAddress}</div>
                  </div>
                </div>
                <div style={{ padding: "1.5rem" }}>
                  <div
                    className="pb-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button className="pdb-save-changes" type="submit">
                      Get in touch
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="pdb-cancel" type="submit">
                      Save Property
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <FooterNew />
    </div>
  );
};

export default LandingDetailsPage;
