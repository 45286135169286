import React, { useMemo, useRef, useState, useEffect } from "react";
import debounce from "lodash/debounce";
import "./propertiesagency.css";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import { createPropertyAddress, SearchPropertyForAddListing } from "../../../CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { PropertyDetailsSchema } from "../../../CommonSection/Schema/schema";
import AddressInput from "../../../../src/input-components/address";
import Header from "../../../CommonSection/Header/Header";
import Sidebar from "../../../CommonSection/SideBar/sideBar";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import { Select, Spin } from "antd";


const initialValues = {
  propertyFor: "",

  propertyType: "",

  assignedTo: "",

  address: "",

  unitNumber: "",

  streetNumber: "",

  streetName: "",

  suburb: "",

  postCode: "",

  state: "",
};

const PropertyDetailsAgency = ({ getDetails, reportTo }) => {
  const [address, setAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAllProperties");
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [addNewProperty, setAddNewProperty] = useState(false);
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);

          setUserId(parsedData.userId);

          setAgencyId(parsedData.agencyID);

        } else {

          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => { }, [reportTo]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setPropertyId(getDetails.propertyId);
      setFieldValue("propertyType", parseInt(getDetails.propertyType));
      setAddress(getDetails.getFormatedAddress);
      setFieldValue("address", getDetails.getFormatedAddress);
      setLatitude(getDetails.propertyGooglePlacesLocationLat);
      setLongitude(getDetails.propertyGooglePlacesLocationLong);
      const matchingItem = reportTo.find(
        (item) => parseInt(item.value, 10) === getDetails.agentId
      );
      if (matchingItem) {
        setSelectedCity(matchingItem.value);
        setFieldValue("assignedTo", matchingItem.value);
      }
    }
  }, [getDetails, reportTo]);


  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };


  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,

    validationSchema: PropertyDetailsSchema,

    onSubmit: (values, action) => {
      values.address = address;

      values.propertyFor = values.propertyFor;

      values.propertyType = values.propertyType;

      values.assignedTo = parseInt(selectedCity, 10);

      handleFinalSubmit();
    },
  });

  const property_type = [
    { label: "House", value: 7 },
    { label: "Apartment", value: 3 },
    { label: "Flat", value: 6 },
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];


  const handlepressSelect = async (addrs) => {
    debugger;
    setAddress(addrs);

    try {
      const results = await geocodeByAddress(addrs);

      const details = results[0];

      const fullFormattedAddress = details.formatted_address;

      setAddress(fullFormattedAddress);
      setFieldValue("address", fullFormattedAddress);

      const { address_components } = details;

      const address = {};

      address_components.forEach((component) => {
        const { types, long_name } = component;

        if (types.includes("subpremise")) {
          address.unitNumber = long_name;
        } else if (types.includes("street_number")) {
          address.streetNumber = long_name;
        } else if (types.includes("route")) {
          address.streetName = long_name;
        } else if (types.includes("locality")) {
          address.suburb = long_name;
        } else if (types.includes("postal_code")) {
          address.postCode = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = long_name;
        }
        let latitude = details.geometry.location.lat();
        let longitude = details.geometry.location.lng();

        setLatitude(latitude);

        setLongitude(longitude);

        setValues({ ...values, ...address });
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,

      PropertySaleRental: values.propertyFor,

      PropertyType: values.propertyType,

      AgentId: parseInt(selectedCity, 10),

      AgencyId: agencyID,

      PropertyGooglePlacesLocation: values.address,

      State: values.state,

      Suburb: values.suburb,

      PostCode: values.postCode,

      UnitNumber: values.unitNumber,

      StreetNumber: values.streetNumber,

      StreetName: values.streetName,

      UserId: userId,

      PropertyGooglePlacesLocationLong: longitude,

      PropertyGooglePlacesLocationLat: latitude,
    };

    const url = createPropertyAddress;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigate(`/agency/AddNewPropertiesListings?propertyId=${returnData.id}&isRentalListed=${false}&isSaleListed=${false}&&propertyUId=${returnData.sUID}`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };


  const handleAssignedTo = (e) => {
    setSelectedCity(e.value);

    setFieldValue("assignedTo", e.value);
  };

  const handleView = () => {
    setViewAddressDetails(!viewAddressDetails);
  };

  const handleAddNewPoperty = () => {
    setAddNewProperty(!addNewProperty);
  };

  const handlecancel = () => {
    setAddNewProperty(false);
  };
  const handlesetAddress = async (addrs) => {
    setAddress(addrs);
    setFieldValue("address", addrs);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchPropertyForAddListing;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
              id: item.id,
              isRentalListed: item.isRentalListed,
              isSaleListed: item.isSaleListed,
              propertyUId: item.propertyUId
            }));
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (e, value, id) => {
    debounceFetcher(value);
    navigate(`/agency/AddNewPropertiesListings?propertyId=${value.id}&isRentalListed=${value.isRentalListed}&isSaleListed=${value.isSaleListed}&&propertyUId=${value.propertyUId}`);
  };


  return (
    <div className="dashboard-agency">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
        <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
          {!addNewProperty ?
            <div className="property-details-content df-all all-input">
              <div className="contacts-group">
                <div className="contacts-group pd-address">
                  <div className={`lda col-lg-5 col-sm-12`}>
                    <label className="s1">Select Existing Property </label>
                    <div style={{ display: "flex" }}>
                      <Select
                        style={{ width: '100%' }}
                        className="landingPageSearchs"
                        mode="single"
                        showSearch
                        placeholder="Search Exisiting Property"
                        labelInValue
                        filterOption={false}
                        onSearch={debounceFetcher}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        options={options}
                        onChange={(label, value, listingId) =>
                          handleSearchProperty(label, value, listingId)
                        }
                        allowClear
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }} className="mt-5 lda col-lg-5 col-sm-12">
                    OR
                  </div>
                  <button style={{ width: '39%' }} className="pdb-save-changes ml-3 mt-5" type="button" onClick={handleAddNewPoperty}>
                    Add New Property
                  </button>
                </div>
              </div>
            </div> : null}
          {addNewProperty ? <div className="property-details-content df-all all-input">
            <div className="contacts-group">
              <SelectBox
                isMandatory
                label="Property Type"
                name="propertyType"
                options={property_type}
                placeholder={"Select Property Type"}
                value={values.propertyType}
                onChange={(val) => setFieldValue("propertyType", val.value)}
                error={errors.propertyType}
                touched={touched.propertyType}
              />

              <div className="contacts-group pd-address">
                <AddressInput
                  address={address}
                  setAddress={handlesetAddress}
                  handlePressSelect={handlepressSelect}
                  error={errors.address}
                  touched={touched.address}
                  isMandatory
                  handleView={handleView}
                  viewDetails={true}
                />

                {viewAddressDetails ? (
                  <TextBox
                    addressMargin={true}
                    label=""
                    placeholder="Unit Number"
                    id="unitNumber"
                    name="unitNumber"
                    value={values.unitNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.unitNumber}
                    touched={touched.unitNumber}
                  />
                ) : null}

                {viewAddressDetails ? (
                  <TextBox
                    label=""
                    placeholder="Street Number"
                    id="streetNumber"
                    name="streetNumber"
                    value={values.streetNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.streetNumber}
                    touched={touched.streetNumber}
                  />
                ) : null}

                {viewAddressDetails ? (
                  <TextBox
                    label=""
                    placeholder="Street Name"
                    id="streetName"
                    name="streetName"
                    value={values.streetName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.streetName}
                    touched={touched.streetName}
                  />
                ) : null}

                {viewAddressDetails ? (
                  <TextBox
                    label=""
                    placeholder="Suburb"
                    id="suburb"
                    name="suburb"
                    value={values.suburb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.suburb}
                    touched={touched.suburb}
                  />
                ) : null}

                {viewAddressDetails ? (
                  <TextBox
                    label=""
                    placeholder="State"
                    id="state"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.state}
                    touched={touched.state}
                  />
                ) : null}

                {viewAddressDetails ? (
                  <TextBox
                    label=""
                    placeholder="Postcode"
                    id="postCode"
                    name="postCode"
                    value={values.postCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.postCode}
                    touched={touched.postCode}
                  />
                ) : null}
              </div>
              <div className="contacts-group pd-address">
                <div className="mt-5 cda-reportTo col-lg-6 col-sm-12">
                  <label htmlFor="trading state" className="s1 w-100">
                    Assigned to<span style={{ color: "red" }}>*</span>
                  </label>

                  <Dropdown
                    value={selectedCity}
                    onChange={handleAssignedTo}
                    options={dpdata}
                    optionLabel="text"
                    className="w-100 md:w-14rem"
                    placeholder="Select Assigned to"
                  />

                  {errors.assignedTo && touched.assignedTo ? (
                    <p className="form-error">{errors.assignedTo}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div> : null}

          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={() =>
                handlecancel()
              }
            >
              Cancel
            </button>

            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyDetailsAgency;
