import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementId: '',
    MaximumTenant: '',
    ElectricalRepair: '',
    ElectricalRepairContact: '',
    PlumbingRepair: '',
    PlumbingRepairContact: '',
    OtherRepair: '',
    OtherRepairContact: '',
    PayWaterBillSeperately: false,
    IsElectricitySupplied: false,
    IsGasSupplied: false,
    SmokeAlarmType: '',
    SmokeAlarmBatteryReplacable: false,
    BatteryType: '',
    BackupBatteryReplacable: false,
    TypeOfBackupBattery: '',
    StrataRsponsibleForReplacement: false,
    StrataByLawsApplicable: false,
    DoesLandlordConsentElectronic: false,
    LandlordElctrcConsentEmail: '',
    DoesTenantConsentElectronic: false,
    TenantElctrcConsentEmail: '',
    PetDetails: '',
    AdditionalComments: '',
};

const ImpInfo = ({ getDetails, handleNextPage }) => {
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);

    useEffect(() => {
        if (getDetails) {
            setFieldValue("MaximumTenant", getDetails.maximumTenant);
            setFieldValue("ElectricalRepair", getDetails.electricalRepair);
            setFieldValue("ElectricalRepairContact", getDetails.electricalRepairContact);
            setFieldValue("PlumbingRepair", getDetails.plumbingRepair);
            setFieldValue("PlumbingRepairContact", getDetails.plumbingRepairContact);
            setFieldValue("OtherRepair", getDetails.otherRepair);
            setFieldValue("OtherRepairContact", getDetails.otherRepairContact);
            setFieldValue("PayWaterBillSeperately", getDetails.payWaterBillSeperately);
            setFieldValue("IsElectricitySupplied", getDetails.isElectricitySupplied);
            setFieldValue("IsGasSupplied", getDetails.isGasSupplied)
            setFieldValue("SmokeAlarmType", getDetails.smokeAlarmType.toString())
            setFieldValue("SmokeAlarmBatteryReplacable", getDetails.smokeAlarmBatteryReplacable);
            setFieldValue("BatteryType", getDetails.batteryType);
            setFieldValue("BackupBatteryReplacable", getDetails.backupBatteryReplacable);
            setFieldValue("TypeOfBackupBattery", getDetails.typeOfBackupBattery);
            setFieldValue("StrataRsponsibleForReplacement", getDetails.strataRsponsibleForReplacement);
            setFieldValue("StrataByLawsApplicable", getDetails.strataByLawsApplicable);
            setFieldValue("DoesLandlordConsentElectronic", getDetails.doesLandlordConsentElectronic)
            setFieldValue("LandlordElctrcConsentEmail", getDetails.landlordElctrcConsentEmail)
            setFieldValue("DoesTenantConsentElectronic", getDetails.doesTenantConsentElectronic);
            setFieldValue("TenantElctrcConsentEmail", getDetails.tenantElctrcConsentEmail);
            setFieldValue("PetDetails", getDetails.petDetails)
            setFieldValue("AdditionalComments", getDetails.additionalComments)
        }
    }, [getDetails]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: AddOpenHomeSchema,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        handleNextPage("sign");
    };

    const handleClear = () => {
        handleNextPage("");
    };

    const handleCancle = () => {
        navigate("/agency/ListAgreement");
    };

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                                    <div style={{ width: '100%' }}>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Maximum number of occupants`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">No more than </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.MaximumTenant}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Urgent repairs`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Electrical Repair</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.ElectricalRepair}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Telephone </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.ElectricalRepairContact}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Plumbing repairs </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PlumbingRepair}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Telephone </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PlumbingRepairContact}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">Other repairs </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.OtherRepair}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Telephone</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.OtherRepairContact}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Water usage`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Will the tenant be required to pay separately for water usage?</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PayWaterBillSeperately ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Utilities`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Is electricity supplied to the premises from an embedded network</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.IsElectricitySupplied ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Is gas supplied to the premises from an embedded network?</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.IsGasSupplied ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Rental Bondrental Bond`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">premises are hardwired or battery operated  </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.SmokeAlarmType ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">If the smoke alarms are battery operated, are the batteries in the smoke alarms of a kind the tenant can replace? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.SmokeAlarmBatteryReplacable ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">If yes, specify the type of battery that needs to be used if the battery in the smoke alarm needs to be replaced </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.BatteryType}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">If the smoke alarms are hardwired, are the back-up batteries in the smoke alarms of a kind the tenant can replace?  </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.BackupBatteryReplacable ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">If yes, specify the type of back-up battery that needs to be used if the back-up battery in the smoke alarm needs to be replaced</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TypeOfBackupBattery}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">If the Strata Schemes Management Act 2015 applies to the residential premises, is the owners corporation of the strata scheme responsible for the repair and replacement of smoke alarms in the residential premises? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.StrataRsponsibleForReplacement ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Are there any strata or community scheme by-laws applicable to the residential premises? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.StrataByLawsApplicable ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Landlord`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Does the landlord give express consent to the electronic service of notices and documents? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.DoesLandlordConsentElectronic ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Are there any strata or community scheme by-laws applicable to the residential premises? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordElctrcConsentEmail}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Tenant`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Does the tenant give express consent to the electronic service of notices and documents? </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.DoesTenantConsentElectronic ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Specify email address to be used for the purpose of serving notices and documents. </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantElctrcConsentEmail}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Pet Details </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PetDetails}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Additional Terms </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.AdditionalComments}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImpInfo;
