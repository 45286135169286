import { useNavigate } from "react-router-dom";
import { useToggle, useEffect, useState, useRef } from 'react'
import './AgentDashboard.css';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { AiOutlineArrowUp } from 'react-icons/ai'
import "chart.js/auto"
import { Chart } from "react-chartjs-2"
import CardBoxDashboard from "../../../../components/CommonSection/CardBoxDashboard/CardBoxDashboard";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { GiHamburgerMenu } from 'react-icons/gi'
import Header from "../../../../components/CommonSection/Header/Header";
import Sidebar from "../../../../components/CommonSection/SideBar/sideBar"
import { GetCookie,currentProfile } from "../../../../components/CommonSection/Cookie/Session";


const AgentDashboard = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("Dashboard");
    const [visiblet, setVisiblet] = useState(true);
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const handlechange = () => {
     
      toastRef.current.show({ type: "success", text: "Checking" })
    }
    const [topicValue, setTopicValue] = useState("Dashboard");
    const handle = (time, text) => {
    }
    const data = {
      labels: [0, 2, 15, 16, 16, 48, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18],
      datasets: [
        {
          label: "",
          data: [0, 2, 15, 16, 16, 48, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18],
          borderColor: 'green',
          borderWidth: 1,
          pointRadius: 0,
        },
  
      ],
  
    }
    const options = {
      scales: {
        x: {
          display: false, // Hide x-axis scale and labels
        },
        y: {
          display: false, // Hide y-axis scale and labels
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend (dataset label)
        },
        tooltip: {
          enabled: true,
          mode: 'nearest', // Display tooltips on the nearest data point
          intersect: true, // Display tooltips even if the cursor isn't directly over a data point
        },
      },
    };
  
    const data_next = {
      labels: [0, 2, 15, 16, 16, 14, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18, 49, 35, 34, 49, 35, 34, 49, 35, 34],
      datasets: [
        {
          label: "Main Graph",
          data: [0, 2, 15, 16, 16, 14, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18, 49, 35, 34, 49, 35, 34, 49, 35, 34],
          borderColor: 'green',
          borderWidth: 1,
          pointRadius: 0,
          fill: true,
        },
  
      ],
  
    }
    const options_next = {
      scales: {
        x: {
          display: false, // Hide x-axis scale and labels
        },
        y: {
          display: false, // Hide y-axis scale and labels
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend (dataset label)
        },
        tooltip: {
          enabled: true,
          mode: 'nearest', // Display tooltips on the nearest data point
          intersect: false, // Display tooltips even if the cursor isn't directly over a data point
        },
      },
    };
    const currentSideBar = currentProfile.get()

    const handleTopicValue = (text) => {
      setTopicValue(text);
      navigate(currentSideBar === 2 ? `/agency/` + text : `/agent/` + text);
      setActiveTopic(text);
    }
  
    return (
      <div className="dashboard-agency">
        <Header />
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '80px',
          padding: '10px',
        }}>
  
        </div>
        <div className='continer-dbag'>
          <div className="dashboardagency-content">
  
          <Sidebar
        visible={sidebarVisible}
        handleTopicValue={handleTopicValue}
        topicValue={activeTopic}
      />
            <div className="sidebar-toggle-2">
              {!visiblet &&<GiHamburgerMenu onClick={() => setVisiblet(!visiblet)} />}
            </div>
            <ToastAlert ref={toastRef} />
            {/* <div className="dashboardagency-content-top-level"> */}
            {/* <div className="dbag-blank-space">
              </div> */}
  
            <div className="dashboardagency-main-content" onClick={() => setVisiblet(false)}>
              <div className="dbag-content-one-row">
                <CardBoxDashboard time="Last Quater" name="My Application" value="20" percentage="100" data={data} options={options} color='green' />
                <CardBoxDashboard time="Last Quater" name="My Application" value="9" percentage="10" data={data} options={options} color='red' />
                <CardBoxDashboard time="Last Quater" name="My Application" value="20" percentage="100" data={data} options={options} color='green' />
  
              </div>
              <div className="dbag-content-one-row">
                <CardBoxDashboard time="Last Quater" name="My Application" value="10" percentage="10" data={data} options={options} color='red' />
                <CardBoxDashboard time="Last Quater" name="My Application" value="2" percentage="100" data={data} options={options} color='green' />
                <CardBoxDashboard time="Last Quater" name="My Application" value="2" percentage="10" data={data} options={options} color='red' />
              </div>
              <div className="dbag-content-main-graph">
                <div className="dbag-content-main-graph-content">
                  <div className="dbag-content-main-graph-name">
                    Marketing Campaign
                  </div>
                  <div className="dbag-content-main-graph-timeline">
                    <div className="dcmg-button" onClick={() => handle(1, 'day')}>
                      1 Day
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'week')}>
                      1 Weeks
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'month')}>
                      1 Month
                    </div>
                    <div className="dcmg-button" onClick={() => handle(6, 'month')}>
                      6 Month
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'year')}>
                      1 Year
                    </div>
                  </div>
                </div>
  
                <div className="dcmg-graph">
                  <Chart
                    width={90}// Set the width
                    height={25}
                    type="line"
                    data={data_next}
                    options={options_next}
                  />
  
                </div>
              </div>
              <div className="dbag-content-main-graph">
                <div className="dbag-content-main-graph-content">
                  <div className="dbag-content-main-graph-name">
                    Sales Data
                  </div>
                  <div className="dbag-content-main-graph-timeline">
                    <div className="dcmg-button" onClick={() => handle(1, 'day')}>
                      1 Day
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'week')}>
                      1 Weeks
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'month')}>
                      1 Month
                    </div>
                    <div className="dcmg-button" onClick={() => handle(6, 'month')}>
                      6 Month
                    </div>
                    <div className="dcmg-button" onClick={() => handle(1, 'year')}>
                      1 Year
                    </div>
                  </div>
                </div>
  
                <div className="dcmg-graph" >
                  <Chart
                    width={90}// Set the width
                    height={25}
                    type="line"
                    data={data_next}
                    options={options_next}
                  />
                </div>
              </div>
              <div className="dbag-content-one-row" >
                <CardBoxDashboard time="Last Quater" name="My Application" value="20" percentage="100" data={data} options={options} color="yellow" />
                <CardBoxDashboard time="Last Quater" name="My Application" value="9" percentage="10" data={data} options={options} color='aqua' />
                <CardBoxDashboard time="Last Quater" name="My Application" value="20" percentage="100" data={data} options={options} color='grey' />
  
              </div>
              <div className="dbag-content-one-row">
                <CardBoxDashboard time="Last Quater" name="My Application" value="10" percentage="10" data={data} options={options} color="blue" />
                <CardBoxDashboard time="Last Quater" name="My Application" value="2" percentage="100" data={data} options={options} color="black" />
                <CardBoxDashboard time="Last Quater" name="My Application" value="2" percentage="10" data={data} options={options} color="green" />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
  
    )
  }
  
 

export default AgentDashboard

