import { useCallback, useState, useEffect, useRef } from "react";
import "./completionnp.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';

const Completetion_np = () => {
  const [userId, setUserID] = useState();
  const [id, setId] = useState();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard');
  }


  // ***********************************************************************************************************

  return (
    <div className="completion" >

      <Header />
      <div className="col-sm-12 col-md-10 col-lg-8 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '80px',
          padding: '10px',
        }}></div>
        < div className="complete_content">
          <div className="image"><img src=".\assets\images\verified.png" alt="" /></div>
          <div className="complete_text">Congratulation you’ve been verified </div>
          <button className="button-add" onClick={handleClick}>
            Go to Dashboard
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Completetion_np;