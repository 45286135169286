import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./licensedetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import { useFormik } from "formik";
import {
  LicenceNumberBaseKey,
  LicenseApiKey,
  LicenseTokenUrl,
  LicenseVerifyUrl,
  addLicenseDetails,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import { dateChnager } from "../../CommonSection/Utility/Utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { ActionStatus, eNewAgencyProfileStatus } from "../../../Utilities/enum";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import {
  getDefaultDateValue,
  getDefaultFromValue,
  getdataForAgencyProgressbar,
  isNullOrEmpty,
  navigateToPageWithDelay,
  parseToDate,
} from "../../CommonSection/Utility/ComponentFunction";
import { LicenseDetailsSchema } from "../../CommonSection/Schema/schema";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import Textbox from "../../../src/input-components/textbox";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility";

const initialValues = {
  license: "",
  expiryDate: null,
  corporateLicenseNumber: "",
  corporateLicenseNumberExpiry: "",
};

const LicenseDetails = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyId, setAgencyId] = useState(0);
  const [status, setStatus] = useState(0);
  const [licenseToken, setLicenseToken] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const ToastRef = useRef();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        ToastRef.current.notify([
          "error",
          "Error retrieving data from AsyncStorage",
        ]);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetLicenseDetailsApi();
      }, 2);
    }
  }, [userUId]);

  const GetLicenseDetailsApi = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          const licenseDetails = returnData.object;
          setAgencyId(licenseDetails.agencyId);
          setStatus(licenseDetails.status);
          setValues({ ...values, ...licenseDetails });
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", "Internal Error"]);
      });
  };

  const {
    errors,
    values,
    setValues,
    setFieldValue,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: LicenseDetailsSchema,
    onSubmit: (values, action) => {
      submitLicenseDetails();
    },
  });

  const TokenGenerator = (corporateLicenseNumber, license) => {
    if (!isNullOrEmpty(corporateLicenseNumber, license)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        LicenseTokenUrl,
        "get",
        null,
        {
          Authorization: LicenceNumberBaseKey,
        }
      )
        .then((returnData) => {
          // Handle the response data
          if (returnData.status == "approved") {
            setLicenseToken(returnData.access_token);
            // console.log(token);
          } else {
            ToastRef.current.notify(["error", "Authentication Failed"]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleCorporateNum = async (event) => {
    let corporateLicenseNumber = event.target.value;
    let bearerToken = licenseToken;
    if (bearerToken == null) {
      TokenGenerator(corporateLicenseNumber);
    } else {
      if (!isNullOrEmpty(bearerToken)) {
        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          `${LicenseVerifyUrl}?licenceNumber=${corporateLicenseNumber}`,
          "get",
          null,
          {
            Authorization: `Bearer ${bearerToken}`,
            apiKey: LicenseApiKey,
          }
        )
          .then((returnData) => {
            if (returnData.length > 0) {
              const firstItem = returnData[0];
              setFieldValue(
                "corporateLicenseNumberExpiry",
                parseToDate(firstItem.expiryDate)
              );
              ToastRef.current.notify([
                "success",
                `Valid License Number ${corporateLicenseNumber}`,
              ]);
            } else {
              ToastRef.current.notify(["error", "Invalid License Number"]);
            }
          })
          .catch((error) => {
            console.error("Error Details:", error);
            console.error(
              "Error Data:",
              error.response ? error.response.data : "No response data"
            );
            console.error(
              "Error Status:",
              error.response ? error.response.status : "No response status"
            );
          });
      } else {
        ToastRef.current.notify(["error", "Please try again"]);
      }
    }
  };

  const handleLicenseNum = async (event) => {
    let license = event.target.value;
    let bearerToken = licenseToken;
    if (bearerToken == "") {
      TokenGenerator(license);
    } else {
      if (bearerToken != null) {
        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          `${LicenseVerifyUrl}?licenceNumber=${license}`,
          "get",
          null,
          {
            Authorization: `Bearer ${bearerToken}`,
            apiKey: LicenseApiKey,
          }
        ).then((returnData) => {
          if (returnData[0] != null) {
            ToastRef.current.notify([
              "success",
              `Valid License Number ${license}`,
            ]);

            setFieldValue("expiryDate", parseToDate(returnData[0].expiryDate));
          } else {
            ToastRef.current.notify(["error", "Invalid License Number"]);
          }
        });
      }
    }
  };

  const submitLicenseDetails = () => {
    const formData = {
      AgencyId: agencyId,

      UserId: userId,

      UserUniqueId: userUId,

      CorporateLicenseNumber: values.corporateLicenseNumber,

      CorporateLicenseNumberExpiry: values.corporateLicenseNumberExpiry,

      License: values.license,

      ExpiryDate: values.expiryDate,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addLicenseDetails,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/addcompanydetails", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error]);

        console.error("Error:", error);
      });
  };

  return (
    <div className="agency">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        {
          <ProgressbarLandlord
            message={getdataForAgencyProgressbar(
              status,
              eNewAgencyProfileStatus.LicenseDetailsAdded
            )}
          />
        }
        <div className="agency-page-title">{`Corporate  License Details`}</div>
        <form className="agency-input-form" onSubmit={handleSubmit}>
          <div className="license-input">
            <Textbox
              isMandatory
              label="Corporate License Number"
              colVal="mt-2"
              placeholder="Corporate License Number"
              name="corporateLicenseNumber"
              value={values.corporateLicenseNumber}
              onChange={handleChange}
              onBlur={handleCorporateNum}
              error={errors.corporateLicenseNumber}
              touched={touched.corporateLicenseNumber}
            />
            <Textbox
              readOnly
              label="Corporate License expiry"
              isMandatory
              colVal="mt-2"
              type="date"
              name="corporateLicenseNumberExpiry"
              placeholder="dd/mm/yyyy"
              value={FormatDate(values.corporateLicenseNumberExpiry)}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.corporateLicenseNumberExpiry}
              touched={touched.corporateLicenseNumberExpiry}
            />
            <Textbox
              isMandatory
              label="License Number"
              colVal="mt-2"
              placeholder="License Number"
              name="license"
              value={values.license}
              onChange={handleChange}
              onBlur={handleLicenseNum}
              error={errors.license}
              touched={touched.license}
            />
            <Textbox
              readOnly
              label="License expiry"
              isMandatory
              colVal="mt-2"
              type="date"
              name="expiryDate"
              placeholder="dd/mm/yyyy"
              value={FormatDate(values.expiryDate)}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.expiryDate}
              touched={touched.expiryDate}
            />
          </div>

          <button className="agency-button" type="submit">
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default LicenseDetails;
