import React, { useState } from "react";
import "./profileImage.css";
import { FilePath, defaultProfilePic } from "../../CommonSection/apiresources";
import { FaCamera } from "react-icons/fa";
export const ProfileImage = ({
  imageSrc,
  inputRef,
  handleFileChange,
  onClickHandler,
  isCircle,
  isPersonalImage
}) => {
  const imageSrcUrl =
    FilePath + (imageSrc != null ? imageSrc : defaultProfilePic);
  const [show, setShow] = useState(false);

  const showOverlay = () => {
    setShow(true);
  };

  const hideOverlay = () => {
    setShow(false);
  };

  return (
    <>
      <div
        onClick={onClickHandler}
        className={`${isPersonalImage ? 'personal-image-container' : 'profile-image-container'} ${isCircle ? 'circle' : ''}`}
        onMouseOver={showOverlay}
        onMouseLeave={hideOverlay}
      >
        <img
          className={`${isCircle ? "imgcircle" : ""}`}
          src={imageSrcUrl}
          alt="Image"
          width="100%"
          height="100%"
        />
        {show && (
          <div className={`overlayImage ${isCircle ? "circle" : ""}`}>
            <FaCamera />
          </div>
        )}
      </div>
      <input
        ref={inputRef}
        type="file"
        onChange={(e) => handleFileChange(e, 2)}
        className="upload-input"
        name="upload"
        hidden
      />
    </>
  );
};

export default ProfileImage;
