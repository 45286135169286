import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom'
import { addRemunrationAgentDetails } from "../../CommonSection/apiresources";
import { GetCookie } from '../../CommonSection/Cookie/Session'
import RadioInput from "../../../src/input-components/radioBtn"
import TextBox from "../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";

const initialValues = {
    variableComponent: "",
    fixedComponent: "",
    commisionType: "",
    unitType: "",
}

const RemunerationDetailsAgent = ({ handleparentsuccess,getDetails }) => {
    var url = new URL(window.location.href);
    var agentId = url.searchParams.get("value");
    var uniqueId = url.searchParams.get("uniqueId");
    let toastdata = [];
    const ToastRef = useRef();
    const inputRef = useRef();
    const navigate = useNavigate();
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [agencyID, setAgencyId] = useState('');
    const [agencyUID, setAgencyUID] = useState('');
    const { showSpinner, hideSpinner } = useSpinnerContext();

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData)
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUnique)
                }
            } catch (error) {
                console.error('Error retrieving data from AsyncStorage:', error);
            }
        };
        retrieveData();
    }, [userId]);


    useEffect(() => {
        if (getDetails) {
            setFieldValue('commisionType', getDetails.commisionType === 1 ? 'Fixed' : 'Flexible');
            setFieldValue('fixedComponent', getDetails.commissionPaid);
            setFieldValue('variableComponent', getDetails.commissionPayable);
            setFieldValue('unitType', getDetails.units === 1 ? 'Weekly' : 'Monthly');
        }
    }, [getDetails]);

    const { errors, values, touched, handleBlur, handleChange, handleSubmit,setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        }
    });

    const handleFinalSubmit = () => {
        const objectData = {
            AgencyId: agencyID,
            AgentId: agentId ? agentId: uniqueId,
            CommisionType: values.commisionType === "Fixed" ? 1 : 2,
            CommissionPaid: values.fixedComponent,
            Units: values.unitType === "Monthly" ? 2 : values.unitType === "Weekly" ? 1 : 3,
            CommissionPayable: values.variableComponent,
        };
        const url = addRemunrationAgentDetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, 'post', objectData).then((returnData) => {
            if (returnData.status === ActionStatus.Successfull) {
                handleparentsuccess(['success', returnData.message]);
                navigate('/agency/AgentList');
            } else {
                handleparentsuccess(['error', returnData.message]);
            }
        })
            .catch((error) => {
                handleparentsuccess(['error', error.message]);
            });
    };

    const options1 = [
        { label: 'Fixed', value: 'Fixed' },
        { label: 'Flexible', value: 'Flexible' },
    ];
    const unitTypeOptions = [
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Yearly', value: 'Yearly' },
    ];
    return (
        <div>
            <form action="none" onSubmit={handleSubmit} className='details-form'>
                <div className="df-all">
                    <div className="all-input-rda">
                        <RadioInput label="Commision Type" name="commisionType" options={options1} value={values.commisionType} onChange={handleChange} onBlur={handleBlur} error={errors.commisionType} touched={touched.commisionType}  />
                        <TextBox label="Fixed Component ($)" placeholder="Number" name="fixedComponent" value={values.fixedComponent} onChange={handleChange} onBlur={handleBlur} error={errors.fixedComponent} touched={touched.fixedComponent} />
                        <RadioInput row={true} label="Unit Type" name="unitType" options={unitTypeOptions} value={values.unitType} onChange={handleChange} onBlur={handleBlur} error={errors.unitType} touched={touched.unitType}  />
                        <TextBox label="Variable Component (%)" placeholder="Number" name="variableComponent" value={values.variableComponent} onChange={handleChange} onBlur={handleBlur} error={errors.variableComponent} touched={touched.variableComponent} />
                    </div>
                </div>
                <div className="property-details-buttons" >
                    <button className="pdb-cancel" type='reset' onClick={() => handleparentsuccess(['warn', 'Changes are cancelled'])}>
                        Cancel
                    </button>
                    <button className="pdb-save-changes" type='submit' >
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RemunerationDetailsAgent