import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updateInformationAgreementDetails} from "../../../components/CommonSection/apiresources";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { tenantInformation } from "../../../components/CommonSection/Schema/schema";
import "./tenantAgreement.css";
import RadioInput from "../../input-components/radioBtn";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementId: '',
    MaximumTenant: '',
    ElectricalRepair: '',
    ElectricalRepairContact: '',
    PlumbingRepair: '',
    PlumbingRepairContact:'',
    OtherRepair:'',
    OtherRepairContact:'',
    PayWaterBillSeperately:true,
    IsElectricitySupplied:false,
    IsGasSupplied:false,
    SmokeAlarmType:'',
    SmokeAlarmBatteryReplacable:false,
    BatteryType:'',
    BackupBatteryReplacable:false,
    TypeOfBackupBattery:'',
    StrataRsponsibleForReplacement:false,
    StrataByLawsApplicable:false,
    DoesLandlordConsentElectronic:false,
    LandlordElctrcConsentEmail:'',
    DoesTenantConsentElectronic:false,
    TenantElctrcConsentEmail:'',
    PetDetails:'',
    AdditionalComments:'',
};


const PropertyOwners = ({ onDataRecive, getDetails,handleNextPage }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [activeTopic, setActiveTopic] = useState("AddOpenHome");
    const [topicValue, setTopicValue] = useState("AddOpenHome");
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);
    const [address, setAddress] = useState("");
    const [secondaryOwner , setSecondaryOwner] = useState([])
    const [primaryContact , setPrimaryContact] = useState({
        address: "",
        contactEmail:"",
        fName:"",
        getFullAddress:"",
        lName:"",
        mobileNumber:"",
        ContactlistModel:[]
    })

    useEffect(() => {
        if (getDetails) {
            setFieldValue("MaximumTenant", getDetails.maximumTenant);
            setFieldValue("ElectricalRepair", getDetails.electricalRepair);
            setFieldValue("ElectricalRepairContact", getDetails.electricalRepairContact);
            setFieldValue("PlumbingRepair", getDetails.plumbingRepair);
            setFieldValue("PlumbingRepairContact", getDetails.plumbingRepairContact);
            setFieldValue("OtherRepair", getDetails.otherRepair);
            setFieldValue("OtherRepairContact", getDetails.otherRepairContact);
            setFieldValue("PayWaterBillSeperately", getDetails.payWaterBillSeperately ? getDetails.payWaterBillSeperately : true);
            setFieldValue("IsElectricitySupplied", getDetails.isElectricitySupplied);
            setFieldValue("IsGasSupplied",getDetails.isGasSupplied)
            setFieldValue("SmokeAlarmType",getDetails.smokeAlarmType.toString())
            setFieldValue("SmokeAlarmBatteryReplacable", getDetails.smokeAlarmBatteryReplacable);
            setFieldValue("BatteryType", getDetails.batteryType);
            setFieldValue("BackupBatteryReplacable", getDetails.backupBatteryReplacable);
            setFieldValue("TypeOfBackupBattery", getDetails.typeOfBackupBattery);
            setFieldValue("StrataRsponsibleForReplacement", getDetails.strataRsponsibleForReplacement );
            setFieldValue("StrataByLawsApplicable", getDetails.strataByLawsApplicable );
            setFieldValue("DoesLandlordConsentElectronic",getDetails.doesLandlordConsentElectronic )
            setFieldValue("LandlordElctrcConsentEmail",getDetails.landlordElctrcConsentEmail )
            setFieldValue("DoesTenantConsentElectronic", getDetails.doesTenantConsentElectronic);
            setFieldValue("PetDetails",getDetails.petDetails )
            setFieldValue("AdditionalComments",getDetails.additionalComments )  
            const formattedTenants = getDetails.tenants?.map(tenant => `${tenant.tenantEmail} ${tenant.tenantMobile}`).join(", ");
            setFieldValue("TenantElctrcConsentEmail", formattedTenants );
          
        }
    }, [getDetails]);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
        setFieldTouched
    } = useFormik({
        initialValues: initialValues,
        validationSchema: tenantInformation,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });


    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.agreeementId,
            MaximumTenant: parseFloat(values.MaximumTenant),
            ElectricalRepair: values.ElectricalRepair,
            ElectricalRepairContact: values.ElectricalRepairContact,
            PlumbingRepair: values.PlumbingRepair,
            PlumbingRepairContact: values.PlumbingRepairContact,
            OtherRepair:values.OtherRepair,
            OtherRepairContact:values.OtherRepairContact,
            PayWaterBillSeperately:values.PayWaterBillSeperately,
            IsElectricitySupplied:values.IsElectricitySupplied,
            IsGasSupplied:values.IsGasSupplied,
            SmokeAlarmType:values.SmokeAlarmType,
            SmokeAlarmBatteryReplacable:values.SmokeAlarmBatteryReplacable,
            BatteryType:values.BatteryType,
            BackupBatteryReplacable:values.BackupBatteryReplacable,
            TypeOfBackupBattery:values.TypeOfBackupBattery,
            StrataRsponsibleForReplacement:values.StrataRsponsibleForReplacement,
            StrataByLawsApplicable:values.StrataByLawsApplicable,
            DoesLandlordConsentElectronic:values.DoesLandlordConsentElectronic,
            LandlordElctrcConsentEmail:values.LandlordElctrcConsentEmail,
            DoesTenantConsentElectronic:values.DoesTenantConsentElectronic,
            TenantElctrcConsentEmail:values.TenantElctrcConsentEmail,
            PetDetails:values.PetDetails,
            AdditionalComments:values.AdditionalComments,
        };
        const url = updateInformationAgreementDetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    handleNextPage("sign")
                    onDataRecive(getDetails.propertyUId)
                    ToastRef.current.notify(["success", returnData.message]);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
             });
            
//handleNextPage1("propetyies")
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    const onChangeSwitch = (e, label) => {
        if (label === "PayWaterBillSeperately") {
            setFieldValue("PayWaterBillSeperately", e);
        } else if (label === "IsElectricitySupplied") {
            setFieldValue("IsElectricitySupplied", e);
        }
        else if (label === "IsGasSupplied") {
            setFieldValue("IsGasSupplied", e);
        }
        else if (label === "SmokeAlarmBatteryReplacable") {
            setFieldValue("SmokeAlarmBatteryReplacable", e);
        }
        else if (label === "BackupBatteryReplacable") {
            setFieldValue("BackupBatteryReplacable", e);
        }
        else if (label === "StrataRsponsibleForReplacement") {
            setFieldValue("StrataRsponsibleForReplacement", e);
        }
        else if (label === "StrataByLawsApplicable") {
            setFieldValue("StrataByLawsApplicable", e);
        }
        else if (label === "DoesLandlordConsentElectronic") {
            setFieldValue("DoesLandlordConsentElectronic", e);
        }
        else if (label === "DoesTenantConsentElectronic") {
            setFieldValue("DoesTenantConsentElectronic", e);
        }
    };

const licenseTypeOptions = [
    { label: 'Hardwired smoke alarms', value: 1 },
    { label: 'Battery operated smoke alarms', value: 2 },
];

const handleClear = () => {
    handleNextPage("rentDetails")
};

const handleCancle = () => {
    navigate("/agency/ListAgreement");
  }
  useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="">
                                <div className="property-details-content df-all all-input">
                                    <div>
                                        <Collapse
                                            id={"landLordDetails"}
                                            title={`Maximum number of occupants`}
                                            data={
                                                <div>
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        isMandatory
                                                        label="No more than"
                                                        name={`MaximumTenant`} // Use a dynamic name to differentiate between input fields
                                                        value={values.MaximumTenant}
                                                        onChange={handleChange} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.MaximumTenant}
                                                        touched={touched.MaximumTenant}
                                                    />
                                                </div>
                                                </div>
                                            } />
                                                   <Collapse
                                            id={"landLordDetails"}
                                            title={`Urgent repairs`}
                                            data={
                                                <div>
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="Electrical Repair"
                                                        name={`ElectricalRepair`} // Use a dynamic name to differentiate between input fields
                                                        value={values.ElectricalRepair}
                                                        onChange={handleChange} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.ElectricalRepair}
                                                        touched={touched.ElectricalRepair}
                                                    />
                                                     
                                                    <TextBox
                                                        label="Telephone"
                                                        name="ElectricalRepairContact"
                                                        value={values.ElectricalRepairContact}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.ElectricalRepairContact}
                                                        touched={touched.ElectricalRepairContact}
                                                    />
                                                    <TextBox
                                                        label="Plumbing repairs"
                                                        placeholder=""
                                                        name="PlumbingRepair"
                                                        value={values.PlumbingRepair}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PlumbingRepair}
                                                        touched={touched.PlumbingRepair}
                                                       
                                                    />
                                                    <TextBox
                                                        label="Telephone"
                                                        name="PlumbingRepairContact"
                                                        value={values.PlumbingRepairContact}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.PlumbingRepairContact}
                                                        touched={touched.PlumbingRepairContact}
                                                    />
                                                      <TextBox
                                                        label="Other repairs"
                                                        name="OtherRepair"
                                                        value={values.OtherRepair}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherRepair}
                                                        touched={touched.OtherRepair}
                                                    />
                                                      <TextBox
                                                        label="Telephone"
                                                        name="OtherRepairContact"
                                                        value={values.OtherRepairContact}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.OtherRepairContact}
                                                        touched={touched.OtherRepairContact}
                                                    />
                                                </div>
                                        
                                                </div>
                                            } />
                                                <Collapse
                                            id={"tenant"}
                                            title={`Water usage`}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                <div >
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Will the tenant be required to pay separately for water usage?</div>
                                                        <div><Switch   checked={values.PayWaterBillSeperately} onChange={(e) => onChangeSwitch(e, "PayWaterBillSeperately")}/></div>
                                                    </div>
                                                </div>

                                            </div>
                                            } />
                                             <Collapse
                                            id={"tenant"}
                                            title={`Utilities`}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                <div >
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Is electricity supplied to the premises from an embedded network?</div>
                                                        <div><Switch    checked={values.IsElectricitySupplied} onChange={(e) => onChangeSwitch(e, "IsElectricitySupplied")} /></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Is gas supplied to the premises from an embedded network?</div>
                                                        <div><Switch    checked={values.IsGasSupplied} onChange={(e) => onChangeSwitch(e, "IsGasSupplied")} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                            } />
              <Collapse
                                            id={"tenant"}
                                            title={`Smoke Alarms`}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                    <div className="pddpn-group" >
                                                        <RadioInput isMandatory row={true} label="Indicate whether the smoke alarms installed in the residential premises are hardwired or battery operated" name="SmokeAlarmType" value={values.SmokeAlarmType} checked={values.SmokeAlarmType} onChange={handleChange} onBlur={handleBlur} error={errors.SmokeAlarmType} touched={touched.SmokeAlarmType} options={licenseTypeOptions} />
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">If the smoke alarms are battery operated, are the batteries in the smoke alarms of a kind the tenant can replace?</div>
                                                        <div><Switch    checked={values.SmokeAlarmBatteryReplacable} onChange={(e) => onChangeSwitch(e, "SmokeAlarmBatteryReplacable")} /></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">If yes, specify the type of battery that needs to be used if the battery in the smoke alarm needs to be replaced:</div>
                                                        <div><TextBox colVal={12}  label="" name="BatteryType" value={values.BatteryType} onChange={handleChange} onBlur={handleBlur} error={errors.BatteryType} touched={touched.BatteryType}/></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">If the smoke alarms are hardwired, are the back-up batteries in the smoke alarms of a kind the tenant can replace?</div>
                                                        <div><Switch    checked={values.BackupBatteryReplacable} onChange={(e) => onChangeSwitch(e, "BackupBatteryReplacable")} /></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">If yes, specify the type of back-up battery that needs to be used if the back-up battery in the smoke alarm needs to be replaced:</div>
                                                        <div><TextBox colVal={12}  label="" name="TypeOfBackupBattery" value={values.TypeOfBackupBattery} onChange={handleChange} onBlur={handleBlur} error={errors.TypeOfBackupBattery} touched={touched.TypeOfBackupBattery}/></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">If the Strata Schemes Management Act 2015 applies to the residential premises, is the owners corporation of the strata scheme responsible for the repair and replacement of smoke alarms in the residential premises?</div>
                                                        <div><Switch     checked={values.StrataRsponsibleForReplacement} onChange={(e) => onChangeSwitch(e, "StrataRsponsibleForReplacement")}/></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Are there any strata or community scheme by-laws applicable to the residential premises?</div>
                                                        <div><Switch     checked={values.StrataByLawsApplicable} onChange={(e) => onChangeSwitch(e, "StrataByLawsApplicable")}/></div>
                                                    </div>
                                                </div>
                                            } />

                                            <Collapse
                                            id={"Landlord"}
                                            title={`Landlord`}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                <div >
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Does the landlord give express consent to the electronic service of notices and documents?</div>
                                                        <div><Switch    checked={values.DoesLandlordConsentElectronic} onChange={(e) => onChangeSwitch(e, "DoesLandlordConsentElectronic")} /></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">[Specify email address to be used for the purpose of serving notices and documents.]</div>
                                                    </div>
                                                    <div><TextBox  label="" name="LandlordElctrcConsentEmail" value={values.LandlordElctrcConsentEmail} onChange={handleChange} onBlur={handleBlur} error={errors.LandlordElctrcConsentEmail} touched={touched.LandlordElctrcConsentEmail}/></div>
                                                </div>

                                            </div>
                                            } />
                          
                          <Collapse
                                            id={"Tenant"}
                                            title={`Tenant`}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                <div >
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">Does the tenant give express consent to the electronic service of notices and documents?</div>
                                                        <div><Switch    checked={values.DoesTenantConsentElectronic} onChange={(e) => onChangeSwitch(e, "DoesTenantConsentElectronic")} /></div>
                                                    </div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: '100%' }} className="mr-5 s1">[Specify email address to be used for the purpose of serving notices and documents.]</div>
                                                    </div>
                                                    <div><TextBox  label="" name="TenantElctrcConsentEmail" value={values.TenantElctrcConsentEmail} onChange={handleChange} onBlur={handleBlur} error={errors.TenantElctrcConsentEmail} touched={touched.TenantElctrcConsentEmail}/></div>
                                                    <div className="pddpn-group" style={{ padding: "0px 0px" }}>
                                                    <TextBox
                                                        label="Pet Details"
                                                        name={`PetDetails`} // Use a dynamic name to differentiate between input fields
                                                        value={values.PetDetails}
                                                        onChange={handleChange} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.PetDetails}
                                                        touched={touched.PetDetails}
                                                    />
                                                     
                                                    <TextBox
                                                        label="Additional Terms"
                                                        name="AdditionalComments"
                                                        value={values.AdditionalComments}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.AdditionalComments}
                                                        touched={touched.AdditionalComments}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            } />
                                     </div>

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                       onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyOwners;
