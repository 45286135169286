// Header.jsx

import React, { useState, useEffect } from "react";
import "./HeaderLogo.css";
import { RemoveCookie, GetCookie } from "../Cookie/Session";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { FilePath, defaultProfilePic } from "../apiresources";

const Header = ({ menuItems, handleMenuChange }) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [profile, setProfile] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setName(parsedData.firstName);
          setLName(parsedData.lastName);
          setProfile(parsedData.profilePicture);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, []);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleLogout = () => {
    RemoveCookie("@UserData");
  };

  return (
    <>
      <div className="selectionlogo-forealproperty-one-color2-parent1">
        <img
          className="selectionlogo-forealproperty-one-color21"
          alt=""
          src="..\..\assets\images\logo.png"
        />
        <nav className="selectionframe-parent2">
          <div className="selectiontext-parent">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`selectionadd-profile ${item.isActive ? "active" : ""} ${item.isDisable ? "" : "hoverable"}`}                onClick={() => {
                 item.isDisable === undefined || !item.isDisable ? handleMenuChange(item.label) : null // Update active section in Contacts
                  //navigate(item.url);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          {name && (
            <div className="profileMenu">
              <div className="menu-detail">
                <div className="pdd-pic-view">
                  <img
                    src={
                      FilePath +
                      (profile != null && profile !== ""
                        ? profile
                        : defaultProfilePic)
                    }
                    alt="Profile Pic"
                    width={45}
                    height={45}
                    className="ProfilePic"
                  />
                </div>

                <div className="">
                  {name} {lName}
                </div>
                <div onClick={handleMenu}>
                  {menu ? (
                    <img
                      src="../../../assets/ProfileIcon/UpIcon.png"
                      alt=""
                      width={25}
                      height={25}
                      className="material-icons"
                    />
                  ) : (
                    <img
                      src="../../../assets/ProfileIcon/DownIcon.png"
                      alt=""
                      width={25}
                      height={25}
                      className="material-icons"
                    />
                  )}
                </div>
              </div>
              {menu && (
                <div className="CardMenu">
                  {menuItems.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="ItemMenu" onClick={item.action}>
                          <img
                            src={item.icon}
                            alt={item.name}
                            width={22}
                            height={22}
                            className="MenuIcon"
                          />
                          <div className="MenuName">{item.name}</div>
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={item.action1}
                        >
                          {item.icon1 && (
                            <img
                              src={item.icon1}
                              alt={item.name + "1"} // Adjusted alt attribute
                              width={22}
                              height={22}
                              className="MenuIcon"
                            />
                          )}
                        </span>
                      </div>
                      <div className="SingleLine"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {!name && (
            <div className="selectionnavigation-actions">
              <div className="selectionbutton1">
                <div
                  className="selectiontext5"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </div>
              </div>
              <div className="selectionbutton1">
                <div
                  className="selectiontext5"
                  onClick={() => RemoveCookie("@UserData")}
                >
                  Log In
                </div>
              </div>
            </div>
          )}
        </nav>
        <div className="hamburger">
          <a href="#" onClick={handleMenu}>
            <GiHamburgerMenu color="#1d2939" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
