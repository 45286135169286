import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus} from "react-icons/bi";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import {AgreementTemplate} from "../../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {GetCookie,currentProfile,} from "../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import {ActionStatus} from "../../../../Utilities/enum";
import { addParametersForProfiles ,dynamicUrl} from "../../../../Utilities/profileManagement";
import Sidebar from "../../../../components/CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import "../../openHome/openHome.css"
import "../../Task/task.css"
import { format } from 'date-fns';


const ListAgreementType = () => {
  const [agreementlist, setAgreementList] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUniqueId(parsedData.agencyUniqueID   );
          setAgentUID(parsedData.agentId)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText]);

  const PostApiWithSpinner = (currentPage,totalRows) => {
    setDisplaytable(false)
    const baseUrl = AgreementTemplate;
    let data = {
      SortBy: "AddedOn",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage ,
      AgencyUniqueId : agencyUniqueID
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data, null , false)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if(response.list.length > 0){
          let templatesData = response.list;
          let templatesList = templatesData.map((task) => ({
            Title: task.title,
            Content: task.content,
            Created_Date:task.addedOn,
          }));
          setAgreementList(templatesList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
        }else{
          //ToastRef.current.notify(["warn", "User not added"]);
        }
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddTask = () => {
    dynamicUrl(navigate, "AddTask");
  };


  const handlePageChange = (event , pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleAgreement = (rowData) => {
    if(rowData.Title === "Management Agreement"){
        dynamicUrl(navigate, "ManagementAgreementTabs");
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="action-icons">
        <button className="pdb-save-changes" type="button" onClick={() => handleAgreement(rowData)}>
                            Create
                        </button>
      </div>
    );
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                     Agreement List
                    </div>
                  </div>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">List Of Agreement Templates</div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
               {displayTable?
                <DataTable
                  ref={dataTableRef}
                  value={agreementlist}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                >
                  <Column
                    field="Title"
                    header="Title"
                    className="common-table-style"
                    headerClassName="centered-header"
                    
                  ></Column>
                    <Column
                        field="Content"
                        header="Content"
                    ></Column>
                   <Column
                    field="Created_Date"
                    header="Created Date"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>{format(new Date(rowData.Created_Date), 'dd/MM/yyyy')}</div>
                    )}
                  ></Column>
                   <Column
                    header="Actions"
                    body={actionBodyTemplate}
                    className="action-column right-align"
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                    style={{textAlign:'left'}}
                  ></Column>
                </DataTable> : null }
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAgreementType;
