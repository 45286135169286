import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { agreementPeriodValid } from "../../../../components/CommonSection/Schema/schema";
import ShowValue from "../../../input-components/ShowValue"
import "../agreement.css";

dayjs.extend(customParseFormat);

const initialValues = {
  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
};

const AgreementPeriod = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  }

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: agreementPeriodValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("FeesAndCharges");
  };

  const handleClear = () => {
    handleNextPage("propetyies");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                        <div className="agreementPeriod">
                        <div className=" agreementLabel mb-3 " >{`Agreement Period`}</div>

                        <div className={`lda-${name} `}>
                                  <ShowValue
                                    Label="Commencing On"
                                    setData={values.AgreementDate}
                                  />
                                </div>
                          <div className="s1 ">
                            In consideration of the Agent promising to use their
                            best endeavors, the Principal hereby grants to the
                            Agent the right to act on behalf of the Principal in
                            leasing and management the property in accordance
                            with this agreement,30 Days’ notice given by either party, If notice is given all fees and charges will be
                            payable on the completion date.
                          </div>
                
                          <div className="s1" style={{paddingLeft:'50px'}}>
                            
                          </div>
                          <div className="s1" style={{paddingLeft:'50px'}}>
                           
                          </div>
                        </div>
                        <div className="agreementPeriod mt-3">
                        <div className=" agreementLabel mb-3 " >{`Agent’s Authority for Leasing`}</div>

                          <div className="s1 ">
                            At the end of each tenancy, the Agent is authorized
                            to 
                          </div>
                          <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Lease and Re-lease the Premises at market rent</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.LeasePremisesAtMarketRent ? "Yes" : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Review the rent when appropriate before re-leasing</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.ReviewRentBfrRLease ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                                <div className={`lda-${name} col-lg-12 col-sm-12`} style={{paddingLeft:'0px'}}>
                                   <div className="address-pair ">
                                    <div className="labelssss">Refer to the Principal for instructions concerning re-leasing and advertising/promotion</div>
                                    <div style={{marginTop:'3px'}}>:</div>
                                    <div className="valuessss"> {values.referToPrincipalForReLease ? 'Yes' : 'No'}</div>
                                  </div>
                                </div>
                        </div>
                    
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="reset"
                    onClick={handleClear}
                  >
                    Previous
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementPeriod;
