import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../CommonSection/Header/Header";
import { BiSearch, BiPlus, BiPencil } from "react-icons/bi";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { PropertyList} from "../../../CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {GetCookie} from "../../../CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../Dashboard-agent/AgentList.css";
import "./propertiesagency.css";
import { SelectButton } from "primereact/selectbutton";
import {ActionStatus,ePropertyPurpose} from "../../../../Utilities/enum";
import ShortCardView from "../../../../src/input-components/shortCardView";
import { dynamicUrl} from "../../../../Utilities/profileManagement";
import Sidebar from "../../../CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';

const AgentList = () => {
  const [agentLists, setAgentLists] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const propertyTypeitems = [
    { name: "All", value: 0 },
    { name: "Sale", value: 1 },
    { name: "Rental", value: 2 },
    { name: "Lost", value: -1 },
  ];
  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText, purpose, propertyType]);

  const PostApiWithSpinner = (currentPage,totalRows) => {
    const baseUrl = PropertyList;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      Search: searchText,
      PropType: propertyType,
      Purpose: purpose,
      PageNo: currentPage ,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let propertyData = response.object.propertyListing;
          let propertyList = propertyData.map((property) => ({
            Address: property.getFormatedAddress,
            Postcode: property.postCode,
            CreatedBy: property.createdBy,
            Owner: property.primaryContactName,
            Agent: property.agentName,
            Type: property.sPropertyType,
            Status: property.sPropertyStatus,
            StatusColor: property.propertyStatusColor,
            guid: property.propertyUniqueID,
            uniqueId: property.propertyId,
          }));
          setAgentLists(propertyList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddAgentClick = () => {
    dynamicUrl(navigate, "properties");
  };

  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.StatusColor);
    return (
      <div className="status-container">
        <span className={"common-statusbtn" + " " + statusClassName}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case "GREY":
        return "grey-status";
      case "ORANGE":
        return "orange-status";
      case "GREEN":
        return "green-status";
      case "BLUE":
        return "blue-status";
      default:
        return "";
    }
  };

  const handleEditClick = (rowData) => {
    dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
  };

  const handleRowClick = (event, rowData) => {
    const clickedElement = event.originalEvent.target;
    if (clickedElement.className.includes("action-column")) {
      return;
    } else {
      dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="action-icons">
        <BiPencil
          style={{ fontSize: "24px" }}
          className="edit-icon"
          onClick={() => handleEditClick(rowData)}
        />
      </div>
    );
  };

  const handlePageChange = (event , pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      List of Properties
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <SelectButton
                    className="mr-2"
                    optionLabel="name"
                    options={propertyTypeitems}
                    onChange={(e) => propertyTypeChangeHandler(e.value)}
                    value={propertyType}
                  />
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              {chartData != null && (
                <div className="propertyList-main-container">
                  <div className="overview-card-container-row ">
                    <ShortCardView
                      label="All"
                      value={chartData.all}
                      onClick={() => setPurpose("")}
                    />
                    <ShortCardView
                      label="Appraisal"
                      value={chartData.appraisal}
                      onClick={() => setPurpose(ePropertyPurpose.Appraisal)}
                    />

                    <ShortCardView
                      label="Listed"
                      value={chartData.enlisted}
                      onClick={() => setPurpose(ePropertyPurpose.EnlistForSale)}
                    />
                    <ShortCardView
                      label="Contract"
                      value={chartData.contract}
                      onClick={() => setPurpose(ePropertyPurpose.Contract)}
                    />
                    <ShortCardView
                      label="Prospect"
                      value={chartData.prospect}
                      onClick={() => setPurpose(ePropertyPurpose.Prospect)}
                    />
                  </div>
                </div>
              )}
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">List of Properties</div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                <DataTable
                  ref={dataTableRef}
                  value={agentLists}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  onRowClick={(e) => handleRowClick(e, e.data)}
                >
                  <Column
                    field="Address"
                    header="Address"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Postcode"
                    header="Postcode"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="CreatedBy"
                    header="Created By"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Owner"
                    header="Owner"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Agent"
                    header="Agent"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Type"
                    header="Type"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Status"
                    header="Status"
                    body={statusBodyTemplate}
                    className="common-table-style "
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    header="Actions"
                    body={actionBodyTemplate}
                    className="common-table-style action-column right-align"
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                  ></Column>
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentList;
