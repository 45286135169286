import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus, BiPencil, BiTrash } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { OpenListHome, deleteOpenhome } from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GetCookie, currentProfile, } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { SelectButton } from "primereact/selectbutton";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import dayjs from 'dayjs';
import "./openHome.css";
import moment from "moment/moment";



const ListOpenHome = () => {
  const [openHomelist, setOpenHomelist] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agentUniqueID, setAgentUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListOpenHome");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);

  const propertyTypeitems = [
    { name: "All", value: 0 },
    { name: "Current", value: 1 },
    { name: "Past", value: 2 },
  ];
  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgentUniqueId(parsedData.agentId);
          setAgentUID(parsedData.agentId)
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner(1, 10);
  }, [userId, searchText, purpose, propertyType]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false)
    const baseUrl = OpenListHome;
    let data = {
      SortBy: "CreatedOn",
      SortOrder: "Desc",
      RecordsPerPage: totalRows,
      AgencyId: agencyID,
      Search: searchText,
      PageNo: currentPage,
      IsCurrent: propertyType === 1 ? true : propertyType === 2 ? false : null
    };
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        setDisplaytable(true)
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            let propertyData = response.list;
            let propertyList = propertyData.map((property) => ({
              Address: property.property,
              eventDate: property.eventDate,
              startTime: property.startTime,
              endTime: property.endTime,
              price: property.propertyPrice + ' ' + '$',
              guid: property.propertyUniqueID,
              uniqueId: property.propertyId,
              OpenHomeUniqueId: property.openHomeuniqueId
            }));
            setOpenHomelist(propertyList);
            setTotalPages(response.totalCount)
            setChartData(response.object);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          alert(`Message: ${response.message}`);
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const deletOpenHome = async (data) => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${deleteOpenhome}?openHomeUniqueId=${data.OpenHomeUniqueId}`,
      "delete"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          PostApiWithSpinner()
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddAgentClick = () => {
    dynamicUrl(navigate, "AddOpenHome");
  };

  const handleEditClick = (rowData) => {
    dynamicUrl(navigate, "AddOpenHome" + "?OpenHomeUniqueId=" + rowData.OpenHomeUniqueId);
  };


  const handleRowClick = (event, rowData) => {
    const clickedElement = event.originalEvent.target;
    if (clickedElement && typeof clickedElement.className === 'string' && clickedElement.className.includes) {
      if (clickedElement.className.includes("action-column")) {
        return;
      } else {
        dynamicUrl(navigate, "OpenHomeDetails" + "?OpenHomeUniqueId=" + rowData.OpenHomeUniqueId);
      }
    }
  };


  const [editable, setEditable] = useState([]);

  useEffect(() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const editableArray = openHomelist.map((property) => {
      const formattedDate = moment(property.eventDate).format('YYYY-MM-DD');
      return moment(formattedDate).isSameOrAfter(currentDate, 'day');
    });
    setEditable(editableArray);
  }, [openHomelist]);

  const actionBodyTemplate = (rowData) => {
    const eventDate = new Date(rowData.Date);
    const today = new Date();

    const disableEdit = eventDate <= today;
    const index = openHomelist.findIndex(property => property.OpenHomeUniqueId === rowData.OpenHomeUniqueId);

    return (
      <div>

        <div className="action-icons">
          {index !== -1 && editable[index] && (
            <BiPencil
              style={{ fontSize: "24px", cursor: 'pointer' }}
              className="edit-icon"
              onClick={() => handleEditClick(rowData)}
            />
          )}
          <BiTrash
            style={{ fontSize: "24px", cursor: 'pointer' }}
            className="edit-icon"
            onClick={() => deletOpenHome(rowData)}
          />
        </div>

      </div>
    );
  };


  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      Open Home List
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <SelectButton
                    className="mr-2"
                    optionLabel="name"
                    options={propertyTypeitems}
                    onChange={(e) => propertyTypeChangeHandler(e.value)}
                    value={propertyType}
                  />
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">Open Home List</div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                {displayTable ?
                  <DataTable
                    ref={dataTableRef}
                    value={openHomelist}
                    size={"normal"}
                    tableStyle={{ minWidth: "20rem" }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    rows={10}
                    dataKey=""
                    rowClassName="custom-row"
                    onRowClick={(e) => handleRowClick(e, e.data)}
                  >
                    <Column
                      field="Address"
                      header="Address"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="combinedDateTime"
                      header="Date & Time"
                      body={(rowData) => {
                        const formattedDate = dayjs(rowData.eventDate).format('DD-MM-YYYY');
                        const formattedStartTime = dayjs(rowData.startTime).format('hh:mm A');
                        const formattedEndTime = dayjs(rowData.endTime).format('hh:mm A');
                        const combinedDateTime = `${formattedDate}<br />${formattedStartTime} - ${formattedEndTime}`;
                        return <div dangerouslySetInnerHTML={{ __html: combinedDateTime }} />;
                      }}
                    ></Column>
                    <Column
                      field="price"
                      header="Price"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      header="Actions"
                      body={actionBodyTemplate}
                      className="action-column right-align"
                      headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                      style={{ textAlign: 'left' }}
                    ></Column>
                  </DataTable> : null}
                {displayTable.length === 0 ? <div className="emptyList">
                  No record found
                </div> : null}
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOpenHome;
