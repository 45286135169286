import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  SearchProperty,
  AddOpenHome,
  updateOpenHomeDetails,
  searchPropertyList,
  OpenHomeSearchProperty
} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../Utilities/profileManagement";
import SideBar from "../../../components/CommonSection/SideBar/sideBar";
import Header from "../../../components/CommonSection/Header/Header";
import { BiDotsVerticalRounded } from "react-icons/bi";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { Dropdown, Space, Checkbox, Divider } from "antd";
import CustomModal from "../../../src/input-components/Modal";
import { IoSettings } from "react-icons/io5";
import "./openHome.css";
import { AddOpenHomeSchema } from "../../../components/CommonSection/Schema/schema";

dayjs.extend(customParseFormat);

const initialValues = {
  property: null,
  propertyName: null,
  eventDate: "",
  startTime: null,
  endTime: null,
  OpenHomeuniqueId: null,
  listingId: null
};

const AddOpenHomePage = ({}) => {
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const [agentUID, setAgentUID] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("AddOpenHome");
  const [topicValue, setTopicValue] = useState("AddOpenHome");
  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const [searchValue, setSearchValues] = useState("");
  const ToastRef = useRef();
  const [selectedValues, setSelectedValues] = useState([]);
  const [showSelect, setShowSelect] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);

  const [startHour, setStartHour] = useState(9);
  const [startMinute, setStartMinute] = useState(0);
  const [startAmPm, setStartAmPm] = useState("AM");
  const [endHour, setEndHour] = useState(9);
  const [endMinute, setEndMinute] = useState(0);
  const [endAmPm, setEndAmPm] = useState("AM");
  const [openHomeUid, setOpenHomeUid] = useState(null);

  const handleStartTimeChange = (event) => {
    const { name, value } = event.target;
    if (name === "hour") {
      setStartHour(parseInt(value));
      setEndHour(parseInt(value));
      setFieldValue("startTime", parseInt(value));
      if (value >= 12 || value <= 8) {
        setStartAmPm("PM");
        setEndAmPm("PM");
      } else {
        setStartAmPm("AM");
        setEndAmPm("AM");
      }
    } else {
      setStartAmPm(value);
    }
    if (!isStartTimeValid()) {
      setStartHour(9);
      setStartMinute(0);
      setStartAmPm("AM");
    }
  };

  const handleMinuteChange = (event) => {
    const { value } = event.target;
    setStartMinute(parseInt(value));
  };

  const isStartTimeValid = () => {
    const startTime = convertTo24Hour(startHour, startMinute, startAmPm);
    return startTime >= 900 && startTime <= 2100;
  };

  // Convert 12-hour time to 24-hour time
  const convertTo24Hour = (hour, minute, amPm) => {
    let hour24 = hour;
    if (amPm === "PM" && hour !== 12) {
      hour24 += 12;
    } else if (amPm === "AM" && hour === 12) {
      hour24 = 0;
    }
    return hour24 * 100 + minute;
  };

  const getStartTime = () => {
    let hour = startHour;
    if (startAmPm === "PM" && hour !== 12) {
      hour += 12;
    } else if (startAmPm === "AM" && hour === 12) {
      hour = 0;
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour; // Fix interpolation syntax
    const formattedMinute = startMinute < 10 ? `0${startMinute}` : startMinute; // Fix interpolation syntax
    const amPm = startHour < 12 ? "AM" : "PM";
    const hour12 = startHour % 12 || 12; // Convert to 12-hour format
    return `${hour12}:${formattedMinute} ${startAmPm}`; // Fix interpolation syntax
  };

  const getEndTime = () => {
    let hour = endHour;
    if (endAmPm === "PM" && hour !== 12) {
      hour += 12;
    } else if (endAmPm === "AM" && hour === 12) {
      hour = 0;
    }
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = endMinute < 10 ? `0${endMinute}` : endMinute;
    const amPm = endHour < 12 ? "AM" : "PM";
    const hour12 = endHour % 12 || 12;
    return `${hour12}:${formattedMinute} ${endAmPm}`;
  };

  const handleEndHourChange = (event) => {
    const { value } = event.target;
    setEndHour(value);
    setFieldValue("endTime", parseInt(value));
    // Determine AM/PM based on selected hour
    if (value >= 12 || value <= 8) {
      setEndAmPm("PM");
    } else {
      setEndAmPm("AM");
    }
    // Reset end time if it's not valid
    if (!isEndTimeValid()) {
      setEndHour(9);
      setEndMinute(0);
      setEndAmPm("AM");
    }
  };

  // Function to handle end time minute change
  const handleEndMinuteChange = (event) => {
    const { value } = event.target;
    setEndMinute(parseInt(value));
  };

  // Function to validate the end time
  const isEndTimeValid = () => {
    const endTime = convertTo24Hour(endHour, endMinute, endAmPm);
    return endTime >= 900 && endTime <= 2100;
  };

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        var url = new URL(window.location.href);
        var OpenHomeUniqueId = url.searchParams.get("OpenHomeUniqueId");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUID(parsedData.agencyUniqueID);
          setAgentUID(parsedData.agentUniqueId);
          if (OpenHomeUniqueId) {
            openHomeDetails(parsedData.agencyID);
          }
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    agencyUIDRef.current = agencyUID;
  }, [agencyUID]);

  const agencyUIDRef = useRef(agencyUID);

  const openHomeDetails = (agencyID) => {
    var url = new URL(window.location.href);
    var OpenHomeUniqueId = url.searchParams.get("OpenHomeUniqueId");
    const apiUrl = `${updateOpenHomeDetails}?openHomeUniqueId=${OpenHomeUniqueId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          const responseData = response.object;
          filteredDetailsList(responseData.propertyId);
          setFieldValue("propertyName", responseData.property);
          setFieldValue("property", responseData.propertyId);
          setFieldValue("eventDate", formatDate(responseData.eventDate));
          setFieldValue(
            "startTime",
            dayjs(responseData.startTime).format("hh:mm:ss A")
          );
          setFieldValue(
            "endTime",
            dayjs(responseData.endTime).format("hh:mm:ss A")
          );
          const startTime = responseData.startTime;
          const startTimeObj = dayjs(startTime);
          setStartHour(startTimeObj.hour());
          setStartMinute(startTimeObj.minute());
          setStartAmPm(startTimeObj.format("A"));

          // Parse end time
          const endTime = responseData.endTime;
          const endTimeObj = dayjs(endTime);
          setEndHour(endTimeObj.hour());
          setEndMinute(endTimeObj.minute());
          setEndAmPm(endTimeObj.format("A"));
          setOpenHomeUid(responseData.openHomeuniqueId);
          setFieldValue("listingId", responseData.listingId)
        } else {
          // handleparentsuccess(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error]);
      });
  };
  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  const filteredDetailsList = (propertyId) => {
    setShowSelect(false);
    const baseUrl = searchPropertyList;
    const data = {
      ids: propertyId ? propertyId : "",
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === 0) {
          setShowSelect(true);
          let landingPageListData = response.list;
          const transformedData = landingPageListData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOption(transformedData);
          const outputArray = transformedData.map((item) => item.label);
          setSelectedValues(outputArray);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AddOpenHomeSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      PropertyId: parseInt(values.property),
      StartTime: getStartTime(),
      EndTime: getEndTime(),
      OpenHomeuniqueId: openHomeUid,
      EventDate: values.eventDate,
      CreatedBy: agentID,
      AgencyId: agencyID,
      AgentId: agentID,
      ListingId: values.listingId
    };
    if (data != undefined) {
      formData.Publish = true;
      formData.isrea = reaUplode;
      formData.isdomain = domainUplode;
    }
    const url = AddOpenHome;
    formData = addParametersForProfiles(formData);
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigate(`/agency/ListOpenHome`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const agencyUID = agencyUIDRef.current;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOption([]);
      setFetching(true);
      const baseUrl = OpenHomeSearchProperty;
      let data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
        AgencyUniqueID: agencyUID,
      };
      data = addParametersForProfiles(data);
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name + " " + (item.propertyFor === "1" ? "(Sale)" : "(Rent)"),
              listingId : item.listingId
            }));            
            if (fetchId === fetchRef.current) {
              setOption(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    const label = value.label;
    const values = value.value;
    setSearchValues(values);
    setFieldValue("property", values);
    setFetching("propertyName", label);
    setFieldValue("listingId", value.listingId)
    debounceFetcher(value);
  };
  const handleClear = () => {
    setShowSelect(false);
    resetForm();
    debounceFetcher("");
    setSelectedValues([]); // Clear the selected values
    setFieldValue("property", null);
    setFieldValue("propertyName", null);
    setTimeout(() => {
      setShowSelect(true);
    }, 500);
    dynamicUrl(navigate, "ListOpenHome");
    ToastRef.current.notify(["warn", "Changes are cancelled"]);
  };

  const handleItemClick = (e) => {
    e.preventDefault();
  };

  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode); // Toggle the state
    } else if (type === "domain") {
      setDomainUplode(!domainUplode); // Toggle the state
    }
  };

  const handleUpload = () => {
    setOpenUpload(true);
  };

  const items = [
    {
      key: "1",
      label: <a>Convert To rental</a>,
    },
    {
      key: "2",
      label: <a onClick={handleUpload}>Upload</a>,
    },
  ];
  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handleReaSubmit = () => {
    const data = {
      propertyID: getDetails.propertyUniqueID,
      isrea: reaUplode,
      isdomain: domainUplode,
      isPrincipleLogged: true,
    };
  };

  const DisabledTime = (now, type = "start" | "end") => {
    return {
      disabledHours: () => {
        // Disable hours before 9 AM and after 9 PM
        return [...Array(24).keys()].filter((hour) => hour < 9 || hour >= 22);
      },
    };
  };

  const onChange = (time, timeStrings) => {
    setFieldValue("startTime", timeStrings[0]);
    setFieldValue("endTime", timeStrings[1]);
  };

  return (
    <>
      <div className="dashboard-agency">
        <Header />
        <ToastAlert ref={ToastRef} />

        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <SideBar
              visible={sidebarVisible}
              handleTopicValue={handleTopicValue}
              topicValue={activeTopic}
            />
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div style={{ width: "100%" }}>
                    <CustomModal
                      title="Upload property to"
                      width={500}
                      closable={closeUpload}
                      visible={openUpload}
                      onCancel={closeUpload}
                      modalData={
                        <div
                          className="row"
                          style={{ marginTop: "30px", marginLeft: "10px" }}
                        >
                          <div className="col-lg-5 col-sm-12">
                            <Checkbox
                              checked={reaUplode}
                              onChange={() => onChangeUpload("rea")}
                            >
                              REA
                            </Checkbox>
                          </div>
                          <div className="col-lg-5 col-sm-12">
                            <Checkbox
                              checked={domainUplode}
                              onChange={() => onChangeUpload("domain")}
                            >
                              Domain
                            </Checkbox>
                          </div>
                        </div>
                      }
                      footer={[
                        <div
                          key="footer"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            style={{ marginRight: "20px" }}
                            className="pdb-save-changes mt-2"
                            onClick={() => handleFinalSubmit("saveAndPublish")}
                          >
                            Submit
                          </button>
                        </div>,
                      ]}
                    />
                  </div>
                  <div
                    className="contacts-group"
                    style={{ padding: "0px 30px" }}
                  >
                    <div className={`lda-${name} col-lg-6 col-sm-12`}>
                      <label className="s1">
                        Property <span style={{ color: "red" }}>*</span>
                      </label>
                      {showSelect ? (
                        <div>
                          <Select
                            style={{ width: "100%", height: "40px" }}
                            placeholder="Search for property"
                            labelInValue
                            filterOption={false}
                            onSearch={debounceFetcher}
                            notFoundContent={
                              fetching ? <Spin size="small" /> : null
                            }
                            options={option}
                            onChange={(label, value,listingId) =>
                              handleSearchChange(label, value, listingId)
                            }
                            defaultValue={selectedValues}
                            mode="single"
                            showSearch
                          />
                          {errors.property && touched.property ? (
                            <p className="form-error">{errors.property}</p>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                    <TextBox
                      isMandatory
                      label="Event date"
                      name="eventDate"
                      type={"date"}
                      value={values.eventDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.eventDate}
                      touched={touched.eventDate}
                      minDate={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <div
                    className="contacts-group"
                    style={{ padding: "0px 30px" }}
                  >
                    <div className={`pd- col-lg-5 col-sm-12`}>
                      <div className="form-group">
                        <label className="s1">
                          Start Time <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group mb-3 ">
                          <select
                            className="customSelect"
                            name="hour"
                            value={startHour}
                            onChange={handleStartTimeChange}
                          >
                            {[...Array(24)].map((_, index) => {
                              const hour = index + 1;
                              let label;
                              if (hour >= 9 && hour <= 11) {
                                label = hour + " AM";
                              } else if (hour >= 12 && hour <= 21) {
                                if(hour === 12){
                                  label = hour + " PM";
                                }else{
                                label = hour - 12 + " PM";
                                }
                              }
                              return (
                                label && (
                                  <option key={hour} value={hour}>
                                    {label}
                                  </option>
                                )
                              );
                            })}
                          </select>
                          <select
                            className="customSelect"
                            name="minute"
                            value={startMinute}
                            onChange={handleMinuteChange}
                          >
                            {[...Array(12)].map((_, index) => (
                              <option key={index * 5} value={index * 5}>
                                {index * 5 < 10
                                  ? "0" + index * 5
                                  : index * 5}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="contacts-group"
                    style={{ padding: "0px 30px" }}
                  >
                      <div className={`pd- col-lg-5 col-sm-12`}>
                        <div className="form-group">
                          <label className="s1 ">
                            End Time <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group mb-3">
                            <select
                              className="customSelect"
                              name="endHour"
                              value={endHour}
                              onChange={handleEndHourChange}
                            >
                              {[...Array(24)].map((_, index) => {
                                const hour = index + 1;
                                const label =
                                hour === 12
                                  ? `12 PM` // 12 PM
                                  : hour >= 9 && hour <= 11
                                  ? `${hour} AM` // 9 AM to 11 AM
                                  : hour >= 12 && hour <= 21
                                  ? `${hour - 12} PM` // 1 PM to 9 PM
                                  : null;
                                // Ensure that end time options start after the selected start time
                                if (hour > startHour || (hour === startHour && index >= startMinute / 15)) {
                                  return label && <option key={hour} value={hour}>{label}</option>;
                                } else {
                                  return null;
                                }
                              })}
                            </select>
                            <select
                              className="customSelect"
                              name="endMinute"
                              value={endMinute}
                              onChange={handleEndMinuteChange}
                            >
                              {[...Array(12)].map((_, index) => (
                                <option key={index * 5} value={index * 5}>
                                  {index * 5 < 10
                                    ? "0" + index * 5
                                    : index * 5}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                  </div>
                  {/* <div
                    className="contacts-group"
                    style={{ padding: "0px 30px" }}
                  >
                    <div className={`lda-${name} col-lg-5 col-sm-12`}>
                      <label className="s1">
                        Start Time & End Time{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <TimePicker.RangePicker
                          order={true}
                          value={[
                            values.startTime
                              ? dayjs(values.startTime, "hh:mm A")
                              : null,
                            values.endTime
                              ? dayjs(values.endTime, "hh:mm A")
                              : null,
                          ]}
                          style={{ width: "100%", height: "40px" }}
                          className="customTime"
                          onChange={(time, timeString) =>
                            onChange("StartTime", timeString)
                          }
                          defaultOpenValue={dayjs("00:00", "HH:mm")}
                          format="hh:mm A"
                          showSecond={false}
                          minuteStep={15}
                          disabledTime={DisabledTime}
                        />
                        {errors.startTime && touched.startTime ? (
                          <p className="form-error">{errors.startTime}</p>
                        ) : null}
                      </div>
                    </div>
                  </div> */}
                  {/* <div
                    className="contacts-group"
                    style={{ padding: "0px 30px" }}
                  >
                    <div className={`lda-${name} col-lg-5 col-sm-12`}>
                      <label className="s1">
                        End Time <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <TimePicker
                          hideDisabledOptions
                          use12Hours
                          value={
                            values.endTime
                              ? dayjs(values.endTime, "hh:mm A")
                              : null
                          }
                          style={{ width: "100%", height: "40px" }}
                          className="customTime"
                          onChange={(time, timeString) =>
                            onChange("EndTime", timeString)
 
                          }
                          defaultOpenValue={dayjs("00:00", "HH:mm")}
                          format="hh:mm A"
                          showSecond={false}
                          minuteStep={15}
                          disabledTime={DisabledTime}
                        />
                        {errors.endTime && touched.endTime ? (
                          <p className="form-error">{errors.endTime}</p>
                        ) : null}
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleClear}
                  >
                    Cancel
                  </button>
                  {/* <button className="pdb-save-changes" type="submit">
                    Save
                  </button> */}
                  <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={() => handleUpload()}
                  >
                    Save & Publish
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOpenHomePage;
