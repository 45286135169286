import { useCallback, useState, useEffect, useRef } from "react";
import "./verifyid.css";
// import '../SignIn/temp.css'
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import Select from 'react-select';
import { apiUrlver, apiGetPendingProfile } from "../../CommonSection/apiresources";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";

const Verify = () => {
  const [valueo, setValueo] = useState(null);
  const [valuet, setValuet] = useState(null);
  const navigate = useNavigate();
  const [userId, setUserID] = useState('');
  const [id, setId] = useState('');

  const [fnameo, setfnameo] = useState("");
  const [mnameo, setmnameo] = useState("")
  const [lnameo, setlnameo] = useState("")
  const [numbero, setnumbero] = useState("")
  const [expiryDateo, setExpiryDateo] = useState("")

  const [fnamet, setfanmet] = useState("");
  const [lnamet, setlnamet] = useState("");
  const [numbert, setnumbert] = useState("");
  const [seq, setSeq] = useState("");
  const [expiryDatet, setExpiryDatet] = useState("");

  const searchParams = new URLSearchParams(window.location.search);
  let value;
  if(searchParams.has('value'))
    {value=searchParams.get('value');}
  const [data, setData] = useState([]);
  let progressData = [{
    label: "Add Property",
    url: "/addproperty",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Verification",
    url: "/verify",
    isSelected: true,
    isCompleted: false
  },
  {
    label: "Ownership",
    url: "/ownership",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Add More Owners",
    url: "/addowner",
    isSelected: false,
    isCompleted: false
  },]


  const options1 = [
    { value: 1, label: 'License (Driving)' },
    { value: 2, label: 'Passport' }
  ]
  const options2 = [
    { value: 1, label: 'Medical (Health)' },
    { value: 2, label: 'BankCard' }
  ]

  const handlePressSelect = (action) => {
    // Handle the selected place here
    form.current.reset();

  };

  const handleVerify = (value) => {
    console.log(value);
  }


  const onAddPropertyContainerClick = () => {
    //console.log('change');
  };



  const onFrameContainerClick = useCallback((isVisible) => {

  }, []);

  // ***********************************************************************************************************
  useEffect(() => {

    const retrieveData = async () => {

      try {

        const userData = GetCookie('@UserData');

        if (userData) {

          const parsedData = JSON.parse(userData);

          setUserID(parsedData.userId);

        }

      } catch (error) {

        console.error('Error retrieving data from AsyncStorage:', error);

      }

    };



    retrieveData();

  }, []);


  const getApiSecondaryOwner = () => {

    const baseUrl1 = baseUrl;

    // Request body with login information

    const UserId = userId;

    const apiUrl = `${baseUrl1}?UserId=${UserId}`;

    //console.log('API URL:', apiUrl);

    axios.get(apiUrl)

      .then(response => {

        // Handle the response data

        if (response.data.status == 1 && response.data.object.isPrimaryOwner == false) {

          navigate('/Agent');

        } else {

          alert(`Message: ${response.data.message}`);

          console.log(response.data)

        }

      })

      .catch(error => {

        console.error('Error:', error);

      });

  }

  useEffect(() => {
    if (userId !== '') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getApi();
      }, 2000)
    }

  }, [userId]);

  const getApi = () => {
    const baseUrl1 = apiGetPendingProfile;

    // Request body with login information

    const UserId1 = JSON.parse(GetCookie('@UserData')).userId;
    console.log(UserId1);
    const apiUrl = `${baseUrl1}?UserId=${UserId1}`;
    console.log('API URL:', apiUrl);

    axios.get(apiUrl)
      .then(response => {
        // Handle the response data
        if (response.data.status == 1) {
          setId(response.data.object.id);
          console.log(`Id get Api : ${id}`);
        } else {
         // alert(`Message: ${response.data.message}`);
          console.log(response.data)
        }
          
        if (response.data.object == null) {
          progressData[0].isSelected = true;
          setData(progressData);
          navigate('/addproperty');
          console.log(response.data)
        } else {
          let x = response.data.object.currentStatus;
          for (let i = 0; i < x; i++) {
            progressData[i].isCompleted = true;
          }
          setData(progressData);
          if(!value){
            if (response.data.object.currentStatus == 1) {

              console.log(response.data.object.status)
              navigate('/verify');
  
            } else if (response.data.object.currentStatus == 2) {
              console.log(response.data.object.status)
  
              navigate('/ownership');
  
            } else if (response.data.object.currentStatus >= 3) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 4) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 5) {
  
              console.log(response.data.object.status)
              navigate('/Completion');
  
            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  const handlePrimaryDoc = () => {

    const apiUrl = apiUrlver;

    // Request body with login information

    const VerifyData = {

      UserId: userId,
      Id: id,
      PrimaryDocumentType: valueo.value,
      PrimaryDocFirstName: fnameo,
      PrimaryDocMidName: mnameo,
      PrimaryDocLastName: lnameo,
      PrimaryDocNumber: numbero,
      PrimaryDocExpiry: expiryDateo,
      SecondaryDocumentType: valuet.value,
      SecondaryDocFirstName: fnamet,
      SecondaryDocMidName: lnamet,
      SecondaryDocLastName: numbert,
      SecondaryDocNumber: seq,
      SecondaryDocExpiry: expiryDatet,

    };

    axios.post(apiUrl, VerifyData)

      .then((response) => {

        // Handle the response from the backend

        if (response.data.status == 1) {

          getApiSecondaryOwner();
          navigate('/ownership');
          console.log("Success");
          alert('User added data Successfully');

        } else {

          alert(`Message: ${response.data.message}`);
          console.log(response.data)

        }

      })

      .catch((error) => {

        console.error('Error:', error);

      });



  }
  // ***********************************************************************************************************

  return (
    <div className="add-property" onClick={() => onAddPropertyContainerClick()}>
         <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '90px',

        }}>
       
        </div>
        <div className="progress-icor">
          {data && <ProgressbarLandlord message={data} />}
        </div>

        <div className="verify-id">{`Verify Id`}</div>
        <form className="verfify-input" >

          <label htmlFor="s1">Choose one option</label>
          <Select options={options1} placeholder="Select any Document" defaultValue={valueo} id="s1" onChange={setValueo} />
          {valueo && <div className="details">
            <input type="text" className="input-v" placeholder="First Name" name='fnameo' id='fnameo' value={fnameo} onChange={(text) => setfnameo(text.target.value)} />
            <input type="text" className="input-v" placeholder="Middle Name" value={mnameo} onChange={(text) => setmnameo(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Last Name" value={lnameo} onChange={(text) => setlnameo(text.target.value)} required />
            <input type="number" className="input-v" placeholder="Number" value={numbero} onChange={(text) => setnumbero(text.target.value)} required />
            <input type="date" className="input-v" placeholder="Expiry" value={expiryDateo} onChange={(text) => setExpiryDateo(text.target.value)} required />
          </div>}
          <label htmlFor="s2">Choose one option</label>
          <Select options={options2} placeholder="Select any Document" defaultValue={valuet} id="s2" onChange={setValuet} />
          {valuet && <div className="details">
            <input type="text" className="input-v" placeholder="First Name" value={fnamet} onChange={(text) => setfanmet(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Last Name" value={lnamet} onChange={(text) => setlnamet(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Number" value={numbert} onChange={(text) => setnumbert(text.target.value)} required />
            <input type="text" className="input-v" placeholder="Seq Number" value={seq} onChange={(text) => setSeq(text.target.value)} required />
            <input type="date" className="input-v" placeholder="Expiry" value={expiryDatet} onChange={(text) => setExpiryDatet(text.target.value)} required />
          </div>}
        </form>
        <button className="button-v" type="submit" onClick={handlePrimaryDoc}>
          <div className="text-parent">
            <div className="text7">Verify</div>
          </div>

        </button>
      </div>
      <Footer />

    </div>
  );
};

export default Verify;
