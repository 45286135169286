import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementId: '',
    LandlordAgentName: '',
    LandlordBusinessAddress: '',
    LandlordAgentPostcode: '',
    LandlordAgentSuburb: '',
    LandlordAgentState: '',
    LandlordAgentContact: '',
    TenantAgentName: '',
    TenantBusinessAddress: '',
    TenantAgentPostcode: '',
    TenantAgentSuburb: '',
    TenantAgentState: '',
    TenantAgentContact: '',
    PropertySuburb: '',
    PropertyPostCode: '',
    PropertyState: '',
    PropertyAddress: '',
    OtherSpace: '',
    TermsAgreement: '',
    TermsAgreementNote: '',
    AgreementStart: '',
    AgreementEnd: '',
    agentType: ''

};

const LandLordDetails = ({ getDetails, handleNextPage }) => {
    const navigate = useNavigate();
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);

    useEffect(() => {
        if (getDetails) {
            setFieldValue("LandlordAgentName", getDetails.landlordAgentName);
            setFieldValue("LandlordBusinessAddress", getDetails.landlordBusinessAddress);
            setFieldValue("LandlordAgentPostcode", getDetails.landlordAgentPostcode);
            setFieldValue("LandlordAgentState", getDetails.landlordAgentState);
            setFieldValue("LandlordAgentSuburb", getDetails.landlordAgentSuburb);
            setFieldValue("LandlordAgentPostcode", getDetails.landlordAgentPostcode);
            setFieldValue("LandlordAgentContact", getDetails.landlordAgentContact);
            setFieldValue("TenantAgentName", getDetails.tenantAgentName);
            setFieldValue("TenantAgentPostcode", getDetails.tenantAgentPostcode);
            setFieldValue("TenantAgentState", getDetails.tenantAgentState)
            setFieldValue("TenantAgentSuburb", getDetails.tenantAgentSuburb)
            setFieldValue("TenantBusinessAddress", getDetails.tenantBusinessAddress)
            setFieldValue("TenantAgentContact", getDetails.tenantAgentContact)
            setFieldValue("PropertyAddress", getDetails.propertyAddress)
            setFieldValue("PropertySuburb", getDetails.propertyState)
            setFieldValue("PropertyPostCode", getDetails.propertyPostCode)
            setFieldValue("PropertyState", getDetails.propertyState)
            setFieldValue("OtherSpace", getDetails.otherSpace)
            setFieldValue("TermsAgreement", getDetails.termsAgreement.toString())
            setFieldValue("TermsAgreementNote", getDetails.termsAgreementNote)
            setFieldValue("AgreementStart", formatDate(getDetails.agreementStart))
            setFieldValue("AgreementEnd", formatDate(getDetails.agreementEnd))
        }
    }, [getDetails]);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${day}-${month}-${year}`;
    }


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);


    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: AddOpenHomeSchema,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        handleNextPage("rentDetails");
    };

    const handleClear = () => {
        handleNextPage("");
    };

    const handleCancle = () => {
        navigate("/agency/ListAgreement");
    };

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                                    <div style={{ width: '100%' }}>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Landlord's agent details [if applicable]`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Agent Name</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordAgentName}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Business Address</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordBusinessAddress}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Subrub</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordAgentSuburb}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">State </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordAgentState}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">PostCode </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordAgentPostcode}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Contact Details</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.LandlordAgentContact}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Tenant's agent details [if applicable]`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Agent Name</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantAgentName}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Business Address</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantBusinessAddress}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Subrub</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantAgentSuburb}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">State </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantAgentState}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">PostCode </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantAgentPostcode}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Contact Details</div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TenantAgentContact}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Property (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified.`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Address </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PropertyAddress}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Subrub </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PropertySuburb}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">State </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PropertyState}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">PostCode  </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.PropertyPostCode}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `}>
                                                <div className="address-pair">
                                                    <div className="labelssss">The residential premises include </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.OtherSpace}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pddpn-group2 pddpn-group3">
                                            <div className="agreementLabel mb-3">{`Term of agreement`}</div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">The residential premises include </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.TermsAgreement}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Starting On </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.AgreementStart}</div>
                                                </div>
                                            </div>
                                            <div className={`lda-${name} `} >
                                                <div className="address-pair">
                                                    <div className="labelssss">Ending On </div>
                                                    <div style={{marginTop:'3px'}}>:</div>
                                                    <div className="valuessss"> {values.AgreementEnd}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandLordDetails;
