import React from "react";
import "./signinclass.css";
import { useFormik } from "formik";
import { signInSchema } from "../Schema/schema";
import { useNavigate } from "react-router-dom";
import { GetCookie, SetCookie, RemoveCookie, currentProfile } from "../Cookie/Session";
import Footer from "../Footer/Footer";
import { userLogin } from "../apiresources";
import ToastAlert from "../ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, UserRoleId } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
const ToastRef = React.createRef();
const initialValues = {
  email: "",
  password: "",
  rem: false,
};
const LogIn = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signInSchema,
      onSubmit: (values, action) => {
        const loginData = {
          UserName: values.email,
          Password: values.password,
          //   userRoleID: 10,
        };

        makeRequestWithSpinner(
          showSpinner,
          hideSpinner,
          userLogin,
          "post",
          loginData,
          "",
          false
        ).then((returnData) => {
          if (returnData.status == ActionStatus.Successfull) {
            // Save user data to AsyncStorage
            ToastRef.current.notify(["success", returnData.message]);
            const userData = returnData.object;
            console.log(userData,'userDatauserData')
            localStorage.setItem("@UserData", JSON.stringify(userData));
            // localStorage.setItem('sessionTimestamp', Date.now());
            SetCookie("@UserData", JSON.stringify(userData), values.rem);
            console.log(GetCookie("@UserData"));
            navigate("/selection");
            if (returnData.object.hasMultipleProfiles == true) {

              
              const userProfiles = returnData.object.userProfiles;
             

              const filteredProfiles = userProfiles.filter(
                (profile) => profile.userRoleId !== UserRoleId.Contact
              );

              const landlordProfiles = filteredProfiles.filter(
                (profile) => profile.userRoleId === UserRoleId.Landlord
              );
              const agentProfiles = filteredProfiles.filter(
                (profile) => profile.userRoleId === UserRoleId.Agent
              );

              const agencyProfiles = filteredProfiles.filter(
                (profile) => profile.userRoleId === UserRoleId.Agency
              );
              const TenantProfiles = filteredProfiles.filter(
                (profile) => profile.userRoleId === UserRoleId.Tenant
              );

              const TradeProfiles = filteredProfiles.filter(
                (profile) => profile.userRoleId === UserRoleId.Trade
              );

              // const MultipleProfile = response.data.object.userProfiles.map((profile) => profile.userRoleLabel);

              if (landlordProfiles.length === 1 && agentProfiles.length === 1) {
                navigate("/selection");
              } else if (landlordProfiles.length === 1) {
                navigate("/selection");
              } else if (agentProfiles.length === 1) {  
                currentProfile.set(UserRoleId.Agent);
                navigate("/agent/dashboard");
              } else if (agencyProfiles.length === 1) {
                currentProfile.set(UserRoleId.Agency);
                navigate("/agency/dashboard");
              } else if (TenantProfiles.length === 1) {
                navigate("/tenant");
                // Navigate to Agent Dashboard
              } else if (TradeProfiles.length === 1) {
                navigate("/trade");
                // Navigate to Agent Dashboard
              } else {
                console.log(returnData.message);
              }
            } else if (userData.hasMultipleProfiles == false) {
              // const SingleProfile = returnData.object.firstName;
              // console.log(SingleProfile);
              navigate("/selection");
            }
          } else {
            ToastRef.current.notify(["error", returnData.message]);
            // alert(`Message: ${response.data.message}`);
            console.log(returnData.message);
          }
        });

        action.resetForm();
      },
    });

  // console.log(errors);

  return (
    <div className="col-12 section">
      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-3 container">
        <div className="header-si">
          <img
            className="logo-forealproperty-one-color2"
            alt=""
            src="./assets/images/logo.png"
          />
          <ToastAlert ref={ToastRef} />
        </div>
        <div className="content-signin">
          <div className="content2">
            <div className="text-and-supporting-text-su">
              <div className="text-si">Log in</div>
            </div>
          </div>
          <div className="content1">
            <form className="form" onSubmit={handleSubmit}>
              <div className="input-field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="email primary"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="form-error">{errors.email}</p>
                ) : null}
                <label htmlFor="password">Password</label>
                <input
                  className="password primary"
                  type="password"
                  name="password"
                  placeholder="Enter the password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password ? (
                  <p className="form-error">{errors.password}</p>
                ) : null}
              </div>
              <div className="input-field2-signin">
                <div className="input2">
                  <input
                    type="checkbox"
                    name="rem"
                    id="rem"
                    className="checkbox"
                    value={values.rem}
                    onChange={handleChange}
                  />
                  <div className="text3">&nbsp;Remember me </div>
                </div>
                <div className="button">
                  <a href="/forgot_password" className="a">
                    {" "}
                    Forgot Password
                  </a>
                </div>
              </div>
              <div className="submit">
                <button type="submit" className="button1">
                  Sign in
                </button>
              </div>
              <div className="signup">
                <div className="promt">Don’t have an account? &nbsp; </div>
                <div className="submitbutton">
                  <a href="/signup" className="a">
                    Signup
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
