import React from 'react';
import { useCallback, useState, useEffect, useRef } from "react";
import "./consent.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { apiUrlowner, BucketName, Region, AccessKey, Keyid, apiUrlamo, GetPendingProfiletenant } from "../../CommonSection/apiresources";
import { BiCalendar, BiEnvelope, BiMailSend, BiMap, BiMessage, BiPhone } from 'react-icons/bi';
import PlacesAutocomplete from "react-places-autocomplete";
import { useFormik } from "formik";
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import ProgressbarLandlord from '../../CommonSection/ProgressLandlord/ProgressLandlord';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { date } from 'yup';
import moment from 'moment';
const curre = new Date();
const currentDate = new Date();
console.log();
const initialValues = {
    name: "",
    date: moment(currentDate).format("DD-MM-YYYY"),


}
const Consent = () => {

    const signatureRef = useRef(null);
    const [sign, setSign] = useState(null);
    // Function to clear the signature canvas
    const clearSignature = () => {
        signatureRef.current.clear();
    };

    // Function to get the signature data as an image URL
    const getSignatureImage = () => {
        setSign(signatureRef.current.toDataURL())
        console.log("sign=" + sign);
        return signatureRef.current.toDataURL();
    };

    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [data, setData] = useState([]);
    const inputRef = useRef();
    const [propAddress, setPropAddress] = useState();
    const [propertyUID, setPropertyUID] = useState();
    const [propId, setPropId] = useState('');
    const [rentAppId, setRentAppId] = useState();
    let progressData = [
        {
            label: "Property Details",
            url: "/propertydetails",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Address History",
            url: "/addresshistory",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Verification",
            url: "/verify",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Employment",
            url: "/employment",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Questionnaire",
            url: "/questionnaire",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Documentation",
            url: "/documentation",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Consent",
            url: "/consent",
            isSelected: true,
            isCompleted: false
        },]


    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        // validationSchema: questionnaireSchema,
        onSubmit: (values, action) => {
            console.log(values);
            console.log(sign);
            console.log(errors);
            handleConsent();


        }
    });

    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID)
                    setUserId(parsedData.userId)
                    console.log(userData)
                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);


    useEffect(() => {
        if (propertyUID !== '') {
            // console.log(`UserId number: ${userId}`);
            const timeout = setTimeout(() => {
                getapi();
            }, 2)
        }

    }, [propertyUID]);



    const getapi = () => {

        const baseUrl = GetPendingProfiletenant;
        setPropertyUID("b4df6e16b5b74");
        // Request body with login information
        setData(progressData);
        const propertyId = propertyUID;
        const userId = userUId;
        const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
        console.log('API URL:', apiUrl);
        console.log(`PropertyId: ${propertyId}`);

        axios.get(apiUrl)
            .then(response => {

                // Handle the response data
                console.log(response.data);
                if (response.data.status == 1) {
                    // console.log('some data');
                    // console.log(response.data.object);
                    setPropId(response.data.object.propertyId);
                    setPropAddress(response.data.object.propertyStreetadress);
                    setRentAppId(response.data.object.rentApplicationId);
                    console.log(propId)
                } else {

                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)

                }

            })

            .catch(error => {

                console.error('Error:', error);

            });

    }

    const handleConsent = () => {
        const apiUrl = 'http://54.79.31.207:8002/api/Rent/addrentappconsent';

        // Request body with login information
        const VerifyData = {
            PropertyId: propId,
            PropertyAddress: propAddress,
            PropertyUniqueId: propertyUID,
            RentApplicationId: rentAppId,
            UserUniqueId: userUId,
            ConsentTimeStamp: values.date,
            ConsentName: values.name,
            ConsentSignature: sign,

        };

        axios.post(apiUrl, VerifyData)
            .then((response) => {
                // Handle the response from the backend
                if (response.data.status == 1) {


                    alert(`Message: ${response.data.message}`);
                    navigation.navigate('ContactDash')
                    console.log(response.data);
                } else {
                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });



    }
    return (
        <div className="tenant" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>
                <div className="progressbar">{data && <ProgressbarLandlord message={data} />}</div>
                <div className="tenant-consent">{`Consent`}</div>
                <form className="tenant-consent-form" onSubmit={handleSubmit} >
                    <div className="tenant-consent-content justify-content-center">
                        <p>I, the Applicant, acknowledge that I have read the Privacy Statement and the Privacy
                            Policy of Forealproperty Pty Ltd.
                            I authorise Forealproperty Pty Ltd and the Agent to collect information about me from:
                        </p>
                        <p>
                            1. My previous letting agents, landlords, current and previous employers
                        </p>
                        <p>
                            2. My referees
                        </p>
                        <p>
                            3. Forealproperty may use one or more of the following residential tenancy databases (RTDs) to check my
                            tenancy history for the purpose of deciding whether the landlord should enter into a tenancy agreement with me:
                        </p>
                        <p>
                            a. The landlord may use:
                        </p>
                        <p>
                            NTD	1300 563 826	ntd.net.au
                        </p>
                        <p>
                            TICA	1902 220 346	tica.com.au
                        </p>
                        <p>
                            RPDATA	1300 734 318	rpdata.com.au
                        </p>
                        <p>
                            BARCLAY MIS	1300 883 916	barclaysmis.com.au
                        </p>
                        <p>
                            TRA	02 9363 9244	tradingreference.com
                        </p>
                        <p>
                            b. You may contact an RTD about personal information held about you by using the contact details provided above
                        </p>
                        <p>
                            4. Personal information collected about me to the owner of the property even if the owner is resident outside Australia
                        </p>
                        <p>
                            5. Personal information collected about me to any third parties valuers, contractors, sales people, insurance companies,
                            bodies corporate, other agents and tenancy default databases to which it is reasonably necessary to disclose in relation
                            to the tenancy arrangement.
                        </p>
                        <p>

                            As your information may be emailed or stored in the cloud,
                            we cannot ensure that overseas recipients to whom your information
                            is disclosed will not breach the Australian Privacy Principles inrelation
                            to your information. Acknowledging that this is so, you consent to your information
                            being emailed and stored in the cloud.

                        </p>
                        <p>
                            I consent to Forealproperty Pty Ltd disclosing confirmation details (including NMI, MIRN and telephone number)
                            to the utility service provider and Forealproperty Pty Ltd.
                        </p>
                        <p>
                            I acknowledge that Forealproperty Pty Ltd Now, Forealproperty Pty Ltd, and the Agent do not accept
                            any responsibility for any delay in, or failure to arrange or provide for,
                            any connection or disconnection of a utility, or for any loss in connection with such delay or failure

                        </p>
                        <p>
                            I acknowledge that Forealproperty Pty Ltd, Forealproperty Pty Ltd the Agent and its employees may receive
                            a fee and/or benefit from a utility service provider in relation to the connection of a utility service
                        </p>

                        <p>
                            I also acknowledge, while there is no charge to me for the Forealproperty Pty Ltd;
                            normal service provider fees or bonds may apply for which I am responsible

                        </p>
                        <p>
                            I ACKNOWLEDGE THAT THIS APPLICATION IS SUBJECT TO THE APPROVAL OF THE OWNER.
                            I DECLARE THAT ALL INFORMATION CONTAINED IN THIS APPLICATION IS TRUE AND CORRECT AND GIVEN OF MY OWN FREE WILL.
                            I DECLARE THAT I HAVE INSPECTED THE PREMISES AND AM SATISFIED WITH THE CURRENT CONDITION AND CLEANLINESS OF THE PROPERTY.
                        </p>

                    </div>
                    <div className="tenant-consent-input-group">
                        <div className="tenant-consent-input">

                            <div className="tc-name">
                                <div className='s1' htmlFor="date">Name*</div>
                                <div className="input-group mb-3">

                                    <input type="text"
                                        id="tenantpd-date"
                                        className="form-control"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="name"
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>
                            <div className="tc-date">
                                <div className='s1' htmlFor="date">Date </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><BiCalendar height="100%" width="100%" /></span>
                                    </div>
                                    <input type="text"
                                        id="tenantpd-date"
                                        className="form-control"
                                        value={values.date}
                                        name="date"
                                        aria-describedby="basic-addon1" />
                                </div>
                            </div>

                        </div>
                        <div className="tc-sign ">
                            <div className='s1' htmlFor="name">Signeture* </div>
                            <div className='sign-tc'>
                                <SignatureCanvas
                                    penColor='green'
                                    ref={signatureRef}
                                    canvasProps={{ width: "300px", height: 150, className: 'signature-canvas' }}
                                />
                                <div className="sign-option">
                                    <div onClick={clearSignature} className='tc-b1'>Clear</div>
                                    <div onClick={getSignatureImage} className='tc-b2'>Confirm</div>
                                </div>
                            </div>
                            {/* {sign&& <img src={sign} alt="" /> } */}
                        </div>
                    </div>

                    <button className="tenant-consent-button" type="submit"  >
                        <div className="text-parent">
                            <div className="text7">Continue</div>
                        </div>
                    </button>
                </form>
            </div >
            <Footer />
        </div >
    );
}

export default Consent