import React from 'react'
import { Chart } from "react-chartjs-2"
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import './CardBoxDashboard.css'


const CardBoxDashboard = (props) => {
    const name=props.name;
    const value=props.value;
    const percentage=props.percentage;
    const chartdata=props.data
    const chartoptions=props.options;
    const time=props.time;
    const color=props.color;
    const data = {
        labels: [35, 0, 2, 15, 16, 16, 48, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18],
        datasets: [
          {
            label: "",
            data: [35, 0, 2, 15, 16, 16, 48, 7, 9, 49, 35, 34, 6, 8, 10, 12, 14, 16, 18],
            borderColor: color,
            borderWidth: 3,
            pointRadius: 0,
          },
    
        ],
      }
      const options = {
        scales: {
          x: {
            display: false, // Hide x-axis scale and labels
          },
          y: {
            display: false, // Hide y-axis scale and labels
          },
        },
        plugins: {
          legend: {
            display: false, // Hide the legend (dataset label)
          },
          tooltip: {
            enabled: true,
            mode: 'nearest', // Display tooltips on the nearest data point
            intersect: false, // Display tooltips even if the cursor isn't directly over a data point
          },
        },
      };

      
  return (
<div className="dbag-box-card">
                <div className="dbag-content-time-item">
                  <div className="dbag-content-time-name">
                    {time}
                  </div>
                  <BiDotsVerticalRounded />
                </div>
                <div className="dbag-content-name">
                  {name}
                </div>
                <div className="dbag-content-data">
                  <div className="dbag-content-data-one">
                    <div className="dbag-content-data-value">{value}</div>
                    <div className="dbag-content-data-description"><div className="dbag-content-data-percentage" style={{color: color}}><AiOutlineArrowUp/>{percentage} %</div><div className="dbag-content-data-description-text">vs last month</div></div>
                  </div>
                  <div className="dbag-content-data-graph">
                    <Chart
                      type="line"
                      data={data}
                      options={options}
                    />
                  </div>
                </div>
              </div>
  )
}

export default CardBoxDashboard