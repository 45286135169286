import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import { BiDollar } from "react-icons/bi";
dayjs.extend(customParseFormat);

const initialValues = {
  ApplicationFeesForUtilites: "",
  OfficeExpenses: "",
  Redirect: false,
  STRATA: false,
  Water: false,
  CouncilRates: false,
  Insurance: false,
  PAccountName: "",
  PBSB: "",
  PAccountNo: "",
};

const TribulantFees = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue(
        "ApplicationFeesForUtilites",
        getDetails.applicationFeesForUtilites
      );
      setFieldValue("OfficeExpenses", getDetails.officeExpenses);
      setFieldValue("Redirect", getDetails.redirect);
      setFieldValue("STRATA", getDetails.strata);
      setFieldValue("Water", getDetails.water);
      setFieldValue("Insurance", getDetails.insurance);
      setFieldValue("PAccountName", getDetails.pAccountName);
      setFieldValue("PBSB", getDetails.pbsb);
      setFieldValue("PAccountNo", getDetails.pAccountNo);
      setFieldValue("CouncilRates", getDetails.councilRates)

    }
  }, [getDetails, reportTo]);


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: tribunalFees,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    handleNextPage("AdvertisingAndPromotions");
  };

  const handleClear = () => {
    handleNextPage("Remunation");
  };

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                    <div className="pddpn-group2 pddpn-group3">
                      <div className=" agreementLabel mb-3 " style={{ paddingLeft: '0px' }} >{`EXPENSES AND CHARGES `}</div>
                    
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Services</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.expenseANDChargesService1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Expense AND Charges Service </div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.expenseANDChargesService2}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">Amount $</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.expenseANDChargesEstimate1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss ">When due and payable</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.expenseANDChargesdue1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Expense AND Charges Estimate</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <BiDollar/>{getDetails.expenseANDChargesEstimate2}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Expense AND Charges due</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> <BiDollar/> {getDetails.expenseANDChargesdue2}</div>
                        </div>
                      </div>
                    </div>
                    <div className="pddpn-group2 pddpn-group3">
                      <div className="agreementLabel mb-3" style={{ paddingLeft: '0px' }} >{`OTHER SERVICES [Clause 4.1]`}</div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="s1">
                        Those services and amounts cannot be varied except with the agreement in writing of the Principal.                        </div>
                        <div className="address-pair">
                          <div className="labelssss">Services</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesService1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Other Services Charges</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesService2}</div>
                        </div>
                      </div>

                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">$ (GST Inc)</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesGST1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Other Services GST</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesGST2}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">When due and payable</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesdue1}</div>
                        </div>
                      </div>
                      <div className={`lda-${name} col-lg-12 col-sm-12`} style={{ paddingLeft: '0px' }}>
                        <div className="address-pair">
                          <div className="labelssss">Other Services Due</div>
                          <div style={{marginTop:'3px'}}>:</div>
                          <div className="valuessss"> {getDetails.otherServicesdue2}</div>
                        </div>
                      </div>
                     
                    </div>

                  </div>
                </div>
        
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TribulantFees;
