import React from 'react'

const ShowValue = ({ Data , setData, Label, agreement, icon, colval}) => {
    const getLabelByValue = (value) => {
        const property = Data.find(item => item.value === value);
        return property ? property.label : " ";
    };

    

    return (
        <div className={`row`}>
            <div className={`${agreement ? "label2 col-lg-6 col-sm-12" : colval === 3 ? "col-lg-3" : "label"}`}>
                <strong>{Label}:</strong>
            </div>
            {
                Data ? (
                    <div className={`${agreement ? "value2" : "value"}`}>
                    {icon && <span className="icon">{icon}</span>}
                    {getLabelByValue(setData)}
                    </div>
                ) : (
                    <div className={`${agreement ? "value2" : "value"}`}>
                    {icon && <span className="icon">{icon}</span>}
                   <span > {setData}</span>
                    </div>

                )
            }
           
        </div>
    )
}

export default ShowValue