import React, { createContext, useContext, useState } from "react";

const SpinnerContext = createContext();

export const SpinnerProvider = ({ children }) => {
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  const showSpinner = () => {
    setIsSpinnerVisible(true);
  };

  const hideSpinner = () => {
    setIsSpinnerVisible(false);
  };

  return (
    <SpinnerContext.Provider
      value={{ isSpinnerVisible, showSpinner, hideSpinner }}
    >
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinnerContext = () => {
  return useContext(SpinnerContext);
};
