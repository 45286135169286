import React from 'react';
import { useCallback, useState, useEffect, useRef } from "react";
import "./documentation.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { GetCookie } from "../../CommonSection/Cookie/Session";
import {  BucketName, Region, AccessKey, Keyid, } from "../../CommonSection/apiresources";
import { useFormik } from "formik";

import Select from 'react-select';
import ProgressbarLandlord from '../../CommonSection/ProgressLandlord/ProgressLandlord';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { string } from 'yup';
const initialValues = {
    c1: false,
    c2: false,
    c3: false,
    ref1: "",
    ref2: "",
    ref3: "",
}
const Documentation = () => {

    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    let uploadedfile = [[]];
    const [id, setId] = useState("");
    const [size, setSize] = useState();
    const [progress, setProgress] = useState();
    const [Uploading, setUploading] = useState();
    const [value, setValue] = useState([]);
    const inputRef = useRef();
    const [final_data, setOwners] = useState([]);
    const [disabledOptions, setDisabledOptions] = useState([]);
    const [propAddress, setPropAddress] = useState();
    const [propertyUID, setPropertyUID] = useState();
    const [propId, setPropId] = useState('');
    const [rentAppId, setRentAppId] = useState();

    // const [selectedFile, setSelectedFile] = useState(null);
    // const [selectedFiles, setSelectedFiles] = useState([]);
    let selectedFile;
    const [newoptions, setNewoptions] = useState([
        { value: 4, label: 'OtherPhotoId' },
        { value: 5, label: 'BirthCertificate' },
        { value: 6, label: 'RentReceipts' },
        { value: 7, label: 'DebitCreditCard' },
        { value: 8, label: 'UtilityBill' },
        { value: 9, label: 'MedicareCard' },
        { value: 10, label: 'EmploymentProof' },

    ]);
    let filteredoptions = [];
    const [documents, setDocuments] = useState([{ id: '', toggeled: false, ref: "", path: [] }])

    let options = [
        { value: 4, label: 'OtherPhotoId' },
        { value: 5, label: 'BirthCertificate' },
        { value: 6, label: 'RentReceipts' },
        { value: 7, label: 'DebitCreditCard' },
        { value: 8, label: 'UtilityBill' },
        { value: 9, label: 'MedicareCard' },
        { value: 10, label: 'EmploymentProof' },

    ]
    let progressData = [
        {
            label: "Questionnaire",
            url: "/questionnaire",
            isSelected: false,
            isCompleted: false
        },
        {
            label: "Documentation",
            url: "/documentation",
            isSelected: true,
            isCompleted: false
        },
        {
            label: "Consent",
            url: "/consent",
            isSelected: false,
            isCompleted: false
        },]

    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        // validationSchema: questionnaireSchema,
        onSubmit: (values, action) => {
            // console.log(values);
            // console.log(errors);
            // console.log(files);
            let path1 = [[], [], []];
            for (let fileindex = 0; fileindex < path1.length; fileindex++) {
                const elementx = path1[fileindex];
                files.forEach(element => {
                    if (element.index == fileindex  )
                        elementx.push(element.name);
                });
    
            }
            let final_data=[
                { id: 1, toggeled: values.c1, ref: values.ref1, path: path1[0] },
                { id: 2, toggeled: values.c2, ref: values.ref2, path: path1[1] },
                { id: 3, toggeled: values.c3, ref: values.ref3, path: path1[2] }, 
                ...documents
            ]
            console.log(final_data);
            setOwners(final_data);

            // files.forEach(element => {
            //     if (element.index > 2)
            //         documents[element.index - 3].path.push(element.name);
            // });
            handleDocumentIdentify();
          

        }
    });

    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID)
                    setUserId(parsedData.userId)
                    console.log(userData)
                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);

    const deleteFile = (index) => {
        setFiles((prev) =>
            prev.filter((_, i) => i !== index)
        );

    }

    useEffect(() => {
        if (propertyUID !== '') {
            // console.log(`UserId number: ${userId}`);
            const timeout = setTimeout(() => {
                getapi();
            }, 2)
        }

    }, [propertyUID]);



    const getapi = () => {

        const baseUrl = GetPendingProfiletenant;
        setPropertyUID("b4df6e16b5b74");
        // Request body with login information
        setData(progressData);
        const propertyId = propertyUID;
        const userId = userUId;
        const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
        console.log('API URL:', apiUrl);
        console.log(`PropertyId: ${propertyId}`);

        axios.get(apiUrl)
            .then(response => {

                // Handle the response data
                console.log(response.data);
                if (response.data.status == 1) {
                    // console.log('some data');
                    // console.log(response.data.object);
                    setPropId(response.data.object.propertyId);
                    setPropAddress(response.data.object.propertyStreetadress);
                    setRentAppId(response.data.object.rentApplicationId);
                    console.log(propId)
                } else {

                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)

                }

            })

            .catch(error => {

                console.error('Error:', error);

            });

    }

    const select = (event) => {
        inputRef.current.click();
    }

    const handleChangedocument = (text, index, key) => {
        const newOwners = [...documents];
        if (key == 'name') {
            setDisabledOptions((prevDisabledOptions) => [
                ...prevDisabledOptions,
                text,
            ]);
            console.log(disabledOptions);
            // newOwners[index][key] = text.label;
            newOwners[index]['id'] = text.value;

        }
        else if (key == 'toggeled')
            newOwners[index][key] = !newOwners[index][key];
        else if(key=='path')
            newOwners[index][key].push(text);
        else
            newOwners[index][key] = text.target.value;
        setDocuments(newOwners);

    };

    const addmoredocuments = (index) => {
        setDocuments([...documents, { id: "", toggeled: false, ref: "", path: [] }]);
        filteredoptions = newoptions;
        if (disabledOptions[index]) {
            filteredoptions = [];
            newoptions.forEach(element => {
                if (disabledOptions[index].value !== element.value)
                    filteredoptions.push(element);
            });
        }
        setNewoptions(filteredoptions);
        console.log(newoptions);
    }
    const removemoredocuments = (index) => {
        let tempdata = disabledOptions.pop();
        const newOwners = documents.filter((_, i) => i !== index);
        filteredoptions = newoptions;
        // console.log(tempdata);
        // console.log(filteredoptions);
        if (!filteredoptions.includes(tempdata))
            filteredoptions.push(tempdata);
        setNewoptions(filteredoptions);
        setDocuments(newOwners);
        //console.log(index);
    }
    const handleFileChange = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
            // console.log(e.target.files[i], i);
            selectedFile = e.target.files[i];
            // setSelectedFile(e.target.files[i]);
            await handleUpload(indexforfiles);
        }


        // setSelectedFiles();
        // console.log(selectedFiles);

        // e.target.files.forEach(selectedFile => {
        //         setSelectedFile(selectedFile)
        //         console.log("started function");
        //         handleUpload();
        //         console.log('ended function')
        // });
    };
    const handleUpload = async (indexforfiles) => {
        // console.log(selectedFile);
        if (!selectedFile) {

            alert("Please select a file to upload.");
            return;
        }
        AWS.config.update({

            accessKeyId: AccessKey,
            secretAccessKey: Keyid,
            region: Region,

        });
        const s3 = new AWS.S3();
        // console.log(selectedFile);
        const fileExtension = selectedFile.name.split(".").pop();
        let new_name = "ownerproof" + Date.now() + "." + fileExtension
        const params = {

            Bucket: BucketName,
            Key: "verificationproofs/" + new_name,
            Body: selectedFile,

        };

        await s3.upload(params, (err, data) => {

            if (err) {

                console.error("Error uploading file:", err);
                alert("Error uploading file. Please try again.");

            } else {
                console.log("File uploaded successfully:", data);
                // alert(`File uploaded successfully. Location: ${data.Location}`);
                setProgress(100);
            }

        });
        setFiles((p) => [
            ...p,
            {
                name: new_name,
                size: selectedFile.size,
                url: URL.createObjectURL(selectedFile),
                type: selectedFile.type,
                index: indexforfiles,
                progress: progress,
            },
        ])
        if(indexforfiles>2)
            handleChangedocument(new_name, indexforfiles-3, 'path');
        // console.log("ended");

    };

    const handleDocumentIdentify = () => {


        const apiUrl = 'http://54.79.31.207:8002/api/Rent/addrentappdocuments';

        // Request body with login information
        const VerifyData = {
            PropertyId: propId,
            PropertyAddress: propAddress,
            PropertyUniqueId: propertyUID,
            RentApplicationId: rentAppId,
            UserUniqueId: userUId,
            identificationProofs: final_data,
        };
        console.log(VerifyData);
        axios.post(apiUrl, VerifyData)
            .then((response) => {
                // Handle the response from the backend
                if (response.data.status == 1) {

                    alert(`Message: ${response.data.message}`);
                    navigation.navigate('Consent')

                    console.log(response.data);
                } else {
                    alert(`Message: ${response.data.message}`);
                    console.log(response.data)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });



            
    }
   

    return (
        <div className="tenant" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>
                <div className="progressbar">{data && <ProgressbarLandlord message={data} />}</div>
                <div className="tenant-documentation">{`Documents Identification`}</div>
                <div className="progress" style={{ width: "66%", marginTop: "20px", gap: "5px", color: "#0E7090" }}>
                    <ProgressBar now={(2 * values.c1 + values.c2 + values.c3 + documents[0].toggeled) * 20} variant="info" style={{ width: "100%", height: "20px", color: "#0E7090" }} />
                    {(2 * values.c1 + values.c2 + values.c3 + documents[0].toggeled) * 20}%
                </div>
                <form className="tenant-documentation-form" onSubmit={handleSubmit} >
                    <div className="tenant-documentation-input">
                        <div className="document1">
                            <label className="tenant-documentation-input-one">

                                <input type="checkbox" className='tenantd-input-checkbox' name='c1' value={values.c1} onChange={handleChange} />
                                <span className="checkmark"></span>
                                <label className="tenantd-checkbox-input-label">40 pts Drivers License or Passport Photo Page*</label>

                            </label>
                            {values.c1 && <div className="document-last-group">
                                <div className="tenantd-ref">
                                    <label htmlFor="name">Reference </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control ref1"
                                            placeholder=""
                                            value={values.ref1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="ref1"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div className="upload-section"  >
                                    <img src=".\assets\images\upload.png" alt="upload-icon" className="upload-icon" />
                                    <div className="upload-setion-input">

                                        <span className="upload-select" onClick={select}>
                                            Click to upload
                                        </span>
                                        <input ref={inputRef}
                                            type="file"
                                            onChange={(e) => handleFileChange(e, 0)}
                                            className="upload-input"
                                            name="upload"
                                            multiple
                                        />
                                        or drag or drop
                                    </div>
                                    <div className="upload-details">
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </div>
                                </div>

                            </div>

                            }
                        </div>
                        <div className="document2">
                            <label className="tenant-documentation-input-one">

                                <input type="checkbox" className='tenantd-input-checkbox' name='c2' value={values.c2} onChange={handleChange} />
                                <span className="checkmark"></span>
                                <label className="tenantd-checkbox-input-label">20 pts Proof of income/Pay slips*</label>

                            </label>
                            {values.c2 && <div className="document-last-group">
                                <div className="tenantd-ref">
                                    <label htmlFor="name">Reference </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control ref1"
                                            placeholder=""
                                            value={values.ref2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="ref2"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div className="upload-section"  >
                                    <img src=".\assets\images\upload.png" alt="upload-icon" className="upload-icon" />
                                    <div className="upload-setion-input">

                                        <span className="upload-select" onClick={select}>
                                            Click to upload
                                        </span>
                                        <input ref={inputRef}
                                            type="file"
                                            onChange={(e) => handleFileChange(e, 1)}
                                            className="upload-input"
                                            name="upload"
                                            multiple
                                        />
                                        or drag or drop
                                    </div>
                                    <div className="upload-details">
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </div>
                                </div>

                            </div>
                            }
                        </div>
                        <div className="document3">
                            <label className="tenant-documentation-input-one">

                                <input type="checkbox" className='tenantd-input-checkbox' name='c3' value={values.c3} onChange={handleChange} />
                                <span className="checkmark"></span>
                                <label className="tenantd-checkbox-input-label">20 pts Bank Statements*</label>

                            </label>
                            {values.c3 && <div className="document-last-group">
                                <div className="tenantd-ref">
                                    <label htmlFor="name">Reference </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control ref1"
                                            placeholder=""
                                            value={values.ref3}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="ref3"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div className="upload-section"  >
                                    <img src=".\assets\images\upload.png" alt="upload-icon" className="upload-icon" />
                                    <div className="upload-setion-input">
                                        <span className="upload-select" onClick={select}>
                                            Click to upload
                                        </span>
                                        <input ref={inputRef}
                                            type="file"
                                            onChange={(e) => handleFileChange(e, 2)}
                                            className="upload-input"
                                            name="upload"
                                            multiple
                                        />
                                        or drag or drop
                                    </div>
                                    <div className="upload-details">
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {documents.map((document, index) => (<div className="documentx" key={index}>
                            <div className="td-top-level input-group mb-3">
                                <label className="tenant-documentation-input-one" key={index}>
                                    <input type="checkbox" className='tenantd-input-checkbox' name='c3' value={document.toggeled} onChange={(text) => handleChangedocument(text, index, 'toggeled')} />
                                    <span className="checkmark"></span>
                                </label>
                                <Select options={newoptions} placeholder="Select any Document" className="td-select" defaultValue={document.name} id="s1" onChange={(text) => handleChangedocument(text, index, 'name')} />

                            </div>

                            {document.toggeled && <div className="document-last-group">
                                <div className="tenantd-ref">
                                    <label htmlFor="name">Reference </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control ref1"
                                            placeholder=""
                                            value={document.ref}
                                            onChange={(text) => handleChangedocument(text, index, 'ref')}
                                            onBlur={(text) => handleChangedocument(text, index, 'ref')}
                                            name="ref3"
                                            aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <div className="upload-section"  >
                                    <img src=".\assets\images\upload.png" alt="upload-icon" className="upload-icon" />
                                    <div className="upload-setion-input">
                                        <span className="upload-select" onClick={select}>
                                            Click to upload
                                        </span>
                                        <input ref={inputRef}
                                            type="file"
                                            onChange={(e) => handleFileChange(e, index + 3)}
                                            className="upload-input"
                                            name="upload"
                                            multiple
                                        />
                                        or drag or drop
                                    </div>
                                    <div className="upload-details">
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                    </div>
                                </div>
                            </div>}
                            <div className="add-remove-td">
                                {index > 0 && index == documents.length - 1 && <div className="remoremoreducments" onClick={() => removemoredocuments(index)}>Remove Documents</div>}
                                {index == documents.length - 1 && document.id && <div className="addmoredocuments" onClick={() => addmoredocuments(index)}>Add more documnets</div>}
                            </div>
                        </div>))}
                    </div>

                    {files.map((files, index) => (
                        <div className="icon-upload-details" key={index}>
                            <img src={files.url} alt={files.nm} width={50} height={50} />
                            <div className="uploaded-files">
                                <div className="name-delete" key={index}>
                                    <div className="file-name">{files.name}</div>
                                    <span className="delete" onClick={() => deleteFile(index)}><img src=".\assets\images\trash.png" alt="delete" /></span>
                                </div>
                                <div className="size-details">Related to document {files.index + 1} , size ={files.size} bytes</div>
                                <div className="progress" style={{ width: "100%", marginTop: "20px", gap: "5px", color: "#0E7090" }}><ProgressBar now={progress} variant="info" style={{ width: "100%", height: "20px", color: "#0E7090" }} /> {progress}%</div>
                            </div>
                        </div>)
                    )}

                    <button className="tenant-documentation-button" type="submit"  >
                        <div className="text-parent">
                            <div className="text7">Continue</div>
                        </div>
                    </button>
                </form>
            </div >
            <Footer />
        </div >
    );
}

export default Documentation