import React from "react";
import "./progress.css";

const ProgressbarLandlord = (props) => {
  // console.log(props);

  return (
    <div className="progressbar">
      <div className="progress-line mb-4">
        {props.message.map((data, index) => (
          <>
            {index > 0 && <div className="col-3 line active" key={index}></div>}
            {!data.isSelected && !data.isCompleted && (
              <div className="circle active">
                <div>
                  <i className="dot"></i>
                </div>
                <span>
                  <a href={data.url}>{data.label}</a>
                </span>
              </div>
            )}
            {data.isSelected && (
              <div className="circle active">
                <div className="dot"></div>
                <span>{data.label}</span>
              </div>
            )}
            {!data.isSelected && data.isCompleted && (
              <div className="circle active">
                <div>
                  <i className="fa fa-check" style={{ color: "#47CD89" }}></i>
                </div>
                <span>
                  {data.url != null ? (
                    <a href={data.url} style={{ textDecoration: "none" }}>
                      {data.label}
                    </a>
                  ) : (
                    <span>{data.label}</span>
                  )}
                </span>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default ProgressbarLandlord;
