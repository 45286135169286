import { useCallback, useState, useEffect, useRef } from "react";
import "./verifyagency.css";
// import '../SignIn/temp.css'
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { useFormik } from "formik";
import Textbox from "../../../src/input-components/textbox"
import {
  FilePath,
  addVerificationAgency,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import {
  BucketName,
  Region,
  AccessKey,
  Keyid,
} from "../../CommonSection/apiresources";
import ProgressBar from "react-bootstrap/esm/ProgressBar";

import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import {
  ActionStatus,
  VerificationDocumentType,
  eNewAgencyProfileStatus,
} from "../../../Utilities/enum";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { UserVerificationSchema } from "../../CommonSection/Schema/schema";
import DatePicker from "react-datepicker";
import CustomSelect from "../../CommonSection/CustomComponents/CustomSelect";
import {
  getDefaultDateValue,
  getDefaultFromValue,
  getdataForAgencyProgressbar,
  navigateToPageWithDelay,
} from "../../CommonSection/Utility/ComponentFunction";
import { Tab, Tabs } from "react-bootstrap";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { FaFileDownload, FaTrashAlt } from "react-icons/fa";
import { FormatDate } from "../../../components/CommonSection/Utility/Utility"
const VerifyAgency = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const primaryDocumentRef = useRef(null);
  const secondayDocumentRef = useRef(null);

  const [userId, setUserID] = useState("");
  const [userUId, setUserUId] = useState("");
  const [status, setStatus] = useState(0);
  const [agencyId, setAgencyId] = useState("");

  const [primaryDocuments, setPrimaryDocuments] = useState([]);
  const [secondaryDocuments, setSecondaryDocuments] = useState([]);

  const [exsitingPrimaryDocuments, setExsitingPrimaryDocuments] = useState([]);
  const [existingSecondaryDocuments, setExistingSecondaryDocuments] = useState(
    []
  );

  const [files, setFiles] = useState([]);

  // const dataArray = ["verificationproofs/ownerproof1700641188595.png", "verificationproofs/ownerproof1700641188579.jpg"];
  // const dataString = dataArray.join(', ');

  // console.log(dataString);
  const [progress1, setProgress1] = useState();
  const [progress2, setProgress2] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [prog, setProg] = useState("");

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  // For the drop event
  const handleDrop = (e, index, documentType) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);

    handleFileChange(droppedFiles, 0, documentType);
  };
  const handleInputChange = (e, indexforfiles, documentType) => {
    const files = e.target.files;
    handleFileChange(files, indexforfiles, documentType);
  };

  const handleFileChange = async (files, indexforfiles, documentType) => {
    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      await handleUpload(selectedFile, indexforfiles, documentType);
    }
  };

  const handleFileUploader = (documentType) => {
    if (
      documentType == VerificationDocumentType.PrimaryDocument &&
      primaryDocumentRef.current
    )
      primaryDocumentRef.current.click();
    if (
      documentType == VerificationDocumentType.SecondaryDocument &&
      secondayDocumentRef.current
    )
      secondayDocumentRef.current.click();
  };

  const handleTabSwitch = () => {
    setTabkey("SecondaryId");
  };

  const options1 = [
    { value: 1, label: "License (Driving)" },
    { value: 2, label: "Passport" },
  ];
  const options2 = [
    { value: 1, label: "Medical (Health)" },
    { value: 2, label: "BankCard" },
  ];

  const initialValues = {
    primaryDocumentType: null,
    primaryDocFirstName: "",
    primaryDocLastName: "",
    primaryDocMidName: "",
    primaryDocNumber: "",
    primaryDocExpiry: "",
    secondaryDocumentType: null,
    secondaryDocFirstName: "",
    secondaryDocMidName: "",
    secondaryDocLastName: "",
    secondaryDocNumber: "",
    secondaryDocExpiry: "",
  };

  // ***********************************************************************************************************
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserID(parsedData.userId);
        }
      } catch (error) {
        ToastRef.current.notify([
          "error",
          "Error retrieving data from AsyncStorage",
        ]);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetVerificationDetails();
      }, 1);
    }
  }, [userUId]);

  const {
    errors,
    values,
    setValues,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: UserVerificationSchema,
    onSubmit: (values, action) => {
      handleVerificationDoc();
    },
  });
  const GetVerificationDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          const verificationDetails = returnData.object.verificationDetails;
          setAgencyId(returnData.object.agencyId);
          setStatus(returnData.object.status);
          setValues({ ...values, ...verificationDetails });
          if (verificationDetails.primaryDocumentFilePath != null)
            setExsitingPrimaryDocuments(
              verificationDetails.primaryDocumentFilePath
                .split(", ")
                .filter(Boolean) // This filters out empty strings
            );


          if (verificationDetails.secondaryDocumentFilePath != null)


            setExistingSecondaryDocuments(
              verificationDetails.secondaryDocumentFilePath.split(", ").filter(Boolean)
            );
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", "Internal Error"]);
      });
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFieldValue(fieldName, selectedOption);
    setFieldTouched(fieldName, true);
  };

  const PrimaryDocs = primaryDocuments.map((doc) => doc.key).join(", ");
  const PrimaryAttachDocs = exsitingPrimaryDocuments.join(", ");
  const PrimaryDocumentFile = PrimaryDocs
    ? `${PrimaryDocs}, ${PrimaryAttachDocs}`
    : PrimaryAttachDocs;

  const SecondaryDocs = secondaryDocuments.map((doc) => doc.key).join(", ");
  const SecondaryAttachDocs = existingSecondaryDocuments.join(", ");
  const SecondaryDocumentFile = SecondaryDocs
    ? `${SecondaryDocs}, ${SecondaryAttachDocs}`
    : SecondaryAttachDocs;

  const handleVerificationDoc = () => {
    const formData = {
      UserId: userId,
      Id: agencyId,
      PrimaryDocumentType: values.primaryDocumentType,
      PrimaryDocFirstName: values.primaryDocFirstName,
      PrimaryDocMidName: values.primaryDocMidName,
      PrimaryDocLastName: values.primaryDocLastName,
      PrimaryDocNumber: values.primaryDocNumber,
      PrimaryDocExpiry: values.primaryDocExpiry,
      PrimaryDocumentFilePath: PrimaryDocumentFile,
      SecondaryDocumentType: values.secondaryDocumentType,
      SecondaryDocFirstName: values.secondaryDocFirstName,
      SecondaryDocMidName: values.secondaryDocMidName,
      SecondaryDocLastName: values.secondaryDocLastName,
      SecondaryDocNumber: values.secondaryDocNumber,
      SecondaryDocExpiry: values.secondaryDocExpiry,
      SecondaryDocumentFilePath: SecondaryDocumentFile,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addVerificationAgency,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/licensedetails", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", returnData.message]);
      });
  };

  const handleUpload = async (selectedFile, indexforfiles, documentType) => {
    // console.log(selectedFile);
    if (!selectedFile || !(selectedFile instanceof File)) {
      alert("Please select a file to upload.");
      return;
    }
    AWS.config.update({
      accessKeyId: AccessKey,
      secretAccessKey: Keyid,
      region: Region,
    });
    const s3 = new AWS.S3();
    // console.log(selectedFile);
    const fileExtension = selectedFile.name.split(".").pop();
    let new_name = "ownerproof" + Date.now() + "." + fileExtension;
    const params = {
      Bucket: BucketName,
      Key: "verificationproofs/" + new_name,
      Body: selectedFile,
    };

    await s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading file:", err);
        alert("Error uploading file. Please try again.");
      } else {
        console.log("File uploaded successfully:", data);
        if (documentType == VerificationDocumentType.PrimaryDocument)
          setPrimaryDocuments((p) => [
            ...p,
            {
              name: new_name,
              key: params.Key,
              size: selectedFile.size,
              url: URL.createObjectURL(selectedFile),
              type: selectedFile.type,
              index: indexforfiles,
              progress: prog,
            },
          ]);

        setProgress1(100);

        if (documentType == VerificationDocumentType.SecondaryDocument)
          setSecondaryDocuments((p) => [
            ...p,
            {
              name: new_name,
              key: params.Key,
              size: selectedFile.size,
              url: URL.createObjectURL(selectedFile),
              type: selectedFile.type,
              index: indexforfiles,
              progress: prog,
            },
          ]);

        // alert(`File uploaded successfully. Location: ${data.Location}`);
        setProgress2(100);
      }
    });
    if (documentType == VerificationDocumentType.PrimaryDocument) {
      setProg("progress1");
    } else {
      setProg("progress2");
    }
  };

  const deleteFile = (key, documentType) => {
    if (documentType == VerificationDocumentType.PrimaryDocument)
      setPrimaryDocuments((prev) => prev.filter((item) => item.key !== key));
    if (documentType == VerificationDocumentType.SecondaryDocument)
      setSecondaryDocuments((prev) => prev.filter((item) => item.key !== key));
  };
  // ***********************************************************************************************************

  const handleDeleteImage1 = (index) => {
    const newArray = [
      ...exsitingPrimaryDocuments.slice(0, index),
      ...exsitingPrimaryDocuments.slice(index + 1),
    ];
    setExsitingPrimaryDocuments(newArray);
  };
  const handleDeleteImage2 = (index) => {
    const newArray = [
      ...existingSecondaryDocuments.slice(0, index),
      ...existingSecondaryDocuments.slice(index + 1),
    ];
    setExistingSecondaryDocuments(newArray);
  };
  const handleDownload1 = (image) => {
    const filePath = FilePath + `./${image}`;

    const anchor = document.createElement("a");
    anchor.href = filePath;
    anchor.download = `File_${image}`;
    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };
  const handleDownload2 = (image) => {
    const filePath = FilePath + `./${image}`;

    const anchor = document.createElement("a");
    anchor.href = filePath;
    anchor.download = `File_${image}`;
    document.body.appendChild(anchor);

    anchor.click();
    document.body.removeChild(anchor);
  };

  const [tabKey, setTabkey] = useState("PrimaryId");
  const defaultPdfImage = "../../../assets/images/PDF.png";




  return (
    <div className="agency">
      <Header />

      <div className="add-property">
        <div className="col-sm-12 col-md-10 col-lg-3 container">
          <div
            className="blank_space"
            style={{
              backgroundColor: "white",
              height: "90px",
            }}
          >
            <ToastAlert ref={ToastRef} />
          </div>
          <div className="progress-ico">
            <ProgressbarLandlord
              message={getdataForAgencyProgressbar(
                status,
                eNewAgencyProfileStatus.VerificationDocumentAdded
              )}
            />
          </div>

          <div className="verify-id">{`Verify Id`}</div>
          <form className="verfify-input" onSubmit={handleSubmit}>
            <Tabs
              defaultActiveKey={tabKey}
              id="fill-tab-example"
              activeKey={tabKey}
              className="TabbarNavigation"
              onSelect={(k) => setTabkey(k)}
              fill
            >
              <Tab eventKey="PrimaryId" title="PrimaryID">
                <>
                  <label htmlFor="s1">Choose one option</label>
                  <CustomSelect
                    label="Select any Document"
                    name="primaryDocumentType"
                    options={options1}
                    placeholder="Select any Document"
                    handleChange={handleSelectChange}
                    value={getDefaultFromValue(values.primaryDocumentType)}
                  />

                  {errors.primaryDocumentType && touched.primaryDocumentType ? (
                    <p className="form-error">{errors.primaryDocumentType}</p>
                  ) : null}

                  {values.primaryDocumentType ? (
                    <div className="details">





                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="First Name"
                        value={getDefaultFromValue(values.primaryDocFirstName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="primaryDocFirstName"
                      />
                      {errors.primaryDocFirstName &&
                        touched.primaryDocFirstName ? (
                        <p className="form-error">
                          {errors.primaryDocFirstName}
                        </p>
                      ) : null}
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="Middle Name"
                        value={getDefaultFromValue(values.primaryDocMidName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="primaryDocMidName"
                      />
                      {errors.primaryDocMidName && touched.primaryDocMidName ? (
                        <p className="form-error">{errors.primaryDocMidName}</p>
                      ) : null}
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="Last Name"
                        value={getDefaultFromValue(values.primaryDocLastName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="primaryDocLastName"
                      />
                      {errors.primaryDocLastName &&
                        touched.primaryDocLastName ? (
                        <p className="form-error">
                          {errors.primaryDocLastName}
                        </p>
                      ) : null}
                      <input
                        // type="number"
                        className="form-control tenant-date"
                        placeholder="Number"
                        value={getDefaultFromValue(values.primaryDocNumber)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="primaryDocNumber"
                      />
                      {errors.primaryDocNumber && touched.primaryDocNumber ? (
                        <p className="form-error">{errors.primaryDocNumber}</p>
                      ) : null}

                      <Textbox colVal="mt-2" type="date" name="primaryDocExpiry" placeholder="dd/mm/yyyy" value={FormatDate(values.primaryDocExpiry)} onChange={handleChange} onBlur={handleBlur} error={errors.primaryDocExpiry} touched={touched.primaryDocExpiry} />

                      {exsitingPrimaryDocuments[0] !== "" ? (
                        <div className="ImageHandler">
                          {exsitingPrimaryDocuments.map((image, index) => (
                            <div key={index} className="image-container">
                              <img
                                src={
                                  image.endsWith(".pdf")
                                    ? defaultPdfImage
                                    : FilePath + image
                                }
                                alt={`Image ${index}`}
                                width={100}
                                height={100}
                                className="image-preview"
                              />
                              <div className="IconFlex">
                                <span
                                  className="IconCover"
                                  onClick={() => handleDeleteImage1(index)}
                                >
                                  <FaTrashAlt />
                                </span>
                                <span
                                  className="IconCover"
                                  onClick={() => handleDownload1(image)}
                                >
                                  <FaFileDownload />
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}


                      <div
                        className={`upload-section ${isDragging ? "dragging" : ""
                          }`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={(e) =>
                          handleDrop(
                            e,
                            0,
                            VerificationDocumentType.PrimaryDocument
                          )
                        }
                      >
                        <img
                          src="../../../assets/images/upload.png"
                          alt="upload-icon"
                          className="upload-icon"
                        />
                        <div className="upload-setion-input">
                          <span
                            className="upload-select"
                            onClick={() =>
                              handleFileUploader(
                                VerificationDocumentType.PrimaryDocument
                              )
                            }
                          >
                            Click to upload
                          </span>
                          <input
                            ref={primaryDocumentRef}
                            type="file"
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                0,
                                VerificationDocumentType.PrimaryDocument
                              )
                            }
                            className="upload-input"
                            name="upload"
                            multiple
                          />
                          or drag or drop
                        </div>
                      </div>

                      {primaryDocuments.map((files, index) => (
                        <div className="icon-upload-details" key={index}>
                          <img
                            src={files.url}
                            alt={files.nm}
                            width={25}
                            height={25}
                          />
                          <div className="uploaded-files">
                            <div className="name-delete" key={index}>
                              <div className="file-name">{files.name}</div>
                              <span
                                className="delete"
                                onClick={() =>
                                  deleteFile(
                                    files.key,
                                    VerificationDocumentType.PrimaryDocument
                                  )
                                }
                              >
                                <img
                                  src="../../../assets/images/trash.png"
                                  alt="delete"
                                />
                              </span>
                            </div>
                            {/* <div className="size-details">Related to document {files.index + 1} , size ={files.size} bytes</div> */}
                            <div
                              className="progress"
                              style={{
                                width: "100%",
                                marginTop: "20px",
                                gap: "5px",
                                color: "#0E7090",
                              }}
                            >
                              <ProgressBar
                                now={progress1}
                                variant="info"
                                style={{
                                  width: "100%",
                                  height: "20px",
                                  color: "#0E7090",
                                }}
                              />{" "}
                              {progress1}%
                            </div>
                          </div>
                        </div>
                      ))}
                     


                      <button
                        type="button"
                        className="button-v"
                        onClick={handleTabSwitch}
                      >
                        <div className="text-parent">
                          <div className="text7">Continue</div>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </>
              </Tab>



              <Tab eventKey="SecondaryId" title="SecondaryID">
                <>
                  <label htmlFor="s2">Choose one option</label>
                  <CustomSelect
                    label="Select any Document"
                    name="secondaryDocumentType"
                    options={options2}
                    placeholder="Select any Document"
                    handleChange={handleSelectChange}
                    value={values.secondaryDocumentType}
                  />
                  {errors.secondaryDocumentType &&
                    touched.secondaryDocumentType ? (
                    <p className="form-error">{errors.secondaryDocumentType}</p>
                  ) : null}
                  {values.secondaryDocumentType && (
                    <div className="details">
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="First Name"
                        value={getDefaultFromValue(
                          values.secondaryDocFirstName
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="secondaryDocFirstName"
                      />
                      {errors.secondaryDocFirstName &&
                        touched.secondaryDocFirstName ? (
                        <p className="form-error">
                          {errors.secondaryDocFirstName}
                        </p>
                      ) : null}
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="Last Name"
                        value={getDefaultFromValue(values.secondaryDocMidName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="secondaryDocMidName"
                      />
                      {errors.secondaryDocMidName &&
                        touched.secondaryDocMidName ? (
                        <p className="form-error">
                          {errors.secondaryDocMidName}
                        </p>
                      ) : null}
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="Number"
                        value={getDefaultFromValue(values.secondaryDocLastName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="secondaryDocLastName"
                      />
                      {errors.secondaryDocLastName &&
                        touched.secondaryDocLastName ? (
                        <p className="form-error">
                          {errors.secondaryDocLastName}
                        </p>
                      ) : null}
                      <input
                        type="text"
                        className="form-control tenant-date"
                        placeholder="Seq Number"
                        value={getDefaultFromValue(values.secondaryDocNumber)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="secondaryDocNumber"
                      />
                      {errors.secondaryDocNumber &&
                        touched.secondaryDocNumber ? (
                        <p className="form-error">
                          {errors.secondaryDocNumber}
                        </p>
                      ) : null}


                      <Textbox colVal="mt-2" name="secondaryDocExpiry" type="date" placeholder="dd/mm/yyyy" value={FormatDate(values.secondaryDocExpiry)} onChange={handleChange} onBlur={handleBlur} error={errors.secondaryDocExpiry} touched={touched.secondaryDocExpiry} />
                      {existingSecondaryDocuments[0] !== "" ? (
                    <div className="ImageHandler">
                      {existingSecondaryDocuments.map((image, index) => (
                        <div key={index} className="image-container">
                          <img
                            src={
                              image.endsWith(".pdf")
                                ? defaultPdfImage
                                : FilePath + image
                            }
                            alt={`Image ${index}`}
                            width={100}
                            height={100}
                            className="image-preview"
                          />
                          <div className="IconFlex">
                            <span
                              className="IconCover"
                              onClick={() => handleDeleteImage2(index)}
                            >
                              <FaTrashAlt />
                            </span>
                            <span
                              className="IconCover"
                              onClick={() => handleDownload2(image)}
                            >
                              <FaFileDownload />
                              {/* <img
                                  src="../../../assets/images/download.png"
                                  alt="IconCover"
                                /> */}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                      

                      <div
                        className={`upload-section ${isDragging ? "dragging" : ""
                          }`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={(e) =>
                          handleDrop(
                            e,
                            0,
                            VerificationDocumentType.SecondaryDocument
                          )
                        }
                      >
                        <img
                          src="../../../assets/images/upload.png"
                          alt="upload-icon"
                          className="upload-icon"
                        />
                        <div className="upload-setion-input">
                          <span
                            className="upload-select"
                            onClick={() =>
                              handleFileUploader(
                                VerificationDocumentType.SecondaryDocument
                              )
                            }
                          >
                            Click to upload
                          </span>
                          <input
                            ref={secondayDocumentRef}
                            type="file"
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                0,
                                VerificationDocumentType.SecondaryDocument
                              )
                            }
                            className="upload-input"
                            name="upload"
                            multiple
                          />
                          or drag or drop
                        </div>
                      </div>
                      {secondaryDocuments.map((files, index) => (
                        <div className="icon-upload-details" key={index}>
                          <img
                            src={files.url}
                            alt={files.nm}
                            width={25}
                            height={25}
                          />
                          <div className="uploaded-files">
                            <div className="name-delete" key={index}>
                              <div className="file-name">{files.name}</div>
                              <span
                                className="delete"
                                onClick={() =>
                                  deleteFile(
                                    files.key,
                                    VerificationDocumentType.SecondaryDocument
                                  )
                                }
                              >
                                <img
                                  src="../../../assets/images/trash.png"
                                  alt="delete"
                                />
                              </span>
                            </div>
                            {/* <div className="size-details">Related to document {files.index + 1} , size ={files.size} bytes</div> */}
                            <div
                              className="progress"
                              style={{
                                width: "100%",
                                marginTop: "20px",
                                gap: "5px",
                                color: "#0E7090",
                              }}
                            >
                              <ProgressBar
                                now={progress2}
                                variant="info"
                                style={{
                                  width: "100%",
                                  height: "20px",
                                  color: "#0E7090",
                                }}
                              />{" "}
                              {progress2}%
                            </div>
                          </div>
                        </div>
                      ))}

                      
                    </div>

                  )}


                  

                  <button className="button-v" type="submit">
                    <div className="text-parent">
                      <div className="text7">Verify</div>
                    </div>
                  </button>
                </>
              </Tab>
            </Tabs>

            {/* DifferentScreen */}
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default VerifyAgency;
