import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatesalesagrmntpromotiondetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {dynamicUrl,} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { prominantalActivities } from "../../../components/CommonSection/Schema/schema";

dayjs.extend(customParseFormat);

const initialValues = {
    isListedOnREA: false,
    isListedOnDomain: false,
    SignboardOutsideProperty: false,
    PropertyPhotos: false,
    FloorPlans: false,
    SocialMediaCampaign: false,
    Inspection: '',
    WithoutRisk: false,
    WithDefects: false,
    DisclosureSourceRebate1: '',
    DisclosureEstimateRebate1: '',
    DisclosureSourceRebate2: '',
    DisclosureEstimateRebate2: '',
    WaiverOfCoolinOffPeriod: false,
    WasAgreementProvidedPrincipal: false,
    DateProvided: '',
};

const advertisingAndPromotions = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("isListedOnREA",getDetails.isListedOnREA)
          setFieldValue("isListedOnDomain",getDetails.isListedOnDomain)
          setFieldValue("SignboardOutsideProperty",getDetails.signboardOutsideProperty)
          setFieldValue("PropertyPhotos",getDetails.propertyPhotos)
          setFieldValue("FloorPlans",getDetails.floorPlans)
          setFieldValue("SocialMediaCampaign",getDetails.socialMediaCampaign)
          setFieldValue("Inspection",getDetails.inspection)
          setFieldValue("WithoutRisk",getDetails.withoutRisk)
          setFieldValue("WithDefects",getDetails.withDefects)
          setFieldValue("DisclosureSourceRebate1",getDetails.disclosureSourceRebate1)
          setFieldValue("DisclosureEstimateRebate1",getDetails.disclosureEstimateRebate1)
          setFieldValue("DisclosureSourceRebate2",getDetails.disclosureSourceRebate2)
          setFieldValue("DisclosureEstimateRebate2",getDetails.disclosureEstimateRebate2)
          setFieldValue("WaiverOfCoolinOffPeriod",getDetails.waiverOfCoolinOffPeriod)
          setFieldValue("WasAgreementProvidedPrincipal",getDetails.wasAgreementProvidedPrincipal)
          let dateToSet = getDetails.dateProvided !== null ? formatDate(getDetails.dateProvided) : formatDate(new Date());
          setFieldValue("DateProvided", dateToSet);
        }
      }, [getDetails, reportTo]);

      function formatDate(date) {
        if (!date) {
            date = new Date();
        } else if (!(date instanceof Date)) {
            date = new Date(date);
            if (isNaN(date.getTime())) {
                date = new Date();
            }
        }
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    


      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: prominantalActivities,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            IsListedOnREA: values.isListedOnREA,
            IsListedOnDomain: values.isListedOnDomain,
            SignboardOutsideProperty: values.SignboardOutsideProperty,
            PropertyPhotos: values.PropertyPhotos,
            FloorPlans: values.FloorPlans,
            SocialMediaCampaign: values.SocialMediaCampaign,
            Inspection: values.Inspection,
            WithoutRisk: values.WithoutRisk ? 1 : 2,
            WithDefects: values.WithDefects ? 1:2,
            DisclosureSourceRebate1: values.DisclosureSourceRebate1,
            DisclosureEstimateRebate1: parseInt(values.DisclosureEstimateRebate1),
            DisclosureSourceRebate2: values.DisclosureSourceRebate2,
            DisclosureEstimateRebate2: parseInt(values.DisclosureEstimateRebate2),
            WaiverOfCoolinOffPeriod: values.WaiverOfCoolinOffPeriod ? 1 : 2,
            WasAgreementProvidedPrincipal: values.WasAgreementProvidedPrincipal ? 1 : 2,
            DateProvided: values.DateProvided,
        };
        const url = updatesalesagrmntpromotiondetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("AgreementSignature")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };


    const handleClear = () => {
        handleNextPage("ExpensesAndCharges")
    };

    const onChangeSwitch = (e, label) => {
        if (label === "isListedOnREA") {
          setFieldValue("isListedOnREA", e);
        } else if (label === "isListedOnDomain") {
          setFieldValue("isListedOnDomain", e);
        }
        else if (label === "SignboardOutsideProperty") {
            setFieldValue("SignboardOutsideProperty", e);
          }
          else if (label === "WithoutRisk") {
            setFieldValue("WithoutRisk", e);
          }
          else if (label === "WithDefects") {
            setFieldValue("WithDefects", e);
          }
          else if (label === "WaiverOfCoolinOffPeriod") {
            setFieldValue("WaiverOfCoolinOffPeriod", e);
          }
          else if (label === "WasAgreementProvidedPrincipal") {
            setFieldValue("WasAgreementProvidedPrincipal", e);
          }
          else if (label === "PropertyPhotos") {
            setFieldValue("PropertyPhotos", e);
          } else if (label === "FloorPlans") {
            setFieldValue("FloorPlans", e);
          } else if (label === "SocialMediaCampaign") {
            setFieldValue("SocialMediaCampaign", e);
          }
      };

      const handleCancle = () => {
        navigate("/agency/ListAgreement");
      }

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                            
                                        <div style={{width:"100%"}}>
                                                  <Collapse
                                                id={"Agreement-Authority"}
                                                title={"ADVERTISING AND PROMOTION"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className='s1 pl-3'>Manner of Advertising and Promotion:</div>
                                                        <div className="pddpn-group">
                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Property Photos</div>
                                                                <div><Switch checked={values.PropertyPhotos} onChange={(e) => onChangeSwitch(e, "PropertyPhotos")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-3 s1">Floor Plans</div>
                                                                <div><Switch checked={values.FloorPlans} onChange={(e) => onChangeSwitch(e, "FloorPlans")}/></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' }} className="mr-5 s1">Social Media Campaign</div>
                                                                <div><Switch  checked={values.SocialMediaCampaign} onChange={(e) => onChangeSwitch(e, "SocialMediaCampaign")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Listing on Realestate.com.au</div>
                                                                <div><Switch checked={values.isListedOnREA} onChange={(e) => onChangeSwitch(e, "isListedOnREA")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Listing on Domain.com.au</div>
                                                                <div><Switch checked={values.isListedOnDomain} onChange={(e) => onChangeSwitch(e, "isListedOnDomain")} /></div>
                                                            </div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Signboard Outside the property</div>
                                                                <div><Switch checked={values.SignboardOutsideProperty} onChange={(e) => onChangeSwitch(e, "SignboardOutsideProperty")} /></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                } />
                                                    <Collapse
                                                id={"INSPECTION OF PROPERTY"}
                                                title={"INSPECTION OF PROPERTY"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            label="Inspection may be conducted in the following circumstances"
                                                            name="Inspection"
                                                            value={values.Inspection}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.Inspection}
                                                            touched={touched.Inspection}
                                                        />
                                                        <div className="mb-3">
                                                            <div className="s1 pl-3">The Principal acknowledges, so far as reasonably practicable, that the principal has thoroughly inspected the Property prior to sale and that the Property is:</div>
                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Without risk to health and safety</div>
                                                                <div><Switch checked={values.WithoutRisk} onChange={(e) => onChangeSwitch(e, "WithoutRisk")} /></div>
                                                            </div>
                                                            <div style={{ marginRight: '50px' }} className="s1 pl-3">OR</div>

                                                            <div className="pd-eventDate col-lg-5 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{width: '100%' }} className="mr-5 s1">Subject to the defects and risks outlined in the contract for sale</div>
                                                                <div><Switch checked={values.WithDefects} onChange={(e) => onChangeSwitch(e, "WithDefects")} /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                } />
                                                <Collapse
                                                id={"INSPECTION OF PROPERTY"}
                                                title={"DISCLOSURE OF REBATES, DISCOUNTS AND COMMISSIONS"}
                                                data={
                                                  <div>
                                                      <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            label="Name of Source of Rebate:"
                                                            name="DisclosureSourceRebate1"
                                                            value={values.DisclosureSourceRebate1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.DisclosureSourceRebate1}
                                                            touched={touched.DisclosureSourceRebate1}
                                                        />
                                                  <TextBox
                                                            label="Estimated Amount of Rebates, Discounts or Commissions:"
                                                            name="DisclosureSourceRebate2"
                                                            value={values.DisclosureSourceRebate2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.DisclosureSourceRebate2}
                                                            touched={touched.DisclosureSourceRebate2}
                                                        />
                                                  <TextBox
                                                            //label="Inspection may be conducted in the following circumstances"
                                                            name="DisclosureEstimateRebate1"
                                                            value={values.DisclosureEstimateRebate1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.DisclosureEstimateRebate1}
                                                            touched={touched.DisclosureEstimateRebate1}
                                                        />
                                                  <TextBox
                                                            //label="Inspection may be conducted in the following circumstances"
                                                            name="DisclosureEstimateRebate2"
                                                            value={values.DisclosureEstimateRebate2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.DisclosureEstimateRebate2}
                                                            touched={touched.DisclosureEstimateRebate2}
                                                        />
                                                    </div>
                                                    <div className="s1 pl-5">COOLING-OFF PERIOD: You (the vendor) have a cooling-off period for this agreement. If you do not wish to continue with this agreement you can cancel it until 5pm on the next business day or Saturday. How to do this, you should refer to the guide given to you before signing the Agreement.</div>
                                                    <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%' , marginLeft: "31px"}} className="mr-2 s1">WAIVER OF COOLING OFF PERIOD</div>
                                                                <div><Switch checked={values.WaiverOfCoolinOffPeriod} onChange={(e) => onChangeSwitch(e, "WaiverOfCoolinOffPeriod")} /></div>
                                                            </div>
                                                        <div className="s1 mt-2 pl-5">The licensee and the principal/s acknowledge and confirm that before signing this agreement the licensee and The principal/s have read and understood and agree to the terms and conditions in part 3 on the reverse side of this agreement.</div>
                                                        <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '100%', marginLeft: "31px" }} className="mr-3 s1">The approved guide “Agency Agreement for the Sale of Residential Property” was provided to the Principal:</div>
                                                                <div><Switch checked={values.WasAgreementProvidedPrincipal} onChange={(e) => onChangeSwitch(e, "WasAgreementProvidedPrincipal")} /></div>
                                                            </div>
                                                            <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                            <TextBox
                                                            type="Date"
                                                            label="Date Provided:"
                                                            name="DateProvided"
                                                            value={values.DateProvided}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.DateProvided}
                                                            touched={touched.DateProvided}
                                                        />
                                                           </div> 
                                                       
                                                  </div>
                                                } />

                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className=" pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default advertisingAndPromotions;
