import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updateTenantsInAgreement } from "../../../components/CommonSection/apiresources";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import AddressInput from "../../input-components/address";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import Collapse from "../../input-components/collapse";
import { managementAgreement } from "../../../components/CommonSection/Schema/schema";
import "./tenantAgreement.css";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";

dayjs.extend(customParseFormat);

const initialValues = {
    primaryfName: '',
    primarylName: '',
    primaryMobileNum: '',
    primaryEmail: '',
    getAgentFullName: '',
    agencyLicenceNumber: '',
    agencyAddress: '',
    mobileNumber: '',
    agentEmail: '',
    address: '',
    fax: '',
    Name:'',
    BusinessName : '',
    LicenseNumber:'',
    Address:'',
    Mobile:'',
    emailAddress:'',
    agentName:''
};


const PropertyOwners = ({ getDetails, handleNextPage1, onDataRecive1 }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [activeTopic, setActiveTopic] = useState("AddOpenHome");
    const [topicValue, setTopicValue] = useState("AddOpenHome");
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);
    const [address, setAddress] = useState("");
    const [secondaryOwner, setSecondaryOwner] = useState([])
    const [userId, setUserId] = useState("");
    const [primaryContact, setPrimaryContact] = useState({
        TenantFname: '',
        TenantLname: '',
        TenantEmail: '',
    })
    const [tenantMain, setTenantMain] = useState({
        tenantId: 0,
        tenantFname: '',
        tenantLname: '',
        tenantEmail: '',
        tenantMobile:''
    })
    const [agencyID, setAgencyId] = useState("");

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");

                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setAgencyId(parsedData.agencyID);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    useEffect(() => {
        if (getDetails) {
            if (getDetails.primaryContact === null) {
                ToastRef.current.notify(["error", "Primary contact is required."]);
            }
    
            setFieldValue("primaryfName", getDetails.primaryContact ? getDetails.primaryContact.fName : '');
            setFieldValue("primarylName", getDetails.primaryContact ? getDetails.primaryContact.lName : '');
            setFieldValue("primaryMobileNum", getDetails.primaryContact ? getDetails.primaryContact.mobileNumber : '');
            setFieldValue("primaryEmail", getDetails.primaryContact ? getDetails.primaryContact.contactEmail : '');
            setFieldValue("getAgentFullName", getDetails.getAgentFullName);
            setFieldValue("companyName", getDetails.companyName);
            setFieldValue("agencyLicenceNumber", getDetails.agencyLicenceNumber);
            setFieldValue("agencyAddress", getDetails.agencyAddress);
            setFieldValue("agentEmail", getDetails.agentEmail);
            setFieldValue("fax", getDetails.fax);
            setFieldValue("Name", getDetails.principalName);
            setFieldValue("BusinessName", getDetails.tradingName);
            setFieldValue("LicenseNumber", getDetails.agencyLicenceNumber);
            setFieldValue("Address", getDetails.agencyAddress);
            setFieldValue("Mobile", getDetails.mobile);
            setFieldValue("fax", getDetails.fax);
            setFieldValue("emailAddress", getDetails.agencyEmail);
            setFieldValue("agentName", getDetails.agencyName);
    
            let mainTenant = getDetails.tenants && getDetails.tenants.length > 0 ? getDetails.tenants[0] : null;
            let secondaryTenants = getDetails.tenants && getDetails.tenants.length > 1 ? getDetails.tenants.slice(1) : [];
    
            if (mainTenant) {
                setTenantMain({
                    tenantId: mainTenant.tenantId,
                    tenantFname: mainTenant.tenantFname,
                    tenantLname: mainTenant.tenantLname,
                    tenantEmail: mainTenant.tenantEmail,
                    tenantMobile: mainTenant.tenantMobile,
                });
            } else {
                setTenantMain([]); // or an appropriate default value
            }
    
            if (secondaryTenants.length > 0) {
                setSecondaryOwner(secondaryTenants.map(tenant => ({
                    tenantId: tenant.tenantId,
                    tenantFname: tenant.tenantFname,
                    tenantLname: tenant.tenantLname,
                    tenantEmail: tenant.tenantEmail,
                    tenantMobile: tenant.tenantMobile,
                })));
            } else {
                setSecondaryOwner([]); // or an appropriate default value
            }
        }
    }, [getDetails]);
    
    


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
        setFieldTouched
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: managementAgreement,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const validate = async () => {
        Object.keys(values).forEach((field) => {
            setFieldTouched(field, true);
        });
        const errors = await validateForm();
        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with your logic
            handleFinalSubmit();
        } else {
            // Validation errors exist, handle them as needed
            console.log("Validation errors:", errors);
            // You can also display errors to the user
        }
    };


    const handleFinalSubmit = () => {        
        // Filter out null or invalid tenant objects
        const validSecondaryOwner = secondaryOwner.filter(owner => owner !== null && owner !== undefined);
        
        // Add the primary tenant to the list
        validSecondaryOwner.unshift(tenantMain);
    
        // Prepare the form data
        let formData = {
            AgreementId: getDetails.agreeementId,
            AgencyId: agencyID,
            AgentId: getDetails.agentId,
            PropertyId: getDetails.propertyId,
            TenantList: validSecondaryOwner,
        };
    
        // Check for missing fields
        const hasMissingFields = validSecondaryOwner.some(owner => !owner.tenantEmail || !owner.tenantMobile || !owner.tenantFname || !owner.tenantLname);
    
        if (hasMissingFields) {
            ToastRef.current.notify(["error", "Please fill the required fields in Tenant."]);
        } else {
            const url = updateTenantsInAgreement;
            makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
                .then((returnData) => {
                    if (returnData.status === ActionStatus.Successfull) {
                        handleNextPage1("LandlordDetails");
                        onDataRecive1(getDetails.propertyUId);
                        ToastRef.current.notify(["success", returnData.message]);
                    } else {
                        ToastRef.current.notify(["error", returnData.message]);
                    }
                })
                .catch((error) => {
                    ToastRef.current.notify(["error", error.message]);
                });
        }
    };
    


    const handleView = () => {
        setViewAddressDetails(!viewAddressDetails);
    };

    const handleDeleteOwner = (index) => {
        const updatedContactList = secondaryOwner.filter((_, i) => i !== index);
        setSecondaryOwner(updatedContactList);
    };

    const handleAddNewOwnership = () => {
        debugger;
        const newOwnership = {
            tenantFname: '',
            tenantLname: '',
            tenantEmail: '',
            tenantMobile:''
        };
        const updatedContactList = [...secondaryOwner, newOwnership];
        setSecondaryOwner(updatedContactList);
    };

    const handleChange1 = (e, index) => {
        const { name, value } = e.target;
        const updatedSecondaryOwner = [...secondaryOwner];
        updatedSecondaryOwner[index] = { ...updatedSecondaryOwner[index], [name]: value };
        setSecondaryOwner(updatedSecondaryOwner);
        setFieldValue("ContactlistModel", updatedSecondaryOwner)

        managementAgreement
            .validateAt(name, updatedSecondaryOwner[index])
            .then(() => {
                // No validation errors
                console.log("No validation errors");
            })
            .catch((error) => {
                // Validation error
                console.error("Validation error:", error.message);
            });
    };

    const handleChangePrimary = (e) => {
        const { name, value } = e.target;
        setTenantMain(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="">
                                <div className="property-details-content df-all all-input">
                                    <div>
                                        <div className="partiesAgreement">Parties Principal</div>
                                        <Collapse
                                            id={"Property_Owners"}
                                            title={`Primary Property Owner(s)`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="First Name"
                                                        name={`primaryfName`} // Use a dynamic name to differentiate between input fields
                                                        value={values.primaryfName}
                                                        //onChange={handleChangePrimary} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.primaryfName}
                                                        touched={touched.primaryfName}
                                                    />
                                                    <TextBox
                                                        label="Last Name"
                                                        name="primarylName"
                                                        value={values.primarylName}
                                                        //onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.primarylName}
                                                        touched={touched.primarylName}
                                                    />
                                                    <TextBox
                                                        label="Mobile"
                                                        placeholder="Mobile Number"
                                                        name="primaryMobileNum"
                                                        value={values.primaryMobileNum}
                                                        //onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.primaryMobileNum}
                                                        touched={touched.primaryMobileNum}
                                                        icon={
                                                            <BiPhone height="100%" width="100%" />
                                                        }
                                                    />
                                                    <TextBox
                                                        label="Email address"
                                                        placeholder="Email"
                                                        name="primaryEmail"
                                                        value={values.primaryEmail}
                                                        //onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.contactEmail}
                                                        touched={touched.contactEmail}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                       {getDetails ? getDetails.contactlistModel.map((contact, index) => (<Collapse
                                                        id={"Property_Owners"}
                                                        title={`Secondary Onwer ${index + 2}`}
                                                        data={
                                                            <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                                <TextBox
                                                                    label="First Name"
                                                                    name={`fName`} // Use a dynamic name to differentiate between input fields
                                                                    value={contact ? contact.fName : null}
                                                                    onChange={(e) => handleChange1(e, index)} // Pass the field name to handleChange
                                                                    onBlur={handleBlur}
                                                                    error={errors.fName}
                                                                    touched={touched.fName}
                                                                />
                                                                <TextBox
                                                                    label="Last Name"
                                                                    name="tenantLname"
                                                                    value={contact ? contact.lName :null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantLname}
                                                                    touched={touched.tenantLname}
                                                                />
                                                                <TextBox
                                                                    label="Mobile"
                                                                    placeholder="Mobile Number"
                                                                    name="tenantMobile"
                                                                    value={contact ? contact.mobileNumber: null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantMobile}
                                                                    touched={touched.tenantMobile}
                                                                    icon={
                                                                        <BiPhone height="100%" width="100%" />
                                                                    }
                                                                />

                                                                <TextBox
                                                                    label="Email address"
                                                                    placeholder="Email"
                                                                    name="tenantEmail"
                                                                    value={contact ? contact.contactEmail:null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantEmail}
                                                                    touched={touched.tenantEmail}
                                                                    icon={
                                                                        <BiEnvelope height="100%" width="100%" />
                                                                    }
                                                                />
                                                            </div>
                                                        } />)) : null}
                                                </div>
                                            } />
                                        <Collapse
                                            id={"tenant"}
                                            title={`Tenant`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                    isMandatory
                                                        label="First Name"
                                                        name={`tenantFname`} // Use a dynamic name to differentiate between input fields
                                                        value={tenantMain?.tenantFname}
                                                        onChange={handleChangePrimary} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.tenantFname}
                                                        touched={touched.tenantFname}
                                                    />
                                                    <TextBox
                                                    isMandatory
                                                        label="Last Name"
                                                        name="tenantLname"
                                                        value={tenantMain?.tenantLname}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.tenantLname}
                                                        touched={touched.tenantLname}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Mobile"
                                                        placeholder="Mobile Number"
                                                        name="tenantMobile"
                                                        value={tenantMain?.tenantMobile}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.tenantMobile}
                                                        touched={touched.tenantMobile}
                                                        icon={
                                                            <BiPhone height="100%" width="100%" />
                                                        }
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Email address"
                                                        placeholder="Email"
                                                        name="tenantEmail"
                                                        value={tenantMain?.tenantEmail}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.tenantEmail}
                                                        touched={touched.tenantEmail}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                    <div className="property-details-buttons">
                                                        <button
                                                            className="pdb-cancel"
                                                            type="button"
                                                            onClick={handleAddNewOwnership}
                                                        >
                                                            Add New Tenant
                                                        </button>
                                                    </div>
                                                    {secondaryOwner ? secondaryOwner.map((contact, index) => (<Collapse
                                                        id={"Property_Owners"}
                                                        title={`Tenant ${index + 2}`}
                                                        data={
                                                            <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                                <TextBox
                                                                isMandatory
                                                                    label="First Name"
                                                                    name={`tenantFname`} // Use a dynamic name to differentiate between input fields
                                                                    value={contact ? contact.tenantFname : null}
                                                                    onChange={(e) => handleChange1(e, index)} // Pass the field name to handleChange
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantFname}
                                                                    touched={touched.tenantFname}
                                                                />
                                                                <TextBox
                                                                isMandatory
                                                                    label="Last Name"
                                                                    name="tenantLname"
                                                                    value={contact ? contact.tenantLname :null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantLname}
                                                                    touched={touched.tenantLname}
                                                                />
                                                                <TextBox
                                                                    isMandatory
                                                                    label="Mobile"
                                                                    placeholder="Mobile Number"
                                                                    name="tenantMobile"
                                                                    value={contact ? contact.tenantMobile: null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantMobile}
                                                                    touched={touched.tenantMobile}
                                                                    icon={
                                                                        <BiPhone height="100%" width="100%" />
                                                                    }
                                                                />

                                                                <TextBox
                                                                isMandatory
                                                                    label="Email address"
                                                                    placeholder="Email"
                                                                    name="tenantEmail"
                                                                    value={contact ? contact.tenantEmail:null}
                                                                    onChange={(e) => handleChange1(e, index)}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tenantEmail}
                                                                    touched={touched.tenantEmail}
                                                                    icon={
                                                                        <BiEnvelope height="100%" width="100%" />
                                                                    }
                                                                />
                                                                <div className="property-details-buttons">
                                                                    <button className="pdb-save-changes" type="button" onClick={() => handleDeleteOwner(index)}>Delete</button>
                                                                </div>
                                                            </div>
                                                        } />)) : null}

                                                </div>
                                            } />

                                        <Collapse
                                            id={"Agent"}
                                            title={"Agent"}
                                            data={<div>

                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        label="Name"
                                                        name={`getAgentFullName`} // Use a dynamic name to differentiate between input fields
                                                        value={values.Name}
                                                        //onChange={(e) => handleChange1(e, index)} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.getAgentFullName}
                                                        touched={touched.getAgentFullName}
                                                    />
                                                    <TextBox
                                                        label="Business Name"
                                                        name="companyName"
                                                        value={values.BusinessName}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.companyName}
                                                        touched={touched.companyName}
                                                    />
                                                    <TextBox
                                                        label="License Number"
                                                        name="agencyLicenceNumber"
                                                        value={values.LicenseNumber}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.agencyLicenceNumber}
                                                        touched={touched.agencyLicenceNumber}
                                                    />

                                                    <AddressInput
                                                    labelName="Address"
                                                        address={values.Address}
                                                        setAddress={setAddress}
                                                        //handlePressSelect={handlepressSelect}
                                                        //error={errors.address}
                                                        //touched={touched.address}
                                                        isMandatory
                                                        handleView={handleView}
                                                    />
                                                    <TextBox
                                                        label="Mobile"
                                                        placeholder="Mobile Number"
                                                        name="mobileNumber"
                                                        value={values.Mobile}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.mobileNumber}
                                                        touched={touched.mobileNumber}
                                                        icon={
                                                            <BiPhone height="100%" width="100%" />
                                                        }
                                                    />
                                                    <TextBox
                                                        label="fax"
                                                        placeholder=""
                                                        name="fax"
                                                        value={values.fax}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.fax}
                                                        touched={touched.fax}

                                                    />

                                                    <TextBox
                                                        label="Email address"
                                                        placeholder="Email"
                                                        name="agentEmail"
                                                        value={values.emailAddress}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.agentEmail}
                                                        touched={touched.agentEmail}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                    <TextBox
                                                        label="Agent Name"
                                                        placeholder=""
                                                        name="mobileNumber"
                                                        value={values.agentName}
                                                        //onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.mobileNumber}
                                                        touched={touched.mobileNumber}

                                                    />

                                                </div>
                                            </div>

                                            } />
                                    </div>

                                </div>
                                <div className="property-details-buttons">
                                    <button className="pdb-save-changes" type="button" onClick={validate}>
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyOwners;
