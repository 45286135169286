import React, { useState } from "react";
import { FilePath, defaultProfilePic } from "../../components/CommonSection/apiresources";
import { BiUpload } from "react-icons/bi";
import { Tooltip } from 'antd';


const DocumentUpload = ({
  imageSrc,
  inputRef,
  handleFileChange,
  onClickHandler,
  isCircle,
  isPersonalImage,
  label,
  isMandatory = false,
  disableStyle
}) => {
  const imageSrcUrl = FilePath + (imageSrc != null ? imageSrc : defaultProfilePic);
  const [show, setShow] = useState(false);

  const showOverlay = () => {
    setShow(true);
  };

  const hideOverlay = () => {
    setShow(false);
  };

  return (
    <div className="col-lg-12 col-sm-12">
      <label className='s1'>
        {label} {isMandatory ? <span style={{color:'red'}}>*</span> : null }
      </label>
      <div style={{display:'flex'}} className={disableStyle === false ? '' : `sub-upload-section col-lg-3 col-sm-12 file-container`}>
        <div className={disableStyle === false ? '' : `documents-comp col-lg-10 col-sm-12 ${isCircle ? 'circle' : ''}`}>
          <div
            onClick={onClickHandler}
            onMouseOver={showOverlay}
            onMouseLeave={hideOverlay}
            style={{ cursor: 'pointer' }}
          >
            <div style={{display:'flex' , justifyContent:'center'}}><img  src="../../../assets/images/upload.png" alt="upload-icon" className="upload-icon"/>
            <span className="mt-2 ml-2" style={{ color: "#0097a7", fontWeight: '500' }}>Click to upload {disableStyle === false ? <span className="s1">or drag and drop</span> : null}
            {disableStyle === false ?  <div className="s1"> PNG, JPG </div> : null }
               {disableStyle === undefined ?
               <Tooltip title="Only .PND, .JPG file is Accepted">
                  <span>
                    <i className="fa fa-info mt-1 ml-3"></i>
                  </span>
                </Tooltip> : null }
            </span>
            {/* <span className="mt-2" style={{ color: "#b3b0b0" }}>or drag and drop</span> */}
            {/* <div className="mt-2" style={{ color: "#b3b0b0" }}>PND, JPG</div> */}
            </div>
          </div>
          <input
            ref={inputRef}
            type="file"
            onChange={(e) => handleFileChange(e, 2)}
            className="upload-input"
            name="upload"
            hidden
            multiple
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
