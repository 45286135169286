import React from "react";
import { useEffect, useState } from "react";
import "./propertiesagency.css";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { useFormik } from "formik";
import { addPropertyLegal } from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import Collapse from "../../../../src/input-components/collapse";
import AddressInput from "../../../../src/input-components/address";
import { PropertyLegalSchema } from "../../../CommonSection/Schema/schema";
import { FaEdit } from "react-icons/fa";
import { Space } from "antd";
import ShowValue from "../../../../src/input-components/ShowValue";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import { useNavigate } from "react-router-dom";

const initialValues = {
  sellerName: "",
  sellerPrincipalName: "",
  sellerEmail: "",
  sellerAddress: "",
  sellerNumber: "",
  sellerFax: "",
  buyerName: "",
  buyerPrincipalName: "",
  buyerEmail: "",
  buyerAddress: "",
  buyerNumber: "",
  buyerFax: "",
};

const PropertyLegal = ({
  handleparentsuccess,
  getDetails,
  handleCurrentPage1
}) => {
  const [sellerAddress, setSellerAddress] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [userId, setUserId] = useState("");
  const [edit, setEdit] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setAgencyUID(parsedData.agencyUniqueID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setFieldValue("sellerName", getDetails.propertySellerFirmName);
      setFieldValue(
        "sellerPrincipalName",
        getDetails.propertySellerPrincipalName
      );
      setFieldValue("sellerAddress", getDetails.propertySellerAddress);
      setFieldValue("sellerNumber", getDetails.propertySellerPhone);
      setFieldValue("sellerFax", getDetails.propertySellerFax);
      setFieldValue("sellerEmail", getDetails.propertySellerEmail);
      setSellerAddress(getDetails.propertySellerAddress);

      setFieldValue("buyerName", getDetails.propertyBuyerFirmName);
      setFieldValue(
        "buyerPrincipalName",
        getDetails.propertyBuyerPrincipalName
      );
      setFieldValue("buyerAddress", getDetails.propertyBuyerAddress);
      setFieldValue("buyerNumber", getDetails.propertyBuyerPhone);
      setFieldValue("buyerFax", getDetails.propertyBuyerFax);
      setFieldValue("buyerEmail", getDetails.propertyBuyerEmail);
      setBuyerAddress(getDetails.propertyBuyerAddress);
      setPropertyId(getDetails.propertyId);
      if (getDetails.propertySellerFirmName === null && getDetails.propertyBuyerFirmName === null) {
        setEdit(true)
      }
    }
  }, [getDetails]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: PropertyLegalSchema,
    onSubmit: (values, action) => {
      values.sellerAddress = sellerAddress;
      values.buyerAddress = buyerAddress;
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: propertyId ? propertyId : 0,
      PropertySellerFirmName: values.sellerName,
      PropertySellerPrincipalName: values.sellerPrincipalName,
      PropertySellerEmail: values.sellerEmail,
      PropertySellerPhone: values.sellerNumber,
      PropertySellerFax: values.sellerFax,
      PropertySellerAddress: values.sellerAddress,
      PropertyBuyerFirmName: values.buyerName,
      PropertyBuyerPrincipalName: values.buyerPrincipalName,
      PropertyBuyerEmail: values.buyerEmail,
      PropertyBuyerPhone: values.buyerNumber,
      PropertyBuyerFax: values.buyerFax,
      PropertyBuyerAddress: values.buyerAddress,
      UserId: userId,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addPropertyLegal,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleCurrentPage1("Overview")
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleSellerAddress = (selectedAddress) => {
    setSellerAddress(selectedAddress);
  };

  const handleByuerAddress = (selectedAddress) => {
    setBuyerAddress(selectedAddress);
  };
  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage1("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  };

  return (
    <div>
      <div
        className="handle-Editpart">
        <a onClick={() => handleEdit()}>
          <Space>
            <FaEdit style={{ fontSize: "24px" }} />
          </Space>
        </a>
      </div>
      {
        edit ? (
          <form onSubmit={handleSubmit} className="details-subform">
            <div className="property-details-content df-all all-input">
              <Collapse
                id={"Seller"}
                title={"Seller Solicitor/Conveyancer Details"}
                data={
                  <div className="contacts-group" style={{ padding: "0px 30px" }}>
                    <TextBox
                      placeholder="Name of the Firm"
                      name="sellerName"
                      value={values.sellerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.sellerName}
                      touched={touched.sellerName}
                    />
                    <TextBox
                      placeholder="Principal's Name"
                      name="sellerPrincipalName"
                      value={values.sellerPrincipalName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.sellerPrincipalName}
                      touched={touched.sellerPrincipalName}
                    />
                    <TextBox
                      placeholder="Email address"
                      name="sellerEmail"
                      value={values.sellerEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.sellerEmail}
                      touched={touched.sellerEmail}
                      icon={<BiEnvelope height="100%" width="100%" />}
                    />
                    <TextBox
                      placeholder="Phone"
                      name="sellerNumber"
                      value={values.sellerNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.sellerNumber}
                      touched={touched.sellerNumber}
                      icon={<BiPhone height="100%" width="100%" />}
                    />
                    <TextBox
                      placeholder="Fax"
                      name="sellerFax"
                      value={values.sellerFax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.sellerFax}
                      touched={touched.sellerFax}
                    />
                    <AddressInput
                      address={sellerAddress}
                      setAddress={setSellerAddress}
                      handlePressSelect={handleSellerAddress}
                      error={errors.sellerAddress}
                      touched={touched.sellerAddress}
                    />
                  </div>
                }
              />
              <Collapse
                id={"Buyer"}
                title={"Buyer Solicitor/Convyencer Details"}
                data={
                  <div className="contacts-group" style={{ padding: "0px 30px" }}>
                    <TextBox
                      placeholder="Name of the Firm"
                      name="buyerName"
                      value={values.buyerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.buyerName}
                      touched={touched.buyerName}
                    />
                    <TextBox
                      placeholder="Principal's Name"
                      name="buyerPrincipalName"
                      value={values.buyerPrincipalName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.buyerPrincipalName}
                      touched={touched.buyerPrincipalName}
                    />
                    <TextBox
                      placeholder="Email address"
                      name="buyerEmail"
                      value={values.buyerEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.buyerEmail}
                      touched={touched.buyerEmail}
                      icon={<BiEnvelope height="100%" width="100%" />}
                    />
                    <TextBox
                      placeholder="Phone"
                      name="buyerNumber"
                      value={values.buyerNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.buyerNumber}
                      touched={touched.buyerNumber}
                      icon={<BiPhone height="100%" width="100%" />}
                    />
                    <TextBox
                      placeholder="Fax"
                      name="buyerFax"
                      value={values.buyerFax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.buyerFax}
                      touched={touched.buyerFax}
                    />
                    <AddressInput
                      address={buyerAddress}
                      setAddress={setBuyerAddress}
                      handlePressSelect={handleByuerAddress}
                    />
                  </div>
                }
              />
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() =>
                  handleCancel()
                }
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
        ) : (

          <div className="details-form">

            <div className="contacts-group">
              <div className="Heading">
                <strong>Seller Solicitor/Conveyancer Details :</strong>
              </div>
              <ShowValue Label='Name of the Firm' setData={values.sellerName} />
              <ShowValue Label="Principal's Name" setData={values.sellerPrincipalName} />
              <ShowValue Label='Email address' setData={values.sellerEmail} />
              <ShowValue Label='Phone' setData={values.sellerNumber} />
              <ShowValue Label='Fax' setData={values.sellerFax} />
              <ShowValue Label='Address' setData={sellerAddress} />
              <div className="Heading">
                <strong>Buyer Solicitor/Convyencer Details :</strong>
              </div>
              <ShowValue Label='Name of the Firm' setData={values.buyerName} />
              <ShowValue Label="Principal's Name" setData={values.buyerPrincipalName} />
              <ShowValue Label='Email address' setData={values.buyerEmail} />
              <ShowValue Label='Phone' setData={values.buyerNumber} />
              <ShowValue Label='Fax' setData={values.buyerFax} />
              <ShowValue Label='Address' setData={buyerAddress} />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default PropertyLegal;
