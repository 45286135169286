import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { searchPropertyList } from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import OwnerShip from "./partiesPrinciples"
import "../../ManagementAgreement/agreement.css";

const initialValues = {
    propertyFor: "",

    propertyType: "",

    assignedTo: "",

    address: "",

    unitNumber: "",

    streetNumber: "",

    streetName: "",

    suburb: "",

    postCode: "",

    state: "",
};

const AddNewProperty = ({ onDataRecive, handleCurrentPage, getDetails, reportTo, handleNextPage }) => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [agencyUID, setAgencyUID] = useState("");
    const [showSelect, setShowSelect] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [option, setOption] = useState([]);
    const fetchRef = useRef(0);
    const [selectedValues, setSelectedValues] = useState([]);
    const [searchValue, setSearchValues] = useState("");
    const [displayOwner, setDisplayOwners] = useState(false)
    const navigate = useNavigate();

    const { showSpinner, hideSpinner } = useSpinnerContext();

    var url = new URL(window.location.href);

    useEffect(() => { }, [reportTo]);

    useEffect(() => {
        if (getDetails) {
            filteredDetailsList(getDetails.propertyId);
        }
    }, [getDetails, reportTo]);


    const {
        errors,

        values,

        touched,

        handleBlur,

        handleChange,

        handleSubmit,
        setValues,
        setFieldValue,
    } = useFormik({
        initialValues: initialValues,

        //validationSchema: PropertyDetailsSchema,

        onSubmit: (values, action) => {
            values.address = values.address;

            values.propertyFor = values.propertyFor;

            values.propertyType = values.propertyType;

            values.assignedTo = parseInt(selectedCity, 10);

            handleFinalSubmit1();
        },
    });


    const handleFinalSubmit1 = () => {
        handleCurrentPage("AgentAgreement");
    };

    useEffect(() => {
        agencyUIDRef.current = agencyUID;
    }, [agencyUID]);

    const agencyUIDRef = useRef(agencyUID);


    const filteredDetailsList = (propertyId) => {
        setShowSelect(false);
        const baseUrl = searchPropertyList;
        const data = {
            ids: propertyId ? propertyId : "",
        };
        makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            baseUrl,
            "post",
            data,
            "",
            false
        )
            .then((response) => {
                if (response.status === 0) {
                    setShowSelect(true);
                    let landingPageListData = response.list;
                    const transformedData = landingPageListData.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }));
                    setOption(transformedData);
                    const outputArray = transformedData.map((item) => item.label);
                    setSelectedValues(outputArray);
                    setDisplayOwners(true)
                } else {
                    alert(`Message: ${response.message}`);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleNextPage1 = (value) => {
        handleNextPage(value)
    }

    const handleCancle = () => {
        navigate("/agency/ListAgreement");
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                    {/* <div className="contacts-group addnewProperty pddpn-group3">
                        <div className="agreementLabel" >{`Property`}</div>
                        <div className={`lda-${name} `}>
                            <div className="address-container">
                                <div className="address-pair">
                                    <div className="labelssss ">Address</div>
                                    <div>:</div>
                                    <div className="valuessss1"> {selectedValues}</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                        <OwnerShip getDetails={getDetails} handleNextPage1={handleNextPage1} />
                    </div>
                </div>
                {!displayOwner ? <div className="property-details-buttons">
                    <button
                        className="pdb-cancel"
                        type="reset"
                        onClick={handleCancle}
                    >
                        Cancel
                    </button>
                    <button className="pdb-save-changes" type="submit">
                        Save & Next
                    </button>
                </div> : null}
            </form>
        </div>
    );
};

export default AddNewProperty;
