import React, { useMemo, useRef, useState, useEffect } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {  getTicketlist,addupdateTicket} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner,uploadToS3WithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import { BiSearch, BiPlus,BiEnvelope } from "react-icons/bi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { Paginator } from "primereact/paginator";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { ActionStatus } from "../../../Utilities/enum";
import { format } from "date-fns";
import CustomModal from "../../input-components/Modal";
import TextArea from "../../input-components/textarea";
import FileUpload from "../../input-components/fileUplode";

const ListTicket = () => {
  let selectedFile;
  const [listPortalEnquiry, setListPortalEnquiry] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agentUniqueID, setAgentUniqueId] = useState("");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListTicket");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValues] = useState("");
  const [protalVal, setProtalVal] = useState("");
  const [propertyTypeVal, setPropertyTypeVal] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [openTicketModal,setOpenTicketModal] = useState(false)
  const [propertyDocuments, setPropertyDocuments] = useState("");
  const ToastRef = useRef();
  const [currentPages, setCurrentPage] = useState(1);
  const [totalRow, setTotalRow] = useState(10);
  const [formData , setFormData] = useState({
    TicketType: "",
    TicketDetail: "",
    TicketHeading: ""
  })
  const eTicketType = [
    { value: 1, label: "Incident" },
    { value: 2, label: "Service Request" },
  ];


  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUniqueId(parsedData.agencyUniqueID);
          setAgentId(parsedData.agentId);
          setAgentUniqueId(parsedData.agentUniqueId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) GetTicketList();
  }, [userId]);

  const GetTicketList = () => {
    debugger
    const baseUrl = getTicketlist;
    let data = {
      AgencyId: agencyID,
      PageNo: currentPages,
      RecordsPerPage: totalRow,
      Search: searchText ? searchText : null,
      SortBy: "CreatedOn",
      SortOrder: "Desc",
      maxDate: enddate ? enddate : null ,
      minDate: startdate ? startdate : null
    };
    //data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length === 0) {
            setListPortalEnquiry([]);
          } else {
            let ticketData = response.list;
            let ticketList = ticketData
              .map((ticket) => ({
                Type: ticket.ticketType === 1 ? "Incident" : "Service Request",
                Heading: ticket.ticketHeading,
                Details: ticket.ticketDetail,
                CreatedDate: ticket.createdOn,
                Status: ticket.ticketStatus === 1 ? "Created" :ticket.ticketStatus === 2 ? "Accepted" :ticket.ticketStatus === 3 ? "Resolved" : "",
                ticketUid : ticket.ticketUniqueId
              }));
              console.log(ticketList,'ticketList')
            setListPortalEnquiry(ticketList);
          }
        } else {
          //ToastRef.current.notify(["error", "error"]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddupdateTicket = () => {
    const baseUrl = addupdateTicket;
    let data = {
      AgencyId: agencyID,
      TicketType: formData.TicketType,
      TicketDetail: formData.TicketDetail,
      TicketHeading: formData.TicketHeading,
      DocPath: propertyDocuments,
      CreatedBy: userId
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
            ToastRef.current.notify(["success", response.message]);
            GetTicketList();
            setOpenTicketModal(false)
        } else {
          //ToastRef.current.notify(["error", "error"]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleOpenModal = () => {
    setOpenTicketModal(true);
    setFormData(() => ({
        TicketType: "",
        TicketDetail: "",
        TicketHeading: ""
    }))
  }
const closeTicketModal = () => {
    setOpenTicketModal(false)
}

const handleFileChange = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload(indexforfiles);
    }
  };

  const handleUpload = async (indexforfiles) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "agencyticket/",
      "agencyticket",
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        ToastRef.current.notify(["success", "File uploaded successfully"]);
        setPropertyDocuments(returnData.Key);
      } else {
        ToastRef.current.notify(["error", "Error uploading file. Please try again."]);

      }
    });
  };

  const handleStartDate = (e, label) => {
    const selectedDate = e.target.value;
    setStartDate(selectedDate);
    
  };

  const handleEndDate = (e, label) => {
    const selectedDate = e.target.value;
    setEndDate(selectedDate);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prevState) => ({
        ...prevState,
        [name] : value,
    }))
  }

  const handleInputSelect = (e) => {
    const value = e.value;
    setFormData((prevState) => ({
        ...prevState,
        "TicketType" : value,
    }))
  }

  const handleRowClick = (rowData) => {
    console.log(rowData,'rowData')
      dynamicUrl(navigate, "ListTicketDetails" + "?ticketUID=" + rowData.data.ticketUid);
  };

  const handleDateFilter = () => {
    GetTicketList();
  }

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      List Tickets
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleOpenModal}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "18px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Report Incident 
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="overview-card-container-row1 ">
                  <TextBox
                    label="Start Date"
                    type={"date"}
                    colVal={2}
                    name="date"
                    value={startdate}
                    onChange={handleStartDate} // Handle changes to the selected date
                  />
                   <TextBox
                    label="End Date"
                    type={"date"}
                    colVal={2}
                    name="date"
                    value={enddate}
                    onChange={handleEndDate} // Handle changes to the selected date
                  />
                  <button
                  style={{ marginTop:'35px'}}
                    className="Add-Property-button ml-3"
                    type="button"
                    onClick={handleDateFilter}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Search
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label"></div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                <DataTable
                  ref={dataTableRef}
                  value={listPortalEnquiry}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  onRowClick={(e) => handleRowClick(e, e.data)}
                >
                  <Column
                    field="Type"
                    header="Type"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Heading"
                    header="Heading"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Details"
                    header="Details"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="CreatedDate"
                    header="Created At"
                    className="common-table-style"
                    headerClassName="centered-header"
                    body={(rowData) => (
                      <div>
                        {format(new Date(rowData.CreatedDate), "dd/MM/yyyy")}
                      </div>
                    )}
                  ></Column>
                  <Column
                    field="Status"
                    header="Status"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={totalPages}
                  rowsPerPageOptions={[10, 20, 50]}
                  onPageChange={handlePageChange}
                />
                  <CustomModal
                title="Add Ticket"
                width={600}
                closable={closeTicketModal}
                visible={openTicketModal}
                onCancel={closeTicketModal}
                modalData={
                  <div
                    style={{ marginLeft: "10px" }}
                  >
                   <SelectBox colVal={12} value={formData.TicketType} name="TicketType" label={"Type"} options={eTicketType} onChange={handleInputSelect}/>
                   <TextBox colVal={12} value={formData.TicketHeading} name="TicketHeading" label={"Heading"} onChange={handleInput}/>
                   <TextArea colVal={12} value={formData.TicketDetail} name="TicketDetail" label={"Description"} onChange={handleInput}/>
                   <FileUpload colVal={12} icon={<BiEnvelope height="100%" width="100%" />} onChange={(e) => handleFileChange(e)} />
                  </div>
                }
                footer={[
                  <div
                    key="footer"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{ marginRight: "20px" }}
                      className="pdb-save-changes mt-2"
                      onClick={AddupdateTicket}
                    >
                      Submit
                    </button>
                  </div>,
                ]}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTicket;
