import React from "react";
import { useEffect, useState, useRef } from "react";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import {resetEmail,generatePdfReport,resendcompletedagreement} from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { currentProfile,} from "../../../components/CommonSection/Cookie/Session";
import {makeRequestWithSpinner,DownloadFiletWithSpinner,} from "../../../Utilities/client";
import {useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../Utilities/enum";
import "../openHome/openHome.css";
import "../Task/task.css";
import moment from "moment";
import { format,parseISO  } from "date-fns";
import CustomModal from "../../input-components/Modal";
import TextBox from "../../input-components/textbox";
import {Divider} from "antd"
import { BiArrowBack } from "react-icons/bi";


const initialValues = {
  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
  PropertyImages: [],
  SignedDate: "",
  AgentSignedDate: "",
};

const Summary = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  let selectedFile;

  const [agreementlist, setAgreementList] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [displayTable, setDisplaytable] = useState(true);
  const navigate = useNavigate();
  const ToastRef = useRef();
  const dataTableRef = useRef(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const inputRef = useRef();
  const signatureRef = useRef(null);
  const [sign, setSign] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSigned, setIsSigned] = useState(false);
  const [isOwnerSigned, setIsOwnerSigned] = useState(false);
  const [isAgentSigned, setIsAgentSigned] = useState(false);
  const [date, setDate] = useState("");
  const [openReset,setOpenReset]  =  useState(false);
  const [email,setEmail]  =  useState(false);
  const [recipientId , setRecipientId] = useState("")
  
  // Function to clear the signature canvas
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    if (getDetails) {
      let filteredArray = [];
      if (getDetails.agreementStatus === 10) {
        filteredArray = getDetails.documentReceipients;
        setIsSigned(true);
      } else {
        filteredArray = getDetails.documentReceipients
          ? getDetails.documentReceipients.filter(
              (item) => item.contactId !== null
            )
          : [];
      }
      setIsAgentSigned(getDetails.isAgentSigned);
      setIsOwnerSigned(getDetails.isOwnerSigned);

      let templatesList = getDetails.documentReceipients.map((task) => ({
        id: task.id,
        Name: task.name,
        Email: task.emailAddress,
        Status: task.isSigned,
        ContactId: task.contactId,
        SignedDate: task.signedDate,
        IsSent : task.isSent,
        SentDate: task.sentDate,
        IsSigned : task.isSigned,
        ipAddress : task.ipAddress,
      }));
      setAgreementList(templatesList);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    const today = new Date();
    setDate(today.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);

  const currentSideBar = currentProfile.get();

  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.Status);
    return (
      <div className="status-container">
        <span className={"common-statusbtn" + " " + statusClassName}>
        {!rowData.IsSent ? "Awaiting to send" : rowData.Status ? "Completed" : "Awaiting Signature"}
        </span>
      </div>
    );
  };

  const historyBodyTemplate = (rowData) => {
    return (
      <div>
        <div style={{fontWeight:'600'}}> 
          {rowData.IsSent ?
             "Document sent Successfully" : null}
        </div>
        {rowData.IsSent ?
          <div >Sent On : {format(new Date(rowData.SentDate), "dd/MM/yyyy hh:mm aa")}</div> : null } 
           {rowData.IsSent ? <Divider style={{margin:"5px 0px"}}/> : null }
          <div style={{fontWeight:'600'}}>
          {rowData.IsSigned ?
             "Document signed Successfully" : null}
        </div>
        {rowData.IsSigned ? <div > Signed On : {format(new Date(rowData.SignedDate), "dd/MM/yyyy hh:mm aa")}</div> : null }
        {rowData.IsSigned ? <div > Ip Address : {rowData.ipAddress}</div> : null }
      </div>
    );
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case true:
        return "green-status";
      case false:
        return "orange-status";
      default:
        return "";
    }
  };

  const handleFinalSubmit = () => {
    let formData = {
      RecipientId: recipientId,
      EmailAddress: email,
    };
    const url = resetEmail;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setOpenReset(false);
          window.location.reload();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleResendAll = () => {
    let formData = {
      PropertyUniqueId: propertyUID,
      DocumentType: 8,
      DocumentId: getDetails.documentReceipients[0].documentId
    };
    const url = resendcompletedagreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          //window.location.reload();
          ToastRef.current.notify(["success", returnData.message]);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    navigate(`/agency/ListAgreement?Page=AgreementList`);
  };

  const handleAgreementDownload = () => {
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.IsSent && rowData.Status === false ? <div className="action-icons">
          <button className="pdb-save-changes" type="button" onClick={() => handleResend(rowData)}>
            Resend
          </button>
        </div> : null }
      </div>
    );
  };

  const handleResend = (data) => {
    setOpenReset(true)
    setEmail(data.Email)
    setRecipientId(data.id)
  }

  const closeReSet = () => {
    setOpenReset(false);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }



  return (
    <div className="dashboard-agency">
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
            <BiArrowBack onClick={handleClear} style={{cursor:'pointer'}}/>
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      Agreement Summary
                    </div>
                  </div>
                </div>
              </div>
              {isSigned ? (
                <div className="signTxt df-all">
                  <div>Document Signed Successfully</div>
                </div>
              ) : null}
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">List Of Contacts</div>
                  </div>
                </div>
                {displayTable ? (
                  <DataTable
                    ref={dataTableRef}
                    value={agreementlist}
                    size={"normal"}
                    tableStyle={{ minWidth: "20rem" }}
                    dataKey=""
                    rowClassName="custom-row"
                    paginator={false}
                  >
                    <Column
                      field="Name Email"
                      header="Name"
                      className="common-table-style"
                      headerClassName="centered-header"
                      body={(rowData) => (
                        <div className="name-column">
                          <div style={{fontWeight:'600'}}>
                            {rowData.ContactId
                              ? rowData.Name
                              : rowData.Name}
                          </div>
                          <div>
                            {rowData.ContactId
                              ? rowData.Email
                              : rowData.Email}
                          </div>
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="History"
                      header="History"
                      className="common-table-style"
                      headerClassName="centered-header"
                      body={historyBodyTemplate}
                    ></Column>
                    <Column
                      field="Status"
                      header="Status"
                      body={statusBodyTemplate}
                      className="common-table-style "
                      headerStyle={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    ></Column>
                     <Column
                    header="Actions"
                    body={actionBodyTemplate}
                    className="action-column right-align"
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                    style={{textAlign:'left'}}
                  ></Column>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
        title="Resend Email"
        width={500}
        closable={closeReSet}
        visible={openReset}
        onCancel={closeReSet}
        modalData={
          <div
            style={{ marginTop: "30px", marginLeft: "10px" }}
          >
           <TextBox label="Email" colVal={12} value={email} onChange={handleEmail}/>
          </div>
        }
        footer={[
          <div
            key="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ marginRight: "15px" }}
              className="pdb-save-changes mt-2"
              onClick={handleFinalSubmit}
            >
              Submit
            </button>
          </div>,
        ]}
      />
        {!isOwnerSigned || !isAgentSigned ? (
          <div className="property-details-buttons">
            <button className="pdb-cancel" type="reset" onClick={handleClear}>
              Cancel
            </button>
            <button
              className="pdb-save-changes"
              type="button"
              onClick={handleAgreementDownload}
            >
              Preview
            </button>
          </div>
        ) : null}
        {isSigned ? (
          <div className="property-details-buttons">
             <button
              className="pdb-cancel"
              type="button"
              onClick={handleResendAll}
            >
              Resend Agreement
            </button>
            <button
              className="pdb-save-changes"
              type="button"
              onClick={handleAgreementDownload}
            >
              Download Agreement
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Summary;
