import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatemangmntagrmntfeeschargedetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {dynamicUrl,} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Checkbox } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { BiDollar } from "react-icons/bi";
import { feesAndChargesValid } from "../../../components/CommonSection/Schema/schema";
import { MdPercent } from "react-icons/md";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);


const initialValues = {
    InitialWeekRent:'',
    PrprtyLeasdPrdDuringAgrmnt:false,
    PrprtyLeasdPrdWithinMonth:false,
    ManagementFee:'',
    MarketingFee:'',
    AdministrationFee:''
};

const FeesAndCharges = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();

    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("InitialWeekRent", getDetails.rent)
          setFieldValue("PrprtyLeasdPrdDuringAgrmnt",getDetails.prprtyLeasdPrdDuringAgrmnt)
          setFieldValue("PrprtyLeasdPrdWithinMonth",getDetails.prprtyLeasdPrdWithinMonth)
          setFieldValue("ManagementFee",getDetails.managementFee)
          setFieldValue("MarketingFee",getDetails.marketingFee)
          setFieldValue("AdministrationFee",getDetails.administrationFee)
        }
      }, [getDetails, reportTo]);

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: feesAndChargesValid,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            InitialWeekRent: values.InitialWeekRent,
            PrprtyLeasdPrdDuringAgrmnt: values.PrprtyLeasdPrdDuringAgrmnt,
            PrprtyLeasdPrdWithinMonth: values.PrprtyLeasdPrdWithinMonth,
            ManagementFee: values.ManagementFee,
            MarketingFee: values.MarketingFee,
            AdministrationFee: values.AdministrationFee,
        };
        const url = updatemangmntagrmntfeeschargedetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("TribunalFees")
                    ToastRef.current.notify(["success", returnData.message]);
                    //navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };


    const handleClear = () => {
        handleNextPage("AgreementPeriod")
    };

    const onChangeSwitch = (e, label) => {
        if (label === "PrprtyLeasdPrdDuringAgrmnt") {
          setFieldValue("PrprtyLeasdPrdDuringAgrmnt", e.target.checked);
        } else if (label === "PrprtyLeasdPrdWithinMonth") {
          setFieldValue("PrprtyLeasdPrdWithinMonth", e.target.checked);
        }
      };

      const handleCancle = () => {
        navigate("/agency/ListAgreement");
      }
      useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input" style={{width:'100%'}}>
                            
                                        <div style={{width:'100%'}}>
                                            <Collapse
                                                id={"Fees and Charges"}
                                                title={"Fees and Charges"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        {/* <div className='s1'>The agent shall be entitled to a leasing fee of 1 week. If Principal/client cancels or back-off from market they shell be liable to pay 1 weeks rent</div> */}
                                                        <TextBox
                                                            isMandatory
                                                            label="1 week Rent"
                                                            name="InitialWeekRent"
                                                            value={values.InitialWeekRent}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.InitialWeekRent}
                                                            touched={touched.InitialWeekRent}
                                                        />
                                                    </div>
                                                } />
                                                  <Collapse
                                                id={"If the property is leased either:"}
                                                title={"If the property is leased either:"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className="mb-3">
                                                        <div><Checkbox className='s1 pl-3' checked={values.PrprtyLeasdPrdDuringAgrmnt}  onChange={(e) => onChangeSwitch(e, "PrprtyLeasdPrdDuringAgrmnt")}>During the agreement period</Checkbox></div>
                                                        <div><Checkbox className='s1 pl-3' checked={values.PrprtyLeasdPrdWithinMonth}  onChange={(e) => onChangeSwitch(e, "PrprtyLeasdPrdWithinMonth")}>Within 60 days of the termination of the agreement</Checkbox></div>
                                                        </div>
                                                        {/* <div className='s1'>The fee to which the Agent is entitled shall be due and payable on the signing of a tenancy agreement or upon demand if the tenancy agreement is not completed owing to a default of the Principal after the parties have entered into a binding agreement or if after making the agreement the Principal and Tenant mutually agree not to proceed with the agreement</div> */}
                                                    </div>
                                                } />
                                                <Collapse
                                                id={"The Agent shall be entitled to the following ongoing fees"}
                                                title={"The Agent shall be entitled to the following ongoing fees"}
                                                data={
                                                    <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            isMandatory
                                                            label="Management Fee (excl GST)"
                                                            name="ManagementFee"
                                                            value={values.ManagementFee}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.ManagementFee}
                                                            touched={touched.ManagementFee}
                                                            icon={
                                                                <MdPercent height="100%" width="100%" />
                                                            }
                                                            extraDetails = "all monies collected on behalf of the Principal"
                                                        />
                                                         <TextBox
                                                            isMandatory
                                                            label="Marketing Fee of"
                                                            name="MarketingFee"
                                                            value={values.MarketingFee}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.MarketingFee}
                                                            touched={touched.MarketingFee}
                                                            icon={
                                                                <BiDollar height="100%" width="100%" />
                                                            }
                                                            extraDetails = "Once property becomes vacant"

                                                        />
                                                         <TextBox
                                                            isMandatory
                                                            label="An Administration Fee of "
                                                            name="AdministrationFee"
                                                            value={values.AdministrationFee}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.AdministrationFee}
                                                            touched={touched.AdministrationFee}
                                                            icon={
                                                                <BiDollar height="100%" width="100%" />
                                                            }
                                                            extraDetails = "Per Month"

                                                        />
                                                    </div>
                                                } />
                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeesAndCharges;
