import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../../components/CommonSection/Header/Header";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import SalesViewPage from "./SalesViewPage";
import AgentAgreement from "./agentAgreement";
import AgreementPeriod from "./agreementPeriod";
import Remunation from "./Remunation";
import ExpensesAndCharges from "./ExpensesAndCharges";
import AdvertisingAndPromotions from "./advertisingAndPromotions";
import RepairAndMaintainace from "./RepairsAndMaintenance";
import AgreementSignature from "./signature";
import {assignedTo,getsalesagreementforsign,} from "../../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import axios from "axios";

const AgreementTabs = ({ handleparentsuccess }) => {
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Properties");
  const [activeKey, setActiveKey] = useState("PropertyDetails");
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [HandleNextPages, setHandleNextPages] = useState("");
  const [viewPage, setViewPage] = useState(false);

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var did = url.searchParams.get("did");
    var utype = url.searchParams.get("utype");
    var ref = url.searchParams.get("ref");
    var activeTab = url.searchParams.get("activeTab");
    if (activeTab) {
      setActiveKey(activeTab);
    }
    GetApi(did, utype, ref);
  }, []);

  const GetApi = (did, utype, ref) => {
    let formData = {
      DocumentId: did,
      UserType: utype,
      Reference: ref,
    };
    const url = getsalesagreementforsign;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((response) => {
        if (response.object !== null) {
          setViewPage(true)
          setGetDetails(response.object);
          const refInt = parseInt(ref);
          const document = response.object.documentReceipients.find(
            (doc) => utype === '3' ? doc.agentId : doc.contactId === refInt
          );
          if (document) {
            if (document.isSigned) {
              setHandleNextPages("AgreementSignature");
            }
          }
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const handleNextPage = (value) => {
    setHandleNextPages(value);
  };

  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <Header />
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
           {viewPage ?  <div
              className="pa-content-top-level"
              onClick={() => setVisiblet(true)}
            >
              <div
                className="text7"
                style={{
                  fontSize: "24px",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Sales Inspection Report & Exclusive Agency Agreement
              </div>
                <SalesViewPage
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                />
                {/* <AgentAgreement
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
                {/* <AgreementPeriod
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
                {/* <Remunation
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
                {/* <ExpensesAndCharges
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
                {/* <AdvertisingAndPromotions
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
              {/* {HandleNextPages === "RepairAndMaintainance" ? (
                <RepairAndMaintainace
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                />
              ) : null} */}
                {/* <AgreementSignature
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> */}
            </div> : null }
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementTabs;
