import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus, BiPencil } from "react-icons/bi";
import ToastAlert from "../../../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { ContactLists } from "../../../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  GetCookie,
  currentProfile,
} from "../../../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../../Utilities/client";
import { useSpinnerContext } from "../../../../../Utilities/SpinnerContext";
import "../../../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import CardBoxDashboard from "../../../../../components/CommonSection/CardBoxDashboard/CardBoxDashboard";
import { ActionStatus } from "../../../../../Utilities/enum";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../../../Utilities/profileManagement";
import Sidebar from "../../../../../components/CommonSection/SideBar/sideBar";
import { Paginator } from 'primereact/paginator';

const ContactList = () => {
  const [contactLists, setContactLists] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ContactList");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner(propertyType);
  }, [userId, searchText]);

  const PostApiWithSpinner = (currentPage,totalRows) => {
    const baseUrl = ContactLists;
    let data = {
      AgencyUniqueId: "",
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      AgencyId: agencyID,
      Search: searchText,
      RecordsPerPage: totalRows,
      PageNo: currentPage,
    };
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          let propertyData = response.list;
          let propertyList = propertyData.map((property) => ({
            Name: property.firstName + " " + property.lastName,
            Email: property.email,
            PostCode: property.postcode,
            Phone: property.phone,
            Type: property.type,
            guid: property.contactUniqueId,
            uniqueId: property.contactId,
          }));
          setTotalPages(response.totalCount)
          setContactLists(propertyList);
          setChartData(response.object);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };
  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
  };

  const handleAddAgentClick = () => {
    dynamicUrl(navigate, "ContactDetails");
  };

  const handleEditClick = (rowData) => {
    dynamicUrl(navigate, "ContactDetails" + "?contactUID=" + rowData.guid);
    //navigate("/agency/ContactDetails" + "?contactUID=" + rowData.guid);
  };

  const handleRowClick = (event, rowData) => {
    const clickedElement = event.originalEvent.target;
    if (clickedElement.className.includes("action-column")) {
      return;
    } else {
      dynamicUrl(navigate, "ContactDetails" + "?contactUID=" + rowData.guid);
      //navigate("/agency/ContactDetails" + "?contactUID=" + rowData.guid);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="action-icons">
        <BiPencil
          style={{ fontSize: "24px" }}
          className="edit-icon"
          onClick={() => handleEditClick(rowData)}
        />
      </div>
    );
  };

  const handlePageChange = (event , pageNumber) => {
    PostApiWithSpinner(event.page + 1,event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      List of Contacts
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add Contacts
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              {chartData != null && (
                <div className="propertyList-main-container">
                  <div className="dbag-content-one-row">
                    <CardBoxDashboard
                      time="Monthly"
                      name="All"
                      value={chartData.all}
                      percentage="100"
                      color="green"
                    />
                    <CardBoxDashboard
                      time="Monthly"
                      name="Appraisal"
                      value={chartData.appraisal}
                      percentage="10"
                      color="red"
                    />
                    <CardBoxDashboard
                      time="Last Quater"
                      name="Listed"
                      value={chartData.enlisted}
                      percentage="100"
                      color="green"
                    />
                  </div>
                  <div className="dbag-content-one-row mt-3">
                    <CardBoxDashboard
                      time="Last Quater"
                      name="Contact"
                      value={chartData.contract}
                      percentage="100"
                      color="green"
                    />
                    <CardBoxDashboard
                      time="Last Quater"
                      name="Prospect"
                      value={chartData.prospect}
                      percentage="10"
                      color="red"
                    />
                  </div>
                </div>
              )}
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">List of Contacts</div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                <DataTable
                  ref={dataTableRef}
                  // globalFilter={globalFilter}
                  value={contactLists}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  //paginator
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={5}
                  dataKey=""
                  rowClassName="custom-row"
                  onRowClick={(e) => handleRowClick(e, e.data)}
                >
                  <Column
                    field="Name"
                    header="Name"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Email"
                    header="Email"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="PostCode"
                    header="Post code"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Phone"
                    header="Phone"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Type"
                    header="Type"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    header="Actions"
                    body={actionBodyTemplate}
                    className="common-table-style action-column right-align"
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                  ></Column>
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactList;
