import { useCallback, useState, useEffect, useRef } from "react";
import "./agencycompletion.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { RemoveCookie, GetCookie } from "../../CommonSection/Cookie/Session";
import { getPendingAgencyDetails } from "../../CommonSection/apiresources";
import axios from "axios";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";

const AgencyCompletion = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const [userId, setUserId] = useState();
  const [userUId, setUserUId] = useState("");

  const ToastRef = useRef();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [pageStatus, setPageStatus] = useState();
  const [agencyId, setAgencyId] = useState("");

  // Empty dependency array ensures that it runs only once when the component mounts

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetApprovalStatusApi();

        // console.log(agencyId);
      }, 2);
    }
  }, [userUId]);

  const GetApprovalStatusApi = async () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          setAgencyId(returnData.object.agencyUniqueID);
          setStatus(returnData.object.approvalStatus);
          setPageStatus(returnData.object.status);
        } else {
          ToastRef.current.notify(["error", response.data.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handleClick = async () => {
    GetApprovalStatusApi().then(() => {
      if (pageStatus == 7) {
        ToastRef.current.notify(["warn", "Please Check the Note"]);
        // alert("Please check the note");
        navigate("/agency/statuspage");
      } else if (pageStatus == 6) {
        ToastRef.current.notify([
          "success",
          "Agency Approval is pending From Admin",
        ]);
        // alert("Page is pending from admin");
      } else {
        RemoveCookie();
      }
    });
  };
  // ***********************************************************************************************************

  return (
    <div className="completion">
      <ToastAlert ref={ToastRef} />
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-8 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        <div className="complete_content">
          <div className="image">
            <img src="..\..\assets\images\verified.png" alt="" />
          </div>
          <div className="complete_text">Congratulation</div>
          {status == 6 && (
            <div className="complete-content">
              You have Successfully created Agency <br /> Wait for Approval
            </div>
          )}

          <button
            className="button-add agency-completion-button"
            onClick={RemoveCookie}
          >
            Log-out
          </button>
          <button
            className="button-add agency-completion-button"
            onClick={handleClick}
          >
            Check Status
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AgencyCompletion;
