import React, { useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { Spin } from "antd";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import TextBox from "../../../../src/input-components/textbox";
import RadioInput from "../../../../src/input-components/radioBtn";
import Collapse from "../../../../src/input-components/collapse";
import { uploadtenantagreementfromproperty, AccessKey, BucketName, Keyid, Region } from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus, eTenantAgreementTerm } from "../../../../Utilities/enum";
import Header from "../../../CommonSection/Header/Header";
import Sidebar from "../../../CommonSection/SideBar/sideBar";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { uplodeFileTenant } from "../../../CommonSection/Schema/schema";
import { AiOutlineDelete } from "react-icons/ai";

const initialValues = {
  AgreementStart: "",
  AgreementEnd: "",
  TermsAgreement: "",
  TermsAgreementNote: "",
  RentAmount: "",
  Period: "",
  AgreementFilePath: "",
};

const periodOptions = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
];

const agentTypeOptions = [
  { label: '6 months', value: eTenantAgreementTerm.SixM },
  { label: '12 months', value: eTenantAgreementTerm.TwelveM },
  { label: '2 years', value: eTenantAgreementTerm.TwoY },
  { label: '3 years', value: eTenantAgreementTerm.ThreeY },
  { label: '5 years', value: eTenantAgreementTerm.FiveY },
  { label: 'Periodic (no end date)', value: eTenantAgreementTerm.Periodic },
  { label: 'Other (Please specify)', value: eTenantAgreementTerm.Other },
];

const UploadAgreementTenant = () => {
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const primaryDocumentRef = useRef(null);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          setUserId(JSON.parse(userData).userId);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    retrieveData();
  }, [navigate]);

  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: uplodeFileTenant,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const propertyId = new URL(window.location.href).searchParams.get("propertyId");
    const formData = {
      PropertyId: propertyId,
      AgreementType: 1,
      ...values,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, uploadtenantagreementfromproperty, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          const propertyUId = new URL(window.location.href).searchParams.get("propertyUID");
          navigate(`/agency/properties?propertyUID=${propertyUId}&switchTab=Ownership&subTab=3`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleDragEvents = (e) => {
    e.preventDefault();
    setIsDragging(e.type === "dragenter");
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    setLoading(true);
    AWS.config.update({ accessKeyId: AccessKey, secretAccessKey: Keyid, region: Region });
    const s3 = new AWS.S3();
    const fileExtension = file.name.split(".").pop();
    const newName = `propertyagreement${Date.now()}.${fileExtension}`;
    const params = { Bucket: BucketName, Key: `propertyagreement/${newName}`, Body: file };

    try {
      await s3.upload(params).promise();
      setFieldValue("AgreementFilePath", params.Key);
      ToastRef.current.notify(["success", "File uploaded successfully"]);
      setFileName(file.name);
    } catch (err) {
      alert("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach(handleFileUpload);
  };

  const handleChangeRadio = (e) => {
    setFieldValue("TermsAgreement", e.target.value);
    setFieldValue("AgreementStart", '');
    setFieldValue("AgreementEnd", '');
  };

  const handleChangeDate = (e) => {
    const startDate = new Date(e.target.value);
    setFieldValue("AgreementStart", e.target.value);

    const monthsToAdd = {
      [eTenantAgreementTerm.SixM]: 6,
      [eTenantAgreementTerm.TwelveM]: 12,
      [eTenantAgreementTerm.TwoY]: 24,
      [eTenantAgreementTerm.ThreeY]: 36,
      [eTenantAgreementTerm.FiveY]: 60,
    }[values.TermsAgreement];

    if (monthsToAdd) {
      const endDate = new Date(startDate.setMonth(startDate.getMonth() + monthsToAdd));
      setFieldValue("AgreementEnd", endDate.toISOString().slice(0, 10));
    }
  };

  const handleChangeRent = (e) => {
    const rentAmount = e.target.value;
    setFieldValue("RentAmount", rentAmount);
    setFieldValue("BondAmount", rentAmount * 4);
  };

  const handleCancel = () => {
    const propertyUId = new URL(window.location.href).searchParams.get("propertyUID");
    navigate(`/agency/properties?propertyUID=${propertyUId}&switchTab=Ownership&subTab=2`);
  };

  const handleDeleteFile = () => {
    setFileName('');
    setFieldValue("AgreementFilePath", '');
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div className="blank_space" style={{ backgroundColor: "white", height: "80px", padding: "10px" }}></div>
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={setActiveTopic}
          topicValue={activeTopic}
        />
        <form onSubmit={handleSubmit} className="details-form" style={{display:'initial'}}>
          <div className="property-details-content df-all all-input">
            <Collapse
              id="Agent"
              title="Term of agreement"
              data={
                <div style={{ padding: "0px 30px" }}>
                  <RadioInput
                    colVal={12}
                    label="The residential premises include"
                    name="TermsAgreement"
                    value={values.TermsAgreement}
                    onChange={handleChangeRadio}
                    onBlur={handleBlur}
                    error={errors.TermsAgreement}
                    touched={touched.TermsAgreement}
                    options={agentTypeOptions}
                  />
                  {values.TermsAgreement === eTenantAgreementTerm.Other && (
                    <div style={{ marginLeft: '350px', marginTop: '-60px' }}>
                      <TextBox
                        colVal={3}
                        label=""
                        name="TermsAgreementNote"
                        value={values.TermsAgreementNote}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.TermsAgreementNote}
                        touched={touched.TermsAgreementNote}
                      />
                    </div>
                  )}
                  <div className="pddpn-group">
                    <TextBox
                      label="Starting On"
                      name="AgreementStart"
                      value={values.AgreementStart}
                      onChange={handleChangeDate}
                      onBlur={handleBlur}
                      error={errors.AgreementStart}
                      touched={touched.AgreementStart}
                      type="date"
                    />
                    <TextBox
                      label="Ending On"
                      name="AgreementEnd"
                      value={values.AgreementEnd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.AgreementEnd}
                      touched={touched.AgreementEnd}
                      type="date"
                    />
                    <TextBox
                      label="The rent is "
                      name="RentAmount"
                      value={values.RentAmount}
                      onChange={handleChangeRent}
                      onBlur={handleBlur}
                      error={errors.RentAmount}
                      touched={touched.RentAmount}
                    />
                    <SelectBox
                      label="Period"
                      name="Period"
                      options={periodOptions}
                      placeholder="Select Period"
                      value={values.Period}
                      onChange={(val) => setFieldValue("Period", val.value)}
                      error={errors.Period}
                      touched={touched.Period}
                    />
                    <div className="contacts-group col-md-5">
                      <div className="s1 mb-2">Upload Agreement</div>
                      <div
                        className={`upload-section ${isDragging ? "dragging" : ""}`}
                        onDragEnter={handleDragEvents}
                        onDragLeave={handleDragEvents}
                        onDragOver={handleDragEvents}
                      >
                        <Spin spinning={loading}>
                          <div className="upload-setion-input">
                            {fileName ? (
                              <div className="mt-3">
                                <span className="upload-select">{fileName}</span>
                                <AiOutlineDelete onClick={handleDeleteFile} style={{ fontSize: '18px', cursor: 'pointer' }} />
                              </div>
                            ) : (
                              <div>
                                <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-icon" />
                                <span className="upload-select" onClick={() => primaryDocumentRef.current.click()}>Click to upload</span>
                                <input
                                  ref={primaryDocumentRef}
                                  type="file"
                                  onChange={handleFileChange}
                                  className="upload-input"
                                  name="upload"
                                  multiple
                                />
                                or drag or drop
                              </div>
                            )}
                          </div>
                        </Spin>
                      </div>
                      {errors.AgreementFilePath && touched.AgreementFilePath && (
                      <p className="form-error">{errors.AgreementFilePath}</p>
                    )}
                    </div>
                   
                  </div>
                </div>
              }
            />
          </div>
          <div className="property-details-buttons">
            <button className="pdb-cancel" type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadAgreementTenant;
