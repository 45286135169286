import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {FilePath,sendmangmntagrmnttoowners,generatePdfReport,} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import {DownloadFiletWithSpinner,makeRequestWithSpinner,uploadToS3WithSpinner,} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";

dayjs.extend(customParseFormat);

const initialValues = {
  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
  PropertyImages: [],
};

const SignaturePage = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
  reportTo,
  handleNextPage,
}) => {
  let selectedFile;
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [propertyimageitems, setPrincipleimageitems] = useState([]);
  const [propertyImages, setPrincipleImages] = useState([]);
  const [secondaryOwner, setSecondaryOwner] = useState([]);
  const inputRef = useRef();
  const signatureRef = useRef(null);
  const [sign, setSign] = useState(null);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  // Function to clear the signature canvas
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  // Function to get the signature data as an image URL
  const getSignatureImage = () => {
    setSign(signatureRef.current.toDataURL());
    return signatureRef.current.toDataURL();
  };

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
      setSecondaryOwner(getDetails.contactlistModel);
    }
  }, [getDetails, reportTo]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  }


  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: agreementPeriodValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (data) => {
    let formData = {
      AgreementId: getDetails.id,
      AgencyId: getDetails.agencyId,
      UserId : userId
    };
    const url = sendmangmntagrmnttoowners;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(getDetails.propertyUId)
          navigate(`/agency/ManagementAgreementTabs?propertyUID=${getDetails.propertyUId}`);
        //handleNextPage("AgreementSummary")
          ToastRef.current.notify(["success", returnData.message]);
          window.location.reload();
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };


  const handleClear = () => {
    handleNextPage("RepairAndMaintainance");
  };

  const textWithBullet = "\u2022 This is a bullet point.";

  const select = (event) => {
    inputRef.current.click();
  };

  const handleUplodeImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

  const handleUpload = async () => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${1}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
        setPrincipleImages((prevDocuments) => [
          ...prevDocuments,
          returnData.Key,
        ]);
        handleparentsuccess(["success", "File uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };
  const handleAgreementDownload = () => {
    debugger;
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generatePdfReport,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handlePrevious = () => {
    handleNextPage("RepairAndMaintainance")
};
  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit} className="details-form">
                <div className="property-details-content df-all all-input">
                  <div>
                {/* <div>
                      <Collapse
                      id={"Agreement-Authority"}
                      title={"Signature of Principal(s)"}
                      data={
                        <div>
                                <div
                                  className="mt-2"
                                  style={{
                                    padding: "0px 30px",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="card"
                                    style={{ width: "50%", display: "flex" }}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          padding: "10px 10px 0px 20px",
                                        }}
                                        className="s1"
                                        htmlFor="name"
                                      >
                                        Signature{" "}
                                      </div>
                                    </div>

                                    <div>
                                      <TabView>
                                        <TabPanel header="Draw">
                                          <div className="tc-sign ">
                                            <div className="sign-tc">
                                              <SignatureCanvas
                                                penColor="green"
                                                ref={signatureRef}
                                                canvasProps={{
                                                  width: "300px",
                                                  height: 150,
                                                  className: "signature-canvas",
                                                }}
                                              />
                                              <div className="sign-option">
                                                <div
                                                  onClick={clearSignature}
                                                  className="tc-b1"
                                                >
                                                  Clear
                                                </div>
                                                <div
                                                  onClick={getSignatureImage}
                                                  className="tc-b2"
                                                >
                                                  Confirm
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </TabPanel>
                                        <TabPanel header="Type">
                  
                                        </TabPanel>
                                        <TabPanel header="Upload">
                                          <DocumentUpload
                                            label=""
                                            inputRef={inputRef}
                                            onClickHandler={select}
                                            handleFileChange={handleUplodeImg}
                                            disableStyle={false}
                                          />
                                        </TabPanel>
                                      </TabView>
                                    </div>
                                  </div>
                                </div>
                        </div>
                      }
                    />
                    <Collapse
                      id={"Agreement-Authority"}
                      title={"Signature of Agent"}
                      data={
                        <div>
                          <div
                            className="mt-2"
                            style={{ padding: "0px 30px", display: "flex" }}
                          >
                            <div
                              className="card"
                              style={{ width: "50%", display: "flex" }}
                            >
                              <div>
                                <div
                                  style={{ padding: "10px 10px 0px 20px" }}
                                  className="s1"
                                  htmlFor="name"
                                >
                                  Signature{" "}
                                </div>
                              </div>

                              <div>
                                <TabView>
                                  <TabPanel header="Draw">
                                    <div className="tc-sign ">
                                      <div className="sign-tc">
                                        <SignatureCanvas
                                          penColor="green"
                                          ref={signatureRef}
                                          canvasProps={{
                                            width: "300px",
                                            height: 150,
                                            className: "signature-canvas",
                                          }}
                                        />
                                        <div className="sign-option">
                                          <div
                                            onClick={clearSignature}
                                            className="tc-b1"
                                          >
                                            Clear
                                          </div>
                                          <div
                                            onClick={getSignatureImage}
                                            className="tc-b2"
                                          >
                                            Confirm
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPanel>
                                  <TabPanel header="Type">
                                    <p className="m-0">Under Constraction...</p>
                                  </TabPanel>
                                  <TabPanel header="Upload">
                                    <DocumentUpload
                                      label=""
                                      inputRef={inputRef}
                                      onClickHandler={select}
                                      handleFileChange={handleUplodeImg}
                                      disableStyle={false}
                                    />
                                  </TabPanel>
                                </TabView>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                </div> */}
                    <Collapse
                      id={"Agreement-Period"}
                      title={"Privacy Statement"}
                      data={
                        <div>
                          <div style={{ padding: "0px 30px" }}>
                            <div className="s1" style={{ fontWeight: "600" }}>
                              All information collected by the Agent is done so
                              with respect of the Privacy Act 1998 and the
                              Privacy Principals.
                            </div>
                            <div className="s1 mt-3">
                              Information collected is necessary
                              for one or more of the activities associated with
                              this agreement.
                            </div>
                            <div className="s1 mt-3">
                              The Agent will not use or disclose
                              your personal information for another purpose,
                              unless it would be reasonable to expect such
                              disclosure is necessary for the completion of
                              activities associated with this agreement.
                            </div>
                            <div className="s1 mt-3">
                              The Agent will take all reasonable
                              steps to ensure that the personal information
                              collected, used or disclosed is accurate, complete
                              and up-to-date.
                            </div>
                            <div className="s1 mt-3">
                              The Agent will take reasonable
                              steps to protect the personal information it holds
                              from misuse and loss and from unauthorised access,
                              modification or disclosure and also take
                              reasonable steps to destroy or permanently
                              de-identify personal information it no longer
                              needs.
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleClear}
                  >
                    Cancel
                  </button>
                  <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handlePrevious}
                                    >
                                        Previous
                                    </button>
                  <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={handleAgreementDownload}
                  >
                    Preview
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignaturePage;
