import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import "./LandingPage.css";
import Header from "../Header/Header";
import FooterNew from "../FooterNew/FooterNew";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { LandingPageSearch } from "../../CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { BiCheckCircle } from "react-icons/bi";
import Steps from "../../../src/input-components/steps";

import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
const LandingPage = () => {
  const [selectedBeds, setSelectedBeds] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [togglePrice, setTogglePrice] = useState("Price");
  const fetchRef = useRef(0);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [searchValue , setSearchValues] = useState('')
  const bedsSize = [
    { name: "1-4 Beds", code: 1 },
    { name: "5-8 Beds", code: 2 },
    { name: "9-12 Beds", code: 3 },
    { name: "13-16 Beds", code: 4 },
    { name: "17-20 Beds", code: 5 },
  ];
  const price = [
    { name: "$50,000 - $100,000", code: 1 },
    { name: "$100,000 - $250,000", code: 2 },
    { name: "$250,000 - $400,000", code: 3 },
    { name: "$400,000 - $550,000", code: 4 },
    { name: "$550,000 - $700,000", code: 5 },
    { name: "$700,000 - $850,000", code: 6 },
    { name: "$850,000 - $1,000,000", code: 7 },
    { name: "$1,000,000- $3,000,000", code: 8 },
    { name: "$3,000,000- $5,000,000", code: 9 },
    { name: "$5,000,000- $9,000,000", code: 10 },
  ];

  const rent = [
    { name: "$100 - $250 pw", code: 1 },
    { name: "$250 - $500 pw", code: 2 },
    { name: "$500 - $1000 pw", code: 3 },
    { name: "$1000 - $2000 pw", code: 4 },
    { name: "$2000 - $4000 pw", code: 5 },
    { name: '$4000+ pw"', code: 6 },
  ];

  const navigate = useNavigate();

  useEffect(() => {
     PostApiWithSpinner();
  }, []);

  const handleFilteredData = () => {
    let propertyType = togglePrice === 'Price' ? 1 : 2
    if(selectedBeds === null){
      navigate("/listLandingPage?catId="+propertyType)
    }else{
      navigate("/listLandingPage"+"?PropertySaleRental="+ propertyType+"&Minbeds="+selectedBeds.code+"&MinPrice="+selectedPrice.code+"&Search="+searchValue);
    }
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      // Call your API with the search value
      const baseUrl = LandingPageSearch;
      const data = {
        Search: value, // Assuming your API expects a 'Search' parameter for filtering
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (value) => {
    // Call loadOptions with the search value
    const values = value.map(item => item.value).join(',');
    setSearchValues(values)
    debounceFetcher(value);
  };

  const PostApiWithSpinner = () => {
    const baseUrl = LandingPageSearch;
    const data = {
      Search: 8,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === 1) {
          let landingPageListData = response.list;
          const transformedData = landingPageListData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleToggleDropdown = (value) => {
    setTogglePrice(value);
  };

  return (
    <div className="landingpage">
      <Header />
      <div
        className="blank_space"
        style={{
          backgroundColor: "white",
          height: "100px",
          padding: "10px",
        }}
      ></div>
      <div></div>
      <div>
        <div
          className="main-banner"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="mt-5 col-lg-5">
                    <div className="col-12 col-lg-12 col-xl-12 col-sm-12 hero-content">
                      <h1>
                        Beyond Real Estate. <br />
                        Untraditional Foreal Property Way
                      </h1>
                      <p>
                        Control the process and change your future, you have
                        everything at your fingertips. Explore our innovative
                        platform and redefine the way you approach real estate.
                        With our cutting-edge tools and personalized solutions,
                        you'll gain a competitive edge in today's dynamic
                        market. Experience the power of seamless integration and
                        unleash the potential of your property investments.
                        Elevate your journey with Foreal Property.
                      </p>

                      <div>
                        <a className="btn btn-primary">Get Started</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 offset-lg-1">
                    <div className="banner-image">
                      <img
                        src="../../../assets/images/mainImage1.jpg"
                        className="animate__animated animate__fadeInDown animate__delay-0.1s img-main"
                        alt="man"
                        width={700}
                        height={450}
                      />
                    </div>
                  </div>
                </div>
                <div className="search-section-lp ">
                  <div
                    className={togglePrice === "Price" ? "rent" : "buy"}
                    onClick={() => handleToggleDropdown("Price")}
                  >
                    Buy
                  </div>
                  <div
                    className={togglePrice === "Rent" ? "rent" : "buy"}
                    onClick={() => handleToggleDropdown("Rent")}
                  >
                    Rent
                  </div>
                  <div className="search-section-lp-first col-sm-12 col-md-6 col-lg-6">
                    {/* <input type="text" className="search-box-lp form-control landingPageSearch" placeholder='Search for suburb or postcode' /> */}
                    <Select
                      className="landingPageSearch"
                      mode="multiple"
                      placeholder="Search for suburb or postcode"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      style={{ width: "100%" }}
                      options={options}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="search-section-lp-second">
                    <Dropdown
                      value={selectedBeds}
                      onChange={(e) => setSelectedBeds(e.value)}
                      options={bedsSize}
                      optionLabel="name"
                      placeholder="Beds"
                      className="landingpageDropdown w-full md:w-14rem"
                    />
                    <Dropdown
                      value={selectedPrice}
                      onChange={(e) => setSelectedPrice(e.value)}
                      options={togglePrice === "Price" ? price : rent}
                      optionLabel="name"
                      placeholder="Price"
                      className="landingpageDropdown w-full md:w-14rem"
                    />
                    <button
                      className="search-button-lp"
                      onClick={handleFilteredData}
                    >
                      Search
                    </button>
                    {/* <button className="beds-lp">Beds</button> */}
                    {/* <button className="price-lp">Price</button> */}
                  </div>
                </div>
              </div>
              <div className="mt-5 solutions-area crr-80">
                <div className="container">
                  <div className="section-title1">
                    <span className="sub-title">What We Offer</span>
                    <h2>Our Featured Solutions</h2>
                    <div className="bar"></div>
                    {/* <p style={{ fontSize: "initial" }}>We offer effective and cutting-edge solutions that enhance how companies run their operations while generating profits and fostering business growth.</p> */}
                    <p style={{ fontSize: "initial" }}>
                      Explore our simplified property management solutions,
                      covering leasing, rent/bill management, repairs, and
                      review/renewal processes. Enhance tenant interactions,
                      streamline operations, and optimize maintenance tasks
                      effortlessly.
                    </p>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                      <div className="single-solutions-box">
                        <div className="icon">
                          <img
                            height={50}
                            width={50}
                            src="../../../assets/images/house_619032.png"
                            alt="Leasing"
                          />
                        </div>
                        <h3>
                          <div legacyBehavior>
                            <a className="learn-label">Leasing</a>
                          </div>
                        </h3>
                        <p>
                          Explore our all-inclusive leasing platform aimed at
                          simplifying property management tasks. Our cloud-based
                          solution automates leasing processes, streamlines
                          tenant interactions, and optimizes facility operations
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                      <div className="single-solutions-box">
                        <div className="icon">
                          <img
                            height={50}
                            width={50}
                            src="../../../assets/images/real-estate_7904760.png"
                            alt="Rent / Bills"
                          />
                        </div>
                        <h3>
                          <div legacyBehavior>
                            <a className="learn-label">Rent / Bills</a>
                          </div>
                        </h3>
                        <p>
                          Discover our comprehensive platform tailored to
                          streamline rent and bill management. Our solution
                          simplifies tracking rent payments, managing bills, and
                          ensuring compliance with ease and efficiency.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                      <div className="single-solutions-box">
                        <div className="icon">
                          <img
                            height={50}
                            width={50}
                            src="../../../assets/images/settings_627495.png"
                            alt="Repairs"
                          />
                        </div>
                        <h3>
                          <div legacyBehavior>
                            <a className="learn-label">Repairs</a>
                          </div>
                        </h3>
                        <p>
                          Explore our intuitive platform designed to streamline
                          house repair management. Our solution simplifies the
                          process of scheduling repairs, tracking maintenance
                          tasks, and coordinating with service providers.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                      <div className="single-solutions-box">
                        <div className="icon">
                          <img
                            height={50}
                            width={50}
                            src="../../../assets/images/tablet_9160780.png"
                            alt="Review / Renewal"
                          />
                        </div>
                        <h3>
                          <div legacyBehavior>
                            <a className="learn-label">Review / Renewal</a>
                          </div>
                        </h3>
                        <p>
                          Discover our comprehensive platform for house renewal
                          and review processes. Our user-friendly solution
                          facilitates seamless renewal negotiations, efficient
                          documentation management, and transparent review
                          processes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features-area pt-80 pb-50 bg-f7fafd">
                <div className="container">
                  <div className="section-title1">
                    <h2>Lets get started</h2>
                  </div>

                  <div className="row">
                    <Steps
                      direction="vertical"
                      items={[
                        {
                          title: "Create Profile",
                          description:
                            " Set up your user profile to get started.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/createProfile.jpg",
                        },
                        {
                          title: "Add Property",
                          description:
                            "Add your property details to the platform.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/addProperty.jpg",
                        },
                        {
                          title: "Meet your leasing officer",
                          description:
                            "Connect with your leasing officer for further assistance.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/leasingOfficer.jpg",
                        },
                        {
                          title: "Inspection and Applications",
                          description:
                            "Manage property inspections and applications.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/inspection.jpg",
                        },
                        {
                          title: "Reports and Sign Lease",
                          description:
                            "Access and sign lease agreements and generate reports.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/reports.jpg",
                        },
                        {
                          title: "Relax and save",
                          description:
                            "Enjoy peace of mind and financial savings through our services.",
                          icon: <BiCheckCircle />,
                          img: "../../../assets/images/relaxSave.jpg",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="features-area pt-80 pb-50 bg-f7fafd">
                <div className="container">
                  <div className="section-title1">
                    <h2>PropertyScape Difference</h2>
                    <p>
                      PropertyScape has transformed the world of Real Estate and
                      changed client communication and interacting. Our
                      real-time platform empowers your relationships with your
                      clients,fuels your efficiency and gives you time to focus
                      on what you do best!
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp1.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />{" "}
                        </div>
                        <h3>
                          <div>
                            <a>Email Notifications</a>
                          </div>
                        </h3>
                        <p>
                          Access real-time information, whenever and wherever
                          you need it.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp2.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />{" "}
                        </div>
                        <h3>
                          <div>
                            <a>Easily Identify and Secure Leads</a>
                          </div>
                        </h3>
                        <p>Easily identify and secure new your leads.</p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp3.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />{" "}
                        </div>
                        <h3>
                          <div>
                            <a>Empower Clients with Information</a>
                          </div>
                        </h3>
                        <p>
                          Give your clients the information they need to make
                          confident decisions.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp4.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />{" "}
                        </div>
                        <h3>
                          <div>
                            <a>Tools for Your Business</a>
                          </div>
                        </h3>
                        <p>
                          {" "}
                          Receive the tools to run your own business, but never
                          feel alone.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp5.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />{" "}
                        </div>
                        <h3>
                          <div>
                            <a>Strong Client Relationships</a>
                          </div>
                        </h3>
                        <p>
                          Our real-time platform provides transparency in its
                          truest form. Experience strong client relationships
                          and long-term loyalty.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="single-features">
                        <div className="icon">
                          <img
                            src="../../../assets/images/lp5.png"
                            alt="lp1"
                            height="50px"
                            width="50px"
                          />
                        </div>
                        <h3>
                          <div>
                            <a>Customizable Features</a>
                          </div>
                        </h3>
                        <p>
                          Lorem ipsum dolor amet, adipiscing, sed do eiusmod
                          tempor incididunt ut labore dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterNew />
    </div>
  );
};

export default LandingPage;
