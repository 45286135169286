import { useCallback, useState, useEffect } from "react";
import './addproperty.css'
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import PlacesAutocomplete, {  geocodeByAddress } from 'react-places-autocomplete';
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { apiUrlap, apiGetPendingProfile } from "../../CommonSection/apiresources";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";



const AddProperty = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userId, setUserID] = useState('');
  const [address, setAddress] = useState("");
  const [unitNum, setUnitNum] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [streetNam, setStreetNam] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postcode, setPostCode] = useState("");
  const [state, setState] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  let value;
  if(searchParams.has('value'))
    {value=searchParams.get('value');}
  const [data, setData]= useState([]);
  let progressData = [{
    label: "Add Property",
    url: "/addproperty",
    isSelected: true,
    isCompleted: false
  },
  {
    label: "Verification",
    url: "/verify",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Ownership",
    url: "/ownership",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Add More Owners",
    url: "/addowner",
    isSelected: false,
    isCompleted: false
  },]

  const apiKey = 'AIzaSyBlCoMA7GMoWtsv6mmdz2Q2AFopWV5PRZY';

  const handlepressSelect = async (addrs) => {
    setAddress(addrs);
    try {

      const results = await geocodeByAddress(addrs)
      const details = results[0];
      //console.log('details =', details);
      const fullFormattedAddress = details.formatted_address;
      //console.log(fullFormattedAddress);
      setAddress(fullFormattedAddress);
      const { address_components } = details;

      //console.log(details);
      const address = {};

      address_components.forEach((component) => {
        const { types, long_name } = component;
        if (types.includes('street_number')) {
          address.streetNumber = long_name;
        } else if (types.includes('route')) {
          address.streetName = long_name;
        } else if (types.includes('locality')) {
          address.suburb = long_name;
        } else if (types.includes('postal_code')) {
          address.postcode = long_name;
        } else if (types.includes('administrative_area_level_1')) {
          address.state = long_name;
        }
        setUnitNum(address.unitNumber);
        setStreetNum(address.streetNumber);
        setStreetNam(address.streetName);
        setSuburb(address.suburb);
        setPostCode(address.postcode);
        setState(address.state);
        // console.log("selection done");
      });

    } catch (error) {
      console.error('Error', error)
    }



    // Now, you can set the values in your other input fields accordingly
    // For example:
  }

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserID(parsedData.userId)
          console.log(userData)
        }
      } catch (error) {
        console.error('Error retrieving data from AsyncStorage:', error);
      }
    };
    retrieveData();

  }, []);


  useEffect(() => {
    if (userId !== '') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getApi();
      }, 2)
    }

  }, [userId]);

  const getApi = () => {
    const baseUrl1 = apiGetPendingProfile;

    // Request body with login information

    const UserId1 = JSON.parse(GetCookie('@UserData')).userId;
    console.log(UserId1);
    const apiUrl = `${baseUrl1}?UserId=${UserId1}`;
    console.log('API URL:', apiUrl);

    axios.get(apiUrl)
      .then(response => {
        // Handle the response data
        if (response.data.object == null) {
          progressData[0].isSelected=true;
          setData(progressData);
          navigate('/addproperty');
          console.log(response.data)
        } else {
          let x=response.data.object.status;
            if(x<4)
            {for(let i=0; i<x; i++){
              progressData[i].isCompleted=true;
            }
            setData(progressData);}
          if(value!=2){
            if (response.data.object.currentStatus == 1) {
              console.log(response.data.object.status)
              navigate('/verify');
  
            } else if (response.data.object.currentStatus == 2) {
              console.log(response.data.object.status)
  
              navigate('/ownership');
  
            } else if (response.data.object.currentStatus == 3) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 4) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 5) {

              console.log(response.data.object.status)
              navigate('/Completion');
  
            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  const handleAddres = () => {

    const apiUrl = apiUrlap;
    const dt = JSON.parse(GetCookie('@UserData')).userId;
    //console.log(dt, " data i am geting");
    // Request body with login information
    const AddressData = {
      PropertyGooglePlacesLocation: address,
      State: state,
      Suburb: suburb,
      PostCode: postcode,
      StreetNumber: streetNum,
      StreetName: streetNam,
      UnitNumber: unitNum,
      UserId: dt,
    };
    axios.post(apiUrl, AddressData)
      .then((response) => {
        // Handle the response from the backend
        if (response.data.status == 1) {
          console.log("Working");
          navigate('/verify');

        } else {
          // alert(`Message: ${response.data.message}`);
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const handlePressSelect = (address) => {
    // Handle the selected place here

  };


  const onAddPropertyContainerClick = () => {
    //console.log('change');
  };


  const onFrameContainerClick = useCallback((isVisible) => {
    setIsVisible(isVisible);
  }, []);

  return (
    <div className="add-property" onClick={() => onAddPropertyContainerClick()}>

      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">

        <div className="progress-steps-progress-icon">
          {data&&<ProgressbarLandlord message={data} />}
        </div>

        <div className="add-property1">{`Add Property `}</div>
        <div className="addressinput">
          <div className="input-dropdown">
            <div className="input-with-label">
              <div className="label">
                <span>Address</span>
                <span className="span">*</span>
              </div>
              {/* <GooglePlacesAutocomplete
                apiKey={apiKey} // Pass your API key here
                onSelect={handlePressSelect} // Use onSelect to handle place selection
                autocompletionRequest={{
                  componentRestrictions: { country: 'au' }, // Restrict to Australia
                }}
                fetchDetails={true} // Enable fetching additional place details
              /> */}
              {/* ****************************************************************************************************** */}
              <PlacesAutocomplete
                value={address}
                onSelect={handlepressSelect}
                onChange={setAddress}
                searchOptions={{
                  types: ['geocode'], // Restrict to addresses
                  componentRestrictions: { country: 'AU' }, // Restrict to Australia
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: 'Address',
                        className: 'input',

                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'input'
                          : 'input';

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {/* ***************************************************************************************************** */}
            </div>
            <div className="hint-text" />
          </div>
          <div className="label-wrapper" onClick={() => onFrameContainerClick(!isVisible)}>
            <div className="label1">Details</div>
          </div>
        </div>
        <div className="subaddress">
          {isVisible && <div className="input-dropdown-parent">
            <div className="input-dropdown1">
              <div className="input-with-label">
                <input type="text" className="input" placeholder='Unit Number' id='Unit' name='Unit' value={unitNum} onChange={(text) => setUnitNum(text)} />
              </div>
              <div className="hint-text" />
            </div>
            <div className="input-dropdown1">
              <div className="input-with-label">

                <input type="text" className="input" placeholder='Street Number' id='StreetNumber' name='StreetNumber' value={streetNum} onChange={(text) => setStreetNum(text)} />
              </div>
              <div className="hint-text" />
            </div>
            <div className="input-dropdown1">
              <div className="input-with-label">
                <input type="text" className="input" placeholder='Street Name' id='StreetName' name='StreeName' value={streetNam} onChange={(text) => setStreetNam(text)} />

              </div>
              <div className="hint-text" />
            </div>
            <div className="input-dropdown1">
              <div className="input-with-label">
                <input type="text" className="input" placeholder='Suburb' id='Suburb' name='Suburb' value={suburb} onChange={(text) => setSuburb(text)} />

              </div>
              <div className="hint-text" />
            </div>
            <div className="input-dropdown1">
              <div className="input-with-label">
                <input type="text" className="input" placeholder='Postcode' id='Postcode' name='Postcode' value={postcode} onChange={(text) => setPostCode(text)} />

              </div>
            </div>
            <div className="input-dropdown1">
              <div className="input-with-label">
                <input type="text" className="input" placeholder='State' id='State' name='State' value={state} onChange={(text) => setState(text)} />
              </div>
            </div>
          </div>}
        </div>

        <button className="button-ap" onClick={() => handleAddres()}>
          <div className="text-parent">
            <div className="text7">Continue</div>
            <img
              className="arrow-narrow-right-icon"
              alt=""
              src=".\assets\images\right-arrow.png"
            />
          </div>

        </button>

      </div>

      <Footer />

    </div>
  );
};

export default AddProperty;
