import React from "react";
import { useSpinnerContext } from "../SpinnerContext";
import { ProgressSpinner } from "primereact/progressspinner";
import "./spinner.css";
import Lottie from "react-lottie";
import animationData from "../Loading.json";

function CustomSpinner() {
  const { isSpinnerVisible } = useSpinnerContext();
  const animationOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return isSpinnerVisible ? (
    <div className="overlay">
      <div className="App">
        <Lottie options={animationOptions1} height={250} width={250} />
      </div>
      {/* <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      /> */}
    </div>
  ) : null;
}

export default CustomSpinner;
