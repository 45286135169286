import { useCallback, useState, useEffect, useRef } from "react";
import "./propertydetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { apiUrlowner, BucketName, Region, AccessKey, Keyid, apiUrlamo, addressRentPropertyDetails, GetPendingProfiletenant } from "../../CommonSection/apiresources";
import { BiCalendar } from 'react-icons/bi';
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";

const PropertyDetails = () => {

  const navigate = useNavigate();
  const [dates, setdates] = useState("");
  const [tenure, setTenure] = useState(0);
  const [rents, setRents] = useState(0);
  const [userId, setUserId] = useState('')
  const [userUId, setUserUId] = useState('')
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);

  const [propAddress, setPropAddress] = useState();
  const [propertyUID, setPropertyUID] = useState();
  const [propId, setPropId] = useState('');
  const [rentAppId, setRentAppId] = useState();
  let progressData = [
    {
      label: "Property Details",
      url: "/propertydetails",
      isSelected: true,
      isCompleted: false
    },
    {
      label: "Address History",
      url: "/addresshistory",
      isSelected: false,
      isCompleted: false
    },
    {
      label: "Verification",
      url: "/verify",
      isSelected: false,
      isCompleted: false
    },
    {
      label: "Employment",
      url: "/employment",
      isSelected: false,
      isCompleted: false
    },
    {
      label: "Questionnaire",
      url: "/questionnaire",
      isSelected: false,
      isCompleted: false
    },
    {
      label: "Documentation",
      url: "/documentation",
      isSelected: false,
      isCompleted: false
    },
    {
      label: "Consent",
      url: "/consent",
      isSelected: false,
      isCompleted: false
    },]

  ///////////////////////////////////////////
  useEffect(() => {

    const retrieveData = async () => {

      try {

        const userData = GetCookie('@UserData');
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID)
          setUserId(parsedData.userId)
          console.log(userData)
        }

      } catch (error) {

        console.error('Error retrieving data from AsyncStorage:', error);

      }

    };

    retrieveData();

  }, [userId]);

  useEffect(() => {
    if (propertyUID !=='') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getapi();
      }, 2)
    }

  }, [propertyUID]);

  const getapi =  () => {

    const baseUrl = GetPendingProfiletenant;
    setPropertyUID("b4df6e16b5b74");
    // Request body with login information
    setData(progressData);
    const propertyId = propertyUID;
    const userId = userUId;
    const apiUrl = `${baseUrl}?propertyId=${propertyId}&userId=${userId}`;
    console.log('API URL:', apiUrl);
    console.log(`PropertyId: ${propertyId}`);

     axios.get(apiUrl)

      .then(response => {

        // Handle the response data
        console.log(response.data);
        if (response.data.status == 1) {
          // console.log('some data');
          // console.log(response.data.object);
          setPropId(response.data.object.propertyId);
          setPropAddress(response.data.object.propertyStreetadress);
          setRentAppId(response.data.object.rentApplicationId);
          console.log(propId)

        } else {

          alert(`Message: ${response.data.message}`);

          console.log(response.data)

        }

      })

      .catch(error => {

        console.error('Error:', error);

      });

  }

  const fetchData = (event) => {

    const apiUrl = addressRentPropertyDetails;
    // console.log(event);

    // Request body with login information
    const apidata = {

      PropertyId: propId,
      RentApplicationId: rentAppId,
      IsInspectedProperty: selectedOption,
      LeaseTerm: tenure,
      WeeklyRent: rents,
      UserId: userId,
      UserUniqueId: userUId,
      PropertyUniqueId: propertyUID,

    }
    // console.log(`UserPropertyId: ${propId}`)
    // console.log(`UserpropertyUniqueId: ${propertyUID}`)
    // console.log(`UserMainId: ${userId}`)
    // console.log(`UserMainUniqueId: ${userUId}`)

    axios.post(apiUrl, apidata)

      .then((response) => {
        // console.log(apiUrl);
        // console.log(apidata);
        // Handle the response from the backend
        console.log(response);
        if (response.data.status == 1) {
          console.log("success");
          navigate('/verifytenant');



        } else {

          alert(`Message: ${response.data.message}`);
          console.log(response.data.message);
          alert("failed");

        }

      })

      .catch((error) => {

        console.error('Error:', error);

      });
  }
  ///////////////////////////////////////////////
  const tenantpdTime = (index) => {
    setTenure(index)
    console.log("value: " + index);
    let id = "tenantpd-select" + index;
    for (let i = 6; i < 25; i = i + 6)
      if (i != index) {
        colorUnset(i);
      }
    document.getElementById(id).style.backgroundColor = "#164c63";
    document.getElementById(id).style.color = '#fff';

  }

  const colorUnset = (i) => {
    let id = "tenantpd-select" + i;
    document.getElementById(id).style.backgroundColor = '#fff';
    document.getElementById(id).style.color = "#164c63";
  }
  // ***********************************************************************************************************

  // ***********************************************************************************************************

  return (
    <div className="tenant">
      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '80px',
          padding: '10px',
        }}></div>
        {data && <ProgressbarLandlord message={data} />}
        <div className="tenantpd">{`Property Details`}</div>
        <form className="tenantpd-input" >
          <div className="tenantpd-text">
            Have you inspected the property?
          </div>
          <div className="tenantpd-radio">
            <label htmlFor="radio">
              <input
                type="radio"
                id="radio"
                name="radioGroup"
                value="true"
                checked={selectedOption === "true"}
                onChange={() => setSelectedOption("true")}
              />
              Yes, I have or plan to inspect the property
            </label>

            <label htmlFor="radio">
              <input
                id="radio"
                type="radio"
                name="radioGroup"
                value="false"
                checked={selectedOption === "false"}
                onChange={() => setSelectedOption("false")}
              />
              No, I accept the property as is
            </label>
            <label htmlFor="date">
              <div className="tenantpd-date-text">Preferred lease start date </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><BiCalendar height="100%" width="100%" /></span>
                </div>
                <input type="date"
                  id="tenantpd-date"
                  className="form-control tenant-date"
                  value={dates}
                  onChange={(event) => { setdates(event.target.value) }}
                  name="date"
                  aria-describedby="basic-addon1" />
              </div>
            </label>
            <label htmlFor="select" >
              <div className="tenantpd-select-text">Initial lease term (months)</div>
              <div className="tenantpd-select-radio">
                <div htmlFor="select" id='tenantpd-select6' className="tenantpd-select" onClick={() => tenantpdTime(6)}>
                  6
                </div>
                <div htmlFor="select" id='tenantpd-select12' className="tenantpd-select" onClick={() => tenantpdTime(12)}>
                  12
                </div>
                <div htmlFor="select" id='tenantpd-select18' className="tenantpd-select" onClick={() => tenantpdTime(18)}>
                  18
                </div>
                <div htmlFor="select" id='tenantpd-select24' className="tenantpd-select" onClick={() => tenantpdTime(24)}>
                  24
                </div>
              </div>

            </label>
            <label htmlFor="rent">
              <div className="tenantpd-rent-text">Rent per week </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">$</span>
                </div>
                <input type="number"
                  value={rents}
                  id="tenantpd-rent"
                  className="form-control"
                  placeholder="400"
                  name="rent"
                  onChange={(event) => { setRents(event.target.value) }}
                  aria-describedby="basic-addon1" />
              </div>
            </label>



          </div>
          <div className="tenantpd-button" onClick={() => fetchData(event)} >
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default PropertyDetails;
