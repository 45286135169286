// excelExport.js

import * as XLSX from "xlsx";
import {getActivitiesDescriptionFromValue,getDescriptionFromValue} from "../../Utilities/helper";

const mapActivityDescriptions = (rowData) => {
    return {
        ...rowData,
        activity: getActivitiesDescriptionFromValue(parseInt(rowData.activity))
    };
};

const exportToExcel = (dataToExport, filename, labelMapping) => {
    // Map activity descriptions before exporting
    const mappedData = dataToExport.map(mapActivityDescriptions);

    // Extract only the desired fields from each object in the array
    const filteredData = mappedData.map(item => {
        let filteredItem = {};
        Object.keys(labelMapping).forEach(label => {
            const parameterName = labelMapping[label];
            filteredItem[label] = item[parameterName];
        });
        return filteredItem;
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert filtered data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Style headers
    const headerStyle = {
        font: { bold: true, color: { rgb: "FF0000FF" } }, // Red font color
        fill: { bgColor: { indexed: 64 } }, // Yellow background color
        border: { bottom: { style: "thick" } }
    };
    
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    // Define the row index to be styled
    const rowIndexToStyle = 1; // Row index 2 (0-based index)

    // Apply the header style to the specified row
    for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = XLSX.utils.encode_cell({ r: rowIndexToStyle, c: C });
        if (!worksheet[cell]) continue;
        if (!worksheet[cell].s) worksheet[cell].s = {};
        Object.assign(worksheet[cell].s, headerStyle);
    }

    // Style all cells and columns border to be bold except the specified row
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = XLSX.utils.encode_cell({ r: R, c: C });
            if (!worksheet[cell]) continue;
            if (!worksheet[cell].s) worksheet[cell].s = {};
            worksheet[cell].s.border = { bottom: { style: "thick" } };
        }
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, filename);
};

export default exportToExcel;
