import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updatesalesagrmntperioddetails } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import SelectBox from "../../input-components/selectBoxNew"
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl, } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { salesAgreementPeriod } from "../../../components/CommonSection/Schema/schema";
import AddressInput from "../../input-components/address";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementStartDate: "",
    AgreementEndDate: "",
    PropOccupation: "",
    FixFitIncluded: "",
    FixFitExcluded: "",
    EasementConvenantDefects: "",
    TermsAndConditionsOfSale: "",
    PriceType: "",
    SellingFixedPrice: "",
    LicenceeRecomendMethodOfSale: "1",
    SplInstructionAboutMarketing: "",
    SellingPriceFrom: '',
    SellingPriceTo: ''
};

const AgreementPeriod = ({ onDataRecive, handleparentsuccess, handleCurrentPage, getDetails, reportTo, handleNextPage }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [viewAddressDetails, setViewAddressDetails] = useState(true);
    const [fixedPrice, setFixedPrice] = useState(true);
    const [PriceTypeArr, setPriceTypeArr] = useState([
        { value: "Fixed Price", label: "Fixed Price" },
        { value: "Price Range", label: "Price Range" },
    ]);
    const [methodSaleArr, setMethodSaleArrArr] = useState([
        { value: "1", label: "Private Treaty" },
        { value: "2", label: "Auction" },
        { value: "3", label: "Sale By Negotiation" },
    ]);
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
            setValues({ ...values, ...getDetails });
            setFieldValue("AgreementStartDate", formatDate(getDetails.agreementStartDate));
            setFieldValue("AgreementEndDate", formatDate(getDetails.agreementEndDate));
            setFieldValue("PropOccupation", getDetails.propOccupation)
            setFieldValue("FixFitIncluded", getDetails.fixFitIncluded)
            setFieldValue("FixFitExcluded", getDetails.fixFitExcluded)
            setFieldValue("EasementConvenantDefects", getDetails.easementConvenantDefects)
            setFieldValue("TermsAndConditionsOfSale", getDetails.termsAndConditionsOfSale)
            setFieldValue("PriceType", getDetails.priceType)
            setFieldValue("SellingFixedPrice", getDetails.sellingFixedPrice)
            setFieldValue("LicenceeRecomendMethodOfSale", getDetails.licenceeRecomendMethodOfSale?.toString());
            setFieldValue("SplInstructionAboutMarketing", getDetails.splInstructionAboutMarketing)
            setFieldValue("SellingPriceFrom", getDetails.sellingPriceFrom)
            setFieldValue("SellingPriceTo", getDetails.sellingPriceTo)
        }
    }, [getDetails, reportTo]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    function formatDate(dateString) {
        if (!dateString) {
            return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: salesAgreementPeriod,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            AgreementStartDate: values.AgreementStartDate,
            AgreementEndDate: values.AgreementEndDate,
            PropOccupation: values.PropOccupation ? 2 : 1,
            FixFitIncluded: values.FixFitIncluded,
            FixFitExcluded: values.FixFitExcluded,
            EasementConvenantDefects: values.EasementConvenantDefects,
            TermsAndConditionsOfSale: values.TermsAndConditionsOfSale,
            SellingFixedPrice: values.SellingFixedPrice ? parseInt(values.SellingFixedPrice) : 0,
            LicenceeRecomendMethodOfSale: values.LicenceeRecomendMethodOfSale ? parseInt(values.LicenceeRecomendMethodOfSale) : 0,
            SplInstructionAboutMarketing: values.SplInstructionAboutMarketing,
            SellingPriceFrom: values.SellingPriceFrom ? parseInt(values.SellingPriceFrom) : 0,
            SellingPriceTo: values.SellingPriceTo ? parseInt(values.SellingPriceTo) : 0
        };
        const url = updatesalesagrmntperioddetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("Remunation")
                    ToastRef.current.notify(["success", returnData.message]);
                    // navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };


    const handleClear = () => {
        handleNextPage("propetyies")
    };

    const handleCancle = () => {
        navigate("/agency/ListAgreement");
    }

    const handlepressSelect = async (addrs) => {
        setAddress(addrs);
        setFieldValue("address", addrs);
        try {
            const results = await geocodeByAddress(addrs);

            const details = results[0];

            const fullFormattedAddress = details.formatted_address;

            setAddress(fullFormattedAddress);

            const { address_components } = details;

            const address = {};

            address_components.forEach((component) => {
                const { types, long_name } = component;

                if (types.includes("subpremise")) {
                    address.unitNumber = long_name;
                } else if (types.includes("street_number")) {
                    address.streetNumber = long_name;
                } else if (types.includes("route")) {
                    address.streetName = long_name;
                } else if (types.includes("locality")) {
                    address.suburb = long_name;
                } else if (types.includes("postal_code")) {
                    address.postCode = long_name;
                } else if (types.includes("administrative_area_level_1")) {
                    address.state = long_name;
                }
                let latitude = details.geometry.location.lat();
                let longitude = details.geometry.location.lng();

                setLatitude(latitude);

                setLongitude(longitude);

                setValues({ ...values, ...address });
            });
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleView = () => {
        setViewAddressDetails(!viewAddressDetails);
    };

    const onChangeSwitch = (e, label) => {
        setFieldValue("PropOccupation", e);
    };

    const handleChangeDateStart = (e) => {
        const startDate = new Date(e.target.value);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 90);

        setFieldValue('AgreementStartDate', e.target.value);
        setFieldValue('AgreementEndDate', endDate.toISOString().split('T')[0]);
    }

    const handleChangeDateEnd = (e) => {
        setFieldValue('AgreementEndDate', e.target.value)
    }

        const handleChangePrice = (e, value) => {
            const priceFrom = parseFloat(e.target.value);
            const priceTo = priceFrom + (priceFrom * 0.10);
            setFieldValue("SellingPriceFrom", e.target.value);
            setFieldValue("SellingPriceTo", priceTo);
        };
    
        useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">

                                    <div>
                                        <Collapse
                                            id={"Agreement-Period"}
                                            title={"AGENCY PERIOD"}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    {/* <div className='s1'>In consideration of the Agent promising to use their best endeavors, the Principal hereby grants to the Agent the right to act on behalf of the Principal in leasing and management the property in accordance with this agreement</div> */}
                                                    <TextBox
                                                        isMandatory
                                                        type={"date"}
                                                        label="From date"
                                                        name="AgreementStartDate"
                                                        value={values.AgreementStartDate}
                                                        onChange={handleChangeDateStart}
                                                        onBlur={handleBlur}
                                                        error={errors.AgreementStartDate}
                                                        touched={touched.AgreementStartDate}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        type={"date"}
                                                        label="To date"
                                                        name="AgreementEndDate"
                                                        value={values.AgreementEndDate}
                                                        onChange={handleChangeDateEnd}
                                                        onBlur={handleBlur}
                                                        error={errors.AgreementEndDate}
                                                        touched={touched.AgreementEndDate}
                                                    />
                                                    <div className='s1 mb-3'>Inclusive now called the 'Exclusive Agency Period</div>
                                                    {/* <div className='s1'>If notice is given all fees and charges will be payable on the completion date.</div> */}
                                                </div>
                                            } />
                                        <Collapse
                                            id={"Agreement-Authority"}
                                            title={"PROPERTY (“the Property”) Description of the Property sufficiently detailed to allow it to be readily identified."}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                    <div className="">
                                                    <div className={`lda-${name} col-lg-12 col-sm-12 pl-3`} style={{ paddingLeft: '0px' }}>
                                                            <div className="address-pair">
                                                                <div className="labelssss ">Property Address </div>
                                                                <div className="valuessss">: {getDetails.propertyAddress}</div>
                                                            </div>
                                                        </div>
                                                      
                                                        
                                                        <div className={`lda-${name} col-lg-12 col-sm-12 pl-3`} style={{ paddingLeft: '0px' }}>
                                                            <div className="address-pair">
                                                                <div className="labelssss ">Items (Fixture/Fittings) to be included</div>
                                                                <div className="valuessss">: {values.FixFitIncluded}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} col-lg-12 col-sm-12 pl-3`} style={{ paddingLeft: '0px' }}>
                                                            <div className="address-pair">
                                                                <div className="labelssss ">Refer to the contract when available Items (Fixture/Fittings) to be excluded </div>
                                                                <div className="valuessss">: {values.FixFitExcluded}</div>
                                                            </div>
                                                        </div>
                                                        <div className={`lda-${name} col-lg-12 col-sm-12 pl-3`} style={{ paddingLeft: '0px' }}>
                                                            <div className="address-pair">
                                                                <div className="labelssss ">Easements/Covenants/Defects/Local Government Notices/Orders affecting the Property known to the Licensee </div>
                                                                <div className="valuessss">: {values.EasementConvenantDefects}</div>
                                                            </div>
                                                        </div>
                                                        <div className="pd-eventDate col-lg-5 col-sm-12 mt-3 pl-3 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="s1" style={{ marginRight: '335px' }} >Occupation:</div>
                                                                <div><Switch checked={values.PropOccupation} checkedChildren="Tenented" unCheckedChildren="Vacant" onChange={(e) => onChangeSwitch(e, "PropOccupation")} /></div>
                                                            </div>
                                                        </div>



                                                        <TextBox
                                                            colVal={12}
                                                            label="Terms and Conditions of Sale Known to the Licensee"
                                                            name="TermsAndConditionsOfSale"
                                                            placeholder="Nil"
                                                            value={values.TermsAndConditionsOfSale}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.TermsAndConditionsOfSale}
                                                            touched={touched.TermsAndConditionsOfSale}
                                                        />

<TextBox
                                                                                                                                                                         colVal={12}

                                                            label="Special Instructions about the Marketing and Showing of the Property"
                                                            name="SplInstructionAboutMarketing"
                                                            placeholder=""
                                                            value={values.SplInstructionAboutMarketing}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SplInstructionAboutMarketing}
                                                            touched={touched.SplInstructionAboutMarketing}
                                                        />


                                                    </div>
                                                </div>
                                            } />
                                                   <Collapse
                                            id={"Agreement-Authority"}
                                            title={"Price"}
                                            data={
                                                <div style={{ padding: "0px 30px" }}>
                                                    <div className="">
                                                    
                                                      
                                                        <TextBox
                                                        isMandatory
                                                        colVal={12}
                                                            label="Price at which the Property is to be offered (including GST if any)"
                                                            name="SellingFixedPrice"
                                                            placeholder="0.0"
                                                            value={values.SellingFixedPrice}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SellingFixedPrice}
                                                            touched={touched.SellingFixedPrice}
                                                        />
                                                         <SelectBox
                                                         isMandatory
                                                            colVal={12}
                                                            label="Licensee’s Recommendation as to the Most Suitable Method of Sale"
                                                            name="LicenceeRecomendMethodOfSale"
                                                            placeholder=""
                                                            options={methodSaleArr}
                                                            value={values.LicenceeRecomendMethodOfSale}
                                                            onChange={(val) => setFieldValue("LicenceeRecomendMethodOfSale", val.value)}
                                                            onBlur={handleBlur}
                                                            error={errors.LicenceeRecomendMethodOfSale}
                                                            touched={touched.LicenceeRecomendMethodOfSale}
                                                        />
                                                        <div>
                                                            <div className="s1 pl-3 mt-3">Agents opinion as to current estimated selling price range: <span style={{color:'red'}}>*</span></div>
                                                            <div className="pddpn-group" >
                                                                <TextBox
                                                                    colVal={6}
                                                                    name="SellingPriceFrom"
                                                                    placeholder="0.0"
                                                                    value={values.SellingPriceFrom}
                                                                    onChange={handleChangePrice}
                                                                    onBlur={handleBlur}
                                                                    error={errors.SellingPriceFrom}
                                                                    touched={touched.SellingPriceFrom}
                                                                    icon={
                                                                        // <BiPhone height="100%" width="100%" />
                                                                        <div>From $</div>
                                                                    }
                                                                />
                                                                <TextBox
                                                                    colVal={6}
                                                                    name="SellingPriceTo"
                                                                    placeholder="0.0"
                                                                    value={values.SellingPriceTo}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.SellingPriceTo}
                                                                    touched={touched.SellingPriceTo}
                                                                    icon={
                                                                        // <BiPhone height="100%" width="100%" />
                                                                        <div>To $</div>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                       
                                                       
                                                    </div>
                                                </div>
                                            } />
                                    </div>

                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className=" pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgreementPeriod;
