import React, { useRef, useState, useEffect } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { uploadmanagementagreementfromproperty,AccessKey,BucketName,Keyid,Region } from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import Header from "../../../CommonSection/Header/Header";
import Sidebar from "../../../CommonSection/SideBar/sideBar";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import { Spin } from "antd";
import Collapse from "../../../../src/input-components/collapse";
import { AiOutlineDelete } from "react-icons/ai";
import { uplodeFileManagement } from "../../../CommonSection/Schema/schema";

const initialValues = {
  AgreementDate : "",
  ManagementFee : "",
  MarketingFee: "",
  AdministrationFee : "",
  InitialWeekRent : "",
  ApplicationFeesForUtilites : "",
  AgreementFilePath:"",
};

const UploadAgreementManagement = () => {
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("propertiesList");
  const [isDragging, setIsDragging] = useState(false);
  const [getDetails, setGetDetails] = useState(null);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  var url = new URL(window.location.href);
  const ToastRef = useRef();
  const primaryDocumentRef = useRef(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [fileName, setFileName] = useState("");


  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);

          setUserId(parsedData.userId);

        } else {

          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
  }, [userId]);


  useEffect(() => {
    if (getDetails) {
        setFieldValue("PropertyPrice", getDetails.propertyPrice)
    }
  }, [getDetails]);

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };


  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: uplodeFileManagement,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });
  const handleFinalSubmit = () => {
    var urls = new URL(window.location.href);
    var propertyId = urls.searchParams.get("propertyId");
    const formData = {
      PropertyId: propertyId,

      AgreementDate: values.AgreementDate,

      ManagementFee : values.ManagementFee,

      MarketingFee : values.MarketingFee,

      AdministrationFee: values.AdministrationFee,

      InitialWeekRent : values.InitialWeekRent,

      ApplicationFeesForUtilites : values.ApplicationFeesForUtilites,

      AgreementFilePath: values.AgreementFilePath,
    };

    const url = uploadmanagementagreementfromproperty;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          var url = new URL(window.location.href);
          var propertyUId = url.searchParams.get("propertyUID");
          navigate(`/agency/properties?propertyUID=${propertyUId}&switchTab=Ownership&subTab=3`);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };


  const handleInputChange = (e, indexforfiles, documentType) => {
    const files = e.target.files;
    handleFileChange(files, indexforfiles, documentType);
  };

  const handleFileChange = async (files, indexforfiles, documentType) => {
    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      await handleUpload(selectedFile, indexforfiles, documentType);
    }
  };

  const handleUpload = async (selectedFile, indexforfiles, documentType) => {
    if (!selectedFile || !(selectedFile instanceof File)) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    AWS.config.update({
      accessKeyId: AccessKey,
      secretAccessKey: Keyid,
      region: Region,
    });
    const s3 = new AWS.S3();
    const fileExtension = selectedFile.name.split(".").pop();
    let new_name = "propertyagreement" + Date.now() + "." + fileExtension;
    const params = {
      Bucket: BucketName,
      Key: "propertyagreement/" + new_name,
      Body: selectedFile,
    };
    await s3.upload(params, (err, data) => {
        setLoading(false);
      if (err) {
        alert("Error uploading file. Please try again.");
      } else {     
        setFieldValue("AgreementFilePath",data.key)   
        ToastRef.current.notify(["success", "File uploaded successfully"]);
        setFileName(selectedFile.name);
      }
    });
  };
  const handleFileUploader = (documentType) => {
      primaryDocumentRef.current.click();
  };

  const handlecancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    navigate(`/agency/properties?propertyUID=${propertyUId}&switchTab=Ownership&subTab=2`);
  }

  const handledeletefile = () => {
    setFileName('')
   }
 

  return (
    <div className="dashboard-agency">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
        <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
          <div className="property-details-content df-all all-input">
          <Collapse
            id={"Agent"}
            title={"Upload Management Agreement"}
            data={<div>
            <div className="contacts-group">
            <TextBox  type={"date"} label="Agreement Start Date" name="AgreementDate" value={values.AgreementDate } onChange={handleChange} onBlur={handleBlur} error={errors.AgreementDate } touched={touched.AgreementDate } />
            <TextBox  label="Management Fee" name="ManagementFee" value={values.ManagementFee} onChange={handleChange} onBlur={handleBlur} error={errors.ManagementFee} touched={touched.ManagementFee} />
            <TextBox  label="Marketing Fee" name="MarketingFee" value={values.MarketingFee} onChange={handleChange} onBlur={handleBlur} error={errors.MarketingFee} touched={touched.MarketingFee} />
            <TextBox  label="Letting Fee" name="AdministrationFee" value={values.AdministrationFee} onChange={handleChange} onBlur={handleBlur} error={errors.AdministrationFee} touched={touched.AdministrationFee} />
            <TextBox  label="NCAT Attendence Fee" name="InitialWeekRent" value={values.InitialWeekRent} onChange={handleChange} onBlur={handleBlur} error={errors.InitialWeekRent} touched={touched.InitialWeekRent} />
            <TextBox  label="Admin Fee" name="ApplicationFeesForUtilites" value={values.ApplicationFeesForUtilites} onChange={handleChange} onBlur={handleBlur} error={errors.ApplicationFeesForUtilites} touched={touched.ApplicationFeesForUtilites} />
            <div className="contacts-group col-md-5">
                <div className="s1 mb-2">Upload Agreement</div>
           <div className={`upload-section ${isDragging ? "dragging" : ""}`} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
           <Spin spinning={loading}>
                  <div className="upload-setion-input">
                 {fileName === '' ? <div>
                 <img src="../../../assets/images/upload.png" alt="upload-icon" className="upload-icon" />
                    <span className="upload-select" onClick={() => handleFileUploader()}>Click to upload</span>
                    <input ref={primaryDocumentRef} type="file" onChange={(e) => handleInputChange(e, 0)} className="upload-input" name="upload" multiple />
                    or drag or drop
                  </div> : <div className="mt-3"><span className="upload-select">{fileName}</span><AiOutlineDelete onClick={handledeletefile} style={{fontSize:'18px',cursor:'pointer'}} /></div>}
                 </div>
                </Spin>
              </div>
           </div>
           {errors.AgreementFilePath && touched.AgreementFilePath ? <p className="form-error">{errors.AgreementFilePath}</p> : null}

            </div>
            </div>}/>
          </div>

          <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handlecancel}
            >
              Cancel
            </button>

            <button className="pdb-save-changes" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadAgreementManagement;
