import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatesalesAgrmntsolicitorDetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {dynamicUrl} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import AddressInput from "../../input-components/address";
import { BiEnvelope, BiPhone ,BiPlus} from "react-icons/bi";
import Collapse from "../../input-components/collapse";
import PlacesAutocomplete, {geocodeByAddress,} from "react-places-autocomplete";
import { FaFax } from "react-icons/fa";

dayjs.extend(customParseFormat);

const initialValues = {
    businessName:'',
    agencyLicenceNumber:"",
    agencyAbn:"",
    agencyName:"",
    agencyEmail:"",
    fax:"",
    mobile:"",
    agencyWorkPhone:"",

    SolicitorFirmName:'',
    SolicitorPrincipalName:'',
    SolicitorAddress:'',
    SolicitorEmail:'',
    SolicitorPhone:'',
    SolicitorFax :'',
};

const AgentAgreement = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);
    const [address, setAddress] = useState("");
    const [solicitorAddres, setSolicitorAddres] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);


    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("businessName" , getDetails.companyName)
          setAddress(getDetails.agencyAddress);
          setFieldValue('agencyName', getDetails.companyName)

          setFieldValue('SolicitorFirmName', getDetails.solicitorFirmName)
          setFieldValue('SolicitorPrincipalName', getDetails.solicitorPrincipalName)
          setFieldValue('SolicitorAddress', getDetails.solicitorAddress)
          setFieldValue('SolicitorEmail', getDetails.solicitorEmail)
          setFieldValue('SolicitorPhone', getDetails.solicitorPhone)
          setFieldValue('SolicitorFax', getDetails.solicitorFax)
        setSolicitorAddres(getDetails.solicitorAddress)
        }
      }, [getDetails]);

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        //validationSchema: salesAgent,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId:getDetails.id,
            SolicitorFirmName: values.SolicitorFirmName,
            SolicitorPrincipalName :values.SolicitorPrincipalName,
            SolicitorAddress :solicitorAddres,
            SolicitorEmail :values.SolicitorEmail,
            SolicitorPhone:values.SolicitorPhone,
            SolicitorFax :values.SolicitorFax,
            PrimaryUserID : getDetails.primaryUserID
        };
        const url = updatesalesAgrmntsolicitorDetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    onDataRecive(getDetails.propertyUId)
                    handleNextPage("AgreementPeriod")
                    ToastRef.current.notify(["success", returnData.message]);
                    handleCurrentPage("AgreementPeriod");
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleClear = () => {
        handleNextPage("")
    };

    const handleView = () => {
        setViewAddressDetails(!viewAddressDetails);
    };

    const handlepressSelect = async (addrs) => {
        setAddress(addrs);
        setFieldValue("address", addrs);
        try {
            const results = await geocodeByAddress(addrs);

            const details = results[0];

            const fullFormattedAddress = details.formatted_address;

            setAddress(fullFormattedAddress);

            const { address_components } = details;

            const address = {};

            address_components.forEach((component) => {
                const { types, long_name } = component;

                if (types.includes("subpremise")) {
                    address.unitNumber = long_name;
                } else if (types.includes("street_number")) {
                    address.streetNumber = long_name;
                } else if (types.includes("route")) {
                    address.streetName = long_name;
                } else if (types.includes("locality")) {
                    address.suburb = long_name;
                } else if (types.includes("postal_code")) {
                    address.postCode = long_name;
                } else if (types.includes("administrative_area_level_1")) {
                    address.state = long_name;
                }
                let latitude = details.geometry.location.lat();
                let longitude = details.geometry.location.lng();

                setLatitude(latitude);

                setLongitude(longitude);

                setValues({ ...values, ...address });
            });
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handlepressSelect1 = async (addrs) => {
        setSolicitorAddres(addrs);
        setFieldValue("SolicitorAddress", addrs);
        try {
            const results = await geocodeByAddress(addrs);

            const details = results[0];

            const fullFormattedAddress = details.formatted_address;

            setSolicitorAddres(fullFormattedAddress);

            const { address_components } = details;

            const address = {};

            address_components.forEach((component) => {
                const { types, long_name } = component;

                if (types.includes("subpremise")) {
                    address.unitNumber = long_name;
                } else if (types.includes("street_number")) {
                    address.streetNumber = long_name;
                } else if (types.includes("route")) {
                    address.streetName = long_name;
                } else if (types.includes("locality")) {
                    address.suburb = long_name;
                } else if (types.includes("postal_code")) {
                    address.postCode = long_name;
                } else if (types.includes("administrative_area_level_1")) {
                    address.state = long_name;
                }
                let latitude = details.geometry.location.lat();
                let longitude = details.geometry.location.lng();

                setLatitude(latitude);

                setLongitude(longitude);

                setValues({ ...values, ...address });
            });
        } catch (error) {
            console.error("Error", error);
        }
    };

    
  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  }

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                                <div className="text-parent" style={{ display: 'block' }}>
                                <div className="text7" style={{ fontSize: "24px" }}>
                                    Agent
                                </div> </div>
                                        <div>
                                            <Collapse
                                                id={"Agent"}
                                                title={"Agent"}
                                                data={
                                                    <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            label="Business Name"
                                                            name="businessName"
                                                            value={values.businessName}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.businessName}
                                                            touched={touched.businessName}
                                                        />
                                                        <TextBox
                                                            label="License Number"
                                                            name="agencyLicenceNumber"
                                                            value={values.agencyLicenceNumber}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyLicenceNumber}
                                                            touched={touched.agencyLicenceNumber}
                                                        />
                                                        <TextBox
                                                            label="ABN/ACN"
                                                            name="eventDate"
                                                            value={values.agencyAbn}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyAbn}
                                                            touched={touched.agencyAbn}
                                                        />
                                                         <TextBox
                                                            label="Trading as"
                                                            name="agencyName"
                                                            value={values.agencyName}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyName}
                                                            touched={touched.agencyName}
                                                        />
                                                       
                                                        <AddressInput
                                                        labelName={"Address"}
                                                            address={address}
                                                            setAddress={setAddress}
                                                            handlePressSelect={handlepressSelect}
                                                            error={errors.address}
                                                            touched={touched.address}
                                                            
                                                            handleView={handleView}
                                                            viewDetails={true}
                                                        />


                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                addressMargin={true}
                                                                label=""
                                                                placeholder="Unit Number"
                                                                id="unitNumber"
                                                                name="unitNumber"
                                                                value={values.unitNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.unitNumber}
                                                                touched={touched.unitNumber}
                                                            />
                                                        ) : null}

                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                label=""
                                                                placeholder="Street Number"
                                                                id="streetNumber"
                                                                name="streetNumber"
                                                                value={values.streetNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.streetNumber}
                                                                touched={touched.streetNumber}
                                                            />
                                                        ) : null}

                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                label=""
                                                                placeholder="Street Name"
                                                                id="streetName"
                                                                name="streetName"
                                                                value={values.streetName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.streetName}
                                                                touched={touched.streetName}
                                                            />
                                                        ) : null}

                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                label=""
                                                                placeholder="Suburb"
                                                                id="suburb"
                                                                name="suburb"
                                                                value={values.suburb}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.suburb}
                                                                touched={touched.suburb}
                                                            />
                                                        ) : null}

                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                label=""
                                                                placeholder="State"
                                                                id="state"
                                                                name="state"
                                                                value={values.state}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.state}
                                                                touched={touched.state}
                                                            />
                                                        ) : null}

                                                        {viewAddressDetails ? (
                                                            <TextBox
                                                                label=""
                                                                placeholder="Postcode"
                                                                id="postCode"
                                                                name="postCode"
                                                                value={values.postCode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.postCode}
                                                                touched={touched.postCode}
                                                            />
                                                        ) : null}
                                                          <TextBox
                                                            label="Phone"
                                                            placeholder="Phone"
                                                            name="agencyWorkPhone"
                                                            value={values.agencyWorkPhone}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyWorkPhone}
                                                            touched={touched.agencyWorkPhone}
                                                            icon={
                                                                <BiPhone height="100%" width="100%" />
                                                            }
                                                        />
                                                         <TextBox
                                                            label="PostCode"
                                                            placeholder="Post Code"
                                                            name="agencyPostCode"
                                                            value={values.agencyPostCode}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyPostCode}
                                                            touched={touched.agencyPostCode}
                                                        />
                                                         <TextBox
                                                            label="Fax"
                                                            placeholder=""
                                                            name="fax"
                                                            value={values.fax}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.fax}
                                                            touched={touched.fax}
                                                        />
                                                        <TextBox
                                                            label="Mobile"
                                                            placeholder="Mobile"
                                                            name="phone"
                                                            value={values.mobile}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.mobile}
                                                            touched={touched.mobile}
                                                            icon={
                                                                <BiPhone height="100%" width="100%" />
                                                            }
                                                        />
                                                      
                                                        <TextBox
                                                            label="Email address"
                                                            placeholder="Email"
                                                            name="agencyEmail"
                                                            value={values.agencyEmail}
                                                            //onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.agencyEmail}
                                                            touched={touched.agencyEmail}
                                                            icon={
                                                                <BiEnvelope height="100%" width="100%" />
                                                            }
                                                        />

                                                    </div>
                                                } />
                                                <Collapse
                                                id={"PRINCIPAL’S SOLICITOR / LICENSED CONVEYANCER"}
                                                title={"PRINCIPAL’S SOLICITOR / LICENSED CONVEYANCER"}
                                                data={
                                                    <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                        <TextBox
                                                            label="Company's Name"
                                                            name="SolicitorFirmName"
                                                            value={values.SolicitorFirmName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SolicitorFirmName}
                                                            touched={touched.SolicitorFirmName}
                                                        />

                                                        <TextBox
                                                            label="Principal Name "
                                                            name="SolicitorPrincipalName"
                                                            value={values.SolicitorPrincipalName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SolicitorPrincipalName}
                                                            touched={touched.SolicitorPrincipalName}
                                                        />
                                                        
                                                        <AddressInput
                                                            labelName={"Address"}
                                                            address={solicitorAddres}
                                                            setAddress={setSolicitorAddres}
                                                            handlePressSelect={handlepressSelect1}
                                                            error={errors.SolicitorAddress}
                                                            touched={touched.SolicitorAddress}
                                                        />

                                                          <TextBox
                                                            label="Phone"
                                                            placeholder="Phone"
                                                            name="SolicitorPhone"
                                                            value={values.SolicitorPhone}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SolicitorPhone}
                                                            touched={touched.SolicitorPhone}
                                                            icon={
                                                                <BiPhone height="100%" width="100%" />
                                                            }
                                                        />
                                                        
                                                         <TextBox
                                                            label="Fax"
                                                            placeholder=""
                                                            name="SolicitorFax"
                                                            value={values.SolicitorFax }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SolicitorFax }
                                                            touched={touched.SolicitorFax }
                                                            icon={
                                                                <FaFax height="100%" width="100%" />
                                                            }
                                                        />
                                                        <TextBox
                                                            label="Email address"
                                                            placeholder="Email"
                                                            name="SolicitorEmail"
                                                            value={values.SolicitorEmail }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.SolicitorEmail }
                                                            touched={touched.SolicitorEmail }
                                                            icon={
                                                                <BiEnvelope height="100%" width="100%" />
                                                            }
                                                        />

                                                    </div>
                                                } />
                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                    <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="pdb-cancel"
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentAgreement;
