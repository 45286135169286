import * as Yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const validDate = Yup.string().required("Enter the date");
const validEmail = Yup.string().email().required("Enter your email");
const validOptionalEmail = Yup.string().nullable().email("Invalid Email");
const validAustralianMob = Yup.string()
  .matches(
    /^(04)\d{8}$/,
    "Phone number must start with 04 and be 10 digits long"
  )
  .min(5, "Number must be greateer than 5 digits")
  .required("Enter Mobile Number");

const validOptionalAustralianMob = Yup.string()
  .nullable()
  .matches(
    /^(04)\d{8}$/,
    "Phone number must start with 04 and be 10 digits long"
  )
  .min(5, "Number must be greateer than 5 digits");

const validBSB = Yup.string()
  .matches(/^[0-9]{6}$/, "BSB must be a 6-digit number")
  .length(6)
  .required()
  .label("BSB Number");

const validABN = Yup.string()
  .matches(/^[0-9]{11}$/, "ABN must be a 11-digit number")
  .required()
  .label("ABN Number");

const validACN = Yup.string()
  .matches(/^[0-9]{9}$/, "ACN must be a 9-digit number")
  .required()
  .label("ACN Number");

const validFname = Yup.string()
  .min(2, "FirstName must be greater than 1 word")
  .max(25)
  .required("First Name is required");

const validName = Yup.string()
  .min(2, "Name must be greater than 1 word")
  .max(25)
  .required(" Name is required");

const validLname = Yup.string()
  .min(2, "LastName must be greater than 1 word")
  .max(25)
  .required("Last Name is required");

const validMname = Yup.string()
  .min(2, "Middle Name must be greater than 1 word")
  .max(25)
  .required("Middle Name is required");
const validOptionalMname = Yup.string()
  .min(2, "Middle Name must be greater than 1 word")
  .max(25);

const validNumber = Yup.string().matches(/^[0-9]+$/, "accept only numbers");

const validDocNumber = Yup.string().required("Document Number is Required");

const validDocType = Yup.string().required("Document Type is Required");

export const signUpSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),

  email: validEmail,
  password: Yup.string()
    .matches(passwordRules, { message: "Create a stronger password" })
    .required("Enter the password."),

  cpassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
  rem: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

export const signInSchema = Yup.object({
  email: validEmail,
  password: Yup.string()
    .matches(passwordRules, { message: "Password is weak" })
    .required("Password is required"),
});

export const forgotSchema = Yup.object({
  email: Yup.string().email("Enter a valid Email").required("Enter your email"),
});

export const amoSchema = Yup.object({
  fname: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  email: validEmail,
  number: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
});

export const addresshistorySchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  fname1: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname1: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  fname2: Yup.string()
    .min(2, "FirsstName must be greater than 1 word")
    .max(25)
    .required("First Name is required"),
  lname2: Yup.string()
    .min(2, "LastName must be greater than 1 word")
    .max(25)
    .required("Last Name is required"),
  phone1: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  phone2: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  date1: Yup.string().required("Enter the date"),
  date2: validDate,
  type: Yup.string().required("Please select "),
});

export const employementSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: Yup.string().required("Enter the date"),
  income: Yup.string().required("Enter the income"),
});

export const VerificationSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  phone: Yup.string()
    .min(5, "Number must be greateer than 5 digits")
    .required("Enter Mobile Number"),
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: Yup.string().required("Enter the date"),
  income: Yup.string().required("Enter the income"),
});

export const questionnaireSchema = Yup.object({
  value1: Yup.string().required("Please select Value"),
  value2: Yup.string().required("Please select Value"),
  value3: Yup.string().required("Please select Value"),
  value4: Yup.string().required("Please select Value"),
});

export const commonValidationSchema = Yup.object({
  companyname: Yup.string()
    .min(2, "Company Name must be greater than 1 word")
    .max(25)
    .required("Company Name is required"),
  role: Yup.string()
    .min(2, "Role must be greater than 1 word")
    .max(25)
    .required("Role is required"),
  name: Yup.string()
    .min(2, "Name must be greater than 1 word")
    .max(25)
    .required("Name is required"),
  email: validEmail,
  incometype: Yup.string().required("Please select income tenure"),
  otherincometype: Yup.string().required("Please select other income tenure"),
  startdate: validDate,
  income: Yup.string().required("Enter the income"),
  date: validDate,
  phone: validAustralianMob,
  fname: validFname,
  lname: validLname,
});

//create Agency

export const AgencyPrincipalDetails = Yup.object({
  jobTitle: Yup.string().required().label("Job Title"),
  firstName: validFname,
  lastName: validLname,
  phone: validAustralianMob,
  email: validEmail,
});
export const UserVerificationSchema = Yup.object({
  primaryDocumentType: validDocType,
  primaryDocFirstName: validFname,
  primaryDocMidName: validOptionalMname,
  primaryDocLastName: validLname,
  primaryDocNumber: validDocNumber,
  primaryDocExpiry: Yup.string().required().label("Document Expiry"),
  secondaryDocumentType: validDocType,
  secondaryDocFirstName: validFname,
  secondaryDocMidName: validLname,
  secondaryDocLastName: validDocNumber,
  secondaryDocNumber: validDocNumber,
  secondaryDocExpiry: Yup.string().required().label("Document Expiry"),
});

export const LicenseDetailsSchema = Yup.object({
  license: Yup.string().required("License Required"),
  corporateLicenseNumber: validNumber.label("License Number"),
  corporateLicenseNumberExpiry: Yup.string().required(
    "Expiry Date is required"
  ),
  expiryDate: Yup.string().required("Expiry Date is required"),
});

export const AgencyCompanyDetailsSchema = Yup.object({
  compnayName: validName,
  tradingName: validName,
  abnNum: validABN,
  acnNumber: validACN,
  tradingRegion: Yup.string().required().label("Trade state"),
  companyRegNumber: Yup.string().required().label("Company Address"),
  companyRegNumberStreetNumber: Yup.string().required().label("Street Number"),
  companyRegNumberStreetName: Yup.string().required().label("Street Name"),
  companyRegNumberSuburb: Yup.string().required().label("Suburb"),
  companyRegNumberPostCode: Yup.string().required().label("Postcode"),
  companyRegNumberState: Yup.string().required().label("State"),
});

export const AgencyInsuranceDetailsSchema = Yup.object({
  insuranceCompanyName: Yup.string().required().label("Company Name"),
  insurancePolicyNumber: Yup.string().required().label("Policy Number"),
  insurancePolicyExpiry: Yup.string().required().label("Policy Expiry Date"),
});

export const AgencyPaymentDetailsSchema = Yup.object({
  saleAccountName: Yup.string().required("Account Name is Required"),
  saleAccountNumber: Yup.string().required().label("Account Number"),
  saleBsb: validBSB,
});

export const AgencyProfilePersonalDetailsSchema = Yup.object({
  firstName: validFname,
  lastName: validLname,
  phone: validAustralianMob,
  email: validEmail,
  jobTitle: Yup.string().required("jobTitle is Required"),
  address: Yup.string().required("Address is Required"),
  license: Yup.string().required("license is Required"),
  expiryDate: Yup.string().required("expiryDate is Required"),
});

export const AgencyProfileCompanyDetailsSchema = Yup.object({
  compnayName: Yup.string().required("Company Name is Required"),
  companyRegNumber: Yup.string().required("Company Address is Required"),
  tradingName: Yup.string().required("Trading Name is Required"),
  companyKey: Yup.string().required("Company Key is Required"),
  acnNumber: validACN,
  abnNum: validABN,
  services: Yup.string().required("Services is Required"),
  corporateLicenseNumber: Yup.string().required(
    "Corporate License Number is Required"
  ),
  corporateLicenseNumberExpiry: Yup.string().required(
    "Corporate License Number Expiry is Required"
  ),
  tradingRegion: Yup.string().required("Trading Region is Required"),
  director: Yup.string().required("Director is Required"),

  companyRegNumberStreetNumber: Yup.string().required().label("Street Number"),
  companyRegNumberStreetName: Yup.string().required().label("Street Name"),
  companyRegNumberSuburb: Yup.string().required().label("Suburb"),
  companyRegNumberPostCode: Yup.string().required().label("Postcode"),
  companyRegNumberState: Yup.string().required().label("State"),
});

export const AgencyProfileTrustAccountDetailsSchema = Yup.object({
  rentalBsb: validBSB,
  saleBsb: validBSB,
});

export const AgencyPersonalDetails = Yup.object({
  agentType: Yup.string().required("Please select Type"),
});

export const AgentLicensingDetailsSchema = Yup.object({
  licenseType: Yup.string().required("Agent Type is Required"),
  licenseNumber: Yup.string()
    .required("Number is required")
    .matches(/^[0-9]+$/, "Accept only numbers"),
  licenseExpirydate: Yup.string().required("Expiry Date is required"),
});

export const AgentExperienceSchema = Yup.object({
  yearOfExperience: Yup.object().required("Years of experience is Required"),
  areaOfExpertise: Yup.array().required("Area of experience is Required"),
  profileDesc: Yup.string().required("Profile Description is Required"),
});

export const AgentContactDetailsSchema = Yup.object({
  reportTo: Yup.string().required("Report to is Required"),
});

export const PropertyDetailsSchema = Yup.object({
  propertyType: Yup.string().required("Property Type is Required"),
  address: Yup.string().required("Address  is Required"),
  assignedTo: Yup.string().required("Assigned To  is Required"),
});

export const OwnershipSchema = Yup.object({
  phone: validOptionalAustralianMob,
  email: validOptionalEmail,
  firstName: Yup.string().required("First Name is Required"),
});
export const PropertyLegalSchema = Yup.object({
  sellerEmail: validOptionalEmail,
  buyerEmail: validOptionalEmail,
  buyerNumber: validOptionalAustralianMob,
  sellerNumber: validOptionalAustralianMob,
});

//Contact Schema

export const CompanyDetailsContactSchema = Yup.object({
  contactname: validName,
  acn: validACN,
  abn: validABN,
});

export const ContactDetailsSchema = Yup.object({
  firstName: Yup.string().required("First Name to is Required"),
  mobileNumber: validOptionalAustralianMob,
  email: validOptionalEmail,
});

export const ContactOthersSchema = Yup.object({
  spouseMobileNumber: validOptionalAustralianMob,
  spouseEmail: validOptionalEmail,
});

export const addNewUserOpenHome = Yup.object({
  firstName: Yup.string().required("First Name  is Required"),
  ContactNo: validAustralianMob,
});

export const AddOpenHomeSchema = Yup.object({
  property: Yup.string().required("Property for is Required"),
  eventDate: Yup.string().required("EventDate is Required"),
  //startTime: Yup.string().required("Start Time is Required"),
  // endTime: Yup.string().required("End Time is Required"),
});

export const addNewTask = Yup.object({
  assignedTo: Yup.string().required("Assignee is Required"),
  DataTime: Yup.string().required("Due Date is Required"),
  taskName: Yup.string().required("task Name is Required"),
});

export const agreementPeriodValid = Yup.object({
  AgreementDate: Yup.string().required("Commencing On is Required"),
});

export const feesAndChargesValid = Yup.object({
  InitialWeekRent: Yup.string().required("1 week Rent is Required"),
  ManagementFee: Yup.string().required("Management Fee of is Required"),
  MarketingFee: Yup.string().required("Marketing Fee of is Required"),
  AdministrationFee: Yup.string().required(
    "An Administration Fee of is Required"
  ),
});

export const tribunalFees = Yup.object({
  ApplicationFeesForUtilites: Yup.string().required(
    "Amount Per Month is Required"
  ),
  OfficeExpenses: Yup.string().required("Amount is Required"),
  PAccountName: Yup.string().required("A/C Name is Required"),
  PBSB: validBSB,
  PAccountNo: Yup.string()
    .required("A/C No is Required")
    .matches(/^[0-9]+$/, "A/C No must contain only numbers"),
});

export const tribunalFeesAgency = Yup.object({
  ApplicationFeesForUtilites: Yup.string().required(
    "Amount Per Month is Required"
  ),
  //OfficeExpenses: Yup.string().required("Amount is Required"),
});

export const prominantalActivities = Yup.object({
  ThirdPartyName: Yup.string().required("Name of Third party is Required"),
  AmountOfRebateDiscount: Yup.string().required(
    "Estimated Amount of Rebate, Discount or Commission is Required"
  ),
});

export const tenantAgreement = Yup.object({
  ThirdPartyName: Yup.string().required("Name of Third party is Required"),
  AmountOfRebateDiscount: Yup.string().required(
    "Estimated Amount of Rebate, Discount or Commission is Required"
  ),
});

export const managementAgreement = Yup.object({
  contactEmail: validEmail,
  fName: Yup.string().required("First Name is Required"),
  lName: Yup.string().required("Last Name is Required"),
  rent: Yup.number()
    .min(1, "Rent must be greater than 0")
    .required("Rent is Required")
    .nullable(true),
  bondAmount: Yup.number()
    .min(1, "Rent must be greater than 0")
    .required("Rent is Required")
    .nullable(true),
  mobileNumber: Yup.string().required("Mobile number is Required"),
  address: Yup.string().required("Address is Required"),
  propertyUnfurnished: Yup.boolean()
    .test(
      "is-true",
      "Property Furnished is Required",
      (value) => value === true
    )
    .required("Property Furnished is Required"),
});

export const RepairsAndMaintainanceValidation = Yup.object({
  SupplyUrgentRepairToTenant: Yup.boolean().required("Required"),
  LooseFillAsbestosInstallation: Yup.boolean().required("Required"),
  SwimmingPoolRegistered: Yup.boolean().required("Required"),
  SmokeAlarm: Yup.boolean().required("Required"),
  HealthIssues: Yup.boolean().required("Required"),
  Flooding: Yup.boolean().required("Required"),
  Bushfire: Yup.boolean().required("Required"),
  SeriousViolentCrime: Yup.boolean().required("Required"),
  ParkingRestriction: Yup.boolean().required("Required"),
  ShareDriveway: Yup.boolean().required("Required"),
  AffectdFlooding: Yup.boolean().required("Required"),
  AffectdBushfire: Yup.boolean().required("Required"),
  AffectdSeriousViolent: Yup.boolean().required("Required"),
  IsContractForSalePremises: Yup.boolean().required("Required"),
  IsProposelToSell: Yup.boolean().required("Required"),
  HasMortgageeCommenced: Yup.boolean().required("Required"),
  isMortgageeTakgActnPossssion: Yup.boolean().required("Required"),
  WaterEfficiencyByNSW: Yup.boolean().required("Required"),
  PrincipalWarrantsSmokeAlarm: Yup.boolean().required("Required"),
});

export const leaserent = Yup.object({
  BSB: validBSB,
  AccountName: Yup.string().required("Account No is required."),
  AccountNo: Yup.string()
    .required("A/C No is Required")
    .matches(/^[0-9]+$/, "A/C No must contain only numbers"),
  RentAmount: Yup.string().required("Rent is required."),
  Period: Yup.string().required("Period is required."),
});

export const propertyListingSale = Yup.object().shape({
  // Other field validations...
  listingStatus: Yup.string().required("Listing Status is required."),
  propertyPrice: Yup.string().required("Property price is required"),
  leadAgent: Yup.string().required("Lead agent is required."),
});

export const propertyListingRental = Yup.object().shape({
  // Other field validations...
  listingStatus: Yup.string().required("Listing Status is required."),
  leadAgent: Yup.string().required("Lead agent is required."),
  rentPrice: Yup.string().required("Rent price is required."),
  rentTenure: Yup.string().required("Rent tenture To  is Required"),
});

export const rentDetails = Yup.object().shape({
  Rent: Yup.number().typeError("Rent must be a number"),
  BondAmount: Yup.number().typeError("Bond Amount must be a number"),
  BondInTrust: Yup.number().typeError("Bond In Trust must be a number"),
  Deposited: Yup.number().typeError("Deposited must be a number"),
  Balance: Yup.number().typeError("Balance must be a number"),
  RentReviewFrequency: Yup.number().typeError(
    "Rent Review Frequency must be a number"
  ),
});

export const salesAgreement = Yup.object({
  contactEmail: validEmail,
  fName: Yup.string().required("First Name is Required"),
  lName: Yup.string().required("Last Name is Required"),
  mobileNumber: Yup.string().required("Mobile number is Required"),
  address: Yup.string().required("Address is Required"),
});

export const salesAgreementPeriod = Yup.object({
  AgreementStartDate: Yup.string().required("From date is Required"),
  AgreementEndDate: Yup.string().required("To date is Required"),
  FixFitIncluded: Yup.string().required("Fix Fit Included is Required"),
  FixFitExcluded: Yup.string().required("Fix Fit Excluded is Required"),
  SellingPriceFrom: Yup.number()
    .typeError("Selling Price From must be a number")
    .required("Selling Price From is required"),
  SellingPriceTo: Yup.number()
    .typeError("Selling Price To must be a number")
    .required("Selling Price To is required")
    .test(
      "is-10-percent-more",
      "Selling Price To should always be at least 10% greater than Selling Price From",
      function (value) {
        const { SellingPriceFrom } = this.parent;
        return value >= SellingPriceFrom * 1.1 - 0.01; // adding a small tolerance
      }
    ),
  SellingFixedPrice: Yup.number()
    .required("Price at which the Property is to be offered is required")
    .min(0.01, "Price should be greater than 0"),
  LicenceeRecomendMethodOfSale: Yup.string().required(
    "Licensee’s Recommendation as to the Most Suitable Method of Sale Required"
  ),
});

export const salesRemunation = Yup.object({
  SalePriceFlatFee: Yup.number()
    .typeError("Sale price must be a number")
    .required("Sale Price Flat Fee is Required"),
  LicenceeEstimateSalePrice: Yup.number()
    .typeError("Licencee Estimate must be a number")
    .required("Licencee Estimate Sale Price is Required"),
  // GSTInclusiveRenumeration: Yup.number().typeError("GST Inclusive must be a number").required("GST Inclusive Renumeration is Required"),
  // PBSB:validBSB
});

export const tenantInformation = Yup.object({
  MaximumTenant: Yup.string().required("No more than Required"),
  SmokeAlarmType: Yup.number()
    .required("Please select a smoke alarm type")
    .oneOf([1, 2], "Please select a smoke alarm type"),
});

export const uplodeFileManagement = Yup.object({
  AgreementFilePath: Yup.string().required("File is Required"),
  MarketingFee: Yup.number().typeError("Marketing Fee must be a number"),
  AdministrationFee: Yup.number().typeError("Administration Fee must be a number"),
  InitialWeekRent: Yup.number().typeError("Initial Week Rent must be a number"),
});

export const uplodeFileTenant = Yup.object({
  AgreementFilePath: Yup.string().required("File is Required"),
  RentAmount: Yup.number().typeError("Rent Amount Week Rent must be a number"),
});

export const uplodeFileSale = Yup.object({
  AgreementFilePath: Yup.string().required("File is Required"),
  PropertyPrice: Yup.number().typeError("Property Price must be a number"),
  CommissionPercentage : Yup.number().typeError("Commission Percentage must be a number"),
  CommissionAmount : Yup.number().typeError("Commission Amount must be a number"),
  MarketingFee : Yup.number().typeError("Marketing Fee  must be a number"),
  ManagementFee: Yup.number().typeError("Management Fee  must be a number"),
  AdministrationFee: Yup.number().typeError("Administration Fee  must be a number"),
});

export const ManagementAgentAgreement = Yup.object({
  businessName: Yup.string().required("Business Name is Required"),
  agencyLicenceNumber: Yup.string().required(
    "Agency Licence Number is Required"
  ),
  agencyName: Yup.string().required("Agency Name is Required"),
  agencyEmail: Yup.string().required("Agency Email is Required"),
  address: Yup.string().required("Address is Required"),
});

export const ManagementViewPage = Yup.object({
  PAccountName: Yup.string().required("A/C Name is Required"),
  PBSB: validBSB,
  PAccountNo: Yup.string()
    .required("A/C No is Required")
    .matches(/^[0-9]+$/, "A/C No must contain only numbers"),
  supplyUrgentRepairToTenant: Yup.boolean().required("Required"),
  looseFillAsbestosInstallation: Yup.boolean().required("Required"),
  swimmingPoolRegistered: Yup.boolean().required("Required"),
  smokeAlarm: Yup.boolean().required("Required"),
  healthIssues: Yup.boolean().required("Required"),
  flooding: Yup.boolean().required("Required"),
  bushfire: Yup.boolean().required("Required"),
  seriousViolentCrime: Yup.boolean().required("Required"),
  parkingRestriction: Yup.boolean().required("Required"),
  shareDriveway: Yup.boolean().required("Required"),
  affectdFlooding: Yup.boolean().required("Required"),
  affectdBushfire: Yup.boolean().required("Required"),
  affectdSeriousViolent: Yup.boolean().required("Required"),
  isContractForSalePremises: Yup.boolean().required("Required"),
  isProposelToSell: Yup.boolean().required("Required"),
  hasMortgageeCommenced: Yup.boolean().required("Required"),
  isMortgageeTakgActnPossssion: Yup.boolean().required("Required"),
  waterEfficiencyByNSW: Yup.boolean().required("Required"),
  principalWarrantsSmokeAlarm: Yup.boolean().required("Required"),
});

export const TenantContactSchema = Yup.object({
  fname1: Yup.string().required("First Name is Required"),
  email: validOptionalEmail, 
  mobileNumber: validOptionalAustralianMob, 
});