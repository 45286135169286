import React from 'react';
import { useState, useEffect } from 'react';
import './statuspage.css'
import Header from '../../CommonSection/Header/Header';
import Footer from '../../CommonSection/Footer/Footer';
import ProgressbarLandlord from '../../CommonSection/ProgressLandlord/ProgressLandlord';
import { useNavigate } from 'react-router-dom';

import { BiCalendar, BiEnvelope, BiMailSend, BiMap, BiMessage, BiPhone } from 'react-icons/bi';
import PlacesAutocomplete from "react-places-autocomplete";
import { GetCookie } from '../../CommonSection/Cookie/Session';
import axios from 'axios';
import { useFormik } from "formik";
import Select from 'react-select';
import { addLicenseDetails, getPendingAgencyDetails, resubmitAgency } from '../../CommonSection/apiresources';


const initialValues = {
    lnumber: "",
    lexpiry: "",
}

const Statuspage = () => {

    const navigate = useNavigate();
    const [dates, setdates] = useState("");
    const [tenure, setTenure] = useState(0);
    const [userId, setUserId] = useState('')
    const [userUId, setUserUId] = useState('')
    const [address, setAddress] = useState("");
    const [selectedOption, setSelectedOption] = useState(1);
    const [response, setResponse] = useState('');
    const [status, setStatus] = useState();
    const [notes, setNotes] = useState();
    const [agencyId, setAgencyId] = useState("");
    useEffect(() => {

        const retrieveData = async () => {

            try {

                const userData = GetCookie('@UserData');
                if (userData) {

                    const parsedData = JSON.parse(userData)
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);

                }

            } catch (error) {

                console.error('Error retrieving data from AsyncStorage:', error);

            }

        };

        retrieveData();

    }, [userId]);

    useEffect(() => {

        if (userUId !== '') {

            const timeout = setTimeout(() => {
                GetApi();

                // console.log(agencyId);

            }, 2)

        }

    }, [userUId]);

    const GetApi = () => {





        const baseUrl = getPendingAgencyDetails;



        // Request body with login information



        const apiUrl = `${baseUrl}?UserUniqueId=${userUId}`;

        console.log('API URL:', apiUrl);

        axios.get(apiUrl)

            .then(response => {

                // Handle the response data

                if (response.data.status == 1) {

                    console.log(response.data)

                    setAgencyId(response.data.object.agencyUniqueID)

                    setStatus(response.data.object.approvalStatus)

                    setNotes(response.data.object.approvalNotes)

                    console.log('Hey Agency Id ' + ' ' + agencyId);


                } else {

                    alert(`Message: ${response.data.message}`);

                    console.log(response.data)

                }

            })

            .catch(error => {

                console.error('Error:', error);

            });

    };


    const onClickButtons = () => {
        navigate('/agency/principaldetails')
        
        // const api = resubmitAgency;
        // const url=api+"?AgencyUId="+agencyId;
        // axios.get(url)
        // .then(response=>{
        //     console.log(response.data.message);
        //     alert(response.data.message)
           
        // }).catch(error=>{
        //     console.error(error)
        // })
        
    }


    return (
        <div className="agency" >
            <Header />
            <div className="col-sm-12 col-md-10 col-lg-6 container">
                <div className="blank_space" style={{
                    backgroundColor: 'white',
                    height: '80px',
                    padding: '10px',
                }}>
                </div>


                <div className="agency-status-content">
                    <div className="agency-status-text">
                        <div>Your Application has been unsuccessful.</div>
                    </div>
                    <div className="agency-status-notes">
                        {notes}
                    </div>
                </div>
                <button className="agency-button resubmit" type="submit" onClick={() => onClickButtons()} >
                    <div className="text-parent">
                        <div className="text7">Resubmit</div>
                    </div>
                </button>
            </div>
            <Footer />
        </div>
    )
}

export default Statuspage