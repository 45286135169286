import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./insurancedetails.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useNavigate } from "react-router-dom";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import axios from "axios";
import { useFormik } from "formik";
import {
  addInsuranceDetails,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import { dateChnager } from "../../CommonSection/Utility/Utility";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { AgencyInsuranceDetailsSchema } from "../../CommonSection/Schema/schema";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus, eNewAgencyProfileStatus } from "../../../Utilities/enum";
import {
  navigateToPageWithDelay,
  getdataForAgencyProgressbar,
} from "../../CommonSection/Utility/ComponentFunction";
import DatePicker from "react-datepicker";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import Textbox from "../../../src/input-components/textbox"
import { FormatDate } from "../../../components/CommonSection/Utility/Utility"

const initialValues = {
  insurancePolicyNumber: "",
  insurancePolicyExpiry: null,
  insuranceCompanyName: "",
};

const InsuranceDetails = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [status, setStatus] = useState(0);
  const ToastRef = useRef();
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyInsuranceDetailsSchema,
    onSubmit: (values, action) => {
      submitInsurenceDetails();
    },
  });

  const [agencyId, setAgencyId] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        getInsuranceDetailsApi();
      }, 2);
    }
  }, [userUId]);

  const getInsuranceDetailsApi = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getPendingAgencyDetails}?UserUniqueId=${userUId}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let insuranceData = returnData.object;
          setAgencyId(insuranceData.agencyId);
          setStatus(insuranceData.status);
          setValues({ ...values, ...insuranceData });
        } else {
          ToastRef.current.notify(["error", "Internal Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", "Internal Error"]);
      });
  };

  const submitInsurenceDetails = () => {
    const formData = {
      AgencyId: agencyId,

      UserId: userId,

      UserUniqueId: userUId,

      InsurancePolicyNumber: values.insurancePolicyNumber,

      InsurancePolicyExpiry: values.insurancePolicyExpiry,

      InsuranceCompanyName: values.insuranceCompanyName,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addInsuranceDetails,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigateToPageWithDelay("/agency/PaymentDetails", navigate);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  return (
    <div className="agency">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        ></div>
        {
          <ProgressbarLandlord
            message={getdataForAgencyProgressbar(
              status,
              eNewAgencyProfileStatus.InsuranceDetails
            )}
          />
        }
        <div className="agency-page-title">{`Insurance Details`}</div>
        <form className="agency-input-form" onSubmit={handleSubmit}>
          <div className="insurance-input">

            <Textbox isMandatory label="Policy Company Name" colVal="mt-2" placeholder="Policy Company Name" name="insuranceCompanyName" value={values.insuranceCompanyName} onChange={handleChange} onBlur={handleBlur} error={errors.insuranceCompanyName} touched={touched.insuranceCompanyName} />
            <Textbox isMandatory label="Policy Number" colVal="mt-2" placeholder="Policy Number" name="insurancePolicyNumber" value={values.insurancePolicyNumber} onChange={handleChange} onBlur={handleBlur} error={errors.insurancePolicyNumber} touched={touched.insurancePolicyNumber} />

            {/* <div className="id-policy">
              <label htmlFor="policynumber" className="input-lable-agency">
                Policy Company Name*
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Policy Company Name"
                  value={values.insuranceCompanyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="insuranceCompanyName"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.insuranceCompanyName &&
              touched.insuranceCompanyName ? (
                <p className="form-error">
                  {errors.insuranceCompanyName}
                </p>
              ) : null}

              <label htmlFor="policynumber" className="input-lable-agency">
                Policy Number*
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control tenant-date"
                  placeholder="Policy Number"
                  value={values.insurancePolicyNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="insurancePolicyNumber"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errors.insurancePolicyNumber && touched.insurancePolicyNumber ? (
                <p className="form-error">{errors.insurancePolicyNumber}</p>
              ) : null}
            </div> */}

            <Textbox label="Policy expiry" isMandatory colVal="mt-2" type="date" name="insurancePolicyExpiry" placeholder="dd/mm/yyyy" value={FormatDate(values.insurancePolicyExpiry)} onChange={handleChange} onBlur={handleBlur} error={errors.insurancePolicyExpiry} touched={touched.insurancePolicyExpiry} />
          </div>
          <button className="agency-button" type="submit">
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default InsuranceDetails;
