import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import "./collapse.css";

const AddressInput = ({
  address,
  setAddress,
  handlePressSelect,
  error,
  touched,
  isMandatory,
  handleView,
  viewDetails,
  labelName,
  readOnly = false, // Default value for readOnly prop
}) => {
  const inputClassName = `input-address mb-3 ${
    error && touched ? "is-invalid" : ""
  }`;

  return (
    <div className="col-lg-6 col-sm-12">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label htmlFor="address" className="s1">
          {labelName ? labelName : ""}  {isMandatory ? <span style={{color:'red'}}>*</span> : null }
        </label>
      </div>
      <PlacesAutocomplete
        value={address}
        onSelect={handlePressSelect}
        onChange={setAddress}
        className=""
        searchOptions={{
          types: ["geocode"], // Restrict to addresses
          componentRestrictions: { country: "AU" }, // Restrict to Australia
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="input-group adpa-input">
            <input
              {...getInputProps({
                placeholder: "Address",
                className: inputClassName,
                readOnly: readOnly, // Pass the readOnly prop here
                style: { backgroundColor: readOnly ? "#e9ecef" : "white",borderRadius : isMandatory ? '4px' : '' }, // Apply gray background if readOnly
              })}
              
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "input-address-suggestion  mb-3"
                  : "input-address-suggestion mb-3";

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {viewDetails ? (
        <div
          style={{ float: "right", color:'#155b75', fontWeight:"600" , cursor:'pointer'}}
          className="address-viewDetails"
          onClick={handleView}
        >
          View Details
        </div>
      ) : null}
      {error && touched ? <p className="form-error">{error}</p> : null}
    </div>
  );
};

export default AddressInput;
