import {
  CurrentProfileString,
  GetCookie,
  currentProfile,
} from "../components/CommonSection/Cookie/Session";
import { UserRoleId } from "./enum";
import { useNavigate } from "react-router-dom";

export const addParametersForProfiles = (
  formData,
  customValues = false,
  newKeyNames,
  newKeyValues
) => {
  if (customValues)
    return generateCustomValues(formData, newKeyNames, newKeyValues);

  const currentProfileValue = currentProfile.get();
  if (currentProfileValue == UserRoleId.Agent)
    return generateAgentParameters(formData);
  if (currentProfileValue == UserRoleId.Agency) return formData;
};
export const generateCustomValues = (formData, newKeyNames, newKeyValues) => {
  const newValues = {};
  newkeyNames.forEach((key, index) => {
    newValues[key] = newKeyValues[index];
  });
  return { ...formData, ...newValues };
};

const generateAgentParameters = (formData) => {
  const userData = GetCookie("@UserData");
  if (userData) {
    const userDataObj = JSON.parse(userData);
    const agentParameters = {
      AgentUniqueID: userDataObj.agentUniqueId,
      AgentId: userDataObj.agentId,
    };
    const updateFormData = { ...formData, ...agentParameters };
    return updateFormData;
  } 
};


export const dynamicUrl = (navigate, path) => {
  //const navigate = useNavigate();
  const currentSideBar = currentProfile.get();
  navigate(currentSideBar === 2 ? `/agency/` + path : `/agent/` + path);
};
