import React, { useMemo, useRef, useState, useEffect } from "react";
import Header from "../../../CommonSection/Header/Header";
import debounce from "lodash/debounce";
import { BiPlus } from "react-icons/bi";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import { ListAllProperties, SearchProperty } from "../../../CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import "../../Dashboard-agent/AgentList.css";
import "./propertiesagency.css";
import { SelectButton } from "primereact/selectbutton";
import { ActionStatus } from "../../../../Utilities/enum";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import Sidebar from "../../../CommonSection/SideBar/sideBar"
import { Paginator } from 'primereact/paginator';
import { Select, Spin } from "antd";

const AgentList = () => {
  const [agentLists, setAgentLists] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [userId, setUserId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [propertyType, setPropertyType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAllProperties");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValues] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const fetchRef = useRef(0);
  const propertyTypeitems = [
    { name: "All", value: null },
    { name: "Sale", value: 1 },
    { name: "Rental", value: 2 },
  ];
  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText, searchValue, purpose, propertyType]);

  const PostApiWithSpinner = () => {
    const baseUrl = ListAllProperties;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: 10,
      AgencyId: agencyID,
      PropertyType: propertyType,
      PropertyId: searchValue,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          // let propertyData = response.list;
          const propertyData = response.list || [];
          let propertyList = propertyData.map((property) => ({
            Address: property.address,
            PropertyType: property.propertySaleRental === 1 ? "Sale" : "Rent",
            agentName: property.listingAgentName,
            dualAgentName: property.dualAgentName,
            isCommercial: property.isCommercial ? "Yes" : "No",
            isDualAgent: property.isDualAgent ? "Yes" : "No",
            isShowPrice: property.isShowPrice ? "Yes" : "No",
            propertyPrice: property.propertyPrice ? property.propertyPrice : property.rent,
            showText: property.showText,
            guid: property.propertyUId,
            uniqueId: property.propertyId,
            Isrea: property.reaUploadId === null ? "No" : "Yes",
          }));
          setAgentLists(propertyList);
          setTotalPages(response.totalCount)
          setChartData(response.object);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddAgentClick = () => {
    navigate("/agency/AddNewProperty");
  };

  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.Isrea);
    return (
      <div className="status-container">
        <span className={"common-statusbtn" + " " + statusClassName}>
          {rowData.Isrea}
        </span>
      </div>
    );
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case "No":
        return "grey-status";
      case "Yes":
        return "green-status";
      default:
        return "";
    }
  };


  const handleRowClick = (event, rowData) => {
    const clickedElement = event.originalEvent.target;
    if (clickedElement.className.includes("action-column")) {
      return;
    } else {
      dynamicUrl(navigate, "properties" + "?propertyUID=" + rowData.guid);
    }
  };


  const handlePageChange = (event, pageNumber) => {
    PostApiWithSpinner(event.page + 1, event.rows)
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = SearchProperty;
      const data = {
        Search: value,
        RecordsPerPage: 10,
        PageNo: 1,
      };

      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchProperty = (value) => {
    debounceFetcher(value);
    setSearchValues(value.value);
  };

  const handleClearProperty = () => {
    setSearchValues("");
  };

  return (
    <div className="dashboard-agency">
      <Header />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <Sidebar
            visible={sidebarVisible}
            handleTopicValue={handleTopicValue}
            topicValue={activeTopic}
          />
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className="filter-container agent-container">
                <div className="search-container">
                  <div className="text-parent">
                    <div className="text7" style={{ fontSize: "24px" }}>
                      List All Properties
                    </div>
                  </div>
                </div>
                <div className=" d-flex">
                  <SelectButton
                    className="mr-2"
                    optionLabel="name"
                    options={propertyTypeitems}
                    onChange={(e) => propertyTypeChangeHandler(e.value)}
                    value={propertyType}
                  />
                  <button
                    className="Add-Property-button"
                    type="submit"
                    onClick={handleAddAgentClick}
                  >
                    <div className="text-parent">
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      <div className="text7" style={{ fontSize: "14px" }}>
                        Add Listings
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div style={{ width: '100%' }}>
                    <div className="property-label">List of Properties</div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <div className="mt-4 col-12 col-lg-6 col-md-4 col-sm-4 customWidth mb-4" >
                      <Select
                        style={{ width: '100%' }}
                        className="landingPageSearchs"
                        mode="single"
                        showSearch
                        placeholder="Search for Property"
                        labelInValue
                        filterOption={false}
                        onSearch={debounceFetcher}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        options={options}
                        onChange={handleSearchProperty}
                        allowClear
                        onClear={handleClearProperty}
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  ref={dataTableRef}
                  value={agentLists}
                  size={"normal"}
                  tableStyle={{ minWidth: "20rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  rows={10}
                  dataKey=""
                  rowClassName="custom-row"
                  onRowClick={(e) => handleRowClick(e, e.data)}
                >
                  <Column
                    field="Address"
                    header="Address"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="PropertyType"
                    header="Property Type"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="agentName"
                    header="Listing agent"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="dualAgentName"
                    header="Dual AgentName"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="isCommercial"
                    header="Is Commercial"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="isDualAgent"
                    header="Is DualAgent"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="isShowPrice"
                    header="Is ShowPrice "
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="propertyPrice"
                    header="Price/Rent"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="showText"
                    header="Show Text"
                    className="common-table-style"
                    headerClassName="centered-header"
                  ></Column>
                  <Column
                    field="Status"
                    header="Is Rea Uploded"
                    body={statusBodyTemplate}
                    className="common-table-style "
                    headerStyle={{ textAlign: "center", whiteSpace: "nowrap" }}
                  ></Column>
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={totalPages} rowsPerPageOptions={[10, 20, 50]} onPageChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentList;
