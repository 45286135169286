import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import { addPropertyListings,ReaUplode} from "../../../CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { Dropdown as AntDropdown, Checkbox, Space, Tabs, Tag } from "antd";
import PhotoPage from "./propertyPhoto";
import LegalPage from "./propertyLegal";
import { Button } from "primereact/button";
import CustomModal from "../../../../src/input-components/Modal";
import { FaEdit } from "react-icons/fa";
import ShowValue from "../../../../src/input-components/ShowValue";
import { IoSettings } from "react-icons/io5";
import { FormatDate, FormatDateView } from "../../../CommonSection/Utility/Utility";
import { parseToDate } from "../../../CommonSection/Utility/ComponentFunction";
import { BiPlus} from "react-icons/bi";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import { BiDollar } from "react-icons/bi";

const { TabPane } = Tabs;

const initialValues = {
  listingStatus: "",
  propertyAgreementType: "",
  rentTenure: "",
  propertyPrice: "",
  rentPrice: "",
  isCommercial: "",
  isNew: "",
  dualAgent: "",
  leadAgent: "",
  isDualAgent: "",
  isShowPrice: "2",
  showText: "",
  reaUploadId: "",
  displaySales: false,
  displayRental: false,
  propertyFor: "",
  listingId: 0,
  DateAvailable: null
};

const PropertyListing = ({
  onDataRecive,
  handleparentsuccess,
  getDetails,
  reportTo,
  handleCurrentPage
}) => {
  const [userId, setUserId] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [leadAgent, setLeadAgent] = useState(null);
  const [dualAgent, setDualAgent] = useState(null);
  const [statusSaleData, setStatusSaleData] = useState([
    { label: "Active", value: 1 },
    { label: "Deleted", value: 2 },
    { label: "Off Market", value: 3 },
    { label: "Sold", value: 4 },
    { label: "Under Offer", value: 5 },
  ]);
  const [statusRentData, setStatusRentData] = useState([
    { label: "Active", value: 1 },
    { label: "Deposit Taken", value: 2 },
    { label: "Deleted", value: 3 },
    { label: "Off Market", value: 4 },
    { label: "Leased", value: 5 },
  ]);

  const [agreeData, setAgreeData] = useState([
    { label: "Auction", value: 1 },
    { label: "Exclusive", value: 2 },
    { label: "Multi List", value: 3 },
    { label: "Conjunctional", value: 4 },
    { label: "Open", value: 5 },
    { label: "Sale by Negotiation", value: 6 },
    { label: "Set Sale", value: 7 },
    { label: "Management", value: 8 },
    { label: "Tenant Lease", value: 8 },
  ]);

  const [rentData, setRentData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value: 2 },
  ]);
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const ToastRef = useRef();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [selectedLeadAgents, setSelectedLeadAgents] = useState([]);
  const [isReaUploaded, setIsReaUploaded] = useState(null);
  const [isDomainUploaded, setIsDomainUploaded] = useState(null);
  const [edit, setEdit] = useState(true);
  const [listStatusOptions, setListStatusOptions] = useState(false);
  const [displaySaleFields, setDisplaySaleFields] = useState(false);
  const [displayRentalFields, setDisplayRentalFields] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);
  const [listingId, setListingId] = useState(null)
  const finalReportToObject = [
    ...reportTo,
    {
      disabled: false,
      group: null,
      selected: false,
      text: "--Select--",
      value: "Select",
    },
  ];

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId, propertyType]);

  useEffect(() => {
    if (getDetails) {
      setEdit(false);
      setValues({ ...values, ...getDetails });
      if (getDetails.propertySaleRental === "1") {
        setFieldValue("listingStatus", getDetails.listingStatus);
        setFieldValue(
          "propertyAgreementType",
          getDetails.propertyAgreementType
        );
        setFieldValue("rentTenure", getDetails.rentTenure);
      } else {
        setFieldValue("listingStatus", getDetails.listingStatus);
        setFieldValue(
          "propertyAgreementType",
          getDetails.propertyAgreementType
        );
      }

      setFieldValue("propertyPrice", getDetails.propertyPrice);
      setFieldValue("isNew", getDetails.isNew.toString());
      setFieldValue("reaUploadId", getDetails.reaUploadId);
      setFieldValue("isCommercial", getDetails.isCommercial ? "1" : "2");
      setFieldValue("showText", getDetails.showText);
      setFieldValue("isShowPrice", getDetails.isShowPrice === true ? "1" : "2");
      setIsReaUploaded(getDetails.listingDetails?.reaUploadId);
      const dateAvailableForRent = getDetails.listingDetail ? getDetails.listingDetail.filter(listing => listing.propertySaleRental === 2) : [];
      setFieldValue("DateAvailable", dateAvailableForRent.length === 0 ? null : dateAvailableForRent);
      setIsDomainUploaded(getDetails.listingDetails?.domainUploadId);
      const matchingItem = reportTo.find(
        (item) => parseInt(item.value, 10) === getDetails.dualAgent
      );
      const matchingItem2 = reportTo.find(
        (item) => parseInt(item.value, 10) === getDetails.agentId
      );
      if (matchingItem) {
        setDualAgent(matchingItem.value);
        setFieldValue("dualAgent", matchingItem.value);
      }
      if (matchingItem2) {
        setLeadAgent(matchingItem2.value);
        setFieldValue("leadAgent", matchingItem2.value);
      }
      setFieldValue("propertyFor", parseInt(getDetails.propertySaleRental));
      setFieldValue("corporateLicenseNumberExpiry", parseToDate(getDetails.dateAvailable));
      let listStatusOptions = getDetails
        ? getDetails.propertySaleRental === "1"
          ? statusSaleData
          : statusRentData
        : [];
      setListStatusOptions(listStatusOptions)
      let displaySaleFields = getDetails
        ? getDetails.propertySaleRental === "1"
          ? true
          : false
        : false;
      setDisplaySaleFields(displaySaleFields)
      let displayRentalFields = getDetails
        ? getDetails.propertySaleRental === "2"
          ? true
          : false
        : false;
      setDisplayRentalFields(displayRentalFields)
    }
  }, [getDetails, reportTo]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (UploadPortal = false) => {
    let dualAgent = parseInt(values.dualAgent, 10);
    let leadAgent = parseInt(values.leadAgent, 10);
    const formData = {
      ListingId: values.listingId ? values.listingId : 0,
      ListingAgentId: leadAgent,
      PropertyId: getDetails ? getDetails.propertyId : 0,
      ListingStatus: values.listingStatus,
      PropertyAgreementType: values.propertyAgreementType,
      RentTenure: values.rentTenure,
      PropertyPrice: values.propertyPrice,
      RentPrice: values.rentPrice,
      IsCommercial: values.isCommercial === "1" ? true : false,
      IsNew: values.isNew,
      DualAgent: dualAgent,
      IsDualAgent: dualAgent ? true : dualAgent === "Select" ? null : false,
      IsShowPrice: values.isShowPrice === "1" ? true : false,
      ShowText: values.showText,
      ReaUploadId: values.reaUploadId,
      PropertySaleRental: values.propertyFor,
      DateAvailable: values.DateAvailable ? values.DateAvailable : null,
      UserId: userId,
    };
    if (UploadPortal) {
      formData.UploadPortal = true;
      formData.ReaUpload = reaUplode;
      formData.DomainUpload = domainUplode;
    }
    const url = addPropertyListings;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          handleparentsuccess(["success", returnData.message]);
          onDataRecive(getDetails.propertyUniqueID)
          setEdit(false)
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleLeadAgent = (e) => {
    const selectedLeadAgentValue = e.value;
    setLeadAgent(e.value);
    setFieldValue("leadAgent", e.value);
    setSelectedLeadAgents(selectedLeadAgentValue);
  };

  const updatedReportToWithoutSelectedLead = finalReportToObject.filter(
    (agent) => !selectedLeadAgents.includes(agent.value)
  );

  const handleDualAgent = (e) => {
    setDualAgent(e.value);
    setFieldValue("dualAgent", e.value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleItemClickInPropertyListings = (e) => {
    setOpenUpload(true);
  };


  const handleDisplaySaleOrRental = (e) => {
    setEdit(true);
    if (e === "sale") {
      setDisplaySaleFields(true);
      setDisplayRentalFields(false);
      setListStatusOptions(statusSaleData)
      setFieldValue("displaySales", true);
      setFieldValue("displayRental", false);
      setFieldValue("propertyFor", 1);
      const saleListing = getDetails.listingDetails ? getDetails.listingDetails.find(listing => listing.propertySaleRental === 1) : [];
      if (saleListing) {
        setFieldValue("listingId", saleListing.listingId);
        setFieldValue("listingStatus", saleListing.listingStatus);
        setFieldValue("propertyAgreementType", saleListing.propertyAgreementType);
        setFieldValue("propertyPrice", saleListing.propertyPrice);
        setFieldValue("isNew", saleListing.isNew.toString());
        setFieldValue("isCommercial", saleListing.isCommercial ? "1" : "2");
        setFieldValue("showText", saleListing.showText);
        setFieldValue("isShowPrice", saleListing.isShowPrice === true ? "1" : "2");
        const matchingItem1 = reportTo.find(
          (item) => parseInt(item.value, 10) === saleListing.listingAgentId
        );
        setLeadAgent(matchingItem1.value);
        setFieldValue("leadAgent", matchingItem1.value);
        const matchingItem2 = reportTo.find(
          (item) => parseInt(item.value, 10) === saleListing.dualAgent
        );
        setDualAgent(matchingItem2.value);
        setFieldValue("dualAgent", matchingItem2.value);
      } else {
        setFieldValue("listingId", 0);
      }
    } else if (e === "rent") {
      setDisplaySaleFields(false);
      setDisplayRentalFields(true);
      setListStatusOptions(statusRentData)
      setFieldValue("displaySales", false);
      setFieldValue("displayRental", true);
      setFieldValue("propertyFor", 2);
      const rentListing = getDetails.listingDetails ? getDetails.listingDetails.find(listing => listing.propertySaleRental === 2) : [];
      if (rentListing) {
        setFieldValue("listingId", rentListing.listingId);
        setFieldValue("listingStatus", rentListing.listingStatus);
        setFieldValue("isCommercial", rentListing.isCommercial ? "1" : "2");
        setFieldValue("isNew", rentListing.isNew.toString());
        setFieldValue("isShowPrice", rentListing.isShowPrice === true ? "1" : "2");
        setFieldValue("showText", rentListing.showText);
        setFieldValue("DateAvailable", parseToDate(rentListing.dateAvailable));
        const matchingItem1 = reportTo.find(
          (item) => parseInt(item.value, 10) === rentListing.listingAgentId
        );
        setLeadAgent(matchingItem1.value);
        setFieldValue("leadAgent", matchingItem1.value);
        const matchingItem2 = reportTo.find(
          (item) => parseInt(item.value, 10) === rentListing.dualAgent
        );
        setDualAgent(matchingItem2.value);
        setFieldValue("dualAgent", matchingItem2.value);
      } else {
        setFieldValue("listingId", 0);
      }
    }
  }

  const handleCancel = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if (propertyUId) {
      handleCurrentPage("Overview")
    } else {
      dynamicUrl(navigate, "propertiesList");
    }
  }

  const handleUpload = () => {
    setOpenUpload(true);
  };

  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode);
    } else if (type === "domain") {
      setDomainUplode(!domainUplode);
    }
  };

  const items = [
    {
      key: "2",
      label: <a onClick={handleUpload}>Upload</a>,
    },
  ];
  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handlePage = (data) => {
    handleCurrentPage(data)
  }

  const handleReaSubmit = () => {
    if (getDetails.propertyPictures.length === 0) {
      handleparentsuccess(["error", "Property Images are required."]);
    } else if (getDetails.propertyType != "16" && !getDetails.countBedrooms === null) {
      handleparentsuccess(["error", "A 'number of bedrooms' has not been provided for this property."]);
    } else if (getDetails.propertyType != "16" && !getDetails.countBathrooms === null) {
      handleparentsuccess(["error", "A 'number of bathrooms' has not been provided for this property."]);
    } else if (getDetails.propertyDescription === null || getDetails.propertyDescription === "") {
      handleparentsuccess(["error", "Please enter description for property"]);
    } else if (getDetails.propertyHeading === null) {
      handleparentsuccess(["error", "Please enter Heading for property"]);
    }
    else {
      const data = {
        propertyID: getDetails.propertyUniqueID,
        isrea: reaUplode,
        isdomain: domainUplode,
        isPrincipleLogged: true,
        ListingId: listingId
      };

      makeRequestWithSpinner(showSpinner, hideSpinner, ReaUplode, "post", data)
        .then((returnData) => {
          if (returnData.status === ActionStatus.Successfull) {
            handleparentsuccess(["success", returnData.message]);
            setOpenUpload(false);
          } else {
            handleparentsuccess(["error", returnData.message]);
          }
        })
        .catch((error) => {
          handleparentsuccess(["error", error.message]);
        });
    }
  };
  const handleItemClick = (e, listingId) => {
    e.preventDefault();
    setListingId(listingId)
  };
console.log(getDetails,'getDetails')
  return (
    <div>
         <ToastAlert ref={ToastRef} />
      <Tabs
        className="subTabs"
        activeKey={activeTab}
        onChange={handleTabChange}
        style={{ padding: '0px 25px 0px 25px' }}
      >
        <TabPane tab="Photos" key="1">
          <PhotoPage
            handleparentsuccess={handleparentsuccess}
            getDetails={getDetails}
            handleCurrentPage1={handlePage}
          />
        </TabPane>
        <TabPane tab="Property Listing" key="2">
          {getDetails && (
            <div className="handle-Editpart1">
              <div >
                <Button
                  type="button"
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      Sale
                    </span>
                  } className={
                    values.displaySales === true
                      ? "custom-button-success"
                      : "p-button-secondary"
                  }
                  onClick={(e) => handleDisplaySaleOrRental("sale")}
                  text
                  raised
                  style={{ borderRadius: "5px" }}
                />
                <Button
                  type="button"
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      Rent
                    </span>
                  } className={
                    values.displayRental === true
                      ? "custom-button-success"
                      : "p-button-secondary"
                  }
                  onClick={(e) => handleDisplaySaleOrRental("rent")}
                  text
                  raised
                  style={{ borderRadius: "5px" }}
                />
              </div>

            </div>
          )}

          {edit ? (
            <div>
              <CustomModal
                title="Upload property to"
                width={500}
                closable={closeUpload}
                visible={openUpload}
                onCancel={closeUpload}
                modalData={
                  <div
                    className="row"
                    style={{ marginTop: "30px", marginLeft: "10px" }}
                  >
                    <div className="col-lg-5 col-sm-12">
                      <Checkbox
                        checked={reaUplode}
                        onChange={() => onChangeUpload("rea")}
                      >
                        REA {isReaUploaded ? "(Update)" : null}
                      </Checkbox>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                      <Checkbox
                        checked={domainUplode}
                        onChange={() => onChangeUpload("domain")}
                      >
                        Domain {isDomainUploaded ? "(Update)" : null}
                      </Checkbox>
                    </div>
                  </div>
                }
                footer={[
                  <div
                    key="footer"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{ marginRight: "20px" }}
                      className="pdb-save-changes mt-2"
                      onClick={handleReaSubmit}
                    >
                      Submit
                    </button>
                  </div>,
                ]}
              />
              <form onSubmit={handleSubmit} className="details-subform">
                <div className="property-details-content df-all all-input">
                  <div className="contacts-group" style={{ padding: "0px 30px" }}>
                    <div className="row pt-2 pl-3">
                      <div className="label">
                        <strong>Property For:</strong>
                      </div>
                      <strong style={{ color: 'black' }} className="value">{values.propertyFor === 1 ? "Sale" : "Rent"}</strong>
                    </div>
                    <SelectBox
                    label="Listing Status"
                      placeholder="Listing Status"
                      name="listingStatus"
                      options={listStatusOptions}
                      value={values.listingStatus}
                      onChange={(val) =>
                        setFieldValue("listingStatus", val.value)
                      }
                      error={errors.listingStatus}
                      touched={touched.listingStatus}
                    />

                    {displaySaleFields ? (
                      <SelectBox
                      label="Agreement Type"
                      placeholder="Agreement Type"
                        name="propertyAgreementType"
                        options={agreeData}
                        value={values.propertyAgreementType}
                        onChange={(val) =>
                          setFieldValue("propertyAgreementType", val.value)
                        }
                        error={errors.propertyAgreementType}
                        touched={touched.propertyAgreementType}
                      />
                    ) : null}
                    {displaySaleFields ? (
                      <TextBox
                      label="Property Price"
                      placeholder="Property Price"
                        name="propertyPrice"
                        value={values.propertyPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.propertyPrice}
                        touched={touched.propertyPrice}
                        icon={
                          <BiDollar height="100%" width="100%" />
                        }
                      />
                    ) : null}
                    {displayRentalFields ? (
                      <TextBox
                      label="Rental Price"
                      placeholder="Rental Price"
                        name="rentPrice"
                        value={values.rentPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.rentPrice}
                        touched={touched.rentPrice}
                      />
                    ) : null}
                    {displayRentalFields ? (
                      <SelectBox
                      label="Rental Intervals"
                      placeholder="Rental Intervals"
                        name="rentTenure"
                        options={rentData}
                        value={values.rentTenure}
                        onChange={(val) => setFieldValue("rentTenure", val.value)}
                        error={errors.rentTenure}
                        touched={touched.rentTenure}
                      />
                    ) : null}
                    <div className="cda-AgentAddress col-lg-5 col-sm-12">
                      <label htmlFor="AgentAddress" className="s1">
                        Is Commercial
                      </label>
                      <div className="input-group mb-3">
                        <Button
                          type="button"
                          label="Yes"
                          className={
                            values.isCommercial === "1"
                              ? "custom-button-success"
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isCommercial", "1")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                        <Button
                          type="button"
                          label="No"
                          className={
                            values.isCommercial === "2"
                              ? "custom-button-success"
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isCommercial", "2")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>

                    <div className="cda-AgentAddress col-lg-5 col-sm-12">
                      <label htmlFor="AgentAddress" className="s1">
                        New or Established
                      </label>
                      <div className="input-group mb-3">
                        <Button
                          type="button"
                          label="New"
                          className={
                            values.isNew === "1"
                              ? "custom-button-success"
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isNew", "1")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                        <Button
                          type="button"
                          label="Established"
                          className={
                            values.isNew === "2"
                              ? "custom-button-success "
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isNew", "2")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>

                    <div className="cda-reportTo col-lg-6 col-sm-12">
                    <label htmlFor="trading state" className="s1 w-100">
                        Listing Agent
                      </label>
                      <Dropdown
                        value={leadAgent}
                        onChange={handleLeadAgent}
                        options={finalReportToObject}
                        optionLabel="text"
                        className="w-100 md:w-14rem"
                        placeholder="Listing Agent"
                      />

                      {errors.leadAgent && touched.leadAgent ? (
                        <p className="form-error">{errors.leadAgent}</p>
                      ) : null}
                    </div>
                    <div className="cda-reportTo col-lg-6 col-sm-12">
                    <label htmlFor="trading state" className="s1 w-100">
                        Dual Agent
                      </label>
                      <Dropdown
                        value={dualAgent}
                        onChange={handleDualAgent}
                        options={updatedReportToWithoutSelectedLead}
                        optionLabel="text"
                        className="w-100 md:w-14rem"
                        placeholder="Select dual agent"
                      />

                      {errors.dualAgent && touched.dualAgent ? (
                        <p className="form-error">{errors.dualAgent}</p>
                      ) : null}
                    </div>

                    {!displayRentalFields ? (<div className="cda-AgentAddress col-lg-5 col-sm-12">
                      <label htmlFor="AgentAddress" className="s1">
                        Show Price
                      </label>
                      <div className="input-group mb-3">
                        <Button
                          type="button"
                          label="Show Price"
                          className={
                            values.isShowPrice === "1"
                              ? "custom-button-success"
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isShowPrice", "1")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                        <Button
                          type="button"
                          label="Show Text"
                          className={
                            values.isShowPrice === "2"
                              ? "custom-button-success"
                              : "p-button-secondary"
                          }
                          onClick={() => setFieldValue("isShowPrice", "2")}
                          text
                          raised
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>) : null}
                    {!displayRentalFields && values.isShowPrice === "2" && (
                      <TextBox
                      label="Show Text"
                        isMandatory
                        placeholder="Show Text"
                        name="showText"
                        value={values.showText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.showText}
                        touched={touched.showText}
                      />
                    )}
                    {displayRentalFields ?
                      <TextBox
                      label="Date Available"
                        type="date"
                        name="DateAvailable"
                        placeholder="dd/mm/yyyy"
                        value={FormatDate(values.DateAvailable)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.DateAvailable}
                        touched={touched.DateAvailable}
                      /> : null}
                  </div>
                </div>
                <div className="property-details-buttons">
                  <button
                    className="pdb-cancel"
                    type="reset"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button className="pdb-save-changes" type="submit">
                    Save
                  </button>
                  <button
                    className="pdb-cancel"
                    type="button"
                    onClick={handleItemClickInPropertyListings}
                  >
                    Save & Publish
                  </button>
                </div>
              </form></div>
          ) : (
            <div className="" style={{ display: 'flex' }}>
              {getDetails.listingDetails?.length > 0 ? getDetails.listingDetails.map(listing => (
                (
                  listing.propertySaleRental === 1 && (
                    <div className="contacts-group">
                      <div style={{ padding: '30px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                          <a onClick={(e) => handleDisplaySaleOrRental("sale")}>
                            <Space>
                              <FaEdit style={{ fontSize: "24px" }} />
                            </Space>
                          </a>
                          <>
                            <AntDropdown
                              menu={{
                                items,
                              }}
                            >
                              <a onClick={(e) => handleItemClick(e, listing.listingId)}>
                                <Space>
                                  <IoSettings style={{ fontSize: "24px" }} />
                                </Space>
                              </a>
                            </AntDropdown>
                            <CustomModal
                              title="Upload property to"
                              width={500}
                              closable={closeUpload}
                              visible={openUpload}
                              onCancel={closeUpload}
                              modalData={
                                <div
                                  className="row"
                                  style={{ marginTop: "30px", marginLeft: "10px" }}
                                >
                                  <div className="col-lg-5 col-sm-12">
                                    <Checkbox
                                      checked={reaUplode}
                                      onChange={() => onChangeUpload("rea")}
                                    >
                                      REA {isReaUploaded ? "(Update)" : null}
                                    </Checkbox>
                                  </div>
                                  <div className="col-lg-5 col-sm-12">
                                    <Checkbox
                                      checked={domainUplode}
                                      onChange={() => onChangeUpload("domain")}
                                    >
                                      Domain {isDomainUploaded ? "(Update)" : null}
                                    </Checkbox>
                                  </div>
                                </div>
                              }
                              footer={[
                                <div
                                  key="footer"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{ marginRight: "20px" }}
                                    className="pdb-save-changes mt-2"
                                    onClick={handleReaSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>,
                              ]}
                            />
                          </>
                        </div>
                        <div key={listing.listingId}>
                          <Tag color={listing.domainUploadId ? "success" : "orange"}>
                            {listing.domainUploadId ? "Listed On Domain" : "Not Listed On Domain"}
                          </Tag>
                          <Tag color={listing.reaUploadId ? "success" : "orange"}>
                            {listing.reaUploadId ? "Listed on Rea" : "Not Listed on Rea"}
                          </Tag>

                          <div className="row pt-2">
                            <div className="label">
                              <strong>Property For:</strong>
                            </div>
                            <div className="value">{listing.propertySaleRental === 1 ? "Sale" : ""}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Listing Status:</strong>
                            </div>
                            <div className="value">{listing.sListingStatus}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Is Commercial:</strong>
                            </div>
                            <div className="value">{listing.isCommercial ? "Yes" : "No"}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>New or Established:</strong>
                            </div>
                            <div className="value">{listing.isNew === 1 ? "New" : "Established"}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Agreement Type :</strong>
                            </div>
                            <div className="value">{listing.sAgreementType}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Property Price :</strong>
                            </div>
                            <div className="value">{listing.propertyPrice}</div>
                          </div>
                          <ShowValue Label="Listing Agent" setData={listing.listingAgentName} />
                          <div className="row">
                            <div className="label">
                              <strong>Dual Agent :</strong>
                            </div>
                            <div className="value">{listing.dualAgentName}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Show Text:</strong>
                            </div>
                            <div className="value">{listing.showText}</div>
                          </div>
                          {listing.isShowPrice === "2" && (
                            <ShowValue Label="Show Text" setData={listing.showText} />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              )) : null}

              {getDetails.listingDetails?.length > 0 ? getDetails.listingDetails.map(listing => (
                (
                  listing.propertySaleRental === 2 && (
                    <div className="contacts-group">
                      <div style={{ padding: '30px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                          <a onClick={(e) => handleDisplaySaleOrRental("rent")} >
                            <Space>
                              <FaEdit style={{ fontSize: "24px" }} />
                            </Space>
                          </a>
                          <>
                            <AntDropdown
                              menu={{
                                items,
                              }}
                            >
                              <a onClick={(e) => handleItemClick(e, listing.listingId)}>
                                <Space>
                                  <IoSettings style={{ fontSize: "24px" }} />
                                </Space>
                              </a>
                            </AntDropdown>

                            <CustomModal
                              title="Upload property to"
                              width={500}
                              closable={closeUpload}
                              visible={openUpload}
                              onCancel={closeUpload}
                              modalData={
                                <div
                                  className="row"
                                  style={{ marginTop: "30px", marginLeft: "10px" }}
                                >
                                  <div className="col-lg-5 col-sm-12">
                                    <Checkbox
                                      checked={reaUplode}
                                      onChange={() => onChangeUpload("rea")}
                                    >
                                      REA {isReaUploaded ? "(Update)" : null}
                                    </Checkbox>
                                  </div>
                                  <div className="col-lg-5 col-sm-12">
                                    <Checkbox
                                      checked={domainUplode}
                                      onChange={() => onChangeUpload("domain")}
                                    >
                                      Domain {isDomainUploaded ? "(Update)" : null}
                                    </Checkbox>
                                  </div>
                                </div>
                              }
                              footer={[
                                <div
                                  key="footer"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{ marginRight: "20px" }}
                                    className="pdb-save-changes mt-2"
                                    onClick={handleReaSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>,
                              ]}
                            />
                          </>
                        </div>
                        <div key={listing.listingId}>
                          <Tag color={listing.domainUploadId ? "success" : "orange"}>
                            {listing.domainUploadId ? "Listed On Domain" : "Not Listed On Domain"}
                          </Tag>
                          <Tag color={listing.reaUploadId ? "success" : "orange"}>
                            {listing.reaUploadId ? "Listed on Rea" : "Not Listed on Rea"}
                          </Tag>
                          <div className="row pt-2">
                            <div className="label">
                              <strong>Property For:</strong>
                            </div>
                            <div className="value">{listing.propertySaleRental === 2 ? "Rental" : ""}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Listing Status:</strong>
                            </div>
                            <div className="value">{listing.sListingStatus}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Is Commercial:</strong>
                            </div>
                            <div className="value">{listing.isCommercial ? "Yes" : "No"}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>New or Established:</strong>
                            </div>
                            <div className="value">{listing.isNew === 1 ? "New" : "Established"}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Rental Price:</strong>
                            </div>
                            <div className="value">{getDetails.rentPrice}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Rental Interval :</strong>
                            </div>
                            <div className="value">{getDetails.rentTenure === 1 ? "Weekly" : "Monthly"}</div>
                          </div>
                          <ShowValue Label="Listing agent" setData={listing.listingAgentName} />

                          <div className="row">
                            <div className="label">
                              <strong>Dual Agent :</strong>
                            </div>
                            <div className="value">{listing.dualAgentName}</div>
                          </div>
                          <div className="row">
                            <div className="label">
                              <strong>Date Available:</strong>
                            </div>
                            <div className="value">{FormatDateView(listing.dateAvailable)}</div>
                          </div>
                          {listing.isShowPrice === "2" && (
                            <ShowValue Label="Show Text" setData={listing.showText} />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              )) : null}

            </div>
          )}
        </TabPane>
        <TabPane tab="Legal" key="3">
          <LegalPage
            handleparentsuccess={handleparentsuccess}
            getDetails={getDetails}
            handleCurrentPage1={handlePage}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PropertyListing;
