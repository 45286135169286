import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import AddOrSelectProperty from "./addNewProperty";
import AgentAgreement from "./agentAgreement"
import AgreementPeriod from "./agreementPeriod"
import FeesAndCharges from "./feesAndCharges"
import TribunalFees from "./TribunalFees"
import PromotionalActivities from "./PromotionalActivities"
import RepairAndMaintainace from "./RepairsAndMaintenance"
import AgreementSignature from "./signature"
import AgreementSummary from "./summary"
import { assignedTo, getManagementAgreement, CreateManagementAgreement } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, ManagementAgreementStatus } from "../../../Utilities/enum";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import axios from "axios";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";

const AgreementTabs = ({ handleparentsuccess }) => {
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Properties");
  const [activeKey, setActiveKey] = useState("PropertyDetails");
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [agencyID, setAgencyId] = useState("");
  const [HandleNextPages, setHandleNextPages] = useState('')
  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    var activeTab = url.searchParams.get("activeTab");
    if (activeTab) {
      setActiveKey(activeTab);
    }
    if (propertyUId) {
      GetApi();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, [activeKey]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          reportData(parsedData.agencyUniqueID);
          setAgencyId(parsedData.agencyID);

        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  const GetApi = (value) => {
    var url = new URL(window.location.href);
    var propertyUId = value ? value : url.searchParams.get("propertyUID");
    const baseUrl = getManagementAgreement;
    const apiUrl = `${baseUrl}?propId=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.object !== null) {
          setGetDetails(response.object);
          if (response.object.agreementStatus === ManagementAgreementStatus.PropertySelected) {
            if (response.object.contactlistModel.length === 0) {
              setActiveKey("")
              setHandleNextPages("")
            } else {
              setActiveKey("propetyies")
              setHandleNextPages("propetyies")
            }
          } else if (response.object.agreementStatus === ManagementAgreementStatus.PropertyDetails) {
            // setActiveKey("ManagementAgreement")
            // setHandleNextPages("ManagementAgreement")

            setActiveKey("propetyies")
            setHandleNextPages("propetyies")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.AgencyDetails) {
            // setActiveKey("propetyies")
            // setHandleNextPages("propetyies")

                setActiveKey("AgreementPeriod")
            setHandleNextPages("AgreementPeriod")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.AgreementPeriod) {
            // setActiveKey("AgreementPeriod")
            // setHandleNextPages("AgreementPeriod")
            setActiveKey("FeesAndCharges")
            setHandleNextPages("FeesAndCharges")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.FeesCharges) {
            // setActiveKey("FeesAndCharges")
            // setHandleNextPages("FeesAndCharges")
            setActiveKey("TribunalFees")
            setHandleNextPages("TribunalFees")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.TribunalInsurance) {
            // setActiveKey("TribunalFees")
            // setHandleNextPages("TribunalFees")
            setActiveKey("PromotionalActivities")
            setHandleNextPages("PromotionalActivities")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.PromotionalActivity) {
            // setActiveKey("PromotionalActivities")
            // setHandleNextPages("PromotionalActivities")
            setActiveKey("RepairAndMaintainance")
            setHandleNextPages("RepairAndMaintainance")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.Repairs) {
            // setActiveKey("RepairAndMaintainance")
            // setHandleNextPages("RepairAndMaintainance")
            setActiveKey("AgreementSignature")
            setHandleNextPages("AgreementSignature")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.Signed) {
            // setActiveKey("AgreementSummary")
            // setHandleNextPages("AgreementSummary")
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (response.object.agreementStatus === ManagementAgreementStatus.Completed) {
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (getDetails.agreementStatus === ManagementAgreementStatus.PropertySelected) {
            setActiveKey("")
            setHandleNextPages("")
          }
        } else if (response.object === null) {
          createManagementAgreement(propertyUId,agencyID);
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };
  const createManagementAgreement = (data,agencyID) => {
    var urls = new URL(window.location.href);
    var Id = agencyID !== ""? agencyID : urls.searchParams.get("agencyId");
    let formData = {
      PropertyUId: data,
      AgencyId: Id ,
    };
    const url = CreateManagementAgreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setGetDetails(returnData.object);

        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };

  const handleNextPage = (value) => {
    setHandleNextPages(value)
  }

  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <Header />
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <Sidebar
              visible={sidebarVisible}
              handleTopicValue={handleTopicValue}
              topicValue={activeTopic}
            />
            <div
              className="pa-content-top-level"
              onClick={() => setVisiblet(true)}
            >
              <div className="text7" style={{ fontSize: "24px", display: 'flex', justifyContent: 'center', textDecoration: 'underline' }}>Exclusive Leasing and Management Agreement</div>
              {HandleNextPages === '' ? <AddOrSelectProperty
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}

              {HandleNextPages === "propetyies" ? <AgentAgreement
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}

              {HandleNextPages === "AgreementPeriod" ?
                <AgreementPeriod
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
              {HandleNextPages === "FeesAndCharges" ?
                <FeesAndCharges
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
              {HandleNextPages === "TribunalFees" ?
                <TribunalFees
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
              {HandleNextPages === "PromotionalActivities" ?
                <PromotionalActivities
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
              {HandleNextPages === "RepairAndMaintainance" ?
                <RepairAndMaintainace
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
              {HandleNextPages === "AgreementSignature" ?
                <AgreementSignature
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}

              {HandleNextPages === "AgreementSummary" ?
                <AgreementSummary
                  onDataRecive={handleDataChild}
                  handleparentsuccess={handleToastMessage}
                  handleCurrentPage={handleTabChange}
                  getDetails={getDetails}
                  reportTo={dpdata}
                  handleNextPage={handleNextPage}
                /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementTabs;
