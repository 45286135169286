import React, { useEffect, useState, useMemo, useRef } from "react";
import debounce from "lodash/debounce";
import "./LandingPage.css";
import Header from "../Header/Header";
import { Checkbox, Radio, Switch, Select, Spin } from "antd";
import SelectBox from "../../../src/input-components/selectBoxNew";
import CustomBtn from "../../../src/input-components/custom-btn";
import {
  BiCheck,
  BiCar,
  BiBed,
  BiBath,
  BiBuildingHouse,
  BiPhoneCall,
  BiUser,
  BiArch,
  BiEnvelope,
} from "react-icons/bi";
import moment from "moment";
import { Galleria } from "primereact/galleria";
import RatingStar from "../../../src/input-components/ratingStar";
import {
  LandingPageListDetails,
  FilePath,
  LandingPageSearch,
  ViewSelectedPostCode,
} from "../../CommonSection/apiresources";
import { useNavigate } from "react-router-dom";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import RadioInput from "../../../src/input-components/radioBtn";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { Paginator } from "primereact/paginator";
import { Skeleton } from "primereact/skeleton";

const ListDetails = () => {
  const ToastRef = useRef();
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [propertyType, setPropertyType] = useState(true);
  const [priceOpen, setPriceType] = useState(true);
  const [bedRoomsOpen, setBedRoomType] = useState(true);
  const [livingAreaOpen, setLivingAreaType] = useState(true);
  const [bathRoomOpen, setBathRoomType] = useState(true);
  const [carSpaceOpen, setCarSpaceType] = useState(true);
  const [landSizeOpen, setLandSizeOpen] = useState(true);
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(true);
  const [outdoorFeaturesOpen, setOutdoorFeaturesOpen] = useState(true);
  const [indoorFeaturesOpen, setIndoorFeaturesOpen] = useState(true);
  const [climateControlOpen, setClimateControlOpen] = useState(true);
  const [buttonActive, setButtonActive] = useState(false);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [defaultSearch, setDefaultSearch] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedBed, setSelectedBed] = useState(0);
  const [selectedBathRoom, setSelectedBathRoom] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedCar, setSelectedCar] = useState(0);
  const [selectedProperty, setSelectedProprerty] = useState("");
  const [selectedLivingArea, setSelectedLivingArea] = useState(0);
  const [selectedOutdoorFeatures, setSelectedOutdoorFeatures] = useState({});
  const [selectedIndoorFeatures, setSelectedIndoorFeatures] = useState({});
  const [selectedClimateFeatures, setSelectedClimateFeatures] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayFilter, setDisplayFilter] = useState(false);
  const navigate = useNavigate();
  const fetchRef = useRef(0);
  const toggleFilters = () => setFiltersOpen(!filtersOpen);
  const toggleProperty = () => setPropertyType(!propertyType);
  const togglePrice = () => setPriceType(!priceOpen);
  const toggleBedRoom = () => setBedRoomType(!bedRoomsOpen);
  const toggleBathRoom = () => setBathRoomType(!bathRoomOpen);
  const toggleCarSpace = () => setCarSpaceType(!carSpaceOpen);
  const toggleLandSize = () => setLandSizeOpen(!landSizeOpen);
  const togglePropertyType = () => setPropertyTypeOpen(!propertyTypeOpen);
  const toggleOutdoorFeatures = () =>
    setOutdoorFeaturesOpen(!outdoorFeaturesOpen);
  const toggleIndoorFeatures = () => setIndoorFeaturesOpen(!indoorFeaturesOpen);
  const toggleClimateControl = () => setClimateControlOpen(!climateControlOpen);
  const toggleLivingArea = () => setLivingAreaType(!livingAreaOpen);
  const [catId, setCatId] = useState("");

  const bedsSize = [
    { label: "1-4 Beds", value: 1 },
    { label: "5-8 Beds", value: 2 },
    { label: "9-12 Beds", value: 3 },
    { label: "13-16 Beds", value: 4 },
    { label: "17-20 Beds", value: 5 },
  ];
  const [land, setLand] = useState([
    { Id: 1, label: "Feed", value: 1 },
    { Id: 2, label: "Sgms", value: 2 },
    { Id: 3, label: "Acres", value: 3 },
    { Id: 4, label: "Hectares", value: 4 },
  ]);

  const [imagesLoaded, setImagesLoaded] = useState(false); // Define imagesLoaded state here

  const bathRoomsOptions = [
    { label: "1+", value: 1 },
    { label: "2+", value: 2 },
    { label: "3+", value: 3 },
    { label: "4+", value: 4 },
    { label: "5+", value: 5 },
    { label: "6+", value: 5 },
  ];

  const livingAreaOptions = [
    { label: "1+", value: 1 },
    { label: "2+", value: 2 },
    { label: "3+", value: 3 },
    { label: "4+", value: 4 },
    { label: "5+", value: 5 },
    { label: "6+", value: 5 },
  ];

  const price = [
    { label: "$50,000 - $100,000", value: 1 },
    { label: "$100,000 - $250,000", value: 2 },
    { label: "$250,000 - $400,000", value: 3 },
    { label: "$400,000 - $550,000", value: 4 },
    { label: "$550,000 - $700,000", value: 5 },
    { label: "$700,000 - $850,000", value: 6 },
    { label: "$850,000 - $1,000,000", value: 7 },
    { label: "$1,000,000- $3,000,000", value: 8 },
    { label: "$3,000,000- $5,000,000", value: 9 },
    { label: "$5,000,000- $9,000,000", value: 10 },
  ];

  const carOptions = [
    { label: "1+", value: 1 },
    { label: "2+", value: 2 },
    { label: "3+", value: 3 },
    { label: "4+", value: 4 },
    { label: "5+", value: 5 },
    { label: "6+", value: 6 },
  ];

  const propertyOptions = [
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Apartment", value: 3 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Flat", value: 6 },
    { label: "House", value: 7 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];

  useEffect(() => {
    const url = new URL(window.location.href);
    const newCatId = url.searchParams.get("catId");
    setCatId(newCatId);
  }, [catId]);


  useEffect(() => {
    var url = new URL(window.location.href);
    var PropertySaleRental = url.searchParams.get("PropertySaleRental");
    var Minbeds = url.searchParams.get("Minbeds");
    var Search = url.searchParams.get("Search");
    var MinPrice = url.searchParams.get("MinPrice");
    filteredDetailsList(Search);
    setSelectedBed(parseInt(Minbeds, 10));
    setSelectedPrice(parseInt(MinPrice, 10));
    if (catId) {
      // Call your API function with the updated catId
      getAllDetailsList(Search, "", "", catId);
    }
  }, [catId]);

  const filteredDetailsList = (Search, currentPage, totalRows) => {
    const baseUrl = ViewSelectedPostCode;
    const data = {
      ids: Search ? Search : "",
      PageNo: currentPage ? currentPage : 1,
      RecordsPerPage: totalRows ? totalRows : 10,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === 0) {
          let landingPageListData = response.list;
          const selectedValues = Search != null ? Search.split(",") : "";
          setDefaultSearch(selectedValues);
          const transformedData = landingPageListData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setOptions(transformedData);
          const outputArray = transformedData.map((item) => item.label);
          setSelectedValues(outputArray);
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAllDetailsList = (Search, currentPage, totalRows, catId) => {
    const baseUrl = LandingPageListDetails;
    const data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: totalRows ? totalRows : 10,
      Minbeds: selectedBed ? selectedBed : 0,
      Search: Search,
      MinPrice: selectedPrice ? selectedPrice : 0,
      //PropertySaleRental : PropertySaleRental,
      CarParking: selectedCar ? selectedCar : 0,
      LivingArea: selectedLivingArea ? selectedLivingArea : 0,
      PropertyType: null,
      Balcony: selectedOutdoorFeatures["Balcony"] || false,
      SecureParking: selectedIndoorFeatures["SecureParking"] || false,
      AlarmSystem: selectedIndoorFeatures["AlarmSystem"] || false,
      Gym: selectedIndoorFeatures["Gym"] || false,
      AirConditioning: selectedClimateFeatures["Air conditioning"] || false,
      PageNo: currentPage ? currentPage : 1,
      cat: catId ? catId : 1,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      baseUrl,
      "post",
      data,
      "",
      false
    )
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length != 0) {
            let landingPageListData = response.list;
            cardDetails(landingPageListData);
            setTotalPages(response.totalCount);
          } else {
            ToastRef.current.notify(["error", "No Data Found"]);
          }
        } else {
          alert(`Message: ${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const cardDetails = (landingPageListData) => {
    let meetingCardsData = landingPageListData.map((item) => {
      let landAreaLabel = "";

      switch (item.propertyLandAreaW) {
        case 1:
          landAreaLabel = "Feed";
          break;
        case 2:
          landAreaLabel = "Sgms";
          break;
        case 3:
          landAreaLabel = "Acres";
          break;
        case 4:
          landAreaLabel = "Hectares";
          break;
        default:
          landAreaLabel = "Unknown"; // Default label if the value is unexpected
      }
      return {
        title: item.propertyHeading,
        description: item.agencyName,
        address: item.getFormatedAddress,
        price: `${
          item.showText
            ? item.showText
            : item.propertyPrice !== null && item.propertyPrice > 0
            ? `$ ${item.propertyPrice}`
            : ""
        }`,
        nearby: "Nearby Placeholder",
        features: [
          {
            label: `${
              item.countBedrooms ? item.countBedrooms : 0
            } BHK Apartment`,
            value: `${
              item.propertyLandAreaL ? item.propertyLandAreaL : 0
            }-${landAreaLabel}`,
          },
        ],
        additionalFeatures: [
          "Highway secured society",
          "MRL Auto-door branded",
          "MRL Auto",
        ],
        bedroom: item.countBedrooms ? item.countBedrooms : "N/A",
        bathroom: item.countBathrooms ? item.countBathrooms : "N/A",
        car: item.countCarParking ? item.countCarParking : "N/A",
        rating: 1,
        imageUrls:
          item.propertyPics.length > 0
            ? item.propertyPics.map((pic) => ({
                itemImageSrc: FilePath + pic,
                thumbnailImageSrc: FilePath + pic,
                alt: "Description for Image",
                title: "Title",
              }))
            : [
                {
                  itemImageSrc: FilePath + "property/property-dummy-image.png",
                  thumbnailImageSrc:
                    FilePath + "property/property-dummy-image.png",
                  alt: "Default Image",
                  title: "Default Title",
                },
              ],
        agentProfilePic: item.agentProfilePic
          ? FilePath + item.agentProfilePic
          : `${FilePath}profile/default.png`,
        propertyType: item.sPropertyType,
        propertyUniqueID: item.propertyUniqueID,
        propertyID: item.propertyId,
        agentName: item.agentName,
        agentContact: item.agentContact,
        agentEmail: item.agentEmail,
      };
    });
    setMainData(meetingCardsData);
    setIsLoading(false);
  };

  const handlePageChange = (event, pageNumber) => {
    getAllDetailsList("", event.page + 1, event.rows);
    filteredDetailsList("", event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const outdoorData = [
    { id: 1, name: "Swimming pool" },
    { id: 2, name: "Outdoor area" },
    { id: 3, name: "Balcony" },
    { id: 4, name: "Garage" },
    { id: 5, name: "Undercover Parking" },
    { id: 6, name: "Shed" },
    { id: 7, name: "Fully fenced" },
    { id: 8, name: "Outdoor spa" },
    { id: 9, name: "Tennis court" },
  ];

  const indoorData = [
    { id: 1, name: "Ensuite" },
    { id: 2, name: "Dishwasher" },
    { id: 3, name: "Study" },
    { id: 4, name: "Alarm system" },
    { id: 5, name: "Broadband" },
    { id: 6, name: "Floorboards" },
    { id: 7, name: "Gym" },
    { id: 8, name: "Rumpus room" },
    { id: 9, name: "Workshop" },
  ];

  const climateData = [
    { id: 1, name: "Air conditioning" },
    { id: 2, name: "Solar panels" },
    { id: 3, name: "Heating" },
    { id: 4, name: "High energy efficiency" },
    { id: 5, name: "Water tank" },
    { id: 6, name: "Solar hot water" },
  ];

  const onChange = (e) => {};

  const itemTemplate = (item) => {
    return (
      <img src={item.itemImageSrc} alt={item.alt} className="detailsImage" />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  const handleDisplayFilter = () => {
    var url = new URL(window.location.href);
    var PropertySaleRental = url.searchParams.get("PropertySaleRental");
    var Minbeds = url.searchParams.get("Minbeds");
    var Search = url.searchParams.get("Search");
    var MinPrice = url.searchParams.get("MinPrice");
    const intValue = parseInt(Minbeds, 10);
    setSelectedBed(intValue);
    setDisplayFilter(!displayFilter);
    const priveVal = parseInt(MinPrice, 10);
    setSelectedPrice(priveVal);
  };

  const handleMoreDetails = (propertyUniqueId, propertyId) => {
    navigate(
      "/landingPageDetails?propertyUniqueId=" +
        propertyUniqueId +
        "&propertyId=" +
        propertyId
    );
  };

  const options1 = [
    { label: "All Types ", value: "1" },
    { label: "Private treaty sale", value: "2" },
    { label: "Auction", value: "2" },
  ];

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const baseUrl = LandingPageSearch;
      const data = {
        Search: value,
      };
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        baseUrl,
        "post",
        data,
        "",
        false
      )
        .then((response) => {
          if (response.status === 1) {
            let landingPageListData = response.list;

            // Transform data to match Select options
            const transformedData = landingPageListData.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            // Update options and fetching state
            if (fetchId === fetchRef.current) {
              setOptions(transformedData);
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        })
        .catch((error) => {
          setFetching(false);
        });
    };
    return debounce(loadOptions, 800);
  }, []);

  const handleSearchChange = (e, value) => {
    // Call loadOptions with the search value
    const values = value.map((item) => item.value).join(",");
    setSearchValues(values);
    debounceFetcher(value);
  };

  return (
    <div className="landingpage">
      <Header />
      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{
          backgroundColor: "white",
          height: "100px",
          padding: "10px",
        }}
      ></div>
      <div
        style={{
          color: "#006377",
          textDecoration: "underline",
          fontWeight: "500",
          cursor: "pointer",
        }}
        onClick={handleDisplayFilter}
      >
        {!displayFilter ? "Filter More..." : "Filter Less..."}
      </div>

      <div className="meeting-room-container">
        {displayFilter ? (
          <div className="left-section">
            <div>
              <div className="left-section-labels" onClick={toggleFilters}>
                Filters{" "}
                <img
                  width={15}
                  style={{ float: "right", marginTop: "10px" }}
                  src="../assets/images/icon.png"
                ></img>
              </div>
              {filtersOpen ? (
                <div className="mt-1 bottom-border-container">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      borderBottom: "1px solid #d0d5d",
                    }}
                  >
                    <Select
                      className=""
                      mode="multiple"
                      placeholder="Search for suburb or postcode"
                      labelInValue
                      filterOption={false}
                      onSearch={debounceFetcher}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      style={{ width: "100%" }}
                      options={options}
                      onChange={(label, value) =>
                        handleSearchChange(label, value)
                      }
                      defaultValue={selectedValues}
                    />
                  </div>
                  <Checkbox className="mt-2 mb-1">
                    Include surrounding areas
                  </Checkbox>
                </div>
              ) : null}
              <div className="mt-2 left-section-labels">
                <div
                  className="mb-2 "
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Hide already seen <Switch />
                </div>
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleProperty}
                  className="mb-2 left-section-labels"
                >
                  Property Type{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {propertyType ? (
                  <div>
                    <SelectBox
                      colVal={12}
                      name="propertyFor"
                      options={propertyOptions}
                      value={selectedProperty}
                      onChange={(val) => setSelectedProprerty(val.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div onClick={togglePrice} className="mb-2 left-section-labels">
                  Price{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {priceOpen ? (
                  <div className="row bottom-border-container">
                    <SelectBox
                      colVal={12}
                      name="price"
                      options={price}
                      value={selectedPrice}
                      onChange={(val) => setSelectedPrice(val.value)}
                    />
                    <Checkbox className="mb-1">
                      Only show properties with price
                    </Checkbox>
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleBedRoom}
                  className="mb-2 left-section-labels"
                >
                  Bed Rooms{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {bedRoomsOpen ? (
                  <div>
                    <SelectBox
                      colVal={12}
                      name="SelectedBed"
                      options={bedsSize}
                      value={selectedBed}
                      onChange={(val) => setSelectedBed(val.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleLivingArea}
                  className="mb-2 left-section-labels"
                >
                  Living Area{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {livingAreaOpen ? (
                  <div>
                    <SelectBox
                      colVal={12}
                      name="bathRoomsOptions"
                      options={livingAreaOptions}
                      value={selectedLivingArea}
                      onChange={(val) => setSelectedLivingArea(val.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleBathRoom}
                  className="mb-2 left-section-labels"
                >
                  Bath Rooms{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {bathRoomOpen ? (
                  <div>
                    <SelectBox
                      colVal={12}
                      name="bathRoomsOptions"
                      options={bathRoomsOptions}
                      value={selectedBathRoom}
                      onChange={(val) => setSelectedBathRoom(val.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleCarSpace}
                  className="mb-2 left-section-labels"
                >
                  Car Space{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {carSpaceOpen ? (
                  <div className="row bottom-border-container">
                    <SelectBox
                      colVal={12}
                      name="car"
                      options={carOptions}
                      value={selectedCar}
                      onChange={(val) => setSelectedCar(val.value)}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <div
                  onClick={toggleLandSize}
                  className="mb-2 left-section-labels"
                >
                  Land size{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {landSizeOpen ? (
                  <div className="row bottom-border-container">
                    <SelectBox
                      label="Min"
                      name="propertyFor"
                      options={[]}
                      placeholder={"Any"}
                    />
                    <SelectBox
                      label="Max"
                      name="propertyFor"
                      options={[]}
                      placeholder={"Any"}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className="mt-2 bottom-border-container"
                onClick={togglePropertyType}
              >
                <div className="mb-2 left-section-labels">
                  New or Established Property{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {propertyTypeOpen ? (
                  <Radio.Group className="mb-2" onChange={onChange}>
                    <Radio value={1}>All Types</Radio>
                    <Radio value={2}>New</Radio>
                    <Radio value={3}>Established</Radio>
                  </Radio.Group>
                ) : null}
              </div>
              <div className="mt-2 bottom-border-container">
                <div
                  className="mb-2 left-section-labels"
                  onClick={toggleOutdoorFeatures}
                >
                  Outdoor Features{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {outdoorFeaturesOpen ? (
                  <div
                    className="mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "5px",
                    }}
                  >
                    {outdoorData.map((item, index) => (
                      <label
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox
                          className="custom-check-box custom-check"
                          checked={selectedOutdoorFeatures[item.name]}
                          onChange={() => {
                            setSelectedOutdoorFeatures((prevState) => ({
                              ...prevState,
                              [item.name]: !prevState[item.name],
                            }));
                          }}
                        />

                        {item.name}
                      </label>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 bottom-border-container">
                <div
                  className="mb-2 left-section-labels"
                  onClick={toggleIndoorFeatures}
                >
                  Indoor Features{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {indoorData ? (
                  <div
                    className="mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "5px",
                    }}
                  >
                    {indoorData.map((item, index) => (
                      <label
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox
                          className="custom-check-box custom-check"
                          checked={selectedIndoorFeatures[item.name]}
                          onChange={() => {
                            setSelectedIndoorFeatures((prevState) => ({
                              ...prevState,
                              [item.name]: !prevState[item.name],
                            }));
                          }}
                        />

                        {item.name}
                      </label>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 bottom-border-container">
                <div
                  className="mb-2 mt-2 left-section-labels"
                  onClick={toggleClimateControl}
                >
                  Climate conytrol & energy{" "}
                  <img
                    width={15}
                    style={{ float: "right", marginTop: "10px" }}
                    src="../assets/images/icon.png"
                  ></img>
                </div>
                {climateControlOpen ? (
                  <div
                    className="mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "5px",
                    }}
                  >
                    {climateData.map((item, index) => (
                      <label
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox
                          className="custom-check-box custom-check"
                          checked={selectedClimateFeatures[item.name]}
                          onChange={() => {
                            setSelectedClimateFeatures((prevState) => ({
                              ...prevState,
                              [item.name]: !prevState[item.name],
                            }));
                          }}
                        />

                        {item.name}
                      </label>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 bottom-border-container">
                <div className="mb-2 left-section-labels">Sale Method </div>
                <RadioInput
                  colVal={12}
                  name="isCommercial"
                  options={options1}
                  //   value={values.isCommercial}
                  //   onChange={handleChange}
                  //   onBlur={handleBlur}
                  //   error={errors.isCommercial}
                  //   touched={touched.isCommercial}
                />
                <Checkbox className="mt-2 mb-1">
                  Include surrounding areas
                </Checkbox>
              </div>
              <div className="mt-2 bottom-border-container">
                <div
                  className="property-details-buttons"
                  style={{ justifyContent: "space-between" }}
                >
                  <button className="pdb-cancel" type="button">
                    Clear
                  </button>
                  <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={getAllDetailsList}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={displayFilter ? "right-section" : "right-section1"}>
          {mainData.map((card, index) => (
            <div className="meeting-card mt-3" style={{cursor:'pointer'}} key={index} onClick={() =>
              handleMoreDetails(card.propertyUniqueID, card.propertyID)
            }>
              <div className="listImg-Container">
                {!imagesLoaded && ( // Display loader if images are not loaded
                  <Skeleton width="640px" height="400px" />
                )}
                <Galleria
                  value={card.imageUrls}
                  numVisible={5}
                  circular
                  style={{
                    maxWidth: "640px",
                    display: imagesLoaded ? "block" : "none",
                  }} // Show only when images are loaded
                  showThumbnails={false}
                  showItemNavigators
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                  onImageError={() => setImagesLoaded(true)} // Set imagesLoaded to true when image loading fails
                  onLoad={() => setImagesLoaded(true)} // Set imagesLoaded to true when all images are loaded
                />
                <div>
                  <div
                    className="listDetails-card"
                    
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="mr-5 mb-2" style={{width:'80%'}}>
                        <div style={{ fontSize: "18px", fontWeight: "500" , width:'80%'}}>
                          {card.title}
                        </div>
                      
                      </div>
                      <div className="listDetails-cardImg">
                        <img
                          src={card.agentProfilePic}
                          alt="User Avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          className="mb-1"
                        
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#006377",
                          }}
                        >
                           {card.address}
                        </div>
                        <div
                              style={{
                                whiteSpace: "nowrap",
                                padding: "0px 4px 2px 8px",
                                backgroundColor: "lightsteelblue",
                                fontWeight: "500",
                              }}
                              className="mt-1 mb-1"
                            >
                              <span>
                                <BiArch
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>{card.propertyType}</span>
                              </span>
                            </div>
                        <div
                            style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                         {card.description} 
                        </div>
                        {/* <div className='mb-1' style={{ fontSize: '14px', fontWeight: '500' }}><BiEnvelope style={{fontSize:'20px', marginRight:'5px'}}/>{card.agentEmail  }</div> */}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          className="mb-1"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          <BiUser
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          {card.agentName}
                        </div>
                        <div
                          className="mb-1"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          <BiPhoneCall
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          {card.agentContact}
                        </div>
                      </div>
                    </div>

                    {/* <div>
                      <div style={{ fontSize: "14px" }}>Near By : </div>
                    </div> */}
                    <div className="mt-2" style={{ display: "flex" }}>

                        
                      {/* {card.features.map((feature, index) => (
                        <div>
                          <div
                            className="mr-5"
                            key={index}
                            style={{
                              border: "1px solid #d0d5dd",
                              borderRadius: "4px",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                padding: "0px 4px 2px 8px",
                                backgroundColor: "lightsteelblue",
                                fontWeight: "500",
                              }}
                            >
                              <span>
                                <BiArch
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>{card.propertyType}</span>
                              </span>
                            </div>
                            <div className="pb-4">
                              <div
                                className="pl-2 pt-1"
                                style={{ fontSize: "14px" }}
                              >
                                {feature.label}
                              </div>
                              <div className="pl-2 pt-1">{feature.value}</div>
                            </div>
                          </div>
                        </div>
                      ))} */}
                    </div>
                    <div style={{ fontWeight: "500" }}>{card.price}</div>

                    {/* <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between', background: '#dbffff', padding: '2px' }}>
                                        <div className='mr-5' style={{ display: 'contents', fontSize: '12px' }}>
                                            {card.additionalFeatures.map((feature, index) => (
                                                <div key={index}><BiCheck />{feature}</div>
                                            ))}
                                        </div>
                                    </div> */}
                    <div className="mt-2 features-container">
                      <div
                        className="mr-5"
                        style={{ display: "flex", fontSize: "18px" }}
                      >
                       {card.bedroom != 'N/A' ?  <div className="mr-5 features-containeR-label">
                          <BiBed className="mr-2" />
                          {card.bedroom}
                        </div> : null}
                        {card.bathroom != 'N/A' ? <div className="mr-5 features-containeR-label">
                          <BiBath className="mr-2" />
                          {card.bathroom}
                        </div> : null}
                       {card.car != 'N/A' ? <div className="mr-5 features-containeR-label">
                          <BiCar className="mr-2" />
                          {card.car}
                        </div> : null }
                      </div>
                      <div style={{ display: "contents", fontSize: "18px" }}>
                        <RatingStar
                          totalStars={1}
                          initialRating={card.rating}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalPages}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ListDetails;
