import "./styles/shortcardview.css";
const ShortCardView = (props) => {
  return (
    <div className="cardcontainer" onClick={() => props.onClick()}>
      <div className="scinnerContainer">
        <div className="scLabelValuecontainer">
          <div className="scLabel">{props.label}</div>
          <div className="scValue">{props.value}</div>
        </div>
      </div>
    </div>
  );
};

export default ShortCardView;
