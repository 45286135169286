import React from "react";
import { useEffect, useState} from "react";
import "../../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../input-components/selectBoxNew";
import {ContactOtherUrl,} from "../../../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../../../components/CommonSection/Cookie/Session";
import TextBox from "../../../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../../../Utilities/client";
import { useSpinnerContext } from "../../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../../Utilities/enum";
import { ContactOthersSchema } from "../../../../../components/CommonSection/Schema/schema";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import TextArea from "../../../../input-components/textarea";

const initialValues = {
  maritalStatus: null,
  spouseName: "",
  spouseMobileNumber: "",
  spouseEmail: "",
  anniversary: "",
  facebookUrl: "",
  linkedinUrl: "",
  twitterUrl: "",
  instagramUrl: "",
  notes: "",
};

const ContactOthers = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
}) => {
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const [martialData, setmartialData] = useState([
    { value: 1, label: "Single" },
    { value: 2, label: "Married" },
    { value: 3, label: "Family" },
    { value: 4, label: "In Relationship" },
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
    }
  }, [getDetails]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactOthersSchema,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      ContactUniqueId: getDetails.contactUniqueId,
      MaritalStatus: values.maritalStatus,
      SpouseName: values.spouseName,
      SpouseEmail: values.spouseEmail,
      SpouseMobileNumber: values.spouseMobileNumber,
      Anniversary: values.anniversary,
      FacebookUrl: values.facebookUrl,
      LinkedinUrl: values.linkedinUrl,
      TwitterUrl: values.twitterUrl,
      InstagramUrl: values.instagramUrl,
      Notes: values.notes,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      ContactOtherUrl,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(returnData.sUID);

          handleparentsuccess(["success", returnData.message]);
          //handleCurrentPage("CompanyDetails");
          navigate("/agency/ContactList");

        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="details-form">
        <div className="property-details-content df-all all-input">
          <div className="pddpn-group" style={{ padding: "0px 30px" }}>
            <SelectBox
              label="Martial Status"
              defaultValue={""}
              name="maritalStatus"
              options={martialData}
              placeholder={"Select Status"}
              value={values.maritalStatus}
              onChange={(val) => setFieldValue("maritalStatus", val.value)}
              error={errors.maritalStatus}
              touched={touched.maritalStatus}
            />
          </div>
          <div className="pddpn-group" style={{ padding: "0px 30px" }}>
            <TextBox
              label="Name"
              placeholder="Name"
              id="Unit"
              name="spouseName"
              value={values.spouseName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.spouseName}
              touched={touched.spouseName}
            />
            <TextBox
              label="Email"
              placeholder="Email"
              name="spouseEmail"
              value={values.spouseEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.spouseEmail}
              touched={touched.spouseEmail}
              icon={<BiEnvelope height="100%" width="100%" />}
            />
            <TextBox
              label="Phone"
              placeholder="Phone"
              name="spouseMobileNumber"
              value={values.spouseMobileNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.spouseMobileNumber}
              touched={touched.spouseMobileNumber}
              icon={<BiPhone height="100%" width="100%" />}
            />
            <TextBox
              label="Anniversary"
              type="date" 
              placeholder="Anniversary"
              id="anniversary"
              name="anniversary"
              value={values.anniversary}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.anniversary}
              touched={touched.anniversary}
            />
            <TextBox
              label="Facebook Url"
              placeholder="Facebook Url"
              id="facebookUrl"
              name="facebookUrl"
              value={values.facebookUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.facebookUrl}
              touched={touched.facebookUrl}
            />
            <TextBox
              label="Linked Url"
              placeholder="Linked Url"
              id="linkedinUrl"
              name="linkedinUrl"
              value={values.linkedinUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.linkedinUrl}
              touched={touched.linkedinUrl}
            />
            <TextBox
              label="Twitter Url"
              placeholder="Twitter Url"
              id="twitterUrl"
              name="twitterUrl"
              value={values.twitterUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.twitterUrl}
              touched={touched.twitterUrl}
            />
            <TextBox
              label="Instagram Url"
              placeholder="Instagram Url"
              id="instagramUrl"
              name="instagramUrl"
              value={values.instagramUrl}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.instagramUrl}
              touched={touched.instagramUrl}
            />
            <TextArea
              label="Notes"
              placeholder="Enter the notes..."
              id="notes"
              name="notes"
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.notes}
              touched={touched.notes}
            />
          </div>
        </div>

        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() =>
              handleparentsuccess(["warn", "Changes are cancelled"])
            }
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOthers;
