import React from "react";
import { useEffect, useState,useMemo, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import PlacesAutocomplete, {geocodeByAddress,} from "react-places-autocomplete";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../input-components/selectBoxNew";
import {createPropertyAddress,ActiveProperty,searchPropertyList,assignedTo} from "../../../components/CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import AddressInput from "../../input-components/address";
import { Select, Spin} from "antd";
import debounce from "lodash/debounce";
import { BiPlus} from "react-icons/bi";
import {addParametersForProfiles} from "../../../Utilities/profileManagement";
import OwnerShip from "./partiesPrinciples"

const initialValues = {
  propertyFor: "",

  propertyType: "",

  assignedTo: "",

  address: "",

  unitNumber: "",

  streetNumber: "",

  streetName: "",

  suburb: "",

  postCode: "",

  state: "",
};

const AddNewProperty = ({
  onDataRecive,

  handleparentsuccess,

  handleCurrentPage,

  getDetails,
  reportTo,
  handleNextPage
}) => {
  const [address, setAddress] = useState("");
  let toastdata = [];
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agencyUID, setAgencyUID] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [viewAddressDetails, setViewAddressDetails] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [displayAddNewProperty, setDisplayAddNewProperty] = useState(false)
  const [showSelect, setShowSelect] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [option, setOption] = useState([]);
  const fetchRef = useRef(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchValue, setSearchValues] = useState("");
  const [displayOwner , setDisplayOwners] = useState(false)
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);

  var url = new URL(window.location.href);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgencyUID(parsedData.agencyUniqueID);
          reportData(parsedData.agencyUniqueID);
        } else {
          navigate("/signin");
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {}, [reportTo]);


  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    if(propertyUId){
      setDisplayOwners(true)
    }
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setPropertyId(getDetails.propertyId);
      setValue1(getDetails.propertyType);
      setFieldValue("propertyType", parseInt(getDetails.propertyType));
      setValue2(getDetails.propertySaleRental);
      setFieldValue("propertyFor", parseInt(getDetails.propertySaleRental));
      setAddress(getDetails.getFormatedAddress);
      setFieldValue("address", getDetails.getFormatedAddress);
      setLatitude(getDetails.propertyGooglePlacesLocationLat);
      setLongitude(getDetails.propertyGooglePlacesLocationLong);
      const matchingItem = reportTo.find(
        (item) => parseInt(item.value, 10) === getDetails.agentId
      );
      if (matchingItem) {
        setSelectedCity(matchingItem.value);
        setFieldValue("assignedTo", matchingItem.value);
      }
      filteredDetailsList(getDetails.propertyId);
    }
  }, [getDetails, reportTo]);


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  const {
    errors,

    values,

    touched,

    handleBlur,

    handleChange,

    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,

    //validationSchema: PropertyDetailsSchema,

    onSubmit: (values, action) => {
      values.address = values.address;

      values.propertyFor = values.propertyFor;

      values.propertyType = values.propertyType;

      values.assignedTo = parseInt(selectedCity, 10);

      displayAddNewProperty ?  handleFinalSubmit() : handleFinalSubmit1();
    },
  });

  const property_type = [
    { label: "House", value: 7 },
    { label: "Apartment", value: 3 },
    { label: "Flat", value: 6 },
    { label: "Acreage/Semi-rural", value: 1 },
    { label: "Alpine", value: 2 },
    { label: "Block Of Units", value: 4 },
    { label: "Duplex/Semi-detached", value: 5 },
    { label: "Retirement Living", value: 8 },
    { label: "Serviced", value: 9 },
    { label: "Studio", value: 10 },
    { label: "Terrace", value: 11 },
    { label: "Town House", value: 12 },
    { label: "Unit", value: 13 },
    { label: "Villa", value: 14 },
    { label: "Ware House", value: 15 },
    { label: "Land", value: 16 },
    { label: "Other", value: 17 },
  ];

  const property_For = [
    { value: 1, label: "Sales" },

    { value: 2, label: "Rental" },
  ];

  const handlepressSelect = async (addrs) => {
    setAddress(addrs);
    setFieldValue("address", addrs);

    try {
      const results = await geocodeByAddress(addrs);

      const details = results[0];

      const fullFormattedAddress = details.formatted_address;

      setAddress(fullFormattedAddress);

      const { address_components } = details;

      const address = {};

      address_components.forEach((component) => {
        const { types, long_name } = component;

        if (types.includes("subpremise")) {
          address.unitNumber = long_name;
        } else if (types.includes("street_number")) {
          address.streetNumber = long_name;
        } else if (types.includes("route")) {
          address.streetName = long_name;
        } else if (types.includes("locality")) {
          address.suburb = long_name;
        } else if (types.includes("postal_code")) {
          address.postCode = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          address.state = long_name;
        }
        let latitude = details.geometry.location.lat();
        let longitude = details.geometry.location.lng();

        setLatitude(latitude);

        setLongitude(longitude);

        setValues({ ...values, ...address });
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleFinalSubmit = () => {
    debugger;
    const formData = {
      PropertyId: propertyId ? propertyId : 0,

      PropertySaleRental: values.propertyFor,

      PropertyType: values.propertyType,

      AgentId: parseInt(selectedCity, 10),

      AgencyId: agencyID,

      PropertyGooglePlacesLocation: address,

      State: values.state,

      Suburb: values.suburb,

      PostCode: values.postCode,

      UnitNumber: values.unitNumber,

      StreetNumber: values.streetNumber,

      StreetName: values.streetName,

      UserId: userId,

      PropertyGooglePlacesLocationLong: longitude,

      PropertyGooglePlacesLocationLat: latitude,
    };

    const url = createPropertyAddress;

    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setDisplayOwners(true)
          onDataRecive(returnData.sUID);
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })

      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleFinalSubmit1 = () => {
    var currentURL = window.location.href;
   
    var newValue = "111";

    var updatedURL;

    if (newValue != null) {
      if (
        currentURL.includes("?") ||
        currentURL.includes("propertyUID")
      ) {
        var split_string = currentURL.split("=");

        updatedURL = split_string[0] + "=" + searchValue;
      } else {
        updatedURL = currentURL + "?propertyUID=" + searchValue;
      }
    }

    history.pushState(null, null, updatedURL);

    //handleparentsuccess(["success", "Property Added"]);

    //handleCurrentPage("AgentAgreement");
  };

  
  const handlePropertyType = (selectedValue) => {
    setValue1(selectedValue.value);

    setFieldValue("propertyType", selectedValue);
  };

  const handlePropertyFor = (selectedValue) => {
    setValue2(selectedValue.value);

    setFieldValue("propertyFor", selectedValue);
  };

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;

    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;

    axios.get(baseUrl).then((response) => {
      if (response.data.object) setDpdata(response.data.object);
    });
  };
  const handleAssignedTo = (e) => {
    setSelectedCity(e.value);

    setFieldValue("assignedTo", e.value);
  };

  const handleView = () => {
    setViewAddressDetails(!viewAddressDetails);
  };

  const handleaddProperty = () => {
    setDisplayAddNewProperty(true)
}

useEffect(() => {
    agencyUIDRef.current = agencyUID;
}, [agencyUID]);

const agencyUIDRef = useRef(agencyUID);

const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
        const agencyUID = agencyUIDRef.current;
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOption([]);
        setFetching(true);
        const baseUrl = ActiveProperty;
        let data = {
            AgencyUniqueID: agencyUID,
            RecordsPerPage: 10,
            PageNo: 1,
            Search: value,

        };
        data = addParametersForProfiles(data);
        makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            baseUrl,
            "post",
            data,
            "",
            false
        )
            .then((response) => {
                if (response.status === 1) {
                    let landingPageListData = response.list;
                    const transformedData = landingPageListData.map((item) => ({
                        value: item.id,
                        label: item.name,
                        propertyUId : item.propertyUId
                    }));
                    if (fetchId === fetchRef.current) {
                        setOption(transformedData);
                        setFetching(false);
                    }
                } else {
                    setFetching(false);
                }
            })
            .catch((error) => {
                setFetching(false);
            });
    };
    return debounce(loadOptions, 800);
}, []);


const filteredDetailsList = (propertyId) => {
  setShowSelect(false);
  const baseUrl = searchPropertyList;
  const data = {
    ids: propertyId ? propertyId : "",
  };
  makeRequestWithSpinner(
    showSpinner,
    hideSpinner,
    baseUrl,
    "post",
    data,
    "",
    false
  )
    .then((response) => {
      if (response.status === 0) {
        setShowSelect(true);
        let landingPageListData = response.list;
        const transformedData = landingPageListData.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOption(transformedData);
        const outputArray = transformedData.map((item) => item.label);
        setSelectedValues(outputArray);
        setDisplayOwners(true)
      } else {
        alert(`Message: ${response.message}`);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const handleSearchChange = (e, value) => {
    debugger;
    const label = value.label;
    const values = value.value;
    setSearchValues(value.propertyUId);
    setFieldValue("property", values);
    setFetching("propertyName", label);
    debounceFetcher(value);
    onDataRecive(value.propertyUId);
    setDisplayOwners(true)
};

const handleClearProperty = () => {
    setSearchValues("");
    setDisplayOwners(false)
  };

  const handleNextPage1 = (value) => {
    handleNextPage(value)
  }

  const onDataRecive1 = (value) => {
    onDataRecive(value)
  }

  const handleCancle = () => {
    navigate("/agency/ListAgreement");
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="details-form">
        <div className="property-details-content df-all all-input">
        <div className="text-parent" style={{ display: 'block' }}>
                                <div className="text7" style={{ fontSize: "24px" }}>
                                    Properties
                                </div>
                            </div>
        <div
                                        className="contacts-group"
                                       
                                    >
                                      
                                        {!displayAddNewProperty ?
                                            <div className={`lda-${name} col-lg-5 col-sm-12`}>
                                                <label className="s1">
                                                    Property <span style={{ color: "red" }}>*</span>
                                                </label>
                                                {showSelect ? (
                                                    <div>
                                                        <div style={{ display: 'flex' }}>
                                                            <Select
                                                                style={{ width: "100%", height: "40px", marginRight: '10px' }}
                                                                placeholder="Search for property"
                                                                labelInValue
                                                                filterOption={false}
                                                                onSearch={debounceFetcher}
                                                                notFoundContent={
                                                                    fetching ? <Spin size="small" /> : null
                                                                }
                                                                options={option}
                                                                onChange={(label, value) =>
                                                                    handleSearchChange(label, value)
                                                                }
                                                                defaultValue={selectedValues}
                                                                mode="single"
                                                                showSearch
                                                                allowClear
                                                                onClear={handleClearProperty}
                                                            />
                                                           {/* {searchValue === '' ?
                                                            <div className="action-icons">
                                                                <button className="pdb-save-changes" type="button" onClick={handleaddProperty}>
                                                                <BiPlus style={{ width: "25px", height: "25px" }} />  Add
                                                                </button>
                                                            </div> : null } */}
                                                        </div>
                                                        {errors.property && touched.property ? (
                                                            <p className="form-error">{errors.property}</p>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div> : null}
                                    </div>
         {displayAddNewProperty ?
          <div className="contacts-group">
            <SelectBox
              isMandatory
              label="Property For"
              name="propertyFor"
              options={property_For}
              placeholder={"Select Property For"}
              value={values.propertyFor}
              onChange={(val) => setFieldValue("propertyFor", val.value)}
              error={errors.propertyFor}
              touched={touched.propertyFor}
            />

            <SelectBox
              isMandatory
              label="Property Type"
              name="propertyType"
              options={property_type}
              placeholder={"Select Property Type"}
              value={values.propertyType}
              onChange={(val) => setFieldValue("propertyType", val.value)}
              error={errors.propertyType}
              touched={touched.propertyType}
            />

            <div className="contacts-group pd-address">
              <AddressInput
              labelName="Address"
                address={address}
                setAddress={setAddress}
                handlePressSelect={handlepressSelect}
                error={errors.address}
                touched={touched.address}
                isMandatory
                handleView={handleView}
                viewDetails={true}
              />

              {viewAddressDetails ? (
                <TextBox
                  addressMargin={true}
                  label=""
                  placeholder="Unit Number"
                  id="unitNumber"
                  name="unitNumber"
                  value={values.unitNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.unitNumber}
                  touched={touched.unitNumber}
                />
              ) : null}

              {viewAddressDetails ? (
                <TextBox
                  label=""
                  placeholder="Street Number"
                  id="streetNumber"
                  name="streetNumber"
                  value={values.streetNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.streetNumber}
                  touched={touched.streetNumber}
                />
              ) : null}

              {viewAddressDetails ? (
                <TextBox
                  label=""
                  placeholder="Street Name"
                  id="streetName"
                  name="streetName"
                  value={values.streetName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.streetName}
                  touched={touched.streetName}
                />
              ) : null}

              {viewAddressDetails ? (
                <TextBox
                  label=""
                  placeholder="Suburb"
                  id="suburb"
                  name="suburb"
                  value={values.suburb}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.suburb}
                  touched={touched.suburb}
                />
              ) : null}

              {viewAddressDetails ? (
                <TextBox
                  label=""
                  placeholder="State"
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.state}
                  touched={touched.state}
                />
              ) : null}

              {viewAddressDetails ? (
                <TextBox
                  label=""
                  placeholder="Postcode"
                  id="postCode"
                  name="postCode"
                  value={values.postCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.postCode}
                  touched={touched.postCode}
                />
              ) : null}
            </div>
            <div className="contacts-group pd-address">
              <div className="mt-5 cda-reportTo col-lg-5 col-sm-12">
                <label htmlFor="trading state" className="s1 w-100">
                  Assigned to<span style={{ color: "red" }}>*</span>
                </label>

                <Dropdown
                  value={dpdata}
                  onChange={handleAssignedTo}
                  options={reportTo}
                  optionLabel="text"
                  className="w-100 md:w-14rem"
                  placeholder="Select Assigned to"
                />

                {errors.assignedTo && touched.assignedTo ? (
                  <p className="form-error">{errors.assignedTo}</p>
                ) : null}
              </div>
            </div>
          </div> : null }

        </div>

       { !displayOwner ?<div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={handleCancle}
          >
            Cancel
          </button>

          <button className="pdb-save-changes" type="submit">
          Save & Next
          </button>
        </div> : null }
      </form>
      {displayOwner ? <OwnerShip getDetails={getDetails} onDataRecive1={onDataRecive1}  handleNextPage1={handleNextPage1}/> : null }

    </div>
  );
};

export default AddNewProperty;
