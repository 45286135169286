import React from "react";
import { useToggle, useEffect, useState, useRef } from "react";
import Header from "../../CommonSection/Header/Header";
import "./profile.css";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import axios from "axios";
import { useFormik } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import {
  apiUrlowner,
  BucketName,
  Region,
  AccessKey,
  Keyid,
  apiUrlamo,
  GetPendingProfiletenant,
  getProfileDetails,
  addtrustaccountProfileDetails,
} from "../../CommonSection/apiresources";
import {
  BiCalendar,
  BiEnvelope,
  BiMailSend,
  BiMap,
  BiMessage,
  BiPhone,
} from "react-icons/bi";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { ActionStatus } from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { AgencyProfileTrustAccountDetailsSchema } from "../../CommonSection/Schema/schema";

const initialValues = {
  rentalAccountName: "",
  saleAccountName: "",
  rentalBsb: "",
  saleBsb: "",
  rentalAccountNumber: "",
  saleAccountNumber: "",
};

const TrustAccountDetailsProfile = ({
  handleToastMessage,
  handleCurrentPage,
}) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [tempValue, setTempValue] = useState("");

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userUId !== "") {
      const timeout = setTimeout(() => {
        GetAgencyDetails();
      }, 2);
    }
  }, [userUId]);

  const GetAgencyDetails = () => {
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      `${getProfileDetails}?agencyUID=${agencyUniqueID}`,
      "get"
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          let agencyDetails = returnData.object;
          setValues({ ...values, ...agencyDetails });
        } else {
          handleToastMessage(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", error]);
      });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AgencyProfileTrustAccountDetailsSchema,
    onSubmit: (values, action) => {
      submitTrustAccDetails();
    },
  });

  const submitTrustAccDetails = () => {
    const formData = {
      AgencyUID: agencyUniqueID,
      SaleAccountName: values.saleAccountName,
      SaleBsb: values.saleBsb,
      SaleAccountNumber: values.saleAccountNumber,
      RentalAccountName: values.rentalAccountName,
      RentalBsb: values.rentalBsb,
      RentalAccountNumber: values.rentalAccountNumber,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addtrustaccountProfileDetails,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          handleToastMessage(["success", returnData.message]);
          handleCurrentPage("PortalAccountDetails");
        } else {
      
          handleToastMessage(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleToastMessage(["error", response.data.message]);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <form action="none" onSubmit={handleSubmit} className="details-form">
        <div className="df-all">
          <div className="all-input" >
            <div className="df-group" style={{display:'initial'}}>
              <div className="df-group-name col-lg-12 col-sm-12">
                Sale Account Details
              </div>
              <div className="tadf-accountNameSales col-lg-5 col-sm-12">
                <label htmlFor="accname" className="s1">
                  Account Name
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.saleAccountName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="saleAccountName"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.saleAccountName && touched.saleAccountName ? (
                  <p className="form-error">{errors.saleAccountName}</p>
                ) : null}
              </div>
              <div className="tadf-bsbSales col-lg-5 col-sm-12">
                <label htmlFor="bsbSales" className="s1">
                  BSB
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.saleBsb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="saleBsb"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.saleBsb && touched.saleBsb ? (
                  <p className="form-error">{errors.saleBsb}</p>
                ) : null}
              </div>
              <div className="tadf-accountNumberSales col-lg-5 col-sm-12">
                <label htmlFor="accountNumberSales" className="s1">
                  Account Number
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.saleAccountNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="saleAccountNumber"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.saleAccountNumber && touched.saleAccountNumber ? (
                  <p className="form-error">{errors.saleAccountNumber}</p>
                ) : null}
              </div>
            </div>
            <div className="df-group" style={{display:'initial'}}>
              <div className="tadf-group-name col-lg-12 col-sm-12">
                Rental Account Details *
              </div>
              <div className="tadf-accountNameRent col-lg-5 col-sm-12">
                <label htmlFor="accountNameRent" className="s1">
                  Account Name
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.rentalAccountName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="rentalAccountName"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.rentalAccountName && touched.rentalAccountName ? (
                  <p className="form-error">{errors.rentalAccountName}</p>
                ) : null}
              </div>
              <div className="tadf-bsbRent col-lg-5 col-sm-12">
                <label htmlFor="bsbRent" className="s1">
                  BSB
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.rentalBsb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="rentalBsb"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.rentalBsb && touched.rentalBsb ? (
                  <p className="form-error">{errors.rentalBsb}</p>
                ) : null}
              </div>
              <div className="tadf-accountNumberRent col-lg-5 col-sm-12">
                <label htmlFor="fname" className="s1">
                  Account Number
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control tenant-date"
                    placeholder=""
                    value={values.rentalAccountNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="rentalAccountNumber"
                    aria-describedby="basic-addon1"
                  />
                </div>
                {errors.rentalAccountNumber && touched.rentalAccountNumber ? (
                  <p className="form-error">{errors.rentalAccountNumber}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() => handleparent(["warn", "changes are canceled"])}
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default TrustAccountDetailsProfile;
