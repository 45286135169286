import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {updatemangmntagrmntperioddetails} from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import {addParametersForProfiles,dynamicUrl,} from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import Collapse from "../../input-components/collapse";
import { agreementPeriodValid } from "../../../components/CommonSection/Schema/schema";
import { useScrollToFirstError } from "../../../Utilities/ScrollToErrorField";

dayjs.extend(customParseFormat);

const initialValues = {
    AgreementDate: "",
    LeasePremisesAtMarketRent:"",
    ReferToPrincipalForReLease:"",
    ReviewRentBfrRLease:""
};

const AgreementPeriod = ({ onDataRecive,handleparentsuccess,handleCurrentPage,getDetails,reportTo,handleNextPage}) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const ToastRef = useRef();


    useEffect(() => {
        if (getDetails) {
          setValues({ ...values, ...getDetails });
          setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
          setFieldValue("ReferToPrincipalForReLease",getDetails.referToPrincipalForReLease)
          setFieldValue("ReviewRentBfrRLease",getDetails.reviewRentBfrRLease)
          setFieldValue("LeasePremisesAtMarketRent",getDetails.leasePremisesAtMarketRent)
        }
      }, [getDetails, reportTo]);

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

      function formatDate(dateString) {
        if (!dateString) {
          return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${year}-${month}-${day}`;
      }

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        isValid,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: agreementPeriodValid,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            AgreementDate: values.AgreementDate,
            LeasePremisesAtMarketRent: values.LeasePremisesAtMarketRent,
            ReferToPrincipalForReLease: values.ReferToPrincipalForReLease,
            ReviewRentBfrRLease: values.ReviewRentBfrRLease,
        };
        const url = updatemangmntagrmntperioddetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    //onDataRecive(getDetails.propertyUId)
                    handleNextPage("FeesAndCharges")
                    ToastRef.current.notify(["success", returnData.message]);
                   // navigate(`/agency/ListOpenHome`);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
            });
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };


    const handleClear = () => {
        handleNextPage("propetyies")
    };

    
    const onChangeSwitch = (e, label) => {
        if (label === "LeasePremisesAtMarketRent") {
          setFieldValue("LeasePremisesAtMarketRent", e);
        } else if (label === "ReviewRentBfrRLease") {
          setFieldValue("ReviewRentBfrRLease", e);
        }else if (label === "ReferToPrincipalForReLease") {
            setFieldValue("ReferToPrincipalForReLease", e);
          }
      };

      const handleCancle = () => {
        navigate("/agency/ListAgreement");
      }
      useScrollToFirstError(isValid, errors, submitCount);

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="details-form">
                                <div className="property-details-content df-all all-input">
                            
                                        <div>
                                            <Collapse
                                                id={"Agreement-Period"}
                                                title={"Agreement-Period"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        {/* <div className='s1'>In consideration of the Agent promising to use their best endeavors, the Principal hereby grants to the Agent the right to act on behalf of the Principal in leasing and management the property in accordance with this agreement</div> */}
                                                        <TextBox
                                                            isMandatory
                                                            type={"date"}
                                                            label="Commencing On"
                                                            name="AgreementDate"
                                                            value={values.AgreementDate}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.AgreementDate}
                                                            touched={touched.AgreementDate}
                                                        />
                                                        {/* <div className='s1'>30 Days’ notice given by either party</div> */}
                                                        {/* <div className='s1'>If notice is given all fees and charges will be payable on the completion date.</div> */}
                                                    </div>
                                                } />
                                                  <Collapse
                                                id={"Agreement-Authority"}
                                                title={"Agent’s Authority for Leasing"}
                                                data={
                                                    <div style={{ padding: "0px 30px" }}>
                                                        <div className='s1 pl-3'>At the end of each tenancy, the Agent is authorized to :</div>
                                                       <div className="pddpn-group">
                                                       <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="s1" style={{marginRight:'270px'}} >Lease and Re-lease the Premises at market rent:</div>
                                                                <div><Switch checked={values.LeasePremisesAtMarketRent} onChange={(e) => onChangeSwitch(e, "LeasePremisesAtMarketRent")}/></div>
                                                            </div>
                                                        </div>
                                                        <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="s1"  style={{marginRight:'245px'}} >Review the rent when appropriate before re-leasing:</div>
                                                                <div><Switch checked={values.ReviewRentBfrRLease} onChange={(e) => onChangeSwitch(e, "ReviewRentBfrRLease")}/></div>
                                                            </div>
                                                        </div>
                                                        <div className="pd-eventDate col-lg-12 col-sm-12 mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="mr-3 s1">Refer to the Principal for instructions concerning re-leasing and advertising/promotion:</div>
                                                                <div><Switch checked={values.ReferToPrincipalForReLease} onChange={(e) => onChangeSwitch(e, "ReferToPrincipalForReLease")}/></div>
                                                            </div>
                                                        </div>
                                                       </div>
                                                    </div>
                                                } />
                                      </div> 

                                </div>
                                <div className="property-details-buttons">
                                <button
                                        className="pdb-save-changes"
                                        type="reset"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className=" pdb-cancel "
                                        type="reset"
                                        onClick={handleClear}
                                    >
                                        Previous
                                    </button>
                                    <button className="pdb-save-changes" type="submit">
                                        Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgreementPeriod;
