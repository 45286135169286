import React from "react";
import { useEffect, useState, useRef } from "react";
import "./propertiesagency.css";
import ToastAlert from "../../../CommonSection/ToastAlert/ToastAlert";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../src/input-components/selectBoxNew";
import { assignedTo, addPropertyListings, propertyDetails } from "../../../CommonSection/apiresources";
import { Dropdown } from "primereact/dropdown";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import { Dropdown as AntDropdown, Checkbox, Tabs } from "antd";
import { Button } from "primereact/button";
import CustomModal from "../../../../src/input-components/Modal";
import { FormatDate } from "../../../CommonSection/Utility/Utility";
import Header from "../../../CommonSection/Header/Header";
import Sidebar from "../../../CommonSection/SideBar/sideBar";
import { dynamicUrl } from "../../../../Utilities/profileManagement";
import { BiPlus } from "react-icons/bi";

const { TabPane } = Tabs;

const initialValues = {
  listingStatus: "",
  propertyAgreementType: "",
  rentTenure: "",
  propertyPrice: "",
  rentPrice: "",
  isCommercial: "",
  isNew: "",
  dualAgent: "",
  leadAgent: "",
  isDualAgent: "",
  isShowPrice: "1",
  showText: "",
  reaUploadId: "",
  displaySales: false,
  displayRental: false,
  propertyFor: "",
  listingId: 0,
  DateAvailable: null
};

const PropertyListing = ({  }) => {
  const [userId, setUserId] = useState("");
  const [leadAgent, setLeadAgent] = useState(null);
  const [dualAgent, setDualAgent] = useState(null);
  const [dpdata, setDpdata] = useState([]);
  const [getDetails, setGetDetails] = useState(null);
  const [statusSaleData, setStatusSaleData] = useState([
    { label: "Active", value: 1 },
    { label: "Deleted", value: 2 },
    { label: "Off Market", value: 3 },
    { label: "Sold", value: 4 },
    { label: "Under Offer", value: 5 },
  ]);
  const [statusRentData, setStatusRentData] = useState([
    { label: "Active", value: 1 },
    { label: "Deposit Taken", value: 2 },
    { label: "Deleted", value: 3 },
    { label: "Off Market", value: 4 },
    { label: "Leased", value: 5 },
  ]);

  const [agreeData, setAgreeData] = useState([
    { label: "Auction", value: 1 },
    { label: "Exclusive", value: 2 },
    { label: "Multi List", value: 3 },
    { label: "Conjunctional", value: 4 },
    { label: "Open", value: 5 },
    { label: "Sale by Negotiation", value: 6 },
    { label: "Set Sale", value: 7 },
    { label: "Management", value: 8 },
    { label: "Tenant Lease", value: 8 },
  ]);

  const [rentData, setRentData] = useState([
    { label: "Weekly", value: 1 },
    { label: "Monthly", value: 2 },
  ]);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [selectedLeadAgents, setSelectedLeadAgents] = useState([]);
  const [isReaUploaded, setIsReaUploaded] = useState(null);
  const [isDomainUploaded, setIsDomainUploaded] = useState(null);
  const [edit, setEdit] = useState(false);
  const [listStatusOptions, setListStatusOptions] = useState(false);
  const [displaySaleFields, setDisplaySaleFields] = useState(false);
  const [displayRentalFields, setDisplayRentalFields] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [reaUplode, setReaUplode] = useState(false);
  const [domainUplode, setDomainUplode] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAllProperties");
  const [propertyId, setPropertyId] = useState(null);
  const [AgentId, setAgentId] = useState(null)
  const ToastRef = useRef();

  const finalReportToObject = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: "--Select--",
      value: "Select",
    },
  ];

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          setAgentId(parsedData.agentId)
          reportData(parsedData.agencyUniqueID);
          GetApi()
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const reportData = (agencyUID) => {
    const apiUrl = assignedTo;
    const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
    axios.get(baseUrl).then((response) => {
      if (response.data.object) {
        const newData = response.data.object;
        const updatedData = [...newData, ...finalReportToObject];
        setDpdata(updatedData);
      }
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const propertyId = searchParams.get('propertyId');
    const isRentalListed = searchParams.get('isRentalListed');
    const isSaleListed = searchParams.get('isSaleListed');
    setPropertyId(propertyId)
    if (isRentalListed === 'true' && isSaleListed === 'true') {
      ToastRef.current.notify(["error", "Both Sale and Rent Added"]);
    } else if (isRentalListed === 'true' && isSaleListed === 'false') {
      setDisplaySaleFields(true)
      setListStatusOptions(statusSaleData)
      setFieldValue("displaySales", true);
      setFieldValue("propertyFor", 1);
    } else if (isRentalListed === 'false' && isSaleListed === 'true') {
      setDisplaySaleFields(false)
      setListStatusOptions(statusRentData)
      setFieldValue("displayRental", true);
      setFieldValue("propertyFor", 2);

    } else if (isRentalListed === 'false' && isSaleListed === 'false') {
      setListStatusOptions(statusSaleData)
      setFieldValue("propertyFor", 1);
    }
  }, [])

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = (UploadPortal = false) => {
        if (getDetails.propertyPictures.length === 0) {
      ToastRef.current.notify(["error", "Property Images are required."]);
    } else if (getDetails.propertyType != "16" && !getDetails.countBedrooms === null) {
      ToastRef.current.notify(["error", "A 'number of bedrooms' has not been provided for this property."]);
    } else if (getDetails.propertyType != "16" && !getDetails.countBathrooms === null) {
      ToastRef.current.notify(["error", "A 'number of bathrooms' has not been provided for this property."]);
    } else if (getDetails.propertyDescription === null || getDetails.propertyDescription === "") {
      ToastRef.current.notify(["error", "Please enter description for property"]);
    } else if (getDetails.propertyHeading === null) {
      ToastRef.current.notify(["error", "Please enter Heading for property"]);
    }else{
    values.dualAgent = parseInt(values.dualAgent, 10);
    values.leadAgent = parseInt(values.leadAgent, 10);
    const formData = {
      ListingId: values.listingId ? values.listingId : 0,
      AgentId: AgentId ? AgentId : "",
      PropertyId: propertyId,
      ListingStatus: values.listingStatus,
      PropertyAgreementType: values.propertyAgreementType,
      RentTenure: values.rentTenure,
      PropertyPrice: values.propertyPrice,
      RentPrice: values.rentPrice,
      IsCommercial: values.isCommercial === "1" ? true : false,
      IsNew: values.isNew,
      DualAgent: values.dualAgent,
      IsDualAgent: dualAgent ? true : dualAgent === "Select" ? null : false,
      IsShowPrice: values.isShowPrice === "1" ? true : false,
      ShowText: values.showText,
      ReaUploadId: values.reaUploadId,
      PropertySaleRental: values.propertyFor,
      DateAvailable: values.DateAvailable
    };
    if (UploadPortal) {
      formData.UploadPortal = true;
      formData.ReaUpload = reaUplode;
      formData.DomainUpload = domainUplode;
    }
    const url = addPropertyListings;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", returnData.message]);
          navigate("/agency/ListAllProperties");
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
    }
  };

  const handleTopicValue = (text) => {
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleLeadAgent = (e) => {
    const selectedLeadAgentValue = e.value;
    setLeadAgent(e.value);
    setFieldValue("leadAgent", e.value);
    setSelectedLeadAgents(selectedLeadAgentValue);
  };

  const updatedReportToWithoutSelectedLead = dpdata.filter(
    (agent) => !selectedLeadAgents.includes(agent.value)
  );

  const handleDualAgent = (e) => {
    setDualAgent(e.value);
    setFieldValue("dualAgent", e.value);
  };

  const GetApi = () => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUId");
    const baseUrl = propertyDetails;
    const apiUrl = `${baseUrl}?propertyID=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          setGetDetails(response.object);
        } else {
          ToastRef.current.notify(["warn", response.data.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  const handleItemClickInPropertyListings = (e) => {
    setOpenUpload(true);
  };


  const handleDisplaySaleOrRental = (e) => {
    setEdit(true);
    if (e === "sale") {
      setListStatusOptions(statusSaleData)
      setFieldValue("displaySales", true);
      setFieldValue("displayRental", false);
      setDisplaySaleFields(true);
      setDisplayRentalFields(false);
      setFieldValue("propertyFor", 1);
    } else if (e === "rent") {
      setListStatusOptions(statusRentData)
      setFieldValue("displaySales", false);
      setFieldValue("displayRental", true);
      setDisplaySaleFields(false);
      setDisplayRentalFields(true);
      setFieldValue("propertyFor", 2);
    }
  }


  const handleCancel = () => {
    navigate("/agency/ListAllProperties");
  }


  const onChangeUpload = (type) => {
    if (type === "rea") {
      setReaUplode(!reaUplode);
    } else if (type === "domain") {
      setDomainUplode(!domainUplode);
    }
  };

  const closeUpload = () => {
    setOpenUpload(false);
  };

  const handleReaSubmit = () => {
    handleFinalSubmit(true)
  };

  const searchParams = new URLSearchParams(window.location.search);
  const isRentalListed = searchParams.get('isRentalListed');
  const isSaleListed = searchParams.get('isSaleListed');

  return (
    <div className="dashboard-agency">
      <CustomModal
        title="Upload property to"
        width={500}
        closable={closeUpload}
        visible={openUpload}
        onCancel={closeUpload}
        modalData={
          <div
            className="row"
            style={{ marginTop: "30px", marginLeft: "10px" }}
          >
            <div className="col-lg-5 col-sm-12">
              <Checkbox
                checked={reaUplode}
                onChange={() => onChangeUpload("rea")}
              >
                REA {isReaUploaded ? "(Update)" : null}
              </Checkbox>
            </div>
            <div className="col-lg-5 col-sm-12">
              <Checkbox
                checked={domainUplode}
                onChange={() => onChangeUpload("domain")}
              >
                Domain {isDomainUploaded ? "(Update)" : null}
              </Checkbox>
            </div>
          </div>
        }
        footer={[
          <div
            key="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{ marginRight: "20px" }}
              className="pdb-save-changes mt-2"
              onClick={handleReaSubmit}
            >
              Submit
            </button>
          </div>,
        ]}
      />
      <Header />
      <ToastAlert ref={ToastRef} />
      <div
        className="blank_space"
        style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
      ></div>
      <div className="dashboardagency-content">
        <Sidebar
          visible={sidebarVisible}
          handleTopicValue={handleTopicValue}
          topicValue={activeTopic}
        />
        <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
          <div className="handle-Editpart1 mb-3">
            <div >
              {isSaleListed === "false" ?
                <Button
                  type="button"
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      Sale
                    </span>
                  } className={
                    values.displaySales === true
                      ? "custom-button-success"
                      : "p-button-secondary"
                  }
                  onClick={(e) => handleDisplaySaleOrRental("sale")}
                  text
                  raised
                  style={{ borderRadius: "5px" }}
                /> : null}
              {isRentalListed === "false" ?
                <Button
                  type="button"
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <BiPlus style={{ width: "25px", height: "25px" }} />
                      Rent
                    </span>
                  } className={
                    values.displayRental === true
                      ? "custom-button-success"
                      : "p-button-secondary"
                  }
                  onClick={(e) => handleDisplaySaleOrRental("rent")}
                  text
                  raised
                  style={{ borderRadius: "5px" }}
                /> : null}
            </div>

          </div>
          {edit ? <div className="property-details-content df-all all-input">

            <div className="contacts-group" style={{ padding: "0px 30px" }}>
              <div className="row pt-2 pl-3">
                <div className="label">
                  <strong>Property For:</strong>
                </div>
                <strong style={{ color: 'black' }} className="value">{values.propertyFor === 1 ? "Sale" : "Rent"}</strong>
              </div>

              <SelectBox
                label="Listing Status"
                name="listingStatus"
                options={listStatusOptions}
                placeholder={"Select Status"}
                value={values.listingStatus}
                onChange={(val) =>
                  setFieldValue("listingStatus", val.value)
                }
                error={errors.listingStatus}
                touched={touched.listingStatus}
              />

              {displaySaleFields ? (
                <SelectBox
                  label="Agreement Type"
                  name="propertyAgreementType"
                  options={agreeData}
                  placeholder={"Select Agreement "}
                  value={values.propertyAgreementType}
                  onChange={(val) =>
                    setFieldValue("propertyAgreementType", val.value)
                  }
                  error={errors.propertyAgreementType}
                  touched={touched.propertyAgreementType}
                />
              ) : null}
              {displaySaleFields ? (
                <TextBox
                  label="Property Price"
                  name="propertyPrice"
                  placeholder="Price"
                  value={values.propertyPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.propertyPrice}
                  touched={touched.propertyPrice}
                />
              ) : null}
              {displayRentalFields ? (
                <TextBox
                  label="Rental Price"
                  name="rentPrice"
                  placeholder="Price"
                  value={values.rentPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.rentPrice}
                  touched={touched.rentPrice}
                />
              ) : null}
              {displayRentalFields ? (
                <SelectBox
                  label="Rental Intervals"
                  name="rentTenure"
                  options={rentData}
                  placeholder={"Select Intervals"}
                  value={values.rentTenure}
                  onChange={(val) => setFieldValue("rentTenure", val.value)}
                  error={errors.rentTenure}
                  touched={touched.rentTenure}
                />
              ) : null}
              <div className="cda-AgentAddress col-lg-5 col-sm-12">
                <label htmlFor="AgentAddress" className="s1">
                  Is Commercial
                </label>
                <div className="input-group mb-3">
                  <Button
                    type="button"
                    label="Yes"
                    className={
                      values.isCommercial === "1"
                        ? "custom-button-success"
                        : "p-button-secondary"
                    }
                    onClick={() => setFieldValue("isCommercial", "1")}
                    text
                    raised
                    style={{ borderRadius: "5px" }}
                  />
                  <Button
                    type="button"
                    label="No"
                    className={
                      values.isCommercial === "2"
                        ? "custom-button-success"
                        : "p-button-secondary"
                    }
                    onClick={() => setFieldValue("isCommercial", "2")}
                    text
                    raised
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>

              <div className="cda-AgentAddress col-lg-5 col-sm-12">
                <label htmlFor="AgentAddress" className="s1">
                  New or Established
                </label>
                <div className="input-group mb-3">
                  <Button
                    type="button"
                    label="New"
                    className={
                      values.isNew === "1"
                        ? "custom-button-success"
                        : "p-button-secondary"
                    }
                    onClick={() => setFieldValue("isNew", "1")}
                    text
                    raised
                    style={{ borderRadius: "5px" }}
                  />
                  <Button
                    type="button"
                    label="Established"
                    className={
                      values.isNew === "2"
                        ? "custom-button-success "
                        : "p-button-secondary"
                    }
                    onClick={() => setFieldValue("isNew", "2")}
                    text
                    raised
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>

              <div className="cda-reportTo col-lg-5 col-sm-12">
                <label htmlFor="trading state" className="s1 w-100">
                  Lead Agent<span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={leadAgent}
                  onChange={handleLeadAgent}
                  options={dpdata}
                  optionLabel="text"
                  className="w-100 md:w-14rem"
                  placeholder="Select agent"
                />

                {errors.leadAgent && touched.leadAgent ? (
                  <p className="form-error">{errors.leadAgent}</p>
                ) : null}
              </div>
              <div className="cda-reportTo col-lg-5 col-sm-12">
                <label htmlFor="trading state" className="s1 w-100">
                  Dual Agent
                </label>
                <Dropdown
                  value={dualAgent}
                  onChange={handleDualAgent}
                  options={updatedReportToWithoutSelectedLead}
                  optionLabel="text"
                  className="w-100 md:w-14rem"
                  placeholder="Select dual agent"
                />

                {errors.dualAgent && touched.dualAgent ? (
                  <p className="form-error">{errors.dualAgent}</p>
                ) : null}
              </div>

              {!displayRentalFields ? (
                <div className="cda-AgentAddress col-lg-5 col-sm-12">
                  <label htmlFor="AgentAddress" className="s1">
                    Show Price
                  </label>
                  <div className="input-group mb-3">
                    <Button
                      type="button"
                      label="Show Price"
                      className={
                        values.isShowPrice === "1"
                          ? "custom-button-success"
                          : "p-button-secondary"
                      }
                      onClick={() => setFieldValue("isShowPrice", "1")}
                      text
                      raised
                      style={{ borderRadius: "5px" }}
                    />
                    <Button
                      type="button"
                      label="Show Text"
                      className={
                        values.isShowPrice === "2"
                          ? "custom-button-success"
                          : "p-button-secondary"
                      }
                      onClick={() => setFieldValue("isShowPrice", "2")}
                      text
                      raised
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                </div>) : null}
              {!displayRentalFields && values.isShowPrice === "2" && (
                <TextBox
                  isMandatory
                  label="Show Text"
                  placeholder="Text"
                  name="showText"
                  value={values.showText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.showText}
                  touched={touched.showText}
                />
              )}
              {displayRentalFields ?
                <TextBox
                  label="Date Available"
                  type="date"
                  name="DateAvailable"
                  placeholder="dd/mm/yyyy"
                  value={FormatDate(values.DateAvailable)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.DateAvailable}
                  touched={touched.DateAvailable}
                /> : null}
            </div>
          </div> : null}
          {edit ? <div className="property-details-buttons">
            <button
              className="pdb-cancel"
              type="reset"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="pdb-save-changes" type="submit">
              Save
            </button>
            <button
              className="pdb-cancel"
              type="button"
              onClick={handleItemClickInPropertyListings}
            >
              Save & Publish
            </button>
          </div> : null}
        </form>
      </div>
    </div>
  );
};

export default PropertyListing;
