import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import ShowValue from "../../input-components/ShowValue";
import { getTicketbyid, addUpdateComment,closeTicket } from "../../../components/CommonSection/apiresources";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import { ActionStatus, isManualFilePath } from "../../../Utilities/enum";
import { FaDownload } from "react-icons/fa";
import CustomModal from "../../input-components/Modal";
import TextArea from "../../input-components/textarea";
import { Table } from "antd"; // Import Ant Design Table
import { FormatDate } from "../../../components/CommonSection/Utility/Utility"
import moment from "moment"; // Import moment for date formatting

const TicketDetails = () => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [topicValue, setTopicValue] = useState("Profile");
    const [activeTopic, setActiveTopic] = useState("ListTicket");
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [getDetails, setGetDetails] = useState({});
    const [openAddResponse, setOpenAddResponse] = useState(false);
    const [response, setResponse] = useState("");
    const [userId, setUserId] = useState("");
    const [closeTicketResVal , setCloseTicketRes] = useState("")
    const [closeTicketResponse , setCloseTicketResponse]  = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    var url = new URL(window.location.href);
    var ticketUID = url.searchParams.get("ticketUID");
    const ToastRef = useRef();

    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserId(parsedData.userId);
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

    useEffect(() => {
        GetDetailsApi();
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
    }, []);

    const GetDetailsApi = () => {
        const url = getTicketbyid + `?TicketId=${ticketUID}`;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "get", "", null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    setGetDetails(response.object);
                }
            })
            .catch((error) => {
                console.error("Error fetching ticket details:", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false once API call completes
            });
    };

    const handleDownload = () => {
        const fileUrl = `${isManualFilePath}${getDetails.docPath}`;
        window.open(fileUrl, '_blank');
    };

    const extractFileName = (path) => {
        return path.split('/').pop();
    };

    const handleAddResponse = () => {
        setOpenAddResponse(true);
        setResponse("");
    };

    const handleCloseResponse = () => {
        setCloseTicketResponse(true)
        setCloseTicketRes("")
    }

    const closeAddResponse = () => {
        setOpenAddResponse(false);
    };

    const closeTicketResponses = () => {
        setCloseTicketResponse(false);
    };

    const handleInput = (e) => {
        setResponse(e.target.value);
    };

    const handleCloseTicket = (e) => {
        setCloseTicketRes(e.target.value)
    }

    const AddResponse = () => {
        const formData = {
            TicketUniqueId: ticketUID,
            Comment: response,
            CreatedBy: userId
        };
        const url = addUpdateComment;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", response.message]);
                    setOpenAddResponse(false);
                    GetDetailsApi()
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", "Error retrieving data from AsyncStorage"]);
            });
    };

    const CloseResponse = () => {
        const formData = {
            TicketUniqueId: ticketUID,
            ResolveDetail: closeTicketResVal,
        };
        const url = closeTicket;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData, null, false)
            .then((response) => {
                if (response.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", response.message]);
                    setCloseTicketResponse(false);
                    GetDetailsApi()
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", "Error retrieving data from AsyncStorage"]);
            });
    };

    if (loading) {
        return <div>Loading...</div>; // Display a loading message or spinner
    }

    // Define table columns
    const columns = [
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (text) => moment(text).format('DD-MM-YYYY hh:mm A'), // Format date to show only the date part
        },
        // {
        //     title: 'Created By',
        //     dataIndex: 'createdBy',
        //     key: 'createdBy',
        //     render: (text) => (text ? text : 'N/A'), // Display 'N/A' if createdBy is null
        // },
    ];

    console.log(getDetails,'kkk')
    return (
        <div className="dashboard-agency">
            <Header />
            <div
                className="blank_space"
                style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
            ></div>
            <div className="continer-dbag">
                <ToastAlert ref={ToastRef} />
                <div className="dashboardagency-content">
                    <Sidebar
                        visible={sidebarVisible}
                        handleTopicValue={handleTopicValue}
                        topicValue={activeTopic}
                    />
                    <div style={{ width: "100%", padding: "20px" }}>
                        <div className="property-details-content df-all all-input details-form" style={{paddingLeft:'10px'}}>
                            <div className="details-form" style={{ width: '100%' }}>
                                <div className="contacts-group">
                                    <div>
                                        <div className="Heading">
                                            <strong>Ticket Details</strong>
                                        </div>
                                        <ShowValue colval={3} Label='Type' setData={getDetails.ticketType === 1 ? "Incident" : "Self Service"} />
                                        <ShowValue colval={3} Label='Details' setData={getDetails.ticketDetail} />
                                        <ShowValue colval={3} Label='Created Date' setData={FormatDate(getDetails.createdOn)} />
                                        <ShowValue colval={3} Label='Status' setData={getDetails.ticketStatus === 1 ? "Created" : getDetails.ticketStatus === 2 ? "Accepted" : getDetails.ticketStatus === 3 ? `Resolved${getDetails.resolveDetail ? ` (${getDetails.resolveDetail})` : ''}` : ""} />
                                        {getDetails.resolveDetail ? <ShowValue colval={3} Label='Resolved Note' setData={getDetails.resolveDetail} /> : null }
                                        {getDetails.docPath && (
                                            <div className={`row`}>
                                                <div className={"col-lg-3"}>
                                                    <strong>{"Document"}:</strong>
                                                </div>
                                                <div className={"value"} style={{ cursor: 'pointer' }} onClick={handleDownload}>
                                                    {extractFileName(getDetails.docPath)}
                                                    <span className="ml-2 icon" title="Download"><FaDownload /></span>
                                                </div>
                                            </div>
                                        )}
                                        <CustomModal
                                            title="Add Response"
                                            width={500}
                                            closable={closeAddResponse}
                                            visible={openAddResponse}
                                            onCancel={closeAddResponse}
                                            modalData={
                                                <div style={{ marginLeft: "10px" }}>
                                                    <TextArea colVal={12} value={response} name="TicketDetail"  onChange={handleInput} />
                                                </div>
                                            }
                                            footer={[
                                                <div
                                                    key="footer"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{ marginRight: "20px" }}
                                                        className="pdb-save-changes mt-2"
                                                        onClick={AddResponse}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>,
                                            ]}
                                        />
                                            <CustomModal
                                            title="Close Ticket"
                                            width={500}
                                            closable={closeTicketResponses}
                                            visible={closeTicketResponse}
                                            onCancel={closeTicketResponses}
                                            modalData={
                                                <div style={{ marginLeft: "10px" }}>
                                                    <TextArea colVal={12} value={closeTicketResVal} name="TicketDetail"  onChange={handleCloseTicket} />
                                                </div>
                                            }
                                            footer={[
                                                <div
                                                    key="footer"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{ marginRight: "20px" }}
                                                        className="pdb-save-changes mt-2"
                                                        onClick={CloseResponse}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>,
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!getDetails.ticketStatus === 3 ?
                        <div className="property-details-buttons">
                        <button
                                className="pdb-cancel"
                                type="button"
                                onClick={() => handleCloseResponse()}
                            >
                                Resolve
                            </button>
                            <button
                                className="pdb-save-changes"
                                type="button"
                                onClick={() => handleAddResponse()}
                            >
                                Add Response
                            </button>
                           
                        </div> : null }
                      {getDetails.ticketComments.length > 0  ?
                        <div style={{ marginTop: "20px" }}>
                            <div style={{backgroundColor:"#d0d5dd7d", padding:'5px',borderRadius:'4px', fontWeight:'500'}}>Response Trail</div>
                            <Table
                                columns={columns}
                                dataSource={getDetails.ticketComments} // Pass comments data to table
                                rowKey="createdOn" // Use a unique key for each row, here it's createdOn
                            />
                        </div> : null }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketDetails;
