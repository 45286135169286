import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {FilePath,tenantagreementsignature,generateTenantAgreementPdfReport} from "../../../../components/CommonSection/apiresources";
import TextBox from "../../../input-components/textbox";
import {makeRequestWithSpinner,uploadToS3WithSpinner,DownloadFiletWithSpinner,uploadSignToS3WithSpinner} from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus, signatureStatus } from "../../../../Utilities/enum";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastAlert from "../../../../components/CommonSection/ToastAlert/ToastAlert";
import "../../openHome/openHome.css";
import Collapse from "../../../input-components/collapse";
import SignatureCanvas from "react-signature-canvas";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import Verify from "../../../../Utilities/verify"

dayjs.extend(customParseFormat);
const currentDate = new Date();
const initialValues = {
  AgreementDate: "",
  LeasePremisesAtMarketRent: "",
  ReferToPrincipalForReLease: "",
  ReviewRentBfrRLease: "",
  PropertyImages: [],
  SignedDate: "",
  AgentSignedDate: "",
};

const SignaturePage = ({handleparentsuccess,getDetails,reportTo,handleNextPage}) => {
  let selectedFile;
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const ToastRef = useRef();
  const [propertyimageitems, setPrincipleimageitems] = useState([]);
  const [principleImages, setPrincipleImages] = useState();
  const [secondaryOwner, setSecondaryOwner] = useState([]);
  const inputRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const signatureRef = useRef(null);
  const [sign, setSign] = useState(null);
  const [IsSigned, setIsSigned] = useState(null);
  const [date, setDate] = useState("");
  const [verified , setVerified] = useState(false)
  // Function to clear the signature canvas
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  // Function to get the signature data as an image URL
  const getSignatureImage = () => {
    setSign(signatureRef.current.toDataURL());
    handleSignUpload(signatureRef.current.toDataURL());
    return signatureRef.current.toDataURL();
  };

  useEffect(() => {
    // Set default value after component mounts
    const matchedObject = getDetails.documentReceipients.filter((doc) =>
    utype === "3" ? doc.agentId : doc.contactId === parseInt(ref)
  );
    setIsSigned(matchedObject.length > 0 ? matchedObject[0].isSigned : false);
    if (signatureRef.current && matchedObject[0].signValue) {
      signatureRef.current.clear(); // Clear the canvas
      // Draw the saved signature
      const ctx = signatureRef.current.getCanvas().getContext("2d");
      const image = new Image();
      image.src = matchedObject[0].signValue;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
    }
  }, [activeIndex]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, []);

  useEffect(() => {
    const today = new Date();
    setFieldValue("SignedDate", today);
    setDate(today.toISOString().split("T")[0]); // Set the default value to yesterday's date
  }, []);


  useEffect(() => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    let utype = url.searchParams.get("utype");
    setVerified(utype === "3" ? true : false);
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setFieldValue("AgreementDate", formatDate(getDetails.agreementDate));
      setFieldValue(
        "ReferToPrincipalForReLease",
        getDetails.referToPrincipalForReLease
      );
      setFieldValue("ReviewRentBfrRLease", getDetails.reviewRentBfrRLease);
      setFieldValue(
        "LeasePremisesAtMarketRent",
        getDetails.leasePremisesAtMarketRent
      );
      setSecondaryOwner(getDetails.contactlistModel);
      const matchedObject = getDetails.documentReceipients.filter(
        (doc) => doc.contactId === parseInt(ref)
      );
      setFieldValue(
        "SignedDate",
        formatDate(matchedObject.length > 0 ? matchedObject[0].signedDate : "")
      );
      function formatDate(dateString) {
        if (!dateString) {
          return "";
        }
        const [year, month, day] = dateString.split("T")[0].split("-");
        return `${year}-${month}-${day}`;
      }
      let contacts = getDetails.contactlistModel
        ? getDetails.contactlistModel
        : [];
      const matchedObjects =
        contacts.length === 0
          ? []
          : getDetails.contactlistModel.filter(
              (doc) => doc.contactId === parseInt(ref)
            );
      if (getDetails.primarycontactModel?.contactId === parseInt(ref)) {
        setVerified(getDetails.primarycontactModel.isUserVerified);
      } else if (contacts.length != 0) {
        if (
          matchedObjects[0] != null &&
          matchedObjects[0].contactId === parseInt(ref)
        ) {
          setVerified(matchedObjects[0].isUserVerified);
        }
      }
    }
  }, [getDetails, reportTo]);

  const isVerified = () => {
    setVerified(true)
  }

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //validationSchema: agreementPeriodValid,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });
  const handleFinalSubmit = (principleImages) => {
    const urls = new URL(window.location.href);
    let ref = urls.searchParams.get("ref");
    let signMethods =
      activeIndex + 1 === signatureStatus.Image
        ? 1
        : activeIndex + 1 === signatureStatus.Type
        ? 2
        : activeIndex + 1 === signatureStatus.Draw
        ? 3
        : "";
    let formData = {
      DocumentId: parseInt(did),
      ContactId: utype === "13" ? ref : null,
      AgentId: utype === "3" ? ref : null,
      SignedDate: moment(),
      SignMethod: signMethods,
      SignPicturePath: principleImages,
      SignValue: activeIndex + 1 === signatureStatus.Image ? null : sign,
    };

    const url = tenantagreementsignature;
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      url,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setIsSigned(true);
          ToastRef.current.notify(["success", returnData.message]);
          setIsSigned(true);
        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleClear = () => {
    handleNextPage("RepairAndMaintainance");
  };

  const textWithBullet = "\u2022 ";

  const select = (event) => {
    inputRef.current.click();
  };
  const handleSignUpload = async (imgByte) => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    await uploadSignToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${ref}`,
      imgByte
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
        setPrincipleImages(returnData.Key);
        handleFinalSubmit(returnData.Key);
        handleparentsuccess(["success", "Sign uploaded successfully", true]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };
  const handleUplodeImg = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload();
    }
  };

  const handleUpload = async () => {
    const url = new URL(window.location.href);
    let ref = url.searchParams.get("ref");
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "signatures/",
      `contact_${ref}`,
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        const transformedItem = {
          id: Math.floor(Math.random() * 1000000),
          src: FilePath + returnData.Key,
          width: 1,
          height: 1,
        };
        setPrincipleimageitems((prevItems) => [...prevItems, transformedItem]);
        setPrincipleImages(returnData.Key);
        handleparentsuccess(["success", "File uploaded successfully", true]);
        handleFinalSubmit(returnData.Key);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const url = new URL(window.location.href);
  let ref = url.searchParams.get("ref");
  let did = url.searchParams.get("did");
  let utype = url.searchParams.get("utype");

  const isRefMatched = getDetails.documentReceipients.some(
    (doc) => doc.contactId === parseInt(ref)
  );
  const matchedDocumentIds = getDetails.documentReceipients
    .filter((doc) => doc.contactId === parseInt(ref))
    .map((doc) => doc.documentId);

  const matchedObject = getDetails.documentReceipients.filter(
    (doc) => doc.contactId === parseInt(ref)
  );

  const handleAgreementDownload = () => {
    const formData = {
      PropertyUId: getDetails.propertyUId,
    };
    DownloadFiletWithSpinner(
      showSpinner,
      hideSpinner,
      generateTenantAgreementPdfReport,
      "post",
      formData,
      null,
      false
    )
      .then((returnData) => {
        if (returnData != null) {
          window.open(returnData, "_blank");
        } else {
          ToastRef.current.notify(["error", "Internal Server Error"]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handlePrevious = () => {
    handleNextPage("RepairAndMaintainance");
  };

  return (
    <>
      <div className="dashboard-agency">
        <ToastAlert ref={ToastRef} />
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <div style={{ width: "100%" }}>
            {!verified? <div style={{width:'100%',display:'flex',justifyContent:'center'}}>     <Verify getDetails={getDetails} isVerified={isVerified} verificationType={1}/></div> : null}
              <form onSubmit={handleSubmit} className="details-form">
                {!IsSigned ? (
                  <div className="property-details-content df-all all-input">
                   {verified ? <div>
                      <Collapse
                        id={"Agreement-Period"}
                        title={"Repairs and Maintenance"}
                        data={
                          <div style={{ padding: "0px 30px" }}>
                       
                            <div className="s1">
                              {textWithBullet}The Agent will be indemnified from
                              any claims or actions, suits, legal costs or
                              demands taken against the Principal(s) named in
                              this agreement and the Agent will not be liable
                              for any claims made against the Principal(s) due
                              to any actions, omissions or statements made or
                              not made by the Principal(s).
                            </div>
                            <div className="s1 mt-3">
                              {textWithBullet}The Principal acknowledges that
                              the Agent is not a Financial and Investment
                              Advisor and any financial advice would be general
                              advice and the Principal should seek their own
                              financial advice.
                            </div>
                            <div className="s1 mt-3">
                              {textWithBullet}The Principal acknowledges that
                              the Property Stock and Business Agents Act
                              requires the Agent to disclose all Material Facts
                              relating to the property throughout the
                              transaction and warrants that all materials facts
                              have been disclosed to the best of their
                              knowledge.
                            </div>
                            <div className="s1 mt-3">
                              {textWithBullet}The Principal acknowledges that at
                              all times the Principal has sole control of the
                              property and that the Agent acts under the
                              instructions of the Principal during the sale of
                              the property. The Principal is responsible for the
                              Work Health and Safety requirements of the
                              property.
                            </div>
                            <div className="s1 mt-3">
                              {textWithBullet}The Principal acknowledges that by
                              signing this agreement the Principal is denied the
                              right to lease the Property without paying a fee
                            </div>
                          </div>
                        }
                      />
                      {isRefMatched || utype === "3"  ? (
                        <Collapse
                          id={"Agreement-Authority"}
                          title={"Signature of Tenant(s)"}
                          data={
                            <div>
                              <div
                                className="mt-2"
                                style={{ padding: "0px 30px", display: "flex" }}
                              >
                                <div
                                  className="card"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{ padding: "10px 10px 0px 20px" }}
                                      className="s1"
                                      htmlFor="name"
                                    >
                                      Signature{" "}
                                    </div>
                                    <TextBox
                                    colVal="sm-6"
                                      type="date"
                                      name="SignedDate"
                                      placeholder="dd/mm/yyyy"
                                      value={date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors.SignedDate}
                                      touched={touched.SignedDate}
                                    />
                                  </div>

                                  <div>
                                    <TabView
                                      activeIndex={activeIndex}
                                      onTabChange={(e) =>
                                        setActiveIndex(e.index)
                                      }
                                    >

                                      <TabPanel header="Draw">
                                        <div className="tc-sign ">
                                          <div className="sign-tc">
                                            <SignatureCanvas
                                              penColor="black"
                                              ref={signatureRef}
                                              canvasProps={{
                                                width: "300",
                                                height: 75,
                                                className: "signature-canvas",
                                              }}
                                            />
                                            {/* <div className="sign-option">
                                              
                                              <div
                                                onClick={getSignatureImage}
                                                className="tc-b2"
                                              >
                                                Confirm
                                              </div>
                                            </div> */}
                                          </div>
                                          <div
                                                onClick={clearSignature}
                                                className="tc-b3"
                                              >
                                                Clear
                                              </div>
                                        </div>
                                      </TabPanel>
                                    </TabView>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      ) : null}

                      {isRefMatched || utype === "3" ? (
                        <Collapse
                          id={"Agreement-Period"}
                          title={"Privacy Statement"}
                          data={
                            <div>
                              <div style={{ padding: "0px 30px" }}>
                                <div
                                  className="s1"
                                  style={{ fontWeight: "600" }}
                                >
                                  All information collected by the Agent is done
                                  so with respect of the Privacy Act 1998 and
                                  the Privacy Principals.
                                </div>
                                <div className="s1 mt-3">
                                  {textWithBullet}Information collected is
                                  necessary for one or more of the activities
                                  associated with this agreement.
                                </div>
                                <div className="s1 mt-3">
                                  {textWithBullet}The Agent will not use or
                                  disclose your personal information for another
                                  purpose, unless it would be reasonable to
                                  expect such disclosure is necessary for the
                                  completion of activities associated with this
                                  agreement.
                                </div>
                                <div className="s1 mt-3">
                                  {textWithBullet}The Agent will take all
                                  reasonable steps to ensure that the personal
                                  information collected, used or disclosed is
                                  accurate, complete and up-to-date.
                                </div>
                                <div className="s1 mt-3">
                                  {textWithBullet}The Agent will take reasonable
                                  steps to protect the personal information it
                                  holds from misuse and loss and from
                                  unauthorised access, modification or
                                  disclosure and also take reasonable steps to
                                  destroy or permanently de-identify personal
                                  information it no longer needs.
                                </div>
                              </div>
                            </div>
                          }
                        />
                      ) : null}
                    </div> : null }
                  </div>
                ) : null}
                {IsSigned ? (
                  <div className="signTxt df-all">
                    <div>Thank you</div>
                    <div>your document has been signed</div>
                  </div>
                ) : null}
                <div className="property-details-buttons">
                  {!IsSigned && verified ? (
                    <>
                      {/* <button
                        className="pdb-save-changes"
                        type="reset"
                        onClick={handleClear}
                      >
                        Cancel
                      </button>
                      <button
                        className="pdb-cancel"
                        type="reset"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button> */}
                      <button
                    className="pdb-save-changes"
                    type="button"
                    onClick={getSignatureImage}
                  >
                    {"Submit"}
                  </button>
                    </>
                  ) : null}

                {IsSigned ? <button
                    className="pdb-cancel"
                    type="button"
                    onClick={handleAgreementDownload}
                  >
                    { "Download Agreement"}
                  </button> : null }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignaturePage;
