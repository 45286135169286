import React, { useState } from "react";
import { useSpinnerContext } from "../../../../../Utilities/SpinnerContext";
import { useEffect } from "react";
import { useFormik } from "formik";
import TextBox from "../../../../input-components/textbox";
import {
  ABNLookUpGuid,
  ABNLookUpbaseURL,
  ContactCompanyUrl,
} from "../../../../../components/CommonSection/apiresources";
import { isNullOrEmpty } from "../../../../../components/CommonSection/Utility/ComponentFunction";
import { makeRequestWithSpinner } from "../../../../../Utilities/client";
import { ActionStatus } from "../../../../../Utilities/enum";
import ShowValue from "../../../../input-components/ShowValue";
import { FaEdit } from "react-icons/fa";
import { Space } from "antd";

const CompanyDetails = ({
  onDataRecive,
  handleparentsuccess,
  handleCurrentPage,
  getDetails,
}) => {
  const { showSpinner, hideSpinner } = useSpinnerContext();

  const initialValues = {
    companyName: "",
    abn: "",
    acn: "",
  };

  const [edit, setEdit] = useState(true);


  const handleABNLoopup = (event) => {
    const abnNumber = event.target.value;

    handleBlur(event);
    if (!isNullOrEmpty(abnNumber)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`,
        "get"
      ).then((returnData) => {
        debugger;
        const jsonString = returnData.match(/\{.*\}/)[0];
        const parsedData = JSON.parse(
          jsonString.slice(jsonString.indexOf("{"))
        );
        if (!isNullOrEmpty(parsedData.Abn)) {
          setFieldError("abn", "");
          setFieldValue("companyName", parsedData.EntityName);
          setFieldValue("acn", parsedData.Acn);
        } else {
          setFieldError("abn", "Invalid ABN number");
          setFieldValue("companyName", "");
          setFieldValue("acn", "");
          handleparentsuccess(["error", "Invalid ABN "]);
        }
      });
    }
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      submitContactDetails();
    },
  });
  useEffect(() => {
    if (getDetails) {
      setValues({ ...values, ...getDetails });
      setEdit(false)
    }
  }, [getDetails]);

  const submitContactDetails = () => {
    debugger;
    const formData = {
      ContactUniqueId: getDetails.contactUniqueId,
      CompanyName: values.companyName,
      ABN: values.abn,
      ACN: values.acn,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      ContactCompanyUrl,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          handleparentsuccess(["success", returnData.message]);
          handleCurrentPage("Preference");
        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
        console.error("Error:", error);
      });
  };

  const handleEdit = () => {
    setEdit(!edit)
  }


  return (
    <div>
      {
        getDetails && (
          <div
            className="handle-Editpart">
            <a onClick={() => handleEdit()}>
              <Space>
                <FaEdit style={{ fontSize: "24px" }} />
              </Space>
            </a>

          </div>
        )
      }



      {
        edit ? (


          <form action="none" onSubmit={handleSubmit} className="details-form">
            <div className="property-details-content df-all all-input">
              <div className="contacts-group pd-address">
                <TextBox
                  label="ABN"
                  placeholder="ABN Number"
                  name="abn"
                  value={values.abn}
                  onChange={handleChange}
                  onBlur={handleABNLoopup}
                  error={errors.abn}
                  touched={touched.abn}
                />
                <TextBox
                  label="ACN"
                  placeholder="ACN "
                  name="acn"
                  value={values.acn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.acn}
                  touched={touched.acn}
                  readOnly={true}
                />
                <TextBox
                  label="Company Name"
                  placeholder="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.companyName}
                  touched={touched.companyName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="property-details-buttons">
              <button
                className="pdb-cancel"
                type="reset"
                onClick={() =>
                  handleToastMessage(["warn", "Changes are cancelled"])
                }
              >
                Cancel
              </button>
              <button className="pdb-save-changes" type="submit">
                Save
              </button>
            </div>
          </form>
        ) : (
          <dev className="details-form">
            <div className="contacts-group">

              <ShowValue Label='ABN' setData={values.abn} />
              <ShowValue Label='ACN' setData={values.acn} />
              <ShowValue Label='Company Name' setData={values.companyName} />



            </div>
          </dev>
        )}
    </div>
  );
};

export default CompanyDetails;
