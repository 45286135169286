import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import AddOrSelectProperty from "./addNewProperty"
import PartiesPrinciple from "./partiesPrinciples";
import LandlordDetails from "./landlordDetails";
import RentDetails from "./rent";
import ImpInfo from "./importantInformation";
import Signature from "./signature";
import Summary from "./summary";
import { assignedTo, getManagementAgreement, CreateManagementAgreement,getTenancyAgreement,CreateTenancyAgreement } from "../../../components/CommonSection/apiresources";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus, eTenancyAgreementStatus } from "../../../Utilities/enum";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import axios from "axios";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";

const AgreementTabs = ({ handleparentsuccess }) => {
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Properties");
  const [activeKey, setActiveKey] = useState("PropertyDetails");
  const [getDetails, setGetDetails] = useState(null);
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [dpdata, setDpdata] = useState([]);
  const [userId, setUserId] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const ToastRef = useRef();
  const navigate = useNavigate();
  const [agencyID, setAgencyId] = useState("");
  const [HandleNextPages, setHandleNextPages] = useState('')
  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleDataChild = (value) => {
    GetApi(value);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    var url = new URL(window.location.href);
    var propertyUId = url.searchParams.get("propertyUID");
    var activeTab = url.searchParams.get("activeTab");
    if (activeTab) {
      setActiveKey(activeTab);
    }
    if (propertyUId) {
      GetApi();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when activeKey changes
  }, [activeKey]);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");

        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
          //reportData(parsedData.agencyUniqueID);
          setAgencyId(parsedData.agencyID);

        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);


  const GetApi = (value) => {
    var url = new URL(window.location.href);
    var propertyUId = value ? value : url.searchParams.get("propertyUID");
    const baseUrl = getTenancyAgreement;
    const apiUrl = `${baseUrl}?propId=${propertyUId}`;
    makeRequestWithSpinner(showSpinner, hideSpinner, apiUrl, "get")
      .then((response) => {
        if (response.object !== null) {
          setGetDetails(response.object);
          if (response.object.agreementStatus === eTenancyAgreementStatus.PropertySelected) {
            if (response.object.contactlistModel.length === 0) {
              setActiveKey("")
              setHandleNextPages("")
            } else {
              setActiveKey("")
              setHandleNextPages("")
            }
          } else if (response.object.agreementStatus === eTenancyAgreementStatus.AgencyOwnerTenant) {
            // setActiveKey("ManagementAgreement")
            // setHandleNextPages("ManagementAgreement")

            setActiveKey("LandlordDetails")
            setHandleNextPages("LandlordDetails")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.TenantOwnerAgent) {
            // setActiveKey("propetyies")
            // setHandleNextPages("propetyies")

            setActiveKey("rentDetails")
            setHandleNextPages("rentDetails")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Rent) {
            // setActiveKey("AgreementPeriod")
            // setHandleNextPages("AgreementPeriod")
            setActiveKey("impInfo")
            setHandleNextPages("impInfo")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Sent) {
            // setActiveKey("FeesAndCharges")
            // setHandleNextPages("FeesAndCharges")
            setActiveKey("summary")
            setHandleNextPages("summary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Signed) {
            // setActiveKey("TribunalFees")
            // setHandleNextPages("TribunalFees")
            setActiveKey("summary")
            setHandleNextPages("summary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.PromotionalActivity) {
            // setActiveKey("PromotionalActivities")
            // setHandleNextPages("PromotionalActivities")
            setActiveKey("RepairAndMaintainance")
            setHandleNextPages("RepairAndMaintainance")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Repairs) {
            // setActiveKey("RepairAndMaintainance")
            // setHandleNextPages("RepairAndMaintainance")
            setActiveKey("AgreementSignature")
            setHandleNextPages("AgreementSignature")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Signed) {
            // setActiveKey("AgreementSummary")
            // setHandleNextPages("AgreementSummary")
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (response.object.agreementStatus === eTenancyAgreementStatus.Completed) {
            setActiveKey("AgreementSummary")
            setHandleNextPages("AgreementSummary")
          }
          else if (getDetails.agreementStatus === eTenancyAgreementStatus.PropertySelected) {
            setActiveKey("")
            setHandleNextPages("")
          }
        } else if (response.object === null) {
          createManagementAgreement(propertyUId,agencyID)
        }
      })
      .catch((error) => {
        //handleparentsuccess(["error", error]);
      });
  };
  const createManagementAgreement = (data,agencyID) => {
    var urls = new URL(window.location.href);
    var Id = agencyID !== ""? agencyID : urls.searchParams.get("agencyId");
    let formData = {
      PropertyUId: data,
      AgencyId: Id,
    };
    const url = CreateTenancyAgreement;
    makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          setGetDetails(returnData.object);

        } else {
          ToastRef.current.notify(["error", returnData.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error.message]);
      });
  };

  const handleToastMessage = (toastdata) => {
    ToastRef.current.notify(toastdata);
  };

//   const reportData = (agencyUID) => {
//     const apiUrl = assignedTo;
//     const baseUrl = apiUrl + "?agencyUID=" + agencyUID;
//     axios.get(baseUrl).then((response) => {
//       if (response.data.object) setDpdata(response.data.object);
//     });
//   };

  const handleNextPage = (value) => {
    setHandleNextPages(value)
  }
  return (
    <>
      <ToastAlert ref={ToastRef} />
      <div className="dashboard-agency">
        <Header />
        <div
          className="blank_space"
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "10px",
          }}
        >
          <BiDotsVerticalRounded onClick={() => setVisiblet(!visiblet)} />
        </div>
        <div className="continer-dbag">
          <div className="dashboardagency-content">
            <Sidebar
              visible={sidebarVisible}
              handleTopicValue={handleTopicValue}
              topicValue={activeTopic}
            />
            <div
              className="pa-content-top-level"
              onClick={() => setVisiblet(true)}
            >
              <div className="text7" style={{ fontSize: "24px", display: 'flex', justifyContent: 'center', textDecoration: 'underline' }}>Residential Tenancy Agreement</div>
              {HandleNextPages === '' ? <AddOrSelectProperty
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
{/*               
              {HandleNextPages === '' ? <PartiesPrinciple
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null} */}
              {HandleNextPages === 'LandlordDetails' ? <LandlordDetails
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
              {HandleNextPages === 'rentDetails' ? <RentDetails
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'impInfo' ? <ImpInfo
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'sign' ? <Signature
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
               {HandleNextPages === 'summary' ? <Summary
                onDataRecive={handleDataChild}
                handleparentsuccess={handleToastMessage}
                handleCurrentPage={handleTabChange}
                getDetails={getDetails}
                reportTo={dpdata}
                handleNextPage={handleNextPage}
              /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementTabs;
