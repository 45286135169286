import { useCallback, useState, useEffect, useRef } from "react";
import "./addowner.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";

import { apiUrlamo, apiGetPendingProfile } from "../../CommonSection/apiresources";
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import { useFormik } from "formik";
import { amoSchema } from "../../CommonSection/Schema/schema";


const Addonwer = () => {

  const navigate = useNavigate();
  const [userId, setUserID] = useState();
  const [id, setId] = useState();
  const [owners, setOwners] = useState([{ fname: '', lname: '', email: '', number: '' }]);
  
  const [visible, setVisible] = useState(false);
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    number: "",
  };

  const addNewOwner = () => {
    setOwners([...owners, { fname: '', lname: '', email: '', number: '' }]);
    setVisible(true);
  };
  const handleOwnerChange = (text, index, key) => {
    handleChange(text);
    //console.log(values);
    const newOwners = [...owners];
    //console.log(newOwners[0]);
    console.log(text);
    newOwners[index][key] = text.target.value;
    setOwners(newOwners);
    console.log(owners);
  };
  const removeOwner = (index) => {
    const newOwners = owners.filter((_, i) => i !== index);
    setOwners(newOwners);
    console.log(index);
  };
  const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: amoSchema,
    onSubmit: (values, action) => {
      console.log(values);
      setOwners(...owners, { fname, lname, email, number });
      console.log('Start');
      const apiUrl = apiUrlamo;

      // Request body with login information
      const VerifyData = {
        UserId: userId,
        Id: id,
        AddMoreOwners: true,
        OtherOwners: owners
      };
      setOwners([]);
      axios.post(apiUrl, VerifyData)
        .then((response) => {
          // Handle the response from the backend
          if (response.data.status == 1) {
            navigate('/Completion');
            console.log(response.data)
            alert(`data Successfully:  ${response.data.message}`);
          } else {
            alert(`Message: ${response.data.message}`);
            console.log(response.data)
          }
        })
        .catch((error) => {

          console.error('Error:', error);

        });

      action.resetForm();
    }
  });


  // ***********************************************************************************************************
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie('@UserData');
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserID(parsedData.userId);
        }
      } catch (error) {
        console.error('Error retrieving data from AsyncStorage:', error);
      }
    };
    retrieveData();
  }, []);
  const searchParams = new URLSearchParams(window.location.search);
  let valueq;
  if (searchParams.has('value')) { valueq = searchParams.get('value'); }
  const [data, setData] = useState([]);
  let progressData = [{
    label: "Add Property",
    url: "/addproperty",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Verification",
    url: "/verify",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Ownership",
    url: "/ownership",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Add More Owners",
    url: "/addowner",
    isSelected: true,
    isCompleted: false
  },]

  useEffect(() => {
    if (userId !== '') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getApi();
      }, 2000)
    }

  }, [userId]);

  const getApi = () => {
    const baseUrl1 = apiGetPendingProfile;

    // Request body with login information

    const UserId1 = JSON.parse(GetCookie('@UserData')).userId;
    console.log(UserId1);
    const apiUrl = `${baseUrl1}?UserId=${UserId1}`;
    console.log('API URL:', apiUrl);

    axios.get(apiUrl)
      .then(response => {

        if (response.data.status == 1) {
          setId(response.data.object.id);
          console.log(`Id get Api : ${id}`);
        } else {
          // alert(`Message: ${response.data.message}`);
          console.log(response.data)
        }
        // Handle the response data
        if (response.data.object == null) {
          progressData[0].isSelected = true;
          setData(progressData);
          navigate('/addproperty?vlue=10');
          console.log(response.data)
        } else {
          let x = response.data.object.status;
          if (x < 4) {
            for (let i = 0; i < x; i++) {
              progressData[i].isCompleted = true;
            }
            setData(progressData);
          }
          
          if(valueq!=2){
            if (response.data.object.status == 1) {
              console.log(response.data.object.status)
              navigate('/verify');
  
            } else if (response.data.object.currentStatus == 2) {
              console.log(response.data.object.status)
  
              navigate('/ownership');
  
            } else if (response.data.object.currentStatus == 3) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 4) {
  
              console.log(response.data.object.status)
              navigate('/addowner');
  
            } else if (response.data.object.currentStatus == 5) {
  
              console.log(response.data.object.status)
              navigate('/Completion');
  
            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }




  // ***********************************************************************************************************

  return (
    <div className="addmoreowner" >

      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '80px',
          padding: '10px',
        }}></div>

        <div className="progress-icon">
          {data && <ProgressbarLandlord message={data} />} </div>

        <div className="addowner">{`Add More Owners`}</div>
        <form className="addowner-input" onSubmit={handleSubmit}>
          {owners.map((owner, index) => (
            <div className="details-selection-amo" key={index}>
              {index > 0 && <div className="owner-number" style={{ marginTop: "20px" }}>Owner number {index}</div>}
              {index > 0 && <div className="more-owners"><div className="text-amo" onClick={() => removeOwner(index)}>Remove Owner</div></div>}
              <div className="input-field-amo">
                <input type="text" className='fname primary' placeholder='First Name' id='fname' value={owner.fname} name='fname' onChange={(text) => handleOwnerChange(text, index, 'fname')} onBlur={(text) => handleOwnerChange(text, index, 'fname')} />
                {errors.fname && touched.fname ? <p className="form-error">{errors.fname}</p> : null}

                <input type="text" className='lname primary' placeholder='Last Name' id='lname' value={owner.lname} name='lname' onChange={(text) => handleOwnerChange(text, index, 'lname')} onBlur={(text) => handleOwnerChange(text, index, 'lname')} />
                {errors.lname && touched.lname ? <p className="form-error">{errors.lname}</p> : null}

                <input type="email" className='email primary' placeholder='Email' id='email' name='email' value={owner.email} onChange={(text) => handleOwnerChange(text, index, 'email')} onBlur={(text) => handleOwnerChange(text, index, 'email')} />
                {errors.email && touched.email ? <p className="form-error">{errors.email}</p> : null}

                <input className='number primary' type="number" name="number" placeholder="Mobile Number" id="number" value={owner.number} onChange={(text) => handleOwnerChange(text, index, 'number')} onBlur={(text) => handleOwnerChange(text, index, 'number')} />
                {errors.number && touched.number ? <p className="form-error">{errors.number}</p> : null}

                {index == owners.length - 1 && <div className="more-owners"><div className="text-amo" onClick={addNewOwner}>Add more owners</div></div>}
              </div>
            </div>
          ))}
          <button className="button-amo" type="submit" >
            <div className="text-parent">
              <div className="text7">Continue</div>
            </div>
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Addonwer;
