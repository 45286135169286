export const ActionStatus = {
  Successfull: 1,
  Error: 2,
  LoggedOut: 3,
  Unauthorized: 4,
  Failed: 5,
};
export const eNewAgencyProfileStatus = {
  PrincipalDetailsAdded: 0,
  VerificationDocumentAdded: 1,
  LicenseDetailsAdded: 2,
  CompanyDetailsAdded: 3,
  InsuranceDetails: 4,
  PaymentDetails: 5,
  ApprovalStatus: 6,
  Resubmit: 7,
  Completed: 8,
};

export const VerificationDocumentType = {
  PrimaryDocument: 1,
  SecondaryDocument: 2,
};
export const eApprovalStatus = {
  None: 0,
  Approved: 1,
  Resubmit: 2,
  Reject: 3,
};
export const ePropertyPurpose = {
  Prospect: 1,

  Appraisal: 2,

  Agreement: 3,

  ContractofSale: 4,

  EnlistForSale: 5,

  Negotiation: 6,

  OfferAccepted: 7,

  Contract: 8,

  Sold: 9,
};

export const enquiryType = {
  property: 1,
  others: 2,
};

export const UserRoleId = {
  Admin: 1,
  Agency: 2,
  Agent: 3,
  AgencyStaff: 4,
  AgencyBranch: 5,
  AgencyPrincipal: 6,
  Affiliate: 7,
  Contact: 10,
  Landlord: 11,
  Trade: 12,
  Tenant: 13,
};
export const eContactCategory = {
  1: "Owner",
  2: "Tenant",
  3: "Buyer",
  4: "Seller",
  5: "Investor",
  6: "Landlord",
};
export const eContactActivities = {
  0:"Select",
  1: "Added as Owner",
  2: "Added as Tenant",
  3: "Enquiry",
  4: "Open Home",
  5: "Offered",
  6: "Bought",
};
export const ePropertyPhototype = {
  Property: 1,
  Floor: 2,
};

export const ManagementAgreementStatus = {
    PropertySelected : 1,
    PropertyDetails : 2,
    AgencyDetails : 3,
    AgreementPeriod : 4,
    FeesCharges : 5,
    TribunalInsurance : 6,
    PromotionalActivity : 7,
    Repairs : 8,
    Signed : 9,
    Completed : 10,
}


export const signatureStatus = {
        Image:1,
        Type:2,
        Draw:3,
    }

export const eTenancyAgreementStatus =
{
    PropertySelected : 1,
    AgencyOwnerTenant : 2,
    TenantOwnerAgent : 3,
    Rent : 4,
    Information : 5,
    Sent : 6,
    Signed : 7,
    Completed : 8,

}

export const  eTenantAgreementTerm =
{
    SixM: 1,
    TwelveM: 2,
    TwoY: 3,
    ThreeY : 4,
    FiveY :5,
    Other:6,
    Periodic:7
}


export const  eAgreementTemplateType =
{
  ExclusiveAgencyAgreement: 1,
  OpenAgencyAgreement: 2,
  ConjunctionAgencyAgreement: 3,
  AuctionAgencyAgreement : 4,
  Section51 :5,
  SalesAdvice:6,
  RentAgreement:7,
  ManagementAgreement: 8,
  TenantLeaseAgreement : 9,
  TenantCheck : 10,
  OwnerCheck : 11,
}


export const isManualFilePath = "https://forealproperties.s3.ap-southeast-2.amazonaws.com/";