import { useCallback, useState, useEffect, useRef } from "react";
import "./onwer.css";
import Header from "../../CommonSection/Header/Header";
import Footer from "../../CommonSection/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { GetCookie } from "../../CommonSection/Cookie/Session";
import Select from 'react-select';
import { ReactDOM } from "react";
import { Buffer } from 'buffer';
import { apiUrlowner, BucketName, Region, AccessKey, Keyid, apiGetPendingProfile } from "../../CommonSection/apiresources";
import AWS from "aws-sdk";

import ProgressBar from 'react-bootstrap/ProgressBar';
import ProgressbarLandlord from "../../CommonSection/ProgressLandlord/ProgressLandlord";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';

// @ts-ignore
window.Buffer = Buffer;// Creating a buffer with 10 bytes


const Ownership = () => {
  const [selectedFile, setSelectedFile] = useState();
 

  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [userId, setUserID] = useState("");
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [id, setId] = useState("");
  const inputRef = useRef();
  const [size, setSize] = useState();
  const [progress, setProgress] = useState();
  const [Uploading, setUploading] = useState();
  const searchParams = new URLSearchParams(window.location.search);
  let values;
  if (searchParams.has('value')) { values = searchParams.get('value'); }
  const [data, setData] = useState([]);
  let progressData = [{
    label: "Add Property",
    url: "/addproperty",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Verification",
    url: "/verify",
    isSelected: false,
    isCompleted: false
  },
  {
    label: "Ownership",
    url: "/ownership",
    isSelected: true,
    isCompleted: false
  },
  {
    label: "Add More Owners",
    url: "/addowner",
    isSelected: false,
    isCompleted: false
  },]


  const options1 = [
    { value: 1, label: 'Council Rates' },
    { value: 2, label: 'Water Rates' }
  ]

  // const handlePressSelect = (action) => {
  //   // Handle the selected place here
  //   form.current.reset();

  // };

  // const handleDrag = (event) => {
  //   console.log("fine");
  //   event.preventDefault(); // Prevent default behavior
  //   //alert("dragger");
  //   event.dataTransfer.dropEffect = 'copy';

  // }

  // const handleDrop = (event) => {
  //   console.log("fine");
  //   event.preventDeafault();
  //   const file = event.target.files;
  //   // setFiles(file);
  //   console.log(file);
  // }

  const select = (event) => {
    inputRef.current.click();
  }

 
  // ***********************************************************************************************************
    useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie('@UserData');
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserID(parsedData.userId);
        }
      } catch (error) {
        console.error('Error retrieving data from AsyncStorage:', error);
      }
    };
    retrieveData();
  }, []);


  
  useEffect(() => {
    if (userId !== '') {
      // console.log(`UserId number: ${userId}`);
      const timeout = setTimeout(() => {
        getApi();
      }, 2000)
    }

  }, [userId]);

  const getApi = () => {
    const baseUrl1 = apiGetPendingProfile;

    // Request body with login information

    const UserId1 = JSON.parse(GetCookie('@UserData')).userId;
    console.log(UserId1);
    const apiUrl = `${baseUrl1}?UserId=${UserId1}`;
    console.log('API URL:', apiUrl);

    axios.get(apiUrl)
      .then(response => {
        if (response.data.status == 1) {
          setId(response.data.object.id);
          console.log(`Id get Api : ${id}`);
        } else {
          // alert(`Message: ${response.data.message}`);
          console.log(response.data)
        }
        // Handle the response data
        if (response.data.object == null) {
          progressData[0].isSelected = true;
          setData(progressData);
          navigate('/addproperty');
          console.log(response.data)
        } else {
          let x = response.data.object.currentStatus;
          if (x < 4) {
            for (let i = 0; i < x; i++) {
              progressData[i].isCompleted = true;
            }
            setData(progressData);
          }
          if (values != 2) {
            if (response.data.object.currentStatus == 1) {

              console.log(response.data.object.status)
              navigate('/verify');

            } else if (response.data.object.currentStatus == 2) {
              console.log(response.data.object.status)

              navigate('/ownership');

            } else if (response.data.object.currentStatus == 3) {

              console.log(response.data.object.status)
              navigate('/addowner');

            } else if (response.data.object.currentStatus == 4) {

              console.log(response.data.object.status)
              navigate('/addowner');

            } else if (response.data.object.currentStatus == 5) {

              console.log(response.data.object.status)
              navigate('/Completion');

            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

    }

  const handleVerification = () => {

    // Request body with login information

    const VerifyData = {

      UserId: userId,
      Id: id,
      ProofOfOwnerShip: value.value,
      ProofOfOwnershipFilepath: image,
    };

    axios.post(apiUrlowner, VerifyData)
      .then((response) => {
        // Handle the response from the backend
        if (response.data.status == 1) {
          alert("Success Achieved");
          navigate('/addowner');
        } else {
          console.log(response.data)
        }

      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const deleteFile = (index) => {
    setFiles((prev) =>
      prev.filter((_, i) => i !== index)
    );
    setFiles([]);

  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const file = e.target.files;
    let index = 0;
    // if (file[index].type.split('/')[0] !== 'image') continue;
    // if (!files.some((e) => (e.name === file[index].name)))
    
    handleUpload();
  };
  const handleUpload = () => {

    if (!selectedFile) {

      alert("Please select a file to upload.");
      return;

    }
    AWS.config.update({

      accessKeyId: AccessKey,
      secretAccessKey: Keyid,
      region: Region,

    });
    const s3 = new AWS.S3();
    console.log(selectedFile);
    const fileExtension =selectedFile.name.split(".").pop();
      let new_name=  "verificationproofs/ownerproof" + Date.now() + "." + fileExtension
      setFiles((p) => [
        ...p,
        {
          name: new_name,
          size: selectedFile.size,
          url: URL.createObjectURL(selectedFile),
          type: selectedFile.type,
        },
      ])

    const params = {

      Bucket: "forealproperties",

      Key: new_name,

      Body: selectedFile,

    };

    
    s3.upload(params, (err, data) => {

      if (err) {

        console.error("Error uploading file:", err);

        alert("Error uploading file. Please try again.");

      } else {

        console.log("File uploaded successfully:", data);

        alert(`File uploaded successfully. Location: ${data.Location}`);
        setProgress(100);
      }

    });

  };

  // ***********************************************************************************************************

  return (
    <div className="ownership" >

      <Header />
      <div className="col-sm-12 col-md-10 col-lg-6 container">
        <div className="blank_space" style={{
          backgroundColor: 'white',
          height: '80px',
          padding: '10px',
        }}></div>

        <div className="progress-icon">
          {data && <ProgressbarLandlord message={data} />}
        </div>

        <div className="owner">{`Ownership`}</div>
        <form className="owner-input"  >
          <div className="docs-selection">
            <label htmlFor="s1">Choose one option</label>
            <Select options={options1} placeholder="Select any Document" defaultValue={value} id="s1" onChange={setValue} />

          </div>

          <div className="details-section" >
            Upload your Documents
          </div>

          {<div className="upload-section"  >
            <img src=".\assets\images\upload.png" alt="upload-icon" className="upload-icon" />
            <div className="upload-setion-input">

              <span className="upload-select" onClick={select}>
                Click to upload
              </span>
              <input ref={inputRef}
                type="file"
                onChange={handleFileChange}
                className="upload-input"
                name="upload"
              />
              or drag or drop
            </div>
            <div className="upload-details">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </div>
          </div>
          }

        </form>
        {files.map((files, index) => (
          <div className="icon-upload-details" key={index}>
            <img src={files.url} alt={files.nm} width={50} height={50} />
            <div className="uploaded-files">

              <div className="name-delete" key={index}>
                <div className="file-name">{files.name}</div>

                <span className="delete" onClick={() => deleteFile(index)}><img src=".\assets\images\trash.png" alt="delete" /></span>

              </div>

              <div className="size-details">{files.size}KB</div>
              <div className="progress" style={{ width: "100%", marginTop: "20px", gap: "5px", color: "#0E7090" }}><ProgressBar now={progress} variant="info" style={{ width: "100%", height: "20px", color: "#0E7090" }} /> {progress}%</div>
            </div>
          </div>)
        )}



        {value && image &&
          <button className="button-own" onClick={handleVerification}>
            <div className="text-parent">
              <div className="text7">Verify</div>
            </div>
          </button>
        }
      </div>
      <Footer />
    </div>
  );
};

export default Ownership;
