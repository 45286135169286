import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate , Link} from "react-router-dom";
import { TaskDetails,TaskCompleted,TaskApproved,addupdatetaskNote } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner ,uploadToS3WithSpinner} from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { dynamicUrl } from "../../../Utilities/profileManagement";
import Header from "../../../components/CommonSection/Header/Header";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar"
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import DOMPurify from "dompurify";
import BasicDetails from "../../../src/input-components/basicDetails";
import { format } from 'date-fns';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import CustomModal from "../../../src/input-components/Modal";
import TextArea from "../../../src/input-components/textarea";
import { MdOutlineNoteAdd } from "react-icons/md";
import FileUpload from "../../input-components/fileUplode";
import { BiEnvelope } from "react-icons/bi";
import { Timeline } from 'primereact/timeline';
import dayjs from 'dayjs';
import { isManualFilePath } from "../../../Utilities/enum";
import { Divider } from 'primereact/divider';

const initialValues = {
    taskName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    ContactNo: "",
    existingUser: "",
    relatedToProperty:"",
    isRecuring:"",
    taskPriorityIndex:"",
    taskDesc:"",
    recurringType:"",
    DataTime:""
};

const ListTaskDetails = ({}) => {
    let selectedFile;
    const [userId, setUserId] = useState("");
    const [userUId, setUserUId] = useState("");
    const [agencyID, setAgencyId] = useState("");
    const [agentId, setAgentId] = useState("");
    const [agencyUID, setAgencyUID] = useState("");
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [activeTopic, setActiveTopic] = useState("ListTask");
    const [topicValue, setTopicValue] = useState("Profile");
    const [basicDetails, setBasicDetails] = useState([]);
    const [mainData , setMaindata] = useState({})
    const [openModal , setOpenModal] = useState(false)
    const [note, setNote] = useState("");
    const [taskId , setTaskId] = useState("")
    const [ completeNote, setCompleteNote] = useState("")
    const [taskDocuments, setTaskDocuments] = useState("");
    const [openCompleteTask,setOpenCompleteTask]= useState(false)
    const [taskNoteModelList,setTaskNoteModelList] = useState([]);
    const ToastRef = useRef();


    useEffect(() => {
        const retrieveData = async () => {
            try {
                const userData = GetCookie("@UserData");
                if (userData) {
                    const parsedData = JSON.parse(userData);
                    setUserUId(parsedData.userUnique_ID);
                    setUserId(parsedData.userId);
                    setAgencyId(parsedData.agencyID);
                    setAgencyUID(parsedData.agencyUniqueID);
                    setAgentId(parsedData.agentId)
                    gettaskDetails(parsedData.agentId)
                } else {
                    navigate("/signin");
                }
            } catch (error) {
                console.error("Error retrieving data from AsyncStorage:", error);
            }
        };
        retrieveData();
    }, [userId]);


    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            //AddNewUser();
        },
    });

    const gettaskDetails = (agentId) => {
        var urls = new URL(window.location.href);
        var TaskDetailsId = urls.searchParams.get("TaskDetails");
        let formData = {
            taskUniqueID: TaskDetailsId,
            AgentId: agentId,
        };
        const url = TaskDetails;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    let data = returnData.object;
                    if (data.taskNoteModelList.length === 1) {
                        data.taskNoteModelList.push({
                            taskNoteId: null,
                            taskNoteUniqueId: null,
                            notes: '',
                            taskId: null,
                            addedOn: '',
                            heading: null
                        });
                    }
                    let taskDetails = {
                        Assignee: data.asignee,
                        Author: data.author,
                        TaskName: data.name,
                        TaskDueDate: format(new Date(data.dueDate), 'dd/MM/yyyy'),
                        ApprovedOn: data.approvedOn ? format(new Date(data.approvedOn), 'dd/MM/yyyy') : 'N/A',
                        CompletedOn: data.isCompleted ? data.completedOn ? format(new Date(data.completedOn), 'dd/MM/yyyy') : 'N/A' : '',
                        TaskPriorityIndex: data.priorityLevel === 1 ? 'High' : data.priorityLevel === 2 ? "Medium" :  data.priorityLevel === 3 ? "Low" : '',
                        Property: data.isRelatedToProperty ? (
                            <Link to={`/agency/properties?propertyUID=${data.propertyUID}`} target="_blank">
                              {data.sProperty}
                            </Link>
                          ) : '',    
                        Document: data.documentPath ? (
                            <Link onClick={() => handleDownload(data.documentPath)}>
                            {data.documentPath}
                          </Link>
                        ) : '',        
                        };
                    setMaindata(returnData.object)
                    setBasicDetails(taskDetails);
                    setTaskId(data.taskId)
                    setTaskNoteModelList(data.taskNoteModelList)
                } else {
                    ToastRef.current.notify(["warn", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["warn", '']);
            });
    };

    const handleDownload = (documentPath) => {
        console.log(documentPath,'mainData.documentPath')
        const fileUrl = `${isManualFilePath}${documentPath}`;
        window.open(fileUrl, "_blank");
      };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };

      const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
      };

      const sanitizedHTML = (html) => {
        return { __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }) };
    }

      const acceptCompleted = () => {
        var urls = new URL(window.location.href);
        var TaskDetailsId = urls.searchParams.get("TaskDetails");
        let formData = {
            TaskID: TaskDetailsId,
            IsCompletedByAgency: false,
            Note : completeNote,
            DocumentPath : taskDocuments
        };
        if(taskDocuments === ""){
            ToastRef.current.notify(["warn", "Note is required"]);
        }else{
        const url = TaskCompleted;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenCompleteTask(false)
                    gettaskDetails()
                } else {
                    ToastRef.current.notify(["warn", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["warn", returnData.message]);
            });
        }
    }

    const acceptApproved = () => {
        var urls = new URL(window.location.href);
        var TaskDetailsId = urls.searchParams.get("TaskDetails");
        makeRequestWithSpinner(
            showSpinner,
            hideSpinner,
            `${TaskApproved}?TaskID=${TaskDetailsId}`,
            "get"
          )
            .then((returnData) => {
              if (returnData.status == ActionStatus.Successfull) {
                ToastRef.current.notify(["success", returnData.message]);
              }
            })
            .catch((error) => {
              ToastRef.current.notify(["error", "Internal Error"]);
            });
    }

    const reject = () => {
        ToastRef.current.notify(["warn","You have rejected"]);

    }

    const confirm1 = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: !mainData.isApproved ? acceptApproved : null,
            reject
        });
    };

    const handleClear = () => {
        dynamicUrl(navigate, "ListTask");
    }

    const handleNote = () => {
        setOpenModal(true)
        setNote("")
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const closeCompleteModal = () => {
        setOpenCompleteTask(false)
    }

    const handleInput = (e) => {
        if(e.target.name === "Note"){
            setNote(e.target.value)
        }else if (e.target.name === "TaskNote") {
            setCompleteNote(e.target.value)
        }
    }

    const addTaskNote = () => {
        let formData = {
            TaskId: parseInt(taskId),
            Notes: note,
        };
        const url = addupdatetaskNote;
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    ToastRef.current.notify(["success", returnData.message]);
                    setOpenModal(false)
                    gettaskDetails();
                } else {
                    ToastRef.current.notify(["warn", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["warn", '']);
            });
    };
    
    const handleFileChange = async (e, indexforfiles) => {
        for (let i = 0; i < e.target.files.length; i++) {
          selectedFile = e.target.files[i];
          await handleUpload(indexforfiles);
        }
      };
    
      const handleUpload = async (indexforfiles) => {
        await uploadToS3WithSpinner(
          showSpinner,
          hideSpinner,
          "TaskDocuments/",
          "TaskDocuments",
          selectedFile
        ).then((returnData) => {
          if (returnData.status == ActionStatus.Successfull) {
            ToastRef.current.notify(["success", "File uploaded successfully"]);
            setTaskDocuments(returnData.Key);
          } else {
            ToastRef.current.notify(["error", "Error uploading file. Please try again."]);
    
          }
        });
      };

      const handleOpenCompletedTask = () => {
        setOpenCompleteTask(true)
        setCompleteNote("")
      }

      const events = taskNoteModelList.map(item => ({
        date: item.addedOn ? dayjs(item.addedOn).format('MMMM D, YYYY h:mm A') : '',
        content: item.notes
      }));

    return (
        <div className="dashboard-agency">
            <Header />
            <ToastAlert ref={ToastRef} />
            <ConfirmDialog />
            <div
                className="blank_space"
                style={{ backgroundColor: "white", height: "80px", padding: "10px" }}
            ></div>
            <div className="dashboardagency-content">
                <Sidebar
                    visible={sidebarVisible}
                    handleTopicValue={handleTopicValue}
                    topicValue={activeTopic}
                />
                <form onSubmit={handleSubmit} className="details-form" style={{ display: 'initial', width: '100%' }}>
                    <div className="property-details-content df-all all-input">
                        <div className="contacts-group">
                            <div className="text-parent" style={{display:'flex', justifyContent:'space-between'}}>
                                <div className="text7" style={{ fontSize: "24px" }}>
                                    Task Details
                                </div>
                            </div>
                            <div className="mt-2 detailsImg-Container">
                                <BasicDetails details={basicDetails} noOfColumnInRow={4} />
                                <div className="mb-3 col-md-4 col-lg-10 col-sm-6 col-xs-12" style={{paddingLeft:'50px'}}>
                                    <div className="labelbasicfont" style={{fontWeight:'500'}}>Description</div>
                                    <div className="" dangerouslySetInnerHTML={sanitizedHTML(mainData.description || '')}></div>
                                </div>
                                <div className="pl-5 mt-5    col-md-4 col-lg-10 col-sm-6 col-xs-12">
                                <Divider/>
                                    <div className="noteLabel  w-100 mb-4" style={{width:'100%' , display:'flex', justifyContent:'space-between'}}>
                                       {taskNoteModelList.length > 0 ? <span>Task Notes</span> : null }
                                        <span  style={{display:'flex' , alignItems:'center', cursor:'pointer'}} onClick={handleNote}><MdOutlineNoteAdd className="mr-1" style={{fontSize:'20px'}} /> Add Notes</span>
                                    </div>
                                    {taskNoteModelList.length > 0 ? 
                                    <Timeline
                                        value={events}
                                        opposite={(item) => <small>{item.date}</small>}
                                        content={(item) => <p>{item.content}</p>}
                                    /> : null }
                                </div>
                                <CustomModal
                                    title="Add Note"
                                    width={500}
                                    closable={closeModal}
                                    visible={openModal}
                                    onCancel={closeModal}
                                    modalData={
                                        <div
                                            style={{  marginLeft: "10px" }}
                                        >
                                            <TextArea colVal={12} value={note} name="Note" label={"Note"} onChange={handleInput}/>
                                        </div>
                                    }
                                    footer={[
                                        <div
                                            key="footer"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "20px" }}
                                                className="pdb-save-changes mt-2"
                                                onClick={addTaskNote}
                                            >
                                                Submit
                                            </button>
                                        </div>,
                                    ]}
                                />
                                <CustomModal
                                    title="Complete"
                                    width={600}
                                    closable={closeCompleteModal}
                                    visible={openCompleteTask}
                                    onCancel={closeCompleteModal}
                                    modalData={
                                        <div
                                            style={{ marginLeft: "10px" }}
                                        >
                                            <TextArea isMandatory colVal={12} value={completeNote} name="TaskNote" label={"Note"} onChange={handleInput} />
                                            <FileUpload colVal={12} icon={<BiEnvelope height="100%" width="100%" />} onChange={(e) => handleFileChange(e)} />
                                        </div>
                                    }
                                    footer={[
                                        <div
                                            key="footer"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "20px" }}
                                                className="pdb-save-changes mt-2"
                                                onClick={acceptCompleted}
                                            >
                                                Submit
                                            </button>
                                        </div>,
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="property-details-buttons">
                        <button
                            className="pdb-cancel"
                            type="reset"
                            onClick={handleClear}
                        >
                            Cancel
                        </button>
                        {mainData.isCompleted === null || mainData.isCompleted === false ?
                        <button className="pdb-save-changes" type="button" onClick={ !mainData.isCompleted && mainData.isApproved ? handleOpenCompletedTask : !mainData.isApproved ? confirm1 : null} >
                           {!mainData.isCompleted && mainData.isApproved ? "Completed" : !mainData.isApproved ? "Approve" : ""}
                        </button> : null }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ListTaskDetails;
