import React from "react";
import { useEffect, useState } from "react";
import "./propertiesagency.css";
import { BiEnvelope, BiDownload } from "react-icons/bi";
import { useFormik } from "formik";
import { PropertyDocumentsUrl, FilePath } from "../../../CommonSection/apiresources";
import { GetCookie } from "../../../CommonSection/Cookie/Session";
import TextBox from "../../../../src/input-components/textbox";
import { makeRequestWithSpinner, uploadToS3WithSpinner, } from "../../../../Utilities/client";
import { useSpinnerContext } from "../../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../../Utilities/enum";
import Collapse from "../../../../src/input-components/collapse";
import SelectBox from "../../../../src/input-components/selectbox";
import FileUpload from "../../../../src/input-components/fileUplode";
import { Divider } from "antd";
import { isNullOrEmpty } from "../../../CommonSection/Utility/ComponentFunction";
import { useNavigate } from "react-router-dom";

const initialValues = {
  propertyId: "",
  propertyDocuments: "",
  docDesc: "",
  docPath: "",
  docTitle: "",
};

const PropertyDocumentsAgency = ({
  onDataRecive,
  handleparentsuccess,
  getDetails,
  handleCurrentPage
}) => {
  let selectedFile;
  const [userId, setUserId] = useState("");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [docArray, setDocArray] = useState([
    { value: "appraisal", label: "Appraisal" },
    { value: "agreement", label: "Agreement" },
    { value: "contractofsale", label: "Contract of Sale" },
    { value: "salesadvise", label: "Sales Advise" },
    { value: "other", label: "Other" },
  ]);
  const [selections, setSelections] = useState([
    { id: 1, label: "", value: "" },
  ]);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const navigate = useNavigate();
  var url = new URL(window.location.href);
  var propertyUID = url.searchParams.get("propertyUID");
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserId(parsedData.userId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = () => {
    const formData = {
      PropertyId: getDetails ? getDetails.propertyId : 0,
      PropertyDocuments: propertyDocuments,
    };
    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      PropertyDocumentsUrl,
      "post",
      formData
    )
      .then((returnData) => {
        if (returnData.status === ActionStatus.Successfull) {
          onDataRecive(propertyUID);
          handleClearFiles();

          handleparentsuccess(["success", returnData.message]);
          handleCurrentPage("Overview")

        } else {
          handleparentsuccess(["error", returnData.message]);
        }
      })
      .catch((error) => {
        handleparentsuccess(["error", error.message]);
      });
  };

  const handleOptionChange = (id, e) => {
    setSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id
          ? { ...selection, label: e.label, value: e.value }
          : selection
      )
    );
    setFieldValue("docTitle", e.label);
  };

  const addSelectionBox = () => {
    const newSelection = {
      id: selections.length + 1,
      label: "",
      value: "",
      selectedOption: "",
    };
    const commonValues = docArray
      .filter(
        (doc) =>
          doc.value !== "other" &&
          selections.some((selection) => doc.value === selection.value)
      )
      .map((commonDoc) => commonDoc.value);
    const updatedDocArray = docArray.filter(
      (doc) => doc.value === "other" || !commonValues.includes(doc.value)
    );
    setDocArray(updatedDocArray);
    setSelections((prevSelections) => [...prevSelections, newSelection]);
  };

  const removeSelectionBox = (id) => {
    setSelections((prevSelections) =>
      prevSelections.filter((selection) => selection.id !== id)
    );
  };

  const handleFileChange = async (e, indexforfiles) => {
    for (let i = 0; i < e.target.files.length; i++) {
      selectedFile = e.target.files[i];
      await handleUpload(indexforfiles);
    }
  };

  const handleUpload = async (indexforfiles) => {
    await uploadToS3WithSpinner(
      showSpinner,
      hideSpinner,
      "propertydocuments/",
      "propertydocuments",
      selectedFile
    ).then((returnData) => {
      if (returnData.status == ActionStatus.Successfull) {
        setFieldValue("docPath", returnData.Key);
        handleparentsuccess(["success", "File uploaded successfully"]);
        const newSection1 = {
          docId: "0",
          docTitle: values.docTitle,
          docDesc: values.docDesc,
          docPath: returnData.Key,
        };
        setPropertyDocuments((prevState) => [...prevState, newSection1]);
      } else {
        handleparentsuccess([
          "error",
          "Error uploading file. Please try again.",
        ]);
      }
    });
  };

  const renderFileIcon = (docPath) => {
    if (docPath.includes("pdf")) {
      return <img src="../../../assets/images/PDF.png" width={20} alt="PDF" />;
    } else if (docPath.includes("png") || docPath.includes("jpg")) {
      return (
        <img src="../../../assets/images/png.png" width={20} alt="Image" />
      );
    } else if (docPath.includes("doc")) {
      return (
        <img
          src="../../../assets/images/doc.png"
          width={20}
          alt="Word Document"
        />
      );
    } else if (docPath.includes("xlsx")) {
      return (
        <img
          src="../../../assets/images/xls.png"
          width={20}
          alt="Excel Document"
        />
      );
    } else if (docPath.includes("jpg")) {
      return (
        <img
          src="../../../assets/images/jpg.png"
          width={20}
          alt="Jpg Document"
        />
      );
    }
    return null;
  };

  const handleDownload = (path) => {
    if (path) {
      const downloadLink = `${FilePath}${path}`;
      window.open(downloadLink, "_blank");
    }
  };

  const handleClearFiles = () => {
    // Clear selected files by resetting the state
    const newItem = [{ id: 1, label: "", value: "" }];
    setSelections(newItem);
    setPropertyDocuments('');
  };


  const allSelectionsHaveValue = selections.every(selection => selection.value !== "");

  return (
    <div>
      <div
        className="mr-5 pb-0"
        style={{ display: "flex", justifyContent: "end" }}
      >
      </div>
      <form
        onSubmit={handleSubmit}
        className="details-form"
        style={{ paddingTop: "10px" }}
      >
        <div className="property-details-content df-all all-input">
          <Collapse
            id={"Uplode"}
            title={"Upload Documents"}
            data={
              <div>
                <div>
                  <div className="ml-3">
                    {getDetails &&
                      getDetails.propertyDocuments &&
                      getDetails.propertyDocuments.map((document) => (
                        <div className="col-lg-5 col-sm-12 ">
                          <div
                            key={document.docId}
                            className="existing-document"
                          >
                            {document.docPath &&
                              renderFileIcon(document.docPath)}
                            <span className="ml-1 document-title">
                              {!isNullOrEmpty(document.docDesc)
                                ? document.docDesc
                                : document.docTitle}
                            </span>
                            <span className="mr-2 document-date">
                              {document.createdOn &&
                                new Date(document.createdOn).toLocaleString(
                                  undefined,
                                  {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  }
                                )}
                            </span>
                            <span className="document-time">
                              {document.createdOn &&
                                new Date(document.createdOn).toLocaleString(
                                  undefined,
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}
                            </span>
                            <BiDownload
                              className="ml-2"
                              style={{ fontSize: "18px" }}
                              onClick={() => handleDownload(document.docPath)}
                            />
                          </div>
                          <Divider />
                        </div>
                      ))}
                  </div>
                  {selections.map((selection) => (
                    <div key={selection.id}>
                      <SelectBox
                        name="docTitle"
                        value={selection}
                        onChange={(e) => handleOptionChange(selection.id, e)}
                        options={docArray}
                        label={"Select Documents"}
                        error={errors.docTitle}
                        touched={touched.docTitle}
                      />
                      {selection.value == "other" ? (
                        <TextBox
                          label=""
                          placeholder=""
                          name="docDesc"
                          value={values.docDesc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.docDesc}
                          touched={touched.docDesc}
                        />
                      ) : null}
                      <FileUpload
                        icon={<BiEnvelope height="100%" width="100%" />}
                        onChange={(e) => handleFileChange(e)}
                        onBlur={() => { }}
                      />

                      {selection.id !== 1 && (
                        <button
                          className="ml-3 remove-document-btn"
                          onClick={() => removeSelectionBox(selection.id)}
                        >
                          Remove
                        </button>

                      )}

                    </div>
                  ))}

                  {
                    allSelectionsHaveValue && (
                      <button
                        type="button"
                        className="add-documents-btn mt-3 ml-3"
                        onClick={addSelectionBox}
                      >
                        Add Documents
                      </button>
                    )
                  }


                </div>
              </div>
            }
          />
        </div>
        <div className="property-details-buttons">
          <button
            className="pdb-cancel"
            type="reset"
            onClick={() =>
              handleparentsuccess(["warn", "Changes are cancelled"])
            }
          >
            Cancel
          </button>
          <button className="pdb-save-changes" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyDocumentsAgency;
