import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "./approveagency.css";
import {
  addAgencyApprovalStatus,
  getAgencyApprovalDetails,
  getPendingAgencyDetails,
} from "../../CommonSection/apiresources";
import Header from "../../CommonSection/Header/Header";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ToastAlert from "../../CommonSection/ToastAlert/ToastAlert";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import {
  ActionStatus,
  eApprovalStatus,
  eNewAgencyProfileStatus,
} from "../../../Utilities/enum";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { navigateToPageWithDelay } from "../../CommonSection/Utility/ComponentFunction";
import { Navigate, useNavigate } from "react-router-dom";

const ApproveAgency = () => {
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [ApprovalStatus, setApprovalStatus] = useState(0);
  const handleShow = async () => setShow(true);
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState();
  const ToastRef = useRef();
  let userUId;

  if (searchParams.has("userUId")) {
    userUId = searchParams.get("userUId");
  }

  useEffect(() => {
    if (data === "") {
      const timeout = setTimeout(() => {
        getnewAgencyDetailsApi();
      }, 2);
    }
  }, [data]);

  const getnewAgencyDetailsApi = async () => {
    await makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      getPendingAgencyDetails + "?UserUniqueId=" + userUId,
      "get",
      null,
      null,
      false
    )
      .then((returnData) => {
        if (returnData.status == ActionStatus.Successfull) {
          setStatus(returnData.object.status);
          setData(returnData.object);
        }
      })

      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };
  const onClickButtons = async (approvalStatus, text) => {
    setApprovalStatus(approvalStatus);
    if (approvalStatus === eApprovalStatus.Approved) {
      handleSubmit(approvalStatus, text);
    } else await handleShow();
    //    await handleSubmit(key, notes);
  };
  const handleSubmit = async (approvalStatus, text) => {
    handleClose();

    const agencydata = {
      AgencyUId: data.agencyUniqueID,
      ApprovalStatus: approvalStatus,
      ApprovalNotes: text,
    };

    makeRequestWithSpinner(
      showSpinner,
      hideSpinner,
      addAgencyApprovalStatus,
      "post",
      agencydata,
      null,
      false
    )
      .then((returnData) => {
        ToastRef.current.notify(["error", returnData.message]);
        navigateToPageWithDelay("/", navigate);
      })
      .catch((error) => {
        ToastRef.current.notify(["error", error]);
      });
  };

  return (
    <div className="agency">
      <ToastAlert ref={ToastRef} />
      <div className="container">
        <div className="aa-text-api-data">
          <div className="aa-principal-details">
            <div className="aa-section-text">Principal Details</div>
            <div className="details-box">
              <div className="pd-name">
                {data.jobTitle + " " + data.firstName + " " + data.lastName}
              </div>
              <div className="pd-phone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.38028 8.85323C9.07627 10.3028 10.0251 11.6615 11.2266 12.8631C12.4282 14.0646 13.7869 15.0134 15.2365 15.7094C15.3612 15.7693 15.4235 15.7992 15.5024 15.8222C15.7828 15.904 16.127 15.8453 16.3644 15.6752C16.4313 15.6274 16.4884 15.5702 16.6027 15.4559C16.9523 15.1063 17.1271 14.9315 17.3029 14.8172C17.9658 14.3862 18.8204 14.3862 19.4833 14.8172C19.6591 14.9315 19.8339 15.1063 20.1835 15.4559L20.3783 15.6508C20.9098 16.1822 21.1755 16.448 21.3198 16.7333C21.6069 17.3009 21.6069 17.9712 21.3198 18.5387C21.1755 18.8241 20.9098 19.0898 20.3783 19.6213L20.2207 19.7789C19.6911 20.3085 19.4263 20.5733 19.0662 20.7756C18.6667 21 18.0462 21.1614 17.588 21.16C17.1751 21.1588 16.8928 21.0787 16.3284 20.9185C13.295 20.0575 10.4326 18.433 8.04466 16.045C5.65668 13.6571 4.03221 10.7947 3.17124 7.76131C3.01103 7.19687 2.93092 6.91464 2.9297 6.5017C2.92833 6.04347 3.08969 5.42298 3.31411 5.02348C3.51636 4.66345 3.78117 4.39863 4.3108 3.86901L4.46843 3.71138C4.99987 3.17993 5.2656 2.91421 5.55098 2.76987C6.11854 2.4828 6.7888 2.4828 7.35636 2.76987C7.64174 2.91421 7.90747 3.17993 8.43891 3.71138L8.63378 3.90625C8.98338 4.25585 9.15819 4.43065 9.27247 4.60643C9.70347 5.26932 9.70347 6.1239 9.27247 6.78679C9.15819 6.96257 8.98338 7.13738 8.63378 7.48698C8.51947 7.60129 8.46231 7.65845 8.41447 7.72526C8.24446 7.96269 8.18576 8.30695 8.26748 8.5873C8.29048 8.6662 8.32041 8.72854 8.38028 8.85323Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                {data.phone}
              </div>
              <div className="pd-email">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                {data.email}
              </div>
            </div>
          </div>
          <div className="aa-license-details">
            <div className="aa-section-text">License Details</div>
            <div className="details-box">
              <table>
                <tr>
                  <td>License Number:</td>
                  <td>
                    <div className="ld-number">{data.license}</div>
                  </td>
                </tr>
                <tr>
                  <td>Expiry Date:</td>
                  <td>
                    <div className="ld-expiry">{data.expiryDate}</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="aa-company-details">
            <div className="aa-section-text">Company Details</div>
            <div className="details-box">
              <table>
                <tr>
                  <td>Company Address:</td>
                  <td>
                    <div className="company-address">
                      {data.companyRegNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Company Name:</td>
                  <td>
                    <div className="company-name">{data.compnayName}</div>
                  </td>
                </tr>
                <tr>
                  <td>ABN Number:</td>
                  <td>
                    <div className="company-abn">{data.abnNum}</div>
                  </td>
                </tr>
                <tr>
                  <td>ACN Number:</td>
                  <td>
                    <div className="company-acn">{data.acnNumber}</div>
                  </td>
                </tr>
                <tr>
                  <td>Trading Name:</td>
                  <td>
                    <div className="trading-name">{data.tradingName}</div>
                  </td>
                </tr>
                <tr>
                  <td>GST Registered:</td>
                  <td>
                    <div className="company-gst-status">
                      {(data.isGstRegistered && "Yes") || "No"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Trading State:</td>
                  <td>
                    <div className="company-trade-state">
                      {data.tradingRegion}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Corporate License Number:</td>
                  <td>
                    <div className="cooperate-license-number">
                      {data.corporateLicenseNumber}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="aa-insurance-details">
            <div className="aa-section-text">Insurance Details</div>
            <div className="details-box">
              <table>
                <tr>
                  <td>Policy Number:</td>
                  <td>
                    <div className="insd-number">
                      {data.insurancePolicyNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Expiry Date:</td>
                  <td>
                    <div className="insd-expiry">
                      {data.insurancePolicyExpiry}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="aa-payment-details">
            <div className="aa-section-text">Payment Details</div>

            <div className="details-box">
              <table>
                <tr>
                  <td>Bank Name:</td>
                  <td>
                    <div className="pyd-bank-name">
                      {data.saleAccountNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Account Name:</td>
                  <td>
                    <div className="pyd-accn">{data.saleAccountName}</div>
                  </td>
                </tr>
                <tr>
                  <td>BSB Account Number:</td>
                  <td>
                    <div className="pyd-bsbn">{data.saleBsb}</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        {status == eNewAgencyProfileStatus.ApprovalStatus && (
          <div className="aa-buttons">
            <button
              className="agency-button reject"
              type="submit"
              onClick={() => onClickButtons(eApprovalStatus.Reject, "Rejected")}
            >
              <div className="text-parent">
                <div className="text7">Reject</div>
              </div>
            </button>
            <button
              className="agency-button resubmit"
              type="submit"
              onClick={() =>
                onClickButtons(eApprovalStatus.Resubmit, "Resubmit")
              }
            >
              <div className="text-parent">
                <div className="text7">Resubmit</div>
              </div>
            </button>
            <button
              className="agency-button approve"
              type="submit"
              onClick={() => onClickButtons(eApprovalStatus.Approved, "Done")}
            >
              <div className="text-parent">
                <div className="text7">Approve</div>
              </div>
            </button>
          </div>
        )}
      </div>
      <form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Note for the Agency</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea name="note" onChange={(e) => setNotes(e.target.value)} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => handleSubmit(ApprovalStatus, notes)}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default ApproveAgency;
