import { Outlet, Navigate } from "react-router-dom";
import { GetCookie } from '../components/CommonSection/Cookie/Session';
import React from 'react'

const PrivateRoutes = () => {
    let auth=GetCookie("@UserData");
  return (
    auth?<Outlet/>: <Navigate to="/signin"/>
  );
}

export default PrivateRoutes