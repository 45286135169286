import React from "react";
import { Timeline } from 'primereact/timeline';
import "../../components/Dashboard/Agency/PropertiesAgecny/viewPropertyDetails.css"

const TabsComponent = ({ activeTab, handleTabChange,groupedHistories }) => {
  const events = [
    { status: '11:24 AM', date: 'Updated email from affirm@gmail.com to aconfirm@gmail.com', author: 'by Ravinder Singh 14th May 2024', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
    { status: '11:24 AM', date: 'Updated email from affirm@gmail.com to aconfirm@gmail.com', author: 'by Ravinder Singh 14th May 2024', icon: 'pi pi-cog', color: '#673AB7' },
  ];

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString([], options);
    return `${formattedDate} ${formattedTime}`;
  };


  const renderTabContent = () => {
    switch (activeTab) {
      case "messages":
        return (
          <div className="timeline-content">
            <div className="timeline-date">14/05/2024</div>
            <Timeline
              value={events}
              opposite={(item) => item.status}
              content={(item) => (
                <div>
                  <small className="text-color-secondary">{item.date}</small>
                  <br />
                  <small className="author-text">{item.author}</small>
                </div>
              )}
            />
          </div>
        );
      case "inspection":
        return (
          <div className="timeline-content">
            <div className="timeline-date">07/05/2024</div>
            <Timeline
              value={events}
              opposite={(item) => item.status}
              content={(item) => (
                <div>
                  <small className="text-color-secondary">{item.date}</small>
                  <br />
                  <small className="author-text">{item.author}</small>
                </div>
              )}
            />
          </div>
        );
      case "bank":
        return (
          <div className="timeline-content">
            <div className="timeline-date">01/05/2024</div>
            <Timeline
              value={events}
              opposite={(item) => item.status}
              content={(item) => (
                <div>
                  <small className="text-color-secondary">{item.date}</small>
                  <br />
                  <small className="author-text">{item.author}</small>
                </div>
              )}
            />
          </div>
        );
      case "maintenance":
        return (
          <div className="timeline-content">
            <div className="timeline-date">25/04/2024</div>
            <Timeline
              value={events}
              opposite={(item) => item.status}
              content={(item) => (
                <div>
                  <small className="text-color-secondary">{item.date}</small>
                  <br />
                  <small className="author-text">{item.author}</small>
                </div>
              )}
            />
          </div>
        );
      default:
        return (
          <div className="timeline-content">
            {Object.keys(groupedHistories ? groupedHistories : {}).map((date) => (
              <div key={date} className="mb-3">
                <Timeline
                  value={groupedHistories ? groupedHistories[date] : []}
                  opposite={(item) => formatDateTime(item.createdOn)}
                  content={(item) => (
                    <div>
                      <small className="text-color-secondary">{`Updated ${item.displayName} from ${item.beforeValue} to ${item.afterValue}`}</small>
                      <br />
                      <small className="author-text">{`by ${item.userName}`}</small>
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
        );
    }
  };

  return (
    <div className="right-content">
      <div className="tabs">
        <button className={`tab ${activeTab === "all" ? "active" : ""}`} onClick={() => handleTabChange("all")}>All</button>
        <button className={`tab ${activeTab === "messages" ? "active" : ""}`} onClick={() => handleTabChange("messages")}>Messages</button>
        <button className={`tab ${activeTab === "inspection" ? "active" : ""}`} onClick={() => handleTabChange("inspection")}>Inspection</button>
        <button className={`tab ${activeTab === "bank" ? "active" : ""}`} onClick={() => handleTabChange("bank")}>Bank</button>
        <button className={`tab ${activeTab === "maintenance" ? "active" : ""}`} onClick={() => handleTabChange("maintenance")}>Maintenance</button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default TabsComponent;
