import React, { useCallback, useImperativeHandle } from 'react';
// Importing toastify module
import { toast, ToastContainer } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

// toast-configuration method, 
// it is compulsory method.
const duration = 2000;

// This is main function
const ToastAlert = React.forwardRef((props1, ref) => {

  // function which is called when 
  // button is clicked
  const notify = useCallback((props) => {
    console.log("notify", props);
    // Calling toast method by passing string
    if (props[0] === 'success')
      toast.success(props[1], {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    else if (props[0] === 'warn')
      toast.warn(props[1], {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    else if (props[0] === 'error')
      toast.error(props[1], {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    else
      toast(props.message, {
        position: "top-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, []);

  const clear = useCallback(() => {
    toast.dismiss();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      notify,
      clear,
    }),
    [notify, clear],
  );

  return (
    <div className="Toast">
      <ToastContainer />
    </div>
  );
});

export default ToastAlert;
