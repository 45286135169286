import React from "react";
import { useEffect, useState, useRef } from "react";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { updatesalesAgrmntPropertyDetails,ABNLookUpGuid,ABNLookUpbaseURL } from "../../../components/CommonSection/apiresources";
import { GetCookie } from "../../../components/CommonSection/Cookie/Session";
import TextBox from "../../input-components/textbox";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import { ActionStatus } from "../../../Utilities/enum";
import { addParametersForProfiles, dynamicUrl } from "../../../Utilities/profileManagement";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Switch } from "antd";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import "../openHome/openHome.css";
import AddressInput from "../../input-components/address";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import Collapse from "../../input-components/collapse";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import CustomModal from "../../input-components/Modal";
import AddNewOwnerShip from "./AddNewOwnerShip";
import { salesAgreement } from "../../../components/CommonSection/Schema/schema";
import { isNullOrEmpty } from "../../../components/CommonSection/Utility/ComponentFunction";

dayjs.extend(customParseFormat);

const initialValues = {
    rent: '',
    bondAmount: '',
    propertyFurnished: false,
    propertyUnfurnished: true,
    fName:'',
    contactEmail:'',
    lName:'',
    mobileNumber:'',
    address:'',
    ContactGstRegistered : false,
    compnayName: '',
    abn: ''
};


const PropertyOwners = ({ onDataRecive, handleparentsuccess, handleCurrentPage, getDetails, reportTo, closeModal,handleNextPage1,onDataRecive1 }) => {
    const navigate = useNavigate();
    const { showSpinner, hideSpinner } = useSpinnerContext();
    const [activeTopic, setActiveTopic] = useState("AddOpenHome");
    const [topicValue, setTopicValue] = useState("AddOpenHome");
    const ToastRef = useRef();
    const [viewAddressDetails, setViewAddressDetails] = useState(false);
    const [address, setAddress] = useState("");
    const [secondaryOwner , setSecondaryOwner] = useState([])
    const [primaryContact , setPrimaryContact] = useState({
        address: "",
        contactEmail:"",
        fName:"",
        getFullAddress:"",
        lName:"",
        mobileNumber:"",
        ContactlistModel:[]
    })


    useEffect(() => {
        if (getDetails) {
            setFieldValue("rent", getDetails.rent);
            setFieldValue("bondAmount", getDetails.bondAmount);
            setFieldValue("propertyFurnished", getDetails.propertyFurnished);
            setFieldValue("propertyUnfurnished", getDetails.propertyUnfurnished);
            setSecondaryOwner(getDetails.contactlistModel)
            setPrimaryContact(getDetails.primarycontactModel)
            setFieldValue("contactEmail", getDetails.primarycontactModel ? getDetails.primarycontactModel.contactEmail : "");
            setFieldValue("fName", getDetails.primarycontactModel ? getDetails.primarycontactModel.fName : "");
            setFieldValue("lName", getDetails.primarycontactModel ? getDetails.primarycontactModel.lName : "");
            setFieldValue("mobileNumber",getDetails.primarycontactModel ? getDetails.primarycontactModel.mobileNumber : "")
            setFieldValue("address",getDetails.primarycontactModel ? getDetails.primarycontactModel.getFullAddress : "")
            setFieldValue("ContactGstRegistered", getDetails.contactGstRegistered === 1 ? true : false)
            setAddress(getDetails.primarycontactModel ? getDetails.primarycontactModel.getFullAddress : "")
            
        }
    }, [getDetails]);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when activeKey changes
      }, []);

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
        setFieldTouched,
        setFieldError
    } = useFormik({
        initialValues: initialValues,
        validationSchema: salesAgreement,
        onSubmit: (values, action) => {
            handleFinalSubmit();
        },
    });

    const validate = async () => {
        Object.keys(values).forEach((field) => {
            setFieldTouched(field, true);
          });
        const errors = await validateForm();
        if (Object.keys(errors).length === 0) {
          // No validation errors, proceed with your logic
          handleFinalSubmit();
        } else {
          // Validation errors exist, handle them as needed
          console.log("Validation errors:", errors);
          // You can also display errors to the user
        }
      };
      
    const handleFinalSubmit = (data) => {
        let formData = {
            AgreementId: getDetails.id,
            ContactlistModel:secondaryOwner,
            PrimarycontactModel:primaryContact,
        };
        const hasMissingFields = secondaryOwner.some(owner => !owner.fName || !owner.lName || !owner.contactEmail || !owner.mobileNumber);
if(hasMissingFields){
    ToastRef.current.notify(["error", "Property Owners fields are mandatory"]);
}else{
        const url = updatesalesAgrmntPropertyDetails;
        formData = addParametersForProfiles(formData);
        makeRequestWithSpinner(showSpinner, hideSpinner, url, "post", formData)
            .then((returnData) => {
                if (returnData.status === ActionStatus.Successfull) {
                    handleNextPage1("propetyies")
                    onDataRecive1(getDetails.propertyUId)
                    ToastRef.current.notify(["success", returnData.message]);
                } else {
                    ToastRef.current.notify(["error", returnData.message]);
                }
            })
            .catch((error) => {
                ToastRef.current.notify(["error", error.message]);
             });
            }
    };

    const handleTopicValue = (text) => {
        setTopicValue(text);
        dynamicUrl(navigate, text);
        setActiveTopic(text);
    };


    const handleView = () => {
        setViewAddressDetails(!viewAddressDetails);
    };

    const handlepressSelect = async (addrs) => {
        setAddress(addrs);
        setPrimaryContact(prevState => ({
            ...prevState, 
            address: addrs ,
            getFullAddress : addrs
        }));
        setFieldValue("address",addrs)
    };

    const onChangeSwitch = (e, label) => {
        if (label === "PropertyFurnished") {
            setFieldValue("propertyFurnished", e);
        } else if (label === "PropertyUnfurnished") {
            setFieldValue("propertyUnfurnished", e);
        }else if (label === "ContactGstRegistered"){
            setFieldValue("ContactGstRegistered", e)
        }
    };

    const handleDeleteOwner = (index) => {
        const updatedContactList = secondaryOwner.filter((_, i) => i !== index);
        setSecondaryOwner(updatedContactList);
    };

    const handleAddNewOwnership = () => {
        const newOwnership = {
            fName: '',
            lName: '',
            abn: '',
            address: '',
            mobileNumber: '',
            phone: '',
            contactEmail: '',
            contactId:0
        };
        const updatedContactList = [...secondaryOwner, newOwnership];
        setSecondaryOwner(updatedContactList);
    };
    

const handleChange1 = (e, index) => {
    const { name, value } = e.target;
    const updatedSecondaryOwner = [...secondaryOwner]; 
    updatedSecondaryOwner[index] = { ...updatedSecondaryOwner[index], [name]: value }; 
    setSecondaryOwner(updatedSecondaryOwner); 
    setFieldValue("ContactlistModel" , updatedSecondaryOwner)

    salesAgreement
        .validateAt(name, updatedSecondaryOwner[index])
        .then(() => {
            // No validation errors
            console.log("No validation errors");
        })
        .catch((error) => {
            // Validation error
            console.error("Validation error:", error.message);
        });
};

const handleChangePrimary = (e) => {
    const { name, value } = e.target;
    setPrimaryContact(prevState => ({
        ...prevState,
        [name]: value
    }));
    if(name === "contactEmail"){
        setFieldValue("contactEmail", e.target.value)
    } else if(name === "fName"){
        setFieldValue("fName", e.target.value)
    } else if(name === "lName"){
        setFieldValue("lName", e.target.value)
    }else if (name === "mobileNumber"){
        setFieldValue("mobileNumber", e.target.value)
    }
};

const handleChangeRent = (e) => {
    setFieldValue("rent", e.target.value)
    setFieldValue("bondAmount", e.target.value * 4)
}

const handleChangebond = (e) => {
    setFieldValue("bondAmount", e.target.value)
}

const handlesetAddress = (e) => {
    setAddress(e)
    setFieldValue("address" , e)
}

const handleABNLoopup = (event) => {
    const abnNumber = event.target.value;

    handleBlur(event);
    if (!isNullOrEmpty(abnNumber)) {
      makeRequestWithSpinner(
        showSpinner,
        hideSpinner,
        `${ABNLookUpbaseURL}/AbnDetails.aspx?abn=${abnNumber}&guid=${ABNLookUpGuid}`,
        "get"
      ).then((returnData) => {
        const jsonString = returnData.match(/\{.*\}/)[0];
        const parsedData = JSON.parse(
          jsonString.slice(jsonString.indexOf("{"))
        );
        if (!isNullOrEmpty(parsedData.Abn)) {
          setFieldError("abnNum", "");
          setFieldValue("compnayName", parsedData.EntityName);
        } else {
          setFieldError("abn", "Invalid ABN number");
          setFieldValue("compnayName", "");
          ToastRef.current.notify(["error", "Invalid ABN "]);
        }
      });
    }
  };

    return (
        <>
            <div className="dashboard-agency">
                <ToastAlert ref={ToastRef} />
                <div className="continer-dbag">
                    <div className="dashboardagency-content">
                        <div style={{ width: "100%" }}>
                            <form onSubmit={handleSubmit} className="">
                                <div className="property-details-content df-all all-input">
                                    <div>
                                        <Collapse
                                            id={"Property_Owners"}
                                            title={`Primary Property Owner(s)`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        isMandatory
                                                        label="First Name"
                                                        name={`fName`} // Use a dynamic name to differentiate between input fields
                                                        value={primaryContact ? primaryContact.fName : ''}
                                                        onChange={handleChangePrimary} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.fName}
                                                        touched={touched.fName}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Last Name"
                                                        name="lName"
                                                        value={primaryContact ? primaryContact.lName : ''}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.lName}
                                                        touched={touched.lName}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Mobile"
                                                        placeholder="mobileNumber"
                                                        name="mobileNumber"
                                                        value={primaryContact? primaryContact.mobileNumber : ''}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.mobileNumber}
                                                        touched={touched.mobileNumber}
                                                        icon={
                                                            <BiPhone height="100%" width="100%" />
                                                        }
                                                    />
                                                     <TextBox
                                                        isMandatory
                                                        label="Email address"
                                                        placeholder="Email"
                                                        name="contactEmail"
                                                        value={primaryContact ? primaryContact.contactEmail : ''}
                                                        onChange={handleChangePrimary}
                                                        onBlur={handleBlur}
                                                        error={errors.contactEmail}
                                                        touched={touched.contactEmail}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                   

                                                    <AddressInput
                                                    labelName="Address"
                                                        address={address}
                                                        setAddress={(e) => handlesetAddress(e)}
                                                        handlePressSelect={handlepressSelect}
                                                        error={errors.address}
                                                        touched={touched.address}
                                                        isMandatory
                                                        handleView={handleView}
                                                    />

                                                    
                                                    <div className="pd-eventDate col-lg-5 col-sm-12 " >
                                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                                                            <div className="mr-3">Registered for GST</div>
                                                            <div><Switch checked={values.ContactGstRegistered} onChange={(e) => onChangeSwitch(e, "ContactGstRegistered")}  /></div>
                                                        </div>
                                                    </div>
                                            <TextBox
                                                label="ABN/ACN"
                                                name="abn"
                                                value={values.abn}
                                                onChange={handleChange}
                                                onBlur={handleABNLoopup}
                                                error={errors.abn}
                                                touched={touched.abn}
                                            />
                                             <TextBox
                                                        isMandatory
                                                        label="Company Name"
                                                        placeholder="Company Name"
                                                        name="compnayName"
                                                        value={values.compnayName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.compnayName}
                                                        touched={touched.compnayName}
                                                        readOnly={true}
                                                    />
                                             <div className="property-details-buttons">
                                                        <button
                                                            className="pdb-cancel"
                                                            type="button"
                                                            onClick={handleAddNewOwnership}
                                                        >
                                                            Add New Owner
                                                        </button>
                                                    </div>
                                        {secondaryOwner ? secondaryOwner.map((contact, index) => (<Collapse
                                            id={"Property_Owners"}
                                            title={`Property Owner(s) ${index + 1}`}
                                            data={
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        isMandatory
                                                        label="First Name"
                                                        name={`fName`} // Use a dynamic name to differentiate between input fields
                                                        value={contact.fName}
                                                        onChange={(e) => handleChange1(e, index)} // Pass the field name to handleChange
                                                        onBlur={handleBlur}
                                                        error={errors.fName}
                                                        touched={touched.fName}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Last Name"
                                                        name="lName"
                                                        value={contact.lName}
                                                        onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.lName}
                                                        touched={touched.lName}
                                                    />
                                                     <TextBox
                                                        isMandatory
                                                        label="Mobile"
                                                        placeholder="mobileNumber"
                                                        name="mobileNumber"
                                                        value={contact.mobileNumber}
                                                        onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.mobileNumber}
                                                        touched={touched.mobileNumber}
                                                        icon={
                                                            <BiPhone height="100%" width="100%" />
                                                        }
                                                    />
                                                    <TextBox
                                                    isMandatory
                                                        label="Email address"
                                                        placeholder="Email"
                                                        name="contactEmail"
                                                        value={contact.contactEmail}
                                                        onChange={(e) => handleChange1(e, index)}
                                                        onBlur={handleBlur}
                                                        error={errors.contactEmail}
                                                        touched={touched.contactEmail}
                                                        icon={
                                                            <BiEnvelope height="100%" width="100%" />
                                                        }
                                                    />
                                                    <div className="property-details-buttons">
                                                    <button className="pdb-save-changes" type="button" onClick={() => handleDeleteOwner(index)}>Delete</button>
                                                    </div>
                                                </div>
                                            } />)) : null}
                                                   
                                                </div>
                                            } />

                                        {/* <Collapse
                                            id={"Property Details"}
                                            title={"Property Details"}
                                            data={<div>

                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <AddressInput
                                                        address={getDetails ? getDetails.propertyAddress : ''}
                                                        //setAddress={setAddress}
                                                        handlePressSelect={handlepressSelect}
                                                        //error={errors.address}
                                                        //touched={touched.address}
                                                        isMandatory
                                                        handleView={handleView}
                                                    />


                                                </div>
                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>

                                                <div className="pd-eventDate col-lg-5 col-sm-12 " style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="mr-3">The property is Unfurnished</div>
                                                            <div><Switch checked={values.propertyUnfurnished} onChange={(e) => onChangeSwitch(e, "PropertyUnfurnished")} /></div>
                                                            <div>{errors.propertyUnfurnished && touched.propertyUnfurnished ? (
                                                                <p className="form-error">{errors.propertyUnfurnished}</p>
                                                            ) : null}</div>
                                                        </div>
                                                    </div>

                                                    <div className="pd-eventDate col-lg-5 col-sm-12 " style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="mr-3">Furnished (inventory prepared by)</div>
                                                            <div><Switch checked={values.propertyFurnished} onChange={(e) => onChangeSwitch(e, "PropertyFurnished")} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            } />
                                        <Collapse
                                            id={"Rent"}
                                            title={"Rent"}
                                            data={<div>

                                                <div className="pddpn-group" style={{ padding: "0px 30px" }}>
                                                    <TextBox
                                                        isMandatory
                                                        label="Rent at which the property is to be offered(including GST if any)"
                                                        name="rent"
                                                        value={values.rent}
                                                        onChange={handleChangeRent}
                                                        onBlur={handleBlur}
                                                        error={errors.rent}
                                                        touched={touched.rent}
                                                    />
                                                    <TextBox
                                                        isMandatory
                                                        label="Bond"
                                                        name="bondAmount"
                                                        value={values.bondAmount}
                                                        onChange={handleChangebond}
                                                        onBlur={handleBlur}
                                                        error={errors.bondAmount}
                                                        touched={touched.bondAmount}
                                                    />


                                                </div>
                                            </div>

                                            } />   */}
                                            </div>

                                </div>
                                <div className="property-details-buttons">
                                    <button className="pdb-save-changes" type="button" onClick={validate}>
                                    Save & Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyOwners;
