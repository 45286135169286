import React from 'react'
import './passwordresetconfirmation.css'

const PasswordResetConfirmation = () => {
    return (
        <div className="col-12 section">
        <div className='col-xs-12 col-sm-12 col-md-10 col-lg-3 container'>
            <div className="header">
            <img
              className="logo-forealproperty-one-color2"
              alt=""
              src=".\assets\images\logo.png"
            />
            </div>
            <div className="content-fp">
                <div className="content2">
                    <div className="text-and-supporting-text">
                        <div className="icon"><img src='.\assets\images\key.png' className='icon_img'/></div>
                        <div className="text">Congratulations</div>
                    </div>
                </div>
                <div className="content1">
                    <p className='content-text-prc'>
                        You have successfully changed the password.
                    </p>
                    <div className='signup'>
                    <a href="/signin" className='a'><img src='.\assets\images\left_arrow.png'/>   Back to Log in</a>
                </div>
                </div>
                <div className="footerspace"><p></p></div>
            </div>
            <div className="footer">
              <div className="text12">© FOREAL PROPERTY 2077</div>
            </div>
            </div>
            </div>
        );
}

export default PasswordResetConfirmation