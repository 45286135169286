import React from "react";
import { useEffect, useState, useRef } from "react";
import Header from "../../../components/CommonSection/Header/Header";
import { BiSearch, BiPlus } from "react-icons/bi";
import ToastAlert from "../../../components/CommonSection/ToastAlert/ToastAlert";
import { useNavigate } from "react-router-dom";
import {
  AgreementDocumentList,
  deleteagreement,
} from "../../../components/CommonSection/apiresources";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  GetCookie,
  currentProfile,
} from "../../../components/CommonSection/Cookie/Session";
import { makeRequestWithSpinner } from "../../../Utilities/client";
import { useSpinnerContext } from "../../../Utilities/SpinnerContext";
import "../../../components/Dashboard/Dashboard-agent/AgentList.css";
import "../../../components/Dashboard/Agency/PropertiesAgecny/propertiesagency.css";
import { ActionStatus } from "../../../Utilities/enum";
import {
  addParametersForProfiles,
  dynamicUrl,
} from "../../../Utilities/profileManagement";
import Sidebar from "../../../components/CommonSection/SideBar/sideBar";
import { Paginator } from "primereact/paginator";
import "../openHome/openHome.css";
import "../Task/task.css";
import { format } from "date-fns";
import { SelectButton } from "primereact/selectbutton";
import SelectBox from "../../input-components/selectbox";
import { TiDeleteOutline } from "react-icons/ti";

const ListAgreementType = () => {
  const [agreementlist, setAgreementList] = useState([]);
  const [visiblet, setVisiblet] = useState(true);
  const [topicValue, setTopicValue] = useState("Profile");
  const [userId, setUserId] = useState("");
  const [userUId, setUserUId] = useState("");
  const [agencyID, setAgencyId] = useState("");
  const [agentID, setAgentId] = useState("");
  const [agencyUniqueID, setAgencyUniqueId] = useState("");
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const ToastRef = useRef();
  const [searchText, setSearchText] = useState("");
  const dataTableRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [activeTopic, setActiveTopic] = useState("ListAgreement");
  const { showSpinner, hideSpinner } = useSpinnerContext();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [agentUID, setAgentUID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [displayTable, setDisplaytable] = useState(true);
  const propertyTypeitems = [
    { name: "All", value: null },
    { name: "Pending", value: false },
    { name: "Completed", value: true },
  ];
  const [ageementTypeList, setAgreementTypeList] = useState([
    { label: "ExclusiveAgencyAgreement", value: 1 },
    { label: "OpenAgencyAgreement", value: 2 },
    { label: "ConjunctionAgencyAgreement", value: 3 },
    { label: "AuctionAgencyAgreement", value: 4 },
    { label: "Section51", value: 5 },
    { label: "SalesAdvice", value: 6 },
    { label: "RentAgreement", value: 7 },
    { label: "ManagementAgreement", value: 8 },
    { label: "TenantLeaseAgreement", value: 9 },
  ]);
  const [agreementType, setAgreementType] = useState(null);
  const [propertyType, setPropertyType] = useState(null);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const userData = GetCookie("@UserData");
        if (userData) {
          const parsedData = JSON.parse(userData);
          setUserUId(parsedData.userUnique_ID);
          setUserId(parsedData.userId);
          setAgencyId(parsedData.agencyID);
          setAgentId(parsedData.agentId);
          setAgencyUniqueId(parsedData.agencyUniqueID);
          setAgentUID(parsedData.agentId);
        }
      } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };
    retrieveData();
  }, [userId]);

  useEffect(() => {
    if (userId) PostApiWithSpinner();
  }, [userId, searchText, propertyType, agreementType]);

  const PostApiWithSpinner = (currentPage, totalRows) => {
    setDisplaytable(false);
    const baseUrl = AgreementDocumentList;
    let data = {
      SortBy: "CreatedDate",
      SortOrder: "Desc",
      RecordsPerPage: rows,
      AgencyId: agencyID,
      IsCompleted: propertyType,
      AgreementType: agreementType,
      PageNo: currentPage ? currentPage : 1,
    };
    if (data.SearchDateType === "") {
      delete data.SearchDateType;
    }
    data = addParametersForProfiles(data);
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          if (response.list.length > 0) {
            setDisplaytable(true);
            let templatesData = response.list;
            let templatesList = templatesData.map((task) => ({
              Type: task.agreementType,
              AgreementId: task.agreementId,
              AgreementType: task.sAgreementType,
              Created_Date: task.createdAt,
              Property_Address: task.propertyAddress,
              Status: task.isCompleted,
              PropertyId: task.propertyUniqueId,
            }));
            setAgreementList(templatesList);
            setTotalPages(response.totalCount);
            setChartData(response.object);
          } else {
            //ToastRef.current.notify(["warn", "User not added"]);
          }
        } else {
          // ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const propertyTypeChangeHandler = (value) => {
    setPropertyType(value);
  };

  const onInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const currentSideBar = currentProfile.get();

  const handleTopicValue = (text) => {
    setTopicValue(text);
    dynamicUrl(navigate, text);
    setActiveTopic(text);
  };

  const handleAddTask = () => {
    dynamicUrl(navigate, "AddTask");
  };

  const handlePageChange = (event, pageNumber) => {
    debugger;
    PostApiWithSpinner(event.page + 1, event.rows);
    setCurrentPage(pageNumber);
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleAgreement = (rowData) => {
    if (rowData.Title === "Management Agreement") {
      dynamicUrl(navigate, "ManagementAgreementTabs");
    } else if (rowData.Title === "Residential Tenancy Agreement") {
      dynamicUrl(navigate, "TenancyAgreementTabs");
    } else if (rowData.Title === "Exclusive Sales Agency Agreement") {
      dynamicUrl(navigate, "SaleAgreementTabs");
    }
  };

  const handleDelete = (rowData) => {
    console.log(rowData);
    const baseUrl = deleteagreement;
    let data = {
      AgreementId: rowData.AgreementId,
      AgreementType: rowData.Type,
    };
    makeRequestWithSpinner(showSpinner, hideSpinner, baseUrl, "post", data)
      .then((response) => {
        if (response.status === ActionStatus.Successfull) {
          ToastRef.current.notify(["success", response.message]);
          PostApiWithSpinner();
          setTimeout(() => {
            ToastRef.current.clear();
          }, 2000); // 2 seconds delay
        } else {
          ToastRef.current.notify(["error", response.message]);
        }
      })
      .catch((error) => {
        ToastRef.current.notify(["error", response.message]);
      });
  };

  const actionBodyTemplate = (rowData) => {
    console.log(rowData);
    return (
      <div className="action-icons">
        {!rowData.Status ? (
          <TiDeleteOutline
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={() => handleDelete(rowData)}
          />
        ) : null}
      </div>
    );
  };

  const handleInput = (e, label) => {
    setAgreementType(e.value);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div className="status-container" style={{ textAlign: "left" }}>
        <span style={{ color: rowData.Status ? "green" : "gray" }}>
          {rowData.Status ? "Completed" : "In-Complete"}
        </span>
      </div>
    );
  };

  const handleRowClick = (e) => {
    const clickedElement = e.originalEvent.target;
    if (clickedElement.className.includes("action-column")) {
      return;
    } else {
      if (e.data.AgreementType === "Residential Tenancy Agreement") {
        navigate(
          `/agency/TenancyAgreementTabs?propertyUID=${e.data.PropertyId}`
        );
      } else if (e.data.AgreementType === "Management Agreement") {
        navigate(
          `/agency/ManagementAgreementTabs?propertyUID=${e.data.PropertyId}`
        );
      } else if (e.data.AgreementType === "Exclusive Sales Agency Agreement") {
        navigate(`/agency/SaleAgreementTabs?propertyUID=${e.data.PropertyId}`);
      }
    }
  };

  return (
    <div className="dashboard-agency">
      <div className="continer-dbag">
        <ToastAlert ref={ToastRef} />
        <div className="dashboardagency-content">
          <div
            className="pa-content-top-level"
            onClick={() => setVisiblet(true)}
          >
            <div className="agent-list-data-table">
              <div className=" agent-container">
                <div className=" d-flex" style={{ alignItems: "center" }}>
                  <div
                    className="search-container"
                    style={{ paddingRight: "25%" }}
                  >
                    <div className="text-parent">
                      <div className="text7" style={{ fontSize: "24px" }}>
                        Agreement List
                      </div>
                    </div>
                  </div>
                  <SelectBox
                    colVal={3}
                    name="Agreement Type"
                    options={ageementTypeList}
                    //value={propertyActivities}
                    placeholder={"Agreement Type"}
                    onChange={handleInput}
                  />
                  <SelectButton
                    className="mr-2"
                    optionLabel="name"
                    options={propertyTypeitems}
                    onChange={(e) => propertyTypeChangeHandler(e.value)}
                    value={propertyType}
                  />
                </div>
              </div>
              <div className="propertyList-main-container">
                <div className="search-container1">
                  <div className="">
                    <div className="property-label">
                      List Of Agreement Templates
                    </div>
                  </div>
                  <div className="search-container">
                    <input
                      className="custom-search"
                      type="text"
                      placeholder="Search..."
                      onChange={onInputChange}
                    />
                    <BiSearch className="search-icon1" />
                  </div>
                </div>
                {displayTable ? (
                  <DataTable
                    ref={dataTableRef}
                    value={agreementlist}
                    size={"normal"}
                    tableStyle={{ minWidth: "20rem" }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    rows={10}
                    dataKey=""
                    rowClassName="custom-row"
                    onRowClick={(e) => handleRowClick(e, e.data)}
                  >
                    <Column
                      field="AgreementType"
                      header="Agreement Type"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="Property_Address"
                      header="Property Address"
                      className="common-table-style"
                      headerClassName="centered-header"
                    ></Column>
                    <Column
                      field="Created_Date"
                      header="Created Date"
                      className="common-table-style"
                      headerClassName="centered-header"
                      body={(rowData) => (
                        <div>
                          {format(new Date(rowData.Created_Date), "dd/MM/yyyy")}
                        </div>
                      )}
                    ></Column>
                    <Column
                      field="Status"
                      header="Status"
                      body={statusBodyTemplate}
                      className="common-table-style "
                      headerStyle={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    ></Column>
                    <Column
                      header="Actions"
                      body={actionBodyTemplate}
                      className="action-column right-align"
                      headerStyle={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                      style={{ textAlign: "left" }}
                    ></Column>
                  </DataTable>
                ) : null}
                {!displayTable ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    No data Found
                  </div>
                ) : null}
                {displayTable ? (
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalPages}
                    rowsPerPageOptions={[10, 20, 50]}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAgreementType;
