
import { useFormik } from 'formik';
import axios from 'axios';
import { userForgotPassword, userPasswordChange , GetUserByTokenAndTokenType } from '../apiresources';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const initialValues = {
    password: "",
    cpassword: "",
};


   

    const EmailVerificationAgent = () => {
        
        const [userId, setUserId] = useState();
        const [userRole, setUserRole] = useState();
        
        let value;
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('token')) { value = searchParams.get('token'); }
    
        const navigate = useNavigate();
        const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
            initialValues: initialValues,
            onSubmit: (values, action) => {
                console.log(values);
                handlePasswordChange();
                // action.resetForm();
            }
        })
        
        useEffect(()=>{
            if(!userId){
                getApi()
            }
        }, [userId])

        const getApi=()=>{
            const apiUrl= GetUserByTokenAndTokenType;
            const loginData={
                
                token: value,
                tokenType: 3,
            }
            axios.post(apiUrl, loginData)
    
                .then((response) => {
    
                    if (response.data.status == 1) {
                        setUserId(response.data.object.userId)
                        setUserRole(response.data.object.userRoleId)
                        console.log(response.data.object)
                    } else {
    
                        console.log(response.data.message)
    
                    }
    
    
                })
    
                .catch((error) => {
    
                    console.error('Error:', error);
    
                });
        }
        const handlePasswordChange = () => {
    
            // Your .NET backend API URL
    
            const apiUrl = userPasswordChange;
    
    
            // Request body with login information
    
            const loginData = {
    
                ConfirmPassword: values.cpassword,
    
                NewPassword: values.password,
    
                UserId: userId,
    
                UserRoleId: userRole,
    
                withPass: true,
    
            };
    
            console.log(loginData)
    
            axios.post(apiUrl, loginData)
    
                .then((response) => {
    
                    if (response.data.status == 1) {
    
                        alert(`Message : ${response.data.message}`);
                        navigate('/passwordresetconfirmation');
    
                    } else {
    
                        alert(`Message: ${response.data.message}`);
    
                    }
    
    
    
                })
    
                .catch((error) => {
    
                    console.error('Error:', error);
    
                });
    
    
        }
        return (
            <div className="col-12 section">
                <div className='col-xs-12 col-sm-12 col-md-10 col-lg-3 container'>
                    <div className="header">
                        <img
                            className="logo-forealproperty-one-color2"
                            alt=""
                            src=".\assets\images\logo.png"
                        />
                    </div>
                    <div className="content-fp">
                        <div className="content2">
                            <div className="text-and-supporting-text">
                                <div className="icon"><img src='.\assets\images\key.png' className='icon_img' /></div>
                                <div className="text">Set new password</div>
                            </div>
                        </div>
                        <div className="content1">
                            <form className='form' onSubmit={handleSubmit}>
                                <div className="input-field">
                                    <label htmlFor="password">New Password*</label>
                                    <input type="password"
                                        className='password form-control'
                                        placeholder='Enter your password'
                                        id='password'
                                        name='password'
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.password && touched.password ? <p className="form-error">{errors.password}</p> : null}
                                </div>
                                <div className="input-field">
                                    <label htmlFor="cpassword">Confirm New Password*</label>
                                    <input type="password"
                                        className='password form-control'
                                        placeholder='Enter your password'
                                        id='cpassword'
                                        name='cpassword'
                                        value={values.cpassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.cpassword && touched.cpassword ? <p className="form-error">{errors.cpassword}</p> : null}
                                </div>

                                <div className="submit">
                                    <button type='submit' className="button1" >Set New Password</button>
                                </div>
                                <div className='signup'>
                                    <a href="/signin" className='a'><img src='.\assets\images\left_arrow.png' />   Back to Log in</a>
                                </div>
                            </form>
                        </div>
                        <div className="footerspace"><p></p></div>
                    </div>
                    <div className="footer">
                        <div className="text12">© FOREAL PROPERTY 2077</div>
                    </div>
                </div>
            </div>)
    }

    export default EmailVerificationAgent
